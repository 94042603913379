<template>
  <div class="modal-column">
    <div class="modal-column-body">
      <Form ref="form" :rules="{}" labelPosition="left" :model="form" :labelWidth="110">
        <FormItem label="字段类型" prop="fieldType" required>
          <Radio v-model="form.fieldType" :datas="['系统字段', '输入字段', '计算字段']"/>
        </FormItem>
        <FormItem label="字段名称:" prop="fieldName" required>
          <Input type="text" v-model="form.fieldName"/>
        </FormItem>
        <FormItem label="数据类型" prop="dataType" required>
          <Radio v-model="form.dataType" :datas="['数值型', '字符型']"/>
        </FormItem>
        <FormItem label="项目配置" prop="proConfig">
          <Select :deletable="false" v-model="form.proConfig" :datas="{'工资总额': '工资总额 ', '应扣工资': '应扣工资', '实发工资调整': '实发工资调整'}"/>
        </FormItem>
        <FormItem label="系统字段" v-if="form.fieldType==='系统字段'" prop="formula">
          <Select @change="changeField" :datas="sfList" :filter="sfilter" keyName="name" titleName="name" v-model="form.formula"></Select>
        </FormItem>
        <FormItem label="计算公式" v-if="form.fieldType==='计算字段'">
          <Codemirror :extensions="extensions" :style="{ height: '200px' }" language="java" v-model="form.formula" placeholder="请输入计算公式"></Codemirror>
          <div class="mt-8px">
            <div class="mb-8px">可选系统字段：<span class="text-12px">(点击名称快速复制)</span></div>
            <div class="mb-16px">
              <span @click="copy(sf.name)" class="h-tag text-hover mb-8px" v-for="sf in sfList">{{ sf.name }}</span>
            </div>
            <template v-if="afList.length">
              <div class="my-8px">可选自定义字段：<span class="text-12px">(点击名称快速复制)</span></div>
              <div>
                <span @click="copy(af.fieldName)" class="h-tag text-hover mb-8px" v-for="af in afList">{{ af.fieldName }}</span>
              </div>
            </template>
          </div>
        </FormItem>
        <FormItem label="显示位置:" prop="weight">
          <Input type="number" v-model="form.weight"/>
        </FormItem>
      </Form>
    </div>
    <div class="modal-column-between">
      <Button @click="$emit('close')">取消</Button>
      <Button color="primary" @click="submit" :loading="loading">更新</Button>
    </div>
  </div>
</template>

<script>
import SalaryManage from "@/api/SalaryManage";
import {clipboard, message} from "heyui.ext";
import {Codemirror} from 'vue-codemirror';
import {java} from '@codemirror/lang-java'

export default {
  name: "WorkSettingForm",
  components: {Codemirror},
  props: {
    original: Object
  },
  data() {
    return {
      loading: false,
      extensions: [java()],
      form: {
        id: null,
        fieldType: '系统字段',
        fieldName: '',
        dataType: '数值型',
        proConfig: '工资总额',
        formula: '',
        weight: '',
      },
      sfList: [],
      afList: []
    }
  },
  methods: {
    submit() {
      let validResult = this.$refs.form.valid();
      if (validResult.result) {
        this.loading = true;
        SalaryManage.editCheckWork(this.form).then(({data}) => {
          message("保存成功~");
          this.$emit('success');
        }).finally(() => this.loading = false);
      }
    },
    loadSf() {
      // 获取系统字段接口
      SalaryManage.listCheckWork().then(({data}) => {
        // this.sfList = data || [];
        this.sfList = [
          {id: 1, name: '基本工资'},
          {id: 2, name: '出勤天数'},
          {id: 3, name: '交通补贴'}
        ]
      });
    },
    loadAf() {
      SalaryManage.listCheckWork().then(({data}) => {
        // this.afList = data || [];
        this.afList = [
          {id: 1, fieldName: '迟到次数'},
          {id: 2, fieldName: '早退次数'},
        ]
      });
    },
    copy(field) {
      clipboard({
        text: field
      });
    },
    changeField(data) {
      this.form.fieldName = data.name
    },
  },
  mounted() {
    if (this.original) {
      this.form = Object.assign({}, this.original)
    }
    this.loadSf()
    this.loadAf()
  }
}
</script>
<style lang="less">

</style>
