<template>
    <app-content class="h-panel">
        <div class="h-panel-bar" style="display: flex;justify-content: space-between">
            <div class="h-input-group mr-8px">
                <span class="">角色名称：</span>
                <Input type="text" v-model="keyword"/>
            </div>
            <div>
                <Button class="mr-16px" color="primary">保存
                </Button>
                <Button class="mr-16px" color="" @click="goback">取消
                </Button>
            </div>

        </div>
        <div class="h-panel-body">
            <table style="width: 100%;margin-top: 10px; color: #333333 !important;" 
            v-for="data in datas"
                   :key="data.id">
                <tr class="header" style="height: 50px;background: #EEEEEE; ">
                    <td style="width: 15%;">
                        <div style="margin-left: 10px">
                            {{ data.name }}
                        </div>
                    </td>
                    <td style="width: 65%;" align="left">
                    </td>
                    <td style="width: 20%;" align="right">
                        <div style="margin-right: 10px" @click="open(data)">
                            {{ data._expand ? '收起' : '展开' }}
                        </div>
                    </td>
                </tr>
                <tr v-if="data._expand">
                    <td style="width: 15%;  border-right: 1px solid #e8e8e8; border-bottom: 1px solid #e8e8e8;border-left: 1px solid #e8e8e8;" rowspan="{{data.child.length}}">
                        <div style="margin-left: 10px">
                            <Checkbox
                            v-model="data.checkAll"
                            @click.native="checkAllMain(data)">全选</Checkbox>
                        </div>
                    </td>
                    <td colspan="2">
                        <tr  style="width: 1000px;height: 40px;  border-bottom: 1px solid #e8e8e8;"
                       
                         v-for="item,index in data.child"
                         :key="index">
                            <td style="width: 20%">
                                <div style="margin-left: 20px">
                                    <Checkbox
                                    :indeterminate="item.permission.length>0&&item.permission.length<item.permissionAll.length"
                                     @click.native="checkAllChange(item,data)"
                                     :checked="item.permissionAll.length == item.permission.length">{{ item.projectName }}</Checkbox>
                                </div>
                            </td>
                            <td style=" display: flex;height: 40px;margin-left: 50px; align-items: center">
                                <!-- <div>{{item.permission}}</div> -->
                                <div>
                                    <Checkbox v-model="item.permission" :datas="item.permissionAll" ></Checkbox>
                                </div>
                                <!-- <div v-for="premiss in item.permission" style="margin-left: 20px">
                                    <Checkbox v-model="checkList">{{ premiss }}</Checkbox>
                                </div> -->
                            </td>
                        </tr>
                    </td>

                </tr>
            </table>

        </div>

    </app-content>
</template>

<script>

import {mapState} from 'vuex';
import SmsVerificationCode from "../../components/SmsVerificationCode";

const emptyForm = {
    "role": "Manager",
    "mobile": "",
};

export default {
    name: 'PermissionSetting',
    components: {SmsVerificationCode},
    data() {
        return {
            datas: [{
                id: 1,
                name: '凭证',
                _expand: false,
                checkAll:false,
                child: [{
                    projectName: '凭证',
                    permission: ['查看', '编辑', '审核', '反审核', '导入', '导出', '整理凭证', '删除', '打印'],
                    permissionAll: ['查看', '编辑', '审核', '反审核', '导入', '导出', '整理凭证', '删除', '打印'],
                }, {
                    projectName: '进销存凭证',
                    permission: ['查看', '生成凭证', '删除凭证', '导出'],
                    permissionAll: ['查看', '生成凭证', '删除凭证', '导出'],
                }, {
                    projectName: '会计电子档案',
                    permission: ['查看', '编辑', '审核', '驳回', '上传', '下载', '删除', '生成凭证'],
                    permissionAll: ['查看', '编辑', '审核', '驳回', '上传', '下载', '删除', '生成凭证'],
                }, {
                    projectName: '收票宝上传',
                    permission: ['查看', '编辑', '删除'],
                    permissionAll: ['查看', '编辑', '删除'],
                },],
            }, {
                id: 2,
                name: '资金',
                _expand: true,
                checkAll:false,
                child: [{
                    projectName: '日记账',
                    permission: ['查看', '编辑',  '导出', '删除'],
                    permissionAll: ['查看', '编辑',  '导出', '删除'],
                }, {
                    projectName: '内部转账',
                    permission: ['查看', '编辑', '删除', '导出'],
                    permissionAll: ['查看', '编辑', '删除', '导出'],
                }, {
                    projectName: '票据台账',
                    permission: ['查看',  '下载', ],
                    permissionAll: ['查看',  '下载', ],
                }, {
                    projectName: '资金报表',
                    permission: ['查看', '编辑', '删除'],
                    permissionAll: ['查看', '编辑', '删除'],
                },],
            }],
            form: Object.assign({}, emptyForm),
            showNewForm: false,
            showHandOver: false,
            showForm: false,
            loading: false,
            authenticated: false,
            receiverMobile: '',
            msgSended: false,
            msgTxt: '发送验证码',
            msgCode: '',
            verificationCode: '',
            validationRules: {
                required: ['mobile'],
                mobile: ['mobile']
            },
            checkList:true,
        };
    },
    computed: {
        ...mapState(['currentAccountSets', 'User']),
    },
    watch: {
        showForm(val) {
            if (!val) {
                this.reset();
            }
        }
    },
    methods: {
        checkAllMain(data){
             console.log(data.checkAll)
             if(data.checkAll){
                for(let i=0;i<data.child.length;i++){
                  data.child[i].permission  = [...data.child[i].permissionAll]
                }
             }else{
                for(let i=0;i<data.child.length;i++){
                    data.child[i].permission  = []
                }
             }
             
         
        },
        checkAllChange(item,data){
            data.checkAll = false
            // item.permission = item.permissionAll
            if (item.permission.length == item.permissionAll.length) {
                item.permission.splice(0, item.permissionAll.length);
            } else {
                item.permission = [...item.permissionAll];
            }
        },
        goback(){
            this.$store.commit('newTab', {key: 'PermissionSetting', title: "凭证列表"})
        },
        open(data) {
            data._expand = !data._expand
            // this.$set(data, '_expand', !data._expand);
        },
        add(datas) {
            datas.push({id: 7, name: '添加', age: 12, address: '然后添加的'});
        },
        loadList() {
            // this.$api.setting.user.list().then(({data}) => {
            //     this.datas = data || [];
            // })
        },
        addUser() {
            let validResult = this.$refs.form.valid();
            if (validResult.result) {
                this.loading = true;
                this.$api.setting.accountSets[this.form.id ? 'updateUserRole' : 'addUser'](this.form).then(() => {
                    this.loadList();
                    this.showForm = false;
                    this.loading = false;
                }).catch((rs) => {
                    this.loading = false;
                    if (rs.code === 501) {
                        this.showNewForm = true;
                    }
                });
            }
        },
        addNewUser() {
            if (!this.msgCode) {
                this.$Message('请输入验证码！');
                return
            }

            this.loading = true;
            this.$api.setting.accountSets.addNewUser(Object.assign({}, this.form, {code: this.msgCode})).then(() => {
                this.loadList();
                this.showForm = false;
                this.showNewForm = false;
                this.loading = false;
            }).catch((rs) => {
                this.loading = false;
            });
        },
        edit(data) {
            this.form = {
                role: data.role,
                mobile: data.mobile,
                id: data.id
            };
            this.showForm = true;
        },
        remove(data) {
            this.$Confirm("确认删除?").then(() => {
                this.$api.setting.accountSets.removeUser(data.id).then(() => {
                    this.loadList();
                })
            })
        },
        reset() {
            this.form = Object.assign({}, emptyForm);
        },
        handOver() {
            if (this.verificationCode) {
                if (!this.authenticated) {
                    //移交人身份确认
                    this.$api.setting.accountSets.identification({code: this.verificationCode}).then(() => {
                        this.verificationCode = "";
                        this.authenticated = true;
                    });
                } else {
                    //移交亲，请再次确定是否移交该账套，移交成功后，接收人登录柠檬云即可查看该账套：浙江欧易新能源有限公司
                    this.$api.setting.accountSets.handOver({
                        mobile: this.receiverMobile,
                        code: this.verificationCode
                    }).then(() => {
                        window.location.replace("/");
                    });
                }
            }
        }
    },
    mounted() {
        this.loadList();
    }
};
</script>
<style lang="less">
.cus-h-radio {
  > label {
    display: block;
    height: auto;

    .desc {
      color: @dark4-color;
      padding-left: 20px;
    }
  }
}
</style>
