<template>
  <app-content class="h-panel" >
    <Tabs :datas="datas" v-model="selected" class="px-16px"></Tabs>
    <component :is="selected" />
  </app-content>

  <!-- my -->
  <!-- <app-content class="h-panel" v-if="isshow == 'first'">
    <component is="ClosingCarryover" :checkData="checkData" />
  </app-content> -->
</template>


<script>
import CheckList from "@/views/checkout/CheckList";
import UnCheckOut from "@/views/checkout/UnCheckOut";
import ClosingCarryover from "@/views/checkout/ClosingCarryover.vue";
export default {
  name: "Checkout",
  components: {UnCheckOut, CheckList,ClosingCarryover},
  data() {
    return {
      datas: [{
        title: '期末处理',
        key: 'CheckList'
      }, {
        title: '反结账',
        key: 'UnCheckOut'
      }, {
        title: '结转模板',
        key: 'CheckTemplate'
      }],
      selected: 'CheckList',
      isshow:'Main',
      // checkData: this.$store.firstdata,
    }
  },
  // methods:{
  //   getdata  (data,type=false)  {
  //     console.log(data,'dataform')
  //     this.isshow = 'first'
  //     this.checkData = data
      
  //   }
  // },

}
</script>
<style lang="less" scoped> 
/deep/ .layui-layer-shade{
  background: none !important;
}
</style>
