<template>
	<div class="modal-column">
		<div class="modal-column-body">
			<Form mode="block" ref="form" :rules="{}" :model="formData">
				<div class="inline-form-item">
					<FormItem label="编码" prop="code" required>
						<NumberInput v-model="formData.code"/>
					</FormItem>
					<FormItem label="名称" prop="name" required>
						<Input type="text" v-model="formData.name"/>
					</FormItem>
					<FormItem label="存货类别" prop="category">
						<Select :datas="['原材料', '在产品', '半成品', '低值易耗品', '库存商品']" v-model="formData.category"/>
					</FormItem>
				</div>
				<div class="inline-form-item">
					<FormItem label="规格型号" prop="specification">
						<Input type="text" v-model="formData.specification"/>
					</FormItem>
					<FormItem label="计量单位" prop="unit">
						<Input type="text" v-model="formData.unit"/>
					</FormItem>
				</div>
				<div class="inline-form-item">
					<FormItem label="启用日期" prop="startDate">
						<DatePicker v-model="formData.startDate"/>
					</FormItem>
					<FormItem label="停用日期" prop="endDate">
						<DatePicker v-model="formData.endDate"/>
					</FormItem>
				</div>
				<FormItem label="备注" prop="remark">
					<Input type="text" v-model="formData.remark"/>
				</FormItem>
			</Form>
		</div>
		<div class="modal-column-btn modal-column-between">
			<vxe-button :loading="loading" icon="fa fa-close" @click="$emit('close')">取消</vxe-button>
			<vxe-button :loading="loading" status="primary" icon="fa fa-save" @click="onSave">保存</vxe-button>
		</div>
	</div>
</template>

<script>
/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
export default {
	name: "StocksForm"
}
</script>

<script setup>
import {reactive, ref, defineEmits, defineProps, toRefs} from "vue";
import {Stocks} from "@/api/Auxiliary";
import {message} from "heyui.ext";
import {CopyObj} from "@/js/common/utils";

const emit = defineEmits(['close', 'success'])
const props = defineProps(['entity'])

const {entity} = toRefs(props)
const loading = ref(false);
const form = ref(null);

const formData = reactive({
	id: null,
	code: null,
	enable: true,
	name: null,
	remark: null,
	category: null,
	endDate: null,
	specification: null,
	startDate: null,
	unit: null,
})

const onSave = () => {
	const validResult = form.value.valid();
	if (validResult.result) {
		loading.value = true;
		Stocks.save(formData).then(() => {
			message("保存成功~")
			emit('success')
		}).finally(() => loading.value = false)
	}
}

CopyObj(formData, entity.value);
</script>
