/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
import Ajax from '../js/common/ajax';

export default {
	list(params) {
		return Ajax.get("/cashier/account", params)
	},
	delete(id) {
		return Ajax.delete(`/cashier/account/${id}`)
	},
	save(params = {}) {
		return Ajax.post(`/cashier/account`, params)
	},
	update(params) {
		return Ajax.post(`/cashier/account`, params)
	},
	updateInitialBalance(id, balance) {
		return Ajax.get(`/cashier/account/balance/` + id, {balance})
	},
	listCheckWork(params) {
		// return Ajax.get(`/salary/listCheckWork`, params)
		return Ajax.get("/cashier/account", params)
	},
	listSalaryWork(params) {
		// return Ajax.get(`/salary/listCheckWork`, params)
		return Ajax.get("/cashier/account", params)
	},
	saveCheckWork(params) {
		return Ajax.post(`/salary/saveCheckWork`, params)
	},
	editCheckWork(row) {
		return Ajax.post(`/salary/editCheckWork`, row)
	},
	deleteCheckWork(row) {
		return Ajax.post(`/salary/deleteCheckWork`, row)
	},
}
