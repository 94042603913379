<template>
    <Dialog :visible.sync="dialog" :title="'用户服务协议 | 隐私政策'" @close="onClose" @cancel="onCancel" @ok="onConfirm">
        <div>
            <div slot="header">
                <span>用户服务协议:</span>
            </div>
            <div class="text item">本《用户服务协议》（以下称 “ 协议 ” ）是用户（以下称 “ 您 ” ）与金华企客管网络科技有限公司（以下称 “ 金华缔造梦 ”
                ）之间关于用户使用金华缔造梦相关服务所订立的协议。为使用金华缔造梦的服务，您应当阅读并遵守本协议。请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款，以及开通或使用某项服务的单独协议。限制、免责条款可能以黑体加粗形式提示您注意。
                除非您已阅读并接受本协议所有条款，否则您无权使用金华缔造梦提供的服务。您使用金华缔造梦的服务即视为您已阅读并同意上述协议的约束。
                如果您未满 18 周岁，请在法定监护人的陪同下阅读本协议，并特别注意未成年人使用条款。
                定义
                •  服务：本协议项下的服务是金华缔造梦向您提供的包括但不限于即时通讯、协同办公、在线学习、网络媒体、互动娱乐、在线企业业务处理、电子商务和广告等产品及服务。
                •  帐户证明：一旦您忘记帐号密码，可以帮助您重新获得相关资料的证明性材料。
                •  用户内容：用户内容包括您上载到服务、存储在服务中，或通过服务传送的任何信息，如数据、文档、照片、视频、音乐、电子邮件和即时消息（在第三条中简称 “ 内容 ” ）。

                帐号使用规范
                若要访问金华缔造梦相应服务，您需要一个帐号。账号是您用来在网络中验证身份的凭据。为了创建账号，您必须提供用户名和密码、某些个人资料信息，以及 “ 帐户证明 ” ，如备用电子邮件地址或电话号码。
                作为有关服务的注册用户，您可以通过邮箱／手机获得帐户。不要将您的帐户信息透露给任何其他人。您自行负责您帐户的保密和安全，并对您的帐户发生的或通过您的帐户发生的一切活动负全责，而且您同意将您的帐户出现的任何违反安全原则的情况立即通知金华缔造梦。对于对您的帐户的擅自使用引起的任何损失，金华缔造梦不承担任何责任。
                金华缔造梦帐号的所有权归金华缔造梦所有，您完成申请注册手续后，仅获得金华缔造梦帐号的使用权，且该使用权仅属于初始申请注册人。同时，初始申请注册人不得赠与、借用、租用、转让或售卖金华缔造梦帐号或者以其他方式许可非初始申请注册人使用金华缔造梦帐号。非初始申请注册人不得通过受赠、继承、承租、受让或者其他任何方式使用金华缔造梦帐号。
                您有责任妥善保管注册帐户信息及帐户密码的安全，您需要对注册帐户以及密码下的行为承担法律责任。您同意在任何情况下不向他人透露帐户及密码信息。当在您怀疑他人在使用您的帐号时，您应立即通知金华缔造梦。
                金华缔造梦可能会不定期对用户身份进行确认，该确认行为不会单独另行通知，在确认期间，若您的帐号未能通过确认，金华缔造梦保留注销或冻结您的帐号的权利。
                金华缔造梦特别提醒您应妥善保管您的帐号和密码。当您使用完毕后，应安全退出。因您保管不善可能导致遭受盗号或密码失窃，责任由您自行承担。如果您忘记密码，或因其他原因而无法访问金华缔造梦帐户，您可以通过访问重新设置密码网页来恢复您的金华缔造梦帐户。金华缔造梦不保证您的金华缔造梦帐户将能够还原，或者您的用户内容受到保护。

                用户信息保护
                您在注册帐号或使用本服务的过程中，可能需要填写一些必要的信息。若国家法律法规有特殊规定的，您需要填写真实的身份信息。若您填写的信息不完整，则无法使用本服务或在使用过程中受到限制。对于您选择提交的个人信息，您应自行承担责任。
                一般情况下，您可随时浏览、修改自己提交的信息，但出于安全性和身份识别（如号码申诉服务）的考虑，您可能无法修改注册时提供的初始注册信息及其他验证信息。
                金华缔造梦将运用各种安全技术和程序建立完善的管理制度来保护您的个人信息，以免遭受未经授权的访问、使用或披露。
                金华缔造梦不会将您的个人信息转移或披露给任何非关联的第三方，除非：
                •  相关法律法规或法院、政府机关要求；
                •  金华缔造梦认为，为保证社会公众、金华缔造梦的客户及员工的合法权益所必要且适当；
                •  作为合并、收购、资产转让或类似交易的一部分而转移；或为金华缔造梦提供您要求的服务所必需。
                •  法律法规规定的其他情形。
                金华缔造梦非常重视对未成年人个人信息的保护。若您是 18 周岁以下的未成年人，在使用金华缔造梦的服务前，应事先取得您家长或法定监护人的书面同意。
                金华缔造梦有权但无义务监视由您提交的或可在有关服务上获得的任何材料，调查任何被报告的或明显的违反本协议行为，以及采取金华缔造梦根据其自行判断认为适当的任何行动.

                用户内容
                4.1 您有权依照本协议约定使用您采购的缔造梦云服务并获得缔造梦公司的技术支持和售后服务。
                4.2 您在使用缔造梦云服务时须遵守相关法律法规、规章制度，维护互联网秩序和安全；不得从事包括但不限于以下行为，也不得为任何违反法律法规的行为提供便利：
                4.2.1 实施危害国家安全、荣誉和利益，煽动颠覆国家政权、推翻社会主义制度，煽动分裂国家、破坏国家统一，宣扬恐怖主义、极端主义，宣扬民族仇恨、民族歧视。
                4.2.2 实施诈欺、虚伪不实或误导行为，或实施侵害他人知识产权等任何合法权益的行为，如“私服”、“外挂”等。
                4.2.3 发布、传播垃圾邮件（SPAM）或包含危害国家秩序和安全、封建迷信、淫秽、色情、低俗等违法违规信息。
                4.2.4 违反与缔造梦云网络相联通之网络、设备或服务的操作规定；实施违法或未授权之接取、盗用、干扰或监测。
                4.2.5 实施任何破坏或试图破坏网络安全的行为，包括但不限于以病毒、木马、恶意代码、钓鱼等方式，对网站、服务器进行恶意扫描、非法侵入系统、非法获取数据等。
                4.2.6
                实施任何改变或试图改变缔造梦云服务提供的系统配置或破坏系统安全的行为；利用技术或其他手段破坏、扰乱缔造梦云服务的运营或他人对缔造梦云服务的使用；以任何方式干扰或企图干扰缔造梦公司任何产品或任何部分、功能的正常运行，或者制作、发布、传播上述工具、方法等。
                4.2.7 因从事包括但不限于“DNS 解析”、“安全服务”、“域名代理”、“反向代理”等任何业务，导致您自己频繁遭受攻击（包括但不限于 DDoS
                攻击）且未及时更正行为，或未根据缔造梦公司要求消除影响，从而对缔造梦云服务平台或他人产生影响的。
                4.2.8 实施其他违法、违规行为，包括但不限于赌博等非法活动。
                4.3 您不应在缔造梦云服务上安装、使用盗版软件等，并且您应按照国家有关规定对您使用的计算机信息系统采取安全保护措施，包括但不限于安装经国家许可的计算机信息系统安全专用产品等。
                4.4 您应当按时付款。若您使用后付费服务且逾期付款的，您除应依约支付到期费用外，还应按所欠费用每天加收 ３‰ 的标准向缔造梦公司支付迟延履行金，直至缴清全部费用为止。若您逾期 15
                天未支付到期费用的，缔造梦公司有权单方终止本协议。若您同时使用了预付费服务的，则您未使用的服务期限对应的费用将作为违约金归缔造梦公司所有。
                如果您采取了上述缔造梦云坚决反对和禁止的行为和产品用途，我们将无条件在不通知的情况下立即暂停您的一切缔造梦云产品服务并视行为引起后果的严重性追究责任，并保留通过法律途径追偿合理损失的权利，并适时配合警方调查取证。

                用户内容
                除了金华缔造梦准许您并入您自己内容的材料之外（如剪贴画），金华缔造梦不索求您在服务上提供的内容的所有权。您的内容仍是您的内容，您自行对其负责。此外，金华缔造梦也不会控制、验证、担保您及他人在服务上提供的内容，或者为这些内容支付费用或对其承担任何责任。
                如果您在服务的公共区域共享内容，或者在您选择的其他人也能使用的共享区域中共享内容，即表示您同意与您共享内容的任何人可以免费使用、保存、复制、分发、显示和传输这些内容，只要他们在使用这些服务以及金华缔造梦或其被许可方的其他产品和服务时涉及这些内容。如果您不希望其他人如此享用您的内容，请不要使用服务来共享您的内容。
                在您使用服务上传任何内容的范围内，您声明您拥有所有权利或得到授权或以其他方式获法律允许上传该等内容，以及该等内容不违反适用于服务的任何服务条款。
                在您将内容上载到服务时，您同意金华缔造梦可以适当地使用、修改、改编、保存、复制、分发和显示这些内容，其适当程度应是为了保护您以及提供、保护和改进金华缔造梦产品和服务而必需的程度。例如，金华缔造梦有时可能会使用自动化的方法从电子邮件、聊天或照片中隔离信息，以帮助检测和防范垃圾邮件和恶意软件或者通过可使服务更易于使用的新功能改善服务。在处理您的内容时，金华缔造梦将会采取相应的措施保护您的隐私。
                如果您的内容违反本协议或法律，金华缔造梦有权要求您从服务中删除这些内容。如不遵守，可能导致您丧失对服务或金华缔造梦帐户的访问权，或者服务或金华缔造梦帐户被取消。此外，如果金华缔造梦断定您的内容确已违反本协议或法律，或者金华缔造梦收到第三方发来的知识产权侵权投诉，金华缔造梦可能直接删除您的内容，而无需征询您的意见。金华缔造梦有权进行独立判断并采取技术手段予以删除、屏蔽或断开链接。同时，金华缔造梦有权视用户的行为性质，采取包括但不限于暂停或终止服务，限制、冻结或终止金华缔造梦帐号使用，追究法律责任等措施。

                知识产权
                您同意，有关服务（包括但不限于图片、用户界面、声音素材、视频素材、编辑内容和安装有关服务所使用的脚本和软件）包含金华缔造梦和 /
                或其许可方拥有的专有信息和材料，受适用的知识产权法律和其他法律的保护。您同意，您不会以任何方式使用该等专有信息或材料，但为了根据本协议使用有关服务的目的而使用的除外。除本协议条款明确允许的以外，不得以任何形式或任何方式复制有关服务的任何部分。您同意，不以任何方式修改、出租、租赁、出借、出售、分发有关服务，或以有关服务为基础创作衍生作品，并且您不得以任何未经授权的方式利用有关服务，包括但不限于侵占网络容量或使网络容量负荷过重。
                有关服务中的以及对有关服务的所有著作权（包括内容汇编、张贴、通往其他互联网资源的链接以及关于上述资源的描述）和相关软件的著作权，由金华缔造梦和 / 或其许可方所有，金华缔造梦和 /
                或其许可方保留其在法律上的所有权利。除本协议中允许的对有关服务的使用外，对软件或有关服务的任何部分的使用均是严格禁止的，此等使用侵犯他人的知识产权，可能使您因侵犯著作权而受到民事和刑事处罚，包括可能支付损害赔偿金。
                金华缔造梦、金华缔造梦标识以及就有关服务使用的其他金华缔造梦商标、服务标记、图形和标识是金华缔造梦在中国和 /
                或其他国家的商标或注册商标。就有关服务使用的其他商标、服务标记、图表和标识可能是其各自所有人的商标。未就任何上述商标或对上述商标的任何使用授予您任何权利或使用许可。金华缔造梦的所有域名均受到法律保护，您不得将金华缔造梦的域名注册商标或进行其他侵犯金华缔造梦域名权的行为，否则，金华缔造梦保留向您追偿的权利。

                免责条款
                您理解并同意，在使用本服务的过程中，可能会遇到不可抗力等风险因素，使本服务发生中断。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、瘟疫流行和风暴等以及社会事件如战争、动乱、政府行为等。出现上述情况时，金华缔造梦将努力在第一时间与相关单位配合，及时进行修复，但是由此给您造成的损失金华缔造梦在法律允许的范围内免责。
                您理解并同意，在使用本服务的过程中，可能会遇到网络信息或其他用户行为带来的风险，金华缔造梦不对任何信息的真实性、适用性、合法性承担责任，也不对由于使用服务致使您可能收到的任何骚扰性、威胁性、诽谤性、冒犯性、侵权或非法信息或传播承担任何责任。这些风险包括但不限于：
                •  来自他人匿名或冒名的含有威胁、诽谤、令人反感或非法内容的信息；
                •  因使用本协议项下的服务，遭受他人误导、欺骗或其他导致或可能导致的任何心理、生理上的伤害以及经济上的损失；
                •  其他因网络信息或用户行为引起的风险。
                您理解并同意，本服务并非为某些特定目的而设计，包括但不限于核设施、军事用途、医疗设施、交通通讯等重要领域。如果因为软件或服务的原因导致上述操作失败而带来的人员伤亡、财产损失和环境破坏等，金华缔造梦不承担法律责任。
                金华缔造梦依据本协议约定获得处理违法违规内容的权利，该权利不构成金华缔造梦的义务或承诺，金华缔造梦不能保证及时发现违法行为或进行相应处理。
                在任何情况下，金华缔造梦、其董事、高级职员、雇员、关联公司、代理、承包商或许可方均不对由您对有关服务的使用或无法使用所引起的任何间接、附带、惩罚性、特殊或后果性的不可预期的损害赔偿承担责任。
                金华缔造梦及各关联公司、经销商、分销商和供应商不提供与您使用服务相关的任何明示或默示的保证、保障或条件。您已了解，您应自担服务使用风险，并且金华缔造梦将按 “ 现状 ” 提供服务，服务 “ 可能存在各种缺陷
                ” 且只提供 “ 目前可用功能 ”
                。金华缔造梦不保证服务所提供的信息的准确性或及时性。在您当地法律允许的范围内，金华缔造梦排除有关适销性、质量满意保证、针对特定目的的适用性、无工艺问题和不侵权等方面的任何默示担保。根据您当地的法律，您可能享有某些权利。如果适用，本协议中的任何规定均无意影响这些权利。
                您同意金华缔造梦可以在提供服务的过程中自行或由第三方广告商向您发送广告、推广或宣传信息（包括商业与非商业信息），其方式和范围可不经向您特别通知而变更。
                金华缔造梦可能为您提供选择关闭广告信息的功能，但任何时候您都不得以本协议未明确约定或金华缔造梦未书面许可的方式屏蔽、过滤广告信息。如您的行为导致金华缔造梦受到广告主的追偿，则金华缔造梦保留向您追偿的权利。
                金华缔造梦依照法律的规定对广告商履行相关义务，您应当自行判断广告信息的真实性并为自己的判断行为负责，除法律明确规定外，您因依该广告信息进行的交易或前述广告商提供的内容而遭受的损失或损害，金华缔造梦不承担责任。
                您同意，对金华缔造梦服务中出现的广告信息，您应审慎判断其真实性和可靠性，除法律明确规定外，您应对依该广告信息进行的交易负责。

                服务变更、中断与终止
                在法律允许的范围内，如发生下列任何一种情形，金华缔造梦有权不经通知而中断、中止或终止服务：
                •  受到计算机病毒、木马或其他恶意程序、黑客攻击的破坏；
                •  您或金华缔造梦的电脑软件、系统、硬件和通信线路出现故障；
                •  违反相关法律法规或本协议的约定；
                •  您通过非金华缔造梦授权的方式使用本服务；
                •  根据法律规定您应提交真实信息，而您提供的个人资料不真实、或与注册时信息不一致又未能提供合理证明；
                •  按照法律规定或主管部门的要求；
                •  出于安全的原因或其他必要的情形；
                •  其他金华缔造梦无法控制或合理预见的情形。

                管辖与适用法律
                本协议适用中华人民共和国法律。
                因本协议引起的或与本协议有关的任何争议，各方应友好协商解决；协商不成的，任何一方均可将有关争议提交至广东仲裁委员会并按照其届时有效的仲裁规则仲裁；仲裁裁决是终局的，对各方均有约束力。
                本协议所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本协议涵义解释的依据。
                本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。
            </div>
        </div>
        <div>
            <div slot="header">
                <span>隐私政策:</span>
            </div>
            <div class="text item">
                我们以本隐私声明对访问者隐私保护的许诺。以下文字公开我站对信息收集和使用的情况。本站的隐私声明正在不断改进中，随着我站服务范围的扩大，我们会随时更新我们的隐私声明。我们欢迎您随时回来查看本声明。也可以通过联系我们的邮箱77638577@qq.com反馈您的意见。
                在浏览本网站之时，您已经同意我们按照本隐私声明来使用和披露您的个人信息。

                用户名和密码
                本隐私声明中的“信息”特指第二条所收集的信息，本隐私政策不适用于您存储在缔造梦云系统中的数据。您可通过《缔造梦云服务协议》 知悉更多关于您存储在缔造梦云系统中的数据的相关政策。

                注册信息
                当您在注册信息时，我们要求您填写一张注册表。注册表要求提供您的真实的手机号码和电子邮件地址。我们通过注册信息来获得会员的统计资料。我们将会用这些统计数据来为您提供更好的服务。我们会通过您的邮箱地址或者手机号码来通知您这些新的服务和机会。

                Cookie的使用
                cookies是少量的数据，在您未拒绝接受cookies的情况下，cookies将被发送到您的浏览器，并储存在您的计算机硬盘。我们使用cookies储存您访问我们网站的相关数据，在您访问或再次访问我们的网站时,我们能识别您的身份，并通过分析数据为您提供更好更多的服务。
                您有权选择接受或拒绝接受cookies。您可以通过修改浏览器的设置以拒绝接受cookies，但是我们需要提醒您，因为您拒绝接受cookies，您可能无法使用依赖于cookies的我们网站的部分功能。

                信息的披露和使用
                我们不会向任何无关第三方提供，出售，出租，分享和交易用户的个人信息，除非第三方和本公司(含本公司的关联公司)单独或共同为网站及(或)用户提供服务，且在该服务结束后，其将被禁止访问包括其以前能够访问的所有这些资料。 
                我们会对您及(或)您的公司的身份和交易数据进行综合统计，并以本公司判断合理的目的和用途将该综合统计向广告主披露。但是，在这些情况下，我们不会向这些机构披露任何可能用以识别用户的个人身份的资料，但从用户的用户名或其它可披露资料分析得出的资料不受此限。 
                您同意我们可批露或使用您的个人信息以用于识别和(或)确认您的身份，或解决争议，或有助于确保网站安全、限制欺诈、非法或其他刑事犯罪活动，以执行我们的服务协议。
                您同意我们可批露或使用您的个人信息以保护您的生命、财产之安全或为防止严重侵害他人之合法权益或为公共利益之需要。 
                您同意我们可批露或使用您的个人信息以改进我们的服务，并使我们的服务更能符合您的要求，从而使您在使用我们服务时得到更好的使用体验。 
                您同意我们利用您的个人信息与您联络，并向您提供您感兴趣的信息，如：产品信息。您接受“服务协议”和本隐私声明即为明示同意收取这些资料。 
                我们的网站公布了用户提交的商业机会和报价，而其他用户可以查询这些报价和商业机会。 
                在浏览本网站之时，您已经同意我们按照本隐私声明来使用和披露您的个人信息。

                信息的存储和交换
                所收集的用户信息和资料将保存在本公司及(或)其关联公司的服务器上。

                外部链接
                本站含有到其他网站的链接。本公司对那些网站的隐私保护措施不负任何责任。我们可能在任何需要的时候增加商业伙伴或共用品牌的网站 。

                公开的交易信息
                您提供发布的供贸易的物品信息或报价将被显示在公共区域，对任何用户来说是公开的。请注意所有在这些场所公开的信息会成为公共信息，请您在决定公布您的个人信息前仔细考虑。

                安全
                我们网站有相应的安全措施来确保我们掌握的信息不丢失，不被滥用和变造。这些安全措施包括向其它服务器备份数据和对用户密码加密。尽管我们有这些安全措施，但请注意在因特网上不存在“完善的安全措施”。
            </div>
        </div>

    </Dialog>
</template>

<script>

    import Dialog from '@/components/Dialog'

    export default {
        name: 'UserAgreement',
        components: {Dialog},
        data() {
            return {
                dialog: false
            }
        },
        methods: {
            onClose() { // 关闭dialog
                this.dialog = false
            },
            onCancel() { // “取消”按钮回调
                this.dialog = false
            },
            onConfirm() { // “确定”按钮回调
                this.dialog = false
            }
        }
    }
</script>

<style scoped>
    .text {
        font-size: 16px;
    }

    .item {
        padding-bottom: 30px;
        line-height: 30px;
    }

</style>
