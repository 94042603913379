<template>
    <div class="frame-page h-full !m-0px p-20px flex flex-center "
         style="align-items: center; color: #FFFFFF; justify-content: center">
        <div class="company">
            <div style="font-size: 32px;font-weight: bold">
                银企互联
            </div>
            <div style="margin-top: 20px;margin-bottom:20px;font-size: 16px">
                实时同步银行交易流水，自动获取银行回单
            </div>
            <div class="details">查看详情</div>
        </div>
        <div class="openuser">
            <div style="font-size: 32px;font-weight: bold">
                预约开户
            </div>
            <div style="margin-top: 20px;margin-bottom:20px;font-size: 16px">
                在线预约银行开户，专人贴心服务
            </div>
            <div class="details">查看详情</div>
        </div>
    </div>
</template>

<script>

import setting from "@api/setting";
import {h} from "vue";
import {layer} from "@layui/layer-vue";
import VTableAuxiliaryFastForm from "@components/VoucherTable/VTableAuxiliaryFastForm.vue";

export default {
    name: "BankCompany",

}
</script>

<style lang='less' scoped>
.company {
    display: flex;
    flex-direction: column;
    background-image: url("../../assets/bank_company.png");
    padding: 35px;
    width: 600px;
    height: 300px;
}

.openuser {
    background-image: url("../../assets/wait_openaccount.png");
    padding: 35px;
    display: flex;
    flex-direction: column;
    width: 600px;
    height: 300px;
}

.details {
    width: 100px;
    height: 40px;
    align-items: center;
    display: flex;
    justify-content: center;
    background: rgba(115, 118, 223, 0);
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    border: 1px solid #FFFFFF;
}
</style>
