<template>
    <app-content class="h-panel">
        <div class="h-panel-bar">
            <div class="margin-top margin-right" style="display: flex;justify-content: end">
                <Button color="primary" @click="showForm">新增</Button>
                <Button color="">导出</Button>
                <Button color="">导入</Button>
            </div>
        </div>
        <div class="h-panel-body voucher-list">
            <table class="header" style="background: #EEEEEE; color: #333333 !important;">
                <tr>
                    <td style="width: 40%;">摘要</td>
                    <td style="width: 20%;">科目</td>
                    <td align="right" style="width: 20%;">借方金额</td>
                    <td align="right" style="width: 20%;">贷方金额</td>
                </tr>
            </table>
            <table v-if="!datas.length">
                <tr>
                    <td colspan="5" class="text-center padding">暂无数据</td>
                </tr>
            </table>
            <table class="details"  v-for="data in datas" :key="data.id">
                <tr class="details-header" style="width: 100%;">
                    <td></td>
                    <td   colspan="2" style="width: 100%">
                        模版名称：{{ data.templateName }}
                        模版类型：{{ data.templateType }}
                    </td>
                    <td colspan="2">
                    </td>
                </tr>
                <tr style="border-bottom:  1px solid #eeeeee; width: 100%" v-for="d in data.details" :key="d.id">
                    <th></th>
                    <td style="width: 40%; align-items: start">
                        {{ d.summary }}
                    </td>
                    <td style="width: 20%;" align="left">{{ d.subject }}</td>
                    <td align="right" style="width: 20%">{{ numFormat(d.borrow) }}</td>
                    <td align="right" style="width: 20%">{{ numFormat(d.loan) }}</td>
                </tr>
                <tr class="font-bold">
                    <td></td>
                    <td>合计</td>
                    <td>{{ dxMoney(data.borrowAmount+data.loanAmount) }}</td>
                    <td align="right">{{ numFormat(data.borrowAmount) }}</td>
                    <td align="right">{{ numFormat(data.loanAmount) }}</td>
                </tr>
            </table>
        </div>
    </app-content>
</template>

<script>
import {layer} from "@layui/layer-vue";

const emptyForm = {
    "word": "",
    "printTitle": "",
    "isDefault": 0,
};
import VoucherTplDialog from "@/views/voucher/VoucherTplDialog.vue";
import {h} from "vue";

export default {
    name: 'VoucherWord',
    data() {
        return {
            datas: [{
                templateName: '销售',
                templateType: '日常收入',
                borrowAmount: 11100.00,
                loanAmount: 34416.00,
                details: [{
                    id:1,
                    summary: '销售收入_增值税普通发票_90177725_安徽新抖科技有限公司',
                    subject: '1122应收账款',
                    borrow: 11100,
                    loan: 0
                }, {
                    id:2,

                    summary: '销售收入_增值税普通发票_90177725_安徽新抖科技有限公司',
                    subject: '5001主营业务收入',
                    borrow: 0,
                    loan: 34075.25
                }, {
                    id:3,

                    summary: '销售收入_增值税普通发票_90177725_安徽新抖科技有限公司',
                    subject: '1122应收账款',
                    borrow: 0,
                    loan: 340.75

                },]
            }],
            form: Object.assign({}, emptyForm),
            validationRules: {
                required: ["word", "printTitle", "isDefault"]
            },
            loading: false
        };
    },
    watch: {
        // showForm(val) {
        //     if (!val) {
        //         this.reset();
        //     }
        // }
    },
    methods: {
        showForm (entity) {
            let layerId = layer.open({
                title: '新建模板',
                shadeClose: false,
                closeBtn: 1,
                zIndex: 1000,
                area: ['1160px', 'auto'],
                content: h(VoucherTplDialog, {
                    entity,
                    onClose: () => {
                        loadList();
                        layer.close(layerId);
                    },
                    onSuccess: (tpl) => {
                        layer.close(layerId);
                    },
                    onBack: () => {
                        layer.close(layerId);
                    }
                })
            });
        },
        loadList() {
            // this.$api.setting.voucherWord.list().then(({data}) => {
            //     this.datas = data || [];
            // })
        },
        submit() {
            let validResult = this.$refs.form.valid();
            if (validResult.result) {
                this.loading = true;
                this.$api.setting.voucherWord[this.form.id ? 'update' : 'save'](this.form).then(() => {
                    this.loadList();
                    this.showForm = false;
                    this.loading = false;
                }).catch(() => {
                    this.loading = false;
                })
            }
        },
        reset() {
            this.form = Object.assign({}, emptyForm)
        },
        remove(data) {
            this.$Confirm("确认删除?").then(() => {
                this.$api.setting.voucherWord.delete(data.id).then(() => {
                    this.loadList();
                })
            })
        },
        edit(data) {
            this.form = Object.assign({}, data)
            this.showForm = true;
        }
    },
    mounted() {
        this.loadList();
    }
};

const showForm = (entity) => {
    let layerId = layer.open({
        title:   '新建模板',
        shadeClose: false,
        closeBtn: 1,
        zIndex: 1000,
        area: ['1160px', 'auto'],
        content: h(VoucherTplDialog, {
            entity,
            onClose: () => {
                loadList();
                layer.close(layerId);
            },
            onSuccess: (tpl) => {
                layer.close(layerId);
            },
            onBack: () => {
                layer.close(layerId);
            }
        })
    });
}
</script>
