<template>
	<begin-col :type="type" :val-change="valChange" :beginCheck="beginCheck"/>
	<total-debit-col :is-start-year="isStartYear" :val-change="valChange" :beginCheck="beginCheck"/>
	<total-credit-col :is-start-year="isStartYear" :val-change="valChange" :beginCheck="beginCheck"/>
	<year-total-col/>
</template>
<script>
import BeginCol from "@/views/setting/initial/BeginCol.vue";
import TotalDebitCol from "@/views/setting/initial/TotalDebitCol.vue";
import TotalCreditCol from "@/views/setting/initial/TotalCreditCol.vue";
import YearTotalCol from "@/views/setting/initial/YearTotalCol.vue";

export default {
	name: 'AmountCol',
	components: {YearTotalCol, TotalCreditCol, TotalDebitCol, BeginCol},
	props: {
		type: String,
		isStartYear: Boolean,
		beginCheck: Boolean,
		valChange: Function
	}
}
</script>
