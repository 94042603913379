import {createApp} from 'vue'
import App from './views/App.vue';
import Login from './views/Login.vue';
import store from './store';
import router from './router';
import api from './api';
import heyui, {confirm, loading, message} from "heyui.ext";
import AppContent from './components/AppContent';
import AccountDateChoose from './components/AccountDateChoose';
import SubMenu from './components/common/sub-menu';
import RouterLink from './components/RouterLink';
import Chart from './views/app/chart/echarts';
import {useTable} from '@js/common/xe-table';
import filters from './js/common/filters';
import '@layui/layer-vue/lib/index.css';
import layer from '@layui/layer-vue';


import vcolorpicker from 'vcolorpicker'


import './js/config/dict';
import 'windi.css'
import 'font-awesome/css/font-awesome.css'
import './styles/app.less';


// // 引入主题样式文件
// import 'heyui/themes/index.less';
// // 引入heyui
// import HeyUI from 'heyui';
// // 使用主题
// HeyUI.install(Vue, {
//   theme: 'green' // 这里可以替换成你想要的主题名称
// });


// 滑块

import SlideVerify from 'vue-monoplasty-slide-verify';
// Vue.use(SlideVerify);

let app;
store.dispatch("init").then(() => {
	app = createApp(App);

	// 权限按钮
	app.directive('permission', {
	   mounted(el, binding) {
		  // 当前按钮传递的值( v-permission="'add'" 中的值)
			const value = binding.value
			const perms = store.state.perms
			if (value && value instanceof Array && value.length > 0) {
				const permissions = value
		  
				var hasPermission = false
		  
				if (perms.indexOf('*') >= 0) {
				  hasPermission = true
				} else {
				  hasPermission = perms.some(perm => {
					return permissions.includes(perm)
				  })
				}
		  
				if (!hasPermission) {
				  el.parentNode && el.parentNode.removeChild(el)
				}
			  } else {
				throw new Error(`need perms! Like v-permission="['GET /aaa','POST /bbb']"`)
			  }
		   
			//不具备权限则删除（隐藏）或者禁用该按钮 (el.parentNode 获取当前节点的父节点),根据在芫荽绑定的值去决定
			
	   }
	})
	



	app.config.globalProperties.$api = api;
	app.config.globalProperties.$Message = message;
	app.config.globalProperties.$Loading = loading;

	app.config.globalProperties.$Confirm = (content) => {
		return new Promise((resolve, reject) => {
			confirm({
				title: "操作提示",
				content,
				onConfirm: resolve,
				onCancel: reject
			})
		})
	};
	app.use(useTable).use(layer).use(filters).use(router).use(heyui).use(store).use(vcolorpicker);
	app.component("app-content", AppContent);
	app.component("account-date-choose", AccountDateChoose);
	app.component('sub-menu', SubMenu);
	app.component('Chart', Chart);
	app.component('RouterLink', RouterLink);

}).catch((err) => {
	app = createApp(Login).use(store).use(heyui).use(filters).use(vcolorpicker);
	app.config.globalProperties.$api = api;
	app.config.globalProperties.$Message = message;
}).finally(() => {
	app.config.globalProperties.APP_NAME = process.env.VUE_APP_NAME;
	app.config.globalProperties.BUID_DATE = process.env.VUE_APP_BUID_DATE;
	app.config.globalProperties.VERSION ="1.0.0.1";//process.env.VUE_APP_VERSION;
	app.mount('#app')
});
