<template>
    <div class="app-logo">
        <img   src="../../assets/menu/logo.png" alt="">
        <span class="app-logo-text">缔造云财务</span>
    </div>
</template>
<script>
    export default {
        name: "AppLogo"
    };
</script>
<style lang='less'>
    .app-logo {
        line-height: 50px;
        height: 60px;
        overflow: hidden;
        white-space: nowrap;
        transition: padding .3s;
        text-align: center;
        background-color: var(--primary);
        // background-image: url(../../assets/menu/logob.png);
        background-size: 100%;
        background-position: 50%;
        background-repeat: no-repeat;
        // margin-bottom: 16px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
        img{
            height: 30px;
            width: 30px;
        }
        .app-logo-text {
            margin-left: 10px;
            font-size: 22px;
            vertical-align: middle;
            color: #FFF;
            // font-weight: bold;
        }

        .app-logo-icon {
            background: url(../../assets/logo.png);
            background-size: contain;
            height: 26px;
            width: 26px;
            display: inline-block;
            margin-right: 5px;
            transition: .3s;
            vertical-align: middle;
        }
    }

    .h-layout-sider-collapsed {
        .app-logo-text {
            display: none;
        }
    }
</style>
