<template>
  <div class="modal-column">
    <div class="modal-column-full-body">
      <Form ref="form" mode="block" :rules="{}" :model="formData">
        <template v-if="category==='未开具发票'">
          <div class="inline-form-item">
            <FormItem label="开票日期" required prop="tradeDate">
              <DatePicker v-model="formData.tradeDate" :clearable="false"/>
            </FormItem>
            <FormItem label="客户名称" prop="customerName">
              <Input required v-model="formData.customerName"/>
            </FormItem>
            <FormItem label="统一社会信用代码" prop="creditCode">
              <Input v-model="formData.creditCode"/>
            </FormItem>
          </div>
          <FormItem label="备注" prop="note">
            <Input v-model="formData.note"/>
          </FormItem>
        </template>
        <template v-else>
          <div class="inline-form-item">
            <FormItem label="开票日期" required prop="tradeDate">
              <DatePicker v-model="formData.tradeDate" :clearable="false"/>
            </FormItem>
            <FormItem label="发票代码" required prop="invoiceCode">
              <Input required v-model="formData.invoiceCode"/>
            </FormItem>
            <FormItem label="发票号码" required prop="invoiceNumber">
              <Input v-model="formData.invoiceNumber"/>
            </FormItem>
          </div>
          <div class="inline-form-item">
            <FormItem label="是否作废" required>
              <Radio v-model="formData.state" :datas="['正常','作废']"/>
            </FormItem>
            <FormItem label="客户名称" v-if="type==='OUT'">
              <Input v-model="formData.customerName"/>
            </FormItem>
            <FormItem v-else label="供应商名称" prop="customerName" required>
              <Input v-model="formData.customerName"/>
            </FormItem>
            <FormItem label="统一社会信用代码">
              <Input v-model="formData.creditCode"/>
            </FormItem>
          </div>
          <div class="inline-form-item">
            <FormItem label="地址及电话">
              <Input v-model="formData.address"/>
            </FormItem>
            <FormItem label="开户行及账号">
              <Input v-model="formData.account"/>
            </FormItem>
            <FormItem label="备注">
              <Input v-model="formData.note"/>
            </FormItem>
          </div>
        </template>
        <FormItem label="业务类型" prop="invoiceBusinessTypeId" required>
          <Select :datas="businessList" keyName="id" :deletable="false" class="!w-200px" v-model="formData.invoiceBusinessTypeId"/>
        </FormItem>
        <FormItem label="发票数据">
          <div class="flex mb-16px">
            <div class="flex flex-1">
              <span><i class="red-color">*</i>是否录入发票明细：</span>
              <Radio v-model="formData.haveDetail" :datas="[{key:true,title:'是'},{key:false,title:'否'}]"></Radio>
            </div>
            <div class="flex flex-1 mx-16px">
              <span><i class="red-color">*</i>不含税金额：</span>
              <Input :disabled="formData.haveDetail" class="flex-1" v-model.number="formData.taxAmountExcluded"/>
            </div>
            <div class="flex flex-1">
              <span><i class="red-color">*</i>税额：</span>
              <Input :disabled="formData.haveDetail" class="flex-1" v-model.number="formData.taxAmount"/>
            </div>
            <div class="flex flex-1 ml-16px">
              <span><i class="red-color">*</i>价税合计：</span>
              <Input disabled class="flex-1" v-model.number="formData.amount"/>
            </div>
          </div>
          <template v-if="formData.haveDetail">
            <vxe-table ref="itemsTable" :data="items"  :edit-config="{trigger:'click',mode:'row'}" :edit-rules="validConfig">
              <vxe-column width="80" align="center">
                <template #default="{row,rowIndex}">
                  <span @click="pushItem(row)" class="fa fa-plus mr-16px text-hover"/>
                  <span @click="popItem(row)" class="fa fa-minus red-color text-hover"/>
                </template>
              </vxe-column>
              <vxe-column field="goodsName" title="商品名称" :edit-render="{name:'$input'}"/>
              <vxe-column field="specification" title="规格" :edit-render="{name:'$input'}"/>
              <vxe-column field="unit" title="单位" align="center" :edit-render="{name:'$input'}"/>
              <vxe-column field="quantity" title="数量" align="right" :edit-render="{name:'$input',props:{type:'number'}}"/>
              <vxe-column field="amount" title="金额" align="right" :edit-render="{name:'$input',props:{type:'number'},events:{input:amountChange},immediate:true}"/>
              <vxe-column field="taxRate" title="税率" align="right" :edit-render="{name:'$select',options:taxRateList,events:{change:amountChange}}"/>
              <vxe-column field="tax" title="税额" align="right" :edit-render="{name:'$input',props:{type:'number'},events:{input:amountChange}}"/>
              <vxe-column width="100" field="taxedItems" title="征税项目" :edit-render="{name:'$select',options:taxedItems}"/>
              <vxe-column width="140" field="taxCalculationMethod" title="计税方法" :edit-render="{name:'$select',options:taxCalculationMethod}"/>
            </vxe-table>
          </template>
        </FormItem>
      </Form>
    </div>
    <div class="modal-column-between">
      <vxe-button :loading="loading" @click="$emit('close')" icon="fa fa-close">取消</vxe-button>
      <vxe-button :loading="loading" @click="doSubmit()" status="primary" icon="fa fa-check">保存</vxe-button>
    </div>
  </div>
</template>

<script>
import {TaxCalculationMethod, TaxedItems, TaxRateList} from "@/views/invoice/InvoiceData";
import NP from "number-precision";
import {Invoice, InvoiceBusiness} from "@/api/Invoice";
import {CopyObj} from "@/js/common/utils";

/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
export default {
  name: "InvoiceForm",
  props: {
    entity: Object,
    type: String,
    category: String,
  },
  data() {
    return {
      loading: false,
      businessList: [],
      formData: {
        id: null,
        category: null,
        type: null,
        tradeDate: null,
        checkDate: null,
        invoiceCode: null,
        invoiceNumber: null,
        customerName: null,
        address: null,
        account: null,
        note: null,
        state: '正常',
        creditCode: null,
        invoiceBusinessTypeId: null,
        taxAmountExcluded: 0,
        taxAmount: 0,
        amount: 0,
        haveDetail: true
      },
      items: [],
      validConfig: {
        goodsName: [{
          required: true, message: "输入商品名称"
        }],
        amount: [{
          required: true, message: "输入金额"
        }],
        taxRate: [
          {
            required: true, message: "输选择税率"
          }
        ],
        tax: [
          {
            required: true, message: "输入税额"
          }
        ],
        taxedItems: [
          {
            required: true, message: "输选择征税项目"
          }
        ],
        taxCalculationMethod: [
          {
            required: true, message: "输选择计税方法"
          }
        ]
      },
      taxRateList: TaxRateList,
      taxedItems: TaxedItems,
      taxCalculationMethod: TaxCalculationMethod
    }
  },
  watch: {
    'formData.taxAmountExcluded'() {
      this.formData.amount = NP.round((this.formData.taxAmountExcluded || 0) + (this.formData.taxAmount || 0), 2);
    },
    'formData.taxAmount'() {
      this.formData.amount = NP.round((this.formData.taxAmountExcluded || 0) + (this.formData.taxAmount || 0), 2);
    }
  },
  methods: {
    amountChange({row}) {
      let {fullData} = this.$refs.itemsTable.getTableData();
      if (row.amount) {
        row.tax = NP.round(row.amount * (row.taxRate / 100), 2);
      } else {
        row.tax = null;
      }
      this.formData.taxAmountExcluded = NP.round(fullData.reduce((c, r) => c + Number((r.amount || 0)), 0), 2);
      this.formData.taxAmount = NP.round(fullData.reduce((c, r) => c + Number((r.tax || 0)), 0), 2)
    },
    pushItem(row) {
      this.$refs.itemsTable.insertAt({taxRate: 13, taxedItems: '应税货物', taxCalculationMethod: '一般计税方法-一般', tax: null}, row);
    },
    popItem(row) {
      let {fullData} = this.$refs.itemsTable.getTableData();
      if (fullData.length > 1) {
        this.$refs.itemsTable.remove(row);
      }
    },
    async doSubmit() {
      let validResult = this.$refs.form.valid();
      let valid = true;
      if (this.formData.haveDetail) {
        valid = await this.itemsValidate();
      }

      if (validResult.result && valid) {
        let params = Object.assign({}, this.formData);
        if (this.formData.haveDetail) {
          let {fullData} = this.$refs.itemsTable.getTableData();
          params.items = fullData.filter(val => val.goodsName && val.amount);
          this.formData.haveDetail = !!params.items.length
        }
        this.loading = true;
        Invoice.save(params).then(() => {
          this.$emit('success')
        }).finally(() => this.loading = false)
      }
    },
    async itemsValidate() {
      return this.formData.taxAmountExcluded > 0;
    }
  },
  created() {
    this.formData.category = this.category;
    this.formData.type = this.type;
    InvoiceBusiness.list(this.type).then(({data}) => {
      this.businessList = data;
      if (!this.entity) {
        this.formData.invoiceBusinessTypeId = data[0].id;
      }
    })

    if (this.entity) {
      this.items = this.entity.items;
      CopyObj(this.formData, this.entity)
    } else {
      const items = [];
      const line = this.category === '未开具发票' ? 1 : 5;
      for (let i = 0; i < line; i++) {
        items.push({taxRate: 13, taxedItems: '应税货物', taxCalculationMethod: '一般计税方法-一般', tax: null});
      }
      this.items = items;
    }
  }
}
</script>
