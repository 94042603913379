<template>
	<div class="modal-column">
		<div class="flex-1">
			<div class="m-16px flex items-center justify-around">
				<div class="label mr-8px w-100px">
					<Select :datas="{'SUBJECTS':'科目','DATA_ITEMS':'数据项'}" v-model.number="form.source" :deletable="false"/>
				</div>
				<div v-if="form.source === 'SUBJECTS'" class="flex-1">
					<Select placeholder="请选择科目" v-model="form.fromTag" :datas="subjectList" keyName="id" titleName="fullName" filterable :filter="filter">
						<template #show="{value}">{{ value.code }} - {{ value.fullName }}</template>
						<template #item="{item}">{{ item.code }} - {{ item.fullName }}</template>
					</Select>
				</div>
				<div v-else class="flex-1">
					<Select placeholder="请选择数据项" v-model="form.fromTag" :datas="templateItemsData" filterable></Select>
				</div>
				<div class="label mx-8px">运算符号</div>
				<Select class="flex-1" v-model="form.calculation" dict="operation" placeholder="运算符号" :deletable="false"/>
				<div v-if="form.source==='SUBJECTS'" class="w-200px flex items-center">
					<div class="label mx-8px">取数规则</div>
					<Select class="w-120px" v-model="form.accessRules" placeholder="取数规则" :datas="accessRules" :deletable="false"/>
				</div>
				<Button :disabled="!form.fromTag" class="ml-8px" color="primary" @click="addItem">添加</Button>
			</div>
			<div class="p-16px h-400px">
				<vxe-table ref="formulaTable" height="auto" class="margin-top" :data="formulas"  :row-config="{height:40}">
					<vxe-column title="科目名称">
						<template #default="{row}">
							{{ row.fromTag ? row.source === 'SUBJECTS' ? (subjectMap[row.fromTag] ? subjectMap[row.fromTag].code + " " + subjectMap[row.fromTag].fullName : row.fromTag) : (itemsMap[row.fromTag] ? itemsMap[row.fromTag].title : '') : row.fromTag }}
						</template>
					</vxe-column>
					<vxe-column title="运算符号" :width="100" field="calculation" align="center">
						<template #default="{row}">
							<Select class="flex-1" v-model="row.calculation" dict="operation" placeholder="运算符号" :deletable="false"/>
						</template>
					</vxe-column>
					<vxe-column title="取数规则" :width="150" field="accessRules" align="center">
						<template #default="{row}">
							<Select v-if="row.source==='SUBJECTS'" class="w-120px" v-model="row.accessRules" placeholder="取数规则" :datas="accessRules" :deletable="false"/>
						</template>
					</vxe-column>
					<vxe-column title="期末数" :width="100" field="calculation" align="center">
						<template #default="{row}">
							<span></span>
						</template>
					</vxe-column>
					<vxe-column title="年初数" :width="100" field="calculation" align="center">
						<template #default="{row}">
							<span></span>
						</template>
					</vxe-column>
					<vxe-column :width="180" align="center" title="操作">
						<template #default="{row,$rowIndex}">
							<div class="actions">
								<!-- <span @click="upGo($rowIndex)"> <i class="fa fa-angle-up"></i> 上移</span>
								<span @click="downGo($rowIndex)"> <i class="fa fa-angle-down"></i> 下移</span> -->
								<span @click="removeItem($rowIndex)" class="red-color"> <i class="fa fa-trash"></i> 删除</span>
							</div>
						</template>
					</vxe-column>
				</vxe-table>
			</div>
		</div>
		<div class="modal-column-btn">
			<button class="h-btn" :loading="loading" @click="$emit('close')">取消</button>
			<Button color="primary" :loading="loading" @click="save">保存</Button>
		</div>
	</div>
</template>

<script>
import {clone} from "xe-utils";
import {heyuiConfig, message} from "heyui.ext";
import Report from "@/api/report";
import setting from "@/api/setting";

export default {
	name: "TemplateItemFormulaModal",
	props: {
		templateItems: Array,
		templateItem: Object,
		template: Object,
	},
	data() {
		return {
			loading: false,
			subjectList: [],
			form: {
				fromTag: null,
				calculation: '+',
				accessRules: '余额',
				source: 'SUBJECTS',
			},
			formulas: []
		}
	},
	watch: {
		'form.source'() {
			this.form.fromTag = null;
		}
	},
	computed: {
		templateItemsData() {
			let map = [];
			this.templateItems.forEach(value => {
				map.push({key: value.id, title: value.title})
			});
			return map;
		},
		itemsMap() {
			let map = {};
			this.templateItems.forEach(val => {
				map[val.id] = val;
			})
			return map;
		},
		subjectMap() {
			let map = {};
			this.subjectList.forEach(val => {
				map[val.id] = val;
			})
			return map;
		},
		accessRules() {
			if (['lr', 'zcfz', 'xjll'].includes(this.template.templateKey)) {
				return heyuiConfig.getDict(this.template.templateKey + "AccessRules")
			} else {
				return heyuiConfig.getDict("accessRules")
			}
		}
	},
	methods: {
		save() {
			this.loading = false;
			Report.template.items.formula({
				formulas: this.$refs.formulaTable.getTableData().fullData,
				id: this.templateItem.id
			}).then(() => {
				this.$emit('success');
			}).finally(() => {
				this.loading = false;
			});
		},
		addItem() {
			if (this.form.fromTag) {
				if (this.formulas.find(value => value.fromTag === this.form.fromTag && value.calculation === this.form.calculation && value.accessRules === this.form.accessRules)) {
					message.error("已存在相同运算公式！");
					return
				}
				const row = Object.assign({}, this.form);
				this.formulas.push(row)
				this.$refs.formulaTable.reloadData(this.formulas);
				//this.$refs.formulaTable.insertAt(row, -1);
				this.form.fromTag = null;
			}
		},
		removeItem(idx) {
			this.formulas.splice(idx, 1)
			this.$refs.formulaTable.reloadData(this.formulas);
		},
		filter(item, val) {
			return item.code.indexOf(val) !== -1 || item.name.indexOf(val) !== -1 || item.mnemonicCode.indexOf(val) !== -1;
		},
		upGo(index) {
			if (index !== 0) {
				this.formulas[index] = this.formulas.splice(index - 1, 1, this.formulas[index])[0];
			} else {
				this.formulas.push(this.formulas.shift());
			}
			this.$refs.formulaTable.reloadData(this.formulas);
		},
		downGo(index) {
			if (index !== this.formulas.length - 1) {
				this.formulas[index] = this.formulas.splice(index + 1, 1, this.formulas[index])[0];
			} else {
				this.formulas.unshift(this.formulas.splice(index, 1)[0]);
			}
			this.$refs.formulaTable.reloadData(this.formulas);
		}
	},
	created() {
		this.formulas = clone(this.templateItem.formulas || [], true);
		setting.subject.voucherSelect({showAll: true}).then(({data}) => {
			this.subjectList = data;
		})
	}
}
</script>

<style scoped lang="less">
.voucher-select {
	max-height: 200px;
	overflow-y: auto;

	li {
		padding: 5px;
		cursor: pointer;
		// border-radius: ;

		&:hover {
			background-color: #dff7df;
		}
	}
}
</style>
