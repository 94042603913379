<template>
	<div class="modal-column">
		<div class="modal-column-body">
			<Form ref="form" mode="twocolumn" :labelWidth="150" :model="formData" :rules="{}">
				<FormItem :label="category.name+'编码'" prop="code" required>
					<NumberInput v-model="formData.code"/>
				</FormItem>
				<FormItem :label="category.name+'名称'" prop="name" required>
					<Input type="text" v-model="formData.name"/>
				</FormItem>
				<FormItem v-for="(col,i) in category.columns" :label="col.title" :key="col.code">
					<DatePicker v-model="formData[camelCase(col.code)]" v-if="col.title.indexOf('日期') !== -1"/>
					<Input v-else type="text" v-model="formData[camelCase(col.code)]"/>
				</FormItem>
				<FormItem label="备注" prop="remark" single>
					<Input v-model="formData.remark"/>
				</FormItem>
			</Form>
		</div>
		<div class="modal-column-between">
			<Button @click="$emit('close')" :loading="loading" icon="fa fa-close">取消</Button>
			<Button @click="onSave" icon="fa fa-save" :loading="loading" color="primary">保存</Button>
		</div>
	</div>
</template>

<script>
/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
export default {
	name: "CustomForm"
}
</script>

<script setup>
import {reactive, ref, defineProps, defineEmits} from "vue";
import setting from "@/api/setting";
import {camelCase} from "xe-utils";

const props = defineProps(['category', 'entity']);
const emits = defineEmits(['success', 'close']);

const loading = ref(false)
const form = ref(null)

const formData = reactive({
	id: null,
	code: null,
	name: null
})

if (props.entity) {
	Object.assign(formData, props.entity)
}

const onSave = () => {
	let validResult = form.value.valid();
	if (validResult.result) {
		loading.value = true;
		setting.accountingCategoryDetails[formData.id ? 'update' : 'save'](Object.assign({
			categoryId: props.category.id,
			categoryType: props.category.categoryType
		}, formData)).then(() => {
			emits('success')
		}).finally(() => {
			loading.value = false;
		})
	}
}
</script>
