<template>
	<div class="modal-column">
		<Loading text="损益检查中..." :loading="loading"></Loading>
		<!-- <div>第二步</div> -->
		<div class="flex-1 m-8px">
			<Row :space="9">
				<Cell width="8" v-for="(type,i) in templateList" :key="i">
					<div class="text-hover">
						<p class="text-16px titleclsoe"><b>{{ type.title }}</b></p>
						<template v-if="type.voucherId">
							<p class="pnumber">{{ type.amount }}</p>
							<router-link width="1024px" tag="button"
							 :to="{name:'VoucherForm',  params: { voucherId:type.voucherId}}" 
							 
							 class="h-btn h-btn-green">查看凭证</router-link>
							 
						</template>
						<template v-else>
							<p class="pnumber">0.00</p>
							<router-link @success="loadTemplateList"
							 width="calc(100vw - 180px)" tag="button" :shadeClose='true'
							 :to="{name:'VoucherForm', params: { checkData,type }}"
							  class="h-btn h-btn-primary">生成凭证</router-link>
						</template>
						
					</div>
				</Cell>
			</Row>
		</div>
		<div class="modal-column-btn">
			<!-- this.$emit('back'); -->
			
			<Button :loading="loading" color="primary" @click="check">下一步</Button>
		</div>
	</div>
</template>

<script>
export default {
	name: "ClosingCarryover"
}
</script>

<script setup>

import checkout from "@api/checkout";
import {h, ref} from "vue";
import {message} from "heyui.ext";
import {layer} from "@layui/layer-vue";
import VoucherForm from "@/views/voucher/VoucherForm.vue";

const props = defineProps(['checkData']);
const emits = defineEmits(['check', 'profitCheckList', 'check', 'profit', 'close']);

const templateList = ref([])
const loading = ref(false)

const loadTemplateList = () => {
	checkout.templateList(props.checkData.checkYear, props.checkData.checkMonth).then(({data}) => {
		templateList.value = data;
	})
}
loadTemplateList();

const check = () => {
	loading.value = true;
	//判断是否需要结转损益
	checkout.profitCheck(props.checkData.checkYear, props.checkData.checkMonth).then(({data}) => {
		if (data.voucherItems && data.voucherItems.length) {
			console.log('结转1')
			let layerId = layer.drawer({
				title: props.checkData.checkYear + "年" + props.checkData.checkMonth + "期-结转损益",
				shadeClose: true,
				zIndex: 999,
				area: ['calc(100vw - 180px)', '100vh'],
				content: h(VoucherForm, {
					checkData: props.checkData,
					profitCheckData: data.voucherItems,
					onSuccess: () => {
						layer.close(layerId);
						emits('check', props.checkData);
					},
					onClose: () => {
						layer.close(layerId);
					}
				})
			})
		} else if (data.voucherIds && data.voucherIds.length) {
			console.log('结转2')
			emits('profitCheckList', {checkData: props.checkData, voucherIds: data.voucherIds});
		} else {
			console.log('结转3')
			message.success("本期不需要结转损益~");
			emits('check', props.checkData);
		}
	}).finally(() => loading.value = false)
}
</script>

<style lang="less" scoped>
/deep/ .layui-layer-shade{
  background: none !important;
}
.h-row > div > div {
	// padding: 8px;
	color: #000;
	border-radius: 3px;
	text-align: center;
	background-color: #Fff;
	height: 200px;
	line-height: 36px;
	border: 1px solid #2FA8FD;
}

.pnumber {
	margin: 5px 0;
	font-size: larger;
}
.titleclsoe{
	// width: 100%;
	padding: 20px;
	border-bottom: 1px solid #2FA8FD;
}

</style>

