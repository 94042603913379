<template>
    <div class="h-full p-20px">
        <div class="flex flex-column h-full">
            <Tabs :datas="{'票据':'票据台账','询价':'询价与贴现'}" v-model="ioType"
                  class="bg-white-color px-16px"/>
            <vxe-toolbar class-name="px-16px" :loading="loading">
                <template #buttons>
                    <div class="h-input-group ml-8px">
                        <DatePicker v-model="dateRange.start"/>
                        <span style="margin-left: 10px;margin-right: 10px"> 至 </span>
                        <DatePicker v-model="dateRange.end"/>
                    </div>
                </template>
                <template #tools>
                    <Checkbox v-model="showAll" class="mr-16px">显示所有信息</Checkbox>
                    <Button @click="importJournal">删除</Button>

                    <DropdownMenu
                            button
                            trigger="hover"
                            @clickItem="voucherClickItem"
                            :datas="{generatePreview:'生成凭证预览',generateSettings:'生成凭证设置'}">
                        生成凭证
                    </DropdownMenu>
                    <Button @click="importJournal">导入</Button>
                    <Button color="primary" @click="checkVouch">新增</Button>


                </template>
            </vxe-toolbar>
            <div class="flex-1 h-0px">
                <vxe-table ref="journalTable"
                           keep-source
                           :checkbox-config="{visibleMethod:({ row }) => !row.init,checkMethod:({ row }) => !row.init}"
                           :loading="loading"
                           :data="dataResult.results"
                           @edit-closed="editClosedEvent"
                           height="auto">
                    <vxe-column type="checkbox" width="50" align="center" fixed="left"/>

                    <vxe-column title="收支类别" field="ioTypeValue">

                    </vxe-column>
                    <vxe-column title="出票日" field="date">

                    </vxe-column>
                    <vxe-column title="票据" field="billNum">

                    </vxe-column>
                    <vxe-column title="票据种类" field="billType">

                    </vxe-column>

                    <vxe-column title="汇票到期日" field="expiredDate" align="right">

                    </vxe-column>
                    <vxe-column title="出票人" field="outBillName" align="right">

                    </vxe-column>
                    <vxe-column title="承诺人" field="promisee" align="right">

                    </vxe-column>

                    <vxe-column title="能否转让" field="canOut" align="center">

                    </vxe-column>
                    <vxe-column title="操作" width="220" align="center">
                        <template #default="{row}">
                            <div class="actions">
                                <span @click="showForm(row)" class="fa ">编辑</span>
                                <span @click="showForm(row)" class="fa ">收款</span>
                                <span @click="showForm(row)" class="fa ">转让</span>
                                <span @click="doTrash(row)" class="fa ">删除</span>
                            </div>
                        </template>
                    </vxe-column>
                </vxe-table>
            </div>
            <vxe-pager @page-change="onPageChange"
                       v-model:current-page="page.page"
                       v-model:page-size="page.pageSize"
                       :total="dataResult.total"
                       :layouts="['PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'Sizes', 'FullJump', 'Total']">
                <template #left>
                    <vxe-button @click="loadList()" type="text" size="mini" icon="fa fa-refresh"
                                :loading="loading"></vxe-button>
                </template>
            </vxe-pager>
        </div>
    </div>
</template>

<script>


export default {
    name: "CashierJournal",
    methods: {
        // const importJournal = () => {
        checkVouch() {
            console.log('1111')
            this.$store.commit('newTab', {key: 'BillLedgerAdd', title: "凭证列表"})
        }

    }
}
</script>
<script setup>
import {ref, h, watch, reactive, defineProps, toRefs} from "vue";
import manba from "manba";
import {confirm, message} from "heyui.ext";
import {useStore} from 'vuex'
import CashierAccount from "@/api/CashierAccount";
import CashierIoType from "@/api/CashierIoType";
import {layer} from "@layui/layer-vue";
import CashierJournalForm from "@/views/cashier/CashierJournalForm";
import CashierJournal from "@/api/CashierJournal";
import setting from "@/api/setting";
import CashierJournalEvidence from "@/views/cashier/CashierJournalEvidence";
import Qs from "qs";
import CashierJournalBatchUpdate from "@/views/cashier/CashierJournalBatchUpdate";
import CashierIoTypeTemplate from "@/views/cashier/CashierIoTypeTemplate";
import CashierJournalVoucherPreview from "@/views/cashier/CashierJournalVoucherPreview";
import CashierAccountForm from "@/views/cashier/CashierAccountForm";
import CashierImport from "@/views/cashier/CashierImport.vue";
import {VXETable} from 'vxe-table'
import cost from "../../js/data/cost";

const store = useStore();

const accountList = ref([]);
const ioTypeMap = reactive({});
const ioTypeOptions = ref([]);
const settlementTypeOptions = ref([]);
const projectOptions = ref([]);
const deptOptions = ref([]);
const account = ref(null);
const journalTable = ref(null);
const journalParamsDropdown = ref(null);
const loading = ref(false);
const ioType = ref('票据');

const currentAccountDate = manba(store.state.currentAccountSets.currentAccountDate);
const startDate = ref(currentAccountDate.startOf(manba.MONTH).format());
const endDate = ref(currentAccountDate.endOf(manba.MONTH).format());

const formData = reactive({
    "id": null,
    "type": null,
    "digest": "",
    "serialNumber": "",
    "billDate": null,
    "accountId": null,
    "ioTypeId": null,
    "ioTypeValue": "",
    "accountingCategoryId": null,
    "accountingCategoryDetailsId": null,
    "accountingCategoryDetailsValue": "",
    "projectCategoryId": null,
    "projectCategoryDetailsId": null,
    "deptCategoryId": null,
    "deptCategoryDetailsId": null,
    "debit": null,
    "credit": null,
    "remark": null,
    "billNo": null,
    "settlementTypeId": null,
})

const updating = ref(false);
const showAll = ref(false);

const dateRange = ref({
    start: startDate.value,
    end: endDate.value
})


const page = ref({
    page: 1,
    pageSize: 20
})

const dataResult = ref({
    results: [
        {
            ioTypeValue: '增值税普通发票',
            date: '2023-09-02',
            billNum: '2023090234110291',
            billType: '银行汇票',
            expiredDate: '2024-09-02',
            outBillName: '吴小凡',
            promisee: '李一峰',
            canOut: '否',
        }

    ],
    total: 20
})

const params = reactive({
    start: startDate.value,
    end: endDate.value,
    ioTypeId: null,
    settlementTypeId: null,
    projectCategoryDetailsId: null,
    deptCategoryDetailsId: null,
    code: null,
    minMoney: null,
    maxMoney: null,
    accountingCategoryDetailsValue: null,
    digest: null,
    remark: null,
});

const onPageChange = ({currentPage, pageSize}) => {
    page.value.page = currentPage;
    page.value.pageSize = pageSize;
    loadList();
}

const saveRow = async (row) => {
    console.log(row)
    // "type": account.value.type,
    // "digest": "",
    // "serialNumber": "",
    // "billDate": endDate.value,
    // "accountId": account.value.id,
    // "ioTypeId": null,
    // "ioTypeValue": "",
    // "accountingCategoryId": null,
    // "accountingCategoryDetailsId": null,
    // "accountingCategoryDetailsValue": "",
    // "projectCategoryId": null,
    // "projectCategoryDetailsId": null,
    // "deptCategoryId": null,
    // "deptCategoryDetailsId": null,
    // "debit": null,
    // "credit": null,
    // "remark": null,
    // "billNo": null,
    // "settlementTypeId": null,
    formData.accountId = account.value.id,
        CashierJournal.save(formData).then(() => {
            emits('success');
        }).finally(() => loading.value = false)
}
const addForm = async (row) => {
    let length = dataResult.value.results.length
    let index = dataResult.value.results.indexOf(row);
    if (index === 0) {
        row = -1
    }
    const $table = journalTable.value
    if ($table) {
        const record = {}
        const {row: newRow} = await $table.insertAt(record, row)
        await $table.setEditCell(newRow, 'name')
    }
}

const editClosedEvent = ({row, column}) => {
    const $table = journalTable.value
    console.log($table)
    if ($table) {
        const field = column.field
        const cellValue = row[field]
        console.log(field)
        console.log(cellValue)
        console.log($table.isUpdateByRow(row, field))
        if ($table.isUpdateByRow(row, field)) {
            if (field == 'ioTypeValue' && cellValue.startsWith('收')) {
                row.ioType = '收'
                row.debit = 0
            } else {
                row.ioType = '支'
                row.credit = 0
            }
            $table.reloadRow(row, null, field)
            console.log(dataResult.value.results)
        }
    }
}

const loadAccountList = () => {
    loading.value = true
    CashierAccount.list().then(({data}) => {
        accountList.value = data.filter(val => val.type === ioType.value);
        if (accountList.value.length) {
            account.value = account.value ? accountList.value.find(val => val.id === account.value.id) : accountList.value[0];
        }
    }).finally(() => loading.value = false)
}

loadAccountList();

CashierIoType.list().then(({data}) => {
    ioTypeOptions.value = data.map(val => {
        ioTypeMap[val.id] = val;
        return {
            key: val.id,
            title: val.type + "-" + val.code + "-" + val.name
        }
    });
    console.log("ioTypeOptions====" + ioTypeOptions.value[1].title)
})

setting.accountSets.getSettlementTypeList().then(({data}) => {
    settlementTypeOptions.value = data.map(val => {
        return {
            key: val.id,
            title: val.title
        }
    });
})

setting.accountingCategory.list().then(({data}) => {
    const categorys = (data || []).filter(val => ['部门', '项目'].includes(val.name));
    if (categorys.length) {
        setting.accountingCategoryDetails.loadAuxiliaryAccountingData(categorys).then(({data}) => {
            let dept = categorys.find(value => value.name === '部门');
            deptOptions.value = data[dept.id].map(val => {
                return {
                    key: val.id,
                    accountingCategoryId: val.accountingCategoryId,
                    title: val.name
                }
            });
            let project = categorys.find(value => value.name === '项目');
            projectOptions.value = data[project.id].map(val => {
                return {
                    key: val.id,
                    accountingCategoryId: val.accountingCategoryId,
                    title: val.name
                }
            });
        })
    }
})

const initialBalanceChange = (row) => {
    updating.value = true;
    CashierJournal.save({id: row.id, balance: row.balance}).then(() => {
        loadList();
    }).finally(() => updating.value = false)
}

const loadList = () => {
    if (account.value) {
        loading.value = true

    }
}

const showForm = (entity = null) => {
    let layerId = layer.open({
        title: account.value.name + "日记账",
        shadeClose: false,
        closeBtn: false,
        area: ['700px', 'auto'],
        zIndex: 1000,
        content: h(CashierJournalForm, {
            entity, account, startDate, endDate, ioTypeOptions,
            ioTypeMap, settlementTypeOptions, deptOptions, projectOptions,
            onClose: () => {
                layer.close(layerId);
            },
            onSuccess: () => {
                loadList();
                layer.close(layerId);
            }
        })
    });
}

const doTrash = (row) => {
    confirm({
        content: `确认删除此记录?`,
        onConfirm: () => {
            loading.value = true;
            CashierJournal.delete(row.id).then(() => {
                loadList();
            }).finally(() => loading.value = false)
        }
    })
}

const showEvidence = (journal) => {
    layer.open({
        title: journal.serialNumber,
        closeBtn: 2,
        area: ['1100px', 'auto'],
        content: h(CashierJournalEvidence, {
            journal, account: account.value
        })
    });
}

const printClickItem = (key) => {
    let records = journalTable.value.getCheckboxRecords().map(row => row.id);
    switch (key) {
        case "printBill":
            if (records.length) {
                window.open("/api/pdf/journal?journal=" + records.join("&journal="))
            } else {
                message("请选择需要打印的记录~")
            }
            break
        case "printList":
            window.open("/api/pdf/journal/list/" + account.value.id + "?" + Qs.stringify(Object.assign(params, page.value)))
            break
    }
}

const exportJournal = () => {
    window.open("/api/cashier/journal/download/" + account.value.id + "?" + Qs.stringify(Object.assign(params, page.value)))
}

const importJournal = () => {
    let layerId = layer.open({
        title: account.value.name + "日记账导入",
        shadeClose: false,
        closeBtn: false,
        zIndex: 1000,
        area: ['5 00px', 'auto'],
        content: h(CashierImport, {
            account: account.value,
            ioType: ioType.value,
            onClose: () => {
                layer.close(layerId);
            },
            onSuccess: () => {
                layer.close(layerId);
            }
        })
    });
}

const batchClickItem = (type) => {
    let records = journalTable.value.getCheckboxRecords().map(row => row.id);
    if (records.length) {
        switch (type) {
            case 'batchDelete':
                confirm({
                    content: `确认删除这些记录?`,
                    onConfirm: () => {
                        loading.value = true;
                        CashierJournal.batchDelete(records).then(() => {
                            loadList();
                        }).finally(() => loading.value = false)
                    }
                })
                break
            case 'setIoType':
            case 'setAccountingCategory':
            case 'setProject':
            case 'setDept':
                let layerId = layer.open({
                    title: "批量更新",
                    shadeClose: false,
                    closeBtn: false,
                    area: ['400px', 'auto'],
                    zIndex: 1000,
                    content: h(CashierJournalBatchUpdate, {
                        records, type, ioTypeOptions,
                        ioTypeMap, settlementTypeOptions, deptOptions, projectOptions,
                        onClose: () => {
                            layer.close(layerId);
                        },
                        onSuccess: () => {
                            loadList();
                            layer.close(layerId);
                        }
                    })
                });
                break
        }
    } else {
        message("请选择需要操作的记录~")
    }
}

const voucherClickItem = (key) => {
    if (key === 'generatePreview') {
        let records = journalTable.value.getCheckboxRecords();
        if (records.length && records.every(val => !val.voucherId)) {
            if (account.value.subjectId) {
                let layerId = layer.drawer({
                    title: account.value.name + " 凭证预览",
                    zIndex: 1000,
                    area: ['1000px', '100vh'],
                    content: h(CashierJournalVoucherPreview, {
                        records, account: account.value,
                        onClose: () => {
                            layer.close(layerId);
                        },
                        onSuccess: () => {
                            layer.close(layerId);
                            loadList();
                        }
                    })
                });
            } else {
                confirm({
                    content: `账户【${account.value.name}】未设置会计科目<br>不能生成预览凭证<br>是否立即设置?`,
                    okText: "立即设置",
                    cancelText: "稍后操作",
                    onConfirm() {
                        let layerId = layer.open({
                            title: account.value.name + " 账户信息",
                            shadeClose: false,
                            closeBtn: false,
                            zIndex: 1000,
                            area: ['400px', 'auto'],
                            content: h(CashierAccountForm, {
                                entity: account.value,
                                type: account.value.type,
                                onClose: () => {
                                    layer.close(layerId);
                                },
                                onSuccess: () => {
                                    loadAccountList();
                                    layer.close(layerId);
                                }
                            })
                        });
                    }
                })
            }
        } else {
            message("没有选择需生成凭证的记录~")
        }
    } else if (key === 'generateSettings') {
        let layerId = layer.drawer({
            title: ioType.value + "凭证模板",
            zIndex: 1000,
            area: ['700px', '100vh'],
            content: h(CashierIoTypeTemplate, {
                ioType, account,
                onClose: () => {
                    layer.close(layerId);
                },
                onSuccess: () => {
                    layer.close(layerId);
                }
            })
        });
    }
}

watch(account, () => {
    loadList();
})

watch(ioType, () => {
    account.value = null;
    loadAccountList();
})

watch(showAll, () => {
    setTimeout(() => {
        journalTable.value.refreshColumn();
    }, 200)
})
</script>
