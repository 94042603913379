/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
import Ajax from '../js/common/ajax';

export default {
	list(params) {
		return Ajax.get("/assets/type", params)
	},
	delete(id) {
		return Ajax.delete(`/assets/type/${id}`)
	},
	save(params = {}) {
		return Ajax.post(`/assets/type`, params)
	},
	update(params = {}) {
		return Ajax.put(`/assets/type`, params)
	},
}
