<template>
  <div class="h-full p-20px">
    <div class="flex flex-column h-full">
      <div class="lay-title">
        <div class="lay-title-txt">账号信息</div>
      </div>
      <div class="flex-1 h-0px">
        <Row :space="24">
          <Cell width="12">
            <div class="h-panel" style="height: 220px">
              <div class="h-panel-bar">
                <div class="h-panel-title" style="font-size: 17px; color: black; font-weight: bold;">登录信息</div>
              </div>
              <div style="width: 100%;">
                <Row :space="24" style="margin: 0 10px">
                  <Cell width="12" style="text-align: center">
                    <Uploader :accept="accept" displayType="file" :option="uploadImage" :show-file-list="false">
                      <template #button>
                        <img :src="userInfo.avatarUrl" class="cellAvatar" alt="修改头像">
                      </template>
                    </Uploader>
                    <p style="font-size: 14px; color: #666666">{{ userInfo.realName }}</p>
                  </Cell>
                  <Cell width="12">
                      <p style="font-size: 14px; color: #666666">登录账号：{{ userInfo.account }}</p>
                      <p style="font-size: 14px; color: #666666">账&nbsp;号&nbsp;I&nbsp;D：{{ userInfo.id }}</p>
                      <p style="font-size: 14px; color: #666666">注册时间：{{ userInfo.createDate }}</p>
                      <p style="font-size: 14px; color: #666666">最后登录：{{ userInfo.createDate }}</p>
                  </Cell>
                </Row>
              </div>
            </div>
          </Cell>
          <Cell width="12">
            <div class="h-panel" style="height: 220px">
              <div class="h-panel-bar">
                <div class="h-panel-title" style="font-size: 17px; color: black; font-weight: bold;">基本信息</div>
              </div>
              <div style="width: 100%; padding: 10px 20px">
                <p style="font-size: 14px; color: #666666">姓&nbsp;&nbsp;&nbsp;&nbsp;名：{{ userInfo.realName }} <i class="vxe-icon-edit" @click="updateUserInfo('PersonalSetting')"></i> </p>
                <p style="font-size: 14px; color: #666666">手机号：{{ userInfo.mobile }} <i class="vxe-icon-edit" @click="updateUserInfo('ChangePhoneNumber')"></i> </p>
                <p style="font-size: 14px; color: #666666">密&nbsp;&nbsp;&nbsp;&nbsp;码：********** <i class="vxe-icon-edit" @click="updateUserInfo('ChangePassword')"></i> </p>
                <p style="font-size: 14px; color: #666666">邮&nbsp;&nbsp;&nbsp;&nbsp;箱：{{ userInfo.email }} <i class="vxe-icon-edit" @click="updateUserInfo('PersonalSetting')"></i> </p>
              </div>
            </div>
          </Cell>
        </Row>
      </div>
    </div>
  </div>
</template>
<script>
  import {mapMutations, mapState} from 'vuex';
  import EnterpriseWorkbench from '@/api/EnterpriseWorkbench'
  import {message} from "heyui.ext";
  import {layer} from "@layui/layer-vue";
  import {h} from "vue";
  import NewIndex from "@/views/personal/NewIndex";
  import Common from "@/api/common";
  const accept = "png,jpg"

  export default {
    name: 'ProductActivation',
    data() {
      return {
        accept,
        loading: false,
        code: null,
        userInfo: {
          avatarUrl: null,
        },
        uploadImage: {
          onBeforeUpload: (file) => {
            if (!(this.acceptList.includes(file.type) || file.type.startsWith("image/"))) {
              message.error(`${file.name} 文件是不支持的上传类型~`);
              return false;
            }
            if (file.size > 100 * 1024 * 1024 * 1024) {
              message.error(`${file.name} 文件大小超出100M限制`);
              return false;
            }
            return true;
          },
          onChange: async (file) => {
            try {
              const fd = new FormData();
              fd.append("file", file);
              const {data, success, msg} = await Common.upload("userImage", fd);
              if (success) {
                this.userInfo.avatarUrl = data.path
              } else {
                message.error(msg);
                throw new Error(msg);
              }
            } catch (error) {
              message.error(`${file.name}上传失败`);
              throw new Error();
            }
          },
        }
      };
    },
    computed: {
      ...mapState(['currentAccountSets', 'User']),
      acceptList() {
        return this.accept.split(",");
      },
    },
    methods: {
      ...mapMutations(['newTab']),
      updateUserInfo(data) {
        layer.open({
          title: "个人信息设置",
          shadeClose: false,
          zIndex: 999,
          area: ['700px', '500px'],
          content: h(NewIndex, {
            type: data,
            onClose: () => {
              layer.close(layerId);
            },
            onSuccess: () => {
              layer.close(layerId);
            }
          })
        });
      },
    },
    created() {
      this.userInfo = this.User
      this.userInfo.avatarUrl = this.User.avatarUrl || '/images/yun.png'
    },
  };
</script>

<style lang="less" scoped>
  .lay-title {
    margin: 0 0 15px 0;
    border-radius: 5px;
    border-color: #ffffff ;
    background: #ffffff ;
    /*border-bottom: 1px solid;*/
  }
  .lay-title-txt {
    font-size: 16px;
    margin-left: 20px;
    height: 50px;
    line-height: 50px;
    display: inline-block;
    position: relative;
    border-bottom: 2px solid #006eff;
  }
  .cellAvatar {
    width: 80px;
    margin: auto;
    margin-top: 20px;
  }
  .h-col p {
    line-height: 25px;
  }
</style>
