<template>
	<div class="modal-column">
		<div class="modal-column-body">
			<div>
				<p>您正在重置账套：</p>
				<p class="red-color"><b>{{ rmData.companyName }}</b></p>
				<p v-if="resetType === '余额表'">当前 <b class="red-color">{{ resetType }}</b> 所有期间数据将重新计算，请谨慎操作。</p>
				<p v-else-if="resetType === '报表配置' || resetType === '科目'">当前 <b class="red-color">{{ resetType }}</b> 将还原到账套初始状态，请谨慎操作。</p>
				<p v-else>当前 <b class="red-color">{{ resetType }}</b> 所有数据都将清空，还原到账套初始化状态，请谨慎操作。</p>
				<p>为保障数据安全，需要短信验证身份。</p>
			</div>
			<Row type="flex" :space="10">
				<Cell><Input type="text" v-model="User.mobile" disabled/></Cell>
				<Cell>
					<SmsVerificationCode :mobile="User.mobile"/>
				</Cell>
			</Row>
			<Row :space="10">
				<Cell><Input v-model="msgCode" type="number" placeholder="请输入验证码"/></Cell>
			</Row>
			<Row :space="10">
				<Cell>
					<div class="h-input-group">
						<span>重置类型：</span>
						<span>
              <Radio v-model="resetType" :datas="['账套','科目','报表配置','余额表']"/>
            </span>
					</div>
				</Cell>
			</Row>
			<Row :space="10" v-if="resetType==='账套'">
				<Cell>
					<div class="h-input-group">
						<span class="h-input-addon">账套启用年月</span>
						<DatePicker type="month" v-model="enableDate" :clearable="false"/>
					</div>
				</Cell>
			</Row>
		</div>
		<div class="modal-column-btn">
			<Button @click="$emit('close')" :loading="loading">取消</Button>
			<Button color="red" :disabled="!this.msgCode || (!enableDate && resetType==='账套')" @click="doRemove" :loading="loading">确认重置</Button>
		</div>
	</div>
</template>

<script>
/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
import {mapState} from 'vuex'
import SmsVerificationCode from "@/components/SmsVerificationCode";
import setting from "@api/setting";

export default {
	name: "RemoveConfirm",
	components: {SmsVerificationCode},
	props: {
		rmData: Object,
	},
	data() {
		return {
			loading: false,
			enableDate: null,
			resetType: '账套',
			msgCode: ''
		}
	},
	computed: {
		...mapState(['User'])
	},
	methods: {
		doRemove() {
			if (this.msgCode) {
				this.$Confirm("确认重置?").then(() => {
					this.loading = true;
					setting.accountSets.reset(this.rmData.id, this.msgCode, this.enableDate, this.resetType).then(() => {
						window.location.reload();
					}).finally(() => this.loading = false)
				})
			}
		}
	},
}
</script>
