<template>
    <div class="h-full p-20px">
        <div class="flex flex-column h-full" v-if="!isupdate">
            <vxe-toolbar class-name="px-16px" :loading="loading">
                <template #buttons>
                    <DropdownCustom ref="paramsDropdown" button class="h-btn" trigger="click">
                        <div>{{ deptCheckName }}</div>
                        <template #content>
                            <Form style="padding: 10px">
                                <FormItem class="!pb-5px" label="部门">
                                    <Select :datas="deptOptions" filterable v-model="params.deptId"/>
                                </FormItem>
                                <FormItem class="!pb-5px" label="员工状态">
                                    <Select :deletable="false" v-model="params.status"
                                            :datas="{0: '全部', 1: '在职', 2: '离职'}"/>
                                </FormItem>
                                <FormItem class="!pb-5px" label="姓名">
                                    <Input v-model="params.name"/>
                                </FormItem>
                                <FormItem class="!pb-5px" label="入职日期">
                                    <div class="flex items-center">
                                        <DatePicker v-model="params.startDate"></DatePicker>
                                        <span class="mx-8px">至</span>
                                        <DatePicker v-model="params.endDate"></DatePicker>
                                    </div>
                                </FormItem>
                                <FormItem class="!pb-5px">
                                    <vxe-button status="primary" @click="loadList();" :loading="loading">确定
                                    </vxe-button>
                                    <vxe-button @click="this.$refs.paramsDropdown.hide()">取消</vxe-button>
                                    <vxe-button @click="reset">重置</vxe-button>
                                </FormItem>
                            </Form>
                        </template>
                    </DropdownCustom>
                </template>
                <template #tools>
                    <Checkbox v-model="showAll" class="mr-16px">显示所有信息</Checkbox>
                    <Button class="h-btn-primary" @click="showForm()">增加员工</Button>
                    <Button @click="importJournal">导入</Button>
                    <Button @click="exportJournal">导出</Button>
                </template>
            </vxe-toolbar>
            <div class="flex-1 h-0px">
                <vxe-table ref="journalTable"
                           :loading="loading"
                           :data="dataResult.results"
                           height="auto"
                           align="center"
                >
                    <vxe-column title="编号" field="code" width="100" fixed="left"/>
                    <vxe-column title="姓名" field="name" width="120" fixed="left"/>
                    <vxe-column title="部门" field="deptName" width="120" fixed="left"/>
                    <vxe-column title="岗位" field="post" width="120" :visible="showAll"/>
                    <vxe-column title="手机" field="mobile" width="140"/>
                    <vxe-column title="邮箱" field="email" width="140"/>
                    <vxe-column title="证件号码" field="idCard" width="180"/>
                    <vxe-column title="员工状态" field="status" width="120" :visible="showAll"/>

                    <vxe-column title="学历" field="data1" width="120" :visible="showAll"/>
                    <vxe-column title="职称" field="data2" width="120" :visible="showAll"/>
                    <vxe-column title="计提工资科目" field="data3" width="160"/>

                    <vxe-column title="员工状态" field="status" width="120" :visible="!showAll"/>

                    <vxe-column title="银行名称" field="data4" width="150" :visible="showAll"/>
                    <vxe-column title="银行账号" field="data5" width="180" :visible="showAll"/>
                    <vxe-column title="出生日期" field="data6" width="150" :visible="showAll"/>
                    <vxe-column title="入职日期" field="data7" width="150"/>
                    <vxe-column title="工资始发日期" field="data8" width="150"/>
                    <vxe-column title="离职日期" field="data9" width="150" :visible="showAll"/>
                    <vxe-column title="工资停发日期" field="data10" width="150" :visible="showAll"/>
                    <vxe-column title="是否缴纳五险一金" field="data11" width="120" :visible="showAll"/>
                    <vxe-colgroup title="养老保险">
                        <vxe-column title="缴纳基数" field="data12" width="110" :visible="showAll"/>
                        <vxe-column title="公司比例" field="data13" width="110" :visible="showAll"/>
                        <vxe-column title="个人比例" field="data14" width="110" :visible="showAll"/>
                    </vxe-colgroup>
                    <vxe-colgroup title="医疗保险">
                        <vxe-column title="缴纳基数" field="data15" width="110" :visible="showAll"/>
                        <vxe-column title="公司比例" field="data16" width="110" :visible="showAll"/>
                        <vxe-column title="个人比例" field="data17" width="110" :visible="showAll"/>
                    </vxe-colgroup>
                    <vxe-colgroup title="失业保险">
                        <vxe-column title="缴纳基数" field="data18" width="110" :visible="showAll"/>
                        <vxe-column title="公司比例" field="data19" width="110" :visible="showAll"/>
                        <vxe-column title="个人比例" field="data20" width="110" :visible="showAll"/>
                    </vxe-colgroup>
                    <vxe-colgroup title="工伤保险">
                        <vxe-column title="缴纳基数" field="data21" width="110" :visible="showAll"/>
                        <vxe-column title="公司比例" field="data22" width="110" :visible="showAll"/>
                        <vxe-column title="个人比例" field="data23" width="110" :visible="showAll"/>
                    </vxe-colgroup>
                    <vxe-colgroup title="生育保险">
                        <vxe-column title="缴纳基数" field="data24" width="110" :visible="showAll"/>
                        <vxe-column title="公司比例" field="data25" width="110" :visible="showAll"/>
                        <vxe-column title="个人比例" field="data26" width="110" :visible="showAll"/>
                    </vxe-colgroup>
                    <vxe-colgroup title="住房公积金">
                        <vxe-column title="缴纳基数" field="data27" width="110" :visible="showAll"/>
                        <vxe-column title="公司比例" field="data28" width="110" :visible="showAll"/>
                        <vxe-column title="个人比例" field="data29" width="110" :visible="showAll"/>
                    </vxe-colgroup>
                    <vxe-column title="备注" field="data30" width="120" :visible="showAll"/>
                    <vxe-column title="操作" width="120">
                        <template #default="{row}">
                            <div class="actions" v-if="!row.init">
                                <span v-if="!row.voucherId" @click="showForm(row)" class="fa">编辑</span>
                                <span v-if="!row.voucherId" @click="doTrash(row['id'])" class="fa">删除</span>
                            </div>
                        </template>
                    </vxe-column>
                </vxe-table>
            </div>
            <vxe-pager @page-change="onPageChange"
                       v-model:current-page="page.page"
                       v-model:page-size="page.pageSize"
                       :total="dataResult.total"
                       :layouts="['PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'Sizes', 'FullJump', 'Total']">
                <template #left>
                    <vxe-button @click="loadList()" type="text" size="mini" icon="fa fa-refresh"
                                :loading="loading"></vxe-button>
                </template>
            </vxe-pager>
        </div>

        <!-- 修改与编辑 -->
        <div class="m-16px flex-1 flex flex-column" v-if="isupdate">
            <Component is="EmployeeForm"
                       :id="dataForm.value"
                       class="flex-1 flex flex-column" @getdata="getdata"></Component>
        </div>
    </div>
</template>

<script>
/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */

export default {
    name: "EmployeeInformation",
    components: {EmployeeForm},

}
</script>
<script setup>
import {ref, h, watch, reactive} from "vue";
import manba from "manba";
import {confirm, message} from "heyui.ext";
import {useStore} from 'vuex'
import Employee from "@/api/Employee";
import EmployeeForm from "@/views/salary/EmployeeForm";
import setting from "@/api/setting";
import Qs from "qs";
import {layer} from "@layui/layer-vue";

const store = useStore();

const deptCheckName = ref('所有员工');
const deptOptions = ref([]);
const account = ref(null);
const journalTable = ref(null);
const paramsDropdown = ref(null);
const loading = ref(false);

const updating = ref(false);
const showAll = ref(false);


const isupdate = ref(false);
const dataForm = ref(false);

const getdata = (data,type=false) => {
    isupdate.value = type
    dataform.value = data;
    console.log(dataform.value,'dataform')
}

const page = ref({
    page: 1,
    pageSize: 20
})

const dataResult = ref({
    results: [],
    total: 20
})

const params = reactive({
    deptId: null,
    status: 0,
    name: null,
    startDate: null,
    endDate: null
});

const reset = () => {
    params.value = {
        deptId: null,
        status: 0,
        name: null,
        startDate: null,
        endDate: null
    };
    loadList();
}

const onPageChange = ({currentPage, pageSize}) => {
    page.value.page = currentPage;
    page.value.pageSize = pageSize;
    loadList();
}

setting.accountingCategoryDetails.loadAuxiliaryAccountingDataByName(['DEPARTMENT', 'VENDOR']).then(({data}) => {
    deptOptions.value = data['DEPARTMENT'].map(val => {
        return {
            key: val.id,
            accountingCategoryId: val.categoryId,
            title: val.name
        }
    });
})

const loadList = () => {
    loading.value = true
    if (params.deptId === null) deptCheckName.value = '所有员工'
    else deptOptions.value.filter(value => value.key === params.deptId).map(item => {
        if (item.title) deptCheckName.value = item.title
    })
    Employee.list(Object.assign({}, params, page.value)).then(({data}) => {
        // dataResult.value = data;
        dataResult.value = {
            page: 1,
            pageSize: 20,
            total: 1,
            totalPage: 1,
            results: [
                {
                    id: '1',
                    code: '001',
                    name: '测试1',
                    deptName: '行政部',
                    post: '员工',
                    mobile: '15763589658',
                    email: '1576129658@qq.com',
                    idCard: '412545785451245632',
                    status: '在职',
                    data1: '博士',
                    data2: '博士',
                    data3: '管理人员职工薪酬',
                    data4: '建行',
                    data5: '12312312312312323123123',
                    data6: '1999-12-12',
                    data7: '1999-12-12',
                    data8: '1999-12-12',
                    data9: '',
                    data10: '',
                    data11: '是',
                    data12: '10%',
                    data13: '10%',
                    data14: '10%',
                    data15: '10%',
                    data16: '29.12',
                    data17: '5000',
                    data18: '120',
                    data19: '44.12',
                    data20: '69.2',
                    data21: '100',
                    data22: '99.12',
                    data23: '28,43',
                    data24: '29.21',
                    data25: '38.12',
                    data26: '120',
                    data27: '10.21',
                    data28: '5000',
                    data29: '120',
                    data30: '123123123123'
                },
                {
                    id: '2',
                    code: '002',
                    name: '测试2',
                    deptName: '合计',
                    post: '组长',
                    mobile: '15763589658',
                    email: '15763389658@qq.com',
                    idCard: '412541232312345632',
                    status: '离职',
                    data1: '本科',
                    data2: '学士',
                    data3: '管理人员职工薪酬',
                    data4: '邮政',
                    data5: '344444423222222233333332',
                    data6: '1999-12-12',
                    data7: '1999-12-12',
                    data8: '1999-12-12',
                    data9: '1999-12-12',
                    data10: '1999-12-12',
                    data11: '是',
                    data12: '10%',
                    data13: '10%',
                    data14: '10%',
                    data15: '22.22',
                    data16: '29.12',
                    data17: '5000',
                    data18: '120',
                    data19: '44.12',
                    data20: '69.2',
                    data21: '100',
                    data22: '99.12',
                    data23: '28,43',
                    data24: '29.21',
                    data25: '38.12',
                    data26: '120',
                    data27: '10.21',
                    data28: '5000',
                    data29: '120',
                    data30: '1233333333333333'
                },
            ]
        }
        paramsDropdown.value.hide();
    }).finally(() => loading.value = false)
}
loadList();

const printClickItem = (key) => {
    window.open("/api/pdf/journal/list/" + account.value.id + "?" + Qs.stringify(Object.assign(params, page.value)))
}

const exportJournal = () => {
    window.open("/api/cashier/journal/download/" + account.value.id + "?" + Qs.stringify(Object.assign(params, page.value)))
}

// 导入接口
const importJournal = () => {

}

// 打开弹框
const showForm = (data) => {

    console.log(data)
    isupdate.value = !isupdate.value
    console.log(isupdate.value)
    if (data!=undefined)
    dataForm.value = data;
    // let layerId = layer.drawer({
    //   title: "员工信息",
    //   shadeClose: false,
    //   closeBtn: false,
    //   zIndex: 1000,
    //   area: ['1000px', '100vh'],
    //   content: h(EmployeeForm, {
    //     id: data,
    //     onClose: () => {
    //       layer.close(layerId);
    //     },
    //     onSuccess: () => {
    //       this.loadList();
    //       layer.close(layerId);
    //     }
    //   })
    // });
}

const doTrash = (id) => {
    confirm({
        content: `确认删除此记录?`,
        onConfirm: () => {
            // loading.value = true;
            // Employee.delete(id).then(() => {
            //   loadList();
            // }).finally(() => loading.value = false)
        }
    })
}

// 个税申报接口
const individualDeclaration = () => {

}

watch(showAll, () => {
    setTimeout(() => {
        journalTable.value.refreshColumn();
    }, 200)
})
</script>

<style lang="less" scoped>

</style>
