<template>
	<div class="modal-column">
		<div class="flex-1 voucher-list p-16px">
			<table class="details" v-for="data in voucherList" :key="data.id">
				<tr class="details-header">
					<td colspan="4">日期：{{ data.voucherDate }} 凭证字号：{{ data.word }}-{{ data.code }}
						<template v-if="data.receiptNum > 0">
							<DropdownCustom :toggle-icon="false" class-name="h-text-dropdown">
                <span class="ml-8px text-hover primary-color">
                <i class="fa fa-paperclip"></i> {{ data.receiptNum }}
                </span>
								<template #content>
									<div class="w-400px">
										<div class="h-150px">
											<vxe-table ref="billListTable" :data="data.billList" :show-header="false" height="auto">
												<vxe-column field="name">
													<template #default="{row}">
														<a :href="'/api'+row.url+'?name='+row.name" :download="row.name" target="_blank">{{ row.name }}</a>
													</template>
												</vxe-column>
											</vxe-table>
										</div>
									</div>
								</template>
							</DropdownCustom>
						</template>
					</td>
				</tr>
				<tr v-for="d in data.details" :key="d.id">
					<td style="width: 215px">
						{{ d.summary }} <span v-if="d.currencyName && d.currencyId !== localCurrency.id">({{ d.currencyName }}：{{ d.originalAmount }}，汇率：{{ d.exchangeRate }})</span>
						<template v-if="d.subject&&d.num&&d.price">
							(数量:{{ d.num }}<span class="dark4-color">{{ d.subject.unit }}</span>，单价:{{ d.price }}<span class="dark4-color">元</span>)
						</template>
					</td>
					<td>{{ d.subjectName }}<span v-if="d.auxiliaryDetailId">_{{ d.auxiliaryTitle }}</span></td>
					<td align="right" style="width: 130px">{{ numFormat(d.debitAmount) }}</td>
					<td align="right" style="width: 130px">{{ numFormat(d.creditAmount) }}</td>
				</tr>
				<tr class="font-bold">
					<td>合计</td>
					<td>{{ dxMoney(data.debitAmount) }}</td>
					<td align="right">{{ numFormat(data.debitAmount) }}</td>
					<td align="right">{{ numFormat(data.creditAmount) }}</td>
				</tr>
			</table>
		</div>
		<div class="modal-column-btn">
			<Button :loading="loading" color="" @click="$emit('back')">上一步</Button>
			<Button @click="$emit('check')">下一步</Button>
		</div>
	</div>
</template>
<script>
import {mapGetters, mapState} from "vuex";

export default {
	name: "ProfitCheckList",
	computed: {
		...mapState(['User', 'currentAccountSets']),
		...mapGetters(['checkoutSelect', 'localCurrency']),
	}
}
</script>

<script setup>

import voucher from "@api/voucher";
import {ref} from "vue";

let props = defineProps(['voucherIds', 'checkData']);

const voucherList = ref([])

const loadVouchers = () => {
	Promise.all(
			props.voucherIds.map((val) => {
				return voucher.load(val)
			})
	).then((data) => {
		voucherList.value = data.map(val => val.data);
	})
}
loadVouchers()
</script>
