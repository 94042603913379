<template>
  <div style="padding: 20px;">
      <div class="toptitle">
        税务申报（安徽省税务局）
      </div>
      <Row :space="20">
        <Cell width="6" v-for="item,index in taxlist" :key="index">
           <div class="box1" @click="toFinan(item.isopen)">
             <span :class="item.isopen ? 'col1':''">{{item.name }}</span>
             <img  class="fiximg" :src="item.isopen ? open:unopen"  alt="">
           </div>

        </Cell>
       
      </Row>
      <div class="tips">
        <span class="red-color">个税申报地区：</span>
        <span>全国</span>
      </div>
      <div>
        <span class="red-color">财务报表申报地区：</span>
        <span>深圳市、广东省、安徽省、河南省、上海市、天津市、重庆市、青岛市、海南省、北京市、江西省、湖南省、建省、河北省、山东省、吉林省、陕西驾、贵州省、辽宁省、四川省、湖北省、黑龙江省
、西藏自治区、厦门市。其他地区将陆续开放，敬请期待!</span>
      </div>
  </div>
</template>
<script>
  import {mapMutations, mapState} from 'vuex';
  import EnterpriseWorkbench from '@/api/EnterpriseWorkbench'


  export default {
    name: 'ApplicationList',
    data() {
      return {
        open:require('@/assets/open.png'),
        unopen:require('@/assets/unopen.png'),
        taxlist:[
           {name:'个税申报',isopen:false,},
           {name:'财务报表申请',isopen:false},
           {name:'增值税申报',isopen:true},
           {name:'消费税申报',isopen:true},
           {name:'附加税申报',isopen:true},
           {name:'企业所得税预缴申报',isopen:true},
           {name:'印花税申报',isopen:true},
           {name:'房产税申报',isopen:true},
        ],
       
      };
    },
    computed: {
      ...mapState(['currentAccountSets', 'currentTab']),
    },
    methods: {
      ...mapMutations(['newTab']),
      
      toFinan(type){
        console.log('11',type)
        if(type){
          this.newTab({
            title: '财务报表申报',
            key: 'FinancialReport'
          })
        }else{
          this.newTab({
            title: '财务报表申报(无信息)',
            key: 'FinancialReportUn'
          })
        }
        
      },
    

    },
    created() {
     
    },
  };
</script>

<style lang="less" scoped>
  .toptitle{
    font-size: 32px;
    font-weight: bold;
    color: #333333;
    width: 100%;
    text-align: center;
    margin: 20px 0;
  }
  .box1{
    // width: 390px;
    height: 175px;
    background: #FFFFFF;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    border: 1px solid #D9D9D9;
    position: relative;
    font-size: 30px;
    font-weight: 400;
    color: #999;
    display: flex;
    justify-content: center;
    align-items: center;
    .col1{
      color: #333;
    }
    .fiximg{
      position: absolute;
      bottom: 0;
      right: 0;
      width: 90px;
      height: 90px;
    }
  }
  .tips{
    margin-top: 16px;
    line-height: 30px;
    display: flex;
   flex-wrap: wrap;  
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    span{
      display: inline-block;
    }
  }

</style>

