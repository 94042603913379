<template>
  <div class="h-full p-20px">
    <vxe-table  height="auto" ref="xTable" :loading="loading" show-overflow stripe :data="dataList">
      <vxe-column title="日期" field="checkDate" width="100"/>
      <vxe-column title="凭证号" field="voucherTitle" width="100"/>
      <vxe-column title="摘要" field="digest" width="200"/>
      <vxe-colgroup align="center" title="原值">
        <vxe-column title="借方" align="right" width="100" field="originalDebit" formatter="toMoney"/>
        <vxe-column title="贷方" align="right" width="100" field="originalCredit" formatter="toMoney"/>
        <vxe-column title="余额" align="right" width="100" field="originalBalance" formatter="toMoney"/>
      </vxe-colgroup>
      <vxe-colgroup align="center" title="累计折旧">
        <vxe-column title="借方" align="right" width="100" field="depreciationDebit" formatter="toMoney"/>
        <vxe-column title="贷方" align="right" width="100" field="depreciationCredit" formatter="toMoney"/>
        <vxe-column title="余额" align="right" width="100" field="depreciationBalance" formatter="toMoney"/>
      </vxe-colgroup>
      <vxe-colgroup align="center" title="减值准备">
        <vxe-column title="借方" align="right" width="100" field="impairmentDebit" formatter="toMoney"/>
        <vxe-column title="贷方" align="right" width="100" field="impairmentCredit" formatter="toMoney"/>
        <vxe-column title="余额" align="right" width="100" field="impairmentBalance" formatter="toMoney"/>
      </vxe-colgroup>
      <vxe-column title="净值" align="right" width="100" field="netValue" formatter="toMoney"/>
      <vxe-column title="使用状况" field="useState" width="100"/>
      <vxe-column title="使用部门" field="deptName" width="100"/>
    </vxe-table>
  </div>
</template>

<script>/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
import Assets from "@/api/Assets";

export default {
  name: "AssetsDetails",
  props: {
    assets: Object
  },
  data() {
    return {
      filterVal: null,
      loading: false,
      dataList: []
    }
  },
  methods: {
    loadList() {
      this.loading = true
      Assets.detail(this.assets.id).then(({data}) => {
        this.dataList = data || [];
      }).finally(() => this.loading = false)
    }
  },
  created() {
    this.loadList();
  }
}
</script>
