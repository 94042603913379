<template>
	<a-accounting-top-bar
					v-model:filter="filter"
					@showForm="showForm"
					:category="category"
					@search="doSearch"/>
	<div class="flex-1">
		<vxe-table  :loading="loading" height="auto" :data="dataList">
			<vxe-column title="编码" field="code"/>
			<vxe-column title="名称" field="name"/>
			<vxe-column title="助记码" field="mnemonics"/>
			<vxe-column title="组织地址" field="address"/>
			<vxe-column title="备注" field="remark"/>
			<vxe-column title="操作" width="120">
				<template #default="{row}">
					<div class="actions">
						<span class="h-icon-edit" @click="showForm(row)"> 编辑</span>
						<span class="h-icon-trash" @click="remove(row)"> 删除</span>
					</div>
				</template>
			</vxe-column>
		</vxe-table>
	</div>
	<vxe-pager v-model:current-page="pageInfo.page" v-model:page-size="pageInfo.pageSize" v-model:total="pageInfo.total"/>
</template>

<script>
/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
import AAccountingTopBar from "@/views/setting/AssistingAccounting/AAccountingTopBar.vue";

export default {
	name: "Organization",
	components: {AAccountingTopBar}
}
</script>

<script setup>
import {ref, defineProps, h} from "vue";
import {usePageInject} from "./AAccountingCrud";
import {Organization} from "@/api/Auxiliary";
import {layer} from "@layui/layer-vue";
import OrganizationForm from "@/views/setting/AssistingAccounting/form/OrganizationForm.vue";
import {confirm} from "heyui.ext";

defineProps(['category'])
const dataList = ref([]);
const filter = ref(null);

const loadList = () => {
	return Organization.list(Object.assign({filter: filter.value}, pageParams.value)).then(({data}) => {
		dataList.value = data.results;
		pageInfo.total = data.total;
	})
}

const {
	pageInfo,
	doSearch,
	pageParams,
	loading
} = usePageInject(loadList);

doSearch();

const showForm = (entity) => {
	let layerId = layer.open({
		title: "组织机构信息",
		shadeClose: false,
		closeBtn: false,
		zIndex: 1000,
		area: ['400px', 'auto'],
		content: h(OrganizationForm, {
			entity,
			onClose: () => {
				layer.close(layerId);
			},
			onSuccess: () => {
				layer.close(layerId);
				doSearch();
			}
		})
	});
}


const remove = (entity) => {
	confirm({
		content: `确认删除${entity.name}？`,
		async: true,
		onConfirm: (resolve) => {
			Organization.delete(entity.id).then(() => {
				resolve(true)
				loadList();
			}).catch(() => resolve(false))
		}
	})
}
</script>
