<template>
  <vxe-column v-if="isUnit" title="数量" field="beginQuantity" align="right" width="90">
    <template #default="{row}">
      <vxe-input @blur="valChange(row,'beginQuantity')" v-if="canEdit(row)" type="float" align="right" v-model.number="row.beginQuantity" :controls="false" size="mini"/>
      <span v-else>{{ row.beginQuantity }}</span>
    </template>
  </vxe-column>
  <vxe-column v-if="isFor&&!isLocal" title="原币" field="beginForBalance" align="right" width="120">
    <template #default="{row}">
      <vxe-input @blur="valChange(row,'beginForBalance')" v-if="canEdit(row)" type="float" align="right" v-model.number="row.beginForBalance" :controls="false"/>
      <span v-else>{{ row.beginForBalance }}</span>
    </template>
  </vxe-column>
  <vxe-column :title="colTitle" field="beginBalance" align="right" width="120">
    <template #default="{row}">
      <vxe-input @blur="valChange(row,'beginBalance')" v-if="canEdit(row)" type="float" align="right" v-model.number="row.beginBalance" :controls="false" size="mini"/>
      <span v-else>{{ row.beginBalance }}</span>
    </template>
  </vxe-column>
</template>

<script>
/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
export default {
  name: "BeginCol",
  props: {
    valChange: Function,
    isUnit: Boolean,
    isFor: Boolean,
    isStartYear: Boolean,
    beginCheck: Boolean,
    isLocal: Boolean,
    type: String,
  },
  computed: {
    colTitle() {
      if (this.isFor) {
        return '本位币';
      }
      return this.isUnit ? '金额' : '期初余额'
    }
  },
  methods: {
    canEdit(row) {
      return !this.beginCheck && (this.type !== '损益' && (row.auxiliaryDetailId !== '0' || (row.leaf && !this.isStartYear && row.auxiliaryAccounting.length === 0)))
    }
  },
}
</script>
