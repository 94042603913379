<template>
	<div class="h-full p-20px flex flex-column">
		<div class="text-center  pb-16px font-bold !text-20px" v-if="report">
			{{ report.name }}
			{{ map[dimension] }}
			<!-- {{view}} -->
		</div>
		<div class="flex-1 bg-white-color flex flex-column !h-0px" v-if="view">
			<component v-if="loading" :is="view" :report="report" :dimension="dimension" :reportId="reportId"/>
		</div>
	</div>
</template>

<script>
import report from "@/api/report";
import {layer} from "@layui/layer-vue";
import ZcfzbReportView from "@/views/report/view/ZcfzbReportView";
import XjllbReportView from "@/views/report/view/XjllbReportView";
import CwgyxxReportView from "@/views/report/view/CwgyxxReportView";
import BmlrReportView from "@/views/report/view/BmlrReportView";
import LrbReportView from "@/views/report/view/LrbReportView";
import XmlrReportView from "@/views/report/view/XmlrReportView";
import EventBus from "@/js/common/EventBus";

export default {
	name: "ReportView",
	components: {XmlrReportView, LrbReportView, BmlrReportView, CwgyxxReportView, XjllbReportView, ZcfzbReportView},
	props: {
		dimension: {
			type: String,
			default: 'TERM'
		},
		reportId: [String]
	},
	data() {
		return {
			report: {},
			map: {
				"TERM": "",
				"QUARTER": "季报"
			},
			// viewMap: {
			// 	xmlr: 'XmlrReportView',
			// 	lr: 'ZcfzbReportView',
			// 	bmlr: 'BmlrReportView',
			// 	cwgyxx: 'CwgyxxReportView',
			// 	xjll: 'ZcfzbReportView',
			// 	zcfz: 'ZcfzbReportView',
			// 	zcfzbcfl: 'ZcfzbReportView',
			// },
			viewMap: {
				xmlr: 'XmlrReportView',
				lr: 'ZcfzbReportView',
				bmlr: 'BmlrReportView',
				cwgyxx: 'CwgyxxReportView',
				xjll: 'ZcfzbReportView',
				zcfz: 'ZcfzbReportView',
				zcfzbcfl: 'ZcfzbReportView',
			},
			
			view: null,
			loading: true
		}
	},
	created() {
		this.loadReport();
		EventBus.off("report.reload");
		EventBus.on("report.reload", () => {
			this.loading = false;
			this.$nextTick(() => {
				this.loadReport();
			})
		})
	},
	methods: {
		loadReport() {
			const loadId = layer.load(2);
			console.log(this.reportId,'this.reportId')
			report.template.load(this.reportId).then(({data}) => {
				this.report = data;
				this.loading = true;
				this.view = this.viewMap[data.templateKey];
				console.log(this.view ,'this.view ')
			}).finally(() => layer.close(loadId));
		}
	},
	unmounted() {
		EventBus.off("report.reload");
	}
}
</script>
