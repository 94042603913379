<template>
  <div class="v-table-td-subject ">
   
    <DropdownCustom ref="dpcRef"
                    class="v-table-td-subject-dpc "
                    equalWidth
                    v-if="editing"
                    :toggleIcon="false"
                    trigger="manual">
    <textarea
        ref="textareaRef"
        placeholder="请输入科目名称/科目编码/科目名称拼音首字母快速搜索"
        v-model="subjectVal"
        @input="onSubjectValChange"
        @blur="canEditing&&endEdit()"
        @keydown.tab.prevent="onEnter"
        @keydown.enter.prevent="onEnter"
        @keydown.left="moveTab(-1)"
        @keydown.right="moveTab(1)"
        @keydown.up="moveSelect(-1)"
        @keydown.down="moveSelect(1)"
        class="v-table-td-subject-edit "
    ></textarea>
      <template #content>
        <!-- :style="{top:((voucherDetailList.length-rowIndex-1)*69)+'px'}" -->
        <div ref="subjectListRef" class="v-table-td-subject-list fixbtm2"   @mouseenter="canEditing=false" @mouseleave="onMouseleave">
          <div class="type-tabs" ref="tabsRef">
            <div :class="subjectType==='全部'?'selected':''" @click="subjectType='全部'">全部</div>
            <div v-for="type in store.state.subjectType" @click="subjectType=type" :class="subjectType===type?'selected':''">{{ type }}</div>
          </div>
          <div class="v-table-td-subject-list-content">
            <ul ref="subjectUlRef">
              <li :class="{'subject-selected':filterVal===s.code}" @click="selectSubject" :data-code="s.code"
                  :data-id="s.id" v-for="s in subjectListFilter" :key="s.code">{{ s.code }} {{ s.fullName }}
              </li>
            </ul>
          </div>
          <div @click="newSubject" class="text-hover text-center py-4px">
            <i class="fa fa-plus-circle"></i> 添加科目
          </div>
        </div>
      </template>
    </DropdownCustom>
    <div v-else class="p-8px h-full v-table-td-subject-text" @click="doEdit">
      <template v-if="subjectObj">
        {{ subjectObj.code }} {{ subjectObj.fullName }}
        <template v-if="auxiliary">
          <span v-for="ax in auxiliary">{{ ax.code }}-{{ ax.name }}</span>
        </template>
        <div class="addon" @click.stop="void(0)" v-if="hasAuxiliary">
          <Tooltip content="辅助项">
            <i @click="setAuxiliary" class="fa  fa-vcard text-hover"></i>
          </Tooltip>
        </div>
      </template>
    </div>
    <div v-if="subjectBalance" class="v-table-td-subject-balance" :class="subjectBalance<0?'red-color':''">
      余额：{{ numFormat(subjectBalance) }}
    </div>
    <div @click.stop="void(0)" v-if="subjectObj && (subjectObj.unit || hasCurrency)" class="v-table-td-subject-unit-currency">
      <div v-if="subjectObj.unit" class="text-hover">
        <DropdownCustom :toggleIcon="false" ref="unitDp">
          数量：{{ detail.num || 0 }} {{ subjectObj.unit }}，单价：{{ detail.price || 0 }}
          <template #content>
            <div class="h-input-group">
              <span class="h-input-addon">数量：</span>
              <NumberInput autofocus @change="numChange" v-model.number="detail.num" placeholder="请输入数量"/>
              <span class="h-input-addon">{{ subjectObj.unit }}</span>
            </div>
            <div class="h-input-group mt-5px">
              <span class="h-input-addon">单价：</span>
              <NumberInput @change="numChange" v-model.number="detail.price" placeholder="请输入单价"/>
            </div>
          </template>
        </DropdownCustom>
      </div>
      <div class="mt-5px text-hover" v-if="hasCurrency">
        币别：3 ，汇率：13，原币：1
      </div>
    </div>
  </div>
</template>
<script setup>

import {computed, getCurrentInstance, h, nextTick, onMounted, ref, watch} from "vue";
import {useStore} from "vuex";
import {getVTable} from "@components/VoucherTable/VtUtil";
import {scrollIntoView} from "heyui.ext";
import VTableAuxiliary from "@components/VoucherTable/VTableAuxiliary.vue";
import {layer} from "@layui/layer-vue";
import SubjectForm from "@/views/setting/subject/SubjectForm.vue";
import setting from "@api/setting";
import {add, multiply, pick, round, subtract} from "xe-utils";

// import { useContext } from "vue";
// const ctx = useContext();
const emit = defineEmits(["newRowLastAdd"]);
const props = defineProps(['subject', 'rowIndex', 'subjectList', 'subjectMap', 'auxiliary', 'detail','voucherDetailList']);
const store = useStore();

const dpcRef = ref(null)
const textareaRef = ref(null)
const tabsRef = ref(null)
const subjectUlRef = ref(null)

const editing = ref(false)
const canEditing = ref(true)
const subjectVal = ref("")
const filterVal = ref(null)
const unitDp = ref(null)
const subjectType = ref("全部")
const subjectObj = ref(null)
const subjectBalance = ref(null)

const subjectOriginalBalance = ref(null)

let VTable = null;

watch(() => props.subject, (val) => {
	subjectObj.value = val;
	if (val) {
		subjectVal.value = val.code + " " + val.fullName;
	} else {
		subjectVal.value = "";
	}
}, {immediate: true})

watch(subjectObj, (subject) => {
	subject && loadSubjectBalance(subject.id);
})

watch(() => [props.detail.debitAmount, props.detail.creditAmount], (amount) => {
	if (props.detail.subject) {
		let orgVal = 0;
		switch (props.detail.subject.balanceDirection) {
			case '借':
				//原始值
				orgVal = subjectOriginalBalance.value + props.detail.originalCreditAmount - props.detail.originalDebitAmount;
				subjectBalance.value = round(subtract(add(orgVal, amount[0] || 0), amount[1] || 0), 2);
				break;
			case '贷':
				orgVal = subjectOriginalBalance.value - props.detail.originalCreditAmount + props.detail.originalDebitAmount;
				subjectBalance.value = round(subtract(add(orgVal, amount[1] || 0), amount[0] || 0), 2);
				break;
		}
	}
})

/**
 * 是否带辅助
 * @type {ComputedRef<boolean>}
 */
const hasAuxiliary = computed(() => {
	return subjectObj.value && subjectObj.value.auxiliaryAccounting && subjectObj.value.auxiliaryAccounting.length;
})
/**
 * 是否带外币
 * @type {ComputedRef<boolean>}
 */
const hasCurrency = computed(() => {
	return subjectObj.value && subjectObj.value.currencyAccounting && subjectObj.value.currencyAccounting.length;
})

function filter(val) {
	if (filterVal.value) {
		return (val.code.indexOf(filterVal.value) !== -1 ||
				val.fullName.indexOf(filterVal.value) !== -1 ||
				val.mnemonicCode.indexOf(filterVal.value) !== -1);
	}
	return true;
}

/**
 * 科目过滤
 * @type {ComputedRef<unknown>}
 */
const subjectListFilter = computed(() => {
	if (subjectType.value === '全部') {
		if (filterVal.value) {
			return props.subjectList.filter(val => filter(val));
		}
		return props.subjectList;
	}
	return props.subjectList.filter(val => val.type === subjectType.value && filter(val));
})

/**
 * 开启科目编辑
 */
const doEdit = () => {
  console.log('666',props.voucherDetailList)
	editing.value = true;
  
	nextTick(() => {
		setTimeout(() => {
			dpcRef.value.show();
			nextTick(() => {
				textareaRef.value.focus();
				textareaRef.value.select();
			})
		}, 100);
	})
}

/**
 * 科目输入过滤值
 * @param e
 */
const onSubjectValChange = (e) => {
  console.log('11')
	filterVal.value = e.target.value;
}

/**
 * 结束编辑
 * @returns {boolean}
 */
const endEdit = () => {
  console.log('22',props.voucherDetailList[props.voucherDetailList.length-1].summary)
  console.log('22',props.voucherDetailList)

  // 判断最后一行是否为空，是就加一行，不是就不处理
  if(props.voucherDetailList && props.voucherDetailList[props.voucherDetailList.length-1].subject != null){
      emit("newRowLastAdd", props.voucherDetailList.length);
  }else{
    
  }
 
  // console.log(props.voucherDetailList[4],'22')
  // $parent.fatherMethod();
  // ctx.emit('fatherMethod')
   
	editing.value = false;
	subjectType.value = '全部'
	filterVal.value = null
	dpcRef.value.hide();
	if (hasAuxiliary.value && (!props.auxiliary || !props.auxiliary.length)) {
		setAuxiliary();
		return false;
	}
	if (subjectObj.value && subjectObj.value.unit) {
		setTimeout(() => {
			unitDp.value.show();
		}, 300)
		return false;
	}
	return true;
}

/**
 * 回车和Tab键操作
 */
const onEnter = () => {
  console.log('33')

	let selector = subjectUlRef.value.querySelector('.subject-selected');
	setValueAndGoNext(selector)
}

/**
 * 鼠标选择科目
 * @param e
 */
const selectSubject = (e) => {
	setValueAndGoNext(e.target)
}

/**
 * 赋值选择科目
 * @param selector
 */
const setValueAndGoNext = (selector) => {
	let next = 'debitAmount';
	if (selector) {
		let newSubject = props.subjectMap[selector.dataset.id];

		if (subjectObj.value && newSubject.id !== subjectObj.value.id) {
			//修改了科目清除已选择的辅助项目
			VTable.exposed.updateFiled(props.rowIndex, 'auxiliary', []);
		}

		subjectObj.value = newSubject;
		VTable.exposed.updateFiled(props.rowIndex, 'subject', subjectObj.value);

		subjectVal.value = subjectObj.value.code + " " + subjectObj.value.fullName;
		if (subjectObj.value.balanceDirection === '贷') {
			next = 'creditAmount';
		}
	}
	if (endEdit()) {
		VTable.exposed.next(props.rowIndex, next);
	}
}

/**
 * 鼠标移出科目选择区域
 */
const onMouseleave = () => {
	canEditing.value = true;
	textareaRef.value.focus();
}

/**
 * 切换科目分类过滤
 * @param step
 */
const moveTab = (step) => {
  console.log('44')

	let selector = tabsRef.value.querySelector('.selected');
	let sibling;
	if (step > 0) {
		sibling = selector.nextElementSibling
	} else {
		sibling = selector.previousElementSibling
	}

	if (sibling) {
		subjectType.value = sibling.innerText.trim();
		sibling.classList.add('selected')
		selector.classList.remove('selected')
	}
}

/**
 * 科目选择键盘方向键控制
 * @param step
 */
const moveSelect = (step) => {
  console.log('55')

	let selector = subjectUlRef.value.querySelector('.subject-selected');
	let sibling;

	if (selector) {
		if (step > 0) {
			sibling = selector.nextElementSibling
		} else {
			sibling = selector.previousElementSibling
		}
		if (sibling) {
			sibling.classList.add('subject-selected')
			selector.classList.remove('subject-selected')
			scrollIntoView(sibling, {
				time: 500, // 动画时间
			})
		}
	} else {
		sibling = subjectUlRef.value.children[0];
		sibling && sibling.classList.add('subject-selected');
	}
}

/**
 * 设置科目辅助项目
 */
const setAuxiliary = () => {
	let layerId = layer.open({
		title: subjectObj.value.code + " " + subjectObj.value.fullName,
		shadeClose: false,
		closeBtn: false,
		zIndex: 1000,
		area: ['500px', 'auto'],
		content: h(VTableAuxiliary, {
			subject: subjectObj.value,
			auxiliary: props.auxiliary,
			onClose: () => {
				layer.close(layerId);
			},
			onSuccess: (auxiliary) => {
				VTable.exposed.updateFiled(props.rowIndex, 'auxiliary', auxiliary || []);
				layer.close(layerId);
				VTable.exposed.next(props.rowIndex, subjectObj.value.balanceDirection === '贷' ? 'creditAmount' : 'debitAmount');
				loadSubjectBalance(subjectObj.value.id)
			}
		})
	});
}

const newSubject = () => {
	let layerId = layer.drawer({
		title: "科目信息",
		shadeClose: false,
		closeBtn: false,
		area: ['600px', '100vh'],
		zIndex: 1000,
		content: h(SubjectForm, {
			faster: true,
			onClose: () => {
				layer.close(layerId);
				doEdit();
			},
			onSuccess: (code) => {
				layer.close(layerId);
				filterVal.value = code;
				VTable.exposed.loadSubject();
				doEdit();
			}
		})
	});
}

const loadSubjectBalance = (subjectId) => {
	setting.subject.balance({
		currencyId: props.detail.currencyId,
		subjectId: subjectId,
		acDetails: (props.auxiliary || []).map(val => pick(val, ['id', 'categoryType', 'categoryId']))
	}).then(({data}) => {
		subjectBalance.value = data;
		subjectOriginalBalance.value = data;
	})
}

const numChange = () => {
	let d = props.detail;
	let field = 'debitAmount';
	if (d.creditAmount || (!d.creditAmount && d.subject.balanceDirection === '贷')) {
		field = 'creditAmount';
	}
	props.detail[field] = round(multiply(d.num, d.price), 2)
}

onMounted(() => {
	VTable = getVTable(getCurrentInstance())
})

defineExpose({
	doEdit,
	endEdit
})
</script>
<style scoped lang="less">
.v-table {

  &-td {
    &-subject {
      flex: 1;
      border-right: @border;
      font-weight: bold;
      position: relative;

      &-balance {
        position: absolute;
        bottom: 0;
        left: 8px;
        font-size: 12px;
      }

      &-unit-currency {
        position: absolute;
        bottom: 0;
        right: 0;
        padding: 5px 5px 0;
        font-weight: normal;
        z-index: 1;
        text-align: right;
      }

      &-dpc {
        display: block;
        height: 100%;

        :deep(.h-dropdowncustom-show) {
          height: 100%;
        }

        :deep(.h-dropdowncustom-show-content) {
          height: 100%;
          display: block;
        }
      }

      &-edit {
        resize: none;
        outline: none;
        height: 100%;
        width: 100%;
        overflow: hidden;
        font-size: 12px;
        padding: 8px;
        border: 1px solid @green-color;
      }

      &-list {
        font-size: 13px;

        &-content {
          height: 200px;
          overflow-y: auto;
          border-bottom: @border;

          li {
            padding: 4px 8px;
            cursor: pointer;

            &:hover {
              background-color: @gray3-color;
            }
          }
        }
      }

      &-text {
        position: relative;

        .addon {
          position: absolute;
          right: 0;
          top: 0;

          i {
            margin: 5px;
            font-size: 14px;
          }

          .fa-vcard {
            color: #ff6600;
          }

          .fa-money {
            color: var(--primary);
          }
        }
      }
    }
  }
}

.type-tabs {
  display: flex;
  border-bottom: @border;

  div {
    flex: 1;
    text-align: center;
    padding: 5px 0;

    &:hover {
      cursor: pointer;
      background-color: var(--primary);
      color: @white-color;
    }
  }

  .selected {
    background-color: var(--primary);
    color: @white-color;
  }
}

.subject-selected {
  background-color: @primary2-color;
}
.fixbtm{
  position:relative;
}
.fixbtm2{
  position:fixed;
  width:225px;
  left:0px;
  background:#fff;
}
</style>
