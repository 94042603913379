<template>
  <div class="h-full flex flex-column p-16px">
    <vxe-toolbar class-name="px-8px" :loading="loading" style="padding: 10px 0 30px;">
      <template #buttons>
        <div class="h-input-group h-input-group-auto">
          <span class="" style="margin-left: 20px;">日期：</span>
          <Select v-model="params.start" :datas="checkDataList" class="!w-120px" :deletable="false"/>
          <span class="h-input-addon">至</span>
          <Select v-model="params.end" :datas="checkDataList" class="!w-120px" :deletable="false"/>
          <span class="" style="margin-left: 20px;">类别：</span>
          <Select v-model="params.assetsTypeId" :datas="assetsTypeList" keyName="id" titleName="name" class="!w-150px"/>
          <span class="" style="margin-left: 20px;">编号：</span>
          <Input v-model="params.code" class="!w-80px"/>
          <span class="" style="margin-left: 20px;">部门：</span>
          <Select v-model="params.deptId" :datas="deptList" class="!w-130px"/>
          <Button :loading="loading" @click="doSearch" color="primary" icon="">查询</Button>
        </div>
      </template>
      <template #tools>
        <Button :loading="loading"  color="" icon="">导入</Button>
        <Button :loading="loading"  color="" icon="">打印</Button>
      </template>
    </vxe-toolbar>
    <div class="flex-1">
      <vxe-table height="auto" :loading="loading" :data="dataList">
        <vxe-column title="会计期间" field="month" width="100"/>
        <vxe-column title="资产编号" field="code" width="80"/>
        <vxe-column title="资产类别" field="typeName" width="100"/>
        <vxe-column title="资产名称" field="name" width="100"/>
        <vxe-column title="规格型号" field="specification" width="120"/>
        <vxe-column title="使用部门" field="deptName" width="100"/>
        <vxe-column title="资产原值" field="originalValue" formatter="toMoney" width="120"/>
        <vxe-column title="本期折旧" field="current" formatter="toMoney" width="120"/>
        <vxe-column title="本年累计折旧" field="currentYear" formatter="toMoney" width="120"/>
        <vxe-column title="累计折旧" field="total" formatter="toMoney" width="120"/>
        <vxe-column title="减值准备" field="" width="120"/>
        <vxe-column title="资产净值" field="netValue" align="right" formatter="toMoney" width="120"/>
      </vxe-table>
    </div>
    <vxe-pager @page-change="loadList"
               v-model:current-page="pagination.page"
               v-model:page-size="pagination.pageSize"
               :total="pagination.total"
               :layouts="['PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'Sizes', 'FullJump', 'Total']">
      <template #left>
        <vxe-button @click="loadList()" type="text" size="mini" icon="fa fa-refresh" :loading="loading"></vxe-button>
      </template>
    </vxe-pager>
  </div>
</template>

<script>
/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
export default {
  name: "AssetsDepreciationList"
}
</script>

<script setup>
import Assets from "@/api/Assets";
import {reactive, ref} from "vue";
import setting from "@/api/setting";
import AssetsType from "@/api/AssetsType";
import {useStore} from "vuex";
import manba from "manba";

const store = useStore();
const loading = ref(false);
const currentAccountDate = manba(store.state.currentAccountSets.currentAccountDate).startOf(manba.MONTH).format();
const checkDataList = ref([])
const assetsTypeList = ref([])
const dataList = ref([])
const deptList = ref([])
const pagination = ref({
  page: 1,
  pageSize: 20,
  total: 0
})

const params = reactive({
  start: currentAccountDate,
  end: currentAccountDate,
  assetsTypeId: null,
  code: null,
  deptId: null,
})

Assets.checkDate().then(({data}) => {
  checkDataList.value = data;
})

setting.accountingCategoryDetails.loadAuxiliaryAccountingDataByName(['DEPARTMENT']).then(({data}) => {
  deptList.value = data['DEPARTMENT'].map(val => {
    return {
      key: val.id,
      accountingCategoryId: val.accountingCategoryId,
      title: val.name
    }
  });
})
AssetsType.list().then(({data}) => {
  assetsTypeList.value = data || [];
})

const loadList = () => {
  loading.value = true;
  Assets.Depreciation.detail(Object.assign({}, pagination.value, params))
      .then(({data: {rows, recordCount}}) => {
        if (rows && rows.length) {
          rows.forEach(row => {
            row['netValue'] = row.originalValue - row.total;
          })
        }
        dataList.value = rows;
        pagination.value.total = recordCount;
      }).finally(() => loading.value = false)
}
loadList();

const doSearch = () => {
  pagination.value.page = 1;
  loadList();
}
</script>
