<template>
    <div class="modal-column" style="top: 63px;left: 296.5px;z-index: 799;">
        <div style="display: flex;justify-content: space-between;padding: 10px;">
            <div></div>
            <Button style="width: auto;" color="primary" :loading="loading"   @click="showForm()">新增</Button>
        </div>
        <div class="zmsundialog-content">
            
            <div class="mpBox" style="width: auto;">
                <ul style="width: 100%;background: #ccc;" >
                    <li>
                        <vxe-table ref="journalTable"
                        :loading="loading"
                        :data="dataList"
                        align="center"
                        >
                                <vxe-column title="摘要" field="code" width="300" />
                                <vxe-column title="会计科目" field="name" width="300" />
                                <vxe-column title="取值" field="code" width="300" />
                                <vxe-column title="方向" field="code" width="100" />
                              
                               
                                <vxe-column title="操作" width="120" fixed="right">
                                    <template #default="{row}">
                                        <div class="actions" v-if="!row.init">
                                            <span v-if="!row.voucherId" @click="showForm(row)" class="fa">修改</span>
                                            <span v-if="!row.voucherId" @click="doTrash(row['id'])" class="fa">删除</span>
                                        </div>
                                    </template>
                                </vxe-column>
                            </vxe-table>
                        
                       </li>
                 </ul>
              
                <!-- <ul class="mpItList" :style="{height: viewContainerHeight + 'px'}">


                    <li>
                        <div class="pzContentXj"><span class="addNew" @click="showForm()" title="新增"></span></div>
                    </li>
                    <li v-for="(item, index) in dataList">
                        <div class="pzContent">
                            <div class="normalPanel">
                                <div class="pzName caigou">
                                    <span class="showText els_span">{{ item.name }}</span>
                                    <span class="editIcon" @click="showForm(item.id)" title="编辑"></span>
                                    <span class="deleteIcon" @click="remove(item.id)" title="删除"></span>
                                </div>
                                <div class="pzText">
                                    <div v-if="item.items.length === 1" class="invoucher-list show-text">
                                        <span class="show-tip">{{ item.items[0].voucher }}</span>
                                    </div>
                                    <div v-if="item.items.length > 1" class="invoucher-list show-text">
                                        <span class="show-tip" v-for="info in item.items" @mouseover="spanHover = true" @mouseleave="spanHover = false">{{ info.voucher }}</span>
                                    </div>
                                    <div v-if="item.items.length > 1 && spanHover" class="finjz_tooltip" >
                                        <div class="arrow" style="top: 5px;">
                                        </div>
                                        <div class="mBox"><span class="show-tip" v-for="info in item.items">{{ info.voucher }}</span></div>
                                    </div>
                                    <div class="money">
                                        <span>金额：</span>
                                        <span v-if="item.items.length > 0" class="price-wrap">{{ item.amount }}</span>
                                        <span v-else class="price-wrap">?</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="item.items.length > 0" style="padding: 0 15px">
                            <vxe-button class="th-btn-deep-ck" @click="doSelect(item)">查看凭证</vxe-button>
                            <vxe-button class="th-btn-deep-fz" @click="onClickItem(item)">生成凭证</vxe-button>
                        </div>
                        <div v-else style="padding: 0 15px">
                            <vxe-button class="th-btn-deep-pz" @click="onClickItem(item)">生成凭证</vxe-button>
                        </div>
                    </li>
                </ul> -->

                <!-- 侧边日期 -->
                <!-- <div style="width: 10%; float: right; margin-top: 20px">
                    <i v-if="ShowMonthList" class="vxe-icon-arrow-double-left" style="float: left" @click="chooseMonthType(false)"></i>
                    <i v-else class="vxe-icon-arrow-double-right" style="float: left" @click="chooseMonthType(true)"></i>
                    <div class="monthDateCont" v-if="ShowMonthList">
                        <div class="monthDateBox singleDate">
                            <div class="monthPick monthPickStart">{{ monthDataList.nowDate }}</div>
                        </div>
                        <ul class="monthList">
                            <li v-if="monthStart !== 0"><a class="monthItem prev_btn" @click="updateMonth(true)"><i class="vxe-icon-arrow-up"></i></a></li>
                            <li v-else><a class="monthItem prev_btn prev_disabled_btn"><i class="vxe-icon-arrow-up"></i></a></li>
                            <li v-for="(item, index) in monthDataList.results.slice(monthStart, monthEnd)">
                                <a v-if="item.substring(5) !== '1' && item === monthDataList.nowDate" class="monthItem active">{{ item.substring(5) + '月' }}</a>
                                <a v-else-if="item.substring(5) !== '1' && item !== monthDataList.nowDate" class="monthItem" @click="changeMonth(index)">{{ item.substring(5) + '月' }}</a>
                                <a v-else class="monthItem" @click="changeMonth(index)">{{ item.substring(5) + '月' }}<span class="year">{{ item.substring(0, 4) }}</span></a>
                            </li>
                            <li v-if="monthEnd !== monthDataList.results.length"><a class="monthItem prev_btn" @click="updateMonth(false)"><i class="vxe-icon-arrow-down"></i></a></li>
                            <li v-else><a class="monthItem prev_btn prev_disabled_btn"><i class="vxe-icon-arrow-down"></i></a></li>
                        </ul>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
    import voucher from "@/api/voucher";
    import {h, ref} from "vue";
    import {confirm, message} from "heyui.ext";
    import {layer} from "@layui/layer-vue";
    import CheckTemplateFormOne from "@/views/checkout/CheckTemplateFormOne.vue";
    import Assets from "@/api/Assets";
    import CheckVoucherForm from "@/views/checkout/CheckVoucherForm";
    import CheckTemp from "@/api/CheckTemp";
    import {mapState} from "vuex";
    import manba from "manba";

    /**
     * <p>****************************************************************************</p>
     * <p><b>Copyright © 2010-2023杭州财汇餐谋科技有限公司All Rights Reserved<b></p>
     * <ul style="margin:15px;">
     * <li>Description : </li>
     * <li>Version     : 1.0</li>
     * <li>Creation    : 2023年01月11日</li>
     * <li>@author     : ____′↘夏悸</li>
     * </ul>
     * <p>****************************************************************************</p>
     */
    export default {
        name: "CheckTemplate",
        computed: {
            ...mapState(['currentAccountSets']),
        },
        data() {
            return {
                loading: false,
                dataList: [],
                viewContainerHeight: window.innerHeight,
                ShowMonthList: true,
                monthDataList: {
                    results: [],
                    nowDate: null
                },
                monthStart: 0,
                monthEnd: 12,
                tipTop: 0,
                tipLeft: 0,
                spanHover: false,
                params: {
                    entryDate: null,
                }
            }
        },
        created() {
            this.params.entryDate = manba(this.currentAccountSets.currentAccountDate).format("YYYY-MM");
          this.loadList();
          this.queryMonthList();
        },
        mounted() {
            this.handleResize();
            window.addEventListener('resize', this.handleResize)
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.handleResize)
        },
        methods: {
            handleResize() {
                this.viewContainerHeight = window.innerHeight - 240
            },
            loadList() {
                this.loading = true;
                voucher.tpl(this.params).then(({data}) => {
                    this.dataList = data;
                    this.dataList = [
                        {
                            "id": 484662818994389423,
                            "code": "000001",
                            "name": "缴纳增值税",
                            "items": [
                                {
                                    "id": "484373741443221022",
                                    "accountSetsId": "469102069945528322",
                                    "category": "未开具发票",
                                    "tradeDate": "2023-08-16",
                                    "state": "正常",
                                    "type": "OUT",
                                    "businessType": "销售收入",
                                    "taxAmountExcluded": 0,
                                    "taxAmount": 0,
                                    "amount": 1000.21,
                                    "haveDetail": false,
                                    "invoiceBusinessTypeId": "433784496953978881",
                                    "voucherId": "484662818994389423",
                                    "voucher": "记-12"
                                },{
                                    "id": "484373741443221022",
                                    "accountSetsId": "469102069945528322",
                                    "category": "未开具发票",
                                    "tradeDate": "2023-08-16",
                                    "state": "正常",
                                    "type": "OUT",
                                    "businessType": "销售收入",
                                    "taxAmountExcluded": 0,
                                    "taxAmount": 0,
                                    "amount": 33,
                                    "haveDetail": false,
                                    "invoiceBusinessTypeId": "433784496953978881",
                                    "voucherId": "484678402687631796",
                                    "voucher": "记-13"
                                }
                            ]
                        },
                        {
                            "id": 483595025343579222,
                            "code": "000002",
                            "name": "采购",
                            "items": []
                        },
                        {
                            "id": 483595025343579333,
                            "code": "000003",
                            "name": "存款",
                            "items": [
                                {
                                    "id": "484373741443221022",
                                    "accountSetsId": "469102069945528322",
                                    "category": "未开具发票",
                                    "tradeDate": "2023-08-16",
                                    "state": "正常",
                                    "type": "OUT",
                                    "businessType": "销售收入",
                                    "taxAmountExcluded": 0,
                                    "taxAmount": 0,
                                    "amount": 32.66,
                                    "haveDetail": false,
                                    "invoiceBusinessTypeId": "433784496953978881",
                                    "voucherId": "484678402687631796",
                                    "voucher": "记-2"
                                }
                            ]
                        },
                        {
                            "id": 483595025343579444,
                            "code": "000004",
                            "name": "发放工资",
                            "items": []
                        },
                    ];
                    this.dataList.forEach(info => {
                        let total = 0;
                        if (info.items) {
                            info.items.forEach(i => {
                                total += i.amount;
                            })
                        }
                        info.amount = total;
                    })
                }).finally((() => this.loading = false))
            },

            showForm(entity) {
                let layerId = layer.open({
                    title: '结转模板',
                    shadeClose: false,
                    closeBtn: 1,
                    zIndex: 1000,
                    area: ['1000px', '65vh'],
                    content: h(CheckTemplateFormOne, {
                        entity,
                        onClose: () => {
                            layer.close(layerId);
                        },
                        onSuccess: () => {
                            this.loadList();
                            layer.close(layerId);
                        },
                        onBack: () => {
                            layer.close(layerId);
                        }
                    })
                });
            },

            remove(entity) {
                confirm({
                    content: "是否确认删除该结转模板?",
                    async: true,
                    onConfirm(resolve) {
                        voucher.deleteTpl(entity.id).then(() => {
                            resolve(true)
                            this.loadList();
                        }).catch(() => {
                            resolve(false)
                        })
                    }
                })
            },

            doSelect(row) {
                let layerId = layer.open({
                    title: "记账凭证",
                    shadeClose: false,
                    closeBtn: 1,
                    zIndex: 1000,
                    area: ['1200px', '90vh'],
                    content: h(CheckVoucherForm, {
                        dataId: row.items[0].voucherId,
                        dataIds: row.items,
                        onClose: () => {
                            layer.close(layerId);
                        },
                        onSuccess: (voucherId) => {
                            this.loadList();
                            layer.close(layerId);
                        }
                    })
                });
            },

            onClickItem(row) {
                // 接口数据暂时注释
                // CheckTemp.getCheck(row.id).then(({data}) => {
                //     row.items = data.items;
                // }).finally()
                row = {
                    "id": 484662818994389423,
                    "code": "000001",
                    "name": "缴纳增值税",
                    "type": 1,
                    "items": [
                        {
                            "summary": "办公费_增值税专用发票1",
                            "subjectId": "433708449910640641",
                            "subjectTitle": "1001-库存现金",
                            "direction": 1,
                            "auxiliary": null,
                            "transfer": null,
                            "retrieval": null,
                            "percentage": null,
                            "formula": [
                                {
                                    "calculation": "+",
                                    "subjectId": "433708449910640641",
                                    "subjectTitle": "1001-库存现金",
                                    "auxiliary": null,
                                    "accessRules": "余额",
                                    "operation": "*",
                                    "percentage": "100",
                                },
                                {
                                    "calculation": "+",
                                    "subjectId": "433708450380402697",
                                    "subjectTitle": "1122-应收账款",
                                    "auxiliary": null,
                                    "accessRules": "找平",
                                    "operation": "/",
                                    "percentage": "20",
                                }
                            ]
                        },
                        {
                            "summary": "办公费_增值税专用发票2",
                            "subjectId": "433708450254573570",
                            "subjectTitle": "1002-银行存款",
                            "direction": 2,
                            "auxiliary": null,
                            "transfer": null,
                            "retrieval": null,
                            "percentage": null,
                            "formula": []
                        }
                    ]
                };
                let layerId = layer.open({
                    title: "记账凭证",
                    shadeClose: false,
                    closeBtn: 1,
                    zIndex: 1000,
                    area: ['1200px', '90vh'],
                    content: h(CheckVoucherForm, {
                        checkTempData: row.items,
                        type: row,
                        onClose: () => {
                            layer.close(layerId);
                        },
                        onSuccess: (voucherId) => {
                            this.loadList();
                            layer.close(layerId);
                        }
                    })
                });
            },

            // 是否显示月份列表
            chooseMonthType(result) {
                this.ShowMonthList = result
            },

            // 查询月份列表接口
            queryMonthList () {
                // SalaryManage.queryMonthList().then(() => {
                // }).finally()
                this.monthDataList = {
                    results: ['2022-9', '2022-10', '2022-11', '2022-12', '2023-1', '2023-2', '2023-3', '2023-4', '2023-5', '2023-6', '2023-7', '2023-8', '2023-9', '2023-10'],
                    nowDate: '2023-8'
                }
                this.monthStart = this.monthDataList.results.length - 13
                this.monthEnd = this.monthDataList.results.length - 1
            },

            // 改变月份
            updateMonth(result) {
                if (result) {
                    this.monthStart = this.monthStart - 1
                    this.monthEnd = this.monthEnd - 1
                } else {
                    this.monthStart = this.monthStart + 1
                    this.monthEnd = this.monthEnd + 1
                }
            },
            // 改变月份
            changeMonth(result) {
                this.monthDataList.nowDate = this.monthDataList.results.slice(this.monthStart, this.monthEnd)[result]
                this.params.entryDate = this.monthDataList.nowDate
                this.loadList()
            }
        }
    }
</script>

<style lang="less" scoped>
    .zmsundialog-content {
        background-color: #FFF;
    }

    .mpBox {
        width: 1040px;
        position: relative;
        margin: 20px auto;
    }

    .mpTit {
        font-size: 20px;
        line-height: 36px;
        font-weight: bold;
    }

    .mpItList {
        height: 420px;
        width: 90%;
        float: left;
        border: 1px solid #f2f2f2;
        margin-top: 10px;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .mpItList li {
        display: block;
        float: left;
        width: 230px;
        height: 214px;
        padding: 10px;
        text-align: center;
    }

    .pzContentXj {
        height: 156px;
        border: 1px solid #2FA8FD;
        position: relative;
        border-radius: 3px;
        background: #fff url('../../assets/line-big.png') no-repeat;
    }

    .addNew {
        width: 88px;
        height: 88px;
        display: block;
        position: absolute;
        left: 60px;
        top: 30px;
        background: url('../../assets/add-new.default.png') center no-repeat;
        margin: 6px auto;
        font-size: 14px;
    }

    .th-btn-deep-ck {
        width: 80px;
        height: 32px;
        line-height: 31px;
        float: left;
        margin: 6px auto;
        border-radius: 3px;
        cursor: pointer;
    }
    .th-btn-deep-ck {
        color: #2FA8FD !important;
        border: 1px solid #2FA8FD !important;
    }
    .th-btn-deep-ck:hover {
        color: white !important;
        border: 1px solid #2FA8FD !important;
        background: #2FA8FD !important
    }

    .th-btn-deep-fz {
        width: 80px;
        height: 32px;
        line-height: 31px;
        float: right;
        margin: 6px auto;
        border-radius: 3px;
        cursor: pointer;
    }
    .th-btn-deep-fz {
        color: white !important;
        border: 1px solid #2FA8FD !important;
        background: #2FA8FD !important;
    }
    .th-btn-deep-fz:hover {
        color: white !important;
        border: 1px solid #1766c2 !important;
        background: #1766c2 !important
    }

    .th-btn-deep-pz {
        width: 80px;
        height: 32px;
        line-height: 31px;
        margin: 6px auto;
        border-radius: 3px;
        cursor: pointer;
    }
    .th-btn-deep-pz {
        color: white !important;
        border: 1px solid #2FA8FD !important;
        background: #2FA8FD !important;
    }
    .th-btn-deep-pz:hover {
        color: white !important;
        border: 1px solid #1766c2 !important;
        background: #1766c2 !important
    }

    .finjz_tooltip {
        position: absolute;
        z-index: 850;
        padding: 0 12px;
        margin-left: 200px;
        max-width: 700px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .arrow {
        left: 0;
        background-image: url('../../assets/tooltip-left.png');
        position: absolute;
        width: 16px;
        height: 16px;
        background-repeat: no-repeat;
    }
    .mBox {
        padding: 0 5px;
        text-align: left;
        background-color: #fff;
        line-height: 24px;
        border: 1px solid #e0e0e0;
        border-radius: 3px;
        box-shadow: 0 0 5px #e0e0e0;
        max-width: 300px;
        word-break: break-all;
    }

    // 2...
    .pzContent {
        border: 1px solid #2FA8FD;
        border-radius: 3px;
    }

    .pzName {
        font-size: 16px;
        padding-left: 40px;
        color: #FFFFFF;
        height: 34px;
        line-height: 34px;
        position: relative;
        background: #2FA8FD 6px center no-repeat;
        background-image: url('../../assets/itemCommIc.svg');
    }

    .pzContent .showText {
        width: 120px;
        display: block;
        text-align: left;
    }

    .pzContent .editIcon {
        right: 46px;
    }

    .pzContent .editIcon {
        background: url('../../assets/itemEditIc.svg') no-repeat center;
        background-size: 20px;
    }

    .pzContent .deleteIcon {
        right: 10px;
    }

    .pzContent .deleteIcon {
        background: url('../../assets/itemDelIc.svg') no-repeat center;
        background-size: 20px;
    }

    .pzContent .editIcon, .pzContent .deleteIcon {
        display: block;
        font-size: 18px;
        position: absolute;
        top: 4px;
        line-height: 24px;
        width: 24px;
        height: 24px;
        cursor: pointer;
        font-family: "ykj-iconfont";
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
    }

    .pzContent .pzText {
        height: 122px;
        position: relative;
        background: #fff url('../../assets/line.png') no-repeat;
        text-align: center;
        line-height: 120px;
    }

    .invoucher-list {
        margin-right: 5px;
        position: absolute;
        right: 0;
        top: 0px;
        font-size: 12px;
        color: #2FA8FD;
        height: 24px;
        line-height: 24px;
        z-index: 1;
        max-width: 50px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .money {
        font-size: 18px;
    }
    .price-wrap {
        text-decoration: underline;
        color: #2FA8FD;
        cursor: pointer;
        max-width: 130px;
    }

    .els_span {
        display: inline-block;
        word-break: keep-all;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
        max-width: 100%;
    }

    .monthDateCont {
        position: relative;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
        transition: all 0 ease 2s;
        float: right;
        width: 82%;
    }
    .monthDateBox {
        position: relative;
        padding: 5px 0;
        text-align: center;
        background-color: #f7f8ff;
        cursor: pointer;
        user-select: none;
    }
    .monthDateBox .monthPick {
        margin: 5px 3px;
        height: 24px;
        line-height: 24px;
        font-size: 12px;
        color: #000;
        border-radius: 5px;
        border: 1px solid #F2F2F2;
        background-color: #fff;
    }
    .monthList {
        text-align: center;
        background-color: #fff;
        /*padding: 10px 20px 10px 30px;*/
    }
    .monthList li a.prev_btn, .zmsunform-fie-ex_datev .monthList li a.next_btn {
        background-color: #2FA8FD;
        color: #fff;
        width: 20px;
        height: 20px;
        line-height: 20px;
        margin: 10px 0;
    }
    .monthList li a.prev_disabled_btn, .monthList li a.next_disabled_btn, .monthList li a.prev_disabled_btn:hover, .monthList li a.next_disabled_btn:hover {
        cursor: default;
        color: #bbb;
        background-color: transparent;
    }
    .monthList li a.active {
        color: #fff;
        background-color: #2FA8FD;
    }
    .monthList li a:hover {
        color: #fff;
        background-color: #2FA8FD;
    }
    .monthList li a {
        position: relative;
        display: inline-block;
        width: 28px;
        height: 28px;
        line-height: 28px;
        text-align: center;
        color: #333;
        cursor: pointer;
        font-size: 12px;
        border-radius: 30px;
        user-select: none;
    }
    .monthList li a .year {
        position: absolute;
        left: -24px;
        top: 0px;
        -moz-border-radius: 5px;
        -webkit-border-radius: 5px;
        border-radius: 5px;
        display: inline-block;
        color: #fff;
        background-color: #2FA8FD;
        line-height: 8px;
        padding: 2px 3px;
    }
</style>
