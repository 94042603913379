/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
import common from "@api/common";
import {loading, message} from "heyui.ext";
import {jsPDF} from "jspdf";

let fontContent;
const FontLoader = (doc) => {
	return new Promise((resolve) => {
		if (fontContent) {
			resolve(fontContent)
		} else {
			loading("打印字体准备中,请稍后...")
			common.pdfFont({
				onDownloadProgress: ({progress}) => {
					loading("打印字体准备中,请稍后..." + (progress * 100).toFixed(2) + "%")
				}
			}).then((blob) => {
				blob.text().then((font) => {
					fontContent = font;
					resolve(font)
				}).catch(() => {
					message.error("字体加载失败😭,请重试~")
				}).finally(() => loading.close());
			})
		}
	})
}

const DEFAULT_OPT = {
	orientation: 'p',
	unit: 'mm',
	format: 'a4',
	putOnlyUsedFonts: true,
	floatPrecision: 2 // or "smart", default is 16
};

jsPDF.API.print = function (filename) {
	this.output('dataurlnewwindow', {filename})
}
/**
 * A4（16k）297mm×210mm；
 *
 * A5（32k）210mm× 148mm；
 * @param options
 * @returns {Promise<unknown>}
 */
const print = (options = {}) => {
	const doc = new jsPDF(Object.assign(DEFAULT_OPT, options));
	return new Promise((resolve) => {
		FontLoader(doc).then((font) => {
			doc.addFileToVFS('simsun-normal.ttf', font);
			doc.addFont('simsun-normal.ttf', 'simsun', 'normal');
			doc.addFont('simsun-normal.ttf', 'simsun', 'bold');
			doc.setFont('simsun');
			doc.setLineHeightFactor(1)
			resolve(doc);
		})
	})
}

export default {print}
