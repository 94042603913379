<template>
    <div class="h-full p-20px">
        <div class="h-full  flex flex-column">
            <div lass="ml-20px  mb-16px" style="display: flex">
                <div class="title"/>
                <div style="margin-left: 10px;  font-size: 18px;flood-color: #333333;">基本设置</div>
            </div>
            <Form ref="form" style="margin-top: 20px" mode="twocolumn" label-width="300" :model="form"
                  labelPosition="right" :rules="{}">
                <FormItem label="生成的进销存凭证是否允许修改:">
                    <Radio :datas="['允许','不允许']"></Radio>
                </FormItem>
                <FormItem label="生成凭证后是否允许修改进销存单据:">
                    <Radio :datas="['允许','不允许']"></Radio>
                </FormItem>
            </Form>
            <div lass="ml-20px  mb-16px" style="display: flex">
                <div class="title"/>
                <div style="margin-left: 10px;  font-size: 18px;flood-color: #333333;">基本设置</div>
            </div>
            <Form ref="form1" style="margin-top: 20px" mode="twocolumn" label-width="150" :model="form"
                  labelPosition="right" :rules="{}">
                <FormItem label="客户:">
                    <Radio :datas="['名称一致','编码一致']"></Radio>
                </FormItem>
                <FormItem label="商品:">
                    <Radio :datas="['名称一致','编码一致']"></Radio>
                </FormItem>
                <FormItem label="供应商:">
                    <Radio :datas="['名称一致','编码一致']"></Radio>
                </FormItem>
                <FormItem label="无法匹配时是否自动添加到财务软件:">
                    <Radio :datas="['自动添加','不添加']"></Radio>
                </FormItem>
                <FormItem label="摘要:">
                    <Radio :datas="['系统默认','进销存单据备注']"></Radio>
                </FormItem>
            </Form>
            <div lass="ml-20px  mb-16px" style="display: flex">
                <div class="title"/>
                <div style="margin-left: 10px;  font-size: 18px;flood-color: #333333;">默认对应的会计科目</div>
            </div>
            <Form ref="form1" style="margin-top: 20px" mode="twocolumn" label-width="150" :model="form"
                  labelPosition="right" :rules="{}">
                <FormItem label="应收账款:">
                    <Input type="text"/>
                </FormItem>
                <FormItem label="库存盘亏:">
                    <Input type="text"/>
                </FormItem>
                <FormItem label="应付账款:">
                    <Input type="text"/>
                </FormItem>
                <FormItem label="库存盘盈:">
                    <Input type="text"/>
                </FormItem>
                <FormItem label="库存商品:">
                    <Input type="text"/>
                </FormItem>
                <FormItem label="其他入库对方科目:">
                    <Input type="text"/>
                </FormItem>
                <FormItem label="主营业收入:">
                    <Input type="text"/>
                </FormItem>
                <FormItem label="其他出库对方科目:">
                    <Input type="text"/>
                </FormItem>
                <FormItem label="主营业成本:">
                    <Input type="text"/>
                </FormItem>
                <FormItem label="成本调整单对方科目:">
                    <Input type="text"/>
                </FormItem>
                <FormItem label="进项税:">
                    <Input type="text"/>
                </FormItem>
                <FormItem label="组装费用来源科目:">
                    <Input type="text"/>
                </FormItem>
                <FormItem label="销项税:">
                    <Input type="text"/>
                </FormItem>
                <FormItem label="拆卸费用来源科目:">
                    <Input type="text"/>
                </FormItem>
                <FormItem label="采购优惠/付款折扣:">
                    <Input type="text"/>
                </FormItem>
                <FormItem label="客户承担费用科目:">
                    <Input type="text"/>
                </FormItem>
                <FormItem label="销售优惠/付款折扣:">
                    <Input type="text"/>
                </FormItem>
                <FormItem label="销售优惠/卖家承担费用科目:">
                    <Input type="text"/>
                </FormItem>
            </Form>

            <div style="display: flex;justify-content: center;align-items: center">
                <Button style="width: 120px" color="primary">保存</Button>
                <Button style="width: 120px">取消</Button>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";

/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
export default {
    name: "CalculateParameter",
    data() {
        return {
            form: {
                connect: false,
            }
        }
    },
    computed: {
        ...mapState(['User', 'currentAccountSets']),
    }
}
</script>

<script setup>
import {confirm, loading} from "heyui.ext";
import {ref, h} from "vue";
import setting from "@/api/setting";
import RecoverForm from "@/views/setting/RecoverForm";
import {layer} from "@layui/layer-vue";

const dataLoading = ref(false)
const afterAuth = ref(false)

const fileInput = ref(null)
const ioType = ref('备份')

</script>

<style lang="less" scoped>
.line {
  height: 1px;
  background-color: #dadada;
  margin: 20px 0;
}

.title {
  width: 4px;
  height: 24px;
  background: #2FA8FD;
  border-radius: 6px 6px 6px 6px;
  opacity: 1;
}
</style>
