<template>
  <div class="frame-page h-full !m-0px p-20px flex flex-column">
    <Tabs class="bg-white-color px-16px" :datas="{AccountSummary:'按账户汇总',IoSummary:'按收支类别汇总'}" v-model="tab"/>
    <component :is="tab"/>
  </div>
</template>

<script>

import AccountSummary from "@/views/cashier/reprot/AccountSummary";
import IoSummary from "@/views/cashier/reprot/IoSummary";

export default {
  name: "FundsReport",
  components: {
    AccountSummary,
    IoSummary
  },
}
</script>

<script setup>
import {ref} from "vue";

const tab = ref('AccountSummary')
</script>
