<template>
  <assets-details :assets="assets"/>
</template>

<script>
import AssetsDetails from "@/views/assets/AssetsDetails";

/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
export default {
  name: "AssetsView",
  components: {AssetsDetails},
  props: {
    assets: Object
  },
}
</script>
