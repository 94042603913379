<template>
  <vxe-column v-if="isUnit" title="数量" field="debitQuantity" align="right" width="90">
    <template #default="{row}">
      <vxe-input @blur="valChange(row,'debitQuantity')" v-if="canEdit(row)" type="float" align="right" v-model.number="row.debitQuantity" :controls="false" size="mini"/>
      <span v-else>{{ row.debitQuantity }}</span>
    </template>
  </vxe-column>
  <vxe-column v-if="isFor&&!isLocal" title="原币" field="yearTotalDebitFor" align="right" width="120">
    <template #default="{row}">
      <vxe-input @blur="valChange(row,'yearTotalDebitFor')" v-if="canEdit(row)" type="float" align="right" v-model.number="row.yearTotalDebitFor" :controls="false"/>
      <span v-else>{{ row.yearTotalDebitFor }}</span>
    </template>
  </vxe-column>
  <vxe-column :title="colTitle" field="yearTotalDebit" align="right" width="120">
    <template #default="{row}">
      <vxe-input @blur="valChange(row,'yearTotalDebit')" v-if="canEdit(row)" type="float" align="right" v-model.number="row.yearTotalDebit" :controls="false"/>
      <span v-else>{{ row.yearTotalDebit }}</span>
    </template>
  </vxe-column>
</template>

<script>
/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
export default {
  name: "TotalDebitCol",
  props: {
    valChange: Function,
    isStartYear: Boolean,
    isUnit: Boolean,
    isFor: Boolean,
    beginCheck: Boolean,
    isLocal: Boolean,
  },
  computed: {
    colTitle() {
      if (this.isFor) {
        return '本位币';
      }
      return this.isUnit ? '金额' : '借方累计'
    }
  },
  methods: {
    canEdit(row) {
      return !this.beginCheck && (row.auxiliaryDetailId !== '0' || (row.leaf && !this.isStartYear && row.auxiliaryAccounting.length === 0))
    }
  },
}
</script>
