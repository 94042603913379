<template>
    <app-content class="h-panel" style="overflow: hidden">
        <div style="margin: 20px 20px 0px 20px">
            <DropdownCustom ref="paramsDropdown" button class="h-btn-primary" trigger="click">
                <div v-if="params.voucherDate.start!==params.voucherDate.end">{{ fqFormat(params.voucherDate.start) }} 至
                    {{ fqFormat(params.voucherDate.end) }}
                </div>
                <div v-else>{{ fqFormat(params.voucherDate.start) }}</div>
                <template #content>
                    <Form style="padding: 10px;width: 400px">
                        <FormItem class="!pb-5px" label="会计期间">
                            <div class="flex items-center">
                                <Select v-model="params.voucherDate.start" :deletable="false" filterable
                                        :datas="checkoutSelect"/>
                                <span class="mx-8px">至</span>
                                <Select v-model="params.voucherDate.end" :deletable="false" filterable
                                        :datas="checkoutSelect"/>
                            </div>
                        </FormItem>
                        <FormItem class="!pb-5px" label="凭证字">
                            <Select v-model="params.voucherWord" :deletable="false" :datas="voucherWords"/>
                        </FormItem>
                        <FormItem class="!pb-5px" label="制单人">
                            <Select v-model="params.voucherWord" :deletable="false" :datas="voucherWords"/>
                        </FormItem>
                        <FormItem class="!pb-5px" label="是否审核">
                            <Select v-model="params.audit" :deletable="false"
                                    :datas="[{key:0,title:'全部'},{key:1,title:'已审核'},{key:2,title:'未审核'}]"/>
                        </FormItem>
                        <FormItem class="!pb-5px" label="摘要">
                            <Input v-model="params.summary"/>
                        </FormItem>
                        <FormItem class="!pb-5px" label="备注">
                            <Input v-model="params.summary"/>
                        </FormItem>
                        <FormItem class="!pb-5px" label="科目">
                            <Input v-model="params.summary"/>
                        </FormItem>
                        <FormItem class="!pb-5px" label="金额">
                            <div class="flex items-center">
                                <Input v-model="params.summary"/>
                                <span class="mx-8px">至</span>
                                <Input v-model="params.summary"/>
                            </div>
                        </FormItem>
                        <FormItem class="!pb-5px" label="凭证号">
                            <div class="flex items-center">
                                <Input v-model="params.summary"/>
                                <span class="mx-8px">至</span>
                                <Input v-model="params.summary"/>
                            </div>
                        </FormItem>
                        <FormItem class="!pb-5px" label="排序依据">
                            <Radio v-model="params.sort" :datas="{code:'凭证号排序',voucher_date:'凭证日期排序'}"></Radio>
                        </FormItem>
                        <FormItem class="!pb-5px" label="排序方式">
                            <Radio v-model="params.sort" :datas="{code:'凭证号排序',voucher_date:'凭证日期排序'}"></Radio>
                        </FormItem>
                        <FormItem class="!pb-5px">
                            <Button color="primary" @click="loadList();" :loading="loading">确定</Button>
                            <Button @click="this.$refs.paramsDropdown.hide()">取消</Button>
                            <Button @click="resetForm">重置</Button>
                        </FormItem>
                    </Form>
                </template>
            </DropdownCustom>
            <div class="float-right">
                <template v-if="User.role!=='View' && currentAccountSets.voucherReviewed">
                    <Button :loading="loading" @click="audit">审核</Button>
                    <Button :loading="loading" @click="cancelAudit">取消审核</Button>
                </template>
                <Button icon="fa fa-refresh" @click="loadList()"/>
                <DropdownMenu button @clickItem="trigger" :datas="param">打印</DropdownMenu>
                <template v-if="User.role!=='View'">
                    <Button :loading="loading" @click="finishingOffNo">整理断号</Button>
                    <Button :loading="loading" @click="batchDelete">批量删除</Button>
                    <DropdownMenu button @clickItem="downloadTrigger" :datas="{all:'导出所有',checked:'导出选中'}">
                        导出凭证
                    </DropdownMenu>
                    <Button :loading="loading" @click="doUpload">
                        导入凭证
                    </Button>
                </template>
            </div>
        </div>
        <div class="h-panel-body voucher-list">
            <table class="header">
                <tr>
                    <th style="width: 50px"><input :checked="checkAll" type="checkbox" @click="checkAll=!checkAll"></th>
                    <td style="width: 215px">摘要</td>
                    <td>科目</td>
                    <td align="right" style="width: 130px">借方金额</td>
                    <td align="right" style="width: 130px">贷方金额</td>
                </tr>
            </table>
            <table v-if="!datas.length">
                <tr>
                    <td colspan="5" class="text-center padding">暂无数据</td>
                </tr>
            </table>
            <table class="details" v-for="data in datas" :key="data.id">
                <tr class="details-header">
                    <th style="width: 50px"><input :class="{'display':data._checked}" v-model="data._checked"
                                                   type="checkbox"></th>
                    <td colspan="2">日期：{{ data.voucherDate }} 凭证字号：{{ data.word }}-{{ data.code }}
                        <template v-if="data.receiptNum > 0">
                            <DropdownCustom :toggle-icon="false" class-name="h-text-dropdown">
                <span class="ml-8px text-hover primary-color">
                <i class="fa fa-paperclip"></i> {{ data.receiptNum }}
                </span>
                                <template #content>
                                    <div class="w-400px">
                                        <div class="h-150px">
                                            <vxe-table ref="billListTable" :data="data.billList" :show-header="false"
                                                       height="auto">
                                                <vxe-column field="name">
                                                    <template #default="{row}">
                                                        <a :href="'/api'+row.url+'?name='+row.name"
                                                           target="_blank">{{ row.name }}</a>
                                                    </template>
                                                </vxe-column>
                                            </vxe-table>
                                        </div>
                                    </div>
                                </template>
                            </DropdownCustom>
                        </template>
                    </td>
                    <td colspan="2">
                        <div class="actions flex justify-end items-center">

                            <span @click="printerSetting(data)" class="fa fa-trash">打印</span>
<!--                            <a :href="`/api/pdf/voucher?id=${data.id}`" target="_blank" class="fa fa-print">打印</a>-->
                            <router-link @success="loadList" width="1024px"
                                         v-if="User.role!=='View' && (!currentAccountSets.voucherReviewed || !data.auditMemberId)"
                                         class="span fa fa-edit" :to="{name:'VoucherForm',params:{voucherId:data.id}}">
                                修改
                            </router-link>
                            <span v-if="User.role!=='View' && (!currentAccountSets.voucherReviewed || !data.auditMemberId)"
                                  @click="remove(data)" class="fa fa-trash">删除</span>
                            <router-link @success="loadList" width="1024px" v-if="User.role!=='View'"
                                         class="span fa fa-copy"
                                         :to="{name:'VoucherForm',params:{voucherId:data.id,copy:true}}">复制
                            </router-link>
                            <router-link class="text-hover" width="80vw"
                                         v-if="currentAccountSets.voucherReviewed && data.auditMemberId" tag="span"
                                         :to="{name:'VoucherForm',params:{voucherId:data.id}}">
                                <i class="h-icon-completed green-color" content="已审核"></i> 查看
                            </router-link>
                        </div>
                    </td>
                </tr>
                <tr v-for="d in data.details" :key="d.id">
                    <th></th>
                    <td style="width: 215px">
                        {{ d.summary }} <span v-if="d.currencyName && d.currencyId !== localCurrency.id">({{
                        d.currencyName
                        }}：{{ d.originalAmount }}，汇率：{{ d.exchangeRate }})</span>
                        <template v-if="d.subject&&d.num&&d.price">
                            (数量:{{ d.num }}<span class="dark4-color">{{ d.subject.unit }}</span>，单价:{{
                            d.price
                            }}<span class="dark4-color">元</span>)
                        </template>
                    </td>
                    <td>{{ d.subjectName }}<span v-if="d.auxiliaryDetailId">_{{ d.auxiliaryTitle }}</span></td>
                    <td align="right" style="width: 130px">{{ numFormat(d.debitAmount) }}</td>
                    <td align="right" style="width: 130px">{{ numFormat(d.creditAmount) }}</td>
                </tr>
                <tr class="font-bold">
                    <td></td>
                    <td>合计</td>
                    <td>{{ dxMoney(data.debitAmount) }}</td>
                    <td align="right">{{ numFormat(data.debitAmount) }}</td>
                    <td align="right">{{ numFormat(data.creditAmount) }}</td>
                </tr>
            </table>
            <Pagination v-model="pagination" @change="currentChange" layout="pager" small align="center"/>
        </div>
    </app-content>
</template>

<script>
import Qs from "qs";
import {mapActions, mapGetters, mapState} from 'vuex'
import {h} from 'vue'
import {clone, pick} from "xe-utils";
import {layer} from "@layui/layer-vue";
import VoucherImport from "@/views/voucher/VoucherImport";
import VoucherPrinterSetting from "@/views/voucher/VoucherPrinterSetting";
import manba from "manba";
import voucher from "@/api/voucher";
import {downloadBlob} from "download.js";
import FinishingOffNo from "@/views/voucher/FinishingOffNo.vue";
import {message} from "heyui.ext";

export default {
    name: "Voucher",
    data() {
        return {
            datas: [],
            accountDate: null,
            pagination: {
                page: 1,
                pageSize: 20,
                total: 0
            },
            voucherWords: ['全部', '选择-'],
            params: {
                voucherDate: {start: null, end: null},
                audit: 0,
                voucherWord: 0,
                summary: null,
            },
            loading: false,
            checkAll: false,
            param: [{title: '打印全部', key: 'printAll'}, {title: '打印选中', key: 'printCheck'}]
        };
    },
    watch: {
        checkAll(nval) {
            let data = Array.from(this.datas);
            data.forEach(val => val._checked = nval);
            this.datas = data;
        }
    },
    computed: {
        ...mapState(['User', 'currentAccountSets']),
        ...mapGetters(['checkoutSelect', 'localCurrency']),
        date() {
            return manba(this.accountDate);
        }
    },
    methods: {
        ...mapActions(['init']),
        currentChange() {
            this.loadList();
        },
        trigger(key) {
            this[key].call(this);
        },
        downloadTrigger(key) {
            if (key === 'all') {
                voucher.download(this.params).then((data) => {
                    downloadBlob(this.currentAccountSets.companyName + (manba().format('YYYYMMDDHHmmss')) + "凭证.xlsx", data);
                })
            } else {
                let checked = this.datas.filter(value => value._checked);
                if (checked.length) {
                    voucher.download({id: checked.map(value => value.id)}).then((data) => {
                        downloadBlob(this.currentAccountSets.companyName + (manba().format('YYYYMMDDHHmmss')) + "凭证.xlsx", data);
                    })
                } else {
                    this.$Message("未选择记录");
                }
            }
        },
        printAll() {
            let param = Qs.stringify({
                audit: this.params.audit,
                voucherDateStart: this.params.voucherDate.start,
                voucherDateEnd: this.params.voucherDate.end
            }, {indices: false});
            window.open(`/api/pdf/voucher?${param}`);
        },
        printCheck() {
            let checked = this.datas.filter(value => value._checked);
            if (checked.length) {
                let param = Qs.stringify({id: checked.map(value => value.id)}, {indices: false});
                window.open(`/api/pdf/voucher?${param}`);
            } else {
                this.$Message("未选择记录");
            }
        },
        loadList() {
            const loadId = layer.load(2);
            voucher.list(this.pagination, this.params).then(({data}) => {
                this.datas = data.results || [];
                this.pagination = {
                    page: data.page,
                    size: data.size,
                    total: data.total
                }
            }).finally(() => layer.close(loadId))
        },
        remove(row) {
            this.$Confirm("确认删除?").then(() => {
                const loadId = layer.load(2);
                voucher.delete([pick(row, ['id', 'year', 'month'])]).then(({data}) => {
                    if (data && data.length) {
                        this.$Message("部分删除不成功，请检查所在账期是否已结账~")
                    } else {
                        this.$Message("删除成功~")
                        this.init();
                    }

                    this.loadList();
                }).finally(() => layer.close(loadId))
            });
        },
        finishingOffNo() {
            let layerId = layer.open({
                title: "断号整理",
                shadeClose: false,
                closeBtn: false,
                zIndex: 1000,
                area: ['400px', 'auto'],
                content: h(FinishingOffNo, {
                    onClose: () => {
                        layer.close(layerId);
                    },
                    onSuccess: () => {
                        layer.close(layerId);
                        this.loadList();
                    }
                })
            });
        },
        batchDelete() {
            let checked = this.datas.filter(value => value._checked && !value.auditMemberId);
            if (checked.length) {
                this.$Confirm("确认删除?").then(() => {
                    const loadId = layer.load(2);
                    this.$api.voucher.delete(checked.map(val => pick(val, ['id', 'year', 'month']))).then(({data}) => {
                        if (data && data.length) {
                            this.$Message("部分删除不成功，请检查所在账期是否已结账~")
                        } else {
                            this.$Message("删除成功~")
                        }
                        this.init();
                        this.loadList();
                    }).finally(() => {
                        layer.close(loadId);
                    });
                });
            } else {
                message.warn("没有可删除凭证~");
            }
        },
        audit() {
            let checked = this.datas.filter(value => value._checked);
            if (checked.length) {
                const vdates = new Set(checked.map(val => val.year + "" + val.month))
                if (vdates.size > 1) {
                    this.$Message("不能跨期操作~");
                    return
                }
                this.$Confirm("亲，确认要审核吗?").then(() => {
                    this.loading = true;
                    this.$api.voucher.audit({
                        checked: checked.map(value => value.id),
                        year: checked[0].year,
                        month: checked[0].month
                    }).then(() => {
                        this.loadList();
                        this.$Message("审核成功！");
                    }).finally(() => {
                        this.loading = false;
                    });
                });
            } else {
                this.$Message("未选择记录");
            }
        },
        cancelAudit() {
            let checked = this.datas.filter(value => value._checked);
            if (checked.length) {
                const vdates = new Set(checked.map(val => val.year + "" + val.month))
                if (vdates.size > 1) {
                    this.$Message("不能跨期操作~");
                    return
                }
                this.$Confirm("亲，确认要取消审核吗?").then(() => {
                    this.loading = true;
                    this.$api.voucher.cancelAudit({
                        checked: checked.map(value => value.id),
                        year: checked[0].year,
                        month: checked[0].month
                    }).then(() => {
                        this.loadList();
                        this.$Message("取消审核成功！");
                    }).finally(() => {
                        this.loading = false;
                    });
                });
            } else {
                this.$Message("未选择记录");
            }
        },
        printerSetting(data){
            let layerId = layer.open({
                title: "打印设置",
                shadeClose: false,
                closeBtn: false,
                zIndex: 1000,
                area: ['700px', '550px'],
                content: h(VoucherPrinterSetting, {
                    onClose: () => {
                        layer.close(layerId);
                    },
                    onSuccess: () => {
                        layer.close(layerId);
                        this.loadList()
                    }
                })
            });
        },
        doUpload() {
            let layerId = layer.open({
                title: "批量导入凭证",
                shadeClose: false,
                closeBtn: false,
                zIndex: 1000,
                area: ['500px', 'auto'],
                content: h(VoucherImport, {
                    onClose: () => {
                        layer.close(layerId);
                    },
                    onSuccess: () => {
                        layer.close(layerId);
                        this.loadList()
                    }
                })
            });
        },
        resetForm() {
            this.params = clone(this.cloneParams, true)
        }
    },
    created() {
        this.params.voucherDate.start = manba(this.currentAccountSets.currentAccountDate).format("YYYY-M");
        this.params.voucherDate.end = this.params.voucherDate.start;
        this.cloneParams = clone(this.params, true);
        this.loadList();
    }
};
</script>
