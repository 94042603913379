<template>
    <div class="h-full p-20px">
        <div class="h-full  flex flex-column">


            <div class="h-full  flex flex-column" style="justify-content: center;align-items: center">
                <Form
                        ref="form"
                        :labelPosition="right"
                        :labelWidth="120">
                    <FormItem label="是否关联进销存:">
                        <Radio v-model="form.connect" :datas="['关联','不关联']"/>
                    </FormItem>
                    <FormItem label="财务软件套账:">
                        <div>{{this.currentAccountSets.companyName}}</div>
                    </FormItem>
                    <FormItem label="关联进销存套账:">
                        <div class="flex items-center">
                                <Select style="width: 120px" :deletable="false" :datas="['选择1','选择2']"/>
                        </div>
                    </FormItem>
                </Form>
                <div style="font-size: 16px;color: #2FA8FD;margin-bottom: 20px">没有账套？请点击前往进销存创建新账套</div>
                <div>
                    <Button style="width: 120px" color="primary">保存</Button>
                    <Button style="width: 120px">取消</Button>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";

/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
export default {
    name: "ConnectVerify",
    data() {
        return {
            form:{
                connect:false,
            }
        }
    },
    computed: {
        ...mapState(['User', 'currentAccountSets']),
        }
}
</script>

<script setup>
import {confirm, loading} from "heyui.ext";
import {ref, h} from "vue";
import setting from "@/api/setting";
import RecoverForm from "@/views/setting/RecoverForm";
import {layer} from "@layui/layer-vue";

const dataLoading = ref(false)
const afterAuth = ref(false)

const fileInput = ref(null)
const ioType = ref('备份')

</script>
