<template>
  <div class="flex flex-column" style="height: 100%">
    <div class="mx-10px mt-10px">
      <Form ref="form" :rules="{}" labelPosition="left" :model="form" :labelWidth="110">
        <FormItem label="方案名称" prop="name" required>
          <Input type="text" v-model.trim="form.name"/>
        </FormItem>
        <div class="insureConfigTip">社保方案
          <div style="float: right;margin: -7px 0px 0 -5px;">
            <vxe-button icon="fa fa-plus" @click="pushItem(-1)" content="添加险种" status="primary"/>
          </div>
        </div>
      </Form>
    </div>
    <div class="flex-1" style="height: 0">
      <vxe-table ref="itemsTable"
                 :loading="loading"
                 :data="form.insureConfigList"
                 :edit-config="{trigger:'click', mode:'cell', showIcon: false}"
                 height="auto"
                 align="center"
                 >
        <vxe-column field="projectName" title="项目名称" :edit-render="{name:'$input'}"/>
        <vxe-column field="paymentBase" title="缴纳基数" :edit-render="{name:'$input',props:{type:'number'}}"/>
        <vxe-colgroup title="公司承担部分">
          <vxe-column field="companyPayment" title="公司缴纳比例(%)" :edit-render="{name:'$input'}"/>
          <vxe-column field="companyMoney" title="公司缴纳金额" :edit-render="{name:'$input',props:{type:'number'}}"/>
        </vxe-colgroup>
        <vxe-colgroup title="个人承担部分">
          <vxe-column field="individualPayment" title="个人缴纳比例(%)" :edit-render="{name:'$input'}"/>
          <vxe-column field="individualMoney" title="个人缴纳金额" :edit-render="{name:'$input',props:{type:'number'}}"/>
        </vxe-colgroup>
        <vxe-column title="操作">
          <template #default="{row}">
            <div class="actions" v-if="!row.init">
              <span v-if="!row.voucherId" @click="popItem(row)" class="fa fa-trash">删除</span>
            </div>
          </template>
        </vxe-column>
      </vxe-table>
    </div>
    <div class="modal-column-between">
      <Button @click="$emit('close')">取消</Button>
      <Button color="primary" @click="submit" :loading="loading">{{ form.id ? '更新' : '保存' }}</Button>
    </div>
  </div>
</template>

<script>
import InsuredConfig from "@/api/InsuredConfig";
import {message} from "heyui.ext";

export default {
  name: "InsureTemplateForm",
  props: {
    original: Object
  },
  data() {
    return {
      loading: false,
      form: {
        id: null,
        name: '',
        insureConfigList: [],
      },
    }
  },
  methods: {
    submit() {
      let validResult = this.$refs.form.valid();
      if (validResult.result) {
        this.loading = true;
        let {fullData} = this.$refs.itemsTable.getTableData();
        this.form.insureConfigList = fullData
        InsuredConfig.save(this.form).then(({data}) => {
          message("保存成功~");
          this.$emit('success');
        }).finally(() => this.loading = false);
      }
    },
    loadList() {
      if (this.form.id === null) {
        this.form.insureConfigList = [
          {id: '1', pid: '1', projectName: '养老保险', paymentBase: '1.21', companyPayment: '20', companyMoney: '1', individualPayment: '8', individualMoney: '6'},
          {id: '2', pid: '2', projectName: '医疗保险', paymentBase: '2.21', companyPayment: '10', companyMoney: '2', individualPayment: '2', individualMoney: '5'},
          {id: '3', pid: '3', projectName: '失业保险', paymentBase: '3.21', companyPayment: '1', companyMoney: '3', individualPayment: '0.2', individualMoney: '4'},
          {id: '4', pid: '4', projectName: '工伤保险', paymentBase: '4.21', companyPayment: '0.5', companyMoney: '4', individualPayment: '0', individualMoney: '0'},
          {id: '5', pid: '5', projectName: '生育保险', paymentBase: '5.21', companyPayment: '0.8', companyMoney: '5', individualPayment: '0', individualMoney: '0'},
          {id: '6', pid: '6', projectName: '住房公积金', paymentBase: '6.21', companyPayment: '12', companyMoney: '6', individualPayment: '12', individualMoney: '1'},
        ]
      } else {
        // InsuredConfig.queryInsureInfo(this.form.id).then((data) => {
        //   this.form = data
        // })
      }
    },
    pushItem(row) {
      this.$refs.itemsTable.insertAt({id: '', pid: '', projectName: '', paymentBase: '0.00', companyPayment: '0.00', companyMoney: '0.00', individualPayment: '0.00', individualMoney: '0.00'}, row);
    },
    popItem(row) {
      let {fullData} = this.$refs.itemsTable.getTableData();
      if (fullData.length > 0) {
        this.$refs.itemsTable.remove(row);
      }
    },
  },
  mounted() {
    if (this.original) {
      this.form.id = this.original.id;
      this.form.name = this.original.name;
    }
    this.loadList()
  }
}
</script>
<style lang="less">
.insureConfigTip {
  padding: 12px 0px 12px 15px;
  font-weight: bold;
  border-left-width: 4px;
  border-left-style: solid;
  background-color: #f8f8f8;
  position: relative;
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
  border-left-color: #f66;
}

.insureConfigTip:before {
  content: "!";
  background-color: #f66;
  position: absolute;
  top: 8px;
  left: -12px;
  color: #fff;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  text-align: center;
  line-height: 20px;
  font-weight: 700;
}
</style>
