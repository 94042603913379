<template>
	<div class="login-frame">
		<Form class="root-register-account" ref="loginForm" :model="reg" :rules="{}" :validOnChange="true">

			<FormItem :showLabel="false" required prop="mobile" label="手机号">
				<div class="h-input h-input-prefix-icon">
					<i class="fa fa-user"></i>
					<Input v-model="reg.mobile" type="text" placeholder="请输入手机号" class="register-input"/>
				</div>
			</FormItem>
			<FormItem :showLabel="false" required prop="code" label="验证码">
				<div class="h-input h-input-prefix-icon">
					<i class="fa fa-lock"></i>
					<Input v-model="reg.code" type="text" placeholder="请输入验证码" maxlength="4" style="width: 68%" class="register-input"/>
					<SmsVerificationCode api="regMsg" :mobile="reg.mobile" class="register-input"/>
				</div>
			</FormItem>
			<FormItem :showLabel="false" required prop="account" label="账号">
				<div class="h-input h-input-prefix-icon">
					<i class="fa fa-user"></i>
					<Input v-model="reg.account" type="text" placeholder="请输入账号" class="register-input"/>
				</div>
			</FormItem>
			<FormItem :showLabel="false" required prop="password" label="密码">
				<div class="h-input h-input-prefix-icon">
					<i class="fa fa-lock"></i>
					<Input v-model="reg.password" required prop="password" type="password" placeholder="请输入密码" class="register-input"/>
				</div>
			</FormItem>
			<FormItem :showLabel="false" class="pb-10px">
				<SiderB />
			</FormItem>
			<FormItem :showLabel="false">
				<Button @click="regSubmit" :loading="loading" class="root-register-btn" block>立 即 注 册</Button>
				<div class=" my-16px">
					<span class="text-hover" style="color: #9b9ea0;" @click="$emit('changeView','LoginForm')">返回登录</span>
				</div>
			</FormItem>
		</Form>
	</div>
</template>
<script>
	import SmsVerificationCode from "../../components/SmsVerificationCode"
	import SiderB from './SiderB'

	export default {
		name: 'Registered',
		components: {SmsVerificationCode,SiderB},
		data() {
			return {
				reg: {
					mobile: "",
					code: "",
					account:"",
					password: "",
				},
				loading: false
			};
		},
		methods: {
			regSubmit() {
				let validResult = this.$refs.loginForm.valid();
				if (validResult.result) {
					this.loading = true;
					console.log(this.reg,'this.reg')
					this.$api.common.register(this.reg).then(() => {
						console.log('注册成功')
						this.$emit('toSuc',false)
						// window.location.replace('/');
					}).finally(() => {
						
						// console.log('注册失败')
						this.loading = false
					});
				}
			}
		}
	}
</script>

<style lang="less" scoped>
	.root-register-account {
		margin-top: 30px;
	}

	.register-input {
		height: 45px;
		border-radius: 6px;
	}
	.root-register-btn {
		height: 40px;
		background-color: #006dfd;
		border-radius: 20px;
		color: #fff;
		cursor: default;
		width: 100%;
	}
	.root-register-btn:hover, .root-register-btn:focus {
		background-color: #006dfd;
		border-radius: 20px;
		color: #fff;
	}
</style>
