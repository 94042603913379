<template>
    <div class="frame-page h-full !m-0px p-20px flex flex-column">
        <vxe-toolbar class-name="px-8px">

            <template #tools>
                <vxe-button @click="printList()" content="打印" icon="fa fa-print"/>
                <vxe-button content="导出" icon="fa fa-download"/>
                <vxe-button content="导入" icon="fa fa-upload"/>
                <vxe-button @click="batchDelete" content="批量删除" icon="fa fa-trash"/>
                <vxe-button content="生成凭证" status="primary"/>
            </template>
        </vxe-toolbar>

        <vxe-toolbar class-name="px-8px">
            <template #buttons>
<!--                <vxe-button @click="showForm()" content="新增" status="primary" icon="fa fa-plus"/>-->
                <div class="h-input-group ml-8px">
                    <span>日期：</span>
                    <DatePicker v-model="dateRange.start"/>
                    <span style="margin-left: 10px;margin-right: 10px"> 至 </span>
                    <DatePicker v-model="dateRange.end"/>

                </div>
                <div class="h-input-group ml-8px">
                    <span>转出账户：</span>
                    <Select class="w-120px" :datas="accountList" keyName="id" titleName="name"
                            v-model="query.outAccountId">
                        <template #show="{value}">{{ value.code }} - {{ value.name }}</template>
                        <template #item="{item}">{{ item.code }} - {{ item.name }}</template>
                    </Select>
                </div>
                <div class="h-input-group ml-8px">
                    <span>转入账户：</span>
                    <Select class="w-120px" :datas="accountList" keyName="id" titleName="name"
                            v-model="query.inAccountId">
                        <template #show="{value}">{{ value.code }} - {{ value.name }}</template>
                        <template #item="{item}">{{ item.code }} - {{ item.name }}</template>
                    </Select>
                </div>
                <vxe-button :loading="loading" @click="doSearch" class="ml-8px" content="查询" icon="fa fa-search"
                            status="primary"/>
            </template>

        </vxe-toolbar>
        <div class="flex-1">
            <vxe-table ref="xTable"
                       show-footer
                       :footer-method="footerMethod"
                       :edit-config="{trigger: 'click', mode: 'cell', showStatus: false}"
                       :loading="loading" :data="results.results" height="auto">
                <vxe-column type="checkbox" width="50" align="center"/>
                <vxe-column field="transferDate" width="120" title="日期" :edit-render="{}">
                    <template #default="{row}">
                        <span>{{ row.transferDate }}</span>
                    </template>
                    <template #edit="{row}">
                        <vxe-input v-model="row.transferDate"
                                   type="date" transfer></vxe-input>
                    </template>
                </vxe-column>
                <vxe-column field="digest" title="摘要" :edit-render="{}">
                    <template #default="{row}">
                        <span>{{ row.digest }}</span>
                    </template>
                    <template #edit="{row}">
                        <vxe-input v-model="row.digest"
                                    transfer></vxe-input>
                    </template>
                </vxe-column>
                <vxe-column field="outAccount" width="150" title="转出账户" :edit-render="{}">
                    <template #default="{row}">
                        <span>{{ row.outAccount }}</span>
                    </template>
                    <template #edit="{row}">
                        <vxe-input v-model="row.outAccount"
                                   transfer></vxe-input>
                    </template>
                </vxe-column>
                <vxe-column field="inAccount" width="150" title="转入账户" :edit-render="{}">
                    <template #default="{row}">
                        <span>{{ row.inAccount }}</span>
                    </template>
                    <template #edit="{row}">
                        <vxe-input v-model="row.inAccount"
                                   transfer></vxe-input>
                    </template>
                </vxe-column>
                <vxe-column field="amount" width="120" align="right" title="金额" formatter="toMoney" :edit-render="{}">
                    <template #default="{row}">
                        <span>{{ row.amount }}</span>
                    </template>
                    <template #edit="{row}">
                        <vxe-input v-model="row.amount"
                                type="number"   transfer></vxe-input>
                    </template>
                </vxe-column>
                <vxe-column field="remark" title="备注" :edit-render="{}">
                    <template #default="{row}">
                        <span>{{ row.remark }}</span>
                    </template>
                    <template #edit="{row}">
                        <vxe-input v-model="row.remark"
                                   transfer></vxe-input>
                    </template>
                </vxe-column>
                <vxe-column field="voucherTitle" title="关联凭证" width="100" align="center" :edit-render="{}">
                    <template #default="{row}">
                        <span>{{ row.voucherTitle }}</span>
                    </template>
                    <template #edit="{row}">
                        <vxe-input v-model="row.voucherTitle"
                                   transfer></vxe-input>
                    </template>
                </vxe-column>
                <vxe-column field="id" title="操作" width="100" align="center">
                    <template #default="{row}">
                        <div class="actions">
<!--                            <span @click="showForm(row)">编辑</span>-->
                            <span @click="remove(row)">删除</span>
                        </div>
                    </template>
                </vxe-column>
            </vxe-table>
        </div>
        <vxe-pager @page-change="onPageChange"
                   v-model:current-page="page.page"
                   v-model:page-size="page.pageSize"
                   :total="results.total"
                   :layouts="['PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'Sizes', 'FullJump', 'Total']">
            <template #left>
                <vxe-button @click="loadList()" type="text" size="mini" icon="fa fa-refresh"
                            :loading="loading"></vxe-button>
            </template>
        </vxe-pager>
    </div>
</template>

<script>
/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
export default {
    name: "InternalTransfer"
}
</script>

<script setup>
import {layer} from "@layui/layer-vue";
import {h, ref} from "vue";
import InternalTransferForm from "@/views/cashier/transfer/InternalTransferForm";
import CashierTransfer from "@/api/CashierTransfer";
import CashierAccount from "@/api/CashierAccount";
import manba from "manba";
import {confirm, message} from "heyui.ext";
import {useStore} from "vuex";
import Qs from "qs";

const store = useStore();
const currentAccountDate = manba(store.state.currentAccountSets.currentAccountDate);
const startDate = ref(currentAccountDate.startOf(manba.MONTH).format());
const endDate = ref(currentAccountDate.endOf(manba.MONTH).format());

const loading = ref(false);
const xTable = ref(null);
const accountList = ref([]);
const results = ref({
    results: [],
    total: 0,
});

const page = ref({
    page: 1,
    pageSize: 20
})

const query = ref({
    outAccountId: null,
    inAccountId: null
})
const dateRange = ref({
    start: startDate.value,
    end: endDate.value
})


const loadList = () => {
    loading.value = true;
    CashierTransfer.list(Object.assign(page.value, query.value, dateRange.value)).then(({data}) => {
        results.value = data;
    }).finally(() => loading.value = false)
}
loadList();

const onPageChange = ({currentPage, pageSize}) => {
    page.value.page = currentPage;
    page.value.pageSize = pageSize;
    loadList();
}

const doSearch = () => {
    page.value.page = 1;
    loadList();
}
const countAmount = (row) => {
    return row.amount
}
const countAllAmount = (data) => {
    let count = 0
    data.forEach(row => {
        count += countAmount(row)
    })
    return count
}
const footerMethod = ({ columns, data }) => {
    console.log(data)
    return [
        columns.map((column, columnIndex) => {
            if (columnIndex === 0) {
                return '合计'
            }
             if (columnIndex === 5) {
                return `共 ${countAllAmount(data)} 元`
            }
            return '-'
        })
    ]
}
const showForm = (entity = null) => {
    let layerId = layer.open({
        title: "转账信息",
        shadeClose: false,
        closeBtn: false,
        zIndex: 1000,
        area: ['500px', 'auto'],
        content: h(InternalTransferForm, {
            entity, accountList: accountList.value,
            onClose: () => {
                layer.close(layerId);
            },
            onReload: () => {
                loadList();
            },
            onSuccess: () => {
                loadList();
                layer.close(layerId);
            }
        })
    });
}

CashierAccount.list().then(({data}) => {
    accountList.value = data;
})

const printList = () => {
    window.open("/api/pdf/transfer/list?" + Qs.stringify(Object.assign(page.value, query.value, dateRange.value), {arrayFormat: 'repeat'}))
}

const remove = (row) => {
    confirm({
        content: "确定删除此记录?",
        async: true,
        onConfirm(resolve) {
            CashierTransfer.delete(row.id).then(() => {
                resolve(true)
                loadList();
            }).catch(() => {
                resolve(false)
            })
        }
    })
}

const batchDelete = (row) => {
    let records = xTable.value.getCheckboxRecords().map(row => row.id);
    if (records.length) {
        confirm({
            content: "确定删除此记录?",
            async: true,
            onConfirm(resolve) {
                CashierTransfer.batchDelete(records).then(() => {
                    resolve(true)
                    loadList();
                }).catch(() => {
                    resolve(false)
                })
            }
        })
    } else {
        message.warn("请先选择记录~");
    }
}
</script>
