import utils from 'hey-utils';
import {clone} from 'xe-utils';

const rclass = /[\t\r\n\f]/g;

export const CopyObj = (target, original) => {
	if (original) {
		//解除obs
		original = clone(original, true);
		Object.keys(target).forEach(key => {
			target[key] = original[key];
		});
	}
}

export const ArrToObj = (target, getKey, getVal) => {
	let obj = {};
	if (target) {
		for (const item of target) {
			obj[getKey(item)] = getVal ? getVal(item) : item;
		}
	}
	return obj;
}


export default utils.extend({}, utils, {
	getClass(elem) {
		return (elem.getAttribute && elem.getAttribute('class')) || '';
	},
	hasClass(elem, selector) {
		let className;
		className = ` ${selector} `;
		if (elem.nodeType === 1 && (` ${this.getClass(elem)} `)
			.replace(rclass, ' ')
			.indexOf(className) > -1) {
			return true;
		}

		return false;
	}
});
