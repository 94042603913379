import Home from './views/Home.vue'
import PersonalSetting from './views/personal/PersonalSetting.vue'
import Voucher from './views/voucher/Index.vue'
import ElectronicArchives from './views/voucher/ElectronicArchives.vue'
import BillInfo from './views/voucher/BillInfo.vue'
import CheckList from './views/checkout/CheckList.vue'
import UnCheckOut from './views/checkout/UnCheckOut.vue'
import CheckTemplate from './views/checkout/CheckTemplate.vue'
import Check from './views/checkout/Check.vue'
import Checkout from './views/checkout/Checkout.vue'
import Account from './views/setting/Account.vue'
import Currency from './views/setting/Currency.vue'
import VoucherWord from './views/setting/VoucherWord.vue'
import AssistingAccounting from './views/setting/AssistingAccounting.vue'
import Initial from './views/setting/Initial.vue'
import RoleAdd from './views/setting/RoleAdd.vue'
import BossSee from './views/setting/BossSee.vue'
import Approve from './views/setting/Approve.vue'
import OptionLog from './views/setting/OptionLog.vue'
import ConnectVerify from './views/setting/ConnectVerify.vue'
import ConnectJXC from './views/setting/ConnectJXC.vue'
import ChangePassword from './views/personal/ChangePassword.vue'
import ChangePhoneNumber from './views/personal/ChangePhoneNumber.vue'
import PermissionSetting from './views/setting/PermissionSetting.vue'
import VoucherTemplate from './views/setting/VoucherTemplate.vue'
import DetailedAccounts from './views/accountbook/DetailedAccounts.vue'
import BindingWebchat from './views/personal/BindingWebchat.vue'
import GeneralLedger from './views/accountbook/GeneralLedger.vue'
import SubjectBalance from './views/accountbook/SubjectBalance.vue'
import TimeSeries from './views/accountbook/TimeSeries.vue'
import AuxiliaryAccountingDetail from './views/accountbook/AuxiliaryAccountingDetail.vue'
import SubjectSummary from './views/accountbook/SubjectSummary.vue'
import AuxiliaryAccountingBalance from './views/accountbook/AuxiliaryAccountingBalance.vue'
import ReportView from './views/report/ReportView.vue'
import VoucherForm from './views/voucher/VoucherForm.vue'
import Subject from './views/setting/Subject.vue'
import CashierAccount from './views/cashier/CashierAccount.vue'
import CashierIoType from './views/cashier/CashierIoType.vue'
import CashierJournal from './views/cashier/CashierJournal.vue'
import InternalTransfer from './views/cashier/InternalTransfer.vue'
import FundsReport from './views/cashier/FundsReport.vue'
import CheckUp from './views/cashier/CheckUp.vue'
import Assets from './views/assets/Assets.vue'
import AssetsType from './views/assets/AssetsType.vue'
import AssetsCheck from './views/assets/AssetsCheck.vue'
import AssetsSummary from './views/assets/AssetsSummary.vue'
import AssetsDepreciationList from './views/assets/AssetsDepreciationList.vue'
import Invoice from './views/invoice/Invoice.vue'
import BakUp from './views/setting/BakUp.vue'
import CalculateParameter from './views/setting/CalculateParameter.vue'
import CreateVoucher from './views/setting/CreateVoucher.vue'
import CompleteBasic from './views/setting/CompleteBasic.vue'
import ArchiveManagement from './views/setting/ArchiveManagement.vue'
import Salary from './views/salary/Salary.vue'
import SalaryCustomize from './views/salary/SalaryCustomize.vue'
import EmployeeInformation from './views/salary/EmployeeInformation.vue'
import SalaryVoucherTemplate from './views/salary/SalaryVoucherTemplate.vue'
import FiveOne from './views/salary/FiveOne.vue'
import FiveOneForm from './views/salary/FiveOneForm.vue'
import InsureIndex from './views/salary/InsureIndex.vue'
import ApplicationList from './views/workbench/ApplicationList.vue'
import ProductActivation from './views/workbench/ProductActivation.vue'
import PersonalCenter from './views/workbench/PersonalCenter.vue'
import TaxDeclaration from './views/taxdeclaration/TaxDeclaration.vue'
import BillLedger from './views/cashier/BillLedger.vue'
import BillLedgerAdd from './views/cashier/BillLedgerAdd.vue'
import BankCompany from './views/cashier/BankCompany.vue'
import FinancialReport from './views/taxdeclaration/FinancialReport.vue'
import FinancialReportUn from './views/taxdeclaration/FinancialReportUn.vue'
import TemplateManager from './views/setting/TemplateManager.vue'
import SetReset from './views/setting/SetReset.vue'
import OldAccount from './views/setting/OldAccount.vue'


const routes = [
	{name: 'Home', title: '让每个决策都有数据支撑', component: Home},
	{name: 'Voucher', title: '凭证列表', component: Voucher},
	{name: 'ElectronicArchives', title: '电子档案', component: ElectronicArchives},
	{name: 'BillInfo', title: '发票宝', component: BillInfo},
	{name: 'BillLedger', title: '票据台账', component: BillLedger},
	{name: 'BankCompany', title: '银企互联', component: BankCompany},
	{name: 'BillLedgerAdd', title: '新增票据', component: BillLedgerAdd},
	{name: 'VoucherForm', title: '凭证信息', component: VoucherForm},
	{name: 'CheckList', title: '期末结转', component: CheckList},
	{name: 'OptionLog', title: '操作日志', component: OptionLog},
	{name: 'ConnectJXC', title: '关联进销存', component: ConnectJXC},
	{name: 'Checkout', title: '结账', component: Checkout},
	{name: 'FiveOne', title: '五险一金', component: FiveOne},
	{name: 'FiveOneForm', title: '五险一金编辑', component: FiveOneForm},
	{name: 'UnCheckOut', title: '反结账', component: UnCheckOut},
	{name: 'CheckTemplate', title: '结转模板', component: CheckTemplate},
	{name: 'Check', title: '检查', component: Check},
	{name: 'BossSee', title: '老板看帐', component: BossSee},
	{name: 'Approve', title: '授权', component: Approve},
	{name: 'Account', title: '账套', component: Account},
	{name: 'Subject', title: '科目', component: Subject},
	{name: 'Initial', title: '期初', component: Initial},
	{name: 'VoucherWord', title: '凭证字', component: VoucherWord},
	{name: 'ConnectVerify', title: '关联进销存', component: ConnectVerify},
	{name: 'CalculateParameter', title: '设置进销存参数', component: CalculateParameter},
	{name: 'CompleteBasic', title: '完善基础资料', component: CompleteBasic},
	{name: 'CreateVoucher', title: '生成凭证', component: CreateVoucher},
	{name: 'VoucherTemplate', title: '凭证模版', component: VoucherTemplate},
	{name: 'RoleAdd', title: '新增角色', component: RoleAdd},
	{name: 'Currency', title: '币别', component: Currency},
	{name: 'AssistingAccounting', title: '辅助核算', component: AssistingAccounting},
	{name: 'PermissionSetting', title: '权限设置', component: PermissionSetting},
	{name: 'PersonalSetting', title: '个人设置', component: PersonalSetting},
	{name: 'ChangePassword', title: '修改密码', component: ChangePassword},
	{name: 'ChangePhoneNumber', title: '修改手机', component: ChangePhoneNumber},
	{name: 'BindingWebchat', title: '绑定微信', component: BindingWebchat},
	{name: 'DetailedAccounts', title: '明细账', component: DetailedAccounts},
	{name: 'GeneralLedger', title: '总账', component: GeneralLedger},
	{name: 'SubjectBalance', title: '科目余额', component: SubjectBalance},
	{name: 'SubjectSummary', title: '科目汇总', component: SubjectSummary},
	{name: 'AuxiliaryAccountingBalance', title: '辅助核算余额', component: AuxiliaryAccountingBalance},
	{name: 'AuxiliaryAccountingDetail', title: '辅助核算明细账', component: AuxiliaryAccountingDetail},
	{name: 'TimeSeries', title: '时序账', component: TimeSeries},
	{name: 'ReportView', title: '报表数据', component: ReportView},
	{name: 'CashierAccount', title: '账户设置', component: CashierAccount},
	{name: 'CashierIoType', title: '收支类别', component: CashierIoType},
	{name: 'CashierJournal', title: '日记账', component: CashierJournal},
	{name: 'InternalTransfer', title: '内部转账', component: InternalTransfer},
	{name: 'FundsReport', title: '内部转账', component: FundsReport},
	{name: 'CheckUp', title: '核对总账', component: CheckUp},
	{name: 'Assets', title: '固定资产管理', component: Assets},
	{name: 'AssetsType', title: '固定资产类型', component: AssetsType},
	{name: 'AssetsDepreciationList', title: '折旧明细表', component: AssetsDepreciationList},
	{name: 'AssetsSummary', title: '固定资产汇总', component: AssetsSummary},
	{name: 'AssetsCheck', title: '固定资产核对总账', component: AssetsCheck},
	{name: 'Invoice', title: '发票', component: Invoice},
	{name: 'BakUp', title: '备份恢复', component: BakUp},
	{name: 'ArchiveManagement', title: '归档管理', component: ArchiveManagement},
	{name: 'Salary', title: '工资管理', component: Salary},
	{name: 'SalaryCustomize', title: '自定义项目', component: SalaryCustomize},
	{name: 'EmployeeInformation', title: '员工信息', component: EmployeeInformation},
	{name: 'InsureIndex', title: '参保方案设置', component: InsureIndex},
	{name: 'ApplicationList', title: '应用列表', component: ApplicationList},
	{name: 'ProductActivation', title: '产品激活', component: ProductActivation},
	{name: 'PersonalCenter', title: '个人中心', component: PersonalCenter},
	{name: 'TaxDeclaration', title: '税务报表', component: TaxDeclaration},
	{name: 'SalaryVoucherTemplate', title: '薪资设置', component: SalaryVoucherTemplate},
	
	//my
	{name: 'FinancialReport', title: '财务报表申报', component: FinancialReport},
	{name: 'FinancialReportUn', title: '财务报表申报(无信息)', component: FinancialReportUn},
	{name: 'TemplateManager', title: '凭证模板', component: TemplateManager},
	{name: 'SetReset', title: '重置初始化', component: SetReset},
	{name: 'OldAccount', title: '旧账导入', component: OldAccount},
	
	
]

export const routeList = routes


export default {
	install(vue) {
		routes.forEach(item => vue.component(item.name, item.component))
	}
}
