<template>
  <div class="v-table-tr">
    <div @click="newRow" class="v-table-tr-plus"><i class="fa fa-plus-circle"></i></div>
    <div class='v-table-tr-sort'>{{rowIndex+1}}</div>
    <v-table-summary ref="summaryRef"
                     :summary="voucherDetail.summary"
                     :detail="voucherDetail"
                     :rowIndex="rowIndex"/>
    <v-table-subject ref="subjectRef"
                     :subjectList="subjectList"
                     :subjectMap="subjectMap"
                     :subject="voucherDetail.subject"
                     :auxiliary="voucherDetail.auxiliary"
                     :detail="voucherDetail"
                     :voucherDetailList='voucherDetailList'
                     @newRowLastAdd='newRowLastAdd'
                     :rowIndex="rowIndex"/>
    <v-table-debit ref="debitRef" :detail="voucherDetail" :debit="voucherDetail.debitAmount" :rowIndex="rowIndex"/>
    <v-table-credit ref="creditRef" :detail="voucherDetail" :credit="voucherDetail.creditAmount" :rowIndex="rowIndex"/>
    <div @click="rmRow" class="v-table-tr-minus"><i class="fa fa-minus-circle"></i></div>
  </div>
</template>

<script setup>
import VTableSummary from "@components/VoucherTable/VTableSummary.vue";
import VTableSubject from "@components/VoucherTable/VTableSubject.vue";
import VTableDebit from "@components/VoucherTable/VTableDebit.vue";
import VTableCredit from "@components/VoucherTable/VTableCredit.vue";
import {getCurrentInstance, onMounted, ref} from "vue";
import {getVTable} from "@components/VoucherTable/VtUtil";

const props = defineProps(['voucherDetail', 'rowIndex', 'subjectList', 'subjectMap','voucherDetailList']);

const summaryRef = ref(null);
const subjectRef = ref(null);
const debitRef = ref(null);
const creditRef = ref(null);
let VTableTr = null;

const newRow = () => {
  // console.log(voucherDetailList.value,'voucherDetailList')
  // console.log(props.rowIndex,'props.rowIndex11111111111')
  VTableTr.exposed.newRow(props.rowIndex);
}
const newRowLastAdd = (val) => {  
  VTableTr.exposed.newRow(val-1);
}

const rmRow = () => {
  VTableTr.exposed.rmRow(props.rowIndex);
}
const fatherMethod = () => {
  // console.log('props.2222')
}

onMounted(() => {
  // console.log('voucherDetailList666666666')
  // console.log(props.voucherDetailList,'voucherDetailList')
  VTableTr = getVTable(getCurrentInstance());
  VTableTr.exposed.pushColInstance(props.rowIndex, {
    summary: summaryRef.value,
    subject: subjectRef.value,
    debitAmount: debitRef.value,
    creditAmount: creditRef.value
  });
})
</script>

<style scoped lang="less">
.v-table {
  &-tr {
    height: 70px;
    border-bottom: @border;
    display: flex;
    position: relative;
    &-sort{
       width:80px;
       display:flex;
       align-items:center;
       justify-content:center;
       border-right:1px solid #eeee;
    }
    &:last-child {
      border-bottom: none;
    }

    &-plus, &-minus {
      display: none;
      position: absolute;
      font-size: 18px;
      cursor: pointer;
      top: 18px;
    }

    &-plus {
      left: -16px;
      color: @green-color;
    }

    &-minus {
      right: -16px;
      color: @red-color;
    }

    &:hover {
      .v-table-tr-plus, .v-table-tr-minus {
        display: inline-block;
      }
    }
  }
}
</style>
