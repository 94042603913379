<template>
  <div class="modal-column">
    <div class="modal-column-body">
      <Form ref="form" :rules="{}" :model="formData" mode="block">
        <FormItem label="模板名称" required prop="name">
          <Input placeholder="请输入模板名称" v-model="formData.name"/>
        </FormItem>
      </Form>
    </div>
    <div class="modal-column-between">
      <vxe-button icon="fa fa-close" @click="$emit('close')">取消</vxe-button>
      <vxe-button status="primary" icon="fa fa-save" @click="doSave()">保存模板</vxe-button>
    </div>
  </div>
</template>

<script>
/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
export default {
  name: "VoucherTplForm"
}
</script>

<script setup>
import voucher from "@/api/voucher";
import {reactive, ref, toRefs} from "vue";
import {pick} from "xe-utils";
import {message} from "heyui.ext";

const props = defineProps(['entity']);
const emits = defineEmits(['close', 'success']);

const {entity} = toRefs(props);

const loading = ref(false);
const form = ref(null);
const formData = reactive({
  name: ""
});

const doSave = () => {
  let validResult = form.value.valid();
  if (validResult.result) {
    loading.value = true;
    voucher.saveTpl(Object.assign({}, formData, pick(entity.value, ['details']))).then(() => {
      message.success("模板保存成功~");
      emits('success');
    }).finally(() => loading.value = false)
  }
}
</script>
