<template>
    <div class="h-full p-20px">
        <div class="h-full  flex flex-column">
            <Tabs :datas="['现金','银行存款']" class="px-20px bg-white-color" v-model="tab"/>
            <div class="flex p-16px justify-between bg-white-color">
                <div class="h-input-group">
                    <span>输入编码或名称:</span>
                    <Input style="margin-left: 10px;margin-right: 10px" placeholder="请输入编码或名称"
                           v-model="filterVal"/>
                    <Button icon="fa fa-search" color="primary" @click="loadList">查询</Button>
                </div>
                <div>

                    <Button icon="fa">导入</Button>
                    <Button @click="showForm()" color="primary" icon="fa fa-plus">新增</Button>
                </div>

            </div>
            <div class="flex-1">
                <vxe-table height="auto" ref="xTable" :loading="loading" show-overflow stripe :data="typeData">
                    <vxe-column title="编码" field="code"/>
                    <vxe-column title="账户名称" field="name"/>
                    <vxe-column title="银行卡号" field="cardNumber" v-if="showCardNum"/>
                    <vxe-column title="币别" field="currencyName"/>
                    <vxe-column title="会计科目" field="subjectName"/>
                    <vxe-column title="启用状态">
                        <Switch></Switch>

                    </vxe-column>
                    <vxe-column title="操作" :width="150" align="center">
                        <template #default="{row}">
                            <div class="actions">
                                <span @click="showForm(row)">编辑</span>
                                <span @click="remove(row)">删除</span>
                            </div>
                        </template>
                    </vxe-column>
                </vxe-table>
            </div>
        </div>
    </div>
</template>

<script>/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
import CashierAccount from "@/api/CashierAccount";
import {layer} from "@layui/layer-vue";
import {h} from "vue";
import CashierAccountForm from "@/views/cashier/CashierAccountForm";
import {padStart} from "xe-utils";

export default {
    name: "CashierAccount",
    data() {
        return {
            filterVal: null,
            tab: "现金",
            loading: false,
            dataList: []
        }
    },
    computed: {
        showCardNum() {
            return this.tab === '银行存款';
        },
        typeData() {
            return this.dataList.filter(val => val.type === this.tab)
        },
        nextCode() {
            return padStart((this.typeData.length + 1) + "", 3, '0')
        }
    },
    watch: {
        tab() {
            this.$refs.xTable.resetColumn(true);
        }
    },
    methods: {
        loadList() {
            this.loading = true
            CashierAccount.list(this.filterVal).then(({data}) => {
                this.dataList = data || [];
            }).finally(() => this.loading = false)
        },
        showForm(entity = null) {
            let layerId = layer.open({
                title: "账户信息",
                shadeClose: false,
                closeBtn: false,
                zIndex: 1000,
                area: ['400px', 'auto'],
                content: h(CashierAccountForm, {
                    entity,
                    type: this.tab,
                    nextCode: this.nextCode,
                    onClose: () => {
                        layer.close(layerId);
                    },
                    onSuccess: () => {
                        this.loadList();
                        layer.close(layerId);
                    }
                })
            });
        },
        remove(row) {
            this.$Confirm("确认删除?").then(() => {
                this.loading = true;
                CashierAccount.delete(row.id).then(() => {
                    this.loadList();
                }).finally(() => this.loading = true)
            })
        }
    },
    created() {
        this.loadList();
    }
}
</script>
