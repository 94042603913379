<template>
	<div >
		<Form class="root-login-account" ref="loginForm" :model="login" :rules="{}" :validOnChange="true">
			<div v-if="loginType === 1">
				<h3><p class="loginByPwd selected" @click="changeLoginType(1)">密码登录</p><p class="loginByCode" @click="changeLoginType(2)">短信登录</p></h3>
			</div>
			<div v-else>
				<h3><p class="loginByPwd" @click="changeLoginType(1)">密码登录</p><p class="loginByCode selected" @click="changeLoginType(2)">短信登录</p></h3>
			</div>
			<div v-if="loginType === 1">
				<FormItem :showLabel="false" required prop="account" label="账号">
					<div class="h-input h-input-prefix-icon">
						<i class="fa fa-user"></i>
						<Input v-model="login.account" type="text" placeholder="请输入账号" class="login-input"/>
					</div>
				</FormItem>
				<FormItem :showLabel="false" required prop="password" label="密码">
					<div class="h-input h-input-prefix-icon">
						<i class="fa fa-lock"></i>
						<Input v-model="login.password" placeholder="请输入密码" type="password" class="login-input"/>
					</div>
				</FormItem>
				<FormItem :showLabel="false" required prop="vcode" label="验证码">
					<div class="h-input h-input-prefix-icon">
						<i class="fa fa-check-circle"></i>
						<Input v-model="login.vcode" placeholder="请输入验证码" type="text" style="width: 63%" class="login-input"/>
						<div class="login-code">
							
							<img :src="codeUrl" @click="getCode">
						</div>
					</div>
				</FormItem>
			</div>
			<div v-else>
				<FormItem :showLabel="false" required prop="mobile" label="手机号">
					<div class="h-input h-input-prefix-icon">
						<i class="fa fa-user"></i>
						<Input v-model="login.mobile" type="text" placeholder="请输入手机号" class="login-input"/>
					</div>
				</FormItem>
				<FormItem :showLabel="false" required prop="code" label="验证码">
					<div class="h-input h-input-prefix-icon">
						<i class="fa fa-lock"></i>
						<Input v-model="login.code" type="text" placeholder="请输入验证码" maxlength="4" style="width: 68%" class="login-input"/>
						<SmsVerificationCode api="mobileLoginMsg" :mobile="login.mobile" class="login-input"/>
					</div>
				</FormItem>
			</div>
			<FormItem :showLabel="false" class="pb-10px">
				<SiderB />
			</FormItem>

			<FormItem :showLabel="false" class="!pb-0px">
				<Button @click="submit" :loading="loading" class="root-login-btn" block>登 录</Button>
				<div class="flex my-16px justify-between">
					<span class="text-hover" style="color: #9b9ea0;" @click="$emit('changeView','Registered')">立即注册</span>
					<span class="text-hover" style="color: #9b9ea0;" @click="$emit('changeView','ForgotPassword')">忘记密码</span>
				</div>

			</FormItem>
		</Form>
		<div style="font-size: 14px; margin-top: -10px;color: #9b9b9b;text-align: center">
			<input type="checkbox" v-model="isChecked" style="vertical-align:middle;">
			<a @click="openAgreement" style="vertical-align:middle; margin-left: 3px;cursor: pointer;">登录即同意《用户服务协议》</a>
		</div>
	</div>
	<UserAgreement ref="form"/>
</template>
<script>

	import code from "@/api/code";
	import SmsVerificationCode from "../../components/SmsVerificationCode"
	import UserAgreement from './UserAgreement'
	import SiderB from './SiderB'

	import axios from 'axios';
	export default {
		name: 'LoginForm',
		components: {SmsVerificationCode, UserAgreement,SiderB},
		data() {
			return {
				login: {
					mobile: localStorage.getItem("login_mobile"),
					password: "",
					code: "",
					vcode: "",
					uuid: "",
					account:""
				},
				loading: false,
				loginType: 1,
				codeUrl: '',
				isChecked: true
			}
		},
		mounted() {
		this.getCode()
		},
		created() {
			//this.getCode()
		},
		methods: {
			async getCode() {

				// code.getCodeImg().then(res => {
				// 	console.log('222222......')
				// 	this.codeUrl = res.img
				// 	this.login.uuid = res.uuid
				// }).catch((res) => {3
				// 	this.codeUrl = res.img
				// 	this.login.uuid = res.uuid
				// 	console.log('444444......')
				// 	this.loading = false
				// });
				// 图形验证码
				try {

				     let res2 = await axios.get("http://caiwu.perfectinfo.cn/api/auth/code");
			
						this.codeUrl = res2.data.img;
						this.login.uuid = res2.data.uuid;
						// 图形验证码
						// let res = await code.getCodeImg();
						// console.log(res,'resresresresres')
						// this.codeUrl = res.img;
					} catch (error) {
						console.log("123445" + error);
					}
										// code.getCodeImg().then(res => {

								// 	this.codeUrl = res.img
								// 	this.login.uuid = res.uuid
								// })
			},
			changeLoginType(type) {
				this.loginType = type
				this.login = {
					mobile: '',
					password: '',
					code: '',
					vcode: '',
				}
				if (type === 1) {
					this.getCode()
				}
			},
			submit() {
				if (this.loginType === 1) {
					let validResult = this.$refs.loginForm.valid();
					if (validResult.result) {
						// 不同意用户服务协议给提示
						if (!this.isChecked) {
							return;
						}
						this.loading = true
						this.$api.common.login(this.login).then(() => {
							localStorage.setItem("login_mobile", this.login.mobile);
							window.location.replace('/');
						}).catch(() => {
							this.loading = false
						});
					}
				}
				if (this.loginType === 2) {
					let validResult = this.$refs.loginForm.valid();
					if (validResult.result) {
						// 不同意用户服务协议给提示
						if (!this.isChecked) {
							return;
						}
						this.loading = true
						this.$api.common.mobilelogin(this.login).then(() => {
							localStorage.setItem("login_mobile", this.login.mobile);
							window.location.replace('/');
						}).catch(() => {
							this.loading = false
						});
					}
				}
			},
			openAgreement() {
				const _this = this.$refs.form
				_this.dialog = true
			}
		}
	}
</script>

<style lang="less" scoped>
	.root-login-btn {
		height: 40px;
		background-color: #006dfd;
		border-radius: 20px;
		color: #fff;
		cursor: default;
		width: 100%;
	}
	.root-login-btn:hover, .root-login-btn:focus {
		background-color: #006dfd;
		border-radius: 20px;
		color: #fff;
	}

	.login-code {
		width: 33%;
		display: inline-block;
		height: 45px;
		float: right;

		img {
			cursor: pointer;
			vertical-align: middle
		}
	}

	.root-login-account h3 p {
		color: #999999;
		font-size: 18px !important;
		font-weight: 400 !important;
		font-style: normal;
		text-align: center;
		line-height: 50px;
		box-sizing: border-box;
		width: 50%;
		display: inline-block;
		margin-bottom: 20px;
	}

	.login-input {
		height: 45px;
		border-radius: 6px;
	}

	.selected {
		color: #333333 !important;
		border-bottom: 2px solid #006eff !important;
	}
</style>
