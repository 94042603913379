<template>
  <Loading :loading="loading" text="报表数据计算中..."/>
  <vxe-toolbar class-name="px-8px">
    <template #buttons>
      <DatePicker v-if="dimension==='QUARTER'" v-model="voucherDate" type="quarter" :clearable="false"/>
      <account-date-choose v-else v-model="voucherDate"/>
    </template>
    <template #tools>
      <vxe-button :loading="dloading" v-if="deptList && deptList.length" @click="exportData" icon="fa fa-download" content="导出"/>
    </template>
  </vxe-toolbar>
  <div ref="reportContent" style="overflow: auto">
    <table class="cus-table" style="min-width: 100%">
      <thead class="header">
      <tr>
        <td rowspan="2">项目</td>
        <td colspan="2" v-for="dept in deptList">{{ dept.name }}</td>
      </tr>
      <tr>
        <template v-for="dept in deptList">
          <td class="text-right" width="120">本年累计金额122</td>
          <td class="text-right" width="120">本期金额</td>
        </template>
      </tr>
      </thead>
      <tbody>
      <template v-for="row in items">
        <report-row :report="report" :reportData="reportData" :row="row" :lv="0" :moretd="deptList">
          <template #default="{row,td}">
            <td class="text-right"><span v-if="reportData">{{ reportData[td.id] ? numFormat(reportData[td.id].beginYear[row.id]) : '' }}</span></td>
            <td class="text-right"><span v-if="reportData">{{ reportData[td.id] ? numFormat(reportData[td.id].endTerm[row.id]) : '' }}</span></td>
          </template>
        </report-row>
      </template>
      </tbody>
    </table>
  </div>
</template>

<script>
/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
export default {
  name: "XmlrReportView"
}
</script>

<script setup>

import ReportApi from "@/api/report";
import {ref, toRefs, watch} from "vue";
import {toArrayTree} from "xe-utils";
import ReportRow from "@/views/report/view/ReportRow";
import {useStore} from "vuex";
import manba from "manba";
import setting from "@/api/setting";
import jQuery from "jquery";
import Report from "@/api/report";
import {downloadBlob} from "download.js";

const store = useStore();
const {currentAccountSets} = store.state

const props = defineProps(['report', 'dimension']);

const {report, dimension} = toRefs(props);
const loading = ref(false);
const dloading = ref(false);
const reportContent = ref(null);
const voucherDate = ref(currentAccountSets.currentAccountDate)
const accountSetsName = ref(currentAccountSets.companyName)

const reportData = ref({
  beginYear: {},
  endTerm: {},
})


const items = ref([])

const deptList = ref(null)
setting.accountingCategoryDetails.loadByName("项目").then(({data}) => {
  deptList.value = data;

  items.value = toArrayTree(report.value.items);
})


const loadData = () => {
  loading.value = true;
  ReportApi.view(report.value.templateKey, report.value.id, {
    voucherDate: manba(voucherDate.value).startOf(manba.MONTH).format(),
    dimension: dimension.value
  }).then(({data}) => {
    reportData.value = Object.assign(reportData.value, data);
  }).finally(() => loading.value = false);
}
loadData()
watch(voucherDate, () => {
  loadData();
})


const exportData = () => {
  const clone = jQuery(reportContent.value).clone();
  clone.find('.report-edit-formulas').remove()
  let dataList = [];
  clone.find("tr").each((idx, tr) => {
    let row = [];
    jQuery(tr).find('td').each((idx, td) => {
      let val = jQuery(td).data('val');
      let lv = jQuery(td).data('lv') || 0;
      if (!val || val === '-0') {
        val = jQuery(td).text();
      }
      for (let i = 0; i < lv; i++) {
        val = "    " + val
      }
      row.push(val)
    })
    dataList.push(row);
  })
  dloading.value = true;
  Report.download({
    title: '项目利润' + (dimension.value === 'QUARTER' ? '季报' : ''),
    voucherDate: voucherDate.value,
    accountSetsName: `编制单位：${accountSetsName.value}`,
    dataList
  }).then((data) => {
    downloadBlob(manba().format("YYYYMMDDhhmmss") + "项目利润" + (dimension.value === 'QUARTER' ? '季报' : '') + ".xls", data)
  }).finally(() => dloading.value = false)
}
</script>
