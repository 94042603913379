<template>
	<div class="flex flex-column">
		
		<Form class="p-16px flex-1" ref="form" mode="twocolumn" :labelWidth="150" :model="form" :rules="validationRules">
			<FormItem label="单位名称:" prop="companyName">
				<Input type="text" v-model="form.companyName"/>
			</FormItem>
			<FormItem label="账套启用年月:" prop="enableDate">
				<DatePicker type="month" v-model="form.enableDate" :disabled="used" :clearable="false"/>
			</FormItem>
			<FormItem label="统一社会信用代码:">
				<Input type="text" v-model="form.creditCode"/>
			</FormItem>
			<FormItem label="会计准则:" prop="accountStandard">
				<Select :deletable="false" v-model="form.accountStandard" dict="accountingStandards" :disabled="!!form.id"/>
			</FormItem>
<!--			<FormItem label="单位所在地:">-->
<!--				<CategoryPicker ref="CategoryPicker" :option="pickerOption" type="key" showAllLevels v-model="form.address"></CategoryPicker>-->
<!--			</FormItem>-->
			<FormItem label="行业:">
				<Select v-model="form.industry" dict="industry"></Select>
			</FormItem>
			<FormItem label="固定资产模块:" prop="fiexdAssetType">
				<Radio v-model="form.fiexdAssetType" dict="enableRadios" :disabled="!!form.id"></Radio>
			</FormItem>
			<FormItem label="增值税种类:" prop="vatType">
				<Radio v-model="form.vatType" dict="vatRadios" :disabled="!!form.id"></Radio>
			</FormItem>
			<!-- <FormItem label="增值税种类:" prop="vatStatus">
				<Radio v-model="form.vatType" dict="enableRadios" :disabled="!!form.id"></Radio>
			</FormItem> -->
			<FormItem label="凭证审核:" prop="voucherReviewed">
				<Radio v-model="form.voucherReviewed" dict="needRadios"></Radio>
			</FormItem>
			<!-- <FormItem label="关联进销存:" prop="voucherReviewed">
				<Radio v-model="form.voucherReviewed" dict="enableRadios"></Radio>
			</FormItem> -->
			<FormItem label="关联进销存:" prop="jxc">
				<Radio v-model="form.jxc" dict="needRadios"></Radio>
			</FormItem>
		</Form>
		<div class="text-center">
			<Button @click="$emit('close')" :loading="loading">取消</Button>
			<Button color="primary" @click="submit" :loading="loading">{{ form.id ? '更新' : '创建' }}账套</Button>
		</div>
	</div>
</template>

<script>
import {getTotalData} from "@/js/locations/district";
import {clone} from "xe-utils";
import moment from "moment/moment";

/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */

const emptyForm = {
	"accountStandard": "SMALL_BUSINESS",
	"address": null,
	"companyName": "",
	"creditCode": "",
	"enableDate": null,
	"voucherReviewed": false,
	"industry": null,
	"vatType": "SMALL_SCALE",
	"vatStatus": "SMALL_SCALE",
	"fiexdAssetType": "SMALL_SCALE",
	"jxc":true,
};

export default {
	name: "AccountForm",
	props: {
		entity: Object,
	},
	data() {
		return {
			loading: false,
			pickerOption: {
				keyName: 'id',
				titleName: 'title',
				dataMode: 'list',
				parentName: 'parentId',
				datas: getTotalData()
			},
			form: clone(emptyForm),
			validationRules: {
				required: ["companyName", "enableDate", "accountingStandards", "fixed_asset_module", "cashier_module", "voucherReviewed", "vatType"]
			},
		}
	},
	methods: {
		submit() {
			let validResult = this.$refs.form.valid();
			if (validResult.result) {
				this.loading = true;
				this.form.enableDate = moment(this.form.enableDate).format("YYYY-MM-DD");
				this.$api.setting.accountSets[this.form.id ? 'update' : 'save'](this.form).then(() => {
					window.location.reload();
				}).catch(() => {
					this.loading = false;
				})
			}
		}
	},
	created() {
		if (this.entity) {
			this.form = clone(this.entity);
		}
	}
}
</script>
