<template>
	<div class="login-frame">
		<Form class="login" ref="loginForm" :model="login" :rules="{}" :validOnChange="true">
			<FormItem :showLabel="false" required prop="mobile" label="手机号111">
				<div class="h-input h-input-prefix-icon">
					<i class="fa fa-user"></i>
					<Input v-model="login.mobile" type="text" placeholder="请输入手机号" class="h-42px"/>
				</div>
			</FormItem>
			<FormItem :showLabel="false" required prop="code" label="验证码" class="my-16px">
				<div class="h-input-group">
					<Input v-model="login.code" type="text" placeholder="验证码" maxlength="4"/>
					<SmsVerificationCode api="mobileLoginMsg" :mobile="login.mobile"/>
				</div>
			</FormItem>
			<FormItem :showLabel="false" class="!pb-0px">
				<Button @click="submit" :loading="loading" style="border-radius: 30px;" color="primary" block>登 录</Button>
				<div class="flex my-16px justify-between">
					<span class="text-hover" @click="$emit('changeView','LoginForm')">账号登录</span>
					<span class="text-hover" @click="$emit('changeView','Registered')">立即注册</span>
					<span @click="$emit('changeView','ForgotPassword')" class="placeholder forgot-password text-hover">忘记密码</span>
				</div>

			</FormItem>
		</Form>
	</div>
</template>
<script>
import SmsVerificationCode from "../../components/SmsVerificationCode"

export default {
	name: 'Registered',
	components: {SmsVerificationCode},
	data() {
		return {
			login: {
				mobile: "",
				code: "",
			},
			loading: false
		};
	},
	methods: {
		submit() {
			let validResult = this.$refs.loginForm.valid();
			if (validResult.result) {
				this.loading = true
				this.$api.common.mobilelogin(this.login).then(() => {
					// localStorage.setItem("login_mobile", this.login.mobile);
					window.location.replace('/');
				}).catch(() => {
					this.loading = false
				});
			}
		},
	}
}
</script>
