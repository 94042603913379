<template>
	<div class="mb-16px" v-if="datas.length<12"  style="display: flex;justify-content: end">
		<Button color="primary" @click="showForm()">自定义辅助核算</Button>
	</div>
	<div>
		<vxe-table :loading="loading" :data="datas" >
			<vxe-column title="类别编号" field="id"></vxe-column>
			<vxe-column title="类别名称" field="name"></vxe-column>
			<vxe-column title="操作" :width="150" align="center">
				<template #default="{row}">
					<div class="actions" v-if="!row.systemDefault && row.canEdit">
						<span @click="showForm(row)">编辑</span>
						<span @click="remove(row)">删除</span>
					</div>
				</template>
			</vxe-column>
		</vxe-table>
	</div>
</template>
<script>

import {h} from "vue";
import setting from "@/api/setting";
import {layer} from "@layui/layer-vue";
import CategoryForm from "@/views/setting/AssistingAccounting/form/CategoryForm.vue";

export default {
	name: "AccountingCategory",
	emits: ['reloadTabs'],
	data() {
		return {
			datas: [],
			loading: false
		};
	},
	methods: {
		loadList() {
			this.loading = true;
			setting.accountingCategory.list().then(({data}) => {
				this.datas = data || [];
			}).finally(() => this.loading = false)
		},
		reloadTabs() {
			this.$emit('reloadTabs')
		},
		showForm(entity) {
			let layerId = layer.open({
				title: (entity ? entity.name : "") + "辅助项信息",
				shadeClose: false,
				closeBtn: !entity || entity.canEdit ? false : 2,
				zIndex: 1000,
				area: ['900px', '500px'],
				content: h(CategoryForm, {
					entity,
					onClose: () => {
						layer.close(layerId);
					},
					onSuccess: () => {
						layer.close(layerId);
						this.loadList();
						if (!entity) {
							this.reloadTabs();
						}
					}
				})
			});
		},
		remove(row) {
			this.$Confirm("确认删除?").then(() => {
				setting.accountingCategory.delete(row.id).then(() => {
					this.loadList();
					this.reloadTabs();
				})
			})
		}
	},
	mounted() {
		this.loadList();
	}
};
</script>
<style lang='less'>
div.h-input-suffix-icon {
  .h-icon-trash {
    display: none;
    cursor: pointer;
  }

  &:hover {
    .h-icon-trash {
      display: inline-block;
    }
  }
}
</style>
