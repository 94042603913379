import {createStore} from 'vuex'
import Common from './api/common'
import menuConfig from './js/config/menu-config';
import setting from "@/api/setting";
import {ArrToObj} from "@/js/common/utils";
// import data from "@/js/common/utils";
import dataAll from "./dataAll.js";

const SubjectType = {
    ASSET: "资产",
    LIABILITY: "负债",
    RIGHTS: "权益",
    COST: "成本",
    INCOME: "损益",
    COMMON: "共同",
    NET_ASSETS: "净资产",
    REVENUE: "收入",
    EXPENSES: "费用"
};

export default createStore({
    modules: {
        dataAll,
    },
    
    state: {
        perms:['delete','delete2'],
        User: {
            name: '夏悸',
            desc: '执着于理想，纯粹于当下',
            email: 'HeyUI@some.com',
            org: '某某公司',
            dept: '某某部门',
            title: '前端开发工程师',
            location: '上海市',
            avatar: '/images/avatar.png',
            tags: ['善解人意', '耿直']
        },
        currentAccountSets: {},
        myAccountSets: [],
        tabs: [
            {
                title: "首页",
                key: "Home"
            },
            // {
            //     title: "关闭所有页面",
            //     key: "CloseAllTab"
            // }
        ],
        subjectType: [],
        menus: [],
        currentTab: 'Home',
        
        currencyMap: {},
        sliderCollapsed: false
    },
    
    mutations: {
        newTab(state, tab) {
            let find = state.tabs.find(val => val.key === tab.key);
            if (!find) {
                state.tabs.push(tab);
            }
            state.currentTab = tab.key;
        },

        changeTab(state, tabKey) {
            state.currentTab = tabKey;
        },
        updateCurrency(state, currencyMap) {
            state.currencyMap = currencyMap;
        },
        SetTab(state, tab) {
            state.tabs = [...tab]
        },
        refreshTab(state, tab) {
            let find = state.tabs.findIndex(val => val.key === tab.key);
            if (find !== -1) {
                state.tabs.splice(find, 1);
            }
            setTimeout(() => {
                state.tabs.push(tab);
            })
            state.currentTab = tab.key;
        },
        refreshHomeTab(state, tab) {
            state.tabs.push(tab)
            setTimeout(() => {
                state.tabs.splice(state.tabs.length - 1, 1)
            })
            state.currentTab = tab.key;
        },
        closeTab(state, tabKey) {
            console.log('closeTab')
            // 关闭时判断一下是否为选中中值,为选中值是将currentTab赋值到前一个tab项
            if(tabKey ==  state.currentTab){
                let find = state.tabs.findIndex(val => val.key === tabKey);
                if (find !== -1) {
                    let next = Math.max(0, find - 1);
                    state.currentTab = state.tabs[next].key;
                    state.tabs.splice(find, 1);
                }
            }else{
                let find = state.tabs.findIndex(val => val.key === tabKey);
                if (find !== -1) {
                    state.tabs.splice(find, 1);
                }
            }

            
        },
        closeOther(state, tabKey) {
           let tab = {}
           state.tabs.map((val)=>{
              if(val.key == tabKey)  tab = val
           })
        //    state.tabs = [],
           state.tabs = [ { title: "首页", key: "Home" },tab]
        },
        updateAccount(state, data) {
            state.User = data;
            state.currentAccountSets = data.accountSets;
            if (data.accountSets) {
                state.subjectType = data.accountSets.subjectCategory.map(val => SubjectType[val]);
            }
            state.myAccountSets = data.accountSetsList;
            
            const LOGINSTATUS = localStorage.getItem("LOGINSTATUS")
            // console.log(LOGINSTATUS,'LOGINSTATUS')
            if (LOGINSTATUS) {
                state.menus = menuConfig['EnterpriseWorkbench'];
                // console.log(state.menus,'111')
            } else {
                state.menus = menuConfig[data.role];
                // console.log(state.menus,data.role,'222')
            }
        },
        updateSliderCollapse(state, isShow) {
            setTimeout(() => {
                G.trigger('page_resize');
            }, 600);
            state.sliderCollapsed = isShow;
        },
        setPerms(state, data) {
            state.perms = data;
        }
    },
    actions: {
        init(context) {
            return new Promise((resolve, reject) => {
                Common.init().then(({data}) => {
                    // console.log('updateAccount', data.user)
                    // console.log('updateCurrency', data.currency)
                    context.commit('updateAccount', data.user);
                    context.commit('updateCurrency', data.currency);
                    resolve(data)
                }).catch(() => {
                    reject();
                })
            });
        },
        updateCurrency(context) {
            setting.currency.list().then(({data}) => {
                context.commit('updateCurrency', ArrToObj(data, val => val.id));
            })
        }
    },
    getters: {
        /**
         * 本位币
         * @param state
         * @returns {unknown}
         */
        perms: state => state.perms,
        localCurrency: state => {
            return Object.values(state.currencyMap).find(val => val.localCurrency)
        },
        tabMap: state => {
            const map = {};
            state.tabs.forEach(item => {
                map[item.key] = item;
            })
            return map;
        },
        account: state => {
            return state.User;
        },
        checkoutList: state => {
            return state.User.checkoutList;
        },
        firstCheckoutDate: state => {
            let reverse = state.User.checkoutList.filter(val => val.status !== 2).reverse();
            if (reverse.length) {
                return reverse[0].checkDate;
            }
            return null;
        },
        checkoutSelect: state => {
            return state.User.checkoutList.map(row => {
                return {
                    key: row.checkYear + '-' + row.checkMonth,
                    title: row.checkYear + '年' + row.checkMonth + "月",
                }
            });
        },
        sliderCollapsed: state => {
            return state.sliderCollapsed;
        }
    }
})
