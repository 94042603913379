/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
import Ajax from '../js/common/ajax';
import Qs from "qs";

export default {
	list(filter = null) {
		return Ajax.get("/cashier/io", {filter})
	},
	delete(id) {
		return Ajax.delete(`/cashier/io/${id}`)
	},
	save(params = {}) {
		return Ajax.post(`/cashier/io`, params)
	},
	templateList(accountType, type) {
		return Ajax.get("/cashier/io/template", {accountType, type})
	},
	templateDelete(id) {
		return Ajax.delete(`/cashier/io/template?` + Qs.stringify({templateId: id}, {arrayFormat: 'repeat'}))
	},
	templateSave(params = {}) {
		return Ajax.post(`/cashier/io/template`, params)
	}
}
