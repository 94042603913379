<template>
  <div>
    <div class="flex p-16px justify-between bg-white-color">
      <div class="h-input-group">
        <!-- <Input placeholder="请输入编码或名称" v-model="params.filterVal"/>
        <Button icon="fa fa-search" color="" @click="doSearch">查询</Button> -->
        <Select v-model="select3" autosize placeholder="请选择" :datas="param3"></Select>
      </div>
      <Button color="" @click="onClickItem">生成凭证</Button>
      
    </div>
    <div style="height: calc(100vh - 300px)">
      <vxe-table height="auto" ref="xTable" 
                 :checkbox-config="{checkMethod:checkMethod,visibleMethod:checkMethod}"
                 :loading="loading" show-overflow stripe :data="dataList">
        <vxe-column type="checkbox" width="40" align="center"/>
        <vxe-column title="变动单类别" field="changeType"/>
        <vxe-column title="资产编号" field="code"/>
        <vxe-column title="资产名称" field="assetsName"/>
        <vxe-column title="变动前内容" field="beforeChange"/>
        <vxe-column title="变动后内容" field="afterChange"/>
        <vxe-column title="变动期间" field="checkDate" width="100" align="center"/>
        <vxe-column title="关联凭证" field="voucher" width="80" align="center">
          <template #default="{row}">
            <router-link class="text-hover primary-color" width="1024px"
                         :to="{name:'VoucherForm',params:{voucherId:row.voucherId}}">{{ row.voucher }}
            </router-link>
          </template>
        </vxe-column>
        <vxe-column title="操作" :width="80" align="center" width="110" fixed="right">
          <template #default="{row}">
            <div class="actions" v-if="(!row.voucherId && row.changeType!=='新增') || ['原值调整', '累计折旧调整', '资产处置'][row.changeType]">
              <span>删除</span>
            </div>
          </template>
        </vxe-column>
      </vxe-table>
      <div class="bg-white-color p-10px">
        <Pagination align="right" v-model="pagination" @change="pageChange" small/>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */

import {layer} from "@layui/layer-vue";
import {h} from "vue";
import {padStart} from "xe-utils";
import {message} from "heyui.ext";
import Assets from "@/api/Assets";
import VoucherForm from "@/views/voucher/VoucherForm";

export default {
  name: "AssetsChange",
  data() {
    return {
      params: {
        filterVal: null,
      },
      loading: false,
      dataList: [],
      pagination: {
        page: 1,
        size: 20,
        total: 0
      },
      param3: {  a1: '6月2', a2: '6月1', a3: '6月2' },
      select3:'a1',
    }
  },
  computed: {
    queryParams() {
      return Object.assign(this.params, {
        page: this.pagination.page,
        pageSize: this.pagination.size
      })
    },
    nextCode() {
      return padStart((this.pagination.total + 1) + "", 3, '0')
    }
  },
  methods: {
    onClickItem() {
      let checkedRows = this.$refs.xTable.getCheckboxRecords();
      if (checkedRows.length <= 0) {
        message.error("请至少选择一项!")
        return
      }
      if (new Set(checkedRows.map(val => val.checkDate)).size > 1) {
        message.error("请相同期间数据~");
        return
      }

      const loadId = layer.load(2);
      Assets.Change.generateVoucher(checkedRows.map(val => val.id)).then(({data}) => {
        if (!data.length) {
          message("选择记录无需生成凭证~")
          return
        }
        let layerId = layer.drawer({
          title: "生成的凭证",
          shadeClose: false,
          zIndex: 1000,
          area: ['1200px', '100vh'],
          content: h(VoucherForm, {
            assetsData: data, voucherDate: (checkedRows[0].checkDate + "01").replace(/[年月]/ig, '-'),
            onClose: () => {
              layer.close(layerId);
            },
            onSuccess: (voucherId) => {
              layer.load(2);
              Assets.Change.relateVoucher(checkedRows.map(val => val.id), voucherId).then(() => {
                this.loadList();
              }).finally(() => layer.closeAll())
            }
          })
        });
      }).finally(() => layer.close(loadId))
    },
    checkMethod({row}) {
      return !row.voucherId || ['新增', '原值调整', '累计折旧调整', '资产处置'][row.changeType];
    },
    pageChange() {
      this.loadList();
    },
    doSearch() {
      this.pagination.page = 1;
      this.loadList();
    },
    loadList() {
      this.loading = true
      Assets.Change.list(this.queryParams).then(({data}) => {
        this.dataList = data.results || [];
        this.pagination.total = data.total;
      }).finally(() => this.loading = false)
    },
    remove(row) {
      this.$Confirm("确认删除?").then(() => {
        this.loading = true;
        Assets.Change.delete(row.id).then(() => {
          this.loadList();
        }).finally(() => this.loading = true)
      })
    }
  },
  created() {
    this.loadList();
  }
}
</script>
