<template>
  <div class="v-table-td-debit">
    <v-table-number ref="numberRef" field="debitAmount" :rowIndex="rowIndex" @onEnter="onEnter" @cancel-edit="cancelEdit" :number="number"/>
  </div>
</template>
<script setup>
import VTableNumber from "@components/VoucherTable/VTableNumber.vue";
import {defineExpose, getCurrentInstance, onMounted, ref, watch} from "vue";
import {getVTable} from "@components/VoucherTable/VtUtil";
import {divide, round} from "xe-utils";

const props = defineProps(['debit', 'rowIndex', 'detail']);

const number = ref(null)
const numberRef = ref(null)
let VTable = null;

const cancelEdit = (numVal) => {
	number.value = numVal;
	props.detail.price = round(divide(numVal, props.detail.num), 2);
	VTable.exposed.updateFiled(props.rowIndex, 'debitAmount', numVal);
}

const onEnter = (numVal) => {
	cancelEdit(numVal);
	if (number.value) {
		VTable.exposed.next(props.rowIndex + 1, 'summary');
	} else {
		VTable.exposed.next(props.rowIndex, 'creditAmount');
	}
}

onMounted(() => {
	VTable = getVTable(getCurrentInstance())
	number.value = props.debit;
})

watch(() => props.debit, (val) => {
	number.value = val;
})

defineExpose({
	doEdit: () => numberRef.value.doEdit(),
	endEdit: () => numberRef.value.cancelEdit()
})

</script>

<style scoped lang="less">
.v-table {

  &-td {
    &-debit {
      width: 221px;
      border-right: @border;
      background-image: url("../../assets/moneyUint.png");
    }
  }
}
</style>
