<template>
    <div class="h-full p-20px">
        <div class="h-full" style="display: flex;flex-direction: column">

            <vxe-toolbar class="px-16px" style="margin-bottom: 10px">
                <template #buttons>
                    <div class="flex items-center" style="color: #2FA8FD;font-size: 16px">
                        进销存+财务管理一体化操作视频
                    </div>
                </template>
            </vxe-toolbar>
            <div class="">
                <vxe-table border="none" height="200px" ref="xTable" :loading="dataLoading" :data="dataList">
                    <vxe-column title="关联状态" field="status"/>
                    <vxe-column title="财务软件套账" field="caiwuname"/>
                    <vxe-column title="进销存套账" field="jxcname"/>
                    <vxe-column title="操作" width="120">
                        <template #default="{row}">
                            <div class="actions">
                                <span class="h-icon-edit" @click="showForm(row)"> 编辑</span>
                            </div>
                        </template>
                    </vxe-column>
                </vxe-table>
            </div>
            <div lass="ml-20px  mb-16px" style="display: flex">
                <div class="connecttitle"/>
                <div style="margin-left: 10px;  font-size: 18px;flood-color: #333333;">操作指引</div>
            </div>
            <div style="display: flex;justify-content: center;align-items: center;margin-top: 20px">
                <div class="connectstep" @click="recover">
                    <img src="../../assets/connect_1.png">
                    <div style="color: #2FA8FD;font-size: 18px;margin-top: 20px">关联进销存账套</div>
                    <div style="color: #999999;font-size: 16px;margin-top: 15px">
                        只有同时拥有财务软件和进销存的账套管理员才有权限设置关联
                    </div>
                </div>
                <div style="margin-left: 10px;margin-right: 10px">
                    <img src="../../assets/connect_middle.png">
                </div>
                <div class="connectstep" @click="settingCalculate">
                    <img src="../../assets/connect_2.png">
                    <div style="color: #2FA8FD;font-size: 18px;margin-top: 20px">设置进销存核算参数</div>
                    <div style="color: #999999;font-size: 16px;margin-top: 15px">检查确定进销存核算参数的设置</div>
                </div>
                <div style="margin-left: 10px;margin-right: 10px">
                    <img src="../../assets/connect_middle.png">
                </div>
                <div class="connectstep" @click="CompleteBasic">
                    <img src="../../assets/connect_3.png">
                    <div style="color: #2FA8FD;font-size: 18px;margin-top: 20px">完善进销存基础资料</div>
                    <div style="color: #999999;font-size: 16px;margin-top: 15px">
                        设置进销存基础资料与财务软件账套的会计科目之间的对应关系
                    </div>
                </div>
                <div style="margin-left: 10px;margin-right: 10px">
                    <img src="../../assets/connect_middle.png">
                </div>
                <div class="connectstep" @click="goCreate()">
                    <img src="../../assets/connect_4.png">
                    <div style="color: #2FA8FD;font-size: 18px;margin-top: 20px">自动生成凭证</div>
                    <div style="color: #999999;font-size: 16px;margin-top: 15px">选择进销存单据生成凭证</div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
export default {
    name: "BakUp",
    methods: {
        settingCalculate() {
            this.$store.commit('newTab', {key: "CalculateParameter", title: "关联进销存"})
        }, CompleteBasic() {
            this.$store.commit('newTab', {key: "CompleteBasic", title: "关联进销存"})
        },
        goCreate() {
            this.$store.commit('newTab', {key: "CreateVoucher", title: "关联进销存"})
        },
    }
}
</script>

<script setup>
import {confirm, loading} from "heyui.ext";
import {ref, h} from "vue";
import setting from "@/api/setting";
import AppVerify from "@/views/setting/AppVerify";
import {layer} from "@layui/layer-vue";
import createVoucher from "./CreateVoucher.vue";

const dataLoading = ref(false)
const dialog = ref(false)
const afterAuth = ref(false)
const dataList = ref([{
    status: '未关联',
    caiwuname: '财务软件套账',
    jxcname: '进销存套账',
}])
const fileInput = ref(null)
const ioType = ref('备份')

const loadList = () => {
    dataLoading.value = false;
    // setting.backup.list().then(({data}) => {
    //     dataList.value = data;
    // }).finally(() => dataLoading.value = false)
}
loadList();

const backUp = () => {
    loading("备份中...")
    setting.backup.backup().then(() => {
        loadList();
    }).finally(() => loading.close())
}

const remove = (row) => {
    confirm({
        content: "确认删除此备份?",
        async: true,
        onConfirm(resolve) {
            loading("删除中...")
            setting.backup.delete(row.id).then(() => {
                loadList();
            }).finally(() => {
                loading.close();
                resolve(true);
            })
        }
    })
}
const recover = (entity) => {
    let layerId = layer.open({
        title: "记账APP验证",
        shadeClose: false,
        closeBtn: false,
        zIndex: 1000,
        area: ['700px', '600px'],
        content: h(AppVerify, {
            entity,
            onClose: () => {
                layer.close(layerId);
            },
            onSuccess: () => {
                layer.close(layerId);
            }
        })
    });
}
const showDialog = () => {
    console.log(11111)
    this.dialog = true;
}
const uploadBackUp = () => {
    if (fileInput.value.files.length) {
        loading("上传中...")
        const formData = new FormData();
        formData.append("file", fileInput.value.files[0]);
        setting.backup.upload(formData).then(() => {
            loadList();
        }).finally(() => loading.close())
    }

}
</script>

<style>

.connecttitle {
    width: 4px;
    height: 24px;
    background: #2FA8FD;
    border-radius: 6px 6px 6px 6px;
    opacity: 1;
}

.connectstep {
    display: flex;
    flex-direction: column;
    padding: 15px;
    justify-content: center;
    align-items: center;
    width: 306px;
    height: 306px;
    background: rgba(47, 168, 253, 0.06);
    border-radius: 4px 4px 4px 4px;
}
</style>
