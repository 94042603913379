<template>
  <Select @change="changeAccountSets" placeholder="请输入账套名称" v-if="currentAccountSets" :datas="myAccountSets" v-model="currentAccountSetsId" autosize :deletable="false" style="display: inline-block;margin-right: 20px;min-width: 300px" filterable keyName="id" titleName="companyName"></Select>
</template>
<script>
  import {mapState} from 'vuex';
  import {loading, message} from "heyui.ext";

  export default {
    name: 'AppHeaderAccountSets',
    data() {
      return {
        messageList: [],
        currentAccountSetsId: null,
        searchValue: ''
      };
    },
    methods: {
      changeAccountSets(as) {
        if (as.id !== this.currentAccountSets.id) {
          loading("正在切换，请稍后...");
          this.$api.common.changeAccountSets(as.id).then(() => {
            message('切换成功！');
            window.location.replace("/")
          });
        }
      }
    },
    computed: {
      ...mapState(['currentAccountSets', 'myAccountSets'])
    },
    watch: {
      'currentAccountSets.id'(id) {
        this.currentAccountSetsId = id;
      }
    },
    created() {
      if (this.currentAccountSets) {
        this.currentAccountSetsId = this.currentAccountSets.id;
      }
    }
  };
</script>
<style lang='less'>
  .app-header-message-vue {
    float: left;
    margin-right: 10px;

    .h-dropdowncustom-show {
      height: @layout-header-height;

      .app-header-icon-item {
        margin-right: 0;
      }

      &.h-pop-trigger {
        background: @hover-background-color;
      }
    }
  }

  .app-account-sets-dropdown-dropdown-container {
    width: 300px;
    min-height: 300px;

    .common-list-container {
      .common-list-item {
        cursor: pointer;
        padding: 10px;

        &:hover {
          background: @hover-background-color;
        }

        &.current {
          background: var(--primary);
          color: @white-color;
        }
      }
    }
  }
</style>
