<template>
  <div class="h-input-group">
    <Select :datas="dateList" v-model="accountDate.start" :deletable="false" filterable @change="changVal"/>
    <span class="h-input-addon">至</span>
    <Select :datas="dateList" v-model="accountDate.end" :deletable="false" filterable @change="changVal"/>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: "AccountDateRangeChoose",
  props: {
    modelValue: [Object],
  },
  computed: {
    ...mapState(['User', 'currentAccountSets']),
    dateList() {
      let arr = [];
      this.User.checkoutList.forEach(item => {
        arr.push({
          key: item.checkYear + "-" + ((item.checkMonth < 9 ? '0' : '') + item.checkMonth) + "-01",
          title: item.checkYear + "年" + ((item.checkMonth < 9 ? '0' : '') + item.checkMonth) + "期",
        })
      })
      return arr;
    }
  },
  data() {
    return {
      accountDate: {
        start: null,
        end: null,
      }
    }
  },
  watch: {
    modelValue(val) {
      this.accountDate = Object.assign({}, this.accountDate, val);
    }
  },
  methods: {
    changVal() {
      this.$emit('update:modelValue', this.accountDate);
    }
  },
  mounted() {
    if (this.modelValue) {
      this.accountDate = Object.assign({}, this.accountDate, modelValue);
    } else {
      // console.log(this.currentAccountSets.currentAccountDate)
      this.accountDate.start = this.currentAccountSets.currentAccountDate;
      this.accountDate.end = this.currentAccountSets.currentAccountDate;
      this.$emit('update:modelValue', this.accountDate);
    }
  }
}
</script>
