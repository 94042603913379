<template>
    <div class="frame-page h-full !m-0px p-20px flex flex-column">
        <div style="display: flex">
            <div class="title"/>
            <div style="margin-left: 10px;  font-size: 18px;flood-color: #333333;">工资总额</div>
        </div>
        <div style="display: flex;align-items: center;margin-top: 15px">
            <div style="color: #333333;font-size: 16px">项目：</div>
            <Input value="工资" style="width: 100px;margin-left: 10px;margin-right: 10px"/>
            <Input value="津贴补助" style="width: 100px;margin-left: 10px;margin-right: 10px"/>
            <Input value="奖金" style="width: 100px;margin-left: 10px;margin-right: 10px"/>
            <Input value="其他所得" style="width: 100px;margin-left: 10px;margin-right: 10px"/>
            <Input value="自定义" style="width: 100px;margin-left: 10px;margin-right: 10px"/>
            <div class="btn">添加列</div>
        </div>
        <div style="display: flex;margin-top: 20px">
            <div class="title"/>
            <div style="margin-left: 10px;  font-size: 18px;flood-color: #333333;">应扣工资</div>
        </div>
        <div style="display: flex;align-items: center;margin-top: 15px">
            <div style="color: #333333;font-size: 16px">项目：</div>
            <Input value="考勤扣款" style="width: 100px;margin-left: 10px;margin-right: 10px"/>
            <Input value="社保扣款" style="width: 100px;margin-left: 10px;margin-right: 10px"/>
            <Input value="其他扣款" style="width: 100px;margin-left: 10px;margin-right: 10px"/>
            <div class="btn">添加列</div>
        </div>
        <div style="display: flex;margin-top: 20px">
            <div class="title"/>
            <div style="margin-left: 10px;  font-size: 18px;flood-color: #333333;">应扣工资</div>
        </div>
        <div style="display: flex;align-items: center;margin-top: 15px">
            <div style="color: #333333;font-size: 16px">项目：</div>
            <Input value="自定义" style="width: 100px;margin-left: 10px;margin-right: 10px"/>
            <div class="btn">添加列</div>
        </div>
        <div style="display: flex;align-items: center;margin-top: 300px;justify-content: center">
                <div class="btn" @click="goback">保存</div>
                <div class="cancelBtn" @click="goback">取消</div>
        </div>

    </div>
</template>
<script>
export default {
    name: "SalaryCustomize",
    methods:{
        goback(){
            this.$store.commit('newTab', {key: 'Salary', title: "凭证列表"})
        }
    }
}
</script>

<style lang='less' scoped>
.title {
    width: 4px;
    height: 24px;
    background: #2FA8FD;
    border-radius: 6px 6px 6px 6px;
    opacity: 1;
}

.btn {
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 16px;
    margin-left: 10px;
    color: #FFFFFF;
    width: 100px;
    height: 35px;
    background: #2FA8FD;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
}

.cancelBtn {
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 16px;
    margin-left: 10px;
    background: rgba(250, 250, 250, 0);
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    border: 1px solid #999999;
    color: #333333;
    width: 100px;
    height: 35px;
}
</style>
