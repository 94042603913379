<template>
	<div class="modal-column">
		<div class="modal-column-body">
			<Form mode="block">
				<FormItem label="整理期间">
					<Select :datas="checkoutList" type="object" keyName="id" v-model="voucherDate" :deletable="false">
						<template v-slot:show="{value}">
							{{ value.checkYear }}年{{ value.checkMonth }}期
						</template>
						<template v-slot:item="{ item }">
							{{ item.checkYear }}年{{ item.checkMonth }}期
						</template>
					</Select>
				</FormItem>
			</Form>
		</div>
		<div class="modal-column-between">
			<Button @click="$emit('close')" :loading="loading" icon="fa fa-close">取消</Button>
			<Button :disabled="!voucherDate" @click="doSubmit" :loading="loading" color="primary">开始整理</Button>
		</div>
	</div>
</template>

<script>
/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
export default {
	name: "FinishingOffNo"
}
</script>

<script setup>
import {ref} from "vue";
import {useStore} from "vuex";
import voucher from "@api/voucher";

let store = useStore();

const emits = defineEmits(['success', 'close'])
const loading = ref(false)
const voucherDate = ref(null)

const checkoutList = store.getters.checkoutList.filter(c => c.status !== 2)

const doSubmit = () => {
	if (voucherDate.value) {
		loading.value = true;
		voucher.finishingOffNo({year: voucherDate.value.checkYear, month: voucherDate.value.checkMonth}).then(() => {
			emits('success')
		}).finally(() => {
			loading.value = false;
		});
	}
}
</script>
