/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
import getCity from './city';

const DISTRICT_DATA = [
	['110101', ['东城', '東城', 'Dongcheng'], '110100'],
	['110102', ['西城', '西城', 'Xicheng'], '110100'],
	['110105', ['朝阳', '朝陽', 'Chaoyang'], '110100'],
	['110106', ['丰台', '豐臺', 'Fengtai'], '110100'],
	['110107', ['石景山', '石景山', 'Shijingshan'], '110100'],
	['110108', ['海淀', '海澱', 'Haidian'], '110100'],
	['110109', ['门头沟', '門頭溝', 'Mentougou'], '110100'],
	['110111', ['房山', '房山', 'Fangshan'], '110100'],
	['110112', ['通州', '通州', 'Tongzhou'], '110100'],
	['110113', ['顺义', '順義', 'Shunyi'], '110100'],
	['110114', ['昌平', '昌平', 'Changping'], '110100'],
	['110115', ['大兴', '大興', 'Daxing'], '110100'],
	['110116', ['怀柔', '懷柔', 'Huairou'], '110100'],
	['110117', ['平谷', '平穀', 'Grain'], '110100'],
	['110228', ['密云', '密雲', 'Miyun'], '110100'],
	['110229', ['延庆', '延慶', 'Yanqing'], '110100'],
	['120101', ['和平', '和平', 'peace'], '120100'],
	['120102', ['河东', '河東', 'Hedong'], '120100'],
	['120103', ['河西', '河西', 'Hexi'], '120100'],
	['120104', ['南开', '南開', 'Nankai'], '120100'],
	['120105', ['河北', '河北', 'Hebei'], '120100'],
	['120106', ['红桥', '紅橋', 'Red bridge'], '120100'],
	['120110', ['东丽', '東麗', 'Toray'], '120100'],
	['120111', ['西青', '西青', 'Xiqing'], '120100'],
	['120112', ['津南', '津南', 'Jinnan'], '120100'],
	['120113', ['北辰', '北辰', 'Beichen'], '120100'],
	['120114', ['武清', '武清', 'Wu Qing'], '120100'],
	['120115', ['宝坻', '寶坻', 'Breguet'], '120100'],
	['120116', ['滨海', '濱海', 'Coast'], '120100'],
	['120221', ['宁河', '寧河', 'Ninghe'], '120100'],
	['120223', ['静海', '靜海', 'Jinghai'], '120100'],
	['120225', ['蓟县', '薊縣', 'Ji County'], '120100'],
	['130102', ['长安', '長安', 'Changan'], '130100'],
	['130103', ['桥东', '橋東', 'Bridge east'], '130100'],
	['130104', ['桥西', '橋西', 'Bridge west'], '130100'],
	['130105', ['新华', '新華', 'Xinhua'], '130100'],
	['130107', ['井陉矿', '井陘礦', 'Jingjing Mine'], '130100'],
	['130108', ['裕华', '裕華', 'Yuhua'], '130100'],
	['130121', ['井陉', '井陘', 'Well'], '130100'],
	['130123', ['正定', '正定', 'Positive'], '130100'],
	['130124', ['栾城', '欒城', 'Yucheng'], '130100'],
	['130125', ['行唐', '行唐', 'Tang'], '130100'],
	['130126', ['灵寿', '靈壽', 'Lingshou'], '130100'],
	['130127', ['高邑', '高邑', 'Sorghum'], '130100'],
	['130128', ['深泽', '深澤', 'Fukasawa'], '130100'],
	['130129', ['赞皇', '贊皇', 'Zanhuang'], '130100'],
	['130130', ['无极', '無極', 'Promise'], '130100'],
	['130131', ['平山', '平山', 'Hirayama'], '130100'],
	['130132', ['元氏', '元氏', 'Yuan'], '130100'],
	['130133', ['赵县', '趙縣', 'Zhao County'], '130100'],
	['130181', ['辛集', '辛集', 'Xinji'], '130100'],
	['130182', ['藁城', '槁城', 'Yucheng'], '130100'],
	['130183', ['晋州', '晉州', 'Jinju'], '130100'],
	['130184', ['新乐', '新樂', 'new music'], '130100'],
	['130185', ['鹿泉', '鹿泉', 'Luquan'], '130100'],
	['130202', ['路南', '路南', 'Lunan'], '130200'],
	['130203', ['路北', '路北', 'Lubei'], '130200'],
	['130204', ['古冶', '古冶', 'Guye'], '130200'],
	['130205', ['开平', '開平', 'Kaiping'], '130200'],
	['130207', ['丰南', '豐南', 'Fengnan'], '130200'],
	['130208', ['丰润', '豐潤', 'Rich'], '130200'],
	['130223', ['滦县', '灤縣', 'Ji County'], '130200'],
	['130224', ['滦南', '灤南', 'Weinan'], '130200'],
	['130225', ['乐亭', '樂亭', 'Laoting'], '130200'],
	['130227', ['迁西', '遷西', 'Qianxi'], '130200'],
	['130229', ['玉田', '玉田', 'Yutian'], '130200'],
	['130230', ['曹妃甸', '曹妃甸', 'Caofeidian'], '130200'],
	['130281', ['遵化', '遵化', 'Zunhua'], '130200'],
	['130283', ['迁安', '遷安', "Qian'an"], '130200'],
	['130302', ['海港', '海港', 'harbor'], '130300'],
	['130303', ['山海关', '山海關', 'Shanhaiguan'], '130300'],
	['130304', ['北戴河', '北戴河', 'Beidaihe'], '130300'],
	['130321', ['青龙', '青龍', 'Qinglong'], '130300'],
	['130322', ['昌黎', '昌黎', 'Changli'], '130300'],
	['130323', ['抚宁', '撫寧', 'Funing'], '130300'],
	['130324', ['卢龙', '盧龍', 'Lu Long'], '130300'],
	['130402', ['邯山', '邯山', 'Mansan'], '130400'],
	['130403', ['丛台', '叢臺', 'Congtai'], '130400'],
	['130404', ['复兴', '複興', 'revival'], '130400'],
	['130406', ['峰峰矿', '峰峰礦', 'Fengfeng Mine'], '130400'],
	['130421', ['邯郸', '邯鄲', 'Handan'], '130400'],
	['130423', ['临漳', '臨漳', 'Linyi'], '130400'],
	['130424', ['成安', '成安', 'Anxiety'], '130400'],
	['130425', ['大名', '大名', 'Big name'], '130400'],
	['130426', ['涉县', '涉縣', 'Shexian County'], '130400'],
	['130427', ['磁县', '磁縣', 'Cixian'], '130400'],
	['130428', ['肥乡', '肥鄉', 'Feixiang'], '130400'],
	['130429', ['永年', '永年', 'Many years'], '130400'],
	['130430', ['邱县', '邱縣', 'Qiu County'], '130400'],
	['130431', ['鸡泽', '雞澤', 'Jize'], '130400'],
	['130432', ['广平', '廣平', 'Guangping'], '130400'],
	['130433', ['馆陶', '館陶', 'Guantao'], '130400'],
	['130434', ['魏县', '魏縣', 'Wei County'], '130400'],
	['130435', ['曲周', '曲周', 'Qu Zhou'], '130400'],
	['130481', ['武安', '武安', "Wu'an"], '130400'],
	['130502', ['桥东', '橋東', 'Bridge east'], '130500'],
	['130503', ['桥西', '橋西', 'Bridge west'], '130500'],
	['130521', ['邢台', '邢臺', 'Xingtai'], '130500'],
	['130522', ['临城', '臨城', 'Lincheng'], '130500'],
	['130523', ['内丘', '內丘', 'Neiqiu'], '130500'],
	['130524', ['柏乡', '柏鄉', 'Baixiang'], '130500'],
	['130525', ['隆尧', '隆堯', 'Long'], '130500'],
	['130526', ['任县', '任縣', 'Ren County'], '130500'],
	['130527', ['南和', '南和', 'Nanwa'], '130500'],
	['130528', ['宁晋', '寧晉', 'Ning Jin'], '130500'],
	['130529', ['巨鹿', '巨鹿', 'Giant deer'], '130500'],
	['130530', ['新河', '新河', 'Shinkawa'], '130500'],
	['130531', ['广宗', '廣宗', 'Guangzong'], '130500'],
	['130532', ['平乡', '平鄉', 'Pingxiang'], '130500'],
	['130533', ['威县', '威縣', 'Wei County'], '130500'],
	['130534', ['清河', '清河', 'Qinghe'], '130500'],
	['130535', ['临西', '臨西', 'Linxi'], '130500'],
	['130581', ['南宫', '南宮', 'Nangong'], '130500'],
	['130582', ['沙河', '沙河', 'Shahe'], '130500'],
	['130602', ['新市', '新市', 'New city'], '130600'],
	['130603', ['北市', '北市', 'North city'], '130600'],
	['130604', ['南市', '南市', 'Nanshi'], '130600'],
	['130621', ['满城', '滿城', 'Mancheng'], '130600'],
	['130622', ['清苑', '清苑', 'Qingyuan'], '130600'],
	['130623', ['涞水', '淶水', 'Drowning'], '130600'],
	['130624', ['阜平', '阜平', 'Muping'], '130600'],
	['130625', ['徐水', '徐水', 'Xu Shui'], '130600'],
	['130626', ['定兴', '定興', 'Dingxing'], '130600'],
	['130627', ['唐县', '唐縣', 'Tang County'], '130600'],
	['130628', ['高阳', '高陽', 'Takayama'], '130600'],
	['130629', ['容城', '容城', 'Rongcheng'], '130600'],
	['130630', ['涞源', '淶源', 'Wuyuan'], '130600'],
	['130631', ['望都', '望都', 'Hometown'], '130600'],
	['130632', ['安新', '安新', 'Anxin'], '130600'],
	['130633', ['易县', '易縣', 'Yi County'], '130600'],
	['130634', ['曲阳', '曲陽', 'Quyang'], '130600'],
	['130635', ['蠡县', '蠡縣', 'Ji County'], '130600'],
	['130636', ['顺平', '順平', 'Applause'], '130600'],
	['130637', ['博野', '博野', 'Hino'], '130600'],
	['130638', ['雄县', '雄縣', 'Xiong County'], '130600'],
	['130681', ['涿州', '涿州', 'Zhangzhou'], '130600'],
	['130682', ['定州', '定州', 'Dingzhou'], '130600'],
	['130683', ['安国', '安國', 'Yasukuni'], '130600'],
	['130684', ['高碑店', '高碑店', 'Gaobeidian'], '130600'],
	['130702', ['桥东', '橋東', 'Bridge east'], '130700'],
	['130703', ['桥西', '橋西', 'Bridge west'], '130700'],
	['130705', ['宣化区', '宣化區', 'Xuanhua District'], '130700'],
	['130706', ['下花园', '下花園', 'Lower garden'], '130700'],
	['130721', ['宣化县', '宣化縣', 'Xuanhua County'], '130700'],
	['130722', ['张北', '張北', 'Zhang Bei'], '130700'],
	['130723', ['康保', '康保', 'Kang Bao'], '130700'],
	['130724', ['沽源', '沽源', 'Wuyuan'], '130700'],
	['130725', ['尚义', '尚義', 'Shang Yi'], '130700'],
	['130726', ['蔚县', '蔚縣', 'Yu County'], '130700'],
	['130727', ['阳原', '陽原', 'Yohara'], '130700'],
	['130728', ['怀安', '懷安', "Huai'an"], '130700'],
	['130729', ['万全', '萬全', 'Wanquan'], '130700'],
	['130730', ['怀来', '懷來', 'Huailai'], '130700'],
	['130731', ['涿鹿', '涿鹿', 'Elk'], '130700'],
	['130732', ['赤城', '赤城', 'Akagi'], '130700'],
	['130733', ['崇礼', '崇禮', 'Chongli'], '130700'],
	['130802', ['双桥', '雙橋', 'Double bridge'], '130800'],
	['130803', ['双滦', '雙灤', 'Double'], '130800'],
	['130804', ['鹰手营子矿', '鷹手營子礦', 'Eagle Hand Camp Mine'], '130800'],
	['130821', ['承德', '承德', 'Chengde'], '130800'],
	['130822', ['兴隆', '興隆', 'Xinglong'], '130800'],
	['130823', ['平泉', '平泉', 'Heizumi'], '130800'],
	['130824', ['滦平', '灤平', 'Muping'], '130800'],
	['130825', ['隆化', '隆化', 'Longhua'], '130800'],
	['130826', ['丰宁', '豐寧', 'Fengning'], '130800'],
	['130827', ['宽城', '寬城', 'Kuancheng'], '130800'],
	['130828', ['围场', '圍場', 'Paddock'], '130800'],
	['130902', ['新华', '新華', 'Xinhua'], '130900'],
	['130903', ['运河', '運河', 'canal'], '130900'],
	['130921', ['沧县', '滄縣', 'Ji County'], '130900'],
	['130922', ['青县', '青縣', 'Qing County'], '130900'],
	['130923', ['东光', '東光', 'Toko'], '130900'],
	['130924', ['海兴', '海興', 'Haixing'], '130900'],
	['130925', ['盐山', '鹽山', 'Salt mountain'], '130900'],
	['130926', ['肃宁', '肅寧', 'Suning'], '130900'],
	['130927', ['南皮', '南皮', 'Nanpi'], '130900'],
	['130928', ['吴桥', '吳橋', 'Wu Qiao'], '130900'],
	['130929', ['献县', '獻縣', 'Xian County'], '130900'],
	['130930', ['孟村', '孟村', 'Mengcun'], '130900'],
	['130981', ['泊头', '泊頭', 'Botou'], '130900'],
	['130982', ['任丘', '任丘', 'Renqiu'], '130900'],
	['130983', ['黄骅', '黃驊', 'Huang Wei'], '130900'],
	['130984', ['河间', '河間', 'Kawaama'], '130900'],
	['131002', ['安次', '安次', 'Cheap'], '131000'],
	['131003', ['广阳', '廣陽', 'Guangyang'], '131000'],
	['131022', ['固安', '固安', "Gu'an"], '131000'],
	['131023', ['永清', '永清', 'Yongqing'], '131000'],
	['131024', ['香河', '香河', 'Xianghe'], '131000'],
	['131025', ['大城', '大城', 'Big city'], '131000'],
	['131026', ['文安', '文安', "Wen'an"], '131000'],
	['131028', ['大厂', '大廠', 'Big factory'], '131000'],
	['131081', ['霸州', '霸州', 'Bazhou'], '131000'],
	['131082', ['三河', '三河', 'Mikawa'], '131000'],
	['131102', ['桃城', '桃城', 'Momojo'], '131100'],
	['131121', ['枣强', '棗強', 'Jujube'], '131100'],
	['131122', ['武邑', '武邑', 'Monk'], '131100'],
	['131123', ['武强', '武強', 'Wu Qiang'], '131100'],
	['131124', ['饶阳', '饒陽', 'Raoyang'], '131100'],
	['131125', ['安平', '安平', 'Anping'], '131100'],
	['131126', ['故城', '故城', 'Old city'], '131100'],
	['131127', ['景县', '景縣', 'Jing County'], '131100'],
	['131128', ['阜城', '阜城', 'Yucheng'], '131100'],
	['131181', ['冀州', '冀州', 'Zhangzhou'], '131100'],
	['131182', ['深州', '深州', 'Shenzhou'], '131100'],
	['140105', ['小店', '小店', 'Small shop'], '140100'],
	['140106', ['迎泽', '迎澤', 'Yingze'], '140100'],
	['140107', ['杏花岭', '杏花嶺', 'Xinghualing'], '140100'],
	['140108', ['尖草坪', '尖草坪', 'Sharp lawn'], '140100'],
	['140109', ['万柏林', '萬柏林', 'Wan Berlin'], '140100'],
	['140110', ['晋源', '晉源', 'Jinyuan'], '140100'],
	['140121', ['清徐', '清徐', 'Qing Xu'], '140100'],
	['140122', ['阳曲', '陽曲', 'Yangqu'], '140100'],
	['140123', ['娄烦', '婁煩', 'Annoying'], '140100'],
	['140181', ['古交', '古交', 'Ancient cross'], '140100'],
	['140202', ['城区', '城區', 'Urban area'], '140200'],
	['140203', ['矿区', '礦區', 'Mining area'], '140200'],
	['140211', ['南郊', '南郊', 'Southern suburbs'], '140200'],
	['140212', ['新荣', '新榮', 'Xinrong'], '140200'],
	['140221', ['阳高', '陽高', 'Yang Gao'], '140200'],
	['140222', ['天镇', '天鎮', 'Tianzhen'], '140200'],
	['140223', ['广灵', '廣靈', 'Guangling'], '140200'],
	['140224', ['灵丘', '靈丘', 'Lingqiu'], '140200'],
	['140225', ['浑源', '渾源', 'Wuyuan'], '140200'],
	['140226', ['左云', '左雲', 'Zuo Yun'], '140200'],
	['140227', ['大同', '大同', 'Datong'], '140200'],
	['140302', ['城区', '城區', 'Urban area'], '140300'],
	['140303', ['矿区', '礦區', 'Mining area'], '140300'],
	['140311', ['郊区', '郊區', 'suburbs'], '140300'],
	['140321', ['平定', '平定', 'Pingding'], '140300'],
	['140322', ['盂县', '盂縣', 'Ji County'], '140300'],
	['140421', ['长治', '長治', 'Changzhi'], '140400'],
	['140423', ['襄垣', '襄垣', 'Xiangyuan'], '140400'],
	['140424', ['屯留', '屯留', 'Tunliu'], '140400'],
	['140425', ['平顺', '平順', 'Smooth'], '140400'],
	['140426', ['黎城', '黎城', 'Licheng'], '140400'],
	['140427', ['壶关', '壺關', 'Huguan'], '140400'],
	['140428', ['长子', '長子', 'Zhangzi'], '140400'],
	['140429', ['武乡', '武鄉', 'Wuxiang'], '140400'],
	['140430', ['沁县', '沁縣', 'Ji County'], '140400'],
	['140431', ['沁源', '沁源', 'Wuyuan'], '140400'],
	['140481', ['潞城', '潞城', 'Yucheng'], '140400'],
	['140482', ['城区', '城區', 'Urban area'], '140400'],
	['140483', ['郊区', '郊區', 'Suburban District'], '140400'],
	['140502', ['城区', '城區', 'Urban area'], '140500'],
	['140521', ['沁水', '沁水', 'Qinshui'], '140500'],
	['140522', ['阳城', '陽城', 'Yangcheng'], '140500'],
	['140524', ['陵川', '陵川', 'Lingchuan'], '140500'],
	['140525', ['泽州', '澤州', 'Zezhou'], '140500'],
	['140581', ['高平', '高平', 'Gao Ping'], '140500'],
	['140602', ['朔城', '朔城', 'Yucheng'], '140600'],
	['140603', ['平鲁', '平魯', 'Pinglu'], '140600'],
	['140621', ['山阴', '山陰', 'Sanin'], '140600'],
	['140622', ['应县', '應縣', 'Ying County'], '140600'],
	['140623', ['右玉', '右玉', 'Right jade'], '140600'],
	['140624', ['怀仁', '懷仁', 'Huairen'], '140600'],
	['140702', ['榆次', '榆次', 'Yuci'], '140700'],
	['140721', ['榆社', '榆社', 'Yushe'], '140700'],
	['140722', ['左权', '左權', 'Zuo Quan'], '140700'],
	['140723', ['和顺', '和順', 'Heshun'], '140700'],
	['140724', ['昔阳', '昔陽', 'Xiyang'], '140700'],
	['140725', ['寿阳', '壽陽', 'Shouyang'], '140700'],
	['140726', ['太谷', '太穀', 'Tar'], '140700'],
	['140727', ['祁县', '祁縣', 'Ji County'], '140700'],
	['140728', ['平遥', '平遙', 'Pingyao'], '140700'],
	['140729', ['灵石', '靈石', 'Lingshi'], '140700'],
	['140781', ['介休', '介休', 'Jiexiu'], '140700'],
	['140802', ['盐湖', '鹽湖', 'Salt Lake'], '140800'],
	['140821', ['临猗', '臨猗', 'Linyi'], '140800'],
	['140822', ['万荣', '萬榮', 'Wan Rong'], '140800'],
	['140823', ['闻喜', '聞喜', 'Wen Xi'], '140800'],
	['140824', ['稷山', '稷山', 'Lushan'], '140800'],
	['140825', ['新绛', '新絳', 'Xinjiang'], '140800'],
	['140826', ['绛县', '絳縣', 'Ji County'], '140800'],
	['140827', ['垣曲', '垣曲', 'Yuanqu'], '140800'],
	['140828', ['夏县', '夏縣', 'Xia County'], '140800'],
	['140829', ['平陆', '平陸', 'Land'], '140800'],
	['140830', ['芮城', '芮城', 'Yucheng'], '140800'],
	['140881', ['永济', '永濟', 'Yongji'], '140800'],
	['140882', ['河津', '河津', 'Kawazu'], '140800'],
	['140902', ['忻府', '忻府', 'Yufu'], '140900'],
	['140921', ['定襄', '定襄', 'Dingxiang'], '140900'],
	['140922', ['五台', '五臺', 'Wutai'], '140900'],
	['140923', ['代县', '代縣', 'Dai County'], '140900'],
	['140924', ['繁峙', '繁峙', 'Traditional'], '140900'],
	['140925', ['宁武', '寧武', 'Ningwu'], '140900'],
	['140926', ['静乐', '靜樂', 'Jingle'], '140900'],
	['140927', ['神池', '神池', 'Shenchi'], '140900'],
	['140928', ['五寨', '五寨', 'Wuzhai'], '140900'],
	['140929', ['岢岚', '岢嵐', 'Kelan'], '140900'],
	['140930', ['河曲', '河曲', 'Hequ'], '140900'],
	['140931', ['保德', '保德', 'Baode'], '140900'],
	['140932', ['偏关', '偏關', 'Preference'], '140900'],
	['140981', ['原平', '原平', 'Arai'], '140900'],
	['141002', ['尧都', '堯都', 'Yudu'], '141000'],
	['141021', ['曲沃', '曲沃', 'Quwo'], '141000'],
	['141022', ['翼城', '翼城', 'Yicheng'], '141000'],
	['141023', ['襄汾', '襄汾', 'Xiangfen'], '141000'],
	['141024', ['洪洞', '洪洞', 'Hongdong'], '141000'],
	['141025', ['古县', '古縣', 'Ancient county'], '141000'],
	['141026', ['安泽', '安澤', 'Anze'], '141000'],
	['141027', ['浮山', '浮山', 'Fushan'], '141000'],
	['141028', ['吉县', '吉縣', 'Ji County'], '141000'],
	['141029', ['乡宁', '鄉寧', 'Xiangning'], '141000'],
	['141030', ['大宁', '大寧', 'Daning'], '141000'],
	['141031', ['隰县', '隰縣', 'Ji County'], '141000'],
	['141032', ['永和', '永和', 'Yonghe'], '141000'],
	['141033', ['蒲县', '蒲縣', 'Pu County'], '141000'],
	['141034', ['汾西', '汾西', 'Fengxi'], '141000'],
	['141081', ['侯马', '侯馬', 'Houma'], '141000'],
	['141082', ['霍州', '霍州', 'Huozhou'], '141000'],
	['141102', ['离石', '離石', 'Lishi'], '141100'],
	['141121', ['文水', '文水', 'Wenshui'], '141100'],
	['141122', ['交城', '交城', 'Jiaocheng'], '141100'],
	['141123', ['兴县', '興縣', 'Xing County'], '141100'],
	['141124', ['临县', '臨縣', 'Lin County'], '141100'],
	['141125', ['柳林', '柳林', 'Liu Lin'], '141100'],
	['141126', ['石楼', '石樓', 'Shilou'], '141100'],
	['141127', ['岚县', '嵐縣', 'Ji County'], '141100'],
	['141128', ['方山', '方山', 'Fangshan'], '141100'],
	['141129', ['中阳', '中陽', 'Zhongyang'], '141100'],
	['141130', ['交口', '交口', 'Junction'], '141100'],
	['141181', ['孝义', '孝義', 'Xiaoyi'], '141100'],
	['141182', ['汾阳', '汾陽', 'Fuyang'], '141100'],
	['150102', ['新城', '新城', 'Hsincheng'], '150100'],
	['150103', ['回民', '回民', 'Huimin'], '150100'],
	['150104', ['玉泉', '玉泉', 'Yuquan'], '150100'],
	['150105', ['赛罕', '賽罕', 'Saihan'], '150100'],
	['150121', ['土默特左', '土默特左', 'Tumut left'], '150100'],
	['150122', ['托克托', '托克托', 'Tokto'], '150100'],
	['150123', ['和林格尔', '和林格爾', 'Linger'], '150100'],
	['150124', ['清水河', '清水河', 'Qingshuihe'], '150100'],
	['150125', ['武川', '武川', 'Mukawa'], '150100'],
	['150202', ['东河', '東河', 'Donghe'], '150200'],
	['150203', ['昆都仑', '昆都侖', 'Kundulun'], '150200'],
	['150204', ['青山', '青山', 'Aoyama'], '150200'],
	['150205', ['石拐', '石拐', 'Stone turn'], '150200'],
	['150206', ['白云矿区', '白雲礦區', 'Baiyun mining area'], '150200'],
	['150207', ['九原', '九原', 'Jiuyuan'], '150200'],
	['150221', ['土默特右', '土默特右', 'Tumut right'], '150200'],
	['150222', ['固阳', '固陽', 'Guyang'], '150200'],
	['150223', ['达尔罕茂明安联合', '達爾罕茂明安聯合', 'Dalhan Maomingan United'], '150200'],
	['150302', ['海勃湾', '海勃灣', 'Haibo Bay'], '150300'],
	['150303', ['海南', '海南', 'Hainan'], '150300'],
	['150304', ['乌达', '烏達', 'Uda'], '150300'],
	['150402', ['红山', '紅山', 'Red mountain'], '150400'],
	['150403', ['元宝山', '元寶山', 'Yuanbaoshan'], '150400'],
	['150404', ['松山', '松山', 'Matsuyama'], '150400'],
	['150421', ['阿鲁科尔沁', '阿魯科爾沁', 'Aru Kerr'], '150400'],
	['150422', ['巴林左', '巴林左', 'Bahrain left'], '150400'],
	['150423', ['巴林右', '巴林右', 'Bahrain right'], '150400'],
	['150424', ['林西', '林西', 'Lin Xi'], '150400'],
	['150425', ['克什克腾', '克什克騰', 'Kashkent'], '150400'],
	['150426', ['翁牛特', '翁牛特', 'Weng Niute'], '150400'],
	['150428', ['喀喇沁', '喀喇沁', 'Kalah'], '150400'],
	['150429', ['宁城', '寧城', 'Ningcheng'], '150400'],
	['150430', ['敖汉', '敖漢', 'Shu Han'], '150400'],
	['150502', ['科尔沁', '科爾沁', 'Horqin'], '150500'],
	['150521', ['科尔沁左翼中', '科爾沁左翼中', 'Kerqin left wing'], '150500'],
	['150522', ['科尔沁左翼后', '科爾沁左翼後', 'Horqin behind the left wing'], '150500'],
	['150523', ['开鲁', '開魯', 'Kailu'], '150500'],
	['150524', ['库伦', '庫倫', 'Coulomb'], '150500'],
	['150525', ['奈曼', '奈曼', 'Naiman'], '150500'],
	['150526', ['扎鲁特', '紮魯特', 'Zalut'], '150500'],
	['150581', ['霍林郭勒', '霍林郭勒', 'Hollingol'], '150500'],
	['150602', ['东胜', '東勝', 'Dongsheng'], '150600'],
	['150621', ['达拉特', '達拉特', 'Dalat'], '150600'],
	['150622', ['准格尔', '準格爾', 'Zhungeer'], '150600'],
	['150623', ['鄂托克前', '鄂托克前', 'Etuoke'], '150600'],
	['150624', ['鄂托克', '鄂托克', 'Etok'], '150600'],
	['150625', ['杭锦', '杭錦', 'Hang Jin'], '150600'],
	['150626', ['乌审', '烏審', 'Wushen'], '150600'],
	['150627', ['伊金霍洛', '伊金霍洛', 'Iginholo'], '150600'],
	['150702', ['海拉尔', '海拉爾', 'Hailar'], '150700'],
	['150703', ['扎赉诺尔', '紮賚諾爾', 'Zazenor'], '150700'],
	['150721', ['阿荣', '阿榮', 'Arong'], '150700'],
	['150722', ['莫力达瓦', '莫力達瓦', 'Molidawa'], '150700'],
	['150723', ['鄂伦春', '鄂倫春', 'Oroqen'], '150700'],
	['150724', ['鄂温克', '鄂溫克', 'Ewenk'], '150700'],
	['150725', ['陈巴尔虎', '陳巴爾虎', 'Chen Balhu'], '150700'],
	['150726', ['新巴尔虎左', '新巴爾虎左', 'New bay tiger left'], '150700'],
	['150727', ['新巴尔虎右', '新巴爾虎右', 'New Barr Tiger Right'], '150700'],
	['150781', ['满洲里', '滿洲裏', 'Manzhouli'], '150700'],
	['150782', ['牙克石', '牙克石', 'Yakeshi'], '150700'],
	['150783', ['扎兰屯', '紮蘭屯', 'Zhalant'], '150700'],
	['150784', ['额尔古纳', '額爾古納', 'Ergun'], '150700'],
	['150785', ['根河', '根河', 'Genhe'], '150700'],
	['150802', ['临河', '臨河', 'Linhe'], '150800'],
	['150821', ['五原', '五原', 'Wuyuan'], '150800'],
	['150822', ['磴口', '磴口', 'Mouthful'], '150800'],
	['150823', ['乌拉特前', '烏拉特前', 'Before Urad'], '150800'],
	['150824', ['乌拉特中', '烏拉特中', 'Urad'], '150800'],
	['150825', ['乌拉特后', '烏拉特後', 'After Urad'], '150800'],
	['150826', ['杭锦后', '杭錦後', 'Hang Jinhou'], '150800'],
	['150902', ['集宁', '集寧', 'Jining'], '150900'],
	['150921', ['卓资', '卓資', 'Zhuozi'], '150900'],
	['150922', ['化德', '化德', 'Huade'], '150900'],
	['150923', ['商都', '商都', 'Commercial capital'], '150900'],
	['150924', ['兴和', '興和', 'Kowa'], '150900'],
	['150925', ['凉城', '涼城', 'Liangcheng'], '150900'],
	['150926', ['察哈尔右翼前', '察哈爾右翼前', 'Chahar right wing front'], '150900'],
	['150927', ['察哈尔右翼中', '察哈爾右翼中', 'Chahar right wing'], '150900'],
	['150928', ['察哈尔右翼后', '察哈爾右翼後', 'Chahar right wing'], '150900'],
	['150929', ['四子王', '四子王', 'Four sons'], '150900'],
	['150981', ['丰镇', '豐鎮', 'Fengzhen'], '150900'],
	['152201', ['乌兰浩特', '烏蘭浩特', 'Ulanhot'], '152200'],
	['152202', ['阿尔山', '阿爾山', 'Aershan'], '152200'],
	['152221', ['科尔沁右翼前', '科爾沁右翼前', 'Kerqin right wing front'], '152200'],
	['152222', ['科尔沁右翼中', '科爾沁右翼中', 'Horqin right wing'], '152200'],
	['152223', ['扎赉特', '紮賚特', 'Zhabit'], '152200'],
	['152224', ['突泉', '突泉', 'Tuquan'], '152200'],
	['152501', ['二连浩特', '二連浩特', 'Erlianhot'], '152500'],
	['152502', ['锡林浩特', '錫林浩特', 'Xilinhot'], '152500'],
	['152522', ['阿巴嘎', '阿巴嘎', 'Aba'], '152500'],
	['152523', ['苏尼特左', '蘇尼特左', 'Sunite left'], '152500'],
	['152524', ['苏尼特右', '蘇尼特右', 'Sunite right'], '152500'],
	['152525', ['东乌珠穆沁', '東烏珠穆沁', 'East Ujim'], '152500'],
	['152526', ['西乌珠穆沁', '西烏珠穆沁', 'Xiwuzhumuyu'], '152500'],
	['152527', ['太仆寺', '太僕寺', 'Taipu Temple'], '152500'],
	['152528', ['镶黄', '鑲黃', 'Yellow'], '152500'],
	['152529', ['正镶白', '正鑲白', 'White inlaid'], '152500'],
	['152530', ['正蓝', '正藍', 'Positive blue'], '152500'],
	['152531', ['多伦', '多倫', 'Doron'], '152500'],
	['152921', ['阿拉善左', '阿拉善左', 'Alxa left'], '152900'],
	['152922', ['阿拉善右', '阿拉善右', 'Alxa Right'], '152900'],
	['152923', ['额济纳', '額濟納', 'Ejina'], '152900'],
	['210102', ['和平', '和平', 'peace'], '210100'],
	['210103', ['沈河', '沈河', 'Shenhe'], '210100'],
	['210104', ['大东', '大東', 'Daito'], '210100'],
	['210105', ['皇姑', '皇姑', 'Huang Gu'], '210100'],
	['210106', ['铁西', '鐵西', 'Tiexi'], '210100'],
	['210111', ['苏家屯', '蘇家屯', 'Su Jiayu'], '210100'],
	['210112', ['浑南', '渾南', 'Weinan'], '210100'],
	['210113', ['新城子', '新城子', 'New town'], '210100'],
	['210114', ['于洪', '於洪', 'Yu Hong'], '210100'],
	['210122', ['辽中', '遼中', 'Liaozhong'], '210100'],
	['210123', ['康平', '康平', 'Kohei'], '210100'],
	['210124', ['法库', '法庫', 'Law storehouse'], '210100'],
	['210181', ['新民', '新民', 'Xinmin'], '210100'],
	['210184', ['沈北', '沈北', 'Shenbei'], '210100'],
	['210202', ['中山', '中山', 'Zhongshan'], '210200'],
	['210203', ['西岗', '西崗', 'Xigang'], '210200'],
	['210204', ['沙河口', '沙河口', 'Shahekou'], '210200'],
	['210211', ['甘井子', '甘井子', 'Ganjingzi'], '210200'],
	['210212', ['旅顺口', '旅順口', 'Lushunkou'], '210200'],
	['210213', ['金州', '金州', 'Golden State'], '210200'],
	['210224', ['长海', '長海', 'Changhai'], '210200'],
	['210281', ['瓦房店', '瓦房店', 'Wafangdian'], '210200'],
	['210282', ['普兰店', '普蘭店', 'Pulandian'], '210200'],
	['210283', ['庄河', '莊河', 'Zhuanghe'], '210200'],
	['210302', ['铁东', '鐵東', 'Tiedong'], '210300'],
	['210303', ['铁西', '鐵西', 'Tiexi'], '210300'],
	['210304', ['立山', '立山', 'Tateyama'], '210300'],
	['210311', ['千山', '千山', 'Qianshan'], '210300'],
	['210321', ['台安', '臺安', 'Taian'], '210300'],
	['210323', ['岫岩', '岫巖', 'Xiuyan'], '210300'],
	['210381', ['海城', '海城', 'Huashi'], '210300'],
	['210402', ['新抚', '新撫', 'New care'], '210400'],
	['210403', ['东洲', '東洲', 'Dongzhou'], '210400'],
	['210404', ['望花', '望花', 'Flower'], '210400'],
	['210411', ['顺城', '順城', 'Shuncheng'], '210400'],
	['210421', ['抚顺', '撫順', 'Fushun'], '210400'],
	['210422', ['新宾', '新賓', 'New guest'], '210400'],
	['210423', ['清原', '清原', 'Kihara'], '210400'],
	['210502', ['平山', '平山', 'Hirayama'], '210500'],
	['210503', ['溪湖', '溪湖', 'Creek lake'], '210500'],
	['210504', ['明山', '明山', 'Akayama'], '210500'],
	['210505', ['南芬', '南芬', 'Nanfen'], '210500'],
	['210521', ['本溪', '本溪', 'Benxi'], '210500'],
	['210522', ['桓仁', '桓仁', 'Huanren'], '210500'],
	['210602', ['元宝', '元寶', 'Ingots'], '210600'],
	['210603', ['振兴', '振興', 'Revitalize'], '210600'],
	['210604', ['振安', '振安', 'Zhenan'], '210600'],
	['210624', ['宽甸', '寬甸', 'Kuandian'], '210600'],
	['210681', ['东港', '東港', 'Eastern port'], '210600'],
	['210682', ['凤城', '鳳城', 'Fengcheng'], '210600'],
	['210702', ['古塔', '古塔', 'Guta'], '210700'],
	['210703', ['凌河', '淩河', 'Linghe'], '210700'],
	['210711', ['太和', '太和', 'Taihe'], '210700'],
	['210726', ['黑山', '黑山', 'Montenegro'], '210700'],
	['210727', ['义县', '義縣', 'Yi County'], '210700'],
	['210781', ['凌海', '淩海', 'Ling Hai'], '210700'],
	['210782', ['北镇', '北鎮', 'North town'], '210700'],
	['210802', ['站前', '站前', 'Before the station'], '210800'],
	['210803', ['西市', '西市', 'West City'], '210800'],
	['210804', ['鲅鱼圈', '鮁魚圈', 'Squid ring'], '210800'],
	['210811', ['老边', '老邊', 'Laobian'], '210800'],
	['210881', ['盖州', '蓋州', 'Gaizhou'], '210800'],
	['210882', ['大石桥', '大石橋', 'Oishi Bridge'], '210800'],
	['210902', ['海州', '海州', 'Haizhou'], '210900'],
	['210903', ['新邱', '新邱', 'Xinqiu'], '210900'],
	['210904', ['太平', '太平', 'Taiping'], '210900'],
	['210905', ['清河门', '清河門', 'Qinghemen'], '210900'],
	['210911', ['细河', '細河', 'Fine river'], '210900'],
	['210921', ['阜新', '阜新', 'Fuxin'], '210900'],
	['210922', ['彰武', '彰武', 'Zhang Wu'], '210900'],
	['211002', ['白塔', '白塔', 'White tower'], '211000'],
	['211003', ['文圣', '文聖', 'Writings'], '211000'],
	['211004', ['宏伟', '宏偉', 'Magnificent'], '211000'],
	['211005', ['弓长岭', '弓長嶺', 'Gongchangling'], '211000'],
	['211011', ['太子河', '太子河', 'Prince River'], '211000'],
	['211021', ['辽阳', '遼陽', 'Liaoyang'], '211000'],
	['211081', ['灯塔', '燈塔', 'lighthouse'], '211000'],
	['211102', ['双台子', '雙臺子', 'Double table'], '211100'],
	['211103', ['兴隆台', '興隆臺', 'Xinglongtai'], '211100'],
	['211121', ['大洼', '大窪', 'Okubo'], '211100'],
	['211122', ['盘山', '盤山', 'Panshan'], '211100'],
	['211202', ['银州', '銀州', 'Yinzhou'], '211200'],
	['211204', ['清河', '清河', 'Qinghe'], '211200'],
	['211221', ['铁岭', '鐵嶺', 'Tieling'], '211200'],
	['211223', ['西丰', '西豐', 'Xifeng'], '211200'],
	['211224', ['昌图', '昌圖', 'Changtu'], '211200'],
	['211281', ['调兵山', '調兵山', 'Tiaobingshan'], '211200'],
	['211282', ['开原', '開原', 'Kaiyuan'], '211200'],
	['211302', ['双塔', '雙塔', 'Twin tower'], '211300'],
	['211303', ['龙城', '龍城', 'Dragon city'], '211300'],
	['211321', ['朝阳', '朝陽', 'Chaoyang'], '211300'],
	['211322', ['建平', '建平', 'Jianping'], '211300'],
	['211324', ['喀左', '喀左', 'Kazuo'], '211300'],
	['211381', ['北票', '北票', 'Beipiao'], '211300'],
	['211382', ['凌源', '淩源', 'Lingyuan'], '211300'],
	['211402', ['连山', '連山', 'Lianshan'], '211400'],
	['211403', ['龙港', '龍港', 'Longgang'], '211400'],
	['211404', ['南票', '南票', 'South ticket'], '211400'],
	['211421', ['绥中', '綏中', 'Yuzhong'], '211400'],
	['211422', ['建昌', '建昌', 'Jianchang'], '211400'],
	['211481', ['兴城', '興城', 'Xingcheng'], '211400'],
	['220102', ['南关', '南關', 'South Gate'], '220100'],
	['220103', ['宽城', '寬城', 'Kuancheng'], '220100'],
	['220104', ['朝阳', '朝陽', 'Chaoyang'], '220100'],
	['220105', ['二道', '二道', 'Second road'], '220100'],
	['220106', ['绿园', '綠園', 'Green Park'], '220100'],
	['220112', ['双阳', '雙陽', 'Shuangyang'], '220100'],
	['220122', ['农安', '農安', 'Nongan'], '220100'],
	['220181', ['九台', '九臺', 'Jiutai'], '220100'],
	['220182', ['榆树', '榆樹', 'elm'], '220100'],
	['220183', ['德惠', '德惠', 'Dehui'], '220100'],
	['220202', ['昌邑', '昌邑', 'Chang Hao'], '220200'],
	['220203', ['龙潭', '龍潭', 'Longtan'], '220200'],
	['220204', ['船营', '船營', 'Boat camp'], '220200'],
	['220211', ['丰满', '豐滿', 'full'], '220200'],
	['220221', ['永吉', '永吉', 'Yongji'], '220200'],
	['220281', ['蛟河', '蛟河', 'Luohe'], '220200'],
	['220282', ['桦甸', '樺甸', 'Huadian'], '220200'],
	['220283', ['舒兰', '舒蘭', 'Shulan'], '220200'],
	['220284', ['磐石', '磐石', 'Meteorite'], '220200'],
	['220302', ['铁西', '鐵西', 'Tiexi'], '220300'],
	['220303', ['铁东', '鐵東', 'Tiedong'], '220300'],
	['220322', ['梨树', '梨樹', 'Pear tree'], '220300'],
	['220323', ['伊通', '伊通', 'Yitong'], '220300'],
	['220381', ['公主岭', '公主嶺', 'Gongzhuling'], '220300'],
	['220382', ['双辽', '雙遼', 'Shuangliao'], '220300'],
	['220402', ['龙山', '龍山', 'Longshan'], '220400'],
	['220403', ['西安', '西安', "Xi'an"], '220400'],
	['220421', ['东丰', '東豐', 'Dongfeng'], '220400'],
	['220422', ['东辽', '東遼', 'East Liao'], '220400'],
	['220502', ['东昌', '東昌', 'Dongchang'], '220500'],
	['220503', ['二道江', '二道江', 'Erdaojiang'], '220500'],
	['220521', ['通化', '通化', 'Tonghua'], '220500'],
	['220523', ['辉南', '輝南', 'Huinan'], '220500'],
	['220524', ['柳河', '柳河', 'Liuhe'], '220500'],
	['220581', ['梅河口', '梅河口', 'Meihekou'], '220500'],
	['220582', ['集安', '集安', "Ji'an"], '220500'],
	['220602', ['浑江', '渾江', 'Lijiang'], '220600'],
	['220621', ['抚松', '撫松', 'Fusong'], '220600'],
	['220622', ['靖宇', '靖宇', 'Jing Yu'], '220600'],
	['220623', ['长白', '長白', 'Changbai'], '220600'],
	['220625', ['江源', '江源', 'Jiangyuan'], '220600'],
	['220681', ['临江', '臨江', 'Linjiang'], '220600'],
	['220702', ['宁江', '寧江', 'Ningjiang'], '220700'],
	['220721', ['前郭', '前郭', 'Qian Guo'], '220700'],
	['220722', ['长岭', '長嶺', 'Changling'], '220700'],
	['220723', ['乾安', '乾安', 'Ganan'], '220700'],
	['220724', ['扶余', '扶余', 'Fuyu'], '220700'],
	['220802', ['洮北', '洮北', 'Yubei'], '220800'],
	['220821', ['镇赉', '鎮賚', 'Zhen Zhen'], '220800'],
	['220822', ['通榆', '通榆', 'Wanted'], '220800'],
	['220881', ['洮南', '洮南', 'Weinan'], '220800'],
	['220882', ['大安', '大安', 'Daan'], '220800'],
	['222401', ['延吉', '延吉', 'Yanji'], '222400'],
	['222402', ['图们', '圖們', 'Tumen'], '222400'],
	['222403', ['敦化', '敦化', 'Dunhua'], '222400'],
	['222404', ['珲春', '琿春', 'Hunchun'], '222400'],
	['222405', ['龙井', '龍井', 'Longjing'], '222400'],
	['222406', ['和龙', '和龍', 'And dragon'], '222400'],
	['222424', ['汪清', '汪清', 'Wang Qing'], '222400'],
	['222426', ['安图', '安圖', 'Antu'], '222400'],
	['230102', ['道里', '道裏', 'Daoli'], '230100'],
	['230103', ['南岗', '南崗', 'Nangang'], '230100'],
	['230104', ['道外', '道外', 'Outdoors'], '230100'],
	['230106', ['香坊', '香坊', 'Xiangfang'], '230100'],
	['230108', ['平房', '平房', 'bungalow'], '230100'],
	['230109', ['松北', '松北', 'Matsukita'], '230100'],
	['230111', ['呼兰', '呼蘭', 'Hulan'], '230100'],
	['230123', ['依兰', '依蘭', 'Yilan'], '230100'],
	['230124', ['方正', '方正', 'Founder'], '230100'],
	['230125', ['宾县', '賓縣', 'Bin County'], '230100'],
	['230126', ['巴彦', '巴彥', 'Bayan'], '230100'],
	['230127', ['木兰', '木蘭', 'Mulan'], '230100'],
	['230128', ['通河', '通河', 'Tonghe'], '230100'],
	['230129', ['延寿', '延壽', 'Yanshou'], '230100'],
	['230181', ['阿城', '阿城', 'Acheng'], '230100'],
	['230182', ['双城', '雙城', 'Twin city'], '230100'],
	['230183', ['尚志', '尚志', 'Shang Zhi'], '230100'],
	['230184', ['五常', '五常', 'Wuchang'], '230100'],
	['230202', ['龙沙', '龍沙', 'Longsha'], '230200'],
	['230203', ['建华', '建華', 'Jianhua'], '230200'],
	['230204', ['铁锋', '鐵鋒', 'Iron front'], '230200'],
	['230205', ['昂昂溪', '昂昂溪', 'Ang ang'], '230200'],
	['230206', ['富拉尔基', '富拉爾基', 'Fulalki'], '230200'],
	['230207', ['碾子山', '碾子山', 'Nianzishan'], '230200'],
	['230208', ['梅里斯达斡尔族', '梅裏斯達斡爾族', 'Meris Daur'], '230200'],
	['230221', ['龙江', '龍江', 'Longjiang'], '230200'],
	['230223', ['依安', '依安', "Yi'an"], '230200'],
	['230224', ['泰来', '泰來', 'Tailai'], '230200'],
	['230225', ['甘南', '甘南', 'Gannan'], '230200'],
	['230227', ['富裕', '富裕', 'rich'], '230200'],
	['230229', ['克山', '克山', 'Keshan'], '230200'],
	['230230', ['克东', '克東', 'Kedong'], '230200'],
	['230231', ['拜泉', '拜泉', 'Baiquan'], '230200'],
	['230281', ['讷河', '訥河', 'Nehe'], '230200'],
	['230302', ['鸡冠', '雞冠', 'crest'], '230300'],
	['230303', ['恒山', '恒山', 'Hengshan'], '230300'],
	['230304', ['滴道', '滴道', 'Drip'], '230300'],
	['230305', ['梨树', '梨樹', 'Pear tree'], '230300'],
	['230306', ['城子河', '城子河', 'Chengzi River'], '230300'],
	['230307', ['麻山', '麻山', 'Mountain'], '230300'],
	['230321', ['鸡东', '雞東', 'Jidong'], '230300'],
	['230381', ['虎林', '虎林', 'Hulin'], '230300'],
	['230382', ['密山', '密山', 'Mishan'], '230300'],
	['230402', ['向阳', '向陽', 'Xiangyang'], '230400'],
	['230403', ['工农', '工農', 'Workers and farmers'], '230400'],
	['230404', ['南山', '南山', 'Nanshan'], '230400'],
	['230405', ['兴安', '興安', "Xing'an"], '230400'],
	['230406', ['东山', '東山', 'Dongshan'], '230400'],
	['230407', ['兴山', '興山', 'Xingshan'], '230400'],
	['230421', ['萝北', '蘿北', 'Luobei'], '230400'],
	['230422', ['绥滨', '綏濱', 'Suibin'], '230400'],
	['230502', ['尖山', '尖山', 'Jianshan'], '230500'],
	['230503', ['岭东', '嶺東', 'Lingdong'], '230500'],
	['230505', ['四方台', '四方臺', 'Sifangtai'], '230500'],
	['230506', ['宝山', '寶山', 'Baoshan'], '230500'],
	['230521', ['集贤', '集賢', 'Ji Xian'], '230500'],
	['230522', ['友谊', '友誼', 'friendship'], '230500'],
	['230523', ['宝清', '寶清', 'Baoqing'], '230500'],
	['230524', ['饶河', '饒河', 'Raohe'], '230500'],
	['230602', ['萨尔图', '薩爾圖', 'Sarto'], '230600'],
	['230603', ['龙凤', '龍鳳', 'Longfeng'], '230600'],
	['230604', ['让胡路', '讓胡路', 'Let Hu Lu'], '230600'],
	['230605', ['红岗', '紅崗', 'Red gang'], '230600'],
	['230606', ['大同', '大同', 'Datong'], '230600'],
	['230621', ['肇州', '肇州', 'Zhangzhou'], '230600'],
	['230622', ['肇源', '肇源', 'Wuyuan'], '230600'],
	['230623', ['林甸', '林甸', 'Lindian'], '230600'],
	['230624', ['杜尔伯特', '杜爾伯特', 'Durbert'], '230600'],
	['230702', ['伊春', '伊春', 'Yichun'], '230700'],
	['230703', ['南岔', '南岔', 'Nanxun'], '230700'],
	['230704', ['友好', '友好', 'friendly'], '230700'],
	['230705', ['西林', '西林', 'Xilin'], '230700'],
	['230706', ['翠峦', '翠巒', 'Tsui'], '230700'],
	['230707', ['新青', '新青', 'New green'], '230700'],
	['230708', ['美溪', '美溪', 'Meixi'], '230700'],
	['230709', ['金山屯', '金山屯', 'Jinshanyu'], '230700'],
	['230710', ['五营', '五營', 'Five camp'], '230700'],
	['230711', ['乌马河', '烏馬河', 'Uma River'], '230700'],
	['230712', ['汤旺河', '湯旺河', 'Tangwang River'], '230700'],
	['230713', ['带岭', '帶嶺', 'Belt ridge'], '230700'],
	['230714', ['乌伊岭', '烏伊嶺', 'Wuyiling'], '230700'],
	['230715', ['红星', '紅星', 'Red star'], '230700'],
	['230716', ['上甘岭', '上甘嶺', 'Shangganling'], '230700'],
	['230722', ['嘉荫', '嘉蔭', 'Jia Yin'], '230700'],
	['230781', ['铁力', '鐵力', 'Iron force'], '230700'],
	['230803', ['向阳', '向陽', 'Xiangyang'], '230800'],
	['230804', ['前进', '前進', 'go ahead'], '230800'],
	['230805', ['东风', '東風', 'Dongfeng'], '230800'],
	['230811', ['郊区', '郊區', 'suburbs'], '230800'],
	['230822', ['桦南', '樺南', 'Huanan'], '230800'],
	['230826', ['桦川', '樺川', 'Huachuan'], '230800'],
	['230828', ['汤原', '湯原', 'Yuhara'], '230800'],
	['230833', ['抚远', '撫遠', 'Fuyuan'], '230800'],
	['230881', ['同江', '同江', 'Tongjiang'], '230800'],
	['230882', ['富锦', '富錦', 'Fujin'], '230800'],
	['230902', ['新兴', '新興', 'new'], '230900'],
	['230903', ['桃山', '桃山', 'Momoyama'], '230900'],
	['230904', ['茄子河', '茄子河', 'Eggplant river'], '230900'],
	['230921', ['勃利', '勃利', 'Boli'], '230900'],
	['231002', ['东安', '東安', 'Dongan'], '231000'],
	['231003', ['阳明', '陽明', 'Yangming'], '231000'],
	['231004', ['爱民', '愛民', 'Love people'], '231000'],
	['231005', ['西安', '西安', "Xi'an"], '231000'],
	['231024', ['东宁', '東寧', 'Tohno'], '231000'],
	['231025', ['林口', '林口', 'Linkou'], '231000'],
	['231081', ['绥芬河', '綏芬河', 'Suifenhe'], '231000'],
	['231083', ['海林', '海林', 'Hailin'], '231000'],
	['231084', ['宁安', '寧安', "Ning'an"], '231000'],
	['231085', ['穆棱', '穆棱', 'Mu Ling'], '231000'],
	['231102', ['爱辉', '愛輝', 'Aihui'], '231100'],
	['231121', ['嫩江', '嫩江', 'Nenjiang'], '231100'],
	['231123', ['逊克', '遜克', 'Xunk'], '231100'],
	['231124', ['孙吴', '孫吳', 'Sun Wu'], '231100'],
	['231181', ['北安', '北安', "Bei'an"], '231100'],
	['231182', ['五大连池', '五大連池', 'Five Dalian pool'], '231100'],
	['231202', ['北林', '北林', 'North'], '231200'],
	['231221', ['望奎', '望奎', 'Wang Kui'], '231200'],
	['231222', ['兰西', '蘭西', 'Lanxi'], '231200'],
	['231223', ['青冈', '青岡', 'Cyclobalanopsis'], '231200'],
	['231224', ['庆安', '慶安', "Qing'an"], '231200'],
	['231225', ['明水', '明水', 'Mingshui'], '231200'],
	['231226', ['绥棱', '綏棱', '绥 绥'], '231200'],
	['231281', ['安达', '安達', 'Anda'], '231200'],
	['231282', ['肇东', '肇東', 'Jidong'], '231200'],
	['231283', ['海伦', '海倫', 'Helen'], '231200'],
	['232702', ['松岭', '松嶺', 'Songling'], '232700'],
	['232703', ['新林', '新林', 'new Forest'], '232700'],
	['232704', ['呼中', '呼中', 'Huzhong'], '232700'],
	['232721', ['呼玛', '呼瑪', 'Huma'], '232700'],
	['232722', ['塔河', '塔河', 'Tahe'], '232700'],
	['232723', ['漠河', '漠河', 'Mohe'], '232700'],
	['232724', ['加格达奇', '加格達奇', 'Gagdach'], '232700'],
	['310101', ['黄浦', '黃浦', 'Huangpu'], '310100'],
	['310104', ['徐汇', '徐匯', 'Xuhui'], '310100'],
	['310105', ['长宁', '長寧', 'Changning'], '310100'],
	['310106', ['静安', '靜安', 'Jingan'], '310100'],
	['310107', ['普陀', '普陀', 'Putuo'], '310100'],
	['310109', ['虹口', '虹口', 'Hongkou'], '310100'],
	['310110', ['杨浦', '楊浦', 'Yangpu'], '310100'],
	['310112', ['闵行', '閔行', 'Minhang'], '310100'],
	['310113', ['宝山', '寶山', 'Baoshan'], '310100'],
	['310114', ['嘉定', '嘉定', 'Jiading'], '310100'],
	['310115', ['浦东', '浦東', 'Pudong'], '310100'],
	['310116', ['金山', '金山', 'Jinshan'], '310100'],
	['310117', ['松江', '松江', 'Songjiang'], '310100'],
	['310118', ['青浦', '青浦', 'Qingpu'], '310100'],
	['310120', ['奉贤', '奉賢', 'Fengxian'], '310100'],
	['310230', ['崇明', '崇明', 'Chongming'], '310100'],
	['320102', ['玄武', '玄武', 'Xuanwu'], '320100'],
	['320104', ['秦淮', '秦淮', 'Qinhuai'], '320100'],
	['320105', ['建邺', '建鄴', 'Jianye'], '320100'],
	['320106', ['鼓楼', '鼓樓', 'Drum Tower'], '320100'],
	['320111', ['浦口', '浦口', 'Pukou'], '320100'],
	['320113', ['栖霞', '棲霞', 'Qixia'], '320100'],
	['320114', ['雨花台', '雨花臺', 'Yuhuatai'], '320100'],
	['320115', ['江宁', '江寧', 'Jiangning'], '320100'],
	['320116', ['六合', '六合', 'Liuhe'], '320100'],
	['320124', ['溧水', '溧水', 'Drowning'], '320100'],
	['320125', ['高淳', '高淳', 'Sorghum'], '320100'],
	['320202', ['崇安', '崇安', "Chong'an"], '320200'],
	['320203', ['南长', '南長', 'Nanchang'], '320200'],
	['320204', ['北塘', '北塘', 'Beitang'], '320200'],
	['320205', ['锡山', '錫山', 'Xishan'], '320200'],
	['320206', ['惠山', '惠山', 'Huishan'], '320200'],
	['320211', ['滨湖', '濱湖', 'Lakeside'], '320200'],
	['320281', ['江阴', '江陰', 'Jiangyin'], '320200'],
	['320282', ['宜兴', '宜興', 'Yixing'], '320200'],
	['320302', ['鼓楼', '鼓樓', 'Drum Tower'], '320300'],
	['320303', ['云龙', '雲龍', 'Yunlong'], '320300'],
	['320305', ['贾汪', '賈汪', 'Jia Wang'], '320300'],
	['320311', ['泉山', '泉山', 'Quanshan'], '320300'],
	['320321', ['丰县', '豐縣', 'Feng County'], '320300'],
	['320322', ['沛县', '沛縣', 'Pei County'], '320300'],
	['320323', ['铜山', '銅山', 'Tongshan'], '320300'],
	['320324', ['睢宁', '睢寧', 'Suining'], '320300'],
	['320381', ['新沂', '新沂', 'New'], '320300'],
	['320382', ['邳州', '邳州', 'Zhangzhou'], '320300'],
	['320402', ['天宁', '天寧', 'Tianning'], '320400'],
	['320404', ['钟楼', '鐘樓', 'Clock tower'], '320400'],
	['320411', ['新北', '新北', 'North south'], '320400'],
	['320412', ['武进', '武進', 'Wu Jin'], '320400'],
	['320481', ['溧阳', '溧陽', 'Fuyang'], '320400'],
	['320482', ['金坛', '金壇', 'Jintan'], '320400'],
	['320505', ['虎丘', '虎丘', 'Tiger hill'], '320500'],
	['320506', ['吴中', '吳中', 'Wu Zhong'], '320500'],
	['320507', ['相城', '相城', 'Ojo'], '320500'],
	['320508', ['姑苏', '姑蘇', 'Gusu'], '320500'],
	['320581', ['常熟', '常熟', 'Changshu'], '320500'],
	['320582', ['张家港', '張家港', 'Zhangjiagang'], '320500'],
	['320583', ['昆山', '昆山', 'Kunshan'], '320500'],
	['320584', ['吴江', '吳江', 'Wu Jiang'], '320500'],
	['320585', ['太仓', '太倉', 'Taicang'], '320500'],
	['320602', ['崇川', '崇川', 'Chongchuan'], '320600'],
	['320611', ['港闸', '港閘', 'Gangzha'], '320600'],
	['320612', ['通州', '通州', 'Tongzhou'], '320600'],
	['320621', ['海安', '海安', "Hai'an"], '320600'],
	['320623', ['如东', '如東', 'Rudong'], '320600'],
	['320681', ['启东', '啟東', 'Qidong'], '320600'],
	['320682', ['如皋', '如皋', 'Rugao'], '320600'],
	['320684', ['海门', '海門', 'sea ​​Gate'], '320600'],
	['320703', ['连云', '連雲', 'Lianyun'], '320700'],
	['320705', ['新浦', '新浦', 'Shinpura'], '320700'],
	['320706', ['海州', '海州', 'Seaside'], '320700'],
	['320721', ['赣榆', '贛榆', '赣榆'], '320700'],
	['320722', ['东海', '東海', 'Tokai'], '320700'],
	['320723', ['灌云', '灌雲', 'Guanyun'], '320700'],
	['320724', ['灌南', '灌南', 'Guannan'], '320700'],
	['320802', ['清河', '清河', 'Qinghe'], '320800'],
	['320803', ['淮安', '淮安', "Huai'an"], '320800'],
	['320804', ['淮阴', '淮陰', 'Huaiyin'], '320800'],
	['320811', ['清浦', '清浦', 'Kiura'], '320800'],
	['320826', ['涟水', '漣水', 'Drowning'], '320800'],
	['320829', ['洪泽', '洪澤', 'Hongze'], '320800'],
	['320830', ['盱眙', '盱眙', 'Xu Yi'], '320800'],
	['320831', ['金湖', '金湖', 'Jinhu'], '320800'],
	['320902', ['亭湖', '亭湖', 'Pavilion lake'], '320900'],
	['320903', ['盐都', '鹽都', 'Salt'], '320900'],
	['320921', ['响水', '響水', 'Ringing water'], '320900'],
	['320922', ['滨海', '濱海', 'Coast'], '320900'],
	['320923', ['阜宁', '阜寧', 'Suining'], '320900'],
	['320924', ['射阳', '射陽', 'Shooting sun'], '320900'],
	['320925', ['建湖', '建湖', 'Jianhu'], '320900'],
	['320981', ['东台', '東臺', 'Dongtai'], '320900'],
	['320982', ['大丰', '大豐', 'Dafeng'], '320900'],
	['321002', ['广陵', '廣陵', 'Guangling'], '321000'],
	['321003', ['邗江', '邗江', 'Lijiang'], '321000'],
	['321023', ['宝应', '寶應', 'Baoying'], '321000'],
	['321081', ['仪征', '儀征', 'Yizheng'], '321000'],
	['321084', ['高邮', '高郵', 'Gaoyou'], '321000'],
	['321088', ['江都', '江都', 'Jiangdu'], '321000'],
	['321102', ['京口', '京口', 'Jingkou'], '321100'],
	['321111', ['润州', '潤州', 'Runzhou'], '321100'],
	['321112', ['丹徒', '丹徒', 'Dantu'], '321100'],
	['321181', ['丹阳', '丹陽', 'Danyang'], '321100'],
	['321182', ['扬中', '揚中', 'Yangzhong'], '321100'],
	['321183', ['句容', '句容', 'Jurong'], '321100'],
	['321202', ['海陵', '海陵', 'Hailing'], '321200'],
	['321203', ['高港', '高港', 'Gaogang'], '321200'],
	['321281', ['兴化', '興化', 'Xinghua'], '321200'],
	['321282', ['靖江', '靖江', 'Jingjiang'], '321200'],
	['321283', ['泰兴', '泰興', 'Taixing'], '321200'],
	['321284', ['姜堰', '薑堰', 'Jiang Yan'], '321200'],
	['321302', ['宿城', '宿城', 'Luck'], '321300'],
	['321311', ['宿豫', '宿豫', 'Suyu'], '321300'],
	['321322', ['沭阳', '沭陽', 'Fuyang'], '321300'],
	['321323', ['泗阳', '泗陽', 'Fuyang'], '321300'],
	['321324', ['泗洪', '泗洪', 'Sihong'], '321300'],
	['330102', ['上城', '上城', 'Uptown'], '330100'],
	['330103', ['下城', '下城', 'down town'], '330100'],
	['330104', ['江干', '江幹', 'Jiang Gan'], '330100'],
	['330105', ['拱墅', '拱墅', 'Arch'], '330100'],
	['330106', ['西湖', '西湖', 'West Lake'], '330100'],
	['330108', ['滨江', '濱江', 'Riverside'], '330100'],
	['330109', ['萧山', '蕭山', 'Xiaoshan'], '330100'],
	['330110', ['余杭', '余杭', 'Yu Hang'], '330100'],
	['330122', ['桐庐', '桐廬', 'Tonglu'], '330100'],
	['330127', ['淳安', '淳安', 'Diane'], '330100'],
	['330182', ['建德', '建德', 'Jiande'], '330100'],
	['330183', ['富阳', '富陽', 'Fuyang'], '330100'],
	['330185', ['临安', '臨安', 'Linan'], '330100'],
	['330203', ['海曙', '海曙', 'Sea otter'], '330200'],
	['330204', ['江东', '江東', 'Jiangdong'], '330200'],
	['330205', ['江北', '江北', 'Jiangbei'], '330200'],
	['330206', ['北仑', '北侖', 'Beilun'], '330200'],
	['330211', ['镇海', '鎮海', 'Zhenhai'], '330200'],
	['330212', ['鄞州', '鄞州', 'Zhangzhou'], '330200'],
	['330225', ['象山', '象山', 'Xiangshan'], '330200'],
	['330226', ['宁海', '寧海', 'Ninghai'], '330200'],
	['330281', ['余姚', '余姚', 'Yuyao'], '330200'],
	['330282', ['慈溪', '慈溪', 'Cixi'], '330200'],
	['330283', ['奉化', '奉化', 'Fenghua'], '330200'],
	['330302', ['鹿城', '鹿城', 'Lucheng'], '330300'],
	['330303', ['龙湾', '龍灣', 'Longwan'], '330300'],
	['330304', ['瓯海', '甌海', 'Bohai'], '330300'],
	['330322', ['洞头', '洞頭', 'Cave head'], '330300'],
	['330324', ['永嘉', '永嘉', 'Yongjia'], '330300'],
	['330326', ['平阳', '平陽', 'Pingyang'], '330300'],
	['330327', ['苍南', '蒼南', 'Cangnan'], '330300'],
	['330328', ['文成', '文成', 'Wen Cheng'], '330300'],
	['330329', ['泰顺', '泰順', 'Taishun'], '330300'],
	['330381', ['瑞安', '瑞安', 'Ryan'], '330300'],
	['330382', ['乐清', '樂清', 'Yueqing'], '330300'],
	['330402', ['南湖', '南湖', 'South lake'], '330400'],
	['330411', ['秀洲', '秀洲', 'Xiuzhou'], '330400'],
	['330421', ['嘉善', '嘉善', 'Jiashan'], '330400'],
	['330424', ['海盐', '海鹽', 'sea ​​salt'], '330400'],
	['330481', ['海宁', '海寧', 'Haining'], '330400'],
	['330482', ['平湖', '平湖', 'Pinghu'], '330400'],
	['330483', ['桐乡', '桐鄉', 'Tongxiang'], '330400'],
	['330502', ['吴兴', '吳興', 'Wu Xing'], '330500'],
	['330503', ['南浔', '南潯', 'Nanxun'], '330500'],
	['330521', ['德清', '德清', 'Deqing'], '330500'],
	['330522', ['长兴', '長興', 'Changxing'], '330500'],
	['330523', ['安吉', '安吉', 'Anji'], '330500'],
	['330602', ['越城', '越城', 'Yuecheng'], '330600'],
	['330621', ['柯桥', '柯橋', 'Keqiao'], '330600'],
	['330624', ['新昌', '新昌', 'Xinchang'], '330600'],
	['330681', ['诸暨', '諸暨', 'Zhuji'], '330600'],
	['330682', ['上虞', '上虞', 'Captain'], '330600'],
	['330683', ['嵊州', '嵊州', 'Zhangzhou'], '330600'],
	['330702', ['婺城', '婺城', 'Yucheng'], '330700'],
	['330703', ['金东', '金東', 'Kinto'], '330700'],
	['330723', ['武义', '武義', 'Wuyi'], '330700'],
	['330726', ['浦江', '浦江', 'Pujiang'], '330700'],
	['330727', ['磐安', '磐安', 'Diane'], '330700'],
	['330781', ['兰溪', '蘭溪', 'Lanxi'], '330700'],
	['330782', ['义乌', '義烏', 'Yiwu'], '330700'],
	['330783', ['东阳', '東陽', 'Dongyang'], '330700'],
	['330784', ['永康', '永康', 'Yongkang'], '330700'],
	['330802', ['柯城', '柯城', 'Kecheng'], '330800'],
	['330803', ['衢江', '衢江', 'Lijiang'], '330800'],
	['330822', ['常山', '常山', 'Changshan'], '330800'],
	['330824', ['开化', '開化', 'Kaihua'], '330800'],
	['330825', ['龙游', '龍遊', 'Longyou'], '330800'],
	['330881', ['江山', '江山', 'country'], '330800'],
	['330902', ['定海', '定海', 'Dinghai'], '330900'],
	['330903', ['普陀', '普陀', 'Putuo'], '330900'],
	['330921', ['岱山', '岱山', 'Lushan'], '330900'],
	['330922', ['嵊泗', '嵊泗', '嵊泗'], '330900'],
	['331002', ['椒江', '椒江', 'Jiaojiang'], '331000'],
	['331003', ['黄岩', '黃巖', 'Huang Yan'], '331000'],
	['331004', ['路桥', '路橋', 'Road bridge'], '331000'],
	['331021', ['玉环', '玉環', 'Yuhuan'], '331000'],
	['331022', ['三门', '三門', 'Three doors'], '331000'],
	['331023', ['天台', '天臺', 'Rooftop'], '331000'],
	['331024', ['仙居', '仙居', 'Xianju'], '331000'],
	['331081', ['温岭', '溫嶺', 'Wenling'], '331000'],
	['331082', ['临海', '臨海', 'Linhai'], '331000'],
	['331102', ['莲都', '蓮都', 'Lotus'], '331100'],
	['331121', ['青田', '青田', 'Qingtian'], '331100'],
	['331122', ['缙云', '縉雲', 'Jinyun'], '331100'],
	['331123', ['遂昌', '遂昌', 'Suichang'], '331100'],
	['331124', ['松阳', '松陽', 'Songyang'], '331100'],
	['331125', ['云和', '雲和', 'Cloud and'], '331100'],
	['331126', ['庆元', '慶元', 'Qingyuan'], '331100'],
	['331127', ['景宁', '景寧', 'Jingning'], '331100'],
	['331181', ['龙泉', '龍泉', 'Longquan'], '331100'],
	['340102', ['瑶海', '瑤海', 'Yaohai'], '340100'],
	['340103', ['庐阳', '廬陽', 'Fuyang'], '340100'],
	['340104', ['蜀山', '蜀山', 'Shuzan'], '340100'],
	['340111', ['包河', '包河', 'Baohe'], '340100'],
	['340121', ['长丰', '長豐', 'Changfeng'], '340100'],
	['340122', ['肥东', '肥東', 'Feidong'], '340100'],
	['340123', ['肥西', '肥西', 'Feixi'], '340100'],
	['340202', ['镜湖', '鏡湖', 'Mirror lake'], '340200'],
	['340203', ['弋江', '弋江', 'Lijiang'], '340200'],
	['340207', ['鸠江', '鳩江', 'Lijiang'], '340200'],
	['340208', ['三山', '三山', 'Miyama'], '340200'],
	['340221', ['芜湖', '蕪湖', 'Wuhu'], '340200'],
	['340222', ['繁昌', '繁昌', 'Fanchang'], '340200'],
	['340223', ['南陵', '南陵', 'Nanling'], '340200'],
	['340302', ['龙子湖', '龍子湖', 'Longzi Lake'], '340300'],
	['340303', ['蚌山', '蚌山', 'Lushan'], '340300'],
	['340304', ['禹会', '禹會', 'Meeting'], '340300'],
	['340311', ['淮上', '淮上', 'Huai Shang'], '340300'],
	['340321', ['怀远', '懷遠', 'Huaiyuan'], '340300'],
	['340322', ['五河', '五河', 'Wu river'], '340300'],
	['340323', ['固镇', '固鎮', 'Guzhen'], '340300'],
	['340402', ['大通', '大通', 'Chase'], '340400'],
	['340403', ['田家庵', '田家庵', 'Tian Jiayu'], '340400'],
	['340404', ['谢家集', '謝家集', 'Xie Jiaji'], '340400'],
	['340405', ['八公山', '八公山', 'Bagong Mountain'], '340400'],
	['340406', ['潘集', '潘集', 'Pan set'], '340400'],
	['340421', ['凤台', '鳳臺', 'Fengtai'], '340400'],
	['340503', ['花山', '花山', 'Flower mountain'], '340500'],
	['340504', ['雨山', '雨山', 'Rain mountain'], '340500'],
	['340506', ['博望', '博望', 'Bowang'], '340500'],
	['340499', ['寿县', '壽縣', 'Shouxian'], '340400'],
	['340521', ['当涂', '當塗', 'When painted'], '340500'],
	['340602', ['杜集', '杜集', 'Du Ji'], '340600'],
	['340603', ['相山', '相山', 'Aoyama'], '340600'],
	['340604', ['烈山', '烈山', 'Lieshan'], '340600'],
	['340621', ['濉溪', '濉溪', 'Tunxi'], '340600'],
	['340705', ['铜官', '銅官', 'Tongguan'], '340700'],
	['340711', ['郊区', '郊區', 'suburbs'], '340700'],
	['340721', ['铜陵', '銅陵', 'Tongling'], '340700'],
	['340799', ['枞阳', '樅陽', 'Fuyang'], '340700'],
	['340802', ['迎江', '迎江', 'Yingjiang'], '340800'],
	['340803', ['大观', '大觀', 'Grand view'], '340800'],
	['340811', ['宜秀', '宜秀', 'Yi Xiu'], '340800'],
	['340822', ['怀宁', '懷寧', 'Huaining'], '340800'],
	['340824', ['潜山', '潛山', 'Buried hill'], '340800'],
	['340825', ['太湖', '太湖', 'Taihu Lake'], '340800'],
	['340826', ['宿松', '宿松', 'Susong'], '340800'],
	['340827', ['望江', '望江', 'Wangjiang'], '340800'],
	['340828', ['岳西', '嶽西', 'Yuexi'], '340800'],
	['340881', ['桐城', '桐城', 'Tongcheng'], '340800'],
	['341002', ['屯溪', '屯溪', 'Tunxi'], '341000'],
	['341003', ['黄山', '黃山', 'Huangshan'], '341000'],
	['341004', ['徽州', '徽州', 'Huizhou'], '341000'],
	['341021', ['歙县', '歙縣', 'Ji County'], '341000'],
	['341022', ['休宁', '休寧', 'Xiuning'], '341000'],
	['341023', ['黟县', '黟縣', 'Ji County'], '341000'],
	['341024', ['祁门', '祁門', 'Trick'], '341000'],
	['341102', ['琅琊', '瑯琊', '琅琊'], '341100'],
	['341103', ['南谯', '南譙', 'Nanxun'], '341100'],
	['341122', ['来安', '來安', 'Lai An'], '341100'],
	['341124', ['全椒', '全椒', 'Whole pepper'], '341100'],
	['341125', ['定远', '定遠', 'Dingyuan'], '341100'],
	['341126', ['凤阳', '鳳陽', 'Fengyang'], '341100'],
	['341181', ['天长', '天長', 'Long'], '341100'],
	['341182', ['明光', '明光', 'Mingguang'], '341100'],
	['341202', ['颍州', '潁州', 'Zhangzhou'], '341200'],
	['341203', ['颍东', '潁東', 'Jidong'], '341200'],
	['341204', ['颍泉', '潁泉', '颍泉'], '341200'],
	['341221', ['临泉', '臨泉', 'Linquan'], '341200'],
	['341222', ['太和', '太和', 'Taihe'], '341200'],
	['341225', ['阜南', '阜南', 'Weinan'], '341200'],
	['341226', ['颍上', '潁上', 'Squat'], '341200'],
	['341282', ['界首', '界首', 'Head of the world'], '341200'],
	['341302', ['埇桥', '埇橋', 'Bridge'], '341300'],
	['341321', ['砀山', '碭山', 'Lushan'], '341300'],
	['341322', ['萧县', '蕭縣', 'Xiao County'], '341300'],
	['341323', ['灵璧', '靈璧', 'Lingbi'], '341300'],
	['341324', ['泗县', '泗縣', 'Ji County'], '341300'],
	['341400', ['巢湖', '巢湖', 'Chaohu'], '340100'],
	['341421', ['庐江', '廬江', 'Lijiang'], '340100'],
	['341422', ['无为', '無為', 'Inaction'], '340200'],
	['341423', ['含山', '含山', 'Mountain'], '340500'],
	['341424', ['和县', '和縣', 'And county'], '340500'],
	['341502', ['金安', '金安', 'Jinan'], '341500'],
	['341503', ['裕安', '裕安', "Yu'an"], '341500'],
	['341522', ['霍邱', '霍邱', 'Huo Qiu'], '341500'],
	['341523', ['舒城', '舒城', 'Shucheng'], '341500'],
	['341524', ['金寨', '金寨', 'Jinzhai'], '341500'],
	['341525', ['霍山', '霍山', 'Huoshan'], '341500'],
	['341602', ['谯城', '譙城', 'Yucheng'], '341600'],
	['341621', ['涡阳', '渦陽', 'Guoyang'], '341600'],
	['341622', ['蒙城', '蒙城', 'Mengcheng'], '341600'],
	['341623', ['利辛', '利辛', 'Lisin'], '341600'],
	['341702', ['贵池', '貴池', 'Guichi'], '341700'],
	['341721', ['东至', '東至', 'East to'], '341700'],
	['341722', ['石台', '石臺', 'Ishida'], '341700'],
	['341723', ['青阳', '青陽', 'Qingyang'], '341700'],
	['341802', ['宣州', '宣州', 'Xuanzhou'], '341800'],
	['341821', ['郎溪', '郎溪', 'Langxi'], '341800'],
	['341822', ['广德', '廣德', 'Guangde'], '341800'],
	['341823', ['泾县', '涇縣', 'Ji County'], '341800'],
	['341824', ['绩溪', '績溪', 'Jixi'], '341800'],
	['341825', ['旌德', '旌德', 'Jude'], '341800'],
	['341881', ['宁国', '寧國', 'Ningguo'], '341800'],
	['350102', ['鼓楼', '鼓樓', 'Drum Tower'], '350100'],
	['350103', ['台江', '臺江', 'Taijiang'], '350100'],
	['350104', ['仓山', '倉山', 'Cangshan'], '350100'],
	['350105', ['马尾', '馬尾', 'Horsetail'], '350100'],
	['350111', ['晋安', '晉安', 'Jinan'], '350100'],
	['350121', ['闽侯', '閩侯', 'Minhou'], '350100'],
	['350122', ['连江', '連江', 'Lianjiang'], '350100'],
	['350123', ['罗源', '羅源', 'Luo Yuan'], '350100'],
	['350124', ['闽清', '閩清', 'Minqing'], '350100'],
	['350125', ['永泰', '永泰', 'Yongtai'], '350100'],
	['350128', ['平潭', '平潭', 'Pingtan'], '350100'],
	['350181', ['福清', '福清', 'Fuqing'], '350100'],
	['350182', ['长乐', '長樂', 'Changle'], '350100'],
	['350203', ['思明', '思明', 'Siming'], '350200'],
	['350205', ['海沧', '海滄', 'Sea otter'], '350200'],
	['350206', ['湖里', '湖裏', 'Lake'], '350200'],
	['350211', ['集美', '集美', 'Jimei'], '350200'],
	['350212', ['同安', '同安', 'Tongan'], '350200'],
	['350213', ['翔安', '翔安', 'Xiang An'], '350200'],
	['350302', ['城厢', '城廂', 'City compartment'], '350300'],
	['350303', ['涵江', '涵江', 'Hanjiang'], '350300'],
	['350304', ['荔城', '荔城', 'Yucheng'], '350300'],
	['350305', ['秀屿', '秀嶼', 'Xiuyu'], '350300'],
	['350322', ['仙游', '仙遊', 'Xianyou'], '350300'],
	['350402', ['梅列', '梅列', 'Merle'], '350400'],
	['350403', ['三元', '三元', 'Ternary'], '350400'],
	['350421', ['明溪', '明溪', 'Mingxi'], '350400'],
	['350423', ['清流', '清流', 'Clear stream'], '350400'],
	['350424', ['宁化', '寧化', 'Ninghua'], '350400'],
	['350425', ['大田', '大田', 'Ota'], '350400'],
	['350426', ['尤溪', '尤溪', 'Youxi'], '350400'],
	['350427', ['沙县', '沙縣', 'Sha County'], '350400'],
	['350428', ['将乐', '將樂', 'Will'], '350400'],
	['350429', ['泰宁', '泰寧', 'Taining'], '350400'],
	['350430', ['建宁', '建寧', 'Jianning'], '350400'],
	['350481', ['永安', '永安', 'Yongan'], '350400'],
	['350502', ['鲤城', '鯉城', 'Yucheng'], '350500'],
	['350503', ['丰泽', '豐澤', 'Fortress'], '350500'],
	['350504', ['洛江', '洛江', 'Luojiang'], '350500'],
	['350505', ['泉港', '泉港', 'Fountain port'], '350500'],
	['350521', ['惠安', '惠安', "Hui'an"], '350500'],
	['350524', ['安溪', '安溪', 'Anxi'], '350500'],
	['350525', ['永春', '永春', 'Yongchun'], '350500'],
	['350526', ['德化', '德化', 'Dehua'], '350500'],
	['350527', ['金门', '金門', 'Golden Gate'], '350500'],
	['350581', ['石狮', '石獅', 'Stone lion'], '350500'],
	['350582', ['晋江', '晉江', 'Jinjiang'], '350500'],
	['350583', ['南安', '南安', 'Nanan'], '350500'],
	['350602', ['芗城', '薌城', 'Yucheng'], '350600'],
	['350603', ['龙文', '龍文', 'Long Wen'], '350600'],
	['350622', ['云霄', '雲霄', 'Yunxiao'], '350600'],
	['350623', ['漳浦', '漳浦', 'Zhapu'], '350600'],
	['350624', ['诏安', '詔安', 'Diane'], '350600'],
	['350625', ['长泰', '長泰', 'Changtai'], '350600'],
	['350626', ['东山', '東山', 'Dongshan'], '350600'],
	['350627', ['南靖', '南靖', 'Nanjing'], '350600'],
	['350628', ['平和', '平和', 'peace'], '350600'],
	['350629', ['华安', '華安', 'Huaan'], '350600'],
	['350681', ['龙海', '龍海', 'Longhai'], '350600'],
	['350702', ['延平', '延平', 'Yanping'], '350700'],
	['350721', ['顺昌', '順昌', 'Shunchang'], '350700'],
	['350722', ['浦城', '浦城', 'Pucheng'], '350700'],
	['350723', ['光泽', '光澤', 'luster'], '350700'],
	['350724', ['松溪', '松溪', 'Songxi'], '350700'],
	['350725', ['政和', '政和', 'Political and'], '350700'],
	['350781', ['邵武', '邵武', 'Shao Wu'], '350700'],
	['350782', ['武夷山', '武夷山', 'Wuyishan'], '350700'],
	['350783', ['建瓯', '建甌', 'Jianye'], '350700'],
	['350784', ['建阳', '建陽', 'Jianyang'], '350700'],
	['350802', ['新罗', '新羅', 'Shilla'], '350800'],
	['350821', ['长汀', '長汀', 'Changting'], '350800'],
	['350822', ['永定', '永定', 'Yongding'], '350800'],
	['350823', ['上杭', '上杭', 'Shanghang'], '350800'],
	['350824', ['武平', '武平', 'Shibei'], '350800'],
	['350825', ['连城', '連城', 'Liancheng'], '350800'],
	['350881', ['漳平', '漳平', 'Muping'], '350800'],
	['350902', ['蕉城', '蕉城', 'Banana city'], '350900'],
	['350921', ['霞浦', '霞浦', 'Xiapu'], '350900'],
	['350922', ['古田', '古田', 'Furuta'], '350900'],
	['350923', ['屏南', '屏南', 'Pingnan'], '350900'],
	['350924', ['寿宁', '壽寧', 'Shouning'], '350900'],
	['350925', ['周宁', '周寧', 'Zhou Ning'], '350900'],
	['350926', ['柘荣', '柘榮', 'Rong Rong'], '350900'],
	['350981', ['福安', '福安', 'Fuan'], '350900'],
	['350982', ['福鼎', '福鼎', 'Fuding'], '350900'],
	['360102', ['东湖', '東湖', 'East lake'], '360100'],
	['360103', ['西湖', '西湖', 'West Lake'], '360100'],
	['360104', ['青云谱', '青雲譜', 'Qingyun spectrum'], '360100'],
	['360105', ['湾里', '灣裏', 'Bay'], '360100'],
	['360111', ['青山湖', '青山湖', 'Aoyama lake'], '360100'],
	['360121', ['南昌', '南昌', 'Nanchang'], '360100'],
	['360122', ['新建', '新建', 'New'], '360100'],
	['360123', ['安义', '安義', 'Anyi'], '360100'],
	['360124', ['进贤', '進賢', 'Jin Xian'], '360100'],
	['360202', ['昌江', '昌江', 'Changjiang'], '360200'],
	['360203', ['珠山', '珠山', 'Zhushan'], '360200'],
	['360222', ['浮梁', '浮梁', 'Floating beam'], '360200'],
	['360281', ['乐平', '樂平', 'Leping'], '360200'],
	['360302', ['安源', '安源', 'Anyuan'], '360300'],
	['360313', ['湘东', '湘東', 'Xiangdong'], '360300'],
	['360321', ['莲花', '蓮花', 'lotus'], '360300'],
	['360322', ['上栗', '上栗', 'Shang Li'], '360300'],
	['360323', ['芦溪', '蘆溪', 'Luxi'], '360300'],
	['360402', ['庐山', '廬山', 'Lushan'], '360400'],
	['360403', ['浔阳', '潯陽', 'Fuyang'], '360400'],
	['360421', ['九江', '九江', 'Jiujiang'], '360400'],
	['360423', ['武宁', '武寧', 'Wuning'], '360400'],
	['360424', ['修水', '修水', 'Repairing water'], '360400'],
	['360425', ['永修', '永修', 'Yongxiu'], '360400'],
	['360426', ['德安', '德安', 'Dean'], '360400'],
	['360427', ['星子', '星子', 'Star'], '360400'],
	['360428', ['都昌', '都昌', 'Duchang'], '360400'],
	['360429', ['湖口', '湖口', 'Hukou'], '360400'],
	['360430', ['彭泽', '彭澤', 'Peng Ze'], '360400'],
	['360481', ['瑞昌', '瑞昌', 'Ruichang'], '360400'],
	['360483', ['共青城', '共青城', 'Gongqingcheng'], '360400'],
	['360502', ['渝水', '渝水', 'Drowning'], '360500'],
	['360521', ['分宜', '分宜', 'Fractal'], '360500'],
	['360602', ['月湖', '月湖', 'Moon Lake'], '360600'],
	['360622', ['余江', '余江', 'Yu Jiang'], '360600'],
	['360681', ['贵溪', '貴溪', 'Guixi'], '360600'],
	['360702', ['章贡', '章貢', 'Zhang Gong'], '360700'],
	['360721', ['赣县', '贛縣', 'Ji County'], '360700'],
	['360722', ['信丰', '信豐', 'Xinfeng'], '360700'],
	['360723', ['大余', '大余', 'Dayu'], '360700'],
	['360724', ['上犹', '上猶', 'Shangyu'], '360700'],
	['360725', ['崇义', '崇義', 'Chongyi'], '360700'],
	['360726', ['安远', '安遠', 'Anyuan'], '360700'],
	['360727', ['龙南', '龍南', 'Longnan'], '360700'],
	['360728', ['定南', '定南', 'Dingnan'], '360700'],
	['360729', ['全南', '全南', 'Quannan'], '360700'],
	['360730', ['宁都', '寧都', 'Ningdu'], '360700'],
	['360731', ['于都', '於都', 'Yudu'], '360700'],
	['360732', ['兴国', '興國', 'Xingguo'], '360700'],
	['360733', ['会昌', '會昌', 'Huichang'], '360700'],
	['360734', ['寻乌', '尋烏', 'Searching for black'], '360700'],
	['360735', ['石城', '石城', 'Stone city'], '360700'],
	['360781', ['瑞金', '瑞金', 'Ruijin'], '360700'],
	['360782', ['南康', '南康', 'Nankang'], '360700'],
	['360802', ['吉州', '吉州', 'Yoshizu'], '360800'],
	['360803', ['青原', '青原', 'Aomi'], '360800'],
	['360821', ['吉安', '吉安', "Ji'an"], '360800'],
	['360822', ['吉水', '吉水', 'Yoshimizu'], '360800'],
	['360823', ['峡江', '峽江', 'Xiajiang'], '360800'],
	['360824', ['新干', '新幹', 'A new trunk'], '360800'],
	['360825', ['永丰', '永豐', 'Yongfeng'], '360800'],
	['360826', ['泰和', '泰和', 'Taihe'], '360800'],
	['360827', ['遂川', '遂川', 'Suichuan'], '360800'],
	['360828', ['万安', '萬安', 'Wanan'], '360800'],
	['360829', ['安福', '安福', 'Anfu'], '360800'],
	['360830', ['永新', '永新', 'Yongxin'], '360800'],
	['360881', ['井冈山', '井岡山', 'Jinggangshan'], '360800'],
	['360902', ['袁州', '袁州', 'Yuanzhou'], '360900'],
	['360921', ['奉新', '奉新', 'Fengxin'], '360900'],
	['360922', ['万载', '萬載', 'Million'], '360900'],
	['360923', ['上高', '上高', 'Upper height'], '360900'],
	['360924', ['宜丰', '宜豐', 'Yifeng'], '360900'],
	['360925', ['靖安', '靖安', 'Jingan'], '360900'],
	['360926', ['铜鼓', '銅鼓', 'Bronze drum'], '360900'],
	['360981', ['丰城', '豐城', 'Fengcheng'], '360900'],
	['360982', ['樟树', '樟樹', 'Eucalyptus'], '360900'],
	['360983', ['高安', '高安', 'High-quality'], '360900'],
	['361002', ['临川', '臨川', 'Linchuan'], '361000'],
	['361021', ['南城', '南城', 'Nancheng'], '361000'],
	['361022', ['黎川', '黎川', 'Li Chuan'], '361000'],
	['361023', ['南丰', '南豐', 'Nanfeng'], '361000'],
	['361024', ['崇仁', '崇仁', 'Chongren'], '361000'],
	['361025', ['乐安', '樂安', 'Lean'], '361000'],
	['361026', ['宜黄', '宜黃', 'Yihuang'], '361000'],
	['361027', ['金溪', '金溪', 'Jinxi'], '361000'],
	['361028', ['资溪', '資溪', 'Zixi'], '361000'],
	['361029', ['东乡', '東鄉', 'Dongxiang'], '361000'],
	['361030', ['广昌', '廣昌', 'Guangchang'], '361000'],
	['361102', ['信州', '信州', 'Shinshu'], '361100'],
	['361121', ['上饶', '上饒', 'Shangrao'], '361100'],
	['361122', ['广丰', '廣豐', 'Guangfeng'], '361100'],
	['361123', ['玉山', '玉山', 'Yushan'], '361100'],
	['361124', ['铅山', '鉛山', 'Lead mountain'], '361100'],
	['361125', ['横峰', '橫峰', 'Hengfeng'], '361100'],
	['361126', ['弋阳', '弋陽', 'Fuyang'], '361100'],
	['361127', ['余干', '余幹', 'Yu Gan'], '361100'],
	['361128', ['鄱阳', '鄱陽', 'Fuyang'], '361100'],
	['361129', ['万年', '萬年', 'Wannian'], '361100'],
	['361130', ['婺源', '婺源', 'Wuyuan'], '361100'],
	['361181', ['德兴', '德興', 'Dexing'], '361100'],
	['370102', ['历下', '曆下', 'Calendar'], '370100'],
	['370103', ['市中', '市中', 'In the market'], '370100'],
	['370104', ['槐荫', '槐蔭', 'Shade'], '370100'],
	['370105', ['天桥', '天橋', 'Overpass'], '370100'],
	['370112', ['历城', '曆城', 'Buddhism'], '370100'],
	['370113', ['长清', '長清', 'Changqing'], '370100'],
	['370124', ['平阴', '平陰', 'Pingyin'], '370100'],
	['370125', ['济阳', '濟陽', 'Jiyang'], '370100'],
	['370126', ['商河', '商河', 'Shanghe'], '370100'],
	['370181', ['章丘', '章丘', 'Zhang Qiu'], '370100'],
	['370202', ['市南', '市南', 'Shinan'], '370200'],
	['370203', ['市北', '市北', 'City north'], '370200'],
	['370211', ['黄岛', '黃島', 'Huangdao'], '370200'],
	['370212', ['崂山', '嶗山', 'Lushan'], '370200'],
	['370213', ['李沧', '李滄', 'Li Wei'], '370200'],
	['370214', ['城阳', '城陽', 'Castle'], '370200'],
	['370281', ['胶州', '膠州', 'Jiaozhou'], '370200'],
	['370282', ['即墨', '即墨', 'Jimo'], '370200'],
	['370283', ['平度', '平度', 'Flatness'], '370200'],
	['370285', ['莱西', '萊西', 'Lacey'], '370200'],
	['370302', ['淄川', '淄川', 'Suichuan'], '370300'],
	['370303', ['张店', '張店', 'Zhangdian'], '370300'],
	['370304', ['博山', '博山', 'Boshan'], '370300'],
	['370305', ['临淄', '臨淄', 'Linyi'], '370300'],
	['370306', ['周村', '周村', 'Zhoucun'], '370300'],
	['370321', ['桓台', '桓臺', 'Platform'], '370300'],
	['370322', ['高青', '高青', 'Gao Qing'], '370300'],
	['370323', ['沂源', '沂源', 'Wuyuan'], '370300'],
	['370402', ['市中', '市中', 'In the market'], '370400'],
	['370403', ['薛城', '薛城', 'Xue Cheng'], '370400'],
	['370404', ['峄城', '嶧城', 'Yucheng'], '370400'],
	['370405', ['台儿庄', '臺兒莊', 'Taierzhuang'], '370400'],
	['370406', ['山亭', '山亭', 'Mountain pavilion'], '370400'],
	['370481', ['滕州', '滕州', 'Tengzhou'], '370400'],
	['370502', ['东营', '東營', 'Dongying'], '370500'],
	['370503', ['河口', '河口', 'estuary'], '370500'],
	['370521', ['垦利', '墾利', 'Profit'], '370500'],
	['370522', ['利津', '利津', 'Lijin'], '370500'],
	['370523', ['广饶', '廣饒', 'Guangrao'], '370500'],
	['370602', ['芝罘', '芝罘', 'Zhifu'], '370600'],
	['370611', ['福山', '福山', 'Fukuyama'], '370600'],
	['370612', ['牟平', '牟平', 'Muping'], '370600'],
	['370613', ['莱山', '萊山', 'Laishan'], '370600'],
	['370634', ['长岛', '長島', 'Long Island'], '370600'],
	['370681', ['龙口', '龍口', 'Longkou'], '370600'],
	['370682', ['莱阳', '萊陽', 'Laiyang'], '370600'],
	['370683', ['莱州', '萊州', 'Laizhou'], '370600'],
	['370684', ['蓬莱', '蓬萊', 'Penglai'], '370600'],
	['370685', ['招远', '招遠', 'Zhaoyuan'], '370600'],
	['370686', ['栖霞', '棲霞', 'Qixia'], '370600'],
	['370687', ['海阳', '海陽', 'Haiyang'], '370600'],
	['370702', ['潍城', '濰城', 'Yucheng'], '370700'],
	['370703', ['寒亭', '寒亭', 'Hanting'], '370700'],
	['370704', ['坊子', '坊子', 'Fangzi'], '370700'],
	['370705', ['奎文', '奎文', 'Kuiwen'], '370700'],
	['370724', ['临朐', '臨朐', 'Linyi'], '370700'],
	['370725', ['昌乐', '昌樂', 'Changle'], '370700'],
	['370781', ['青州', '青州', 'Qingzhou'], '370700'],
	['370782', ['诸城', '諸城', 'Zhucheng'], '370700'],
	['370783', ['寿光', '壽光', 'Shouguang'], '370700'],
	['370784', ['安丘', '安丘', 'Anqiu'], '370700'],
	['370785', ['高密', '高密', 'high density'], '370700'],
	['370786', ['昌邑', '昌邑', 'Chang Hao'], '370700'],
	['370802', ['市中', '市中', 'In the market'], '370800'],
	['370811', ['任城', '任城', 'Rencheng'], '370800'],
	['370826', ['微山', '微山', 'Weishan'], '370800'],
	['370827', ['鱼台', '魚臺', 'Fish station'], '370800'],
	['370828', ['金乡', '金鄉', 'Jinxiang'], '370800'],
	['370829', ['嘉祥', '嘉祥', 'Jiaxiang'], '370800'],
	['370830', ['汶上', '汶上', 'Wenshang'], '370800'],
	['370831', ['泗水', '泗水', 'Drowning'], '370800'],
	['370832', ['梁山', '梁山', 'Liangshan'], '370800'],
	['370881', ['曲阜', '曲阜', 'Qufu'], '370800'],
	['370882', ['兖州', '兗州', 'Yanzhou'], '370800'],
	['370883', ['邹城', '鄒城', 'Zou Cheng'], '370800'],
	['370902', ['泰山', '泰山', 'Taishan'], '370900'],
	['370903', ['岱岳', '岱嶽', 'Yu Yue'], '370900'],
	['370921', ['宁阳', '寧陽', 'Ningyang'], '370900'],
	['370923', ['东平', '東平', 'Dongping'], '370900'],
	['370982', ['新泰', '新泰', 'Xintai'], '370900'],
	['370983', ['肥城', '肥城', 'Feicheng'], '370900'],
	['371002', ['环翠', '環翠', 'Huancui'], '371000'],
	['371081', ['文登', '文登', 'Literary translation'], '371000'],
	['371082', ['荣成', '榮成', 'Rongcheng'], '371000'],
	['371083', ['乳山', '乳山', 'Rushan'], '371000'],
	['371102', ['东港', '東港', 'Eastern port'], '371100'],
	['371103', ['岚山', '嵐山', 'Arashiyama'], '371100'],
	['371121', ['五莲', '五蓮', 'Wulian'], '371100'],
	['371122', ['莒县', '莒縣', 'Ji County'], '371100'],
	['371202', ['莱城', '萊城', 'Laicheng'], '371200'],
	['371203', ['钢城', '鋼城', 'Steel city'], '371200'],
	['371302', ['兰山', '蘭山', 'Lanshan'], '371300'],
	['371311', ['罗庄', '羅莊', 'Luo Zhuang'], '371300'],
	['371312', ['河东', '河東', 'Hedong'], '371300'],
	['371321', ['沂南', '沂南', 'Weinan'], '371300'],
	['371322', ['郯城', '郯城', 'Yucheng'], '371300'],
	['371323', ['沂水', '沂水', 'Drowning'], '371300'],
	['371324', ['兰陵', '蘭陵', 'Lanling'], '371300'],
	['371325', ['费县', '費縣', 'Fei County'], '371300'],
	['371326', ['平邑', '平邑', 'Hiratsuka'], '371300'],
	['371327', ['莒南', '莒南', 'Weinan'], '371300'],
	['371328', ['蒙阴', '蒙陰', 'Mengyin'], '371300'],
	['371329', ['临沭', '臨沭', 'Linyi'], '371300'],
	['371402', ['德城', '德城', 'Decheng'], '371400'],
	['371421', ['陵城', '陵城', 'Lingcheng'], '371400'],
	['371422', ['宁津', '寧津', 'Ningjin'], '371400'],
	['371423', ['庆云', '慶雲', 'Qingyun'], '371400'],
	['371424', ['临邑', '臨邑', 'Linyi'], '371400'],
	['371425', ['齐河', '齊河', 'Qihe'], '371400'],
	['371426', ['平原', '平原', 'Plain'], '371400'],
	['371427', ['夏津', '夏津', 'Xiajin'], '371400'],
	['371428', ['武城', '武城', 'Wucheng'], '371400'],
	['371481', ['乐陵', '樂陵', 'Leling'], '371400'],
	['371482', ['禹城', '禹城', 'Yucheng'], '371400'],
	['371502', ['东昌府', '東昌府', 'Dongchang House'], '371500'],
	['371521', ['阳谷', '陽穀', 'Yanggu'], '371500'],
	['371522', ['莘县', '莘縣', 'Ji County'], '371500'],
	['371523', ['茌平', '茌平', 'Muping'], '371500'],
	['371524', ['东阿', '東阿', 'Donga'], '371500'],
	['371525', ['冠县', '冠縣', 'Guan County'], '371500'],
	['371526', ['高唐', '高唐', 'Gaotang'], '371500'],
	['371581', ['临清', '臨清', 'Linqing'], '371500'],
	['371602', ['滨城', '濱城', 'Ben Thanh'], '371600'],
	['371621', ['惠民', '惠民', 'Huimin'], '371600'],
	['371622', ['阳信', '陽信', 'Yangxin'], '371600'],
	['371623', ['无棣', '無棣', 'Innocent'], '371600'],
	['371624', ['沾化', '沾化', 'Zhanhua'], '371600'],
	['371625', ['博兴', '博興', 'Boxing'], '371600'],
	['371626', ['邹平', '鄒平', 'Zouping'], '371600'],
	['371702', ['牡丹', '牡丹', 'peony'], '371700'],
	['371721', ['曹县', '曹縣', 'Cao County'], '371700'],
	['371722', ['单县', '單縣', 'Single county'], '371700'],
	['371723', ['成武', '成武', 'Cheng Wu'], '371700'],
	['371724', ['巨野', '巨野', 'Juye'], '371700'],
	['371725', ['郓城', '鄆城', 'Yucheng'], '371700'],
	['371726', ['鄄城', '鄄城', 'Yucheng'], '371700'],
	['371727', ['定陶', '定陶', 'Dingtao'], '371700'],
	['371728', ['东明', '東明', 'Dongming'], '371700'],
	['410102', ['中原', '中原', 'Zhongyuan'], '410100'],
	['410103', ['二七', '二七', 'Two seven'], '410100'],
	['410104', ['管城回族', '管城回族', 'Guancheng Hui'], '410100'],
	['410105', ['金水', '金水', 'Gold water'], '410100'],
	['410106', ['上街', '上街', 'Going to the street'], '410100'],
	['410108', ['惠济', '惠濟', 'Huiji'], '410100'],
	['410122', ['中牟', '中牟', 'Lieutenant'], '410100'],
	['410181', ['巩义', '鞏義', 'Gong Yi'], '410100'],
	['410182', ['荥阳', '滎陽', 'Fuyang'], '410100'],
	['410183', ['新密', '新密', 'Xinmi'], '410100'],
	['410184', ['新郑', '新鄭', 'Xinzheng'], '410100'],
	['410185', ['登封', '登封', 'Dengfeng'], '410100'],
	['410202', ['龙亭', '龍亭', 'Longting'], '410200'],
	['410203', ['顺河回族', '順河回族', 'Shunhe Hui'], '410200'],
	['410204', ['鼓楼', '鼓樓', 'Drum Tower'], '410200'],
	['410205', ['禹王台', '禹王臺', 'Wang Wangtai'], '410200'],
	['410211', ['金明', '金明', 'Jin Ming'], '410200'],
	['410221', ['杞县', '杞縣', 'Ji County'], '410200'],
	['410222', ['通许', '通許', 'Pass'], '410200'],
	['410223', ['尉氏', '尉氏', 'Yu'], '410200'],
	['410224', ['祥符', '祥符', 'Xiang Fu'], '410200'],
	['410225', ['兰考', '蘭考', 'Lankao'], '410200'],
	['410302', ['老城', '老城', 'Old town'], '410300'],
	['410303', ['西工', '西工', 'Xigong'], '410300'],
	['410304', ['瀍河回族', '瀍河回族', 'Luohe Hui'], '410300'],
	['410305', ['涧西', '澗西', 'Daisy'], '410300'],
	['410306', ['吉利', '吉利', 'auspicious'], '410300'],
	['410307', ['洛龙', '洛龍', 'Loron'], '410300'],
	['410322', ['孟津', '孟津', 'Mengjin'], '410300'],
	['410323', ['新安', '新安', "Xin'an"], '410300'],
	['410324', ['栾川', '欒川', 'Mukawa'], '410300'],
	['410325', ['嵩县', '嵩縣', 'Ji County'], '410300'],
	['410326', ['汝阳', '汝陽', 'Fuyang'], '410300'],
	['410327', ['宜阳', '宜陽', 'Yiyang'], '410300'],
	['410328', ['洛宁', '洛寧', 'Luoning'], '410300'],
	['410329', ['伊川', '伊川', 'Yichuan'], '410300'],
	['410381', ['偃师', '偃師', 'Teacher'], '410300'],
	['410402', ['新华', '新華', 'Xinhua'], '410400'],
	['410403', ['卫东', '衛東', 'Weidong'], '410400'],
	['410404', ['石龙', '石龍', 'Shilong'], '410400'],
	['410411', ['湛河', '湛河', 'Zhanhe'], '410400'],
	['410421', ['宝丰', '寶豐', 'Baofeng'], '410400'],
	['410422', ['叶县', '葉縣', 'Ye County'], '410400'],
	['410423', ['鲁山', '魯山', 'Lushan'], '410400'],
	['410425', ['郏县', '郟縣', 'Ji County'], '410400'],
	['410481', ['舞钢', '舞鋼', 'Wugang'], '410400'],
	['410482', ['汝州', '汝州', 'Zhangzhou'], '410400'],
	['410502', ['文峰', '文峰', 'Wen Feng'], '410500'],
	['410503', ['北关', '北關', 'North Gate'], '410500'],
	['410505', ['殷都', '殷都', 'Yindu'], '410500'],
	['410506', ['龙安', '龍安', 'Longan'], '410500'],
	['410522', ['安阳', '安陽', 'Anyang'], '410500'],
	['410523', ['汤阴', '湯陰', 'Tang Yin'], '410500'],
	['410526', ['滑县', '滑縣', 'Hua County'], '410500'],
	['410527', ['内黄', '內黃', 'Inner yellow'], '410500'],
	['410581', ['林州', '林州', 'Linzhou'], '410500'],
	['410602', ['鹤山', '鶴山', 'Heshan'], '410600'],
	['410603', ['山城', '山城', 'Mountain city'], '410600'],
	['410611', ['淇滨', '淇濱', 'Qibin'], '410600'],
	['410621', ['浚县', '浚縣', 'Ji County'], '410600'],
	['410622', ['淇县', '淇縣', 'Qi County'], '410600'],
	['410702', ['红旗', '紅旗', 'red flag'], '410700'],
	['410703', ['卫滨', '衛濱', 'Wei Bin'], '410700'],
	['410704', ['凤泉', '鳳泉', 'Fengquan'], '410700'],
	['410711', ['牧野', '牧野', 'Makino'], '410700'],
	['410721', ['新乡', '新鄉', 'Xinxiang'], '410700'],
	['410724', ['获嘉', '獲嘉', 'Gain'], '410700'],
	['410725', ['原阳', '原陽', 'Yuanyang'], '410700'],
	['410726', ['延津', '延津', 'Yanjin'], '410700'],
	['410727', ['封丘', '封丘', 'Fengqiu'], '410700'],
	['410728', ['长垣', '長垣', 'Long'], '410700'],
	['410781', ['卫辉', '衛輝', 'Wei Hui'], '410700'],
	['410782', ['辉县', '輝縣', 'Huixian'], '410700'],
	['410802', ['解放', '解放', 'liberation'], '410800'],
	['410803', ['中站', '中站', 'Middle station'], '410800'],
	['410804', ['马村', '馬村', 'Ma Village'], '410800'],
	['410811', ['山阳', '山陽', 'Sanyo'], '410800'],
	['410821', ['修武', '修武', 'Xiuwu'], '410800'],
	['410822', ['博爱', '博愛', 'Pok Oi'], '410800'],
	['410823', ['武陟', '武陟', 'Monk'], '410800'],
	['410825', ['温县', '溫縣', 'Wen County'], '410800'],
	['410882', ['沁阳', '沁陽', 'Fuyang'], '410800'],
	['410883', ['孟州', '孟州', 'Mengzhou'], '410800'],
	['410902', ['华龙', '華龍', 'Hualong'], '410900'],
	['410922', ['清丰', '清豐', 'Qingfeng'], '410900'],
	['410923', ['南乐', '南樂', 'Nanle'], '410900'],
	['410926', ['范县', '範縣', 'Fan County'], '410900'],
	['410927', ['台前', '臺前', 'In front of the stage'], '410900'],
	['410928', ['濮阳', '濮陽', 'Fuyang'], '410900'],
	['411002', ['魏都', '魏都', 'Weidu'], '411000'],
	['411023', ['许昌', '許昌', 'Xu Chang'], '411000'],
	['411024', ['鄢陵', '鄢陵', 'Fuling'], '411000'],
	['411025', ['襄城', '襄城', 'Yucheng'], '411000'],
	['411081', ['禹州', '禹州', 'Zhangzhou'], '411000'],
	['411082', ['长葛', '長葛', 'Changge'], '411000'],
	['411102', ['源汇', '源匯', 'Source sink'], '411100'],
	['411103', ['郾城', '郾城', 'Yucheng'], '411100'],
	['411104', ['召陵', '召陵', 'Zhaoling'], '411100'],
	['411121', ['舞阳', '舞陽', 'Wuyang'], '411100'],
	['411122', ['临颍', '臨潁', 'Linyi'], '411100'],
	['411202', ['湖滨', '湖濱', 'Lakeside'], '411200'],
	['411221', ['渑池', '澠池', 'Dianchi'], '411200'],
	['411222', ['陕州', '陝州', 'Shaanxi'], '411200'],
	['411224', ['卢氏', '盧氏', 'Lushi'], '411200'],
	['411281', ['义马', '義馬', 'Yima'], '411200'],
	['411282', ['灵宝', '靈寶', 'Lingbao'], '411200'],
	['411302', ['宛城', '宛城', 'Wancheng'], '411300'],
	['411303', ['卧龙', '臥龍', 'Wolong'], '411300'],
	['411321', ['南召', '南召', 'Nanzhao'], '411300'],
	['411322', ['方城', '方城', 'Fangcheng'], '411300'],
	['411323', ['西峡', '西峽', 'Xixia'], '411300'],
	['411324', ['镇平', '鎮平', 'Zhenping'], '411300'],
	['411325', ['内乡', '內鄉', 'Neixiang'], '411300'],
	['411326', ['淅川', '淅川', 'Suichuan'], '411300'],
	['411327', ['社旗县', '社旗縣', 'Sheqi County'], '411300'],
	['411328', ['唐河', '唐河', 'Tanghe'], '411300'],
	['411329', ['新野', '新野', 'Shinano'], '411300'],
	['411330', ['桐柏', '桐柏', 'Tongbai'], '411300'],
	['411381', ['邓州', '鄧州', 'Dengzhou'], '411300'],
	['411402', ['梁园', '梁園', 'Liang Yuan'], '411400'],
	['411403', ['睢阳', '睢陽', 'Fuyang'], '411400'],
	['411421', ['民权', '民權', 'Civil rights'], '411400'],
	['411422', ['睢县', '睢縣', 'Ji County'], '411400'],
	['411423', ['宁陵', '寧陵', 'Ningling'], '411400'],
	['411424', ['柘城', '柘城', 'Zhucheng'], '411400'],
	['411425', ['虞城', '虞城', 'Yucheng'], '411400'],
	['411426', ['夏邑', '夏邑', 'Xia Wei'], '411400'],
	['411481', ['永城', '永城', 'Yongcheng'], '411400'],
	['411502', ['浉河', '溮河', 'Luohe'], '411500'],
	['411503', ['平桥', '平橋', 'Pingqiao'], '411500'],
	['411521', ['罗山', '羅山', 'Luoshan'], '411500'],
	['411522', ['光山', '光山', 'Guangshan'], '411500'],
	['411523', ['新县', '新縣', 'New county'], '411500'],
	['411524', ['商城', '商城', 'Mall'], '411500'],
	['411525', ['固始', '固始', 'Gushi'], '411500'],
	['411526', ['潢川', '潢川', 'Huangchuan'], '411500'],
	['411527', ['淮滨', '淮濱', 'Huaibin'], '411500'],
	['411528', ['息县', '息縣', 'Xi County'], '411500'],
	['411602', ['川汇', '川匯', 'Chuanhui'], '411600'],
	['411621', ['扶沟', '扶溝', 'Fugou'], '411600'],
	['411622', ['西华', '西華', 'Saka'], '411600'],
	['411623', ['商水', '商水', 'Commercial water'], '411600'],
	['411624', ['沈丘', '沈丘', 'Deer hill'], '411600'],
	['411625', ['郸城', '鄲城', 'Yucheng'], '411600'],
	['411626', ['淮阳', '淮陽', 'Huaiyang'], '411600'],
	['411627', ['太康', '太康', 'Taikang'], '411600'],
	['411628', ['鹿邑', '鹿邑', 'Luhan'], '411600'],
	['411681', ['项城', '項城', 'Xiangcheng'], '411600'],
	['411702', ['驿城', '驛城', 'Yucheng'], '411700'],
	['411721', ['西平', '西平', 'Nishihira'], '411700'],
	['411722', ['上蔡', '上蔡', 'Shangcai'], '411700'],
	['411723', ['平舆', '平輿', 'Hiratsuka'], '411700'],
	['411724', ['正阳', '正陽', 'Zhengyang'], '411700'],
	['411725', ['确山', '確山', 'True mountain'], '411700'],
	['411726', ['泌阳', '泌陽', 'Biyang'], '411700'],
	['411727', ['汝南', '汝南', 'Weinan'], '411700'],
	['411728', ['遂平', '遂平', 'Muping'], '411700'],
	['411729', ['新蔡', '新蔡', 'Xincai'], '411700'],
	['420102', ['江岸', '江岸', 'riverbank'], '420100'],
	['420103', ['江汉', '江漢', 'Jiang Han'], '420100'],
	['420104', ['硚口', '礄口', 'Pass'], '420100'],
	['420105', ['汉阳', '漢陽', 'Hanyang'], '420100'],
	['420106', ['武昌', '武昌', 'Wuchang'], '420100'],
	['420107', ['青山', '青山', 'Aoyama'], '420100'],
	['420111', ['洪山', '洪山', 'Hongshan'], '420100'],
	['420112', ['东西湖', '東西湖', 'East Lake'], '420100'],
	['420113', ['汉南', '漢南', 'Hannan'], '420100'],
	['420114', ['蔡甸', '蔡甸', 'Cai Dian'], '420100'],
	['420115', ['江夏', '江夏', 'Jiangxia'], '420100'],
	['420116', ['黄陂', '黃陂', 'Huang Wei'], '420100'],
	['420117', ['新洲', '新洲', 'Xinzhou'], '420100'],
	['420202', ['黄石港', '黃石港', 'Yellowstone Harbour'], '420200'],
	['420203', ['西塞山', '西塞山', 'Cisse'], '420200'],
	['420204', ['下陆', '下陸', 'Landing'], '420200'],
	['420205', ['铁山', '鐵山', 'Tieshan'], '420200'],
	['420222', ['阳新', '陽新', 'Yang Xin'], '420200'],
	['420281', ['大冶', '大冶', 'Daye'], '420200'],
	['420302', ['茅箭', '茅箭', 'Mao Jian'], '420300'],
	['420303', ['张湾', '張灣', 'Zhangwan'], '420300'],
	['420321', ['郧阳', '鄖陽', 'Fuyang'], '420300'],
	['420322', ['郧西', '鄖西', 'Daisy'], '420300'],
	['420323', ['竹山', '竹山', 'Zhushan'], '420300'],
	['420324', ['竹溪', '竹溪', 'Bamboo creek'], '420300'],
	['420325', ['房县', '房縣', 'Fang County'], '420300'],
	['420381', ['丹江口', '丹江口', 'Danjiangkou'], '420300'],
	['420502', ['西陵', '西陵', 'Xiling'], '420500'],
	['420503', ['伍家岗', '伍家崗', 'Wu Jiagang'], '420500'],
	['420504', ['点军', '點軍', 'Point army'], '420500'],
	['420505', ['猇亭', '猇亭', 'Yuting'], '420500'],
	['420506', ['夷陵', '夷陵', 'Yiling'], '420500'],
	['420525', ['远安', '遠安', 'Yuanan'], '420500'],
	['420526', ['兴山', '興山', 'Xingshan'], '420500'],
	['420527', ['秭归', '秭歸', 'Zigui'], '420500'],
	['420528', ['长阳', '長陽', 'Changyang'], '420500'],
	['420529', ['五峰', '五峰', 'Wufeng'], '420500'],
	['420581', ['宜都', '宜都', 'Yidu'], '420500'],
	['420582', ['当阳', '當陽', 'Dangyang'], '420500'],
	['420583', ['枝江', '枝江', 'Zhijiang'], '420500'],
	['420602', ['襄城', '襄城', 'Yucheng'], '420600'],
	['420606', ['樊城', '樊城', 'Fan Cheng'], '420600'],
	['420607', ['襄州', '襄州', 'Zhangzhou'], '420600'],
	['420624', ['南漳', '南漳', 'Nanxun'], '420600'],
	['420625', ['谷城', '穀城', 'Valley city'], '420600'],
	['420626', ['保康', '保康', 'Baokang'], '420600'],
	['420682', ['老河口', '老河口', 'Laohekou'], '420600'],
	['420683', ['枣阳', '棗陽', 'Zaoyang'], '420600'],
	['420684', ['宜城', '宜城', 'Yicheng'], '420600'],
	['420702', ['梁子湖', '梁子湖', 'Liangzi Lake'], '420700'],
	['420703', ['华容', '華容', 'Huarong'], '420700'],
	['420704', ['鄂城', '鄂城', 'Castle'], '420700'],
	['420802', ['东宝', '東寶', 'Dongbao'], '420800'],
	['420804', ['掇刀', '掇刀', 'Sickle'], '420800'],
	['420821', ['京山', '京山', 'Jingshan'], '420800'],
	['420822', ['沙洋', '沙洋', 'Sha Yang'], '420800'],
	['420881', ['钟祥', '鐘祥', 'Zhong Xiang'], '420800'],
	['420902', ['孝南', '孝南', 'Konan'], '420900'],
	['420921', ['孝昌', '孝昌', 'Xiaochang'], '420900'],
	['420922', ['大悟', '大悟', 'Dawu'], '420900'],
	['420923', ['云梦', '雲夢', 'Yunmeng'], '420900'],
	['420981', ['应城', '應城', 'Yingcheng'], '420900'],
	['420982', ['安陆', '安陸', 'Anlu'], '420900'],
	['420984', ['汉川', '漢川', 'Hanchuan'], '420900'],
	['421002', ['沙市', '沙市', 'Sand city'], '421000'],
	['421003', ['荆州', '荊州', 'Jingzhou'], '421000'],
	['421022', ['公安', '公安', 'public security'], '421000'],
	['421023', ['监利', '監利', 'Jianli'], '421000'],
	['421024', ['江陵', '江陵', 'Gangneung'], '421000'],
	['421081', ['石首', '石首', 'Stone head'], '421000'],
	['421083', ['洪湖', '洪湖', 'Honghu'], '421000'],
	['421087', ['松滋', '松滋', 'Songzi'], '421000'],
	['421102', ['黄州', '黃州', 'Huangzhou'], '421100'],
	['421121', ['团风', '團風', 'Group wind'], '421100'],
	['421122', ['红安', '紅安', 'Hong An'], '421100'],
	['421123', ['罗田', '羅田', 'Luo Tian'], '421100'],
	['421124', ['英山', '英山', 'Hideyama'], '421100'],
	['421125', ['浠水', '浠水', 'Drowning'], '421100'],
	['421126', ['蕲春', '蘄春', 'Hunchun'], '421100'],
	['421127', ['黄梅', '黃梅', 'Huang Mei'], '421100'],
	['421181', ['麻城', '麻城', 'Macheng'], '421100'],
	['421182', ['武穴', '武穴', 'Wuxue'], '421100'],
	['421202', ['咸安', '鹹安', 'Xianan'], '421200'],
	['421221', ['嘉鱼', '嘉魚', 'Jiayu'], '421200'],
	['421222', ['通城', '通城', 'Castle'], '421200'],
	['421223', ['崇阳', '崇陽', 'Chongyang'], '421200'],
	['421224', ['通山', '通山', 'Mountain stream'], '421200'],
	['421281', ['赤壁', '赤壁', 'Chibi'], '421200'],
	['421302', ['曾都', '曾都', 'Zengdu'], '421300'],
	['421321', ['随县', '隨縣', 'Sui county'], '421300'],
	['421381', ['广水', '廣水', 'Guangshui'], '421300'],
	['422801', ['恩施', '恩施', 'Enshi'], '422800'],
	['422802', ['利川', '利川', 'Icheon'], '422800'],
	['422822', ['建始', '建始', 'Founding'], '422800'],
	['422823', ['巴东', '巴東', 'Padang'], '422800'],
	['422825', ['宣恩', '宣恩', 'Xuan En'], '422800'],
	['422826', ['咸丰', '鹹豐', 'Xianfeng'], '422800'],
	['422827', ['来凤', '來鳳', 'Laifeng'], '422800'],
	['422828', ['鹤峰', '鶴峰', 'Hefeng'], '422800'],
	['430102', ['芙蓉', '芙蓉', 'Hibiscus'], '430100'],
	['430103', ['天心', '天心', 'Heaven'], '430100'],
	['430104', ['岳麓', '嶽麓', 'Yue Yue'], '430100'],
	['430105', ['开福', '開福', 'Kaifu'], '430100'],
	['430111', ['雨花', '雨花', 'Rain flower'], '430100'],
	['430121', ['长沙', '長沙', 'Changsha'], '430100'],
	['430122', ['望城', '望城', 'Wangcheng'], '430100'],
	['430124', ['宁乡', '寧鄉', 'Ningxiang'], '430100'],
	['430181', ['浏阳', '瀏陽', 'Liuyang'], '430100'],
	['430202', ['荷塘', '荷塘', 'Lotus pond'], '430200'],
	['430203', ['芦淞', '蘆淞', 'Reed'], '430200'],
	['430204', ['石峰', '石峰', 'Shifeng'], '430200'],
	['430211', ['天元', '天元', 'Tianyuan'], '430200'],
	['430221', ['株洲', '株洲', 'Zhuzhou'], '430200'],
	['430223', ['攸县', '攸縣', 'Ji County'], '430200'],
	['430224', ['茶陵', '茶陵', 'Chaling'], '430200'],
	['430225', ['炎陵', '炎陵', 'Yanling'], '430200'],
	['430281', ['醴陵', '醴陵', 'Fuling'], '430200'],
	['430302', ['雨湖', '雨湖', 'Rain lake'], '430300'],
	['430304', ['岳塘', '嶽塘', 'Yuetang'], '430300'],
	['430321', ['湘潭', '湘潭', 'Xiangtan'], '430300'],
	['430381', ['湘乡', '湘鄉', 'Xiangxiang'], '430300'],
	['430382', ['韶山', '韶山', 'Lushan'], '430300'],
	['430405', ['珠晖', '珠暉', 'Zhu Hui'], '430400'],
	['430406', ['雁峰', '雁峰', 'Yanfeng'], '430400'],
	['430407', ['石鼓', '石鼓', 'Stone drum'], '430400'],
	['430408', ['蒸湘', '蒸湘', 'Steaming'], '430400'],
	['430412', ['南岳', '南嶽', 'Nanyue'], '430400'],
	['430421', ['衡阳', '衡陽', 'Hengyang'], '430400'],
	['430422', ['衡南', '衡南', 'Hengnan'], '430400'],
	['430423', ['衡山', '衡山', 'Hengshan'], '430400'],
	['430424', ['衡东', '衡東', 'Hengdong'], '430400'],
	['430426', ['祁东', '祁東', 'Jidong'], '430400'],
	['430481', ['耒阳', '耒陽', 'Fuyang'], '430400'],
	['430482', ['常宁', '常寧', 'Changning'], '430400'],
	['430502', ['双清', '雙清', 'Double clear'], '430500'],
	['430503', ['大祥', '大祥', 'Daxiang'], '430500'],
	['430511', ['北塔', '北塔', 'North tower'], '430500'],
	['430521', ['邵东', '邵東', 'Shao Dong'], '430500'],
	['430522', ['新邵', '新邵', 'Xin Shao'], '430500'],
	['430523', ['邵阳', '邵陽', 'Shaoyang'], '430500'],
	['430524', ['隆回', '隆回', 'Long back'], '430500'],
	['430525', ['洞口', '洞口', 'Hole'], '430500'],
	['430527', ['绥宁', '綏寧', 'Suining'], '430500'],
	['430528', ['新宁', '新寧', 'Xinning'], '430500'],
	['430529', ['城步', '城步', 'City step'], '430500'],
	['430581', ['武冈', '武岡', 'Wugang'], '430500'],
	['430602', ['岳阳楼', '嶽陽樓', 'Yueyang Tower'], '430600'],
	['430603', ['云溪', '雲溪', 'Yunxi'], '430600'],
	['430611', ['君山', '君山', 'Junshan'], '430600'],
	['430621', ['岳阳', '嶽陽', 'Yue Yang'], '430600'],
	['430623', ['华容', '華容', 'Huarong'], '430600'],
	['430624', ['湘阴', '湘陰', 'Xiangyin'], '430600'],
	['430626', ['平江', '平江', 'Pingjiang'], '430600'],
	['430681', ['汨罗', '汨羅', 'Miluo'], '430600'],
	['430682', ['临湘', '臨湘', 'Linxiang'], '430600'],
	['430702', ['武陵', '武陵', 'Wuling'], '430700'],
	['430703', ['鼎城', '鼎城', 'Dingcheng'], '430700'],
	['430721', ['安乡', '安鄉', 'Anxiang'], '430700'],
	['430722', ['汉寿', '漢壽', 'Hanshou'], '430700'],
	['430723', ['澧县', '澧縣', 'Ji County'], '430700'],
	['430724', ['临澧', '臨澧', 'Linyi'], '430700'],
	['430725', ['桃源', '桃源', 'Taoyuan'], '430700'],
	['430726', ['石门', '石門', 'Shimen'], '430700'],
	['430781', ['津市', '津市', 'Tsu'], '430700'],
	['430802', ['永定', '永定', 'Yongding'], '430800'],
	['430811', ['武陵源', '武陵源', 'Wulingyuan'], '430800'],
	['430821', ['慈利', '慈利', 'Cili'], '430800'],
	['430822', ['桑植', '桑植', 'Sang Zhi'], '430800'],
	['430902', ['资阳', '資陽', 'Ziyang'], '430900'],
	['430903', ['赫山', '赫山', 'Heshan'], '430900'],
	['430921', ['南县', '南縣', 'Nan County'], '430900'],
	['430922', ['桃江', '桃江', 'Taojiang'], '430900'],
	['430923', ['安化', '安化', 'Anhua'], '430900'],
	['430981', ['沅江', '沅江', 'Lijiang'], '430900'],
	['431002', ['北湖', '北湖', 'North Lake'], '431000'],
	['431003', ['苏仙', '蘇仙', 'Su Xian'], '431000'],
	['431021', ['桂阳', '桂陽', 'Guiyang'], '431000'],
	['431022', ['宜章', '宜章', 'Yizhang'], '431000'],
	['431023', ['永兴', '永興', 'Yongxing'], '431000'],
	['431024', ['嘉禾', '嘉禾', 'Golden Harvest'], '431000'],
	['431025', ['临武', '臨武', 'Linwu'], '431000'],
	['431026', ['汝城', '汝城', 'Yucheng'], '431000'],
	['431027', ['桂东', '桂東', 'Guidong'], '431000'],
	['431028', ['安仁', '安仁', 'Anren'], '431000'],
	['431081', ['资兴', '資興', 'Zixing'], '431000'],
	['431102', ['零陵', '零陵', 'Lingling'], '431100'],
	['431103', ['冷水滩', '冷水灘', 'Cold water beach'], '431100'],
	['431121', ['祁阳', '祁陽', 'Fuyang'], '431100'],
	['431122', ['东安', '東安', 'Dongan'], '431100'],
	['431123', ['双牌', '雙牌', 'Double card'], '431100'],
	['431124', ['道县', '道縣', 'Dao County'], '431100'],
	['431125', ['江永', '江永', 'Jiang Yong'], '431100'],
	['431126', ['宁远', '寧遠', 'Ningyuan'], '431100'],
	['431127', ['蓝山', '藍山', 'Blue Mountain'], '431100'],
	['431128', ['新田', '新田', 'Nitta'], '431100'],
	['431129', ['江华', '江華', 'Jiang Hua'], '431100'],
	['431202', ['鹤城', '鶴城', 'Hecheng'], '431200'],
	['431221', ['中方', '中方', 'Chinese side'], '431200'],
	['431222', ['沅陵', '沅陵', 'Fuling'], '431200'],
	['431223', ['辰溪', '辰溪', 'Chenxi'], '431200'],
	['431224', ['溆浦', '漵浦', 'Zhapu'], '431200'],
	['431225', ['会同', '會同', 'Meeting'], '431200'],
	['431226', ['麻阳', '麻陽', 'Mayang'], '431200'],
	['431227', ['新晃', '新晃', 'New'], '431200'],
	['431228', ['芷江', '芷江', 'Lijiang'], '431200'],
	['431229', ['靖州', '靖州', 'Jingzhou'], '431200'],
	['431230', ['通道', '通道', 'aisle'], '431200'],
	['431281', ['洪江', '洪江', 'Hong Jiang'], '431200'],
	['431302', ['娄星', '婁星', 'Comet'], '431300'],
	['431321', ['双峰', '雙峰', 'Double peak'], '431300'],
	['431322', ['新化', '新化', 'New'], '431300'],
	['431381', ['冷水江', '冷水江', 'Lengshuijiang'], '431300'],
	['431382', ['涟源', '漣源', 'Wuyuan'], '431300'],
	['433101', ['吉首', '吉首', 'Jishou'], '433100'],
	['433122', ['泸溪', '瀘溪', 'Tunxi'], '433100'],
	['433123', ['凤凰', '鳳凰', 'Phoenix'], '433100'],
	['433124', ['花垣', '花垣', 'Flower garden'], '433100'],
	['433125', ['保靖', '保靖', 'Baojing'], '433100'],
	['433126', ['古丈', '古丈', 'Guzhang'], '433100'],
	['433127', ['永顺', '永順', 'Yongshun'], '433100'],
	['433130', ['龙山', '龍山', 'Longshan'], '433100'],
	['440103', ['荔湾', '荔灣', 'Tsuen Wan'], '440100'],
	['440104', ['越秀', '越秀', 'Yuexiu'], '440100'],
	['440105', ['海珠', '海珠', 'Haizhu'], '440100'],
	['440106', ['天河', '天河', 'Tianhe'], '440100'],
	['440111', ['白云', '白雲', 'White clouds'], '440100'],
	['440112', ['黄埔', '黃埔', 'Huangpu'], '440100'],
	['440113', ['番禺', '番禺', 'Panyu'], '440100'],
	['440114', ['花都', '花都', 'Huadu'], '440100'],
	['440115', ['南沙', '南沙', 'Nansha'], '440100'],
	['440116', ['萝岗', '蘿崗', 'Luogang'], '440100'],
	['440183', ['增城', '增城', 'Zengcheng'], '440100'],
	['440184', ['从化', '從化', 'Conghua'], '440100'],
	['440203', ['武江', '武江', 'Wujiang'], '440200'],
	['440204', ['浈江', '湞江', 'Lijiang'], '440200'],
	['440205', ['曲江', '曲江', 'Qujiang'], '440200'],
	['440222', ['始兴', '始興', 'Shixing'], '440200'],
	['440224', ['仁化', '仁化', 'Renhua'], '440200'],
	['440229', ['翁源', '翁源', 'Wengyuan'], '440200'],
	['440232', ['乳源', '乳源', 'Milk source'], '440200'],
	['440233', ['新丰', '新豐', 'Xinfeng'], '440200'],
	['440281', ['乐昌', '樂昌', 'Lechang'], '440200'],
	['440282', ['南雄', '南雄', 'Nanxiong'], '440200'],
	['440303', ['罗湖', '羅湖', 'Luohu'], '440300'],
	['440304', ['福田', '福田', 'Futian'], '440300'],
	['440305', ['南山', '南山', 'Nanshan'], '440300'],
	['440306', ['宝安', '寶安', 'Baoan'], '440300'],
	['440307', ['龙岗', '龍崗', 'Longgang'], '440300'],
	['440308', ['盐田', '鹽田', 'Yantian'], '440300'],
	['440402', ['香洲', '香洲', 'Xiangzhou'], '440400'],
	['440403', ['斗门', '鬥門', 'Doumen'], '440400'],
	['440404', ['金湾', '金灣', 'Jinwan'], '440400'],
	['440507', ['龙湖', '龍湖', 'Longhu'], '440500'],
	['440511', ['金平', '金平', 'Kanpe'], '440500'],
	['440512', ['濠江', '濠江', 'Lijiang'], '440500'],
	['440513', ['潮阳', '潮陽', 'Chaoyang'], '440500'],
	['440514', ['潮南', '潮南', 'Chaonan'], '440500'],
	['440515', ['澄海', '澄海', 'Chenghai'], '440500'],
	['440523', ['南澳', '南澳', 'South Australia'], '440500'],
	['440604', ['禅城', '禪城', 'Chancheng'], '440600'],
	['440605', ['南海', '南海', 'South China Sea'], '440600'],
	['440606', ['顺德', '順德', 'Shunde'], '440600'],
	['440607', ['三水', '三水', 'Sanshui'], '440600'],
	['440608', ['高明', '高明', 'clever'], '440600'],
	['440703', ['蓬江', '蓬江', 'Pengjiang'], '440700'],
	['440704', ['江海', '江海', 'Jiang Hai'], '440700'],
	['440705', ['新会', '新會', 'Xinhui'], '440700'],
	['440781', ['台山', '臺山', 'Taishan'], '440700'],
	['440783', ['开平', '開平', 'Kaiping'], '440700'],
	['440784', ['鹤山', '鶴山', 'Heshan'], '440700'],
	['440785', ['恩平', '恩平', 'Enping'], '440700'],
	['440802', ['赤坎', '赤坎', 'Chikan'], '440800'],
	['440803', ['霞山', '霞山', 'Xiashan'], '440800'],
	['440804', ['坡头', '坡頭', 'Slope'], '440800'],
	['440811', ['麻章', '麻章', 'Ma Zhang'], '440800'],
	['440823', ['遂溪', '遂溪', 'Tunxi'], '440800'],
	['440825', ['徐闻', '徐聞', 'Xu Wen'], '440800'],
	['440881', ['廉江', '廉江', 'Lianjiang'], '440800'],
	['440882', ['雷州', '雷州', 'Leizhou'], '440800'],
	['440883', ['吴川', '吳川', 'Wu Chuan'], '440800'],
	['440902', ['茂南', '茂南', 'Maonan'], '440900'],
	['440903', ['电白', '電白', 'Electric white'], '440900'],
	['440981', ['高州', '高州', 'Gaozhou'], '440900'],
	['440982', ['化州', '化州', 'Huazhou'], '440900'],
	['440983', ['信宜', '信宜', 'Xinyi'], '440900'],
	['441202', ['端州', '端州', 'Duanzhou'], '441200'],
	['441203', ['鼎湖', '鼎湖', 'Dinghu'], '441200'],
	['441223', ['广宁', '廣寧', 'Guangning'], '441200'],
	['441224', ['怀集', '懷集', 'Huaiji'], '441200'],
	['441225', ['封开', '封開', 'separate'], '441200'],
	['441226', ['德庆', '德慶', 'Deqing'], '441200'],
	['441283', ['高要', '高要', 'High'], '441200'],
	['441284', ['四会', '四會', 'Sihui'], '441200'],
	['441302', ['惠城', '惠城', 'Huicheng'], '441300'],
	['441303', ['惠阳', '惠陽', 'Huiyang'], '441300'],
	['441322', ['博罗', '博羅', 'Boro'], '441300'],
	['441323', ['惠东', '惠東', 'Huidong'], '441300'],
	['441324', ['龙门', '龍門', 'Longmen'], '441300'],
	['441402', ['梅江', '梅江', 'Meijiang'], '441400'],
	['441421', ['梅县', '梅縣', 'Meixian'], '441400'],
	['441422', ['大埔', '大埔', 'Tai Po'], '441400'],
	['441423', ['丰顺', '豐順', 'Fengshun'], '441400'],
	['441424', ['五华', '五華', 'Goka'], '441400'],
	['441426', ['平远', '平遠', 'Hirafu'], '441400'],
	['441427', ['蕉岭', '蕉嶺', 'Jiaoling'], '441400'],
	['441481', ['兴宁', '興寧', 'Xingning'], '441400'],
	['441502', ['城区', '城區', 'Urban area'], '441500'],
	['441521', ['海丰', '海豐', 'Haifeng'], '441500'],
	['441523', ['陆河', '陸河', 'Luhe'], '441500'],
	['441581', ['陆丰', '陸豐', 'Lu Feng'], '441500'],
	['441602', ['源城', '源城', 'Castle'], '441600'],
	['441621', ['紫金', '紫金', 'Zijin'], '441600'],
	['441622', ['龙川', '龍川', 'Longchuan'], '441600'],
	['441623', ['连平', '連平', 'Lianping'], '441600'],
	['441624', ['和平', '和平', 'peace'], '441600'],
	['441625', ['东源', '東源', 'Dongyuan'], '441600'],
	['441702', ['江城', '江城', 'Jiangcheng'], '441700'],
	['441721', ['阳西', '陽西', 'Yangxi'], '441700'],
	['441723', ['阳东', '陽東', 'The east'], '441700'],
	['441781', ['阳春', '陽春', 'spring'], '441700'],
	['441802', ['清城', '清城', 'Qingcheng'], '441800'],
	['441821', ['佛冈', '佛岡', 'Fogang'], '441800'],
	['441823', ['阳山', '陽山', 'Yangshan'], '441800'],
	['441825', ['连山', '連山', 'Lianshan'], '441800'],
	['441826', ['连南', '連南', 'Liannan'], '441800'],
	['441827', ['清新', '清新', 'Fresh'], '441800'],
	['441881', ['英德', '英德', 'Yingde'], '441800'],
	['441882', ['连州', '連州', 'Lianzhou'], '441800'],
	['445102', ['湘桥', '湘橋', 'Xiangqiao'], '445100'],
	['445121', ['潮安', '潮安', 'Chaoan'], '445100'],
	['445122', ['饶平', '饒平', 'Raoping'], '445100'],
	['445202', ['榕城', '榕城', 'Yucheng'], '445200'],
	['445221', ['揭东', '揭東', 'Jiedong'], '445200'],
	['445222', ['揭西', '揭西', 'Jiexi'], '445200'],
	['445224', ['惠来', '惠來', 'Huilai'], '445200'],
	['445281', ['普宁', '普寧', 'Puning'], '445200'],
	['445302', ['云城', '雲城', 'Yuncheng'], '445300'],
	['445321', ['新兴', '新興', 'new'], '445300'],
	['445322', ['郁南', '鬱南', 'Yunan'], '445300'],
	['445323', ['云安', '雲安', 'Yunan'], '445300'],
	['445381', ['罗定', '羅定', 'Luoding'], '445300'],
	['450102', ['兴宁', '興寧', 'Xingning'], '450100'],
	['450103', ['青秀', '青秀', 'Qingxiu'], '450100'],
	['450105', ['江南', '江南', 'Jiangnan'], '450100'],
	['450107', ['西乡塘', '西鄉塘', 'Xixiangtang'], '450100'],
	['450108', ['良庆', '良慶', 'Liang Qing'], '450100'],
	['450109', ['邕宁', '邕寧', 'Suining'], '450100'],
	['450122', ['武鸣', '武鳴', 'Wu Ming'], '450100'],
	['450123', ['隆安', '隆安', 'Longan'], '450100'],
	['450124', ['马山', '馬山', 'Masan'], '450100'],
	['450125', ['上林', '上林', 'Shimibe'], '450100'],
	['450126', ['宾阳', '賓陽', 'Binyang'], '450100'],
	['450127', ['横县', '橫縣', 'Heng County'], '450100'],
	['450202', ['城中', '城中', 'In the city'], '450200'],
	['450203', ['鱼峰', '魚峰', 'Fish peak'], '450200'],
	['450204', ['柳南', '柳南', 'Liu Nan'], '450200'],
	['450205', ['柳北', '柳北', 'Liu Bei'], '450200'],
	['450221', ['柳江', '柳江', 'Liu Jiang'], '450200'],
	['450222', ['柳城', '柳城', 'Liucheng'], '450200'],
	['450223', ['鹿寨', '鹿寨', 'Luzhai'], '450200'],
	['450224', ['融安', '融安', 'Rongan'], '450200'],
	['450225', ['融水', '融水', 'Melt water'], '450200'],
	['450226', ['三江', '三江', 'Sanjiang'], '450200'],
	['450302', ['秀峰', '秀峰', 'Xiufeng'], '450300'],
	['450303', ['叠彩', '疊彩', 'Stacking'], '450300'],
	['450304', ['象山', '象山', 'Xiangshan'], '450300'],
	['450305', ['七星', '七星', 'Seven stars'], '450300'],
	['450311', ['雁山', '雁山', 'Yanshan'], '450300'],
	['450321', ['阳朔', '陽朔', 'Yangshuo'], '450300'],
	['450322', ['临桂', '臨桂', 'Lingui'], '450300'],
	['450323', ['灵川', '靈川', 'Lingchuan'], '450300'],
	['450324', ['全州', '全州', 'all states'], '450300'],
	['450325', ['兴安', '興安', "Xing'an"], '450300'],
	['450326', ['永福', '永福', 'Yongfu'], '450300'],
	['450327', ['灌阳', '灌陽', 'Guanyang'], '450300'],
	['450328', ['龙胜', '龍勝', 'Longsheng'], '450300'],
	['450329', ['资源', '資源', 'Resource'], '450300'],
	['450330', ['平乐', '平樂', 'Pingle'], '450300'],
	['450331', ['荔浦', '荔浦', 'Zhapu'], '450300'],
	['450332', ['恭城', '恭城', 'Gongcheng'], '450300'],
	['450403', ['万秀', '萬秀', 'Wan Xiu'], '450400'],
	['450405', ['长洲', '長洲', 'Nagasu'], '450400'],
	['450406', ['龙圩', '龍圩', 'Long Yu'], '450400'],
	['450421', ['苍梧', '蒼梧', 'Sky'], '450400'],
	['450422', ['藤县', '藤縣', 'Tengxian'], '450400'],
	['450423', ['蒙山', '蒙山', 'Mengshan'], '450400'],
	['450481', ['岑溪', '岑溪', 'Tunxi'], '450400'],
	['450502', ['海城', '海城', 'Huashi'], '450500'],
	['450503', ['银海', '銀海', 'Silver sea'], '450500'],
	['450512', ['铁山港', '鐵山港', 'Tieshan Port'], '450500'],
	['450521', ['合浦', '合浦', 'Hepu'], '450500'],
	['450602', ['港口', '港口', 'port'], '450600'],
	['450603', ['防城', '防城', 'Fangcheng'], '450600'],
	['450621', ['上思', '上思', 'Thinking'], '450600'],
	['450681', ['东兴', '東興', 'Dongxing'], '450600'],
	['450702', ['钦南', '欽南', 'Qinnan'], '450700'],
	['450703', ['钦北', '欽北', 'Qinbei'], '450700'],
	['450721', ['灵山', '靈山', 'Lingshan'], '450700'],
	['450722', ['浦北', '浦北', 'Pubei'], '450700'],
	['450802', ['港北', '港北', 'Kohoku'], '450800'],
	['450803', ['港南', '港南', 'Konan'], '450800'],
	['450804', ['覃塘', '覃塘', 'Qiangtang'], '450800'],
	['450821', ['平南', '平南', 'Hitanan'], '450800'],
	['450881', ['桂平', '桂平', 'Guiping'], '450800'],
	['450902', ['玉州', '玉州', 'Yuzhou'], '450900'],
	['450903', ['福绵', '福綿', 'Fu Mian'], '450900'],
	['450921', ['容县', '容縣', 'Rong County'], '450900'],
	['450922', ['陆川', '陸川', 'Lu Chuan'], '450900'],
	['450923', ['博白', '博白', 'Bobai'], '450900'],
	['450924', ['兴业', '興業', 'Industrial'], '450900'],
	['450981', ['北流', '北流', 'Northern stream'], '450900'],
	['451002', ['右江', '右江', 'Youjiang'], '451000'],
	['451021', ['田阳', '田陽', 'Tian Yang'], '451000'],
	['451022', ['田东', '田東', 'Tian Dong'], '451000'],
	['451023', ['平果', '平果', 'Flat fruit'], '451000'],
	['451024', ['德保', '德保', 'Debao'], '451000'],
	['451025', ['靖西', '靖西', 'Jingxi'], '451000'],
	['451026', ['那坡', '那坡', 'Napo'], '451000'],
	['451027', ['凌云', '淩雲', 'Lingyun'], '451000'],
	['451028', ['乐业', '樂業', 'Music industry'], '451000'],
	['451029', ['田林', '田林', 'Tian Lin'], '451000'],
	['451030', ['西林', '西林', 'Xilin'], '451000'],
	['451031', ['隆林', '隆林', 'Longlin'], '451000'],
	['451102', ['八步', '八步', 'Eight steps'], '451100'],
	['451119', ['平桂', '平桂', 'Flat sky'], '451100'],
	['451121', ['昭平', '昭平', 'Zhaoping'], '451100'],
	['451122', ['钟山', '鐘山', 'Zhongshan'], '451100'],
	['451123', ['富川', '富川', 'Bucheon'], '451100'],
	['451202', ['金城江', '金城江', 'Jinchengjiang'], '451200'],
	['451221', ['南丹', '南丹', 'Nandan'], '451200'],
	['451222', ['天峨', '天峨', 'Scorpio'], '451200'],
	['451223', ['凤山', '鳳山', 'Fengshan'], '451200'],
	['451224', ['东兰', '東蘭', 'Donglan'], '451200'],
	['451225', ['罗城', '羅城', 'Luocheng'], '451200'],
	['451226', ['环江', '環江', 'Huanjiang'], '451200'],
	['451227', ['巴马', '巴馬', 'Bama'], '451200'],
	['451228', ['都安', '都安', 'Duan'], '451200'],
	['451229', ['大化', '大化', 'Dahua'], '451200'],
	['451281', ['宜州', '宜州', 'Yizhou'], '451200'],
	['451302', ['兴宾', '興賓', 'Xingbin'], '451300'],
	['451321', ['忻城', '忻城', 'Yucheng'], '451300'],
	['451322', ['象州', '象州', 'Elephant state'], '451300'],
	['451323', ['武宣', '武宣', 'Wu Xuan'], '451300'],
	['451324', ['金秀', '金秀', 'Jin Xiu'], '451300'],
	['451381', ['合山', '合山', 'Heshan'], '451300'],
	['451402', ['江州', '江州', 'Jiangzhou'], '451400'],
	['451421', ['扶绥', '扶綏', 'Fusui'], '451400'],
	['451422', ['宁明', '寧明', 'Ning Ming'], '451400'],
	['451423', ['龙州', '龍州', 'Longzhou'], '451400'],
	['451424', ['大新', '大新', 'Daxin'], '451400'],
	['451425', ['天等', '天等', 'Heaven'], '451400'],
	['451481', ['凭祥', '憑祥', 'Pingxiang'], '451400'],
	['460105', ['秀英', '秀英', 'Xiuying'], '460100'],
	['460106', ['龙华', '龍華', 'Longhua'], '460100'],
	['460107', ['琼山', '瓊山', 'Qiongshan'], '460100'],
	['460108', ['美兰', '美蘭', 'Meilan'], '460100'],
	['460321', ['西沙', '西沙', 'Xisha'], '460300'],
	['460322', ['南沙', '南沙', 'Nansha'], '460300'],
	['460323', ['中沙', '中沙', 'Zhongsha'], '460300'],
	['500101', ['万州', '萬州', 'Wanzhou'], '500100'],
	['500102', ['涪陵', '涪陵', 'Fuling'], '500100'],
	['500103', ['渝中', '渝中', 'Yuzhong'], '500100'],
	['500104', ['大渡口', '大渡口', 'Dadukou'], '500100'],
	['500105', ['江北', '江北', 'Jiangbei'], '500100'],
	['500106', ['沙坪坝', '沙坪壩', 'Shapingba'], '500100'],
	['500107', ['九龙坡', '九龍坡', 'Jiulongpo'], '500100'],
	['500108', ['南岸', '南岸', 'South bank'], '500100'],
	['500109', ['北碚', '北碚', 'Beibei'], '500100'],
	['500110', ['万盛', '萬盛', 'Wansheng'], '500100'],
	['500111', ['双桥', '雙橋', 'Double bridge'], '500100'],
	['500112', ['渝北', '渝北', 'Yubei'], '500100'],
	['500113', ['巴南', '巴南', 'Banan'], '500100'],
	['500114', ['黔江', '黔江', 'Lijiang'], '500100'],
	['500115', ['长寿', '長壽', 'longevity'], '500100'],
	['500222', ['綦江', '綦江', 'Lijiang'], '500100'],
	['500223', ['潼南', '潼南', 'Weinan'], '500100'],
	['500224', ['铜梁', '銅梁', 'Copper beam'], '500100'],
	['500225', ['大足', '大足', 'Bigfoot'], '500100'],
	['500226', ['荣昌', '榮昌', 'Rongchang'], '500100'],
	['500227', ['璧山', '璧山', 'Lushan'], '500100'],
	['500228', ['梁平', '梁平', 'Liang Ping'], '500100'],
	['500229', ['城口', '城口', 'Chengkou'], '500100'],
	['500230', ['丰都', '豐都', 'Fengdu'], '500100'],
	['500231', ['垫江', '墊江', 'Dianjiang'], '500100'],
	['500232', ['武隆', '武隆', 'Wulong'], '500100'],
	['500233', ['忠县', '忠縣', 'Zhongxian County'], '500100'],
	['500234', ['开县', '開縣', 'Kai County'], '500100'],
	['500235', ['云阳', '雲陽', 'Yunyang'], '500100'],
	['500236', ['奉节', '奉節', 'Fengjie'], '500100'],
	['500237', ['巫山', '巫山', 'Wushan'], '500100'],
	['500238', ['巫溪', '巫溪', 'Wuxi'], '500100'],
	['500240', ['石柱', '石柱', 'Stone pillar'], '500100'],
	['500241', ['秀山', '秀山', 'Xiushan'], '500100'],
	['500242', ['酉阳', '酉陽', 'Fuyang'], '500100'],
	['500243', ['彭水', '彭水', 'Peng Shui'], '500100'],
	['500381', ['江津', '江津', 'Jiangjin'], '500100'],
	['500382', ['合川', '合川', 'Hechuan'], '500100'],
	['500383', ['永川', '永川', 'Yongchuan'], '500100'],
	['500384', ['南川', '南川', 'Nanchuan'], '500100'],
	['510104', ['锦江', '錦江', 'Jinjiang'], '510100'],
	['510105', ['青羊', '青羊', 'Qingyang'], '510100'],
	['510106', ['金牛', '金牛', 'Taurus'], '510100'],
	['510107', ['武侯', '武侯', 'Wuhou'], '510100'],
	['510108', ['成华', '成華', 'Cheng Hua'], '510100'],
	['510112', ['龙泉驿', '龍泉驛', 'Longquan'], '510100'],
	['510113', ['青白江', '青白江', 'Qingbaijiang'], '510100'],
	['510114', ['新都', '新都', 'New capital'], '510100'],
	['510115', ['温江', '溫江', 'Wen Jiang'], '510100'],
	['510121', ['金堂', '金堂', 'Jintang'], '510100'],
	['510122', ['双流', '雙流', 'Double flow'], '510100'],
	['510124', ['郫县', '郫縣', 'Ji County'], '510100'],
	['510129', ['大邑', '大邑', 'Daxie'], '510100'],
	['510131', ['蒲江', '蒲江', 'Pujiang'], '510100'],
	['510132', ['新津', '新津', 'Xinjin'], '510100'],
	['510181', ['都江堰', '都江堰', 'Dujiangyan'], '510100'],
	['510182', ['彭州', '彭州', 'Pengzhou'], '510100'],
	['510183', ['邛崃', '邛崍', 'Qionglai'], '510100'],
	['510184', ['崇州', '崇州', 'Chongzhou'], '510100'],
	['510302', ['自流井', '自流井', 'artesian well'], '510300'],
	['510303', ['贡井', '貢井', 'Kaii'], '510300'],
	['510304', ['大安', '大安', 'Daan'], '510300'],
	['510311', ['沿滩', '沿灘', 'Along the beach'], '510300'],
	['510321', ['荣县', '榮縣', 'Rong County'], '510300'],
	['510322', ['富顺', '富順', 'Fushun'], '510300'],
	['510402', ['东区', '東區', 'Eastern District'], '510400'],
	['510403', ['西区', '西區', 'West District'], '510400'],
	['510411', ['仁和', '仁和', 'Honoko'], '510400'],
	['510421', ['米易', '米易', 'Mi Yi'], '510400'],
	['510422', ['盐边', '鹽邊', 'Salt side'], '510400'],
	['510502', ['江阳', '江陽', 'Koyo'], '510500'],
	['510503', ['纳溪', '納溪', 'Naxi'], '510500'],
	['510504', ['龙马潭', '龍馬潭', 'Longmatan'], '510500'],
	['510521', ['泸县', '瀘縣', 'Ji County'], '510500'],
	['510522', ['合江', '合江', 'Hejiang'], '510500'],
	['510524', ['叙永', '敘永', 'Xu Yong'], '510500'],
	['510525', ['古蔺', '古藺', 'Ancient'], '510500'],
	['510603', ['旌阳', '旌陽', 'Fuyang'], '510600'],
	['510623', ['中江', '中江', 'Nakae'], '510600'],
	['510626', ['罗江', '羅江', 'Luo Jiang'], '510600'],
	['510681', ['广汉', '廣漢', 'Guanghan'], '510600'],
	['510682', ['什邡', '什邡', 'What?'], '510600'],
	['510683', ['绵竹', '綿竹', 'Mianzhu'], '510600'],
	['510703', ['涪城', '涪城', 'Yucheng'], '510700'],
	['510704', ['游仙', '遊仙', 'Youxian'], '510700'],
	['510722', ['三台', '三臺', 'Three sets'], '510700'],
	['510723', ['盐亭', '鹽亭', 'Salt pavilion'], '510700'],
	['510724', ['安县', '安縣', 'An County'], '510700'],
	['510725', ['梓潼', '梓潼', '梓潼'], '510700'],
	['510726', ['北川', '北川', 'Kitagawa'], '510700'],
	['510727', ['平武', '平武', 'Hebe'], '510700'],
	['510781', ['江油', '江油', 'Jiangyou'], '510700'],
	['510802', ['利州', '利州', 'Lee State'], '510800'],
	['510811', ['昭化', '昭化', 'Showa'], '510800'],
	['510812', ['朝天', '朝天', 'Chaotian'], '510800'],
	['510821', ['旺苍', '旺蒼', 'Wang Cang'], '510800'],
	['510822', ['青川', '青川', 'Qing River'], '510800'],
	['510823', ['剑阁', '劍閣', 'Jiange'], '510800'],
	['510824', ['苍溪', '蒼溪', 'Cangxi'], '510800'],
	['510903', ['船山', '船山', 'Ship mountain'], '510900'],
	['510904', ['安居', '安居', 'Settle down'], '510900'],
	['510921', ['蓬溪', '蓬溪', 'Pengxi'], '510900'],
	['510922', ['射洪', '射洪', 'Flooding'], '510900'],
	['510923', ['大英', '大英', 'British'], '510900'],
	['511002', ['市中', '市中', 'In the market'], '511000'],
	['511011', ['东兴', '東興', 'Dongxing'], '511000'],
	['511024', ['威远', '威遠', 'Weiyuan'], '511000'],
	['511025', ['资中', '資中', 'Capital'], '511000'],
	['511028', ['隆昌', '隆昌', 'Longchang'], '511000'],
	['511102', ['市中', '市中', 'In the market'], '511100'],
	['511111', ['沙湾', '沙灣', 'Shawan'], '511100'],
	['511112', ['五通桥', '五通橋', 'Wutong Bridge'], '511100'],
	['511113', ['金口河', '金口河', 'Jinkouhe'], '511100'],
	['511123', ['犍为', '犍為', '犍'], '511100'],
	['511124', ['井研', '井研', 'Ikken'], '511100'],
	['511126', ['夹江', '夾江', 'Jiajiang'], '511100'],
	['511129', ['沐川', '沐川', 'Muchuan'], '511100'],
	['511132', ['峨边', '峨邊', 'Side'], '511100'],
	['511133', ['马边', '馬邊', 'Horseside'], '511100'],
	['511181', ['峨眉山', '峨眉山', 'Mount Emei'], '511100'],
	['511302', ['顺庆', '順慶', 'Shunqing'], '511300'],
	['511303', ['高坪', '高坪', 'Gaoping'], '511300'],
	['511304', ['嘉陵', '嘉陵', 'Jialing'], '511300'],
	['511321', ['南部', '南部', 'Southern'], '511300'],
	['511322', ['营山', '營山', 'Camp mountain'], '511300'],
	['511323', ['蓬安', '蓬安', 'Pengan'], '511300'],
	['511324', ['仪陇', '儀隴', 'Ceremonial'], '511300'],
	['511325', ['西充', '西充', 'Xichong'], '511300'],
	['511381', ['阆中', '閬中', 'Langzhong'], '511300'],
	['511402', ['东坡', '東坡', 'Dongpo'], '511400'],
	['511421', ['仁寿', '仁壽', 'Renshou'], '511400'],
	['511422', ['彭山', '彭山', 'Pengshan'], '511400'],
	['511423', ['洪雅', '洪雅', 'Hongya'], '511400'],
	['511424', ['丹棱', '丹棱', 'Tanji'], '511400'],
	['511425', ['青神', '青神', 'Blue god'], '511400'],
	['511502', ['翠屏', '翠屏', 'Cuiping'], '511500'],
	['511521', ['宜宾', '宜賓', 'Yibin'], '511500'],
	['511522', ['南溪', '南溪', 'Nanxi'], '511500'],
	['511523', ['江安', '江安', 'Jiang An'], '511500'],
	['511524', ['长宁', '長寧', 'Changning'], '511500'],
	['511525', ['高县', '高縣', 'Gao County'], '511500'],
	['511526', ['珙县', '珙縣', 'Ji County'], '511500'],
	['511527', ['筠连', '筠連', 'Qilian'], '511500'],
	['511528', ['兴文', '興文', 'Xingwen'], '511500'],
	['511529', ['屏山', '屏山', 'Pingshan'], '511500'],
	['511602', ['广安', '廣安', "Guang'an"], '511600'],
	['511603', ['前锋', '前鋒', 'forward'], '511600'],
	['511621', ['岳池', '嶽池', 'Yuechi'], '511600'],
	['511622', ['武胜', '武勝', 'Wu Sheng'], '511600'],
	['511623', ['邻水', '鄰水', 'Neighborhood water'], '511600'],
	['511681', ['华蓥', '華鎣', 'Hua Wei'], '511600'],
	['511702', ['通川', '通川', 'River'], '511700'],
	['511721', ['达川', '達川', 'Tachikawa'], '511700'],
	['511722', ['宣汉', '宣漢', 'Xuanhan'], '511700'],
	['511723', ['开江', '開江', 'Kaijiang'], '511700'],
	['511724', ['大竹', '大竹', 'Otake'], '511700'],
	['511725', ['渠县', '渠縣', 'Qu County'], '511700'],
	['511781', ['万源', '萬源', 'Wanyuan'], '511700'],
	['511802', ['雨城', '雨城', 'Rain city'], '511800'],
	['511821', ['名山', '名山', 'Famous mountain'], '511800'],
	['511822', ['荥经', '滎經', 'Chanting'], '511800'],
	['511823', ['汉源', '漢源', 'Hanyuan'], '511800'],
	['511824', ['石棉', '石棉', 'asbestos'], '511800'],
	['511825', ['天全', '天全', 'Tianquan'], '511800'],
	['511826', ['芦山', '蘆山', 'Lushan'], '511800'],
	['511827', ['宝兴', '寶興', 'Baoxing'], '511800'],
	['511902', ['巴州', '巴州', 'Bazhou'], '511900'],
	['511903', ['恩阳', '恩陽', 'Enyang'], '511900'],
	['511921', ['通江', '通江', 'Tongjiang'], '511900'],
	['511922', ['南江', '南江', 'Nanjiang'], '511900'],
	['511923', ['平昌', '平昌', 'Pyeongchang'], '511900'],
	['512002', ['雁江', '雁江', 'Yanjiang'], '512000'],
	['512021', ['安岳', '安嶽', 'Anyue'], '512000'],
	['512022', ['乐至', '樂至', 'Happy'], '512000'],
	['512081', ['简阳', '簡陽', 'Jianyang'], '512000'],
	['513221', ['汶川', '汶川', 'Wenchuan'], '513200'],
	['513222', ['理县', '理縣', 'Li County'], '513200'],
	['513223', ['茂县', '茂縣', 'Mao County'], '513200'],
	['513224', ['松潘', '松潘', 'Songpan'], '513200'],
	['513225', ['九寨沟', '九寨溝', 'Jiuzhaigou'], '513200'],
	['513226', ['金川', '金川', 'Jinchuan'], '513200'],
	['513227', ['小金', '小金', 'Xiaojin'], '513200'],
	['513228', ['黑水', '黑水', 'Black water'], '513200'],
	['513229', ['马尔康', '馬爾康', 'Malcolm'], '513200'],
	['513230', ['壤塘', '壤塘', 'Soil pond'], '513200'],
	['513231', ['阿坝', '阿壩', 'Aba'], '513200'],
	['513232', ['若尔盖', '若爾蓋', 'Zoige'], '513200'],
	['513233', ['红原', '紅原', 'Hongyuan'], '513200'],
	['513321', ['康定', '康定', 'Kangding'], '513300'],
	['513322', ['泸定', '瀘定', 'Set'], '513300'],
	['513323', ['丹巴', '丹巴', 'Danba'], '513300'],
	['513324', ['九龙', '九龍', 'Kowloon'], '513300'],
	['513325', ['雅江', '雅江', 'Yajiang'], '513300'],
	['513326', ['道孚', '道孚', 'Daofu'], '513300'],
	['513327', ['炉霍', '爐霍', 'Furnace'], '513300'],
	['513328', ['甘孜', '甘孜', 'Ganzi'], '513300'],
	['513329', ['新龙', '新龍', 'New dragon'], '513300'],
	['513330', ['德格', '德格', 'Dege'], '513300'],
	['513331', ['白玉', '白玉', 'White jade'], '513300'],
	['513332', ['石渠', '石渠', 'Stone channel'], '513300'],
	['513333', ['色达', '色達', 'Seda'], '513300'],
	['513334', ['理塘', '理塘', 'Litang'], '513300'],
	['513335', ['巴塘', '巴塘', 'Batang'], '513300'],
	['513336', ['乡城', '鄉城', 'Township'], '513300'],
	['513337', ['稻城', '稻城', 'Daocheng'], '513300'],
	['513338', ['得荣', '得榮', 'Win'], '513300'],
	['513401', ['西昌', '西昌', 'Xichang'], '513400'],
	['513422', ['木里', '木裏', 'The back side'], '513400'],
	['513423', ['盐源', '鹽源', 'Salt source'], '513400'],
	['513424', ['德昌', '德昌', 'Dechang'], '513400'],
	['513425', ['会理', '會理', 'Meeting'], '513400'],
	['513426', ['会东', '會東', 'Huidong'], '513400'],
	['513427', ['宁南', '寧南', 'Ningnan'], '513400'],
	['513428', ['普格', '普格', 'Puig'], '513400'],
	['513429', ['布拖', '布拖', 'Cloth'], '513400'],
	['513430', ['金阳', '金陽', 'Jinyang'], '513400'],
	['513431', ['昭觉', '昭覺', 'Zhaojue'], '513400'],
	['513432', ['喜德', '喜德', 'Sid'], '513400'],
	['513433', ['冕宁', '冕寧', 'Suining'], '513400'],
	['513434', ['越西', '越西', 'Yuexi'], '513400'],
	['513435', ['甘洛', '甘洛', 'Ganlo'], '513400'],
	['513436', ['美姑', '美姑', 'Meigu'], '513400'],
	['513437', ['雷波', '雷波', 'Leibo'], '513400'],
	['520102', ['南明', '南明', 'Nanming'], '520100'],
	['520103', ['云岩', '雲巖', 'Yunyan'], '520100'],
	['520111', ['花溪', '花溪', 'Flower creek'], '520100'],
	['520112', ['乌当', '烏當', 'Wudang'], '520100'],
	['520113', ['白云', '白雲', 'White clouds'], '520100'],
	['520121', ['开阳', '開陽', 'Kaiyang'], '520100'],
	['520122', ['息烽', '息烽', 'Interest'], '520100'],
	['520123', ['修文', '修文', 'Xiuwen'], '520100'],
	['520151', ['观山湖', '觀山湖', 'Guanshan Lake'], '520100'],
	['520181', ['清镇', '清鎮', 'Qingzhen'], '520100'],
	['520201', ['钟山', '鐘山', 'Zhongshan'], '520200'],
	['520203', ['六枝特', '六枝特', 'Six branches'], '520200'],
	['520221', ['水城', '水城', 'Water city'], '520200'],
	['520222', ['盘县', '盤縣', 'Pan County'], '520200'],
	['520302', ['红花岗', '紅花崗', 'Honghuagang'], '520300'],
	['520303', ['汇川', '匯川', 'Huichuan'], '520300'],
	['520321', ['遵义', '遵義', 'Zunyi'], '520300'],
	['520322', ['桐梓', '桐梓', 'Tonglu'], '520300'],
	['520323', ['绥阳', '綏陽', 'Fuyang'], '520300'],
	['520324', ['正安', '正安', 'Zhengan'], '520300'],
	['520325', ['道真', '道真', 'Michizane'], '520300'],
	['520326', ['务川', '務川', 'Uchigawa'], '520300'],
	['520327', ['凤冈', '鳳岡', 'Tenoka'], '520300'],
	['520328', ['湄潭', '湄潭', 'Yutan'], '520300'],
	['520329', ['余庆', '余慶', 'fortunate heredity'], '520300'],
	['520330', ['习水', '習水', 'Xishui'], '520300'],
	['520381', ['赤水', '赤水', 'Chishui'], '520300'],
	['520382', ['仁怀', '仁懷', 'Ren Huai'], '520300'],
	['520402', ['西秀', '西秀', 'Nishihide'], '520400'],
	['520421', ['平坝', '平壩', 'Pingba'], '520400'],
	['520422', ['普定', '普定', 'Puding'], '520400'],
	['520423', ['镇宁', '鎮寧', 'Zhenning'], '520400'],
	['520424', ['关岭', '關嶺', 'Guan Ling'], '520400'],
	['520425', ['紫云', '紫雲', 'Ziyun'], '520400'],
	['522201', ['碧江', '碧江', 'Bijiang'], '522200'],
	['522222', ['江口', '江口', 'Eguchi'], '522200'],
	['522223', ['玉屏', '玉屏', 'Yuping'], '522200'],
	['522224', ['石阡', '石阡', 'Shi Yan'], '522200'],
	['522225', ['思南', '思南', 'Sinan'], '522200'],
	['522226', ['印江', '印江', 'Yinjiang'], '522200'],
	['522227', ['德江', '德江', 'Dejiang'], '522200'],
	['522228', ['沿河', '沿河', 'Along the river'], '522200'],
	['522229', ['松桃', '松桃', 'Pine peach'], '522200'],
	['522230', ['万山', '萬山', 'Wanshan'], '522200'],
	['522301', ['兴义', '興義', 'Xingyi'], '522300'],
	['522322', ['兴仁', '興仁', 'Xingren'], '522300'],
	['522323', ['普安', '普安', "Pu'an"], '522300'],
	['522324', ['晴隆', '晴隆', 'Qinglong'], '522300'],
	['522325', ['贞丰', '貞豐', 'Yu Feng'], '522300'],
	['522326', ['望谟', '望謨', 'Hope'], '522300'],
	['522327', ['册亨', '冊亨', 'Book'], '522300'],
	['522328', ['安龙', '安龍', 'Anlong'], '522300'],
	['522401', ['七星关', '七星關', 'Seven Stars'], '522400'],
	['522422', ['大方', '大方', 'generous'], '522400'],
	['522423', ['黔西', '黔西', 'Daisy'], '522400'],
	['522424', ['金沙', '金沙', 'Sands'], '522400'],
	['522425', ['织金', '織金', 'Zhijin'], '522400'],
	['522426', ['纳雍', '納雍', 'Labuan'], '522400'],
	['522427', ['威宁', '威寧', 'Weining'], '522400'],
	['522428', ['赫章', '赫章', 'Hezhang'], '522400'],
	['522601', ['凯里', '凱裏', 'Carey'], '522600'],
	['522622', ['黄平', '黃平', 'Huang Ping'], '522600'],
	['522623', ['施秉', '施秉', 'Shi Bing'], '522600'],
	['522624', ['三穗', '三穗', 'Three ears'], '522600'],
	['522625', ['镇远', '鎮遠', 'Zhenyuan'], '522600'],
	['522626', ['岑巩', '岑鞏', 'Qi Gong'], '522600'],
	['522627', ['天柱', '天柱', 'Tianzhu'], '522600'],
	['522628', ['锦屏', '錦屏', 'Jinping'], '522600'],
	['522629', ['剑河', '劍河', 'Jianhe'], '522600'],
	['522630', ['台江', '臺江', 'Taijiang'], '522600'],
	['522631', ['黎平', '黎平', 'Liping'], '522600'],
	['522632', ['榕江', '榕江', 'Lijiang'], '522600'],
	['522633', ['从江', '從江', 'From the river'], '522600'],
	['522634', ['雷山', '雷山', 'Leishan'], '522600'],
	['522635', ['麻江', '麻江', 'Ma Jiang'], '522600'],
	['522636', ['丹寨', '丹寨', 'Danzhai'], '522600'],
	['522701', ['都匀', '都勻', 'Duyun'], '522700'],
	['522702', ['福泉', '福泉', 'Fuquan'], '522700'],
	['522722', ['荔波', '荔波', 'Libo'], '522700'],
	['522723', ['贵定', '貴定', 'Nobility'], '522700'],
	['522725', ['瓮安', '甕安', 'Diane'], '522700'],
	['522726', ['独山', '獨山', 'Dushan'], '522700'],
	['522727', ['平塘', '平塘', 'Pingtang'], '522700'],
	['522728', ['罗甸', '羅甸', 'Luodian'], '522700'],
	['522729', ['长顺', '長順', 'Changshun'], '522700'],
	['522730', ['龙里', '龍裏', 'Backstage'], '522700'],
	['522731', ['惠水', '惠水', 'Huishui'], '522700'],
	['522732', ['三都', '三都', 'Three capitals'], '522700'],
	['530102', ['五华', '五華', 'Goka'], '530100'],
	['530103', ['盘龙', '盤龍', 'Panlong'], '530100'],
	['530111', ['官渡', '官渡', 'Guandu'], '530100'],
	['530112', ['西山', '西山', 'Xishan'], '530100'],
	['530113', ['东川', '東川', 'Dongchuan'], '530100'],
	['530121', ['呈贡', '呈貢', 'Chenggong'], '530100'],
	['530122', ['晋宁', '晉寧', 'Jinning'], '530100'],
	['530124', ['富民', '富民', 'Fumin'], '530100'],
	['530125', ['宜良', '宜良', 'Yiliang'], '530100'],
	['530126', ['石林', '石林', 'stone forest'], '530100'],
	['530127', ['嵩明', '嵩明', 'Yan Ming'], '530100'],
	['530128', ['禄劝', '祿勸', 'Luquan'], '530100'],
	['530129', ['寻甸', '尋甸', 'Xundian'], '530100'],
	['530181', ['安宁', '安寧', 'peaceful'], '530100'],
	['530302', ['麒麟', '麒麟', 'Kirin'], '530300'],
	['530321', ['马龙', '馬龍', 'Malone'], '530300'],
	['530322', ['陆良', '陸良', 'Lu Liang'], '530300'],
	['530323', ['师宗', '師宗', 'Shizong'], '530300'],
	['530324', ['罗平', '羅平', 'Luo Ping'], '530300'],
	['530325', ['富源', '富源', 'Fuyuan'], '530300'],
	['530326', ['会泽', '會澤', 'Huize'], '530300'],
	['530328', ['沾益', '沾益', 'Zhanyi'], '530300'],
	['530381', ['宣威', '宣威', 'Xuanwei'], '530300'],
	['530402', ['红塔', '紅塔', 'Red tower'], '530400'],
	['530421', ['江川', '江川', 'Egawa'], '530400'],
	['530422', ['澄江', '澄江', 'Chengjiang'], '530400'],
	['530423', ['通海', '通海', 'Tonghai'], '530400'],
	['530424', ['华宁', '華寧', 'Hua Ning'], '530400'],
	['530425', ['易门', '易門', 'Yimen'], '530400'],
	['530426', ['峨山', '峨山', 'Lushan'], '530400'],
	['530427', ['新平', '新平', 'Shinghei'], '530400'],
	['530428', ['元江', '元江', 'Motohiro'], '530400'],
	['530502', ['隆阳', '隆陽', 'Longyang'], '530500'],
	['530521', ['施甸', '施甸', 'Shidian'], '530500'],
	['530522', ['腾冲', '騰沖', 'Tengchong'], '530500'],
	['530523', ['龙陵', '龍陵', 'Longling'], '530500'],
	['530524', ['昌宁', '昌寧', 'Changning'], '530500'],
	['530602', ['昭阳', '昭陽', 'Zhaoyang'], '530600'],
	['530621', ['鲁甸', '魯甸', 'Ludian'], '530600'],
	['530622', ['巧家', '巧家', 'Skillful home'], '530600'],
	['530623', ['盐津', '鹽津', 'Yanjin'], '530600'],
	['530624', ['大关', '大關', 'Great mark'], '530600'],
	['530625', ['永善', '永善', 'Yongshan'], '530600'],
	['530626', ['绥江', '綏江', 'Lijiang'], '530600'],
	['530627', ['镇雄', '鎮雄', 'Zhenxiong'], '530600'],
	['530628', ['彝良', '彝良', 'Yan Liang'], '530600'],
	['530629', ['威信', '威信', 'prestige'], '530600'],
	['530630', ['水富', '水富', 'Water rich'], '530600'],
	['530702', ['古城', '古城', 'Ancient city'], '530700'],
	['530721', ['玉龙', '玉龍', 'Yulong'], '530700'],
	['530722', ['永胜', '永勝', 'Yongsheng'], '530700'],
	['530723', ['华坪', '華坪', 'Huaping'], '530700'],
	['530724', ['宁蒗', '寧蒗', 'Ning Wei'], '530700'],
	['530802', ['思茅', '思茅', 'Simao'], '530800'],
	['530821', ['宁洱', '寧洱', 'Ning Wei'], '530800'],
	['530822', ['墨江', '墨江', 'Mojiang'], '530800'],
	['530823', ['景东', '景東', 'Jingdong'], '530800'],
	['530824', ['景谷', '景穀', 'Landscapes'], '530800'],
	['530825', ['镇沅', '鎮沅', 'Zhen Zhen'], '530800'],
	['530826', ['江城', '江城', 'Jiangcheng'], '530800'],
	['530827', ['孟连', '孟連', 'Meng Lian'], '530800'],
	['530828', ['澜沧', '瀾滄', '澜沧'], '530800'],
	['530829', ['西盟', '西盟', 'Western Union'], '530800'],
	['530902', ['临翔', '臨翔', 'Linxiang'], '530900'],
	['530921', ['凤庆', '鳳慶', 'Feng Qing'], '530900'],
	['530922', ['云县', '雲縣', 'Yun County'], '530900'],
	['530923', ['永德', '永德', 'Yongde'], '530900'],
	['530924', ['镇康', '鎮康', 'Zhenkang'], '530900'],
	['530925', ['双江', '雙江', 'Shuangjiang'], '530900'],
	['530926', ['耿马', '耿馬', 'Hummer'], '530900'],
	['530927', ['沧源', '滄源', 'Wuyuan'], '530900'],
	['532301', ['楚雄', '楚雄', 'Chuxiong'], '532300'],
	['532322', ['双柏', '雙柏', 'Double cypress'], '532300'],
	['532323', ['牟定', '牟定', 'Set'], '532300'],
	['532324', ['南华', '南華', 'South China'], '532300'],
	['532325', ['姚安', '姚安', 'Yao An'], '532300'],
	['532326', ['大姚', '大姚', 'Dayao'], '532300'],
	['532327', ['永仁', '永仁', 'Yongren'], '532300'],
	['532328', ['元谋', '元謀', 'Yuanmou'], '532300'],
	['532329', ['武定', '武定', 'Wuding'], '532300'],
	['532331', ['禄丰', '祿豐', 'Lufeng'], '532300'],
	['532501', ['个旧', '個舊', 'Old'], '532500'],
	['532502', ['开远', '開遠', 'Kaiyuan'], '532500'],
	['532522', ['蒙自', '蒙自', 'Mengzi'], '532500'],
	['532523', ['屏边', '屏邊', 'Screen edge'], '532500'],
	['532524', ['建水', '建水', 'Jianshui'], '532500'],
	['532525', ['石屏', '石屏', 'Stone screen'], '532500'],
	['532526', ['弥勒', '彌勒', 'Maitreya'], '532500'],
	['532527', ['泸西', '瀘西', 'Daisy'], '532500'],
	['532528', ['元阳', '元陽', 'Yuanyang'], '532500'],
	['532529', ['红河', '紅河', 'Red river'], '532500'],
	['532530', ['金平', '金平', 'Kanpe'], '532500'],
	['532531', ['绿春', '綠春', 'Green spring'], '532500'],
	['532532', ['河口', '河口', 'estuary'], '532500'],
	['532621', ['文山', '文山', 'Wenshan'], '532600'],
	['532622', ['砚山', '硯山', 'Lushan'], '532600'],
	['532623', ['西畴', '西疇', 'Xiqiu'], '532600'],
	['532624', ['麻栗坡', '麻栗坡', 'Malipo'], '532600'],
	['532625', ['马关', '馬關', 'Ma Guan'], '532600'],
	['532626', ['丘北', '丘北', 'Qiubei'], '532600'],
	['532627', ['广南', '廣南', 'Guangnan'], '532600'],
	['532628', ['富宁', '富寧', 'Funing'], '532600'],
	['532801', ['景洪', '景洪', 'Jing Hong'], '532800'],
	['532822', ['勐海', '猛海', 'Menghai'], '532800'],
	['532823', ['勐腊', '猛臘', 'Mengla'], '532800'],
	['532901', ['大理', '大理', 'Dali'], '532900'],
	['532922', ['漾濞', '漾濞', '漾濞'], '532900'],
	['532923', ['祥云', '祥雲', 'Xiangyun'], '532900'],
	['532924', ['宾川', '賓川', 'Binchuan'], '532900'],
	['532925', ['弥渡', '彌渡', 'Midu'], '532900'],
	['532926', ['南涧', '南澗', 'Nanxun'], '532900'],
	['532927', ['巍山', '巍山', 'Lushan'], '532900'],
	['532928', ['永平', '永平', 'Yongping'], '532900'],
	['532929', ['云龙', '雲龍', 'Yunlong'], '532900'],
	['532930', ['洱源', '洱源', 'Wuyuan'], '532900'],
	['532931', ['剑川', '劍川', 'Jianchuan'], '532900'],
	['532932', ['鹤庆', '鶴慶', 'He Qing'], '532900'],
	['533102', ['瑞丽', '瑞麗', 'Ruili'], '533100'],
	['533103', ['芒市', '芒市', 'Mangshi'], '533100'],
	['533122', ['梁河', '梁河', 'Liang He'], '533100'],
	['533123', ['盈江', '盈江', 'Yingjiang'], '533100'],
	['533124', ['陇川', '隴川', 'Suichuan'], '533100'],
	['533321', ['泸水', '瀘水', 'Drowning'], '533300'],
	['533323', ['福贡', '福貢', 'Fugong'], '533300'],
	['533324', ['贡山', '貢山', 'Gongshan'], '533300'],
	['533325', ['兰坪', '蘭坪', 'Lanping'], '533300'],
	['533421', ['香格里拉', '香格裏拉', 'Shangri-La'], '533400'],
	['533422', ['德钦', '德欽', 'Deqin'], '533400'],
	['533423', ['维西', '維西', 'Visi'], '533400'],
	['540102', ['城关', '城關', 'City gate'], '540100'],
	['540121', ['林周', '林周', 'Lin Zhou'], '540100'],
	['540122', ['当雄', '當雄', 'Dangxiong'], '540100'],
	['540123', ['尼木', '尼木', 'Nimu'], '540100'],
	['540124', ['曲水', '曲水', 'Qushui'], '540100'],
	['540125', ['堆龙德庆', '堆龍德慶', 'Pile dragon deqing'], '540100'],
	['540126', ['达孜', '達孜', 'Daban'], '540100'],
	['540127', ['墨竹工卡', '墨竹工卡', 'Mozhugong card'], '540100'],
	['542121', ['卡若', '卡若', 'Card'], '542100'],
	['542122', ['江达', '江達', 'Jiangda'], '542100'],
	['542123', ['贡觉', '貢覺', 'Gongjue'], '542100'],
	['542124', ['类乌齐', '類烏齊', 'Uzzi'], '542100'],
	['542125', ['丁青', '丁青', 'Ding Qing'], '542100'],
	['542126', ['察雅', '察雅', 'Chaya'], '542100'],
	['542127', ['八宿', '八宿', 'Eight places'], '542100'],
	['542128', ['左贡', '左貢', 'Zuogong'], '542100'],
	['542129', ['芒康', '芒康', 'Mangkang'], '542100'],
	['542132', ['洛隆', '洛隆', 'Loron'], '542100'],
	['542133', ['边坝', '邊壩', 'Side dam'], '542100'],
	['542221', ['乃东', '乃東', 'Northeast'], '542200'],
	['542222', ['扎囊', '紮囊', 'Sac'], '542200'],
	['542223', ['贡嘎', '貢嘎', 'Gongga'], '542200'],
	['542224', ['桑日', '桑日', 'Sanri'], '542200'],
	['542225', ['琼结', '瓊結', 'Qiong Jie'], '542200'],
	['542226', ['曲松', '曲松', 'Qu Song'], '542200'],
	['542227', ['措美', '措美', 'Measure'], '542200'],
	['542228', ['洛扎', '洛紮', 'Loza'], '542200'],
	['542229', ['加查', '加查', 'Check'], '542200'],
	['542231', ['隆子', '隆子', 'Ryuko'], '542200'],
	['542232', ['错那', '錯那', 'Wrong'], '542200'],
	['542233', ['浪卡子', '浪卡子', 'Wave clip'], '542200'],
	['542301', ['桑珠孜', '桑珠孜', 'Sang Zhuyu'], '542300'],
	['542322', ['南木林', '南木林', 'Nanmulin'], '542300'],
	['542323', ['江孜', '江孜', 'Jiang Yan'], '542300'],
	['542324', ['定日', '定日', 'Fixed day'], '542300'],
	['542325', ['萨迦', '薩迦', 'Sakya'], '542300'],
	['542326', ['拉孜', '拉孜', 'Pull'], '542300'],
	['542327', ['昂仁', '昂仁', 'Angren'], '542300'],
	['542328', ['谢通门', '謝通門', 'Xie Tongmen'], '542300'],
	['542329', ['白朗', '白朗', 'Bai Lang'], '542300'],
	['542330', ['仁布', '仁布', 'Renbu'], '542300'],
	['542331', ['康马', '康馬', 'Kangma'], '542300'],
	['542332', ['定结', '定結', 'Set'], '542300'],
	['542333', ['仲巴', '仲巴', 'Zhongba'], '542300'],
	['542334', ['亚东', '亞東', 'Yadong'], '542300'],
	['542335', ['吉隆', '吉隆', 'Geelong'], '542300'],
	['542336', ['聂拉木', '聶拉木', 'Nyalam'], '542300'],
	['542337', ['萨嘎', '薩嘎', 'Samuel'], '542300'],
	['542338', ['岗巴', '崗巴', 'Gamba'], '542300'],
	['542421', ['那曲', '那曲', 'Nagqu'], '542400'],
	['542422', ['嘉黎', '嘉黎', 'Jiali'], '542400'],
	['542423', ['比如', '比如', 'such as'], '542400'],
	['542424', ['聂荣', '聶榮', 'Nie Rong'], '542400'],
	['542425', ['安多', '安多', 'Amdo'], '542400'],
	['542426', ['申扎', '申紮', 'Shenzha'], '542400'],
	['542427', ['索县', '索縣', 'Suo County'], '542400'],
	['542428', ['班戈', '班戈', 'Bangor'], '542400'],
	['542429', ['巴青', '巴青', 'Ba Qing'], '542400'],
	['542430', ['尼玛', '尼瑪', 'Nima'], '542400'],
	['542432', ['双湖', '雙湖', 'Twin lake'], '542400'],
	['542521', ['普兰', '普蘭', 'Plan'], '542500'],
	['542522', ['札达', '劄達', 'Zada'], '542500'],
	['542523', ['噶尔', '噶爾', 'Muir'], '542500'],
	['542524', ['日土', '日土', 'Daily land'], '542500'],
	['542525', ['革吉', '革吉', 'Geji'], '542500'],
	['542526', ['改则', '改則', 'Change'], '542500'],
	['542527', ['措勤', '措勤', 'Coqin'], '542500'],
	['542621', ['巴宜', '巴宜', 'Bayi'], '542600'],
	['542622', ['工布江达', '工布江達', 'Gongbu Jiangda'], '542600'],
	['542623', ['米林', '米林', 'Milin'], '542600'],
	['542624', ['墨脱', '墨脫', 'Medog'], '542600'],
	['542625', ['波密', '波密', 'Bomi'], '542600'],
	['542626', ['察隅', '察隅', 'Cha'], '542600'],
	['542627', ['朗县', '朗縣', 'Lang County'], '542600'],
	['610102', ['新城', '新城', 'New city'], '610100'],
	['610103', ['碑林', '碑林', 'Stele forest'], '610100'],
	['610104', ['莲湖', '蓮湖', 'Lianhu'], '610100'],
	['610111', ['灞桥', '灞橋', 'Bridge'], '610100'],
	['610112', ['未央', '未央', 'Weiyang'], '610100'],
	['610113', ['雁塔', '雁塔', 'Wild goose'], '610100'],
	['610114', ['阎良', '閻良', 'Yan Liang'], '610100'],
	['610115', ['临潼', '臨潼', 'Linyi'], '610100'],
	['610116', ['长安', '長安', 'Changan'], '610100'],
	['610122', ['蓝田', '藍田', 'Lantian'], '610100'],
	['610124', ['周至', '周至', 'Zhou Zhi'], '610100'],
	['610125', ['户县', '戶縣', 'Hu County'], '610100'],
	['610126', ['高陵', '高陵', 'Gaoling'], '610100'],
	['610202', ['王益', '王益', 'Wang Yi'], '610200'],
	['610203', ['印台', '印臺', 'stamp pad'], '610200'],
	['610204', ['耀州', '耀州', 'Yaozhou'], '610200'],
	['610222', ['宜君', '宜君', 'Yijun'], '610200'],
	['610302', ['渭滨', '渭濱', 'Suibin'], '610300'],
	['610303', ['金台', '金臺', 'Jintai'], '610300'],
	['610304', ['陈仓', '陳倉', 'Chen Cang'], '610300'],
	['610322', ['凤翔', '鳳翔', 'Feng Xiang'], '610300'],
	['610323', ['岐山', '岐山', 'Lushan'], '610300'],
	['610324', ['扶风', '扶風', 'Fufeng'], '610300'],
	['610326', ['眉县', '眉縣', 'Mei County'], '610300'],
	['610327', ['陇县', '隴縣', 'Ji County'], '610300'],
	['610328', ['千阳', '千陽', 'Qianyang'], '610300'],
	['610329', ['麟游', '麟遊', 'Lin You'], '610300'],
	['610330', ['凤县', '鳳縣', 'Feng County'], '610300'],
	['610331', ['太白', '太白', 'Too white'], '610300'],
	['610402', ['秦都', '秦都', 'Qindu'], '610400'],
	['610403', ['杨陵', '楊陵', 'Yang Ling'], '610400'],
	['610404', ['渭城', '渭城', 'Yucheng'], '610400'],
	['610422', ['三原', '三原', 'Mihara'], '610400'],
	['610423', ['泾阳', '涇陽', 'Fuyang'], '610400'],
	['610424', ['乾县', '乾縣', 'Gan County'], '610400'],
	['610425', ['礼泉', '禮泉', 'Liquan'], '610400'],
	['610426', ['永寿', '永壽', 'Yongshou'], '610400'],
	['610427', ['彬县', '彬縣', 'Bin County'], '610400'],
	['610428', ['长武', '長武', 'Chief'], '610400'],
	['610429', ['旬邑', '旬邑', 'Xun'], '610400'],
	['610430', ['淳化', '淳化', 'Suihua'], '610400'],
	['610431', ['武功', '武功', 'Wugong'], '610400'],
	['610481', ['兴平', '興平', 'Xingping'], '610400'],
	['610502', ['临渭', '臨渭', 'Linyi'], '610500'],
	['610521', ['华县', '華縣', 'Hua County'], '610500'],
	['610522', ['潼关', '潼關', 'Shaoguan'], '610500'],
	['610523', ['大荔', '大荔', 'Daxie'], '610500'],
	['610524', ['合阳', '合陽', 'Heyang'], '610500'],
	['610525', ['澄城', '澄城', 'Chengcheng'], '610500'],
	['610526', ['蒲城', '蒲城', 'Pucheng'], '610500'],
	['610527', ['白水', '白水', 'White water'], '610500'],
	['610528', ['富平', '富平', 'Buddha'], '610500'],
	['610581', ['韩城', '韓城', 'Hancheng'], '610500'],
	['610582', ['华阴', '華陰', 'Huayin'], '610500'],
	['610602', ['宝塔', '寶塔', 'pagoda'], '610600'],
	['610621', ['延长', '延長', 'extend'], '610600'],
	['610622', ['延川', '延川', 'Yanchuan'], '610600'],
	['610623', ['子长', '子長', 'Child length'], '610600'],
	['610624', ['安塞', '安塞', 'Ansai'], '610600'],
	['610625', ['志丹', '志丹', 'Zhidan'], '610600'],
	['610626', ['吴起', '吳起', 'Wu Qi'], '610600'],
	['610627', ['甘泉', '甘泉', 'Ganquan'], '610600'],
	['610628', ['富县', '富縣', 'Fu County'], '610600'],
	['610629', ['洛川', '洛川', 'Luochuan'], '610600'],
	['610630', ['宜川', '宜川', 'Yichuan'], '610600'],
	['610631', ['黄龙', '黃龍', 'Huang Long'], '610600'],
	['610632', ['黄陵', '黃陵', 'Huangling'], '610600'],
	['610702', ['汉台', '漢臺', 'Hantai'], '610700'],
	['610721', ['南郑', '南鄭', 'Nan Zheng'], '610700'],
	['610722', ['城固', '城固', 'Chenggu'], '610700'],
	['610723', ['洋县', '洋縣', 'Yang County'], '610700'],
	['610724', ['西乡', '西鄉', 'Xixiang'], '610700'],
	['610725', ['勉县', '勉縣', 'Ji County'], '610700'],
	['610726', ['宁强', '寧強', 'Ning Qiang'], '610700'],
	['610727', ['略阳', '略陽', 'Lueyang'], '610700'],
	['610728', ['镇巴', '鎮巴', 'Zhenba'], '610700'],
	['610729', ['留坝', '留壩', 'Retaining dam'], '610700'],
	['610730', ['佛坪', '佛坪', 'Foping'], '610700'],
	['610802', ['榆阳', '榆陽', 'Fuyang'], '610800'],
	['610821', ['神木', '神木', 'Shenmu'], '610800'],
	['610822', ['府谷', '府穀', 'Fugu'], '610800'],
	['610823', ['横山', '橫山', 'Hengshan'], '610800'],
	['610824', ['靖边', '靖邊', 'Jingbian'], '610800'],
	['610825', ['定边', '定邊', 'Fixed edge'], '610800'],
	['610826', ['绥德', '綏德', 'Jude'], '610800'],
	['610827', ['米脂', '米脂', 'Rice fat'], '610800'],
	['610828', ['佳县', '佳縣', 'Jia County'], '610800'],
	['610829', ['吴堡', '吳堡', 'Wubao'], '610800'],
	['610830', ['清涧', '清澗', 'Clear'], '610800'],
	['610831', ['子洲', '子洲', 'Zizhou'], '610800'],
	['610902', ['汉滨', '漢濱', 'Hanbin'], '610900'],
	['610921', ['汉阴', '漢陰', 'Hanyin'], '610900'],
	['610922', ['石泉', '石泉', 'Stone spring'], '610900'],
	['610923', ['宁陕', '寧陝', 'Ningshan'], '610900'],
	['610924', ['紫阳', '紫陽', 'Ziyang'], '610900'],
	['610925', ['岚皋', '嵐皋', '岚皋'], '610900'],
	['610926', ['平利', '平利', 'Pingli'], '610900'],
	['610927', ['镇坪', '鎮坪', 'Zhenping'], '610900'],
	['610928', ['旬阳', '旬陽', 'Xunyang'], '610900'],
	['610929', ['白河', '白河', 'White river'], '610900'],
	['611002', ['商州', '商州', 'Shangzhou'], '611000'],
	['611021', ['洛南', '洛南', 'Luknan'], '611000'],
	['611022', ['丹凤', '丹鳳', 'Dan Feng'], '611000'],
	['611023', ['商南', '商南', 'Shangnan'], '611000'],
	['611024', ['山阳', '山陽', 'Sanyo'], '611000'],
	['611025', ['镇安', '鎮安', 'Zhenan'], '611000'],
	['611026', ['柞水', '柞水', 'Drowning'], '611000'],
	['620102', ['城关', '城關', 'City gate'], '620100'],
	['620103', ['七里河', '七裏河', 'Shichitakawa'], '620100'],
	['620104', ['西固', '西固', 'Xigu'], '620100'],
	['620105', ['安宁', '安寧', 'peaceful'], '620100'],
	['620111', ['红古', '紅古', 'Red ancient'], '620100'],
	['620121', ['永登', '永登', 'Yongdeng'], '620100'],
	['620122', ['皋兰', '皋蘭', 'Cymbidium'], '620100'],
	['620123', ['榆中', '榆中', 'Yuzhong'], '620100'],
	['620302', ['金川', '金川', 'Jinchuan'], '620300'],
	['620321', ['永昌', '永昌', 'Yongchang'], '620300'],
	['620402', ['白银', '白銀', 'silver'], '620400'],
	['620403', ['平川', '平川', 'Hirakawa'], '620400'],
	['620421', ['靖远', '靖遠', 'Jing Yuan'], '620400'],
	['620422', ['会宁', '會寧', 'Huining'], '620400'],
	['620423', ['景泰', '景泰', 'Jingtai'], '620400'],
	['620502', ['秦州', '秦州', 'Qinzhou'], '620500'],
	['620503', ['麦积', '麥積', 'Mai Ji'], '620500'],
	['620521', ['清水', '清水', 'Clear water'], '620500'],
	['620522', ['秦安', '秦安', 'Qin An'], '620500'],
	['620523', ['甘谷', '甘穀', 'Sweet cake'], '620500'],
	['620524', ['武山', '武山', 'Takeyama'], '620500'],
	['620525', ['张家川', '張家川', 'Zhang Jiachuan'], '620500'],
	['620602', ['凉州', '涼州', 'Liangzhou'], '620600'],
	['620621', ['民勤', '民勤', 'Minqin'], '620600'],
	['620622', ['古浪', '古浪', 'Gulang'], '620600'],
	['620623', ['天祝', '天祝', 'Tianzhu'], '620600'],
	['620702', ['甘州', '甘州', 'Ganzhou'], '620700'],
	['620721', ['肃南', '肅南', 'Sunan'], '620700'],
	['620722', ['民乐', '民樂', 'folk music'], '620700'],
	['620723', ['临泽', '臨澤', 'Linze'], '620700'],
	['620724', ['高台', '高臺', 'high tower'], '620700'],
	['620725', ['山丹', '山丹', 'Shandan'], '620700'],
	['620802', ['崆峒', '崆峒', '崆峒'], '620800'],
	['620821', ['泾川', '涇川', 'Suichuan'], '620800'],
	['620822', ['灵台', '靈臺', 'Lingtai'], '620800'],
	['620823', ['崇信', '崇信', 'Chongxin'], '620800'],
	['620824', ['华亭', '華亭', 'Huating'], '620800'],
	['620825', ['庄浪', '莊浪', 'Zhuanglang'], '620800'],
	['620826', ['静宁', '靜寧', 'Jingning'], '620800'],
	['620902', ['肃州', '肅州', 'Suzhou'], '620900'],
	['620921', ['金塔', '金塔', 'Quinta'], '620900'],
	['620922', ['瓜州', '瓜州', 'Guaju'], '620900'],
	['620923', ['肃北', '肅北', 'Subei'], '620900'],
	['620924', ['阿克塞', '阿克塞', 'Axe'], '620900'],
	['620981', ['玉门', '玉門', 'Yumen'], '620900'],
	['620982', ['敦煌', '敦煌', 'Dunhuang'], '620900'],
	['621002', ['西峰', '西峰', 'Xifeng'], '621000'],
	['621021', ['庆城', '慶城', 'Qingcheng'], '621000'],
	['621022', ['环县', '環縣', 'Huan County'], '621000'],
	['621023', ['华池', '華池', 'Huachi'], '621000'],
	['621024', ['合水', '合水', 'Water'], '621000'],
	['621025', ['正宁', '正寧', 'Zhengning'], '621000'],
	['621026', ['宁县', '寧縣', 'Ning County'], '621000'],
	['621027', ['镇原', '鎮原', 'Chiten'], '621000'],
	['621102', ['安定', '安定', 'Stability'], '621100'],
	['621121', ['通渭', '通渭', 'Wanted'], '621100'],
	['621122', ['陇西', '隴西', 'Daisy'], '621100'],
	['621123', ['渭源', '渭源', 'Wuyuan'], '621100'],
	['621124', ['临洮', '臨洮', 'Linyi'], '621100'],
	['621125', ['漳县', '漳縣', 'Ji County'], '621100'],
	['621126', ['岷县', '岷縣', 'Ji County'], '621100'],
	['621202', ['武都', '武都', 'Budo'], '621200'],
	['621221', ['成县', '成縣', 'Cheng County'], '621200'],
	['621222', ['文县', '文縣', 'Wen County'], '621200'],
	['621223', ['宕昌', '宕昌', 'Suichang'], '621200'],
	['621224', ['康县', '康縣', 'Kang County'], '621200'],
	['621225', ['西和', '西和', 'Nishiwa'], '621200'],
	['621226', ['礼县', '禮縣', 'Li County'], '621200'],
	['621227', ['徽县', '徽縣', 'Hui County'], '621200'],
	['621228', ['两当', '兩當', 'Two'], '621200'],
	['622901', ['临夏市', '臨夏市', 'Linxia City'], '622900'],
	['622921', ['临夏县', '臨夏縣', 'Linxia County'], '622900'],
	['622922', ['康乐', '康樂', 'Recreation'], '622900'],
	['622923', ['永靖', '永靖', 'Yongjing'], '622900'],
	['622924', ['广河', '廣河', 'Guanghe'], '622900'],
	['622925', ['和政', '和政', 'Peace'], '622900'],
	['622926', ['东乡', '東鄉', 'Dongxiang'], '622900'],
	['622927', ['积石山', '積石山', 'Shishimayama'], '622900'],
	['623001', ['合作', '合作', 'Cooperation'], '623000'],
	['623021', ['临潭', '臨潭', 'Lintan'], '623000'],
	['623022', ['卓尼', '卓尼', 'Zhuoni'], '623000'],
	['623023', ['舟曲', '舟曲', 'Zhou Qu'], '623000'],
	['623024', ['迭部', '迭部', 'Stack'], '623000'],
	['623025', ['玛曲', '瑪曲', 'Ma Qu'], '623000'],
	['623026', ['碌曲', '碌曲', 'Luqu'], '623000'],
	['623027', ['夏河', '夏河', 'Xiahe'], '623000'],
	['630102', ['城东', '城東', 'Chengdong'], '630100'],
	['630103', ['城中', '城中', 'In the city'], '630100'],
	['630104', ['城西', '城西', 'City west'], '630100'],
	['630105', ['城北', '城北', 'North city'], '630100'],
	['630121', ['大通', '大通', 'Chase'], '630100'],
	['630122', ['湟中', '湟中', 'In the middle'], '630100'],
	['630123', ['湟源', '湟源', 'Wuyuan'], '630100'],
	['632121', ['平安', '平安', 'Peace'], '632100'],
	['632122', ['民和', '民和', 'Minhe'], '632100'],
	['632123', ['乐都', '樂都', 'Ledu'], '632100'],
	['632126', ['互助', '互助', 'Mutual aid'], '632100'],
	['632127', ['化隆', '化隆', 'Hualong'], '632100'],
	['632128', ['循化', '循化', 'Xunhua'], '632100'],
	['632221', ['门源', '門源', 'Door source'], '632200'],
	['632222', ['祁连', '祁連', 'Qilian'], '632200'],
	['632223', ['海晏', '海晏', 'Sea otter'], '632200'],
	['632224', ['刚察', '剛察', 'Just inspect'], '632200'],
	['632321', ['同仁', '同仁', 'Tongren'], '632300'],
	['632322', ['尖扎', '尖紮', 'Sharp'], '632300'],
	['632323', ['泽库', '澤庫', 'Sawasho'], '632300'],
	['632324', ['河南', '河南', 'Henan'], '632300'],
	['632521', ['共和', '共和', 'Republic'], '632500'],
	['632522', ['同德', '同德', 'Tongde'], '632500'],
	['632523', ['贵德', '貴德', 'Guide'], '632500'],
	['632524', ['兴海', '興海', 'Xinghai'], '632500'],
	['632525', ['贵南', '貴南', 'Guinan'], '632500'],
	['632621', ['玛沁', '瑪沁', 'Malang'], '632600'],
	['632622', ['班玛', '班瑪', 'Banma'], '632600'],
	['632623', ['甘德', '甘德', 'Gander'], '632600'],
	['632624', ['达日', '達日', 'Day'], '632600'],
	['632625', ['久治', '久治', 'Kyō'], '632600'],
	['632626', ['玛多', '瑪多', 'Mado'], '632600'],
	['632721', ['玉树', '玉樹', 'Yushu'], '632700'],
	['632722', ['杂多', '雜多', 'Miscellaneous'], '632700'],
	['632723', ['称多', '稱多', 'Weigh more'], '632700'],
	['632724', ['治多', '治多', 'Rise'], '632700'],
	['632725', ['囊谦', '囊謙', '囊 囊'], '632700'],
	['632726', ['曲麻莱', '曲麻萊', 'Quma Lai'], '632700'],
	['632801', ['格尔木', '格爾木', 'Golmud'], '632800'],
	['632802', ['德令哈', '德令哈', 'Delingha'], '632800'],
	['632821', ['乌兰', '烏蘭', 'Ulan'], '632800'],
	['632822', ['都兰', '都蘭', 'Dulan'], '632800'],
	['632823', ['天峻', '天峻', 'Tian Jun'], '632800'],
	['640104', ['兴庆', '興慶', 'Xing Qing'], '640100'],
	['640105', ['西夏', '西夏', 'Xixia'], '640100'],
	['640106', ['金凤', '金鳳', 'Jin Feng'], '640100'],
	['640121', ['永宁', '永寧', 'Yongning'], '640100'],
	['640122', ['贺兰', '賀蘭', 'He Lan'], '640100'],
	['640181', ['灵武', '靈武', 'Lingwu'], '640100'],
	['640202', ['大武口', '大武口', 'Dawukou'], '640200'],
	['640205', ['惠农', '惠農', 'Huinong'], '640200'],
	['640221', ['平罗', '平羅', 'Pingluo'], '640200'],
	['640302', ['利通', '利通', 'Satisfaction'], '640300'],
	['640303', ['红寺堡', '紅寺堡', 'Red Temple Fort'], '640300'],
	['640323', ['盐池', '鹽池', 'Salt pond'], '640300'],
	['640324', ['同心', '同心', 'concentric'], '640300'],
	['640381', ['青铜峡', '青銅峽', 'Qingtongxia'], '640300'],
	['640402', ['原州', '原州', 'Wonju'], '640400'],
	['640422', ['西吉', '西吉', 'Siji'], '640400'],
	['640423', ['隆德', '隆德', 'Lund'], '640400'],
	['640424', ['泾源', '涇源', 'Wuyuan'], '640400'],
	['640425', ['彭阳', '彭陽', 'Peng Yang'], '640400'],
	['640502', ['沙坡头', '沙坡頭', 'Shapotou'], '640500'],
	['640521', ['中宁', '中寧', 'Zhongning'], '640500'],
	['640522', ['海原', '海原', 'The ocean'], '640500'],
	['650102', ['天山', '天山', 'Tianshan'], '650100'],
	['650103', ['沙依巴克', '沙依巴克', 'Shaybak'], '650100'],
	['650104', ['新市', '新市', 'New city'], '650100'],
	['650105', ['水磨沟', '水磨溝', 'Water mill'], '650100'],
	['650106', ['头屯河', '頭屯河', 'Head river'], '650100'],
	['650107', ['达坂城', '達阪城', 'Dasaka City'], '650100'],
	['650109', ['米东', '米東', 'U.S.'], '650100'],
	['650121', ['乌鲁木齐', '烏魯木齊', 'Urumqi'], '650100'],
	['650202', ['独山子', '獨山子', 'Dushanzi'], '650200'],
	['650203', ['克拉玛依', '克拉瑪依', 'Karamay'], '650200'],
	['650204', ['白碱滩', '白堿灘', 'White pelicans'], '650200'],
	['650205', ['乌尔禾', '烏爾禾', 'Urho'], '650200'],
	['652101', ['高昌', '高昌', 'Gao Chang'], '652100'],
	['652122', ['鄯善', '鄯善', 'Shan Shan'], '652100'],
	['652123', ['托克逊', '托克遜', 'Tokson'], '652100'],
	['652201', ['哈密', '哈密', 'Hami'], '652200'],
	['652222', ['巴里坤', '巴裏坤', 'Barkun'], '652200'],
	['652223', ['伊吾', '伊吾', 'Yiwu'], '652200'],
	['652301', ['昌吉', '昌吉', 'Changji'], '652300'],
	['652302', ['阜康', '阜康', 'Ji Kang'], '652300'],
	['652323', ['呼图壁', '呼圖壁', 'Call wall'], '652300'],
	['652324', ['玛纳斯', '瑪納斯', 'Manas'], '652300'],
	['652325', ['奇台', '奇臺', 'Qitai'], '652300'],
	['652327', ['吉木萨尔', '吉木薩爾', 'Jimsar'], '652300'],
	['652328', ['木垒', '木壘', 'Wooden rampart'], '652300'],
	['652701', ['博乐', '博樂', 'Bole'], '652700'],
	['652702', ['阿拉山口', '阿拉山口', 'Alashankou'], '652700'],
	['652722', ['精河', '精河', 'Jinghe'], '652700'],
	['652723', ['温泉', '溫泉', 'spa'], '652700'],
	['652801', ['库尔勒', '庫爾勒', 'Korla'], '652800'],
	['652822', ['轮台', '輪臺', 'Round table'], '652800'],
	['652823', ['尉犁', '尉犁', 'Plow'], '652800'],
	['652824', ['若羌', '若羌', 'If'], '652800'],
	['652825', ['且末', '且末', 'End'], '652800'],
	['652826', ['焉耆', '焉耆', '焉耆'], '652800'],
	['652827', ['和静', '和靜', 'And quiet'], '652800'],
	['652828', ['和硕', '和碩', 'Heshuo'], '652800'],
	['652829', ['博湖', '博湖', 'Bohu'], '652800'],
	['652901', ['阿克苏', '阿克蘇', 'Aksu'], '652900'],
	['652922', ['温宿', '溫宿', 'Warm accommodation'], '652900'],
	['652923', ['库车', '庫車', 'Kuche'], '652900'],
	['652924', ['沙雅', '沙雅', 'Shaya'], '652900'],
	['652925', ['新和', '新和', 'Shinwa'], '652900'],
	['652926', ['拜城', '拜城', 'Baicheng'], '652900'],
	['652927', ['乌什', '烏什', 'Ush'], '652900'],
	['652928', ['阿瓦提', '阿瓦提', 'Awati'], '652900'],
	['652929', ['柯坪', '柯坪', 'Ke Ping'], '652900'],
	['653001', ['阿图什', '阿圖什', 'Atushi'], '653000'],
	['653022', ['阿克陶', '阿克陶', 'Akto'], '653000'],
	['653023', ['阿合奇', '阿合奇', 'Aheqi'], '653000'],
	['653024', ['乌恰', '烏恰', 'Ucha'], '653000'],
	['653101', ['喀什', '喀什', 'Kashgar'], '653100'],
	['653121', ['疏附', '疏附', 'Sparse'], '653100'],
	['653122', ['疏勒', '疏勒', 'Shule'], '653100'],
	['653123', ['英吉沙', '英吉沙', 'Yingjisha'], '653100'],
	['653124', ['泽普', '澤普', 'Zep'], '653100'],
	['653125', ['莎车', '莎車', 'Shakespeare'], '653100'],
	['653126', ['叶城', '葉城', 'Yecheng'], '653100'],
	['653127', ['麦盖提', '麥蓋提', 'McGati'], '653100'],
	['653128', ['岳普湖', '嶽普湖', 'Yuepu Lake'], '653100'],
	['653129', ['伽师', '伽師', 'Jiashi'], '653100'],
	['653130', ['巴楚', '巴楚', 'Bachu'], '653100'],
	['653131', ['塔什库尔干', '塔什庫爾幹', 'Tashkurgan'], '653100'],
	['653201', ['和田市', '和田市', 'Wada city'], '653200'],
	['653221', ['和田县', '和田縣', 'Hotan County'], '653200'],
	['653222', ['墨玉', '墨玉', 'Moyu'], '653200'],
	['653223', ['皮山', '皮山', 'Pishan'], '653200'],
	['653224', ['洛浦', '洛浦', 'Luopu'], '653200'],
	['653225', ['策勒', '策勒', 'Celle'], '653200'],
	['653226', ['于田', '於田', 'Yu Tian'], '653200'],
	['653227', ['民丰', '民豐', 'Minfeng'], '653200'],
	['654002', ['伊宁市', '伊寧市', 'Yining City'], '654000'],
	['654003', ['奎屯', '奎屯', 'Kuitun'], '654000'],
	['654021', ['伊宁县', '伊寧縣', 'Yining County'], '654000'],
	['654022', ['察布查尔', '察布查爾', 'Chabuchar'], '654000'],
	['654023', ['霍城', '霍城', 'Huocheng'], '654000'],
	['654024', ['巩留', '鞏留', 'Custody'], '654000'],
	['654025', ['新源', '新源', 'New source'], '654000'],
	['654026', ['昭苏', '昭蘇', 'Shōsu'], '654000'],
	['654027', ['特克斯', '特克斯', 'Turks'], '654000'],
	['654028', ['尼勒克', '尼勒克', 'Nilek'], '654000'],
	['654201', ['塔城', '塔城', 'Tacheng'], '654200'],
	['654202', ['乌苏', '烏蘇', 'Usu'], '654200'],
	['654221', ['额敏', '額敏', 'Emin'], '654200'],
	['654223', ['沙湾', '沙灣', 'Shawan'], '654200'],
	['654224', ['托里', '托裏', 'Tori'], '654200'],
	['654225', ['裕民', '裕民', 'Yu Min'], '654200'],
	['654226', ['和布克赛尔', '和布克賽爾', 'Buxel'], '654200'],
	['654301', ['阿勒泰', '阿勒泰', 'Altay'], '654300'],
	['654321', ['布尔津', '布爾津', 'Burqin'], '654300'],
	['654322', ['富蕴', '富蘊', 'Rich'], '654300'],
	['654323', ['福海', '福海', 'Fuhai'], '654300'],
	['654324', ['哈巴河', '哈巴河', 'Haba'], '654300'],
	['654325', ['青河', '青河', 'Qinghe'], '654300'],
	['654326', ['吉木乃', '吉木乃', 'Jimu Nai'], '654300']
];

function toTreeData(data) {
	return data.map(item => ({
		id: item[0],
		title: item[1][0],
		traditionalTitle: item[1][1],
		pinyinTitle: item[1][2],
		parentId: item[2]
	}));
}

// 直辖市处理
const municipality = {
	'110100': 110000,
	'310100': 310000,
	'120100': 120000,
	'500100': 500000
};

function toCityData(data) {
	let result = toTreeData(data);
	for (let item of result) {
		if (municipality[item.parentId]) {
			item.parentId = municipality[item.parentId];
		}
	}
	return result;
}

const DISTRICT = toCityData(DISTRICT_DATA);

export const getTotalData = function () {
	return [...getCity().filter(item => !municipality[item.id]), ...DISTRICT];
};

export const getData = function (parent) {
	if (parent == null) {
		return getCity().filter(item => !municipality[item.id]);
	} else {
		return DISTRICT.filter(item => item.parentId == parent.id);
	}
};
