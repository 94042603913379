<template>
  <vxe-toolbar class-name="px-8px">
    <template #buttons>
      <DropdownCustom ref="assetsParamsDropdown" button class="" trigger="click" @click.stop="$refs.assetsParamsDropdown.show()">
        <div>{{ manba(params.entryDate).format('YYYY年MM月') }}</div>
        <template #content>
          <Form style="padding: 10px">
            <FormItem class="!pb-5px" label="会计期间">
              <Select :datas="checkDataList" v-model="params.entryDate" :deletable="false"/>
            </FormItem>
            <FormItem class="!pb-5px" label="增加日期">
              <div class="flex items-center">
                <DatePicker v-model="params.start" :option="{end:params.end}"/>
                <span class="mx-8px">至</span>
                <DatePicker v-model="params.end" :option="{start:params.start}"/>
              </div>
            </FormItem>
            <FormItem class="!pb-5px" label="状态">
              <Select :datas="{1:'正常',2:'停用',3:'出租',4:'已减少'}" filterable v-model.number="params.settlementTypeId"/>
            </FormItem>
            <FormItem class="!pb-5px" label="资产编号">
              <Input v-model="params.code"/>
            </FormItem>
            <FormItem class="!pb-5px" label="资产类别">
              <Select :datas="assetsTypeList" keyName="id" titleName="name" filterable v-model.number="params.assetsTypeId"/>
            </FormItem>
            <FormItem class="!pb-5px" label="资产名称">
              <Input v-model="params.name"/>
            </FormItem>
            <FormItem class="!pb-5px" label="规格型号">
              <Input v-model="params.specification"/>
            </FormItem>
            <FormItem class="!pb-5px" label="使用部门">
              <Select :datas="deptList" filterable v-model.number="params.deptId"/>
            </FormItem>
            <FormItem class="!pb-5px" label="供应商">
              <Input v-model="params.supplier"/>
            </FormItem>
            <FormItem class="!pb-5px">
              <vxe-button status="primary" @click="loadList();" :loading="loading">确定</vxe-button>
              <vxe-button @click="this.$refs.assetsParamsDropdown.hide()">取消</vxe-button>
              <vxe-button @click="reset">重置</vxe-button>
            </FormItem>
          </Form>
        </template>
      </DropdownCustom>
    </template>
    <template #tools>
      <vxe-button @click="showForm()" status="primary" icon="fa fa-plus">添加资产</vxe-button>
      <vxe-button @click="copyAssets()" icon="fa fa-copy">复制资产</vxe-button>
      <vxe-button @click="removeCheckbox()" icon="fa fa-trash">删除资产</vxe-button>
      <vxe-button @click="exportAssets" icon="fa fa-upload">导入</vxe-button>
      <vxe-button @click="importAssets" icon="fa fa-download">导出</vxe-button>
      <vxe-button icon="fa fa-print">打印</vxe-button>
      <vxe-button icon="fa fa-upload">导入固定资产科目</vxe-button>


    </template>
  </vxe-toolbar>
  <div class="flex-1">
    <vxe-table height="auto" ref="xTable"  :loading="loading" show-overflow stripe :data="dataList">
      <vxe-column type="checkbox" width="50" align="center" fixed="left"/>
      <vxe-column title="编码" field="code" width="100"/>
      <vxe-column title="资产类别" field="assetsTypeName" width="150"/>
      <vxe-column title="资产名称" field="name" width="200"/>
      <vxe-column title="规格型号" field="specification" width="100"/>
      <vxe-column title="开始使用日期" field="startDate" width="110"/>
      <vxe-column title="资产原值" field="originalValue" width="130" align="right"/>
      <vxe-column title="本期折旧" field="currentDepreciation" width="130" align="right"/>
      <vxe-column title="资产净值" field="netValue" width="130" align="right"/>
      <vxe-column title="操作" align="center" width="200" fixed="right">
        <template #default="{row}">
          <div class="actions">
            <span @click="showForm(row)">编辑</span>
            <!-- <span  @click="$emit('getdata',row)">编辑</span> -->
           
            <span @click="showChangeForm(row)" v-if="!row.disposeType && !row.canEdit">变更</span>
            <DropdownMenu
                v-if="!row.disposeType && row.isVoucher"
                class="mr-10px"
                :datas="{'SELL':'出售','SCRAP':'报废','OTHER':'其他'}"
                :toggle-icon="false"
                class-name="h-text-dropdown"
                placement="left-start"
                @clickItem="dispose($event,row)"
            >
              <span>处置</span>
            </DropdownMenu>
            <span @click="showDetail(row)">查看</span>
            <span @click="remove(row)" v-if="row.canEdit">删除</span>
          </div>
        </template>
      </vxe-column>
    </vxe-table>
  </div>
  <vxe-pager @page-change="onPageChange"
             v-model:current-page="pagination.page"
             v-model:page-size="pagination.pageSize"
             :total="pagination.total"
             :layouts="['PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'Sizes', 'FullJump', 'Total']">
    <template #left>
      <vxe-button @click="loadList()" type="text" size="mini" icon="fa fa-refresh" :loading="loading"></vxe-button>
    </template>
  </vxe-pager>
  
    <!-- <component is="AssetsForm2" :entity="{}" /> -->
   
</template>

<script>
/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
import {layer} from "@layui/layer-vue";
import {h} from "vue";
import {padStart} from "xe-utils";
import Assets from "@/api/Assets";
import AssetsForm from "@/views/assets/AssetsForm";
import AssetsForm2 from "@/views/assets/AssetsForm2";
import AssetsChangeForm from "@/views/assets/AssetsChangeForm";
import setting from "@/api/setting";
import Setting from "@/api/setting";
import {mapGetters, mapState} from "vuex";
import AssetsType from "@/api/AssetsType";
import {confirm, message} from "heyui.ext";
import AssetsView from "@/views/assets/AssetsView";
import AssetsImport from "@/views/assets/AssetsImport";
import {CopyObj} from "@/js/common/utils";
export default {
  name: "AssetsList",
  components: {
    AssetsForm2
		},
  data() {
    return {
      params: {
        entryDate: null,
        start: null,
        end: null,
        code: null,
        name: null,
        specification: null,
        assetsTypeId: null,
        deptId: null,
        supplier: null,
      },
      loading: false,
      showAll: false,
      checkDataList: [],
      dataList: [],
      deptList: [],
      supplierList: [],
      assetsTypeList: [],
      subjectList: [],
      pagination: {
        page: 1,
        pageSize: 20,
        total: 0
      }
    }
  },
  computed: {
    ...mapState(['currentAccountSets']),
    ...mapGetters(['checkoutSelect']),
    nextCode() {
      return padStart((this.pagination.total + 1) + "", 3, '0')
    },
    deptListMap() {
      let map = {};
      this.deptList.map(val => {
        map[val.key] = val.title;
      })
      return map;
    },
    subjectMap() {
      let map = {};
      this.subjectList.map(val => {
        map[val.code] = val.name;
      })
      return map;
    }
  },
  methods: {
    onPageChange() {
      this.loadList();
    },
    doSearch() {
      this.pagination.page = 1;
      this.loadList();
    },
    loadList() {
      this.loading = true
      Assets.list(Object.assign({}, this.params, this.pagination)).then(({data}) => {
        this.dataList = data.results || [];
        this.pagination.total = data.total;
      }).finally(() => this.loading = false)
      if (this.$refs.assetsParamsDropdown) {
        this.$refs.assetsParamsDropdown.hide()
      }
    },
    initSelect() {
      setting.accountingCategoryDetails.loadAuxiliaryAccountingDataByName(['DEPARTMENT', 'VENDOR']).then(({data}) => {
        this.deptList = data['DEPARTMENT'].map(val => {
          return {
            key: val.id,
            accountingCategoryId: val.categoryId,
            title: val.name
          }
        });
        this.supplierList = data['VENDOR'].map(val => {
          return {
            key: val.id,
            accountingCategoryId: val.accountingCategoryId,
            title: val.name
          }
        });
      })
      AssetsType.list().then(({data}) => {
        this.assetsTypeList = data || [];
      })
      Setting.subject.voucherSelect().then(({data}) => {
        this.subjectList = data || [];
      })
    },
    copyAssets() {
      let records = this.$refs.xTable.getCheckboxRecords().map(row => row.id);
      if (records.length) {
        this.loading = true;
        Assets.copy(records).then(() => {
          this.loadList();
        }).finally(() => this.loading = false)
      } else {
        message.warn("请至少选择一条记录~")
      }
    },
    removeCheckbox() {
      let records = this.$refs.xTable.getCheckboxRecords().map(row => row.id);
      if (records.length) {
        confirm({
          content: `确认删除这些记录?`,
          onConfirm: () => {
            this.loading = true;
            Assets.batchDelete(records).then(() => {
              this.loadList();
            }).finally(() => this.loading = false)
          }
        })
      } else {
        message.warn("请至少选择一条记录~")
      }
    },
    showForm(entity = null) {
      let AssetsForm = {
           entity,
          nextCode: this.nextCode,
          assetsTypeList: this.assetsTypeList,
          deptList: this.deptList,
          supplierList: this.supplierList,
          subjectList: this.subjectList,
          checkDate: this.checkDataList[0],
      }
      this.$emit('getdata',AssetsForm,true)
      // let layerId = layer.drawer({
      //   title: "固定资产信息",
      //   shadeClose: true,
      //   closeBtn: false,
      //   zIndex: 1000,
      //   area: ['calc(100vw - 140px)', '100vh'],
      //   content: h(AssetsForm, {
      //     entity,
      //     nextCode: this.nextCode,
      //     assetsTypeList: this.assetsTypeList,
      //     deptList: this.deptList,
      //     supplierList: this.supplierList,
      //     subjectList: this.subjectList,
      //     checkDate: this.checkDataList[0],
      //     onClose: () => {
      //       console.log('关闭')
      //       layer.close(layerId);
      //     },
      //     onSuccess: () => {
      //       this.loadList();
      //       layer.close(layerId);
      //     }
      //   })
      // });
    },
    showChangeForm(entity = null) {
      let layerId = layer.open({
        title: "变更资产信息",
        shadeClose: false,
        closeBtn: false,
        zIndex: 1000,
        area: ['600px', 'auto'],
        content: h(AssetsChangeForm, {
          entity, subjectList: this.subjectList, deptList: this.deptList,
          onClose: () => {
            layer.close(layerId);
          },
          onSuccess: () => {
            this.loadList();
            layer.close(layerId);
          }
        })
      });
    },
    remove(row) {
      this.$Confirm("确认删除?").then(() => {
        this.loading = true;
        Assets.delete(row.id).then(() => {
          this.loadList();
        }).finally(() => this.loading = true)
      })
    },
    reset() {
      Object.keys(this.params).forEach(key => this.params[key] = null)
      this.params.entryDate = this.checkDataList[0].key;
      this.loadList();
    },
    showDetail(row) {
      layer.drawer({
        title: "资产信息",
        zIndex: 1000,
        area: ['80vw', '100vh'],
        content: h(AssetsView, {
          assets: row
        })
      });
    },
    dispose(code, row) {
      confirm({
        title: "操作提示",
        content: "确定进行此操作吗?",
        async: true,
        onConfirm: (resolve) => {
          Assets.dispose(row.id, code).then(() => {
            resolve(true);
            this.loadList();
          }).catch(() => resolve(false))
        }
      })
    },
    exportAssets() {
      let layerId = layer.open({
        title: "资产导入",
        shadeClose: false,
        closeBtn: false,
        zIndex: 1000,
        area: ['500px', 'auto'],
        content: h(AssetsImport, {
          onClose: () => {
            layer.close(layerId);
          },
          onSuccess: () => {
            this.loadList();
            layer.close(layerId);
          }
        })
      });
    }
  },
  created() {
    this.initSelect();
    Assets.checkDate().then(({data}) => {
      this.checkDataList = data;
      this.params.entryDate = data[0].key;
      this.loadList();
    })
  }
}
</script>
<script setup>
import manba from 'manba'
</script>
