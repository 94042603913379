<template>
  <div class="h-full p-20px">
    <div class="h-full  flex flex-column">
      <Tabs :datas="{'收':'收入','支':'支出'}" class="px-20px bg-white-color" v-model="tab"/>
      <div class="flex p-16px justify-between bg-white-color">
        <div class="h-input-group">
          <span >输入编码或名称:</span>
          <Input style="margin-left: 10px;margin-right: 10px" placeholder="请输入编码或名称" v-model="filterVal"/>
          <Button icon="fa fa-search" color="primary" @click="loadList">查询</Button>
        </div>
        <Button @click="showForm()" color="primary" icon="fa fa-plus">新增</Button>
      </div>
      <div class="flex-1">
        <vxe-table height="auto" ref="xTable" :loading="loading" show-overflow stripe :data="typeData">
          <vxe-column title="编码" field="code" width="100"/>
          <vxe-column title="名称" field="name"/>
          <vxe-column title="操作" :width="150" align="center" width="200">
            <template #default="{row}">
              <div class="actions">
                <span @click="showForm(row)">编辑</span>
                <span @click="remove(row)">删除</span>
              </div>
            </template>
          </vxe-column>
        </vxe-table>
      </div>
    </div>
  </div>
</template>

<script>/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
import {layer} from "@layui/layer-vue";
import {h} from "vue";
import {padStart} from "xe-utils";
import CashierIoType from "@/api/CashierIoType";
import CashierIoTypeForm from "@/views/cashier/CashierIoTypeForm";

export default {
  name: "CashierIoType",
  data() {
    return {
      filterVal: null,
      tab: "收",
      loading: false,
      dataList: []
    }
  },
  computed: {
    typeData() {
      return this.dataList.filter(val => val.type === this.tab)
    },
    nextCode() {
      return padStart((this.typeData.length + 1) + "", 3, '0')
    }
  },
  methods: {
    loadList() {
      this.loading = true
      CashierIoType.list(this.filterVal).then(({data}) => {
        this.dataList = data || [];
      }).finally(() => this.loading = false)
    },
    showForm(entity = null) {
      let layerId = layer.open({
        title: this.tab + "类别信息",
        shadeClose: false,
        closeBtn: false,
        zIndex: 1000,
        area: ['400px', 'auto'],
        content: h(CashierIoTypeForm, {
          entity,
          type: this.tab,
          nextCode: this.nextCode,
          onClose: () => {
            layer.close(layerId);
          },
          onSuccess: () => {
            this.loadList();
            layer.close(layerId);
          }
        })
      });
    },
    remove(row) {
      this.$Confirm("确认删除?").then(() => {
        this.loading = true;
        CashierIoType.delete(row.id).then(() => {
          this.loadList();
        }).finally(() => this.loading = true)
      })
    }
  },
  created() {
    this.loadList();
  }
}
</script>
