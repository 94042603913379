<template>
  <div>
    <vxe-toolbar class-name="!px-8px" perfect>
      <template #buttons>
                                                  <!-- h-btn-primary -->
        <DropdownCustom ref="paramsDropdown" button class="" trigger="click">
          <div>{{ startDate }} 至 {{ endDate }}</div>
          <template #content>
            <Form style="padding: 10px" labelWidth="100" class="compact">
              <FormItem label="日期">
                <div class="flex items-center">
                  <DatePicker v-model="params.start" :clearable="false" :option="{end:params.end}"/>
                  <span class="mx-8px">至</span>
                  <DatePicker v-model="params.end" :clearable="false" :option="{start:params.start}"/>
                </div>
              </FormItem>
              <FormItem label="发票号码">
                <Input v-model="params.invoiceNumber"/>
              </FormItem>
              <FormItem label="是否生成凭证">
                <Select placeholder="全部" :datas="[{key:1,title:'是'},{key:0,title:'否'}]" v-model="params.voucherId"/>
              </FormItem>
              <FormItem label="发票状态">
                <Select placeholder="全部" :datas="[{key:'正常',title:'正常'},{key:'作废',title:'作废'}]" v-model="params.state"/>
              </FormItem>
              <FormItem label="发票种类">
                <Select placeholder="全部" :datas="categoryList" v-model="params.category"/>
              </FormItem>
              <FormItem label="客户名称">
                <Input v-model="params.customerName"/>
              </FormItem>
              <FormItem>
                <Button color="primary" @click="loadList();" :loading="loading">确定</Button>
                <Button @click="paramsDropdown.hide()">取消</Button>
                <Button @click="resetForm">重置</Button>
              </FormItem>
            </Form>
          </template>
        </DropdownCustom>
      </template>
      <template #tools>
        <DropdownMenu
            class="mr-12px"
            :datas="categoryList"
            :toggleIcon="false"
            @clickItem="showNewForm($event,null)">
          <vxe-button status="primary" icon="fa fa-plus">新增发票</vxe-button>
        </DropdownMenu>
        <vxe-button @click="$emit('download',{type:'IN',params})" icon="fa fa-download">导出</vxe-button>
        <vxe-button @click="$emit('import',{type:'IN',params})" icon="fa fa-upload">导入</vxe-button>
        <vxe-button @click="doAction('批量认证')" icon="fa fa-check">批量认证</vxe-button>
        <DropdownMenu
            class="mx-12px"
            :datas="['批量删除','指定业务类型']"
            :toggleIcon="false"
            @clickItem="doAction($event)">
          <vxe-button icon="fa fa-copy">批量操作</vxe-button>
        </DropdownMenu>
        <vxe-button @click="generateVoucher" icon="fa fa-file-o">生成凭证</vxe-button>
      </template>
    </vxe-toolbar>
    <div class="flex-1 ">
      <vxe-table :loading="loading" ref="xTable" :data="pageList.results" height="auto" >
        <vxe-column type="checkbox" width="50" align="center"/>
        <vxe-column title="发票种类" field="category" width="140"/>
        <vxe-column title="开票日期" field="tradeDate" width="100"/>
        <vxe-column title="发票号码" field="invoiceNumber" width="150"/>
        <vxe-column title="认证日期" field="checkDate" width="100"/>
        <vxe-column title="供应商名称" field="customerName" width="140"/>
        <vxe-column title="业务类型" field="businessType" width="100"/>
        <vxe-column title="合计金额" field="taxAmountExcluded" width="120" align="right" formatter="toMoney"/>
        <vxe-column title="税率" field="rate" align="right" width="120" :formatter="({cellValue})=>cellValue+'%'"/>
        <vxe-column title="合计税额" field="taxAmount" width="120" align="right" formatter="toMoney"/>
        <vxe-column title="价税合计" field="amount" width="120" align="right" formatter="toMoney"/>
        <vxe-column title="关联凭证" field="voucher" align="center" width="80">
          <template #default="{row}">
            <router-link class="text-hover primary-color" width="1024px"
                         v-if="row.voucherId"
                         :to="{name:'VoucherForm',params:{voucherId:row.voucherId}}">
              {{ row.voucher }}
            </router-link>
          </template>
        </vxe-column>
        <vxe-column title="操作" width="120" align="center">
          <template #default="{row}">
            <span v-if="!row.voucherId && !row.checkDate" @click="showNewForm(row.category,row)" class="text-hover mr-16px"> <i class="fa fa-edit"></i> 编辑</span>
            <span v-if="!row.voucherId && !row.checkDate" @click="remove([row])" class="text-hover"> <i class="fa fa-trash"></i> 删除</span>
          </template>
        </vxe-column>
      </vxe-table>
    </div>
    <vxe-pager @page-change="onPageChange"
               v-model:current-page="page.page"
               v-model:page-size="page.pageSize"
               :total="pageList.total"
               :layouts="['PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'Sizes', 'FullJump', 'Total']">
      <template #left>
        <vxe-button @click="loadList()" type="text" size="mini" icon="fa fa-refresh" :loading="loading"></vxe-button>
      </template>
    </vxe-pager>
  </div>
</template>

<script>
/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
export default {
  name: "IncomeInvoice"
}
</script>

<script setup>
import {h, reactive, ref, toRefs} from "vue";
import {layer} from "@layui/layer-vue";
import InvoiceForm from "@/views/invoice/InvoiceForm";
import {Invoice} from "@/api/Invoice";
import {confirm, message} from "heyui.ext";
import InvoiceChangeBusinessType from "@/views/invoice/InvoiceChangeBusinessType";
import InvoiceCheck from "@/views/invoice/InvoiceCheck";
import InvoiceGenerateVoucher from "@/views/invoice/InvoiceGenerateVoucher";
import manba from "manba";
import {useStore} from "vuex";

const store = useStore();

const currentAccountDate = manba(store.state.currentAccountSets.currentAccountDate).add(-1, manba.MONTH);
const startDate = ref(currentAccountDate.startOf(manba.MONTH).format());
const endDate = ref(currentAccountDate.endOf(manba.MONTH).format());

const params = reactive({
  start: startDate.value,
  end: endDate.value,
  voucherId: null,
  customerName: null,
  invoiceNumber: null,
  state: null,
  category: null,
});

const resetForm = () => {
  Object.assign(params, {
    start: startDate.value,
    end: endDate.value,
    voucherId: null,
    customerName: null,
    invoiceNumber: null,
    state: null,
    category: null,
  })
  loadList();
  paramsDropdown.value.hide();
}
const props = defineProps(['categoryList'])
const {categoryList} = toRefs(props)
const pageList = ref({
  results: [],
  total: 0,
});
const xTable = ref(null);
const paramsDropdown = ref(null);
const loading = ref(false);
const total = ref(0);
const page = reactive({
  page: 1,
  pageSize: 20
});

const showNewForm = (key, entity) => {
  let layerId = layer.drawer({
    title: key,
    shadeClose: false,
    closeBtn: false,
    zIndex: 1000,
    area: ['1200px', '100vh'],
    content: h(InvoiceForm, {
      entity,
      category: key,
      type: "IN",
      onClose: () => {
        layer.close(layerId);
      },
      onSuccess: () => {
        layer.close(layerId);
        loadList();
      }
    })
  });
}

const loadList = () => {
  loading.value = true;
  Invoice.list("IN", page, params).then(({data}) => {
    pageList.value = data;
    startDate.value = params.start;
    endDate.value = params.end;
  }).finally(() => loading.value = false)
}
loadList();

const onPageChange = ({currentPage, pageSize}) => {
  page.page = currentPage;
  page.pageSize = pageSize;
  loadList();
}

const remove = (rows) => {
  confirm({
    content: "确认删除?",
    async: true,
    onConfirm(resolve) {
      Invoice.remove(rows.map(val => val.id)).then(() => {
        resolve(true);
        loadList();
      }).catch(() => resolve(false))
    }
  })
}


const doAction = (action) => {
  const records = xTable.value.getCheckboxRecords();
  if (records.length) {
    switch (action) {
      case "批量删除":
        remove(records);
        break;
      case "指定业务类型":
      case "批量认证":
        let layerId = layer.open({
          title: action,
          shadeClose: false,
          closeBtn: false,
          zIndex: 1000,
          area: ['400px', 'auto'],
          content: h(action === '指定业务类型' ? InvoiceChangeBusinessType : InvoiceCheck, {
            rows: records,
            type: "IN",
            onClose: () => {
              layer.close(layerId);
            },
            onSuccess: () => {
              layer.close(layerId);
              loadList();
            }
          })
        });
        break;
    }
  }
}

const generateVoucher = () => {
  const records = xTable.value.getCheckboxRecords();
  if (records.length && records.every(val => !val.voucher)) {
    let layerId = layer.drawer({
      title: "生成凭证预览",
      shadeClose: false,
      zIndex: 1000,
      area: ['1100px', '100vh'],
      content: h(InvoiceGenerateVoucher, {
        records,
        type: "IN",
        onClose: () => {
          layer.close(layerId);
        },
        onSuccess: () => {
          layer.close(layerId);
          loadList();
        }
      })
    });
  } else {
    message.warn("没有选择需要生成凭证的记录~");
  }
}
</script>
