<template>
  <div class="modal-column">
    <div class="modal-column-body">
      <Form mode="block">
        <FormItem label="认证日期" prop="checkDate" required>
          <DatePicker v-model="checkDate"/>
        </FormItem>
      </Form>
    </div>
    <div class="modal-column-between">
      <vxe-button :loading="loading" @click="$emit('close')" icon="fa fa-close">取消</vxe-button>
      <vxe-button :loading="loading" :disabled="!checkDate" @click="doSubmit()" status="primary" icon="fa fa-check">保存</vxe-button>
    </div>
  </div>
</template>

<script>
import {Invoice} from "@/api/Invoice";
import {message} from "heyui.ext";

/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
export default {
  name: "InvoiceCheck",
  props: {
    rows: Array,
  },
  data() {
    return {
      loading: false,
      checkDate: null
    }
  },
  methods: {
    doSubmit() {
      this.loading = true;
      Invoice.batchCheck(this.checkDate, this.rows.map(val => val.id)).then(({data}) => {
        message(`本次提交处理【${this.rows.length}】条，成功处理【${data}】条~`)
        this.$emit('success')
      }).finally(() => this.loading = false)
    }
  }
}
</script>
