<template>
  <app-content class="h-panel">
    <div class="flex mb-16px justify-between items-center">
      <span class="h-panel-title">{{ template.name }} - 数据项</span>
      <Button color="primary" @click="showForm()">添加项目</Button>
    </div>
    <vxe-table :data="templateItems" :loading="loading">
      <vxe-column :width="60" align="center">
        <template #default="{row}">{{ row.isClassified ? '归类' : '' }}</template>
      </vxe-column>
      <vxe-column title="名称" field="title" width="200">
        <template #default="{row}">
          <div :class="{'font-bold':row.isBolder}" :style="{paddingLeft:((row.level-1)*15)+'px'}">{{ row.title }}</div>
        </template>
      </vxe-column>
      <vxe-column title="行次" align="center">
        <template #default="{row}">{{ row.isClassified ? '' : row.lineNum }}</template>
      </vxe-column>
      <vxe-column title="类型" v-if="template.type == 1">
        <template #default="{row}">{{ dictMapping(row.isClassified ? '' : row.type, 'reportTemplateItemType') }}</template>
      </vxe-column>
      <vxe-column title="取数来源">
        <template #default="{row}">{{ dictMapping(row.isClassified ? '' : row.sources, 'reportTemplateItemSources') }}</template>
      </vxe-column>
      <vxe-column title="显示位置" prop="pos" :width="80" align="center"></vxe-column>
      <vxe-column width="160" align="center">
        <template #default="{row}">
          <div class="actions">
            <span @click="showForm(row)">添加</span>
            <span @click="showForm({id:row.parentId},row)">编辑</span>
            <span v-if="!row.isClassified" @click="showFormulaModal(row)">公式</span>
            <span @click="remove(row)">删除</span>
          </div>
        </template>
      </vxe-column>
    </vxe-table>
  </app-content>
</template>

<script>

import TemplateItemModal from "./TemplateItemModal";
import TemplateItemFormulaModal from "./TemplateItemFormulaModal";
import {layer} from "@layui/layer-vue";
import {h} from "vue";

export default {
  name: "TemplateForm",
  props: {
    templateId: [Number, String]
  },
  data() {
    return {
      loading: false,
      template: {},
      templateItems: [],
    }
  },
  methods: {
    showForm(parent, org) {
      let layerId = layer.open({
        title: "编辑项目",
        shadeClose: false,
        closeBtn: false,
        zIndex: 999,
        area: ['800px', 'auto'],
        content: h(TemplateItemModal, {
          parent, org, template: this.template, templateItems: this.templateItems,
          onClose: () => {
            layer.close(layerId);
          },
          onSuccess: () => {
            this.loadTemplate();
            layer.close(layerId);
          }
        })
      });
    },
    showFormulaModal(item) {
      let layerId = layer.open({
        title: `编辑公式 - ${item.title}`,
        shadeClose: false,
        closeBtn: false,
        zIndex: 999,
        area: ['800px', 'auto'],
        content: h(TemplateItemFormulaModal, {
          templateItem: item, type: this.template.type, templateItems: this.templateItems,
          onClose: () => {
            layer.close(layerId);
          },
          onSuccess: () => {
            this.loadTemplate();
            layer.close(layerId);
          }
        })
      });
    },
    loadTemplate() {
      this.loading = true;
      Api.report.template.load(this.templateId).then(({data}) => {
        this.template = data || {};
        this.templateItems = data.items || [];
      }).finally(() => this.loading = false);
    },
    remove(row) {
      this.$Confirm("确认删除?").then(() => {
        this.loading = true;
        Api.report.template.items.delete(row.id).then(() => {
          this.loadTemplate();
        }).finally(() => this.loading = false);
      })
    }
  },
  created() {
    this.loadTemplate()
  }
}
</script>

