<template>
  <div class="flex-1">
    <div style="margin: 10px;color: #FEA503;height: 40px;">
      亲，点击“计提折旧”即可生成折旧明细表，点击“生成凭证”即可生成折旧凭证！灰色表示不能操作，如有疑问，请将鼠标移至灰字上面，查看提示操作。
    </div>
    <!-- height ="auto" -->
    <vxe-table   ref="xTable" 
               :checkbox-config="{checkMethod:checkMethod}"
               :loading="loading" show-overflow stripe :data="dataList">
      <vxe-column type="checkbox" width="60"/>
      <vxe-column title="会计期间" field="checkDate"/>
      <vxe-column title="新增资产" field="newStatus" :formatter="({cellValue})=>['无数据','有数据，未生成凭证','有数据，已生成所有凭证'][cellValue]"/>
      <vxe-column title="变更记录" field="updateStatus" :formatter="({cellValue})=>['无数据','有数据，未生成凭证','有数据，已生成所有凭证'][cellValue]"/>
      <vxe-column title="计提折旧" field="status" :formatter="({cellValue})=>['','无数据','已计算折旧','','无需折旧'][cellValue]"/>
      <vxe-column title="折旧凭证" field="voucherTitle" width="100" align="center">
        <template #default="{row}">
          <router-link v-if="row.voucherId" class="text-hover primary-color" width="1024px"
                       :to="{name:'VoucherForm',params:{voucherId:row.voucherId}}">{{ row.voucherTitle }}
          </router-link>
          <span v-else>{{ row.voucherTitle }}</span>
        </template>
      </vxe-column>
      <vxe-column title="操作" :width="150" align="center" width="110" fixed="right">
        <template #default="{row}">
          <div class="actions">
            <span v-if="!row.voucherId && row.status===1" @click="depreciation(row)">计提折旧</span>
            <span v-if="row.cancel &&  row.status===2" @click="cancelDepreciation(row)">取消折旧</span>
            <span v-if="!row.voucherId && row.status===2" @click="onClickItem(row)">生成凭证</span>
          </div>
        </template>
      </vxe-column>
    </vxe-table>
  </div>
  <vxe-pager @page-change="loadList"
             v-model:current-page="pagination.page"
             v-model:page-size="pagination.pageSize"
             :total="pagination.total"
             :layouts="['PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'Sizes', 'FullJump', 'Total']">
    <template #left>
      <vxe-button @click="loadList()" type="text" size="mini" icon="fa fa-refresh" :loading="loading"></vxe-button>
    </template>
  </vxe-pager>
</template>

<script>
/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */

import {layer} from "@layui/layer-vue";
import {h} from "vue";
import {padStart} from "xe-utils";
import Assets from "@/api/Assets";
import VoucherForm from "@/views/voucher/VoucherForm";
import {message} from "heyui.ext";

export default {
  name: "AssetsDepreciationDetails",
  data() {
    return {
      params: {
        filterVal: null,
      },
      loading: false,
      dataList: [],
      pagination: {
        page: 1,
        pageSize: 20,
        total: 0
      }
    }
  },
  computed: {
    queryParams() {
      return Object.assign(this.params, {
        page: this.pagination.page,
        pageSize: this.pagination.pageSize
      })
    },
    nextCode() {
      return padStart((this.pagination.total + 1) + "", 3, '0')
    }
  },
  methods: {
    onClickItem(row) {
      const loadId = layer.load(2);
      Assets.Depreciation.generateVoucher(row.id).then(({data}) => {
        if (!data.length) {
          message("本期无需生成凭证~")
          this.loadList();
          return
        }
        let layerId = layer.drawer({
          title: "凭证预览",
          shadeClose: false,
          zIndex: 1000,
          area: ['1200px', '100vh'],
          content: h(VoucherForm, {
            assetsData: data, voucherDate: (row.checkDate + "01").replace(/[年月]/ig, '-'),
            onClose: () => {
              layer.close(layerId);
            },
            onSuccess: (voucherId) => {
              layer.load(2);
              Assets.Depreciation.relateVoucher(row.id, voucherId).then(() => {
                this.loadList();
              }).finally(() => layer.closeAll())
            }
          })
        });
      }).finally(() => layer.close(loadId))
    },
    checkMethod({row}) {
      return !row.voucherId;
    },
    pageChange() {
      this.loadList();
    },
    doSearch() {
      this.pagination.page = 1;
      this.loadList();
    },
    loadList() {
      this.loading = true
      Assets.Depreciation.list(this.queryParams).then(({data}) => {
        this.dataList = data.results || [];
        this.pagination.total = data.total || 0;
      }).finally(() => this.loading = false)
    },
    depreciation(row) {
      this.loading = true
      Assets.Depreciation.depreciation(row.id).then(({data}) => {
        this.loadList();
      }).finally(() => this.loading = false)
    },
    cancelDepreciation(row) {
      this.loading = true
      Assets.Depreciation.cancel(row.id).then(({data}) => {
        this.loadList();
      }).finally(() => this.loading = false)
    }
  },
  created() {
    this.loadList();
  }
}
</script>
