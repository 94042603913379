<template>
	<div id="app">
		<Layout class="app-frame " :class='currentAccountSets ?"":"suoxiao"'   :siderCollapsed="sliderCollapsed" :siderFixed="layoutConfig.siderFixed">
			<Sider :theme="layoutConfig.siderTheme" style="background: #FFF;">
				<appMenu :theme="layoutConfig.siderTheme"></appMenu>
			</Sider>
			<Layout :headerFixed="layoutConfig.headerFixed">
				<HHeader theme="white">
					<appHead @openSetting="openSetting=true" @getcolType="getcolType" :layoutConfig="layoutConfig"></appHead>
				</HHeader>
				<SysTabs v-if="layoutConfig.showSystab" homePage="Home"></SysTabs>
				<Content class="flex flex-column">
					<div v-if="!LOGINSTATUS" class="bg-white-color">
						<!-- 导航栏 -->
						<!-- <Tabs class-name="h-tabs-card px-8px mt-8px" :datas="tabs" v-model="selectedTab" @clickItem="tabClick">
							<template #item="{tab}">
								<span>{{ tab.title }}2</span>
								<i @click.stop="closeTab(tab.key)" class="h-icon-close-min" v-if="tab.key!=='Home'"></i>
							</template>
						</Tabs> -->
						<!--  -->
						    <!-- 用来占位 -->
						    <div class='tabAllfixed'></div>
							<!-- 用来占位 -->
							<div class='tabAll' :class='sliderCollapsed ? "close":""'>
								<div class='hometab' @click="tabClick(tabs[0],0)" :class='currentTab == "Home" ?"activeHome" + coltype :""'>首页</div>
								<div class='TabCss' ref="scroll" @wheel.native.prevent="handleScroll">
									<div class='TabItem'  
									:style="{marginLeft:(index == 1 ?'0':'')+'px'}"
									 v-for='tab,index in tabs' :key='index'

									 draggable="true"
									 @dragstart="tab.key != 'Home' &&  handleDragStart($event, tab)"
									 @dragover.prevent="tab.key != 'Home' &&  handleDragOver($event, tab)"
								 	 @dragenter="tab.key != 'Home' &&  handleDragEnter($event, tab)"
									 @dragend="tab.key != 'Home' &&  handleDragEnd($event, tab)"
									 @contextmenu.prevent.native="openMenu($event,tab)"
									 v-show="index !== 0"
									 :class='currentTab == tab.key ?"active"+ coltype :""'>
										<span @click="tabClick(tab,index)" >{{ tab.title }}</span>
										<i @click.stop="closeTab(tab.key)" class="h-icon-close-min" v-if="tab.key!=='Home'"></i>										
									</div>
								</div>
							</div>
							<ul :style="{right:10+'px',top:62+'px'}" class="moreMenu" v-if='tablen>0'>
								<li @click.native='showMore = !showMore'>···</li>
							</ul>
							<ul  :style="{right:10+'px',top:110+'px'}" class="HideMenu" v-show='showMore' v-if='tablen>0 && showHead'>
								<li v-for='tab,index in tabs' 
								   v-show="index<(tabs.length - tablen)"
								    :key='index' @click="tabClick(tab,index)">
									{{ tab.title }}
								</li>
							</ul>
							<ul  :style="{right:10+'px',top:110+'px'}" class="HideMenu" v-show='showMore' v-if='tablen>0 && !showHead'>
								<li v-for='tab,index in tabs' 
								   v-show="index >= tablen"
								    :key='index' @click="tabClick(tab,index)">
									{{ tab.title }}
								</li>
							</ul>

							<ul v-show="visible" :style="{left:left+'px',top:top+'px'}" class="contextMenu">
								<li @click.stop="CloseCurTab('cur',selectItem)">关闭当前</li>
								<li @click.stop="CloseCurTab('other',selectItem)">关闭其他</li>
								<li @click.stop="CloseCurTab('all',selectItem)">关闭所有</li>
							</ul>

						<!--  -->
					</div>
					
					<div class="app-frame-content flex1" :class='currentAccountSets ?"":"suoxiao2"' style="height: 0;overflow-y: auto;background-color: #fff;margin: 10px;border-radius: 10px;" >
						<template v-for="tab in tabs">
							<component v-show="currentTab === tab.key" is="ReportView" :is="tab.params.dimension" :dimension="tab.params.dimension" :reportId="tab.params.reportId" v-if="tab.key.startsWith('ReportView')"/>
							<component v-show="currentTab === tab.key" :is="tab.key" v-else/>
						</template>
					</div>
				</Content>
			</Layout>
		</Layout>
	</div>
</template>
<script>

	import appHead from './app/app-header';
	import appMenu from './app/app-menu';
	import appFooter from './app/app-footer';
	import {mapGetters, mapMutations, mapState} from 'vuex';
	import SysTabs from './app/sys-tabs/sys-tabs';
	
	const tagAndTagSpacing = 4 
	export default {
		name: "FXY",
		data() {
			return {
				openSetting: false,
				selectedTab: "Home",
				isRouterAlive: true,
				layoutConfig: {
					siderTheme: 'dark',
					showSystab: false,
					headerFixed: true,
					siderFixed: true
				},
				LOGINSTATUS: null,
				listWidth:[],
				current: 0,
				
				clientWidth:0,
				scrollTop:null,
				isScroll:null,
				selectItem:{},
				visible: false,
				top: 0,
                left: 0,
				dragging: null,
				ending: null,
				tablen:0,
				showHead:false,
				showMore:false,
				coltype:1,

				
			};
		},
		watch: {
			currentTab(val) {
				this.showMore = false;
				this.visible = false;
				this.selectedTab = val;
				// console.log(this.selectedTab,'this.selectedTab')
				localStorage.setItem("currentTab", JSON.stringify(this.tabMap[val]));
				let that = this
				this.$nextTick(() => {
					that.tabs.forEach((item,index)=>{
                     if(this.selectedTab == item.key){
						that.changeModel(index)
					 }
				})
					
				});
				
				// 仅展示
				location.hash = val;
			},
			visible(value) {
                    if (value) {
                        document.body.addEventListener('click', this.closeMenu)
                    } else {
                        document.body.removeEventListener('click', this.closeMenu)
                    }
                }

		},
		mounted() {
		

			this.$nextTick(() => {
			 
			  this.getBoxWidth();
			  
			
			});
		},
		
		methods: {

			

			
			...mapMutations(['changeTab', 'newTab']),
			updateLayoutConfig({key, value}) {
				this.layoutConfig[key] = value;
			},
			closeTab(key) {
				console.log(this.currentTab,'this.currentTab')
				if (key === 'CloseAllTab') {
					// tableData为原数组，row.id为将要删除的对象的id
					// 使用过滤器筛选出id不等于要删除的数据的所有对象，并将其重新赋值给list数组
					this.tableData = this.tabs.filter((item) => {
						return item.key !== 'Home' && item.key !== 'CloseAllTab';
					});
					this.tableData.forEach(item => {
						this.$store.commit('closeTab', item.key);
					})
					this.$store.commit('refreshHomeTab', {key: "Home", title: "首页"})
					this.$store.commit('changeTab', 'Home');
				} else {
					this.$store.commit('closeTab', key);

					// if (this.currentTab === 'CloseAllTab') {
					// 	this.$store.commit('refreshHomeTab', {key: "Home", title: "首页"})
					// 	this.$store.commit('changeTab', 'Home');
					// }
				}
			},
			tabClick({key},index) {
				// this.moveToTarget(item)
				this.changeModel(index)
				if (key !== 'CloseAllTab') {
					if (this.currentTab !== key) {
						if (key === 'Home') {
							this.$store.commit('refreshHomeTab', {key: "Home", title: "首页"})
						}
						this.$store.commit('changeTab', key);
					}
				}
			},
			// 计算tab到右边的位置
			  getBoxWidth() {
				this.clientWidth = this.$refs.scroll.clientWidth;
				// setTimeout(() => {
				//延时计算元素的宽度
				let rightItems = this.$refs.scroll.getElementsByClassName("TabItem"); //获取指定类名的所有元素
				let width = 0;
				this.listWidth =[]
				let islen = true
				for (let i = 0; i < rightItems.length; i++) {
					let item = rightItems[i]; // 右边的每一个模块（蓝色标题 + 标题下面所带的内容）
					width += item.clientWidth - 20;
					this.listWidth.push(width); // 把右边模块内容的高度全都放到一个数组中
                    if(islen){
						// 让其只执一次
						if(width + 20 >this.clientWidth){
							islen = false;
							this.tablen = i
							console.log(this.tablen,'this.this.tablen')
						}
					}

				}
				// width为所有子的宽度集合，
				if(width + 20 <this.clientWidth){
					// 滚动条出现
					this.tablen = 0
					islen = true
					console.log('滚动条隐藏')
				}
			
			
			  },
				changeModel(index) {
					this.getBoxWidth()
					// console.log(index,this.clientWidth,'index')
					if (index == 0) {
						this.$refs.scroll.scrollLeft = 0;
					} else {
						// console.log(this.clientWidth,'this.clientWidth')
						let width = this.listWidth[index] + 100
						let l =  width -this.clientWidth
						// let s = 0
						// console.log(l,s,'llll')
						// l是滚动条宽度
						if(l < -220){
							this.$refs.scroll.scrollLeft = 0;
						}else{
							this.$refs.scroll.scrollLeft = l + this.clientWidth/2
							// console.log(l + this.clientWidth/2,'l + this.clientWidth/2')
						}
						
					
					}
				},
              
				// tab滚轮横向滚动
				handleScroll(e) {
				
					const eventDelta = e.wheelDelta || -e.deltaY * 40
					const $scrollWrapper = this.$refs.scroll
					$scrollWrapper.scrollLeft = $scrollWrapper.scrollLeft + eventDelta / 4

					if($scrollWrapper.scrollLeft  == 0){
						this.showHead = false
					}else{
						this.showHead = true
					}
               },

			//    鼠标右键事件
			   openMenu(e,item) {
                    console.log("e",e);
                    console.log("item",item);
                    let x = e.clientX;
                    let y = e.clientY;
                    this.top = y+20;
                    this.left = x;
                    this.visible = true;
					this.selectItem = item
                },
                closeMenu() {
                    this.visible = false;
                },
				CloseCurTab(type,item){
                    if(type == 'cur'){
                           this.closeTab(item.key)
					}else if(type == 'other'){
						this.$store.commit('closeOther', item.key);
					}else if(type == 'all'){
						  this.closeTab('CloseAllTab')
					}
					this.visible = false
				},
				// ----
			
				 // 交换顶部tagsview方法
				 handleDragStart (e, item) {
				// console.log(item,'1')
				  this.dragging = item
				},
				handleDragEnd (e, item) {
				if (this.ending.key === this.dragging.key) {
					return
				}
				let newItems = [...this.tabs]
				const src = newItems.indexOf(this.dragging)
				const dst = newItems.indexOf(this.ending)
				newItems.splice(src, 1, ...newItems.splice(dst, 1, newItems[src]))

				// 更新仓库 visitedViews 的值
				this.$store.commit('SetTab', newItems);
				this.tabs = []
				
				this.$nextTick(() => {
					this.dragging = null
					this.ending = null
				})
				
				},
				handleDragOver (e) {
				// 首先把div变成可以放置的元素，即重写dragenter/dragover
				e.dataTransfer.dropEffect = 'move'// e.dataTransfer.dropEffect="move";//在dragenter中针对放置目标来设置!
				},
				handleDragEnter (e, item) {
				// console.log(item,'3')
				e.dataTransfer.effectAllowed = 'move'// 为需要移动的元素设置dragstart事件
				this.ending = item
				},
				// 交换顶部tagsview方法


				// 切换颜色
				getcolType(type){
                    this.coltype = type
				},


		},
		computed: {
			...mapState(['sliderCollapsed', 'tabs', 'currentTab', 'menus','currentAccountSets']),
			...mapGetters(['tabMap'])
		},
		components: {
			appHead,
			appMenu,
			appFooter,
			SysTabs
		},
		created() {
			this.LOGINSTATUS = localStorage.getItem("LOGINSTATUS")
			const currentTab = localStorage.getItem("currentTab")
			console.log(currentTab,'currentTab')
			if (currentTab) {
				this.newTab(JSON.parse(currentTab));
			}
		}
	};
</script>
<style scoped lang="less">

	.h-icon-close-min {
		margin-left: 1px;
		font-weight: bold;

		&:hover {
			color: #f60 !important;
		}
	}

	
	// 表格行高
	/deep/ .vxe-table .vxe-body--column.col--ellipsis {
		height: 60px !important;
	}
	// 表头颜色
	/deep/ .vxe-table--header-wrapper,.vxe-header--row,.vxe-table--header{
		background-color: #FAFAFA !important;
		font-size: 12px !important;
		font-weight: bold !important;
		color: #333333 !important;
	}
   
	/deep/  .h-icon-search{
		color: var(--primary) !important;
	}
	// 全局input框
	/deep/ .h-input{
		border-radius: 3px !important;
	}
	
    // 全局selcct下拉
	/deep/ .h-select-show{
		border-radius: 5px !important;
		// border: none!important;
	}
	
	
	// 全局按钮
	/deep/ .h-btn{
		border-radius: 5px !important;
	}
	
	// 表头行高
    /deep/  .vxe-table--header .vxe-header--row {
		height: 60px !important;
	//   line-height: 60px !important;  /* 这里可以根据需求进行调整 */
	}
	/deep/ .titlecss{
		font-size: 18px  !important;
		font-weight: 400 !important;
		color: #333333 !important;
		padding-left: 20px !important;
		border-left: 4px solid var(--primary) !important;
		margin:  10px 0 ;
	}
	.suoxiao{
		width:1000px;
		height:500px ;
		overflow:hidden;
		// background:#ccc;
		margin:0 auto;
	}
	.suoxiao2{
		width:900px;
		height:400px !important;
		display:flex;
		align-items:center;
		background:none !important;
	}
	.tabAllfixed{
		display:flex;
		align-items:center;
		width:100%;
		height:50px;
		// background:#000;
	}
	.tabAll{
		display:flex;
		align-items:center;
		width:calc(100vw - @layout-sider-width);
		height:50px;
		background:#fff;
		position:fixed;
		top:60px;
		left:@layout-sider-width;
		z-index:999;
		&.close{
			width:calc(100vw - @layout-sider-collapse-width);
			left:@layout-sider-collapse-width;
		}
	}
	.hometab{
		cursor:pointer;
		display:inline-block;
		padding:0 20px 0 20px;
		// width:50px;
		height:40px;
		line-height:40px;
		// background:#ccc;
		background:url(../assets/tipshome.png);
		background-size:100% 100%;
		// position:relative;
		// z-index:999;
		margin-bottom:10px;
		&.active {
			background:url(../assets/tips-home1.png);
	     	background-size:100% 100%;
		}
		&.activeHome1 {
			// background-color: #42b983;
			color: var(--theme_bg_color);
			background: url(../assets/tips-home1.png);
			background-size: 100% 100%;
			color:#fff;
		}
		&.activeHome2 {
			// background-color: #42b983;
			color: var(--theme_bg_color);
			background: url(../assets/tips-home2.png);
			background-size: 100% 100%;
			color:#fff;
		}
		&.activeHome3 {
			// background-color: #42b983;
			color: var(--theme_bg_color);
			background: url(../assets/tips-home3.png);
			background-size: 100% 100%;
			color:#fff;
		}
		&.activeHome4 {
			// background-color: #42b983;
			color: var(--theme_bg_color);
			background: url(../assets/tips-home4.png);
			background-size: 100% 100%;
			color:#fff;
		}
		&.activeHome5 {
			// background-color: #42b983;
			color: var(--theme_bg_color);
			background: url(../assets/tips-home5.png);
			background-size: 100% 100%;
			color:#fff;
		}
		&.activeHome6 {
			// background-color: #42b983;
			color: var(--theme_bg_color);
			background: url(../assets/tips-home6.png);
			background-size: 100% 100%;
			color:#fff;
		}
		&.activeHome7 {
			// background-color: #42b983;
			color: var(--theme_bg_color);
			background: url(../assets/tips-home7.png);
			background-size: 100% 100%;
			color:#fff;
		}
		
		&.activeHome8 {
			// background-color: #42b983;
			color: var(--theme_bg_color);
			background: url(../assets/tips-home8.png);
			background-size: 100% 100%;
			color:#fff;
		}
		
	}
	.TabCss{
		
		width:calc(100% - 120px);
		// width:200px;
		height:50px;
		// background:#ccc;
		margin:0 10px;
		display:flex;
		overflow:auto;
		margin-left:-10px;
		
	}
	.TabItem{
		
		height:40px;
		line-height:40px;
		display:inline-block;
		// width:80px;
		white-space:nowrap;
		padding:0 20px 0 20px;
		// background:#006dfd;
		// margin:0 5px;
		background:url(../assets/tips.png);
		background-size:100% 100%;
		margin-left:-20px;
		span{
			cursor:pointer;
		}
		&.active{
			background:url(../assets/tips-s1.png);
			background-size:100% 100%;
			color:#fff;
		}
		&.active1 {
			background: url(../assets/tips-s1.png);
			background-size: 100% 100%;
			color:#fff;
		}
		&.active2 {
			background: url(../assets/tips-s2.png);
			background-size: 100% 100%;
			color:#fff;
		}
		&.active3 {
			background: url(../assets/tips-s3.png);
			background-size: 100% 100%;
			color:#fff;
		}
		&.active4 {
			background: url(../assets/tips-s4.png);
			background-size: 100% 100%;
			color:#fff;
		}
		&.active5 {
			background: url(../assets/tips-s5.png);
			background-size: 100% 100%;
			color:#fff;
		}
		&.active6 {
			background: url(../assets/tips-s6.png);
			background-size: 100% 100%;
			color:#fff;
		}
		&.active7 {
			background: url(../assets/tips-s7.png);
			background-size: 100% 100%;
			color:#fff;
		}
		&.active8 {
			background: url(../assets/tips-s8.png);
			background-size: 100% 100%;
			color:#fff;
		}
		
	}
	
	
.contextMenu{
	margin: 0;
	background: #fff;
	z-index: 3000;
	position: fixed;
	list-style-type: none;
	padding: 5px 0;
	border-radius: 4px;
	font-size: 12px;
	font-weight: 400;
	color: #333;
	box-shadow: 0px 0px 10px 1px rgba(0, 21, 41, 0.2);
	text-align: center;
	// width: 5%;
	// background:#ccc;
}
.contextMenu li{
	// padding:0 5px;
	white-space:nowrap;
	cursor:pointer;
	margin:8px;
}
.moreMenu{
	margin: 0;
	background: #fff;
	z-index: 3000;
	position: fixed;
	list-style-type: none;
	// padding: 5px 0;
	border-radius: 4px;
	font-size: 12px;
	font-weight: 400;
	color: #333;
	// box-shadow: 0px 0px 10px 1px rgba(0, 21, 41, 0.2);
	text-align: center;
	// width: 5%;
	// background:#ccc;
}
.moreMenu li{
	// padding:0 5px;
	white-space:nowrap;
	cursor:pointer;
	margin:0 8px ;
	font-size:24px;
	font-weight:bold;
}
.HideMenu{
	margin: 0;
	background: #fff;
	z-index: 3000;
	position: fixed;
	list-style-type: none;
	// padding: 5px 0;
	border-radius: 4px;
	font-size: 12px;
	// font-weight: 400;
	color: #333;
	box-shadow: 0px 0px 10px 1px rgba(0, 21, 41, 0.2);
	text-align: center;
}
.HideMenu li{
	// padding:0 5px;
	white-space:nowrap;
	cursor:pointer;
	margin:4px 8px ;
	font-size:14px;
}

</style>
