<template>
  <app-content class="h-panel">
    <div class="h-panel-bar">
      <span class="h-panel-title">科目汇总</span>
    </div>
    <div class="margin-right-left margin-top">
      <DropdownCustom ref="paramsDropdown" button class="" trigger="click">
        <div v-if="params.start!==params.end">{{ fqFormat(params.start) }} 至 {{ fqFormat(params.end) }}</div>
        <div v-else>{{ fqFormat(params.start) }}</div>
        <template #content>
          <Form style="padding: 10px;width: 400px">
            <FormItem class="!pb-5px" label="会计期间">
              <div class="flex items-center">
                <Select v-model="params.start" :deletable="false" filterable :datas="checkoutSelect"/>
                <span class="mx-8px">至</span>
                <Select v-model="params.end" :deletable="false" filterable :datas="checkoutSelect"/>
              </div>
            </FormItem>
            <FormItem class="!pb-5px" label="起始科目">
              <Select :datas="subjectList" :filter="sfilter" keyName="code" titleName="subjectFullName" v-model.number="params.subjectStart"/>
            </FormItem>
            <FormItem class="!pb-5px" label="结束科目">
              <Select :datas="subjectList" :filter="sfilter" keyName="code" titleName="subjectFullName" v-model.number="params.subjectEnd"/>
            </FormItem>
            <FormItem class="!pb-5px" label="科目级别">
              <div class="flex items-center">
                <Select v-model="params.levelStart" :deletable="false" filterable :datas="[1,2,3,4]"/>
                <span class="mx-8px">至</span>
                <Select v-model="params.levelEnd" :deletable="false" filterable :datas="[1,2,3,4]"/>
              </div>
            </FormItem>
            <FormItem class="!pb-5px">
              <Button color="primary" @click="loadList();" :loading="loading">确定</Button>
              <Button @click="this.$refs.paramsDropdown.hide()">取消</Button>
              <Button @click="resetForm">重置</Button>
            </FormItem>
          </Form>
        </template>
      </DropdownCustom>
      <div class="float-right flex items-center">
        <Checkbox class="mr-16px" v-model="showNumPrice">显示数量金额</Checkbox>
        <vxe-button @click="exportData" icon="fa fa-download" content="导出"/>
        <vxe-button @click="printData" icon="fa fa-print" content="打印"/>
      </div>
    </div>
    <div class="h-panel-body" type="flex">
      <div class="tablezero">
         <span>科目：所有</span>
         <span>凭证数量：6张 &nbsp; 附件数：0张</span>
      </div>
	    <vxe-table ref="xTable" :data="dataList" v-if="!showNumPrice" :tree-config="{transform: true, expandAll:true,showIcon:false,rowField: 'treeId', parentField: 'parentTreeId'}" >
		    <vxe-column title="科目编码" field="subjectCode" tree-node/>
		    <vxe-column title="科目名称" field="subjectName"/>
		    <vxe-colgroup title="金额合计" align="center">
			    <vxe-column title="借方" field="debit" align="right" formatter="toMoney"/>
			    <vxe-column title="贷方" field="credit" align="right" formatter="toMoney"/>
		    </vxe-colgroup>
	    </vxe-table>
	    <vxe-table ref="xTable" :data="dataList" v-else :tree-config="{transform: true, expandAll:true,showIcon:false,rowField: 'treeId', parentField: 'parentTreeId'}" >
		    <vxe-column title="科目编码" field="subjectCode" tree-node/>
		    <vxe-column title="科目名称" field="subjectName"/>
		    <vxe-column title="单位" field="unit"/>
		    <vxe-colgroup title="金额合计" align="center">
			    <vxe-column title="借方" field="debit" align="right" formatter="toMoney"/>
			    <vxe-column title="贷方" field="credit" align="right" formatter="toMoney"/>
		    </vxe-colgroup>
		    <vxe-colgroup title="数量合计" align="center">
			    <vxe-column title="借方" field="debitQuantity" align="right"/>
			    <vxe-column title="贷方" field="creditQuantity" align="right"/>
		    </vxe-colgroup>
	    </vxe-table>
    </div>
  </app-content>
</template>

<script>

import BooUtil from "@/views/accountbook/bookUtil";
import accountbook from "@/api/accountbook";
import {downloadBlob} from "download.js";
import manba from "manba";
import {mapGetters, mapState} from "vuex";
import {clone} from "xe-utils";
import setting from "@/api/setting";

export default {
  name: "SubjectSummary",
  data() {
    return {
      dataList: [],
      showNumPrice: false,
      loading: false,
      params: {
	      start: null,
	      end: null,
	      currencyId: null,
	      levelStart: 1,
	      levelEnd: 4,
	      subjectStart: null,
	      subjectEnd: null,
      }
    }
  },
  computed: {
	  ...mapState(['currentAccountSets']),
	  ...mapGetters(['checkoutSelect', 'localCurrency']),
  },
  watch: {
    showNumPrice() {
      this.loadList();
    }
  },
  methods: {
    resetForm() {
      this.params = clone(this.cloneParams, true)
    },
    sfilter(item, val) {
      return item.code.indexOf(val) !== -1 || item.name.indexOf(val) !== -1 || item.mnemonicCode.indexOf(val) !== -1;
    },
    loadList() {
      this.loading = true;
      accountbook.loadSubjectSummary(Object.assign({}, this.params, {
        showNumPrice: this.showNumPrice
      })).then(({data}) => {
	      this.dataList = data;
	      this.$nextTick(() => {
		      this.$refs.xTable.setAllTreeExpand(true);
	      });
      }).finally(() => {
        this.loading = false;
      })
    },
    exportData() {
      const utils = new BooUtil(this.$refs.xTable, '\n')
      accountbook.download({header: utils.header, dataList: utils.dataList}).then((data) => {
        downloadBlob(`${manba(this.accountDate).format("YYYY年MM期")}-科目汇总.xls`, data)
      })
    },
    printData() {
      const utils = new BooUtil(this.$refs.xTable)
      const title = `${manba(this.accountDate).format("YYYY年MM期")}-科目汇总`
      accountbook.print({content: utils.printContent(title, this.currentAccountSets.companyName), rotate: this.showNumPrice}).then((data) => {
        const url = URL.createObjectURL(data);
        window.open(url, '账簿')
      })
    }
  },
  created() {
    this.params.start = manba(this.currentAccountSets.currentAccountDate).format("YYYY-M");
	  this.params.end = this.params.start;
	  this.params.currencyId = this.localCurrency.id;
	  this.cloneParams = clone(this.params, true);
    setting.subject.voucherSelect().then(({data}) => {
      this.subjectList = data;
    })
    this.loadList();
  }
}
</script>
