<template>
  <app-content class="h-panel">
    <div class="flex justify-between p-20px pb-0px">
      <div class="flex">
        <DropdownCustom ref="paramsDropdown" button class=" mr-20px" trigger="click">
          <div v-if="params.start!==params.end">{{ fqFormat(params.start) }} 至 {{ fqFormat(params.end) }}</div>
          <div v-else>{{ fqFormat(params.start) }}</div>
          <template #content>
            <Form style="padding: 10px;width: 400px">
              <FormItem class="!pb-5px" label="会计期间">
                <div class="flex items-center">
                  <Select v-model="params.start" :deletable="false" :datas="checkoutSelect"/>
                  <span class="mx-8px">至</span>
                  <Select v-model="params.end" :deletable="false" :datas="checkoutSelect"/>
                </div>
              </FormItem>
              <FormItem class="!pb-5px" label="科目">
                <Select :datas="subjectList" :filter="filter" keyName="id" titleName="fullName"
                        v-model="params.subjectId">
                  <template #show="{value}">
                    {{ value.code }} {{ value.fullName }}
                  </template>
                  <template #item="{item}">
                    {{ item.code }} {{ item.fullName }}
                  </template>
                </Select>
              </FormItem>
              <FormItem class="!pb-5px" label="科目级别">
                <div class="flex items-center">
                  <Select v-model="params.levelStart" :deletable="false" filterable :datas="[1,2,3,4]"/>
                  <span class="mx-8px">至</span>
                  <Select v-model="params.levelEnd" :deletable="false" filterable :datas="[1,2,3,4]"/>
                </div>
              </FormItem>
              <FormItem class="!pb-5px">
                <Button color="primary" @click="loadList();" :loading="loading">确定</Button>
                <Button @click="this.$refs.paramsDropdown.hide()">取消</Button>
                <Button @click="resetForm">重置</Button>
              </FormItem>
            </Form>
          </template>
        </DropdownCustom>
        <div class="flex" style="align-items: center;">
          <span>辅助类别：</span>
          <Select type="object" v-model="auxiliary" :deletable="false" style="min-width: 100px" :datas="auxiliaryType"
                keyName="id" titleName="name"/>
        </div>
        <Button class="ml-16px" color="primary" @click="loadList();" :loading="loading">查询</Button>

       
      </div>
      <div class=" flex items-center">
        <Checkbox class="mr-16px" v-model="showNumPrice">显示数量金额</Checkbox>
        <vxe-button @click="exportData" icon="fa fa-download" content="导出"/>
        <vxe-button @click="printData" icon="fa fa-print" content="打印"/>
      </div>
    </div>
    <div class="h-panel-body flex">
      <div class="flex-1 mr-16px">
        <vxe-table ref="xTable" :data="dataList"  :loading="loading">
          <vxe-column title="日期" field="voucherDate" :width="100"></vxe-column>
          <vxe-column title="凭证字号" :width="85">
            <template #default="{row}">
              <router-link @success="loadAuxiliaryList()" class="text-hover primary-color" width="1200px"
                           v-if="row.word" :to="{name:'VoucherForm',params:{voucherId:row.voucherId}}">{{
                  row.word
                }}-{{ row.code }}
              </router-link>
            </template>
          </vxe-column>
          <vxe-column title="摘要" field="summary"></vxe-column>
          <vxe-column title="借方" align="right" :width="120">
            <template #default="{row}">
              {{ numFormat(row.debitAmount) }}
            </template>
          </vxe-column>
          <vxe-column title="贷方" align="right" :width="120">
            <template #default="{row}">
              {{ numFormat(row.creditAmount) }}
            </template>
          </vxe-column>
          <vxe-column title="方向" field="balanceDirection" align="center" :width="50"></vxe-column>
          <vxe-column title="余额" align="right" :width="120">
            <template #default="{row}">
              {{ numFormat(row.balance) }}
            </template>
          </vxe-column>
        </vxe-table>
      </div>
      <div class="w-200px">
        <Tree @select="doSelect" :option="param" ref="auxiliary" filterable selectOnClick
              className="h-tree-theme-row-selected"></Tree>
      </div>
    </div>
  </app-content>
</template>

<script>
import BooUtil from "@/views/accountbook/bookUtil";
import accountbook from "@/api/accountbook";
import {downloadBlob} from "download.js";
import manba from "manba";
import {mapGetters, mapState} from "vuex";
import {clone} from "xe-utils";
import setting from "@/api/setting";

export default {
  name: "AuxiliaryAccountingDetail",
  data() {
    return {
      dataList: [],
      subjectList: [],
      loading: false,
      showNumPrice: false,
      param: {
        keyName: 'id',
        parentName: 'parentId',
        titleName: 'name',
        dataMode: 'list',
        datas: []
      },
      params: {
        start: null,
        end: null,
        levelStart: 1,
        levelEnd: 4,
        currencyId: null,
        subjectId: null,
      },
      accountDate: null,
      auxiliary: null,
      auxiliaryItem: null,
      auxiliaryType: []
    }
  },
  computed: {
    ...mapState(['currentAccountSets']),
    ...mapGetters(['checkoutSelect', 'localCurrency']),
    queryParams() {
      return Object.assign({}, this.params, {
        categoryType: this.auxiliary ? this.auxiliary.categoryType : null,
        categoryId: this.auxiliary ? this.auxiliary.id : null,
        detailId: this.auxiliaryItem ? this.auxiliaryItem.id : null
      })
    },
    queryAuxiliaryParams() {
      return Object.assign({}, this.params, {
        categoryType: this.auxiliary ? this.auxiliary.categoryType : null,
        categoryId: this.auxiliary ? this.auxiliary.id : null
      })
    }
  },
  watch: {
    auxiliary() {
      this.loadAuxiliaryList();
    }
  },
  methods: {
    filter(item, val) {
      return item.code.indexOf(val) !== -1 || item.name.indexOf(val) !== -1 || item.mnemonicCode.indexOf(val) !== -1;
    },
    loadAuxiliaryType() {
      setting.accountingCategory.list().then(({data}) => {
        this.auxiliaryType = data;
        if (data) {
          this.auxiliary = data[0];
        }
      });
    },
    loadList() {
      this.loading = true;
      accountbook.loadAuxiliaryDetails(this.queryParams).then(({data}) => {
        this.dataList = data;
      }).finally(() => {
        this.loading = false;
      });
    },
    loadAuxiliaryList() {
      this.dataList = [];
      accountbook.loadAuxiliary(this.queryAuxiliaryParams).then(({data}) => {
        data.forEach(val => val.name = val.code + " " + val.name);
        data = data.sort((a, b) => a.code.localeCompare(b.code));
        this.param.datas = data;
        if (data.length) {
          this.auxiliaryItem = data[0];
          this.loadList();
          this.$nextTick(() => {
            this.$refs.auxiliary.updateSelect(this.auxiliaryItem.id);
          });
        }
      });
    },
    doSearch() {
      this.loadAuxiliaryList();
    },
    doSelect(node) {
      this.auxiliaryItem = node;
      this.params.detailId = node.id;
      this.loadList();
    },
    exportData() {
      const utils = new BooUtil(this.$refs.xTable, '\n')
      accountbook.download({header: utils.header, dataList: utils.dataList}).then((data) => {
        downloadBlob(`${this.auxiliary ? this.auxiliary.name + '-' : ''}${this.auxiliaryItem ? this.auxiliaryItem.name + '-' : ''}${manba(this.accountDate).format("YYYY年MM期")}-明细账.xls`, data)
      })
    },
    printData() {
      const utils = new BooUtil(this.$refs.xTable)
      const title = `${this.auxiliary ? this.auxiliary.name + '-' : ''}${this.auxiliaryItem ? this.auxiliaryItem.name + '-' : ''}${manba(this.accountDate).format("YYYY年MM期")}-明细账`
      accountbook.print({
        content: utils.printContent(title, this.currentAccountSets.companyName),
        rotate: this.showNumPrice
      }).then((data) => {
        const url = URL.createObjectURL(data);
        window.open(url, '账簿')
      })
    }
  },
  mounted() {
    this.params.start = manba(this.currentAccountSets.currentAccountDate).format("YYYY-M");
    this.params.end = this.params.start;
    this.params.currencyId = this.localCurrency.id;
    this.cloneParams = clone(this.params, true)
    this.loadAuxiliaryType();
    setting.subject.voucherSelect({all: true}).then(({data}) => {
      this.subjectList = data;
    })
  }
}
</script>
