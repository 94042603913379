<template>
    <div class="h-full p-20px">
        <div class="h-full  flex flex-column">
            <div style="display: flex;justify-content: space-between">
                <Tabs :datas="{'备份':'备份数据包','文件':'文件下载中心','Excel':'归档Excel','PDF':'归档PDF','审计':'审计对接'}"
                      v-model="ioType"
                      class="bg-white-color px-16px"/>
                <div style="display:flex;justify-content: center;align-items: center;">
                    <Checkbox>启用自动备份，数据永不丢失</Checkbox>
                    <div style="color: #2FA8FD;margin-left: 10px">设置</div>
                    <div style="margin-left: 10px">
                        已用空间(6075MB/10.00MB)
                    </div>
                </div>
            </div>

            <div v-if="ioType=='备份'" class="h-full  flex flex-column">
                <vxe-toolbar class="px-16px">
                    <template #buttons>
                        <div style="font-size: 16px;color: #999999">
                            亲，备份的数据包是加密不能查看的，下载后即可上传，并且支持恢复数据包中的数据哦
                        </div>
                    </template>
                    <template #tools>
                        <Button :loading="dataLoading" @click="fileInput.click();" color="primary">上传本地备份</Button>
                        <Button :loading="dataLoading" @click="backUp" color="primary">开始备份</Button>
                        <input accept="application/zip" type="file" @change="uploadBackUp" ref="fileInput"
                               style="position: absolute;z-index: -1"/>
                    </template>
                </vxe-toolbar>
                <div class="flex-1">
                    <vxe-table border="none" height="auto" ref="xTable" :loading="dataLoading" show-overflow stripe
                               :data="dataList">
                        <vxe-column title="备份名称" field="title" width="400"/>
                        <vxe-column title="备份日期" field="bakTime"/>
                        <vxe-column title="文件大小" field="size"/>
                        <vxe-column title="操作人" field="userName"/>
                        <vxe-column title="操作" :width="150" align="center" width="200">
                            <template #default="{row}">
                                <div class="actions">
                                    <span @click="recover(row)">恢复</span>
                                    <span @click="remove(row)">删除</span>
                                    <a :href="'/api/backup/download/'+row.id"
                                       :download="'（数据包加密不能查看，仅供数据恢复使用）'+row.title">下载</a>
                                </div>
                            </template>
                        </vxe-column>
                    </vxe-table>
                </div>
                <div style="display:flex;flex-direction: column;color: #333333;font-size: 16px;margin-bottom: 20px">
                    <div>备份数据包、归档Excel、归档PDF有什么区别？</div>
                    <div style="margin-top: 10px">备份数据包 “备份”整个账套的数据 （<span style="color: #2FA8FD"> 支持下载到本地和数据恢复</span>，
                        不支持在线预览 ）
                    </div>
                    <div style="margin-top: 10px">
                        归档Excel 和 归档PDF 将整个账套的数据归档成Excel或PDF文件 （ <span style="color: #2FA8FD"> 支持下载到本地</span>
                        ， 不支持在线预览和数据恢复 ）10.00MB)
                    </div>
                </div>
            </div>

            <div v-else-if="ioType=='审计'" class="h-full  flex flex-column">
                <div v-if="!afterAuth" class="h-full  flex flex-column" style="justify-content: center;align-items: center">
                    <Form
                            ref="form"
                            :labelPosition="right"
                            :labelWidth="120">
                        <FormItem label="审计软件:">
                            <Select :deletable="false" :datas="['鼎信诺','言必信']"/>
                        </FormItem>
                        <FormItem label="审计时间:">
                            <div class="flex items-center">
                                <DatePicker/>
                                <span class="mx-8px">至</span>
                                <DatePicker/>
                            </div>
                        </FormItem>
                    </Form>
                    <Button style="width: 120px" :loading="dataLoading" @click="afterAuth=true" color="primary">一件提取</Button>
                </div>
                <div v-else>
                        <vxe-toolbar class="px-16px">
                            <template #tools>
                                <Button :loading="dataLoading" @click="backUp" color="primary">一键领取</Button>
                            </template>
                        </vxe-toolbar>
                        <div class="flex-1">
                            <vxe-table border="none" height="auto" ref="xTable" :loading="dataLoading" show-overflow stripe
                                       :data="dataList">
                                <vxe-column title="备份名称" field="title" width="400"/>
                                <vxe-column title="备份日期" field="bakTime"/>
                                <vxe-column title="文件大小" field="size"/>
                                <vxe-column title="操作人" field="userName"/>
                                <vxe-column title="操作" :width="150" align="center" width="200">
                                    <template #default="{row}">
                                        <div class="actions">
                                            <span @click="recover(row)">恢复</span>
                                            <span @click="remove(row)">删除</span>
                                            <a :href="'/api/backup/download/'+row.id"
                                               :download="'（数据包加密不能查看，仅供数据恢复使用）'+row.title">下载</a>
                                        </div>
                                    </template>
                                </vxe-column>
                            </vxe-table>
                        </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
export default {
    name: "BakUp"
}
</script>

<script setup>
import {confirm, loading} from "heyui.ext";
import {ref, h} from "vue";
import setting from "@/api/setting";
import RecoverForm from "@/views/setting/RecoverForm";
import {layer} from "@layui/layer-vue";

const dataLoading = ref(false)
const afterAuth = ref(false)
const dataList = ref([])
const fileInput = ref(null)
const ioType = ref('备份')

const loadList = () => {
    dataLoading.value = true;
    setting.backup.list().then(({data}) => {
        dataList.value = data;
    }).finally(() => dataLoading.value = false)
}
loadList();

const backUp = () => {
    loading("备份中...")
    setting.backup.backup().then(() => {
        loadList();
    }).finally(() => loading.close())
}

const remove = (row) => {
    confirm({
        content: "确认删除此备份?",
        async: true,
        onConfirm(resolve) {
            loading("删除中...")
            setting.backup.delete(row.id).then(() => {
                loadList();
            }).finally(() => {
                loading.close();
                resolve(true);
            })
        }
    })
}

const recover = (entity) => {
    let layerId = layer.open({
        title: "恢复数据",
        shadeClose: false,
        closeBtn: false,
        zIndex: 1000,
        area: ['550px', 'auto'],
        content: h(RecoverForm, {
            entity,
            onClose: () => {
                layer.close(layerId);
            }
        })
    });
}

const uploadBackUp = () => {
    if (fileInput.value.files.length) {
        loading("上传中...")
        const formData = new FormData();
        formData.append("file", fileInput.value.files[0]);
        setting.backup.upload(formData).then(() => {
            loadList();
        }).finally(() => loading.close())
    }

}
</script>
