<template>
    <div class="h-full p-20px">
        <div class="h-full  flex flex-column">
            <Tabs :datas="['基础设置','高级设置']" class="px-20px bg-white-color" v-model="tab"/>
            <Form v-if="showBasic()" style="padding: 10px;" :labelWidth="120">
                <FormItem class="!pb-10px" label="打印选项">
                    <Radio v-model="basicSet.printSelect"
                           :datas="{code:'打印全部凭证',voucher_date:'打印选中凭证'}"></Radio>
                </FormItem>
                <FormItem class="!pb-10px" label="选择打印纸张">
                    <div class="flex items-center">
                        <div style="width: 300px;margin-right: 10px">
                            <Select v-model="basicSet.printPaperSelect" :deletable="false"/>
                        </div>
                        <Radio v-model="basicSet.printRalaSelect" :datas="{code:'局中',voucher_date:'靠上'}"></Radio>
                    </div>
                </FormItem>
                <FormItem class="!pb-10px" label="打印版凭证行">
                    <Select v-model="basicSet.printLine" :deletable="false" :datas="['5','6','7','8','9','10']"/>
                </FormItem>
                <FormItem class="!pb-10px" label="打印字体大小">
                    <Select v-model="basicSet.printFrontSize" :deletable="false" :datas="['5','6','7','8','9','10']"/>
                </FormItem>
                <FormItem class="!pb-10px" label="打印方向">
                    <Select v-model="basicSet.printDiract" :deletable="false"
                            :datas="{code:'纵向',voucher_date:'横向'}"/>
                </FormItem>
                <FormItem class="!pb-10px" label="页面边距">
                    <div class="h-full  flex flex-column">
                        <div class="h-full" style=" display: flex;">
                            <div style="display: flex">
                                <span>左</span>
                                <div style="width: 100px;margin-right: 5px;margin-left: 5px">
                                    <Input v-model="basicSet.printMarginLeft"/>
                                </div>
                                <span>厘米</span>
                            </div>
                            <div style="display: flex;margin-left: 50px">
                                <span>右</span>
                                <div style="width: 100px;margin-right: 5px;margin-left: 5px">
                                    <Input v-model="basicSet.printMarginRight"/>
                                </div>
                                <span>厘米</span>
                            </div>
                        </div>
                        <div class="h-full" style=" display: flex;margin-top: 10px">
                            <div style="display: flex">
                                <span>上</span>
                                <div style="width: 100px;margin-right: 5px;margin-left: 5px">
                                    <Input v-model="basicSet.printMarginTop"/>
                                </div>
                                <span>厘米</span>
                            </div>
                            <div style="display: flex;margin-left: 50px">
                                <span>下</span>
                                <div style="width: 100px;margin-right: 5px;margin-left: 5px">
                                    <Input v-model="basicSet.printMarginBottom"/>
                                </div>
                                <span>厘米</span>
                            </div>
                        </div>
                    </div>
                </FormItem>
            </Form>

            <Form v-else style="padding: 10px;" :labelWidth="0">
                <FormItem class="!pb-10px" label=" ">
                    <div class=" flex items-center">
                        <Checkbox class="mr-16px" v-model="advanceSet.printerCreateName">打印制单人姓名</Checkbox>
                        <Checkbox class="mr-16px" v-model="advanceSet.printerAutiName">打印审核人姓名</Checkbox>
                        <Checkbox class="mr-16px" v-model="advanceSet.printerFacePage">打印封面</Checkbox>
                    </div>
                </FormItem>
                <FormItem class="!pb-10px" label=" ">
                    <div class=" flex items-center">
                        <Checkbox class="mr-16px" v-model="advanceSet.printLeaderNameChecked">打印主管姓名</Checkbox>
                        <div style="width: 200px;margin-right: 5px;margin-left: 5px">
                            <Input v-model="advanceSet.printLeaderName"/>
                        </div>
                    </div>
                </FormItem>
                <FormItem class="!pb-10px" label=" ">
                    <div class=" flex items-center">
                        <Checkbox class="mr-16px" v-model="advanceSet.printRemeberNameChecked">打印记账姓名</Checkbox>
                        <div style="width: 200px;margin-right: 5px;margin-left: 5px">
                            <Input v-model="advanceSet.printRemeberName"/>
                        </div>
                    </div>
                </FormItem>
                <FormItem class="!pb-10px" label=" ">
                    <div class=" flex items-center">
                        <Checkbox class="mr-16px" v-model="advanceSet.printOutNameChecked">打印出纳姓名</Checkbox>
                        <div style="width: 200px;margin-right: 5px;margin-left: 5px">
                            <Input v-model="advanceSet.printOutName"/>
                        </div>
                    </div>
                </FormItem>
                <FormItem class="!pb-10px" label=" ">
                    <Checkbox class="mr-16px" v-model="advanceSet.printRate">在摘要中打印显示原币、汇率</Checkbox>
                </FormItem>
                <FormItem class="!pb-10px" label=" ">
                    <Checkbox class="mr-16px" v-model="advanceSet.printAmountPrice">在摘要中打印显示数量、单价</Checkbox>
                </FormItem>
                <FormItem class="!pb-10px" label=" ">
                    <Checkbox class="mr-16px" v-model="advanceSet.printZero">无附件时打印“0”</Checkbox>
                </FormItem>
                <FormItem class="!pb-10px" label=" ">
                    <Checkbox class="mr-16px" v-model="advanceSet.printSummary">汇总打印</Checkbox>
                </FormItem>
            </Form>


            <div style="display: flex;align-items: center">
                <span style="color: #FF7979;font-size: 16px">提示:</span>
                <span style="color: #333333;font-size: 16px">为了保证您的正常打印，请先下载安装</span>
                <span style="color: #2FA8FD;font-size: 16px">Adobe PDF阅读器</span>
            </div>
            <div style="display: flex;align-items: center;justify-content: end;margin-top: 20px">
                <Button @click="$emit('close')">取消</Button>
                <Button color="primary" @click="$emit('close')">确定</Button>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: "VoucherPrinterSetting",
    data() {
        return {
            filterVal: null,
            tab: "基础设置",
            loading: false,
            dataList: [],
            basicSet: {
                printSelect: undefined,
                printPaperSelect: undefined,
                printRalaSelect: undefined,
                printLine: undefined,
                printFrontSize: undefined,
                printDiract: undefined,
                printMarginTop: undefined,
                printMarginLeft: undefined,
                printMarginBottom: undefined,
                printMarginRight: undefined,
            },
            advanceSet: {
                printerCreateName: undefined,
                printerAutiName: undefined,
                printerFacePage: undefined,
                printLeaderNameChecked: undefined,
                printLeaderName: undefined,
                printRemeberNameChecked: undefined,
                printRemeberName: undefined,
                printOutNameChecked: undefined,
                printOutName: undefined,
                printRate: undefined,
                printAmountPrice: undefined,
                printZero: undefined,
                printSummary: undefined,
            }
        }
    },
    computed: {},
    watch: {},
    methods: {
        showBasic() {
            return this.tab === '基础设置';
        },
    },
    created() {
    }
}
</script>
