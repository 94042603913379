<template>
    <app-content style="position: relative;">
        <Loading text="操作中~" :loading="loading"></Loading>
        <div class="prevPzBtn" v-if="voucherId && showPrev" title="上一张" @click="prevPzBtn()"></div>
        <div class="h-panel" style="width: 1035px;margin: 0 auto;padding-top: 20px;">
            <div class="padding-right-left">
                <Row type="flex" :space-x="10">
                    <Cell>
                        <Button :disabled="!canSave" color="primary" @click="save(false)" :loading="loading">
                            保存
                        </Button>
                    </Cell>
                    <Cell v-if="form.id">
                        <a class="h-btn" :href="`/api/pdf/voucher?id=${form.id}`" target="_blank">打印</a>
                    </Cell>
                </Row>
                <Row class="margin-top" type="flex" :space-x="10">
                    <Cell>
                        <Select keyName="word" titleName="word" style="min-width: 70px" :deletable="false" :datas="voucherWords" v-model="form.word" placeholder="记"/>
                    </Cell>
                    <Cell class="label">
                        <NumberInput :min="1" v-model="form.code" style="display: inline-block" :disabled="true"/>
                        号
                    </Cell>
                    <Cell class="label">
                        日期：
                    </Cell>
                    <Cell class="label">
                        <DatePicker :disabled="true" :option="dpOps" :clearable="false" v-model="form.voucherDate" format="YYYY-MM-DD"/>
                    </Cell>
                    <Cell class="label flex items-center">
                        <div class="ml-16px h-input-group w-150px">
                            <span class="h-input-addon">附单据</span>
                            <Input v-model.number="form.receiptNum"/>
                            <span class="h-input-addon">张</span>
                        </div>
                        <DropdownCustom :toggle-icon="false" class-name="h-text-dropdown" placement="bottom-end">
                          <span class="text-hover blue-color font-bold ml-16px">
                            <i class="fa fa-files-o"></i>
                            附件
                          </span>
                        </DropdownCustom>
                    </Cell>
                </Row>
            </div>
            <VoucherTable2 ref="voucherTable2" :is-edit="!!form.id" @update="onItemsUpdate" :voucherDetails="voucherDetails"/>
            <div class="padding-right-left padding-bottom">
                制单人：{{ User.realName }}
            </div>
        </div>
        <div class="nextPzBtn" v-if="voucherId && showNext" title="下一张" @click="nextPzBtn()"></div>
    </app-content>
</template>

<script>
    import VoucherTable2 from "../../components/VoucherTable/index";
    import {mapGetters, mapState} from 'vuex';
    import {h} from 'vue';
    import TemplateForm from "@/views/report/template/TemplateForm";
    import {message} from "heyui.ext";
    import manba from "manba";
    import Common from "@/api/common";
    import {layer} from "@layui/layer-vue";
    import VoucherTplForm from "@/views/voucher/VoucherTplForm.vue";
    import VoucherTplList from "@/views/voucher/VoucherTplList.vue";
    import setting from "../../api/setting";
    import voucher from "@api/voucher";
    import checkout from "@api/checkout";
    import {add, floor} from "xe-utils";

    const accept = "image/*,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/msword,application/pdf,text/plain,application/vnd.openxmlformats-officedocument.wordprocessingml.document"

    export default {
        name: "CheckVoucherForm",
        components: {TemplateForm, VoucherTable2: VoucherTable2},
        props: {
            dataId: String,
            dataIds: Array,
            voucherDate: String,
            type: Object,
            checkTempData: Array
        },
        computed: {
            ...mapState(['User', 'currentAccountSets', 'tabs']),
            ...mapGetters(['firstCheckoutDate', 'localCurrency']),
            canSave() {
                return this.voucherItems.length > 0
            },
        },
        data() {
            return {
                accept,
                loading: false,
                carryForward: false,
                voucherWords: [],
                voucherItems: [],
                voucherDetails: [],
                voucherTable: {voucherItems: []},
                dpOps: {
                    start: manba().format(),
                    end: null
                },
                voucher: {},
                form: {
                    id: null,
                    remark: '',
                    billList: [],
                    word: '',
                    code: '',
                    voucherDate: '',
                    receiptNum: 0,
                    carryForward: false,
                    checkTplId: null,
                },
                voucherId: null,
                voucherIds: [],
                showPrev: false,
                showNext: false,
                nowIndex: 0
            }
        },

        methods: {
            onItemsUpdate(val) {
                this.voucherItems = val;
                this.voucherTable.jfTotal = floor(val.reduce((total, row) => add(total, (row.debitAmount || 0)), 0), 2)
                this.voucherTable.dfTotal = floor(val.reduce((total, row) => add(total, (row.creditAmount || 0)), 0), 2)
            },

            loadVoucherWords() {
                setting.voucherWord.list().then(({data}) => {
                    let newData = [];
                    data.forEach(info => {
                        if (info.word.includes('记')) {
                            newData.push(info);
                        }
                    })
                    this.voucherWords = newData || [];
                })
            },

            loadCode() {
                if (!this.voucherId) {
                    this.form.voucherDate = manba(this.currentAccountSets.currentAccountDate).endOf(manba.MONTH).format();
                    voucher.loadCode({
                        word: '记',
                        currentAccountDate: this.form.voucherDate
                    }).then(({data}) => {
                        this.form.code = data
                    })
                }
            },

            formValid() {
                if (!this.form.voucherDate) {
                    this.$Message("亲，请选择日期！");
                    return false;
                }

                if (!this.form.code) {
                    this.$Message("亲，请输入编号！");
                    return false;
                }

                if (!this.voucherItems.length) {
                    this.$Message("亲，第1行不能为空！");
                    return false;
                }

                if (this.checkItem("摘要", 'summary') || this.checkItem("科目", 'subjectId') || this.checkItem("金额")) {
                    return false;
                }

                if (this.voucherTable.jfTotal !== this.voucherTable.dfTotal) {
                    this.$Message("亲，借贷不平衡！");
                    return false;
                }
                return true;
            },

            save() {
                if (!this.formValid()) {
                    return
                }
                let formData = Object.assign({}, this.form, {
                    details: this.voucherItems,
                    carryForward: this.carryForward,
                    createMember: this.User.id
                });
                if (this.type) {
                    formData.checkTplId = this.type.id;
                }
                this.loading = true;
                voucher[this.voucherId ? 'update' : 'save'](formData).then(({success, data}) => {
                    if (success) {
                        message("保存成功~")
                        this.loading = false;
                        this.$emit('success')
                    }
                }).catch(() => {
                    this.loading = false;
                }).finally(() => this.loading = false);
            },

            checkItem(name, field) {
                let i = 0, len = this.voucherItems.length, row = -1;
                for (; i < len; i++) {
                    if ((field && !this.voucherItems[i][field]) || (!field && !this.voucherItems[i].debitAmount && !this.voucherItems[i].creditAmount)) {
                        row = i + 1;
                        break;
                    }
                }

                if (row > -1) {
                    this.$Message(`亲，第${row}行，请输入${name}！`);
                    return true;
                }
            },

            init() {
                if (this.voucherId) {
                    this.loading = true;
                    voucher.load(this.voucherId).then(({data}) => {
                        data.details.forEach(val => {
                            val.originalCreditAmount = val.creditAmount;
                            val.originalDebitAmount = val.debitAmount;
                        })
                        this.voucherDetails = data.details;
                        this.voucher = data;
                        this.form = {
                            id: data.id,
                            auditMemberId: data.auditMemberId,
                            word: data.word,
                            remark: data.remark,
                            voucherDate: data.voucherDate,
                            carryForward: data.carryForward,
                            billList: data.billList || [],
                            code: data.code
                        };
                    }).finally(() => this.loading = false)
                }
            },

            checkTemp() {
                this.loadVoucherWords();
                this.loadCode();
                if (this.checkTempData) {
                    let details = [];
                    this.checkTempData.forEach(info => {
                        details.push({
                            summary: info.summary,
                            subjectId: info.subjectId,
                            subjectName: info.subjectTitle,
                            currencyId: this.localCurrency.id,
                            debitAmount: info.debitAmount || 0,
                            creditAmount: info.creditAmount || 0,
                        });
                    })
                    this.voucherDetails = details
                }
            },

            prevPzBtn() {
                if (this.nowIndex - 1 >= 0) {
                    this.nowIndex -= 1;
                    this.voucherId = this.voucherIds[this.nowIndex].voucherId;
                    this.init();
                    this.checkTemp();
                    if (this.nowIndex === 0) {
                        this.showPrev = false;
                        this.showNext = true;
                    }
                }
            },
            nextPzBtn() {
                if (this.nowIndex + 1 <= (this.voucherIds.length - 1)) {
                    this.nowIndex += 1;
                    this.voucherId = this.voucherIds[this.nowIndex].voucherId;
                    this.init();
                    this.checkTemp();
                    if (this.nowIndex === (this.voucherIds.length - 1)) {
                        this.showNext = false;
                        this.showPrev = true;
                    }
                }
            }
        },
        created() {
            if (this.dataId && this.dataIds) {
                this.voucherId = this.dataId;
                this.voucherIds = this.dataIds;
                if (this.dataIds.length > 1) {
                    this.showNext = true;
                }
            }
            this.init();
            this.checkTemp();
        }
    }
</script>

<style scoped>
    .h-panel {
        border: 1px solid #D3D3D3;
    }
    .prevPzBtn {
        background: url('../../assets/arrow4-left.png') center no-repeat;
        position: absolute;
        width: 30px;
        height: 72px;
        top: 40%;
        cursor: pointer;
    }
    .nextPzBtn {
        right: 0;
        background: url('../../assets/arrow4-right.png') center no-repeat;
        position: absolute;
        width: 30px;
        height: 72px;
        top: 40%;
        cursor: pointer;
    }
</style>
