<template>
	<div class="login-body" v-if='loginsuc'> 
		
		<!-- <div class="login-top-bar">
			<div class="login-topbar-header">
				<a class="topbar-header-log" href="/" target="_self">
					<img class="img" src="../assets/logos.png" alt="缔造梦">
				</a>
				<h2 class="login-topbar-title">缔造梦云财务</h2>
				<a href="http://www.jhqkg.com" target="_blank" class="sub-topic-text">官方网站</a>
				<a @click="showImage(imageStatus)" class="sub-topic-text">联系我们</a>
			</div>
			<img @click="showImage(imageStatus)" v-if="imageStatus" class="contact" src="../assets/contactus.jpg" alt="联系我们">
		</div> -->
		<div class="login-main-content" >
			<img v-if="!isCodeLogin" @click="isCodeLogin = !isCodeLogin"   class="fiximg" src="../assets/EwmLogin.png" alt="" >
			<img v-if="isCodeLogin" @click="isCodeLogin = !isCodeLogin"  class="fiximg" src="../assets/pc.png" alt="" >
			<!-- <img  class="fiximg" src="../assets/pc.png" alt="" > -->
			<div class="login-main-text"></div>
			<div class="root-login">
				<div class="login-title">
					{{ APP_NAME }}
					
				</div>
				<div class="login-title">
					<div>
						<!-- <slide-verify
						:l="42"
						:r="10"
						:w="310"
						:h="155"
						slider-text="向右滑动"
						@success="onSuccess"
						@fail="onFail"
						@refresh="onRefresh"
						></slide-verify> -->
						
						
					</div>
				</div>
				<component v-if="isCodeLogin"   is="EwmLogin" @changeView="changeView"/>
				<component v-if="!isCodeLogin" :is="viewName" @changeView="changeView" @toSuc='toSuc' />
				<!-- <div class='Sliderbox'>
					<span class='SliderTxt'>{{slidertype?'向右滑动完成验证':'验证完成'}}</span>
					<Slider v-model="msg" id='Slidercss' class='Slidercss' :showtip="false"  @change='changesi' :range='{start: 0, end: 100}'></Slider>
				</div> -->
			</div>
		</div>
		


		<div class="copyright">
			官网首页 隐私政策 联系我们 2023 qwh365.com All Rights Reserved
			Powered by  {{ APP_NAME }} 
			<!-- {{ year }} -->
		</div>
	</div>
	<div  v-if='!loginsuc' class='suclogin'>
		<div  class="login2">
			
			<div class="regstitle">
				恭喜您已注册成功!
			</div>
			<!-- <div class="regstitle2 fontcol">
			(登录密码已发送至您的手机和邮箱，请查收)
			</div> -->
		<div class="rowall">
			<Row  class="rowbg"  type="flex" justify="space-around">
				<Cell :width="18" >
						<div class="regstitle3">为更好地为您提供【试用答疑】服务，您可以选择：</div>
						
						<div class="checklist">
							<Radio style='margin-bottom:16px' :value='param[0]' v-model="value" ><span style="font-size: 18px;">A.立刻沟通专属顾问</span></Radio>
							<Radio style='margin-bottom:16px' :value='param[1]' v-model="value"><span style="font-size: 18px">B.今天内联系我</span></Radio>
							<Radio style='margin-bottom:16px' :value='param[2]' v-model="value"><span style="font-size: 18px;">C.今天15:00联系我</span></Radio>
							<Radio style='margin-bottom:16px' :value='param[3]' v-model="value"><span style="font-size: 18px;">D.明天联系我</span></Radio>
						</div>
							
						
						<div style="font-size: 14px;color: #999999;">
						(如需联系，请注意接听缔造梦官方电话)
						</div>
						<div style="margin-top: 40px;">
						<!-- <el-button  type="primary" @click="tohome"></el-button> -->
						<Button color="blue" @click="tohome">立即试用</Button>
						</div>
						
				</Cell>
				<Cell :width="4" >
					<img src="../assets/ewm.png" alt="" style="width: 100%;">
					
					<div style="font-size: 14px;color: #999999;text-align: center;">
						扫官方企业微信咨询
						</div>
						<div style="margin-top: 10px;text-align: center;">
						<el-button  type="text">"看教程"、“询报价”</el-button>
				    </div>
				</Cell>
			</Row>
		 </div>
    </div>

	</div>

	

</template>

<script>
	/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘企万慧</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
	import LoginForm from "@/views/login/LoginForm.vue";
	import ForgotPassword from "@/views/login/ForgotPassword.vue";
	import Registered from "@/views/login/Registered.vue";
	import PhoneLoginForm from "@/views/login/PhoneLoginForm.vue";
	import EwmLogin from "@/views/login/EwmLogin.vue";

	export default {
		name: "Login",
		components: {LoginForm, ForgotPassword, Registered,PhoneLoginForm,EwmLogin},
		data() {
			return {
				msg: "",
				slidertype:false,
				imageStatus: false,
				isCodeLogin:false,
				loginsuc:true,
				value:null,
				param: [{ title: '选择1', key: 'a1', other: '其他值' }, 
				{ title: '选择2', key: 'a2' }, 
				{ title: '选择3', key: 'a3' }, { title: '选择4', key: 'a4' }]
			}
		},
		mounted(){
            this.setCurrentFocusPos()
		},
		methods: {
			showImage(imageStatus) {
				if (imageStatus === true) {
					this.imageStatus = false
				} else {
					this.imageStatus = true
				}
			},
			changesi(val) {
			
			   if(val > 91){
				this.msg = 91;
				this.slidertype = false;
			   }else{
			
			   }
			},
			
			


			 setCurrentFocusPos(){
				let that = this
				var slider = document.getElementById("Slidercss");
				slider.addEventListener('mouseup', function() {
				
					if(that.msg <91){
						that.msg = 0
						that.slidertype = true
					}
				});

			},

			
		}
	}
</script>
<script setup>

	import {ref} from "vue";

	const viewName = ref('LoginForm');
	const loginsuc = ref(true);
	const changeView = (toView) => {
		viewName.value = toView;
	}
	const toSuc = (val) => {
		loginsuc.value = val;
		// console.log(loginsuc.value,'loginsuc取值')
	}
	const tohome = () => {
		// loginsuc.value = false;
		window.location.replace('/');
	}
	

	localStorage.removeItem("currentTab");
</script>

<style scoped lang="less">
	.login-body {
		min-height: 100vh;
		width: 100%;
		// background-image: url("../assets/logo_bg.png");
		background-image: url('/images/loginbg.png');
		background-size: 100% 100%;
		position: relative;
	}

	.login-top-bar {
		background-color: #fff;
		height: 80px;
		width: 100%;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
		box-sizing: border-box;
	}

	.login-topbar-header {
		position: relative;
		max-width: 1200px;
		margin: 0 auto;
		padding: 18px 0;
		height: 80px;
	}

	.topbar-header-log {
		display: inline-block;
		margin-right: 10px;
		width: 110px;
	}

	.img {
		width: 110px;
		height: 45px
	}

	.login-topbar-title {
		display: inline-block;
		padding: 0 10px;
		font-size: 20px;
		border-left: 1px solid #999;
		top: 50%;
		transform: translateY(-50%);
		font-weight: initial;
	}

	.sub-topic-text {
		float: right;
		line-height: 45px;
		font-size: 14px;
		color: #73777a;
		margin-right: 20px;
		cursor: pointer;
	}

	.contact {
		position: fixed;
		left: 80%;
		z-index: 2000;
		height: 200px;
	}

	.login-main-content {
		
		// max-width: 1200px;
		// min-height: 800px;
		// min-height: calc(100vh - 81px - 89px);
		// margin: 0 auto;
		display: flex;
		justify-content: center;
		align-items: center;
		box-sizing: border-box;
		border-radius: 20px 20px 20px 20px;
		// overflow: hidden;
		// background: #000;
		position:fixed;
		left:50%;
		top:50%;
		transform:translate(-50%,-50%);
		z-index:1000;
		.fiximg{
			position: absolute;
			right: 0;
			top: 0;
			width: 120px;
			height: 120px;
			z-index: 1001;
		}
	}

	.login-main-text {
		box-sizing: border-box;
		width: 520px;
		height: 600px;
		background-image: url("../assets/dashboard.png");
		background-size: 100% 100%;
		background-color: #fff;
		box-shadow: 0px 2px 10px 0px rgba(188, 202, 224, 0.25);
		// background-size: cover;
		border-radius: 20px 0 0 20px;
	}

	.root-login {
		position: relative;
		width: 520px;
		height: 600px;
		background-color: #fff;
		font-size: 14px;
		border-color: #e4e4e4;
		overflow: hidden;
		box-shadow: 0px 2px 10px 0px rgba(188, 202, 224, 0.25);
		padding: 50px ;
		border-radius:0 20px 20px 0;
	}
	

	.login-title {
		font-size: 32px;
		text-align: center;
		margin: 10px 0;
		font-weight: bold;
	}

	.copyright {
		position:fixed;
		left:50%;
		bottom:30px;
		transform:translate(-50%,-50%);
		z-index:1000;
		text-align: center;
		font-size: 12px;
		color: #777;
		margin-top: 16px;
	}
	.suclogin{
		width:100%;
		height:100vh;
		// background:#ccc;
		display:flex;
		align-items:center;
		justify-content:center;
	}
	.sucTitle{
		font-size: 50px;
		font-weight: bold;
		color: #333333;
	}

	.login2{
//   height: 100%;
  // background-image: url("~@/assets/image/bg.png");
  background-size: cover;
  padding:20px 80px;
  box-sizing: border-box;
}
.rowall{
  display: flex;
  justify-content: center;
}
.rowbg{
  height: 100%;
//   width: 60%;
  margin-top: 60px;
  background-image: url("../assets/sucbg.png");
  background-size: 100% 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items:center;
}
.regstitle{
  padding-top: 50px;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
}
.regstitle2{
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  color: #7376DF;
  margin-top: 20px;
}
.checklist{
  display: flex;
  flex-direction: column;
  justify-content:center;
  margin-top:20px;
//   align-items:center;
  
}
.regstitle3{
  // text-align: center;
  font-size: 16px;
  font-family: Source Han Sans CN-Bold, Source Han Sans CN;
  font-weight: bold;
}

.Sliderbox{
	border:1px solid #ccc;
	padding:5px 0;
	width:100%;
	display:flex;
	height:40px;
	align-items:center;
	position:relative;
	
	
}
.SliderTxt{
    position:absolute;
	top:7px;
	left:40%;
	color:#333;
	font-size:16px;
	font-weight:400;
	z-index:998 !important;
}
.Slidercss{
	width:100%;
	position:relative;
	
}

/deep/ .h-slider{
   padding:0!important;
}
// 滑块
/deep/ .h-slider-node{
	// background:#000 !important;
	width:40px;
	height:40px;
	padding:5px;
	overflow:hidden;
	border-radius:0px!important;
	margin-top:-12px;
	margin-left:0;
	transform:scale(1) !important;
	background: url("../assets/to.png");
    background-size: 100% 100%;
	border:1px solid #ccc;
	z-index:999;
	
	
}
/deep/ .h-slider-node:hover{
	// transform:scale(0.8) !important;
	// transform:translate(-20px,0)
	
}
// 
/deep/ .h-slider-track {
	// background:#ccc !important;
	height:40px!important;
	border-radius:0px!important;
}
/deep/ .h-slider-line{
	background:none !important;
}

</style>
