/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2010-2022杭州财汇餐谋科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2022年10月26日</li>
 * <li>@author     : ____′↘夏悸</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
import Ajax from '../js/common/ajax';

export default {
	queryMonthList(params) {
		return Ajax.get("/account-sets/queryMonthList", params)
	},
	queryGuiDangList(params = {}) {
		return Ajax.post("/account-sets/queryGuiDangList", params)
	},
	saveGuiDangData(params = {}) {
		return Ajax.post("/account-sets/saveGuiDangData", params)
	},
	print(content) {
		return Ajax.post(`/account-sets/export/book/pdf`, content, {
			responseType: 'blob'
		})
	},
	download(params) {
		return Ajax.post(`/account-sets/export/book/download`, params, {
			responseType: 'blob'
		})
	},
	delete(id) {
		return Ajax.delete(`/account-sets/account/${id}`)
	},
	save(params = {}) {
		return Ajax.post(`/account-sets/account`, params)
	},
	update(params) {
		return Ajax.post(`/account-sets/account`, params)
	},
	updateInitialBalance(id, balance) {
		return Ajax.get(`/account-sets/account/balance/` + id, {balance})
	},
	listCheckWork(params) {
		return Ajax.get(`/account-sets/listCheckWork`, params)
	},
	listSalaryWork(params) {
		return Ajax.get(`/account-sets/listCheckWork`, params)
	},
	saveCheckWork(params) {
		return Ajax.post(`/account-sets/saveCheckWork`, params)
	},
	editCheckWork(row) {
		return Ajax.post(`/account-sets/editCheckWork`, row)
	},
	deleteCheckWork(row) {
		return Ajax.post(`/account-sets/deleteCheckWork`, row)
	},
}
