<template>
  <div class="modal-column">
    <div class="padding flex-1">
      <p>第一步： 请点击下面的链接下载Excel模板，并按照模板填写信息</p>
      <a class="blue-color text-hover" :href="`/api/invoice/template/${type}`">下载模板</a>
      <p>第二步： 导入Excel模板文件</p>
      <span class="blue-color text-hover" @click="$refs.file.click()">选取文件</span>
      <div class="h-30px mt-8px"><span v-if="fileName">已选：{{ fileName }}</span></div>
      <input type="file" style="visibility: hidden;position: absolute" @change="fileChange()" ref="file">
    </div>
    <div class="modal-column-btn">
      <Button @click="$emit('close')" :loading="loading">取消</Button>
      <Button color="green" @click="importData" :disabled="!fileName" :loading="loading">导入</Button>
    </div>
  </div>
</template>

<script>/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
import {modal} from "heyui.ext";
import {Invoice} from "@/api/Invoice";

export default {
  name: "InvoiceImport",
  props: {
    type: String,
  },
  data() {
    return {
      file: null,
      loading: false
    }
  },
  computed: {
    fileName() {
      if (this.file) {
        return this.file.name;
      }
      return '';
    }
  },
  methods: {
    importData() {
      if (this.$refs.file.files.length) {
        let formData = new FormData();
        formData.append('file', this.$refs.file.files[0]);
        this.loading = true;
        Invoice.import(this.type, formData).then(({data}) => {
          if (data && data.length) {
            modal({
              title: '如下记录发生错误未导入成功',
              buttons: [{
                type: 'cancel',
                name: '确认',
                style: 'primary'
              }],
              content: data.join("<br>"),
              events: {
                cancel: modal => {
                  this.$refs.file.value = "";
                  this.$emit('success', data);
                  modal.close();
                }
              }
            })
          } else {
            this.$Message("亲,导入成功~");
            this.$refs.file.value = "";
            this.$emit('success', data);
          }
        }).finally(() => {
          this.loading = false;
        }).catch(() => {
          this.$refs.file.value = "";
          this.file = null;
        });
      }
    },
    fileChange() {
      this.file = this.$refs.file.files[0];
    }
  }
}
</script>
