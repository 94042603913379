<template>
  <app-content class="h-panel">
    <div class="flex justify-between mx-16px pt-16px">
      <DropdownCustom ref="paramsDropdown" button class="" trigger="click">
        <div v-if="params.start!==params.end">{{ fqFormat(params.start) }} 至 {{ fqFormat(params.end) }}</div>
        <div v-else>{{ fqFormat(params.start) }}</div>
        <template #content>
          <Form style="padding: 10px;width: 400px">
            <FormItem class="!pb-5px" label="会计期间">
              <div class="flex items-center">
                <Select v-model="params.start" :deletable="false" filterable :datas="checkoutSelect"/>
                <span class="mx-8px">至</span>
                <Select v-model="params.end" :deletable="false" filterable :datas="checkoutSelect"/>
              </div>
            </FormItem>
            <FormItem class="!pb-5px" label="起始科目">
              <Select :datas="subjectList" :filter="sfilter" keyName="code" titleName="fullName"
                      v-model="params.subjectStart">
                <template #show="{value}">
                  {{ value.code }} {{ value.fullName }}
                </template>
                <template #item="{item}">
                  {{ item.code }} {{ item.fullName }}
                </template>
              </Select>
            </FormItem>
            <FormItem class="!pb-5px" label="结束科目">
              <Select :datas="subjectList" :filter="sfilter" keyName="code" titleName="fullName"
                      v-model="params.subjectEnd">
                <template #show="{value}">
                  {{ value.code }} {{ value.fullName }}
                </template>
                <template #item="{item}">
                  {{ item.code }} {{ item.fullName }}
                </template>
              </Select>
            </FormItem>
            <FormItem class="!pb-5px" label="科目级别">
              <div class="flex items-center">
                <Select v-model="params.levelStart" :deletable="false" filterable :datas="[1,2,3,4]"/>
                <span class="mx-8px">至</span>
                <Select v-model="params.levelEnd" :deletable="false" filterable :datas="[1,2,3,4]"/>
              </div>
            </FormItem>
            <FormItem class="!pb-5px">
              <Button color="primary" @click="loadList();" :loading="loading">确定</Button>
              <Button @click="this.$refs.paramsDropdown.hide()">取消</Button>
              <Button @click="resetForm">重置</Button>
            </FormItem>
          </Form>
        </template>
      </DropdownCustom>
      <div class="float-right flex items-center">
        <Checkbox class="mr-16px" >按月列示</Checkbox>
        <Checkbox class="mr-16px" v-model="showNumPrice">显示数量金额</Checkbox>
        <Checkbox class="mr-16px" >显示本年累计</Checkbox>
        <vxe-button @click="exportData" icon="fa fa-download" content="导出"/>
        <vxe-button @click="printData" icon="fa fa-print" content="打印"/>
      </div>
    </div>
    <div class="h-panel-body">
      <div class="text-center mb-16px font-bold text-20px">{{ title }} 科目余额表</div>
      <vxe-table :show-overflow="false"
                 :tree-config="{transform: true, rowField: 'treeId', parentField: 'parentTreeId'}"
                 show-footer
                 :footer-method="calcTotal"
                 :loading="loading" ref="xTable" :data="dataList"  v-if="!showNumPrice">
        <vxe-column title="科目编码" field="subjectCode" fixed="left" tree-node width="150"></vxe-column>
        <vxe-column title="科目名称" field="subjectName" fixed="left" width="150"></vxe-column>
        <vxe-colgroup title="期初余额" align="center">
          <vxe-column title="借方" field="beginningDebitBalance" align="right" width="120">
            <template #default="{row}">
              <span v-if="row.balanceDirection==='借'">{{ numFormat(row.beginBalance) }}</span>
            </template>
          </vxe-column>
          <vxe-column title="贷方" field="beginningCreditBalance" align="right" width="120">
            <template #default="{row}">
              <span v-if="row.balanceDirection==='贷'">{{ numFormat(row.beginBalance) }}</span>
            </template>
          </vxe-column>
        </vxe-colgroup>
        <vxe-colgroup title="本期发生额" align="center">
          <vxe-column title="借方" field="debit" align="right" formatter="toMoney" width="120"/>
          <vxe-column title="贷方" field="credit" align="right" formatter="toMoney" width="120"/>
        </vxe-colgroup>
        <vxe-colgroup title="本年累计发生额" align="center">
          <vxe-column title="借方" field="yearTotalDebit" align="right" formatter="toMoney" width="120"/>
          <vxe-column title="贷方" field="yearTotalCredit" align="right" formatter="toMoney" width="120"/>
        </vxe-colgroup>
        <vxe-colgroup title="期末余额" align="center">
          <vxe-column title="借方" field="endingDebitBalance" align="right"
                      :class-name="({row})=>row.endBalance<0?'red-color':''" width="120">
            <template #default="{row}">
              <span v-if="row.balanceDirection==='借'">{{ numFormat(row.endBalance) }}</span>
            </template>
          </vxe-column>
          <vxe-column title="贷方" field="endingCreditBalance" align="right"
                      :class-name="({row})=>row.endBalance<0?'red-color':''" width="120">
            <template #default="{row}">
              <span v-if="row.balanceDirection==='贷'">{{ numFormat(row.endBalance) }}</span>
            </template>
          </vxe-column>
        </vxe-colgroup>
      </vxe-table>
      <vxe-table ref="xTable" :data="dataList" stripe  v-else>
        <vxe-column title="科目编码" field="code">
          <template #default="{row}">
            <div :style="{'padding-left':(row.level)*10+'px'}">{{ row.code }}</div>
          </template>
        </vxe-column>
        <vxe-column title="科目名称" field="name">
          <template #default="{row}">
            <div :style="{'padding-left':(row.level)*10+'px'}">{{ row.name }}</div>
          </template>
        </vxe-column>
        <vxe-colgroup title="期初余额" align="center">
          <vxe-column title="借方数量" field="beginningDebitNum" align="right"/>
          <vxe-column title="借方金额" field="beginningDebitBalance" align="right" formatter="toMoney"/>
          <vxe-column title="贷方数量" field="beginningCreditNum" align="right"/>
          <vxe-column title="贷方金额" field="beginningCreditAmount" align="right" formatter="toMoney"/>
        </vxe-colgroup>
        <vxe-colgroup title="本期发生额" align="center">
          <vxe-column title="借方数量" field="currentDebitAmountNum" align="right"/>
          <vxe-column title="借方金额" field="currentDebitAmount" align="right" formatter="toMoney"/>
          <vxe-column title="贷方数量" field="currentCreditAmountNum" align="right"/>
          <vxe-column title="贷方金额" field="currentCreditAmount" align="right" formatter="toMoney"/>
        </vxe-colgroup>
        <vxe-colgroup title="期末余额" align="center">
          <vxe-column title="借方数量" field="endingDebitBalanceNum" align="right"/>
          <vxe-column title="借方金额" field="endingDebitBalance" align="right" formatter="toMoney"/>
          <vxe-column title="贷方数量" field="endingCreditBalanceNum" align="right"/>
          <vxe-column title="贷方金额" field="endingCreditBalance" align="right"
                      :class-name="({row})=>row.endingCreditBalance<0?'red-color':''" formatter="toMoney"/>
        </vxe-colgroup>
      </vxe-table>
    </div>
  </app-content>
</template>

<script>
import BooUtil from "@/views/accountbook/bookUtil";
import accountbook from "@/api/accountbook";
import {downloadBlob} from "download.js";
import manba from "manba";
import {mapGetters, mapState} from "vuex";
import AccountDateRangeChoose from "@/components/AccountDateRangeChoose";
import {clone} from "xe-utils";
import setting from "@/api/setting";

export default {
  name: "SubjectBalance",
  components: {AccountDateRangeChoose},
  data() {
    return {
      subjectList: [],
      dataList: [],
      accountDate: null,
      showNumPrice: false,
      loading: false,
      params: {
        start: null,
        end: null,
        currencyId: null,
        levelStart: 1,
        levelEnd: 4,
        subjectStart: null,
        subjectEnd: null,
      }
    }
  },
  computed: {
    ...mapState(['currentAccountSets']),
    ...mapGetters(['checkoutSelect', 'localCurrency']),
    title() {
      if (!this.accountDate) {
        return '';
      }
      if (this.accountDate.start === this.accountDate.end) {
        return manba(this.accountDate.start).format("YYYY年MM期")
      }
      return manba(this.accountDate.start).format("YYYY年MM期") + " - " + manba(this.accountDate.end).format("YYYY年MM期");
    },
    topData() {
      return this.dataList.filter(val => val.parentTreeId === '0');
    }
  },
  watch: {
    accountDate() {
      this.loadList();
    },
    showNumPrice() {
      this.loadList();
    }
  },
  methods: {
    loadList() {
      this.loading = true;
      accountbook.loadSubjectBalance(Object.assign({
        showNumPrice: this.showNumPrice
      }, this.params)).then(({data}) => {
        this.loading = false;
        this.dataList = data;
      });
    },
    sfilter(item, val) {
      return item.code.indexOf(val) !== -1 || item.name.indexOf(val) !== -1 || item.mnemonicCode.indexOf(val) !== -1;
    },
    exportData() {
      const utils = new BooUtil(this.$refs.xTable, '\n')
      accountbook.download({header: utils.header, dataList: utils.dataList}).then((data) => {
        downloadBlob(`${this.title}-科目余额表.xls`, data)
      })
    },
    printData() {
      const utils = new BooUtil(this.$refs.xTable)
      const title = `${this.title}-科目余额表`
      accountbook.print({
        content: utils.printContent(title, this.currentAccountSets.companyName),
        rotate: true
      }).then((data) => {
        const url = URL.createObjectURL(data);
        window.open(url, '账簿')
      })
    },
    resetForm() {
      this.params = clone(this.cloneParams, true)
    },
    calcTotal({columns, data}) {
      const row = [];
      columns.forEach(col => {
        if ('beginningDebitBalance' === col.property) {
          row.push(this.numFormat(this.topData.filter(val => val.balanceDirection === '借').reduce((total, r) => total + (r.beginBalance || 0), 0)));
        } else if ('beginningCreditBalance' === col.property) {
          row.push(this.numFormat(this.topData.filter(val => val.balanceDirection === '贷').reduce((total, r) => total + (r.beginBalance || 0), 0)));
        } else if ('endingDebitBalance' === col.property) {
          row.push(this.numFormat(this.topData.filter(val => val.balanceDirection === '借').reduce((total, r) => total + (r.endBalance || 0), 0)));
        } else if ('endingCreditBalance' === col.property) {
          row.push(this.numFormat(this.topData.filter(val => val.balanceDirection === '贷').reduce((total, r) => total + (r.endBalance || 0), 0)));
        } else if (!['subjectCode', 'subjectName'].includes(col.property)) {
          row.push(this.numFormat(this.topData.reduce((total, r) => total + (r[col.property] || 0), 0)));
        } else {
          row.push(col.property === "subjectName" ? '合计' : "")
        }
      })
      return [row];
    }
  },
  created() {
    this.params.start = manba(this.currentAccountSets.currentAccountDate).format("YYYY-M");
    this.params.end = this.params.start;
    this.params.currencyId = this.localCurrency.id;
    this.cloneParams = clone(this.params, true)
    setting.subject.voucherSelect().then(({data}) => {
      this.subjectList = data;
    })
    this.loadList();
  }
}
</script>

<style scoped lang="less">
.balance {
  width: 100%;
  border-collapse: collapse;

  td {
    padding: 0 8px;
    border: 1px solid #e2e2e2;
    font-size: 12px;
    height: 32px;

    &.tip {
      background-color: #FFEEEF;
    }
  }

  tbody tr:nth-child(even) {
    background-color: #f8fbf8;
  }

  tbody tr:hover {
    background-color: #F0F6FF;
  }

  .header {
    td {
      background-color: #F5F5F5;
      text-align: center;
      font-weight: bold;
      height: 35px;
    }
  }
}
</style>
