<template>
	<div   class="h-panel-body padding font-bold">
		<template v-for="year in years">
			
			<h2 class="margin-bottom">{{ year }}年</h2>
			<Row :space="20">
				<template v-for="month in dataList[year]">
					<Cell width="3">
						<!-- <div class="period" :class="{disable:month.disable,completed:month.status===2}" @click="carryForward(month)">
							<div v-if="month.status===1" class="bg-green-color month-header">
							</div>
							<div v-else class="bg-primary-color month-header">
								<i v-if="month.status===2" class="fa fa-lock white-color"></i>
							</div>
							<span>0{{ month.checkMonth }}</span>
						</div> -->
						<div class="period2"  @click="carryForward(month)">
							
							<span>{{ month.checkMonth }}</span>
						</div>
					</Cell>
				</template>
			</Row>
		</template>
	</div>
	
</template>

<script>
import {layer} from "@layui/layer-vue";
import {h} from "vue";
import {mapState} from "vuex";
import Check from "@/views/checkout/Check";
import checkout from "@api/checkout";
import ClosingCarryover from "@/views/checkout/ClosingCarryover.vue";
import ProfitCheckList from "@/views/checkout/ProfitCheckList.vue";

export default {
	name: "CheckList",
	data() {
		return {
			dataList: {},
			isupdate:false,
		}
	},
	computed: {
		...mapState(['currentAccountSets']),
		years() {
			return Object.keys(this.dataList).sort((a, b) => b - a);
		}
	},
	methods: {
		loadList() {
			checkout.list().then(({data}) => {
				let disable = false;
				for (let i = data.length - 1; i >= 0; i--) {
					data[i].disable = disable;
					if (data[i].status !== 2 && !disable) {
						disable = true;
					}
				}

				let checkData = {};
				data.forEach(val => {
					if (!checkData[val.checkYear]) {
						checkData[val.checkYear] = [];
					}
					checkData[val.checkYear].push(val);
				});

				this.dataList = checkData;
			})
		},
		carryForward(data) {
			if (data.status !== 2 && !data.disable) {
				
				// this.$emit('getdata',data,'first')
			    //  return

				let layerId = layer.drawer({
					title: "第一步：期末结转",
					shadeClose: true,
					zIndex: 999,
					area: ['calc(100vw - 180px)', '100vh'],
					content: h(ClosingCarryover, {
						checkData: data,
						onClose: () => {
							layer.close(layerId);
						},
						onCheck: (checkData) => {
							layer.close(layerId);
							this.check(checkData, data);
						},
						onProfit: ({checkData, profitList}) => {
							layer.close(layerId);
							this.check(checkData, data);
						},
						onProfitCheckList: ({checkData, voucherIds}) => {
							layer.close(layerId);
							this.profitCheckList(checkData, voucherIds);
						}
					})
				});
			} else if (data.disable) {
				this.$Message("上期未结转损益，本期不能点击")
			}
		},
		check(checkData, data) {
			let layerId = layer.drawer({
				title: "结转",
				shadeClose: false,
				closeBtn: 1,
				zIndex: 999,
				area: ['calc(100vw - 180px)', '100vh'],
				content: h(Check, Object.assign(checkData, {
					onClose: () => {
						layer.close(layerId);
					},
					onBack: () => {
						layer.close(layerId);
						this.carryForward(data)
					},
					onSuccess: () => {
						layer.close(layerId);
						this.loadList();
					}
				}))
			});
		},
		profitCheckList(checkData, voucherIds) {
			console.log('急转11')
			let layerId = layer.drawer({
				title: "已结转损益",
				shadeClose: true,
				zIndex: 999,
				area: ['calc(100vw - 180px)', '100vh'],
				content: h(ProfitCheckList, {
					checkData, voucherIds,
					onClose: () => {
						layer.close(layerId);
					},
					onBack: () => {
						layer.close(layerId);
						this.carryForward(checkData)
					},
					onCheck: () => {
						layer.close(layerId);
						this.check(checkData, checkData);
					}
				})
			});
		}
	},
	created() {
		this.loadList();
	}
}
</script>


<style lang="less" scoped>
.h-row > div > div {
	padding: 8px;
	border-radius: 5px;
	text-align: center;
	font-size: 20px;
	color: #000;
}

.period {
	border-radius: 3px;
	text-align: center;
	line-height: 54px;
	border: 1px solid var(--primary);
	white-space: nowrap;
	overflow: hidden;
	box-sizing: border-box;
	height: 80px;
    
	&:hover {
		cursor: pointer;
		box-shadow: 0 0 20px 2px rgba(102, 100, 100, 0.24), 0px 2px 4px 0px rgba(199, 198, 198, 0.5);
	}

	.month-header {
		height: 25px;
		margin: -8px -8px 0 -8px;
	}

	&.completed {
		.month-header {
			background-color: @gray1-color !important;
			line-height: 20px;
		}

		border-color: @gray1-color;

		color: #a2a2a2;

		&:hover {
			cursor: default;
			box-shadow: none;
		}
	}

	&.disable {
		opacity: 0.4;

		&:hover {
			cursor: default;
			box-shadow: none;
		}
	}
}
.period2 {
	border-radius: 3px;
	text-align: center;
	// line-height: 54px;
	// border: 1px solid @primary-color;
	white-space: nowrap;
	overflow: hidden;
	box-sizing: border-box;
	height: 80px;
    background-image: url('../../assets/month.png');
	background-size: 100% 100%;
	padding-top: 20px !important;
	span{
		font-size: 43px;
		font-weight: 400;
		color: #2FA8FD;
	}
	&:hover {
		cursor: pointer;
		// box-shadow: 0 0 20px 2px rgba(102, 100, 100, 0.24), 0px 2px 4px 0px rgba(199, 198, 198, 0.5);
	}

	.month-header {
		height: 25px;
		margin: -8px -8px 0 -8px;
	}

	&.completed {
		.month-header {
			background-color: @gray1-color !important;
			line-height: 20px;
		}

		border-color: @gray1-color;

		color: #a2a2a2;

		&:hover {
			cursor: default;
			box-shadow: none;
		}
	}

	&.disable {
		opacity: 0.4;

		&:hover {
			cursor: default;
			box-shadow: none;
		}
	}
}
</style>
