<template>
  <div class="modal-column">
    <div class="flex-1 p-20px">
      <Form ref="form" :model="formData" :rules="{}" mode="block" :labelWidth="120">
        <div class="inline-form-item">
          <FormItem label="收支类型" prop="ioTypeId" required>
            <Select :datas="ioTypeList" v-model="formData.ioTypeId" keyName="id" titleName="name" :deletable="false"/>
          </FormItem>
          <FormItem label="凭证字" prop="wordId" required>
            <Select :datas="voucherWordList" v-model="formData.wordId" :deletable="false" keyName="id" titleName="word"/>
          </FormItem>
        </div>
        <FormItem label="会计科目" single required>
          <div class="h-input-group mb-16px">
            <Select :disabled="type==='收'" :datas="subjectList" type="object" v-model="subjectCode1" keyName="id" titleName="fullName" :deletable="false" :filter="subjectFilter" filterable :equalWidth="false" class="mr-16px"/>
            <span class="h-input-addon"><Radio v-model="direction1" :datas="['借','贷']"/></span>
          </div>
          <div class="h-input-group">
            <Select :disabled="type==='支'" :datas="subjectList" type="object" keyName="id" v-model="subjectCode2" titleName="fullName" :deletable="false" :filter="subjectFilter" filterable :equalWidth="false" class="mr-16px"/>
            <span class="h-input-addon"><Radio v-model="direction2" :datas="['借','贷']"/></span>
          </div>
        </FormItem>
      </Form>
    </div>
    <div class="modal-column-btn">
      <Button @click="$emit('close')">取消</Button>
      <Button color="green" @click="submit" :loading="loading">保存</Button>
    </div>
  </div>
</template>

<script>
/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
export default {
  name: "CashierIoTypeTemplateForm"
}
</script>

<script setup>
import {reactive, ref, toRefs, watch} from "vue";
import CashierIoType from "@/api/CashierIoType";
import setting from "@/api/setting";
import {message} from "heyui.ext";

const props = defineProps(['ioType', 'type', 'items']);
const emits = defineEmits(['close', 'success']);

const {ioType, type, items} = toRefs(props);
const formData = reactive({
  wordId: null,
  ioTypeId: null,
  subjectId: null,
  direction: null,
  journalType: ioType.value,
  type: type.value,
})
const subjectCode1 = ref(null);
const direction1 = ref('借');
const subjectCode2 = ref(null);
const direction2 = ref('贷');
const form = ref(null);

const loading = ref(false)
const subjectList = ref([])
const ioTypeList = ref([])
const voucherWordList = ref([])

if (items.value) {
  formData.wordId = items.value[0].wordId;
  formData.ioTypeId = items.value[0].ioTypeId;

  subjectCode1.value = {id: items.value[0].subjectId};
  direction1.value = items.value[0].direction;

  subjectCode2.value = {id: items.value[1].subjectId};
  direction2.value = items.value[1].direction;
}

CashierIoType.list().then(({data}) => {
  ioTypeList.value = (data || []).filter(val => val.type === type.value);
})

setting.voucherWord.list().then(({data}) => {
  voucherWordList.value = data || [];
})

setting.subject.voucherSelect({showAll: true}).then(({data}) => {
  subjectList.value = data || [];

  (type.value === '收' ? subjectCode1 : subjectCode2).value = subjectList.value.find(val => val.name === (ioType.value === '现金' ? '库存现金' : '银行存款'));
});

const submit = () => {
  let validResult = form.value.valid();
  if (validResult.result) {
    if (!subjectCode1.value || !subjectCode2.value) {
      message.error("请选择会计科目~")
      return
    }
    if (direction1.value === direction2.value) {
      message.error("科目必须有借有贷~")
      return
    }

    loading.value = true;
    CashierIoType.templateSave([
      Object.assign({}, formData, {subjectId: subjectCode1.value.id, direction: direction1.value}),
      Object.assign({}, formData, {subjectId: subjectCode2.value.id, direction: direction2.value}),
    ]).then(() => {
      emits('success')
    }).finally(() => loading.value = false)
  }
}

const subjectFilter = (item, filter) => {
  return item.fullName.indexOf(filter) !== -1 || item.mnemonicCode.indexOf(filter) !== -1;
}
</script>
