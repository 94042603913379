<template>
    <div class="h-full p-20px">
        <div class="h-full  flex flex-column">

            <vxe-toolbar class="px-16px" style="margin-bottom: 10px">
                <template #buttons>
                    <div class="flex items-center">
                        <DatePicker/>
                        <span class="mx-8px">至</span>
                        <DatePicker/>
                    </div>
                    <div class="flex items-center" style="margin-left: 10px">
                        选择用户:
                        <Select style="width: 120px" :deletable="false" :datas="['会计001','会计002']"/>
                    </div>
                    <Input placeholder="请输入日志的关键字搜索" style="margin-left: 10px"/>
                    <Button :loading="dataLoading" @click="backUp" color="primary" style="margin-left: 10px">查询</Button>

                </template>
            </vxe-toolbar>
            <div class="flex-1">
                <vxe-table border="none" height="auto" ref="xTable" :loading="dataLoading" :data="dataList">
                    <vxe-column title="用户id" field="title"/>
                    <vxe-column title="用户名" field="bakTime"/>
                    <vxe-column title="日志类型" field="size"/>
                    <vxe-column title="模块" field="userName"/>
                    <vxe-column title="日志" field="userName"/>
                    <vxe-column title="操作时间" field="userName"/>
                </vxe-table>
            </div>


        </div>
    </div>
</template>

<script>
/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
export default {
    name: "BakUp"
}
</script>

<script setup>
import {confirm, loading} from "heyui.ext";
import {ref, h} from "vue";
import setting from "@/api/setting";
import RecoverForm from "@/views/setting/RecoverForm";
import {layer} from "@layui/layer-vue";

const dataLoading = ref(false)
const afterAuth = ref(false)
const dataList = ref([])
const fileInput = ref(null)
const ioType = ref('备份')

const loadList = () => {
    dataLoading.value = true;
    setting.backup.list().then(({data}) => {
        dataList.value = data;
    }).finally(() => dataLoading.value = false)
}
loadList();

const backUp = () => {
    loading("备份中...")
    setting.backup.backup().then(() => {
        loadList();
    }).finally(() => loading.close())
}

const remove = (row) => {
    confirm({
        content: "确认删除此备份?",
        async: true,
        onConfirm(resolve) {
            loading("删除中...")
            setting.backup.delete(row.id).then(() => {
                loadList();
            }).finally(() => {
                loading.close();
                resolve(true);
            })
        }
    })
}

const recover = (entity) => {
    let layerId = layer.open({
        title: "恢复数据",
        shadeClose: false,
        closeBtn: false,
        zIndex: 1000,
        area: ['550px', 'auto'],
        content: h(RecoverForm, {
            entity,
            onClose: () => {
                layer.close(layerId);
            }
        })
    });
}

const uploadBackUp = () => {
    if (fileInput.value.files.length) {
        loading("上传中...")
        const formData = new FormData();
        formData.append("file", fileInput.value.files[0]);
        setting.backup.upload(formData).then(() => {
            loadList();
        }).finally(() => loading.close())
    }

}
</script>
