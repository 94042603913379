<template>
  <div class="v-table-td-summary" @click="doEdit">
    <textarea
        v-if="editing"
        ref="textarea"
        v-model="summaryVal"
        @keydown.tab.prevent="onEnter"
        @keydown.enter.prevent="onEnter"
        @blur="endEdit"
        class="v-table-td-summary-edit"
    ></textarea>
    <div class="p-8px" v-else>
      {{ summary }}
    </div>
  </div>
</template>
<script setup>

import {getCurrentInstance, nextTick, onMounted, ref, watch} from "vue";
import {getVTable} from "@components/VoucherTable/VtUtil";

const props = defineProps(['summary', 'rowIndex', 'detail']);

const textarea = ref(null)
const editing = ref(false)
const summaryVal = ref("")
let VTable = null;

const doEdit = () => {
  editing.value = true;
  nextTick(() => {
    textarea.value.focus();
    textarea.value.select();
  })
}

const endEdit = () => {
  editing.value = false;
  VTable.exposed.updateFiled(props.rowIndex, 'summary', summaryVal.value);
}

const onEnter = (e) => {
  editing.value = false;
  endEdit();
  VTable.exposed.next(props.rowIndex, 'subject');
}

watch(() => props.summary, (val) => {
  summaryVal.value = val;
})

onMounted(() => {
  summaryVal.value = props.summary;
  VTable = getVTable(getCurrentInstance())
})

defineExpose({
  doEdit,
  endEdit
})
</script>
<style scoped lang="less">
.v-table {

  &-td {
    &-summary {
      width: 210px;
      border-right: @border;

      &-edit {
        resize: none;
        outline: none;
        height: 100%;
        width: 100%;
        overflow: hidden;
        font-size: 12px;
        padding: 8px;

        &:focus {
          border: 1px solid @green-color;
        }
      }
    }
  }
}
</style>
