<template>
  <div class="h-full p-20px">
    <div class="h-full flex flex-column">
      <div class="flex justify-between bg-white-color items-center">
        <Tabs :datas="tabs" class="px-20px bg-white-color" v-model="tab"/>
        <vxe-button @click="showInvoiceVoucherTemplate" type="text" icon="fa fa-gear">生成凭证设置</vxe-button>
      </div>
      <component @import="doImport" @download="doDownload" :is="tab" :categoryList="categoryList[tab]" class="flex-1 bg-white-color flex flex-column"/>
    </div>
  </div>
</template>

<script>/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */

import {mapState} from "vuex";
import OutputInvoice from "@/views/invoice/OutputInvoice";
import IncomeInvoice from "@/views/invoice/IncomeInvoice";
import Calculation from "@/views/invoice/Calculation";

import Cerium from "@/views/invoice/Cerium";
import {h} from "vue";
import {layer} from "@layui/layer-vue";
import InvoiceVoucherTemplate from "@/views/invoice/InvoiceVoucherTemplate";
import {Invoice} from "@/api/Invoice";
import {downloadBlob} from "download.js";
import manba from "manba";
import InvoiceImport from "@/views/invoice/InvoiceImport";

const categoryList = {
  OutputInvoice: {
    未开具发票: "未开具发票",
    增值税普通发票: "增值税普通发票",
    增值税专用发票: "增值税专用发票",
    全电发票增值税专用发票: "全电发票(增值税专用发票)",
    全电发票普通发票: "全电发票(普通发票)",
    税控机动车专用发票: "税控机动车专用发票",
    纳税检查调整: "纳税检查调整"
  },
  IncomeInvoice: {
    增值税专用发票: "增值税专用发票",
    增值税普通发票: "增值税普通发票",
    全电发票增值税专用发票: "全电发票(增值税专用发票)",
    全电发票普通发票: "全电发票(普通发票)",
    税控机动车专用发票: "税控机动车专用发票",
  },
  // TT
  Calculation: {
    增值税专用发票: "增值税专用发票",
    增值税普通发票: "增值税普通发票",
    全电发票增值税专用发票: "全电发票(增值税专用发票)",
    全电发票普通发票: "全电发票(普通发票)",
    税控机动车专用发票: "税控机动车专用发票",
  },
}

export default {
  name: "Invoice",
  components: {Cerium, IncomeInvoice, OutputInvoice,Calculation},
  data() {
    return {
      tab: "OutputInvoice",
      categoryList
    }
  },
  computed: {
    ...mapState(['currentAccountSets']),
    tabs() {
      console.log(this.currentAccountSets.vatType,'this.currentAccountSets.vatType')
      if (this.currentAccountSets.vatType) {
        return {'OutputInvoice': '销项发票', 'IncomeInvoice': '进项发票','Calculation': '增值税测算'}
      } else {
        return {'OutputInvoice': '销项发票', 'IncomeInvoice': '进项发票','Calculation': '增值税测算'}
      }
    }
  },
  methods: {
    showInvoiceVoucherTemplate() {
      layer.drawer({
        title: "凭证模板设置",
        zIndex: 1000,
        area: ['700px', '100vh'],
        content: h(InvoiceVoucherTemplate)
      });
    },
    doImport(args) {
      let layerId = layer.open({
        title: this.tabs[this.tab] + "导入",
        shadeClose: false,
        closeBtn: false,
        zIndex: 1000,
        area: ['500px', 'auto'],
        content: h(InvoiceImport, {
          type: args.type,
          onClose: () => {
            layer.close(layerId);
          },
          onSuccess: () => {
            layer.close(layerId);
          }
        })
      });
    },
    doDownload(args) {
      const loadId = layer.load(1);
      Invoice.download(args.type, Object.assign({download: true}, args.params)).then((resp) => {
        downloadBlob(this.tabs[this.tab] + manba().format('YYYYMMDD'), resp);
      }).finally(() => layer.close(loadId));
    }
  },
}
</script>
