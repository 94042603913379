/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
const taxRateList = [];
const taxRates = [0, 1, 3, 4, 5, 6, 9, 10, 11, 13, 16, 17];
for (let i = taxRates.length - 1; i >= 0; i--) {
	taxRateList.push({
		label: taxRates[i] + "%",
		value: taxRates[i]
	})
}

export const TaxRateList = taxRateList;

export const TaxedItems = [
	{
		label: "应税货物",
		value: "应税货物"
	},
	{
		label: "应税劳务",
		value: "应税劳务"
	},
	{
		label: "应税服务、不动产、无形资产",
		value: "应税服务、不动产、无形资产"
	}
];

export const TaxCalculationMethod = [
	{
		label: "一般计税方法-一般",
		value: "一般计税方法-一般"
	},
	{
		label: "一般计税方法-即征即退",
		value: "一般计税方法-即征即退"
	},
	{
		label: "简易计税方法-一般",
		value: "简易计税方法-一般"
	},
	{
		label: "简易计税方法-即征即退",
		value: "简易计税方法-即征即退"
	},
	{
		label: "免抵退税",
		value: "免抵退税"
	},
	{
		label: "免税",
		value: "免税"
	}
];


