<template>
  <div class="modal-column">
    <div style="display: flex;justify-content: end;margin-top: 10px;margin-right: 10px">
        <div>
            <Button @click="showForm()" color="primary" icon="fa" >新建</Button>
            <Button @click="goback()" color="" icon="fa" >返回</Button>
        </div>
    </div>
    <div class="modal-column-full-body">
      <div v-for="item in templateList[tab]" class="border p-8px mb-16px" style=" padding:0;border-radius: 4px 4px 4px 4px;opacity: 1;border: 1px solid #D9D9D9;">
        <div class="font-bold mb-8px flex justify-between items-center" style="font-size: 16px; padding:10px;height: 54px;background: #FAFAFA;border-radius: 4px 4px 0px 0px;opacity: 1;">
          <span>{{ item.title }}</span>
          <div>
            <vxe-button @click="showForm(item)" type="text" icon="fa fa-edit" style="color: #2FA8FD">编辑</vxe-button>
            <vxe-button @click="remove(item)" type="text" icon="fa fa-trash"  style="color: #2FA8FD">删除</vxe-button>
          </div>
        </div>
        <div class="pl-16px">
          <div v-for="vi in item.items" style="margin-top: 5px;margin-bottom: 5px;font-size: 16px">
            {{ vi.direction }}： {{ vi.subjectTitle }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
export default {
  name: "SalaryVoucherTemplate",
    methods:{
        goback(){
            this.$store.commit('newTab', {key: 'Salary', title: "凭证列表"})
        }
    }
}
</script>

<script setup>
import {reactive, ref, h, watch} from "vue";
import {SalaryBusiness} from "@/api/SalaryChange";
import {layer} from "@layui/layer-vue";
import SalaryVoucherTemplateForm from "@/views/salary/SalaryVoucherTemplateForm";
import {confirm} from "heyui.ext";

const tab = ref("AccruedSalary");

const templateList = reactive({
  // 计提工资
  "AccruedSalary": [],
  // 发放工资
  "PayrollSalary": [],
})

const loadList = () => {
  // SalaryBusiness.list().then(({data}) => {
  //   templateList[tab.value] = data
  // })
  templateList[tab.value] = [
    {
      accountSetsId: '123123232',
      id: '123123123',
      invoiceType: 'AccruedSalary',
      title: '计提工资',
      voucherWord: '12321323123',
      word: '记',
      items: [
        {
          direction: "借",
          subjectId: "433708451772911751",
          subjectTitle: "管理费用-管理人员职工薪酬",
          valueField: "amount"
        },
        {
          direction: "贷",
          subjectId: "433708451712333311751",
          subjectTitle: "应付职工薪酬",
          valueField: "amount"
        },
      ]
    },
    {
      accountSetsId: '122222232',
      id: '1231222223',
      invoiceType: 'PayrollSalary',
      title: '发放工资',
      voucherWord: '1232222223',
      word: '记',
      items: [
        {
          direction: "借",
          subjectId: "43370333333351",
          subjectTitle: "应付职工薪酬",
          valueField: "amount"
        },
        {
          direction: "贷",
          subjectId: "43370845444443311751",
          subjectTitle: "银行存款",
          valueField: "amount"
        },
      ]
    },
  ]
}
loadList();

const showForm = (entity = null) => {
  let layerId = layer.open({
    title: "生成凭证规则",
    shadeClose: false,
    closeBtn: false,
    zIndex: 1000,
    area: ['700px', '60vh'],
    content: h(SalaryVoucherTemplateForm, {
      entity,
      type: tab.value,
      onClose: () => {
        layer.close(layerId);
      },
      onSuccess: () => {
        layer.close(layerId);
        loadList();
      }
    })
  });
}
//
// const hideForm = () => {
//   // layer.closeAll();
//     window.history.back();
//
// }

const remove = (row) => {
  confirm({
    content: "确认删除?",
    async: true,
    onConfirm(resolve) {
      SalaryBusiness.remove(row.id).then(() => {
        resolve(true);
        loadList();
      }).catch(() => resolve(false))
    }
  })
}
</script>

<style lang="less" scoped>
  .salaryBtn {
    justify-content: center;
    margin: 10px 10px 0 20px;
  }
</style>
