<template>
	<a-accounting-top-bar
					v-model:filter="filter"
					@showForm="showForm"
					:category="category"
					@search="doSearch"/>
	<div class="flex-1">
		<vxe-table ref="categoryTable" height="auto" :data="dataList"  :loading="loading">
			<vxe-column :title="category.name+'编码'" field="code" :width="120"></vxe-column>
			<vxe-column :title="category.name+'名称'" field="name" :width="150"></vxe-column>
			<vxe-column title="助记码" field="mnemonics" :width="100"></vxe-column>
			<vxe-column v-for="(col,i) in category.columns" :title="col.title" :field="'cusColumn'+i"></vxe-column>
			<vxe-column title="备注" field="remark"/>
			<vxe-column title="操作" :width="120" align="center">
				<template #default="{row}">
					<div class="actions">
						<span @click="showForm(row)">编辑</span>
						<span @click="remove(row)">删除</span>
					</div>
				</template>
			</vxe-column>
		</vxe-table>
	</div>
	<vxe-pager v-model:current-page="pageInfo.page" v-model:page-size="pageInfo.pageSize" v-model:total="pageInfo.total"/>
</template>
<script>
import AAccountingTopBar from "@/views/setting/AssistingAccounting/AAccountingTopBar.vue";

export default {
	name: "CategoryCustom",
	components: {AAccountingTopBar}
};
</script>


<script setup>
import {ref, defineProps, h} from "vue";
import {usePageInject} from "./AAccountingCrud";
import {layer} from "@layui/layer-vue";
import {confirm} from "heyui.ext";
import setting from "@/api/setting";
import CustomForm from "@/views/setting/AssistingAccounting/form/CustomForm.vue";

let props = defineProps(['category']);
const dataList = ref([]);
const filter = ref(null);

const loadList = () => {
	return setting.accountingCategoryDetails.list(props.category.id, Object.assign({keyword: filter.value}, pageParams.value)).then(({data}) => {
		dataList.value = data.results;
		pageInfo.total = data.total;
	})
}

const {
	pageInfo,
	doSearch,
	pageParams,
	loading
} = usePageInject(loadList);

doSearch();

const showForm = (entity) => {
	let layerId = layer.open({
		title: props.category.name + "信息",
		shadeClose: false,
		closeBtn: false,
		zIndex: 1000,
		area: ['700px', 'auto'],
		content: h(CustomForm, {
			entity, category: props.category,
			onClose: () => {
				layer.close(layerId);
			},
			onSuccess: () => {
				layer.close(layerId);
				doSearch();
			}
		})
	});
}


const remove = (entity) => {
	confirm({
		content: `确认删除${entity.name}？`,
		async: true,
		onConfirm: (resolve) => {
			setting.accountingCategoryDetails.delete(entity.id).then(() => {
				resolve(true)
				loadList();
			}).catch(() => resolve(false))
		}
	})
}
</script>

