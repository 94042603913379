<template>
  <div style="padding: 20px;">
      <!-- <div class="toptitle">
       财务报表申报(无下限)
      </div> -->
      <Tabs :datas="datalist" class="px-20px bg-white-color" v-model="tab"/>
      <Row :space="20" v-if="tab == 'info'">
        <Cell width="8"></Cell>
        <Cell width="8">
          <div style="padding: 100px 10px;">
          </div>
          <Form  :label-width="120" :model="dataForm" ref="dataForm" :rules="{}" :validOnChange="true" showErrorTip>
              <FormItem label="账套名称" prop="select2" required>
                <!-- <Input type="text" v-model="data.number" /> -->
                <Select v-model="dataForm.select2" :datas="taxlist" keyName="name" titleName="name"></Select>
              </FormItem>
              <FormItem label="账套启用年月" prop="select1" required>
                <!-- <Input type="text" v-model="data.number" /> -->
                <Select v-model="dataForm.select1" :datas="taxlist" keyName="name" titleName="name"></Select>
              </FormItem>
              <FormItem label="报税所属期：" prop="date" required >
                <div style="display: flex;">
                  <DatePicker v-model="dataForm.date" format="YYYY-MM-DD"></DatePicker>
                至
                <DatePicker v-model="dataForm.date2" format="YYYY-MM-DD"></DatePicker>
                </div>
              </FormItem>
              <FormItem label="会计准则" prop="select3" required >
                <Select v-model="dataForm.select3" :autosize="true"  :datas="taxlist" keyName="name" titleName="name"></Select>
                <span class="clickspan">切换准则</span>
              </FormItem>
              <FormItem label="行业" prop="select4"  >
                <Select v-model="dataForm.select4" :datas="taxlist" keyName="name" titleName="name"></Select>
              </FormItem>
              <FormItem label="增值纳税种类" prop="radio" required >
                    <Radio value="0" v-model="dataForm.radio">
                         <span>小规模纳税人</span>
                      </Radio> 
                      <Radio  value="1" v-model="dataForm.radio">
                     <span>小规模纳税人</span>
                  </Radio>
              </FormItem>
             
              <FormItem >
                <Button color="primary" @click="submit">保存账套</Button>
                <Button @click="toTax">取消</Button>
              </FormItem>
              
            </Form>
        </Cell>
        <Cell width="8" > </Cell>
      </Row>
      <Row :space="20" v-if="tab == 'parameter'">
        <Cell width="8"></Cell>
        <Cell width="8">
          <div style="padding: 100px 10px;">
          </div>
          <Form  :label-width="120" :model="dataForm" ref="dataForm" :rules="{}" :validOnChange="true" showErrorTip>
              <div class="tips" style="">
                 模块设置
              </div>
              <FormItem label="资产模块：" prop="radio2" required >
                    <Radio class="mr-16px" value="0" v-model="dataForm.radio2">启用(2023-08)</Radio> 
                    <Radio  value="1" v-model="dataForm.radio2">不启用</Radio>
              </FormItem>
              <FormItem label="资产模块：" prop="radio3" required >
                    <Radio class="mr-16px" value="0" v-model="dataForm.radio3">启用(2023-08)</Radio> 
                    <Radio value="1" v-model="dataForm.radio3">不启用 </Radio>
              </FormItem>
              
              <FormItem label="关联进销存" prop="radio4" required >
                    <Radio class="mr-16px" value="0" v-model="dataForm.radio4">启用(2023-08)</Radio> 
                    <Radio value="1" v-model="dataForm.radio4">不启用 </Radio>
              </FormItem>
              <div class="tips" style="">
                 模块设置
              </div>
              <FormItem label="凭证审核" prop="radio5" required >
                    <Radio class="mr-16px" value="0" v-model="dataForm.radio5">启用(2023-08)</Radio> 
                    <Radio value="1" v-model="dataForm.radio5">不启用 </Radio>
              </FormItem>
              <FormItem label="单价/数量/汇率小数：" prop="select5"  >
                <Select v-model="dataForm.select5" :datas="taxlist" keyName="name" titleName="name"></Select>
              </FormItem>
              <FormItem >
                <Button color="primary" @click="submit">保存账套</Button>
                <Button @click="toTax">取消</Button>
              </FormItem>
             
              
            </Form>
        </Cell>
        <Cell width="8" >
        
        </Cell>
        
        
         
      </Row>
      <Row :space="20" v-if="tab == 'taxinfo'">
        <Cell width="5"></Cell>
        <Cell width="14">
          <div style="padding: 100px 10px;">
          </div>
          <Form  :label-width="160" :model="dataForm" ref="dataForm" mode="twocolumn"
           :rules="{}" :validOnChange="true" showErrorTip>
              
              <FormItem label="纳税人名称：" prop="name" >
                <Input type="text" v-model="dataForm.name" />
              </FormItem>
              <FormItem label="纳税人识别号：" prop="num" >
                <Input type="text" v-model="dataForm.num" />
              </FormItem>
              <FormItem label="报税地区：" prop="address"  >
                <Select v-model="dataForm.address" :datas="taxlist" keyName="name" titleName="name"></Select>
              </FormItem>
              <FormItem label="电子税局密码：" prop="pass"  >
                <Select v-model="dataForm.pass" :datas="taxlist" keyName="name" titleName="name"></Select>
              </FormItem>
              <FormItem label="个税办税人员姓名：" prop="name2" >
                <Input type="text" v-model="dataForm.name2" />
              </FormItem>
              <FormItem label="个税办税人员手机：" prop="phone" >
                <Input type="text" v-model="dataForm.phone" />
              </FormItem>
              <FormItem label="个税系统密码：" prop="pass2"  >
                <Select v-model="dataForm.pass2" :datas="taxlist" keyName="name" titleName="name"></Select>
              </FormItem>
              <FormItem ></FormItem>
              <FormItem ></FormItem>
              
              <FormItem >
                <Button color="primary" @click="submit">保存账套</Button>
                <Button @click="toTax">取消</Button>
              </FormItem>
             
              
            </Form>
        </Cell>
        <Cell width="8" >
        
        </Cell>
        
        
         
      </Row>
 
     
    
  </div>
</template>
<script>
  import {mapMutations, mapState} from 'vuex';
  import EnterpriseWorkbench from '@/api/EnterpriseWorkbench'

  export default {
    name: 'FinancialReport',
    data() {
      return {
        open:require('@/assets/open.png'),
        unopen:require('@/assets/unopen.png'),
        taxlist:[
           {name:'个税申报',isopen:false,},
           {name:'财务报表申请',isopen:false},
           {name:'增值税申报',isopen:true},
           {name:'消费税申报',isopen:true},
           {name:'附加税申报',isopen:true},
           {name:'企业所得税预缴申报',isopen:true},
           {name:'印花税申报',isopen:true},
           {name:'房产税申报',isopen:true},
        ],
        dataForm:{
          select1:'',
          select2:'',
        },
        
          datalist: [{
          title: '基本信息',
          key: 'info'
        }, {
          title: '功能参数',
          key: 'parameter'
        }, {
          title: '税务信息',
          key: 'taxinfo'
        }],
        tab: 'info',
        RadioNodeList:[
            
        ]
       
      };
    },
    computed: {
      ...mapState(['currentAccountSets', 'currentTab']),
    },
    methods: {
      ...mapMutations(['newTab']),
      toTax(){
        console.log('11')
        this.newTab({
          title: '税务申报',
          key: 'TaxDeclaration'
        })
      },
      submit() {
				  console.log('提交')
					let validResult = this.$refs.dataForm.valid();
          console.log('提交',validResult)
					if (validResult.result) {
					
            console.log('提交11')
						this.loading = true
						// this.$api.common.login(this.login).then(() => {
						// 	localStorage.setItem("login_mobile", this.login.mobile);
						// 	window.location.replace('/');
						// }).catch(() => {
						// 	this.loading = false
						// });
					}
				
			
			},
     
    

    },
    created() {
      
     
    },
  };
</script>

<style lang="less" scoped>
  .toptitle{
    font-size: 32px;
    font-weight: bold;
    color: #333333;
    width: 100%;
    text-align: center;
    margin: 20px 0;
  }
  .box1{
    // width: 390px;
    height: 175px;
    background: #FFFFFF;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    border: 1px solid #D9D9D9;
    position: relative;
    font-size: 30px;
    font-weight: 400;
    color: #999;
    display: flex;
    justify-content: center;
    align-items: center;
    .col1{
      color: #333;
    }
    .fiximg{
      position: absolute;
      bottom: 0;
      right: 0;
      width: 90px;
      height: 90px;
    }
  }
  .tips{
    height: 24px;
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    margin: 0 20px 20px;
  }
  .clickspan{
    color: var(--primary);
    cursor: pointer;
    // padding: 10px;
  }

</style>

