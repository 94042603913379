<template>
  <div class="v-table-unit">
    <span>亿</span>
    <span>千</span>
    <span>百</span>
    <span>十</span>
    <span>万</span>
    <span>千</span>
    <span>百</span>
    <span>十</span>
    <span>元</span>
    <span>角</span>
    <span class="last">分</span>
  </div>
</template>

<style scoped lang="less">
.v-table-unit {
  line-height: 30px;
  font-size: 12px;
  font-weight: normal;
  background-image: url("../../assets/moneyUint.png");

  span {
    display: inline-block;
    text-align: center;
    width: 20px;
    height: 100%;
    font-size: 12px;
  }
}
</style>
