<template>
  <div class="modal-column">
    <div class="h-300px p-26px overflow-y">
      <Form mode="block" ref="form" :rules="{}" :model="formData">
        <FormItem v-for="c in categoryList" :label="c.name" required :prop="c.id">
          <div class="h-input-group">
            <Select placeholder="输入名称/编码/拼音首字母快速检索" :filter="doFilter" v-model="formData[c.id]" :datas="c.details" keyName="id" titleName="name" type="object">
              <template #show="{value}">
                [{{ value.code }}] {{ value.name }}
              </template>
              <template #item="{item}">
                [{{ item.code }}] {{ item.name }}
              </template>
            </Select>
            <Button icon="fa fa-plus" @click="newAuxiliary(c)"></Button>
          </div>
        </FormItem>
      </Form>
    </div>
    <div class="modal-column-btn">
      <vxe-button :loading="loading" content="取消" @click="$emit('close')"/>
      <vxe-button :loading="loading" content="确认" status="primary" @click="onConfirm"/>
    </div>
  </div>
</template>

<script>
import setting from "@api/setting";
import {h} from "vue";
import {layer} from "@layui/layer-vue";
import VTableAuxiliaryFastForm from "@components/VoucherTable/VTableAuxiliaryFastForm.vue";

/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
export default {
  name: "VTableAuxiliary",
  props: {
    subject: Object,
    auxiliary: Array
  },
  data() {
    return {
      loading: false,
      formData: {},
      categoryList: []
    }
  },
  methods: {
    newAuxiliary(category) {
      let layerId = layer.open({
        title: category.name + "信息",
        shadeClose: false,
        closeBtn: false,
        zIndex: 1000,
        area: ['400px', 'auto'],
        content: h(VTableAuxiliaryFastForm, {
          category,
          onClose: () => {
            layer.close(layerId);
          },
          onSuccess: () => {
            layer.close(layerId);
            this.loadCategoryList();
          }
        })
      });
    },
    loadCategoryList() {
      this.loading = true;
      setting.accountingCategory.listByIds(this.subject.auxiliaryAccounting).then(({data}) => {
        this.categoryList = data;
        let formData = {};
        data.forEach(d => {
          formData[d.id] = null;
        })
        this.formData = Object.assign(formData, this.formData);
      }).finally(() => this.loading = false)
    },
    onConfirm() {
      let validResult = this.$refs.form.valid();
      if (validResult.result) {
        this.$emit('success', Object.values(this.formData))
      }
    },
    doFilter(item, val) {
      return item.code.indexOf(val) !== -1 || item.name.indexOf(val) !== -1 || item.mnemonics.indexOf(val) !== -1;
    }
  },
  created() {
    if (this.auxiliary) {
      this.auxiliary.forEach(val => {
        this.formData[val.categoryId] = val;
      })
    }
    this.loadCategoryList();
  }
}
</script>

<style scoped>

</style>
