<template>
  <div class="modal-column">
    <div class="flex-1 p-20px">
      <Form ref="form" :model="form" :rules="{}" labelWidth="150" mode="twocolumn" >
        <FormItem label="编码" prop="code" required>
          <Input type="text" v-model="form.code"/>
        </FormItem>
        <FormItem label="名称" prop="name" required>
          <Input type="text" v-model="form.name"/>
        </FormItem>
        <FormItem label="折旧方法" prop="depreciationMethod" required>
          <Select v-model="form.depreciationMethod" :deletable="false" :datas="depreciationMethods" keyName="code" titleName="name"></Select>
        </FormItem>
        <FormItem label="使用期限" prop="useMonth" required>
          <Input type="text" v-model="form.useMonth"/>
        </FormItem>
        <FormItem label="残值率" prop="residualRate" required>
          <Input type="text" v-model.number="form.residualRate"/>
        </FormItem>
        <FormItem label="固定资产科目" prop="costAccount" required>
            <Select :datas="subjectList" :deletable="false" keyName="code" titleName="name" filterable v-model="form.costAccount" :filter="filter" >
            <template v-slot:item="{ item }">
              {{ item.code }} {{ item.name }}
            </template>
          </Select>
        </FormItem>
        <FormItem label="累计折旧科目" prop="depreciationAccount" required>
          <Select :datas="subjectList" :deletable="false" keyName="code" titleName="name" filterable v-model="form.depreciationAccount" :filter="filter" >
            <template v-slot:item="{ item }">
              {{ item.code }} {{ item.name }}
            </template>
          </Select>
        </FormItem>
        <FormItem label="备注" prop="remark" >
          <Input type="text" v-model="form.remark"/>
        </FormItem>
      </Form>
    </div>
    <div class="modal-column-btn" style="background: none;color: #2FA8FD;">
      提示：资产类别中设置的科目，在新增卡片时会默认带到卡片上，带入后仍可以修改哦~
    </div>
    <div class="modal-column-btn" style="background: none;">
      <!-- <Button @click="$emit('close')">取消</Button> -->
      <!-- 当这个组件不是做为layui组件方式传入触发的父组件close函数， -->
      <Button @click="$emit('close')">取消</Button>
      <Button color="primary" @click="submit" :loading="loading">{{ form.id ? '更新' : '保存' }}</Button>
    </div>
  </div>
</template>

<script>/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
import AssetsType from "@/api/AssetsType";
import Setting from "@/api/setting";
import {CopyObj} from "@/js/common/utils";

export default {
  name: "AssetsTypeForm",
  props: {
    type: String,
    nextCode: String,
    entity: Object,
  },
  data() {
    return {
      loading: false,
      depreciationMethods:[
          { name: '平均年限法', code: 0 },
          { name: '不折旧', code: 1 }],
      form: {
        id: null,
        code: null,
        name: null,
        depreciationMethod:0,
        useMonth: null,
        residualRate:null,
        costAccount: "1602",
        depreciationAccount: "1601",
      },
      currencyList: [],
      subjectList: []
    }
  },
  methods: {
   
    filter(item, val) {
      return item.code.indexOf(val) !== -1 || item.name.indexOf(val) !== -1 || item.mnemonicCode.indexOf(val) !== -1;
    },
    submit() {
      let validResult = this.$refs.form.valid();
      if (validResult.result) {
        this.loading = true;
        AssetsType.save(this.form).then(() => {
          this.$emit('success');
        }).finally(() => {
          this.loading = false;
        })
      }
    }
  },
  created() {
    console.log('zujian')
    Setting.subject.voucherSelect().then(({data}) => {
      this.subjectList = data || [];
    })
    Setting.currency.list().then(({data}) => {
      this.currencyList = data || [];
    })

    CopyObj(this.form, this.entity);
    if (!this.entity) {
      this.form.code = this.nextCode;
    }
  }
}
</script>
