<template>
	<div class="modal-column">
		<div class="p-8px">
			科目： {{ subject.subjectFullName }}，币别：{{ currency.name }}
		</div>
		<div class="p-8px flex-1 h-0px">
			<vxe-table ref="xTable" height="auto"
								 :data="auxiliaryList"
								 :edit-rules="editRules"
								 @beforeEditMethod="beforeEditMethod"
								 :edit-config="{trigger:'click',mode:'row'}">
				<vxe-column type="seq" width="50"/>
				<vxe-column :title="c.name" :field="c.id" v-for="c in categoryList" :edit-render="{}">
					<template #default="{row}">
						<span v-if="row[c.id]">{{ c.details.find(val => val.id === row[c.id]).name }}</span>
					</template>
					<template #edit="{row}">
						<vxe-select v-model="row[c.id]" :options="c.details" filterable :option-props="{value:'id', label:'name'}"/>
					</template>
				</vxe-column>
				<vxe-column width="60">
					<template #default="{row,$rowIndex}">
						<i v-if="auxiliaryList.length-1===$rowIndex" class="fa fa-plus mr-16px text-hover green-color" @click="newRow($rowIndex)"></i>
						<i v-if="auxiliaryList.length>1 && !row.used" class="fa fa-trash text-hover red-color" @click="rmRow($rowIndex)"></i>
					</template>
				</vxe-column>
			</vxe-table>
		</div>
		<div class="modal-column-between">
			<Button @click="$emit('close')" :loading="loading" icon="fa fa-close">取消</Button>
			<Button @click="onSave" icon="fa fa-save" :loading="loading" color="primary">保存</Button>
		</div>
	</div>
</template>

<script>
/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
export default {
	name: "InitialAuxiliary",
}
</script>

<script setup>
import {defineProps, defineEmits, ref, reactive} from "vue"
import setting from "@/api/setting";
import {message} from "heyui.ext";

const props = defineProps(['subject', 'currency']);
const emits = defineEmits(['success', 'close']);

const auxiliaryInitList = [];
props.subject.children.forEach(val => {
	if (val.auxiliaryDetailId !== '0') {
		let row = {
			used: val.used
		};
		val.details.forEach(a => {
			row[a.categoryId] = a.value;
		})
		auxiliaryInitList.push(row);
	}
})

const loading = ref(false)
const xTable = ref(null)
const auxiliaryList = ref(auxiliaryInitList)
const auxiliaryMap = ref({})
const categoryList = ref([])
const editRules = ref({})


setting.accountingCategory.listByIds(props.subject.auxiliaryAccounting).then(({data}) => {
	categoryList.value = data;
	let rules = {};
	data.forEach(d => {
		auxiliaryMap[d.id] = d;
		rules[d.id] = [{required: true, message: `请选择${d.name}`}]
	})
	editRules.value = rules;
	if (!auxiliaryInitList.length) {
		newRow();
	}
})


const newRow = (index = 0) => {
	let row = {};
	let rows = auxiliaryList.value;
	categoryList.value.forEach(it => {
		row[it.id] = null;
	})
	rows.push(row)
	xTable.value.loadData(rows);
}

const rmRow = (index) => {
	if (auxiliaryList.value.length > 1) {
		auxiliaryList.value.splice(index, 1)
		xTable.value.loadData(auxiliaryList.value);
	}
}

const onSave = async () => {
	const $table = xTable.value
	const errMap = await $table.validate(true)
	if (!errMap) {
		const {fullData} = $table.getTableData();
		const set = new Set(fullData.map(val => props.subject.auxiliaryAccounting.map(a => val[a]).join("_")));
		if (set.size !== fullData.length) {
			message("不允许出现重复行~");
			return
		}
		loading.value = true;
		const details = fullData.map(val => {
			const rows = [];
			props.subject.auxiliaryAccounting.forEach(a => {
				rows.push({
					categoryId: a,
					categoryType: auxiliaryMap[a].categoryType,
					value: val[a],
				})
			})
			return rows;
		});
		setting.initialBalance.saveAuxiliary({
			subjectId: props.subject.subjectId,
			currencyId: props.currency.id,
			details
		}).then(() => {
			emits('success')
		}).finally(() => loading.value = false)
	}
}

const beforeEditMethod = ({row}) => {
	return !row.used;
}
</script>
