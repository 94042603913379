<template>
    <app-content class="h-panel">
        <div style="display: flex;flex-direction: column;align-items: center;justify-content: center">
            <div style="font-size: 32px;font-weight: bold;color: #333333;margin-top: 60px">
                帮您智能生成一份老板都看得懂的报表
            </div>
            <div style="font-size: 24px;margin-top:10px;margin-bottom: 40px; color: #666666">
                智能财务分析报告，助您轻松和老板汇报
            </div>
            <Button color="primary" @click="nowApprove();">立即授权</Button>
            <div style="margin-top: 40px;display: flex;width: 90%;justify-content: space-between">
                <div class="bossItemBg">
                    <div style="display: flex;flex-direction: column">
                        <div style="font-size: 24px;margin-top: 15px">老板看账亮点：</div>
                        <div style="font-size: 18px;margin-top: 30px">
                            <Checkbox checked> 财务报告自动生成</Checkbox>
                        </div>
                        <div style="font-size: 18px;margin-top: 30px">
                            <Checkbox checked> 财务数据通俗易懂</Checkbox>
                        </div>
                        <div style="font-size: 18px;margin-top: 30px">
                            <Checkbox checked> 收入费用一目了然</Checkbox>
                        </div>
                    </div>
                    <div style="display: flex;justify-content: center;align-items: center">
                        <img src="../../assets/boss_phone.png" style="height: 180px;width: 180px">
                    </div>
                </div>
                <div class="bossItemBg">
                    <div>
                        <div style="display: flex;flex-direction: column">
                            <div style="font-size: 24px;margin-top: 15px">【缔造梦】公众号：</div>
                            <div style="font-size: 18px;margin-top: 30px">
                                微信扫码关注，并推送给老板
                            </div>

                            <div style="font-size: 18px;margin-top: 30px">
                                轻松向老板汇报企业经营情况~
                            </div>
                        </div>
                    </div>
                    <div style="display: flex;justify-content: center;align-items: center">
                        <img src="../../assets/boss_code.png" style="height: 180px;width: 180px">
                    </div>
                </div>
            </div>
        </div>

    </app-content>
</template>

<script>


export default {
    name: 'BossSee',
    data() {
        return {};
    },
    computed: {},
    watch: {},
    methods: {
        nowApprove() {
            this.$store.commit('newTab', {key: "Approve", title: "账套"})

        },

    },
    mounted() {
    }
};
</script>
<style>
.bossItemBg {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    width: 550px;
    height: 288px;
    background: rgba(47, 168, 253, 0.06);
    box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.16);
    border-radius: 0px 0px 4px 4px;
}
</style>
