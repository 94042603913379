<template>
	<a-accounting-top-bar
					v-model:filter="filter"
					@showForm="showForm"
					:category="category"
					@search="doSearch"/>
	<div class="flex-1">
		<vxe-table ref="xTable" height="auto" :data="dataList"  show-overflow :loading="loading">
			<vxe-column title="客户编码" field="code" :min-width="100" :width="120"/>
			<vxe-column title="客户名称" field="name" :width="150"/>
			<vxe-column title="助记码" field="mnemonics" :width="150"/>
			<vxe-column title="客户类别" field="category" :width="150"/>
			<vxe-column title="经营地址" field="address" :width="160"/>
			<vxe-column title="联系人" field="contact" :width="150"/>
			<vxe-column title="手机" field="telPhone" :width="120"/>
			<vxe-column title="税号" field="creditCode" :width="150"/>
			<vxe-column title="备注" field="remark" width="120"/>
			<vxe-column title="操作" :width="120" align="center" fixed="right">
				<template #default="{row}">
					<div class="actions">
						<span @click="showForm(row)">编辑</span>
						<span @click="remove(row)">删除</span>
					</div>
				</template>
			</vxe-column>
		</vxe-table>
	</div>
	<vxe-pager v-model:current-page="pageInfo.page" v-model:page-size="pageInfo.pageSize" v-model:total="pageInfo.total"/>
</template>

<script>
/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
import AAccountingTopBar from "@/views/setting/AssistingAccounting/AAccountingTopBar.vue";

export default {
	name: "Customer",
	components: {AAccountingTopBar}
}
</script>

<script setup>
import {ref, defineProps, h} from "vue";
import {usePageInject} from "./AAccountingCrud";
import {Customer} from "@/api/Auxiliary";
import {layer} from "@layui/layer-vue";
import CustomerForm from "@/views/setting/AssistingAccounting/form/CustomerForm.vue";
import {confirm} from "heyui.ext";

let props = defineProps(['category']);
const dataList = ref([]);
const filter = ref(null);

const loadList = () => {
	return Customer.list(Object.assign({filter: filter.value}, pageParams.value)).then(({data}) => {
		dataList.value = data.results;
		pageInfo.total = data.total;
	})
}

const {
	pageInfo,
	doSearch,
	pageParams,
	loading
} = usePageInject(loadList);

doSearch();

const showForm = (entity) => {
	let layerId = layer.open({
		title: "客户信息",
		shadeClose: false,
		closeBtn: false,
		zIndex: 1000,
		area: ['800px', 'auto'],
		content: h(CustomerForm, {
			entity,
			category: props.category,
			onClose: () => {
				layer.close(layerId);
			},
			onSuccess: () => {
				layer.close(layerId);
				doSearch();
			}
		})
	});
}


const remove = (entity) => {
	confirm({
		content: `确认删除${entity.name}？`,
		async: true,
		onConfirm: (resolve) => {
			Customer.delete(entity.id).then(() => {
				resolve(true)
				loadList();
			}).catch(() => resolve(false))
		}
	})
}
</script>
