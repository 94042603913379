<template>
    <div >
        <div v-if="showDialog" class="zmsundialog-overlay finjz-guiDang-viewer-overlay" style="z-index: 798;"></div>
        <div v-if="showDialog" class="zmsundialog finjz-guiDang-viewer"
             style="width: 460px;top: 10%;left: 653px;z-index: 799;">
            <div class="zmsundialog-titlebar">
                <div class="zmsundialog-title-box">
                    <span class="zmsundialog-title">归档列表</span>
                </div>
                <div class="zmsundialog-titlebar-close">
                    <span class="zmsundialog-closetext" @click="closeDialog">×</span>
                </div>
            </div>
            <div class="zmsundialog-content" style="width: 460px;">
                <div class="finjz-guiDang-dialog-mbox">
                    <div class="finjz-guiDang-dialog-content">
                        <div class="listItems">
                            <div style="width: 100%;display: flex;align-items: center;"><span>年归档列表：</span>
                                <input class="allCheck" type="checkbox" style="margin-right:4px;" v-model="isAllSelectedY">全选
                            </div>
                            <div v-for="(item, index) in yearGDList" :key="index">
                                <div class="listItem">
                                    <input v-model="selectedListY" :value="item.key" class="checkbox" type="checkbox">{{ item.name }}
                                </div>
                            </div>
                        </div>
                        <div class="listItems">
                            <div style="width: 100%;display: flex;align-items: center;"><span>月归档列表：</span>
                                <input class="allCheck1" type="checkbox" style="margin-right:4px;" v-model="isAllSelectedM">全选
                            </div>
                            <div v-for="(item, index) in monthGDList" :key="index">
                                <div class="listItem">
                                    <input v-model="selectedListM" :value="item.key" class="checkbox" type="checkbox">{{ item.name }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="guiDang-btn-box">
                        <button class="th-btn-thick-fz finjz-guiDang-dialog-cancle" @click="closeDialog">取消</button>
                        <button class="th-btn-deep finjz-guiDang-dialog-submit" style="margin-left:12px;"
                                @click="submitDialog">确定
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="pzGuiDangPlug">
            <div class="viewHeader"><h3 class="viewTitle">归档管理</h3>
                <div class="fnBtns">
                    <ul class="clearfix">
                        <li class="guidangBtn th-btn-deep-fz" style="display: list-item;" @click="showDialogData">归档</li>
                    </ul>
                </div>
            </div>
            <div class="viewContainer" :style="{height: viewContainerHeight + 'px'}">
                <div class="dataLeft">
                    <div class="treeNavPlug">
                        <div class="treeItems">
                            <ul>
                                <li v-for="(item, index) in monthDataList">
                                    <div v-if="item.type === 'year'" @click="checkMonth(item.date)">
                                        <div v-if="item.date === entryDate" class="treeItem treeItemTop active">
                                            <div class="mark"></div>
                                            <span>{{ item.label }}</span>
                                        </div>
                                        <div v-else class="treeItem treeItemTop">
                                            <div class="mark"></div>
                                            <span>{{ item.label }}</span>
                                        </div>
                                    </div>
                                    <div v-else @click="checkMonth(item.date)">
                                        <div v-if="item.date === entryDate" class="treeItem treeItemSub active">
                                            <div class="mark"></div>
                                            <span>{{ item.label }}</span>
                                        </div>
                                        <div v-else class="treeItem treeItemSub">
                                            <div class="mark"></div>
                                            <span>{{ item.label }}</span>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="dataRight">
                    <div v-if="guiDangList.length === 0" class="noDataMask" style="display: block;">
                        <div class="tipMsg">您还没有归档，请在左侧选择年或者月，点击右上角的『归档』</div>
                    </div>
                    <div v-else>
                        <div v-if="loading" class="procDataMask" style="display: block;">
                            <div class="tipMsg"></div>
                        </div>
                        <div v-if="selectedGuiDang.length !== 0" class="slecTT1" style="display: block;">
                            <span class="slecImA">已选中<span class="slecImB">{{ selectedGuiDang.length }}</span>个文件</span>
                            <a class="downloadBtn downloadExcelBtn" @click="exportData(selectedGuiDang)">EXCEL下载</a>
                            <a class="downloadBtn downloadPdfBtn" @click="print(selectedGuiDang)">PDF下载</a>
                        </div>
                        <div class="finjz_table" style="">
                            <div class="gridBox" :style="{height: viewContainerHeight + 'px'}">
                                <div class="finjz_table_mtbl">
                                    <div class="finjz_table_hpart_box">
                                        <div class="grid-table-head">
                                            <table class="dgrid-row-table">
                                                <colgroup>
                                                    <col width="517px">
                                                    <col width="135px">
                                                    <col>
                                                </colgroup>
                                                <thead>
                                                <tr>
                                                    <th><input class="fullsch" type="checkbox" v-model="isSelectedGuiDang"><span class="fullspn">文件名称</span></th>
                                                    <th>大小</th>
                                                    <th>归档日期</th>
                                                </tr>
                                                </thead>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="grid-panel" :style="{height: viewContainerHeight + 'px'}">
                                        <table class="dgrid-row-table">
                                            <colgroup>
                                                <col width="517px">
                                                <col width="135px">
                                                <col>
                                            </colgroup>
                                            <tbody class="mConBox">
                                            <tr v-for="item in guiDangList" class="dgrid-row">
                                                <td style="" class="dgrid-cell">
                                                    <input type="checkbox" class="sigsch" v-model="selectedGuiDang" :value="item.date">
                                                    <span v-if="!item.url_pdf" class="edit-text archive-dir" @click="checkMonth(item.date)">{{ item.label }}</span>
                                                    <span v-else class="archive-file">{{ item.label }}</span>
                                                    <!--                                                        <a v-if="item.url_xlsx" class="file-exceldown popTip" title="EXCEL下载" :href="item.url_xlsx" target="_blank"></a>-->
                                                    <a v-if="item.url_xlsx" class="file-exceldown popTip" title="EXCEL下载" @click="exportData(item)" target="_blank"></a>
                                                    <a v-if="item.url_pdf" class="file-look popTip" title="PDF查看" :href="item.url_pdf" target="_blank"></a>
                                                    <a v-if="item.url_pdf" class="file-down popTip" title="PDF下载" @click="print(item)" target="_blank"></a>
                                                </td>
                                                <td style="" class="dgrid-cell text-center">{{ item.fileSize }}</td>
                                                <td style="" class="dgrid-cell text-center">{{ item.arc_date }}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import BooUtil from "@/views/accountbook/bookUtil";
    import {downloadBlob} from "download.js";
    import ArchiveManagement from "@/api/ArchiveManagement";
    import manba from "manba";

    export default {
        name: 'ArchiveManagement',
        data() {
            return {
                guiDangList: [],
                showDialog: false,
                yearGDList: [
                    {key: 'yearzongzhang', name: '总账'},
                    {key: 'yearmingxi', name: '明细账'},
                    {key: 'yearyue', name: '余额表'},
                    {key: 'yearxushiz', name: '序时账'},
                    {key: 'yearfuzhuhs_mxz', name: '辅助核算明细帐'},
                    {key: 'yearfuzhuhs_mx_cunhuo', name: '存货数量明细帐'},
                    {key: 'yearfuzhuhs_mx_mea', name: '数量明细账'},
                    {key: 'yearmingxi_waib', name: '外币明细账'},
                    {key: 'yearmingxi_waib_mea', name: '数量外币明细账'},
                ],
                monthGDList: [
                    {key: 'monthxjliuliang', name: '现金流量表'},
                    {key: 'monthzcfz', name: '资产负债表'},
                    {key: 'monthmingxi', name: '明细账'},
                    {key: 'monthlirun', name: '利润表'},
                    {key: 'monthyue', name: '余额表'},
                    {key: 'monthpz', name: '凭证'},
                    {key: 'monthxushiz', name: '序时账'},
                    {key: 'monthfuzhuhs_mxz', name: '辅助核算明细帐'},
                    {key: 'monthfuzhuhs_mx_cunhuo', name: '存货数量明细帐'},
                    {key: 'monthfuzhuhs_yue', name: '辅助核算余额表'},
                    {key: 'monthfuzhuhs_yue_ch', name: '存货数量余额表'},
                    {key: 'monthfuzhuhs_mx_mea', name: '数量明细账'},
                    {key: 'monthfuzhuhs_yue_mea', name: '数量余额表'},
                    {key: 'monthmingxi_waib', name: '外币明细账'},
                    {key: 'monthmingxi_waib_mea', name: '数量外币明细账'},
                ],
                selectedListY: [],
                selectedListM: [],
                isAllSelectedY: false,
                isAllSelectedM: false,
                selectedGuiDang: [],
                isSelectedGuiDang: false,
                loading: false,
                viewContainerHeight: window.innerHeight,
                monthDataList: [],
                entryDate: new Date().getFullYear()
            };
        },
        watch: {
            // 监听全选按钮状态
            isAllSelectedY: function (val) {
                if (val) {
                    this.selectedListY = this.yearGDList.map(item => item.key);
                } else {
                    this.selectedListY = [];
                }
            },
            isAllSelectedM: function (val) {
                if (val) {
                    this.selectedListM = this.monthGDList.map(item => item.key);
                } else {
                    this.selectedListM = [];
                }
            },
            isSelectedGuiDang: function (val) {
                if (val) {
                    this.selectedGuiDang = this.guiDangList.map(item => item.date);
                } else {
                    this.selectedGuiDang = [];
                }
            },
        },
        methods: {
            handleResize() {
                this.viewContainerHeight = window.innerHeight - 190
            },
            queryMonth() {
                // ArchiveManagement.queryMonthList().then(() => {
                //
                // })
                this.monthDataList = [
                    {type: "year", date: 2022, changed: false, label: "2022年", nodata: false},
                    {type: "month", date: "2022-01", changed: false, label: "1月", nodata: true},
                    {type: "month", date: "2022-02", changed: false, label: "2月", nodata: true},
                    {type: "month", date: "2022-03", changed: false, label: "3月", nodata: true},
                    {type: "month", date: "2022-04", changed: false, label: "4月", nodata: true},
                    {type: "month", date: "2022-05", changed: false, label: "5月", nodata: true},
                    {type: "month", date: "2022-06", changed: false, label: "6月", nodata: true},
                    {type: "month", date: "2022-07", changed: false, label: "7月", nodata: true},
                    {type: "month", date: "2022-08", changed: false, label: "8月", nodata: true},
                    {type: "month", date: "2022-09", changed: false, label: "9月", nodata: true},
                    {type: "month", date: "2022-10", changed: false, label: "10月", nodata: true},
                    {type: "month", date: "2022-11", changed: false, label: "11月", nodata: true},
                    {type: "month", date: "2022-12", changed: false, label: "12月", nodata: true},
                    {type: "year", date: 2023, changed: false, label: "2023年", nodata: false},
                    {type: "month", date: "2023-01", changed: false, label: "1月", nodata: false},
                    {type: "month", date: "2023-02", changed: false, label: "2月", nodata: false},
                    {type: "month", date: "2023-03", changed: false, label: "3月", nodata: false},
                    {type: "month", date: "2023-04", changed: false, label: "4月", nodata: true},
                    {type: "month", date: "2023-05", changed: false, label: "5月", nodata: true},
                    {type: "month", date: "2023-06", changed: false, label: "6月", nodata: true},
                    {type: "month", date: "2023-07", changed: false, label: "7月", nodata: true}
                ]
            },
            checkMonth(data) {
                this.entryDate = data
                this.loadList()
            },
            loadList() {
                // ArchiveManagement.queryGuiDangList({"date": this.entryDate}).then(({data}) => {
                //     this.dataList = data || [];
                // })
                if (this.entryDate === '2023-07') {
                    this.guiDangList = [
                        {
                            arc_date: "2023-08-2",
                            assoc: "month",
                            date: "2023-02",
                            fileSize: "34.73KB",
                            iiden: "辅助核算余额表",
                            label: "辅助核算余额表",
                            url_pdf: "/zm_pub/finjz/archive/1023894/2023/08/10/arc_mingxi_64d49ec11171d929266563.pdf",
                            url_xlsx: "/zm_pub/finjz/archive/1023894/2023/08/10/arc_mingxi_64d49ec11171d929266563.xlsx"
                        },
                        {
                            arc_date: "2023-08-4",
                            assoc: "month",
                            date: "2023-02",
                            fileSize: "34.73KB",
                            iiden: "资产负债表",
                            label: "资产负债表",
                            url_pdf: "/zm_pub/finjz/archive/1023894/2023/08/10/arc_mingxi_64d49ec11171d929266563.pdf",
                            url_xlsx: "/zm_pub/finjz/archive/1023894/2023/08/10/arc_mingxi_64d49ec11171d929266563.xlsx"
                        }
                    ]
                } else {
                    this.guiDangList = [
                        {
                            arc_date: "2023-08-10",
                            assoc: "year-month",
                            date: "2023-07",
                            fileSize: "53.55KB",
                            iiden: "7",
                            label: "7月"
                        },
                        {
                            arc_date: "2023-08-10",
                            assoc: "year",
                            date: "2023",
                            fileSize: "34.73KB",
                            iiden: "明细账",
                            label: "明细账",
                            url_pdf: "/zm_pub/finjz/archive/1023894/2023/08/10/arc_mingxi_64d49ec11171d929266563.pdf",
                            url_xlsx: "/zm_pub/finjz/archive/1023894/2023/08/10/arc_mingxi_64d49ec11171d929266563.xlsx"
                        }
                    ]
                }
                this.loading = false
            },
            showDialogData() {
                this.showDialog = true
            },
            closeDialog() {
                this.showDialog = false
            },
            submitDialog() {
                this.showDialog = false;
                this.loading = true;
                // 接口
                // ArchiveManagement.saveGuiDangData({
                //     "date": this.entryDate,
                //     "list": this.selectedListY.concat(this.selectedListM)
                // }).then(() => {
                //     setTimeout(() => {
                //         this.loadList()
                //     }, 1000)
                // })
                setTimeout(() => {
                    this.loadList()
                }, 2000)
            },
            exportData(item) {
                ArchiveManagement.download(item).then((data) => {
                    downloadBlob(item.label + `.xls`, data)
                })
            },
            print(item) {
                ArchiveManagement.print(item).then((data) => {
                    downloadBlob(item.label + `.pdf`, data)
                })
            },
            scrollToBottom: function () {
                this.$nextTick(() => {
                    var container = this.$el.querySelector(".dataLeft");
                    container.scrollTop = container.scrollHeight;
                })
            }
        },
        created() {
            this.loadList();
        },
        mounted() {
            this.queryMonth();
            this.handleResize();
            this.scrollToBottom();
            window.addEventListener('resize', this.handleResize)
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.handleResize)
        },
    };
</script>

<style lang="less" scoped>
    .zmsundialog-overlay {
        position: fixed;
        position: absolute \0;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #000;
        opacity: 0.3;
        filter: progid:DXImageTransform.Microsoft.Alpha(opacity=30);
        z-index: 798;
    }

    .zmsundialog {
        box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 5px;
        position: absolute;
        background-color: white;
        z-index: 799;
        width: 400px;
        font-family: Microsoft YaHei, tahoma, arial;
    }

    .zmsundialog-titlebar {
        overflow: hidden;
        position: relative;
        border-bottom: 1px solid #dadde0;
    }

    .zmsundialog-title-box {
        font-size: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 80%;
        float: left;
    }

    .zmsundialog-title-box span {
        float: left;
        height: 40px;
        color: #212a31;
        overflow: hidden;
        margin: 0;
        padding-left: 20px;
        font-size: 14px;
        line-height: 40px;
    }

    .zmsundialog-titlebar-close {
        cursor: pointer;
        line-height: 40px;
        width: 40px;
        height: 40px;
        text-align: center;
        float: right;
        position: relative;
        overflow: hidden;
    }

    .zmsundialog-closetext {
        font-size: 24px;
        color: #a2a2a2;
        font-family: Arial;
    }

    .zmsundialog-content {
        position: relative;
        background-color: #FFF;
    }

    .finjz-guiDang-viewer .finjz-guiDang-dialog-content .listItems {
        display: flex;
        padding: 23px;
        align-items: center;
        flex-wrap: wrap;
    }

    .finjz-guiDang-viewer .finjz-guiDang-dialog-content .listItems:last-child {
        padding-top: 0px;
    }

    .finjz-guiDang-viewer .finjz-guiDang-dialog-content .listItems .listItem {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px 6px 6px 0px;
    }

    .finjz-guiDang-viewer .guiDang-btn-box {
        display: flex;
        justify-content: flex-end;
        margin-right: 26px;
        margin-bottom: 10px;
    }

    .th-btn, .th-btn-thick, .th-btn-cancel, .th-btn-deep, .th-btn-confirm, .th-btn-thick-fz, .th-btn-deep-fz {
        position: relative;
        text-align: center;
        cursor: pointer;
        display: inline-block;
        *display: inline;
        *zoom: 1;
        border-radius: 3px;
        font-size: 14px;
        height: 30px;
        line-height: 30px;
        padding-left: 16px;
        padding-right: 16px;
        user-select: none;
        outline: none;
    }

    .th-btn-thick-fz {
        color: #2080f0 !important;
        border: 1px solid #2080f0 !important;
        background: transparent !important;
    }

    .th-btn-deep, .th-btn-confirm {
        color: white !important;
        border: 1px solid #2080f0 !important;
        background: #2080f0 !important;
    }

    .pzGuiDangPlug {
        position: relative;
        width: 1000px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 30px;
    }

    .viewHeader {
        position: relative;
        height: 45px;
    }

    .viewTitle {
        font-size: 24px;
        color: #333;
        font-weight: normal;
        line-height: 40px;
        text-align: center;
    }

    .fnBtns {
        position: absolute;
        right: 0px;
        top: 5px;
    }

    .pzGuiDangPlug .clearfix {
        display: block;
    }

    .guidangBtn {
        font-size: 12px;
        padding: 0 12px;
        display: none;
    }

    .th-btn-deep-fz {
        color: white !important;
        border: 1px solid #2080f0 !important;
        background: #2080f0 !important;
        line-height: 30px;
        border-radius: 5px;
    }

    .pzGuiDangPlug .viewContainer {
        position: relative;
    }

    .dataLeft {
        position: absolute;
        width: 190px;
        bottom: 9px;
        left: 0;
        top: 0;
        overflow-y: auto;
        border-right: 1px solid #eff0f0;
        background-color: #fff;
        height: 100%;
    }

    .treeNavPlug {
        position: relative;
    }

    .treeItem:hover, .treeNavPlug .treeItem.active {
        color: #000000;
        background-color: #c6eefd;
    }

    .treeNavPlug .treeItemTop {
        font-size: 14px;
    }

    .treeNavPlug .treeItem {
        position: relative;
        margin-left: 40px;
        height: 30px;
        line-height: 30px;
        cursor: pointer;
    }

    .treeNavPlug .treeItem .mark {
        background-image: url('../../assets/file-active.svg');
        background-repeat: no-repeat;
        background-position: center;
        width: 18px;
        height: 18px;
        display: inline-block;
        margin-right: 10px;
        vertical-align: sub;
    }

    .treeNavPlug .treeItemSub {
        padding-left: 20px;
    }

    .treeNavPlug .treeItemSub .mark {
        background-image: url('../../assets/file-close.svg');
    }


    .pzGuiDangPlug .dataRight {
        height: 100%;
        background-color: white;
        width: 800px;
        float: right;
        position: relative;
    }

    .pzGuiDangPlug .noDataMask {
        background: url('../../assets/img-file.default.svg') no-repeat center 138px white;
    }
    .pzGuiDangPlug .noDataMask, .pzGuiDangPlug .procDataMask {
        position: absolute;
        top: 34px;
        bottom: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        display: none;
    }
    .pzGuiDangPlug .noDataMask .tipMsg {
        text-align: center;
        font-size: 18px;
        margin-top: 80px;
    }

    .pzGuiDangPlug .procDataMask {
        background: url('../../assets/img-file-wawa-new.default.svg') no-repeat center 60px white;
        background-size: 200px;
    }
    .pzGuiDangPlug .procDataMask .tipMsg {
        text-align: center;
        font-size: 18px;
        margin-top: 36px;
        height: 36px;
        background: url('../../assets/img-file-hkj-tips.png') no-repeat center;
    }

    .pzGuiDangPlug .slecTT1 {
        position: absolute;
        width: 775px;
        height: 34px;
        background-color: #2080f0;
        top: 0;
        left: 25px;
        z-index: 1;
        display: none;
    }
    .pzGuiDangPlug .slecTT1 .slecImA {
        line-height: 38px;
        color: white;
        font-size: 14px;
        padding-left: 10px;
    }
    .pzGuiDangPlug .slecTT1 .downloadExcelBtn {
        margin-left: 30px;
    }
    .pzGuiDangPlug .slecTT1 .downloadPdfBtn {
        margin-left: 10px;
    }
    .pzGuiDangPlug .slecTT1 .downloadBtn {
        display: inline-block;
        padding: 5px 10px;
        text-align: center;
        border: 1px solid #ffffff;
        border-radius: 3px;
        width: 100px;
        color: white;
        cursor: pointer;
        font-size: 14px;
    }

    .finjz_table .gridBox {
        background-color: white;
        overflow-y: hidden;
        overflow-x: auto;
    }
    .finjz_table .finjz_table_mtbl {
        position: relative;
        display: table;
        width: 100%;
    }
    .finjz_table .finjz_table_hpart_box {
        position: relative;
        width: 100%;
        box-sizing: border-box;
        background-color: #2080f0;
    }
    .finjz_table .grid-table-head {
        background: #2080f0;
        padding: 0 10px;
        clear: both;
    }
    .pzGuiDangPlug .fullsch {
        margin-top: 10px;
        margin-right: 5px;
    }
    .pzGuiDangPlug .fullsch, .pzGuiDangPlug .fullspn {
        float: left;
    }
    .finjz_table .dgrid-row-table thead th {
        font-size: 14px;
        padding: 0;
        text-align: center;
        height: 34px;
        line-height: 34px;
        color: #fff;
        background-color: #2080f0;
    }
    .pzGuiDangPlug .finjz_table .grid-panel {
        padding-left: 0;
        padding-right: 0;
    }
    .finjz_table .grid-panel {
        overflow-y: auto;
        overflow-x: hidden;
        position: relative;
        min-height: 50px;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: 0;
        padding: 0 10px;
    }
    .finjz_table .dgrid-row-table, .finjz_table .dgrid-row-box>table {
        border-collapse: separate;
        width: 100%;
        table-layout: fixed;
        empty-cells: show;
        position: relative;
    }

    .finjz_table .gridBox .dgrid-cell {
        vertical-align: top;
        border-top-style: none;
        box-sizing: border-box;
        border-left: 0;
        height: 28px;
        line-height: 28px;
        border-right: 1px solid #d1d1d1;
        border-bottom: 1px solid #d1d1d1;
        white-space: nowrap;
        text-overflow: ellipsis;
        outline: none;
        font-size: 12px;
        padding: 3px;
        color: #333;
        position: relative;
    }
    .pzGuiDangPlug .sigsch {
        margin-left: 10px;
        margin-right: 10px;
    }
    .pzGuiDangPlug .archive-dir {
        padding-left: 24px;
        background: url('../../assets/archive_dir.svg') left center no-repeat;
    }
    .pzGuiDangPlug .edit-text {
        color: #464646;
        text-decoration: underline;
        cursor: pointer;
    }
    .finjz_table .gridBox .text-center {
        text-align: center;
    }
    .pzGuiDangPlug .archive-file {
        padding-left: 24px;
        background: url('../../assets/archive_file.svg') left center no-repeat;
    }
    .pzGuiDangPlug .file-exceldown {
        position: absolute;
        width: 21px;
        height: 21px;
        right: 70px;
        top: 6px;
        background: url('../../assets/icon-exceldown.svg') no-repeat;
    }
    .pzGuiDangPlug .file-look {
        position: absolute;
        width: 21px;
        height: 21px;
        right: 10px;
        top: 6px;
        background: url('../../assets/icon-lookdown.svg') no-repeat;
        background-size: 19px;
    }
    .pzGuiDangPlug .file-down {
        position: absolute;
        width: 21px;
        height: 21px;
        right: 40px;
        top: 6px;
        background: url('../../assets/icon-pdfdown.svg') no-repeat;
    }

</style>
