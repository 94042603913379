/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
import Ajax from '../js/common/ajax';
import Qs from "qs";

export default {
	list(page, params) {
		return Ajax.post("/voucher/list", params, {params: page})
	},
	load(id) {
		return Ajax.get(`/voucher/${id}`)
	},
	loadCode(params) {
		return Ajax.get(`/voucher/code`, params)
	},
	summary() {
		return Ajax.get(`/voucher/summary`)
	},
	delete(records) {
		return Ajax.post(`/voucher/delete`, records)
	},
	save(params = {}) {
		return Ajax.post(`/voucher`, params)
	},
	saveTpl(params = {}) {
		return Ajax.post(`/voucher/tpl`, params)
	},
	tpl(params = {}) {
		return Ajax.get(`/voucher/tpl`, params)
	},
	deleteTpl(tplId) {
		return Ajax.delete(`/voucher/tpl/` + tplId)
	},
	update(params = {}) {
		return Ajax.put(`/voucher`, params)
	},
	carryForwardMoney(params = {}) {
		return Ajax.post(`/voucher/carryForwardMoney`, Qs.stringify(params, {indices: false}))
	},
	finishingOffNo(params = {}) {
		return Ajax.get(`/voucher/finishingOffNo`, params)
	},
	beforeId(params = {}) {
		return Ajax.get(`/voucher/beforeId`, params)
	},
	nextId(params = {}) {
		return Ajax.get(`/voucher/nextId`, params)
	},
	audit(params = {}) {
		return Ajax.post(`/voucher/audit`, Qs.stringify(params, {indices: false}))
	},
	cancelAudit(params = {}) {
		return Ajax.post(`/voucher/cancelAudit`, Qs.stringify(params, {indices: false}))
	},
	import(params) {
		return Ajax.post(`/voucher/import`, params, {'Content-Type': 'multipart/form-data'})
	},
	download(params) {
		return Ajax.post(`/voucher/download`, params, {responseType: 'blob'})
	}
}
