
// 导出
export default {
    namespaced: true, // 为每个模块添加一个前缀名，保证模块命明不冲突 
    state: {
        data:'666',
        firstdata:{},

    },

    mutations: {
     setdata: (state, data) => {
        state.data = data
     },
     setfirstdata: (state, data) => {
        state.firstdata = data
     },
    },
    actions: {}
  }
  
  