<template>
    <app-content class="h-panel" style="height: 100%;box-sizing: border-box;margin: 0;">
        <div style="display: flex;flex-direction: column;align-items: center;justify-content: center;height:100% ;">
            <div>
                <div style="font-size: 32px;font-weight: bold;color: #FF7979;margin-top: 60px">
                重新初始化系统将会清空你录入的所有数据，请慎重！
                </div>
                <div>
                    <p><br>1，系统将删除您新增的所有科目<br><br></p>
                    <!-- <p><br></p> -->
                    <p>2，系统将删除您录入的所有凭证<br><br></p>
                    
                    <p>3，系统将删除您录入的所有初始化数据<br><br></p>
                    <p>
                        <Checkbox v-model="ischeck" @click.native="checkAllMain()">我已清楚了解将产生的后果</Checkbox>
                    </p>
                    <br>
                    <p>
                        <Button @click="remove()" :disabled="!ischeck"> 重置初始化</Button>
                    </p>
                </div>
            </div>
           
        </div>

    </app-content>
</template>

<script>


export default {
    name: 'SetReset',
    data() {
        return {
            ischeck:false
        };
    },
    computed: {},
    watch: {},
    methods: {
        nowApprove() {
            this.$store.commit('newTab', {key: "Approve", title: "账套"})

        },
        remove  (rows)  {
            this.$Confirm("确认初始化?").then(() => {
                // this.loading = true;
                // SalaryManage.deleteCheckWork(row).then(() => {
                // this.loadList();
                // }).finally(() => this.loading = false)
            })
        }

    },
    mounted() {
    }
};
</script>
<style>
.bossItemBg {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    width: 550px;
    height: 288px;
    background: rgba(47, 168, 253, 0.06);
    box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.16);
    border-radius: 0px 0px 4px 4px;
}
</style>
