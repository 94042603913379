<template>
  <app-content class="h-panel">
    <Tabs class="px-20px" :datas="subjectType" v-model="selected" @change="change"></Tabs>
    <div style="color: #2FA8FD;text-align: right;padding-top: 40px;cursor: pointer;">
          不会操作？点此观看视频
        </div>
     <div v-if="selected == '科目余额表导账'">
      <Row :space="20" type="flex" justify="center">
        <!-- <Cell width="2"></Cell> -->
        <Cell width="10">
          <div style="padding-top: 40px;">
            <Form
                ref="form"
                :validOnChange="validOnChange"
                :showErrorTip="showErrorTip"
                :labelPosition="labelPosition"
                :labelWidth="160"
                :rules="validationRules"
                :model="model"
              >
               
                <FormItem label="选择财务软件：" prop="Select1">
                    <Select v-model="model.Select1" :datas="subjectType"></Select>
                </FormItem>
                <FormItem label="选择导入账套：" prop="Radio1">
                     <Radio v-model="model.Radio1" :datas="radiolist"></Radio>
                </FormItem>
                <FormItem label="金额为空是否导入："  prop="Radio2">
                     <Radio v-model="model.Radio2" :datas="radiolist"></Radio>
                </FormItem>
                <FormItem label="账套名称：" prop="Select1">
                  <Input type="text" v-model="model.name" placeholder="请输入账套名称"/>
                </FormItem>
                <FormItem label="账套启用年月：" prop="Select1">
                  <DatePicker v-model="model.date" type="month" ></DatePicker>
                </FormItem>
                <FormItem label="选择会计准则：" prop="Select2">
                  <Select v-model="model.Select2" :datas="subjectType"></Select>
                </FormItem>
              </Form>
          </div>
       </Cell>
        <Cell width="10">
          
          <div style="padding-top: 40px;">

              <div style="font-size: 28px;text-align: center;
                    font-weight: 400;
                    color: #333333;">支持100+财务软件   轻松实现旧账导入</div>
                    <img style="width: 100%;margin-top: 20px;" src="../../assets/text.png" alt="">
          </div>
       </Cell>
        
      </Row>
      <div style="color: #2FA8FD;text-align: right;padding-top: 40px;cursor: pointer;">
        <Tooltip
              theme="white"
              trigger="click"
              editable >
              <span class="text-hover"><i class="h-icon-users"></i>免费导账</span>
              <template #content>
                <div style="padding: 10px;">
                  <img style="width: 160px;" src="../../assets/ewm.png" alt="">

                  <span class="text-hover"
                   style="display: inline-block;width: 100%;text-align: center;">微信扫一扫，立即导账</span>
                </div>
              </template>
            </Tooltip>

      </div>

      <div style="display: flex;justify-content: center;">
        <Button color="primary" :loading="isLoading" @click="submit">提交</Button>
      </div>

     </div>
     <div v-if="selected == '导账工具导账'" style="height: 500px;">
      <Row :space="20" type="flex" justify="space-around" style="height: 100%;">
        <!-- <Cell width="2"></Cell> -->
       
        <Cell width="10" style="height: 100%;">
          <div style="display: flex;justify-content: center;align-items: center;height: 100%;">
            <img style="width: 60%;margin-top: 20px;" src="../../assets/text2.png" alt="">
          </div>
       </Cell>
       <Cell width="12" style="display: flex;justify-content: center;align-items: center;height: 100%;">
          <div style="padding-top: 40px;">
                <div style="font-size: 28px;
                            font-weight: 400;
                            color: #333333;"> 缔造梦导账工具 </div>
                  <div style="font-size: 18px;line-height: 32px;margin-top: 10px;
                  font-weight: 400;
                  color: #666;"> 柠檬云导账工具可以打通用户的数据通道，节省用户迁移数据的人力投入，帮助用户快
                                速体验财务软件的智能记账过程！ </div>

                <div style="margin-top: 40px;">
                   <Row :space="20">
                     
                      <Cell width="12" v-for="item in tipsList">
                          <div style="display: flex;">
                              <div style="padding: 5px;">
                                <img style="width: 80px;" :src="item.img" alt="">
                              </div>
                              <div>
                                  <p style="font-size: 22px;color: #333;">{{item.tips}}</p>
                                  <span style="font-size: 18px;color: #333;">{{item.lable}}</span>
                              </div>
                          </div>
                      </Cell>
                      
                   </Row>
                </div>
          </div>
       </Cell>
        
      </Row>
      <div style="display: flex;justify-content: center;margin-top: 40px;">
        <Button color="primary" :loading="isLoading" @click="submit">立即体验</Button>
      </div>
    </div>
  </app-content>
</template>

<script>


export default {
  name: "OldAccount",
  data() {
    return {
      a: 1,
      value: null,
      datas: [],
      type: "资产",
      selected: '资产',
      loading: false,
      status: false,
      lastCode: null,
      subjectType:[
        '科目余额表导账','导账工具导账'
      ],
      radiolist:[
        '导入新的账套','导入本账套'
      ],
      isLoading: false,
      labelPosition: 'right',
      labels: {
        left: 'Label左对齐',
        right: 'Label右对齐'
        },
        model: {
          name: '',
          password: ''
        },
        validationRules: {
          required: ['name', 'password']
        },
        showErrorTip: true,
        validOnChange: true,
        tipsList:[
          {img:require('@/assets/t1.png'),tips:'快速',lable:'一键操作，快速迁移'},
          {img:require('@/assets/t2.png'),tips:'易用',lable:'操作界面简单易用'},
          {img:require('@/assets/t3.png'),tips:'自动',lable:'全流程自动化操作'},
          {img:require('@/assets/t4.png'),tips:'高效',lable:'快速轻松，提高效率'}
       ],
      };
  },
 
  
  methods: {
    change() {
      this.a = this.a + 1;
    },
    show() {
      console.log('show');
    },
    hide() {
      console.log('hide');
    },
    submit() {
      this.isLoading = true;
      let validResult = this.$refs.form.valid();
      if (validResult.result) {
        this.$Message('验证成功');
        setTimeout(() => {
          this.isLoading = false;
        }, 1000);
      } else {
        this.isLoading = false;
      }
    },
    reset() {
      this.$refs.form.resetValid();
    }
    
  },
  created() {
    this.selected = this.subjectType[0];
  },
  mounted() {
    
  }
};
</script>

<style scoped>
div.h-tabs.h-tabs-card {
  border-bottom: 1px solid #52abc50d;
}

div.h-panel-body {
  padding-top: 0px;
}

div.h-panel-bar {
  border-bottom: 0px solid #eeeeee;
  border-top: 1px solid #eeeeee;
}
</style>
