<template>
  <app-content class="h-panel">
    <div class="flex justify-between m-16px pt-16px">
      <DropdownCustom ref="paramsDropdown" button class="" trigger="click">
        <div v-if="params.start!==params.end">{{ fqFormat(params.start) }} 至 {{ fqFormat(params.end) }}</div>
        <div v-else>{{ fqFormat(params.start) }}</div>
        <template #content>
          <Form style="padding: 10px;width: 400px">
            <FormItem class="!pb-5px" label="会计期间">
              <div class="flex items-center">
                <Select v-model="params.start" :deletable="false" filterable :datas="checkoutSelect"/>
                <span class="mx-8px">至</span>
                <Select v-model="params.end" :deletable="false" filterable :datas="checkoutSelect"/>
              </div>
            </FormItem>
            <FormItem class="!pb-5px" label="起始科目">
              <Select :datas="subjectList" :filter="sfilter" keyName="code" titleName="fullName" v-model="params.subjectStart">
                <template #show="{value}">
                  {{ value.code }} {{ value.fullName }}
                </template>
                <template #item="{item}">
                  {{ item.code }} {{ item.fullName }}
                </template>
              </Select>
            </FormItem>
            <FormItem class="!pb-5px" label="结束科目">
              <Select :datas="subjectList" :filter="sfilter" keyName="code" titleName="fullName" v-model="params.subjectEnd">
                <template #show="{value}">
                  {{ value.code }} {{ value.fullName }}
                </template>
                <template #item="{item}">
                  {{ item.code }} {{ item.fullName }}
                </template>
              </Select>
            </FormItem>
            <FormItem class="!pb-5px" label="科目级别">
              <div class="flex items-center">
                <Select v-model="params.levelStart" :deletable="false" filterable :datas="[1,2,3,4]"/>
                <span class="mx-8px">至</span>
                <Select v-model="params.levelEnd" :deletable="false" filterable :datas="[1,2,3,4]"/>
              </div>
            </FormItem>
            <FormItem class="!pb-5px">
              <Button color="primary" @click="loadSubject();" :loading="loading">确定</Button>
              <Button @click="this.$refs.paramsDropdown.hide()">取消</Button>
              <Button @click="resetForm">重置</Button>
            </FormItem>
          </Form>
        </template>
      </DropdownCustom>
      <div class="float-right flex items-center">
        <Checkbox class="mr-16px" :disabled="numPriceDisabled" v-model="showNumPrice">显示数量金额</Checkbox>
        <vxe-button @click="exportData" icon="fa fa-download" content="导出"/>
        <vxe-button @click="printData" icon="fa fa-print" content="打印"/>
      </div>
    </div>
    <div class="text-center mb-16px font-bold text-20px">{{ subject ? subject.name : "" }} {{ title }} 明细账</div>
    <div class="h-panel-body flex ">
      <div style="overflow-x: auto;flex: 1;height: calc(100vh - 290px)" class="mr-8px">
        <vxe-table ref="xTable" :data="datalist" height="auto"  stripe v-if="!showNumPrice">
          <vxe-column title="日期" field="voucherDate" width="110" align="center"/>
          <vxe-column title="凭证字号" field="voucherId" width="80" align="center">
            <template #default="{row}">
              <router-link class="text-hover primary-color" @success="loadList()" width="1024px"
                           v-if="row.voucherId"
                           :to="{name:'VoucherForm',params:{voucherId:row.voucherId}}">
                {{ row.word }}-{{ row.code }}
              </router-link>
            </template>
          </vxe-column>
          <vxe-column title="科目" field="subjectName" width="180"/>
          <vxe-column title="摘要" field="summary" width="200"/>
          <vxe-column title="借方" field="debitAmount" width="130" align="right" formatter="toMoney"/>
          <vxe-column title="贷方" field="creditAmount" width="130" align="right" formatter="toMoney"/>
          <vxe-column title="方向" field="balanceDirection" align="center" width="70"/>
          <vxe-column title="余额" field="balance" width="130" align="right" formatter="toMoney"/>
        </vxe-table>
        <vxe-table ref="xTable" :data="datalist"  stripe v-else>
          <vxe-column title="日期" field="voucherDate" width="110" align="center"/>
          <vxe-column title="凭证字号" field="voucherId" width="80" align="center">
            <template #default="{row}">
              <router-link class="text-hover primary-color" @success="loadList()" width="1024px"
                           v-if="row.voucherId"
                           :to="{name:'VoucherForm',params:{voucherId:row.voucherId}}">
                {{ row.word }}-{{ row.code }}
              </router-link>
            </template>
          </vxe-column>
          <vxe-column title="科目" field="subjectName" width="180"/>
          <vxe-column title="摘要" field="summary" width="200"/>
          
          <vxe-colgroup title="借方发生额" header-align="center">
            <vxe-column title="数量" field="debitAmount" width="130" align="right" formatter="toMoney">
              <template #default="{row}">
                {{ row.creditAmount && row.balanceDirection == '借' ? row.num : '' }}
              </template>
            </vxe-column>
            <vxe-column title="单价" field="creditAmount" width="130" align="right" formatter="toMoney">
              <template #default="{row}">
                {{ row.creditAmount && row.balanceDirection == '借' ? row.price : '' }}
              </template>
            </vxe-column>
            <vxe-column title="金额" field="debitAmount" align="right" formatter="toMoney" width="130"/>
          </vxe-colgroup>
          <vxe-colgroup title="贷方发生额" header-align="center">
            <vxe-column title="数量" field="debitAmount" width="130" align="right" formatter="toMoney">
              <template #default="{row}">
                {{ row.creditAmount && row.balanceDirection == '贷' ? row.num : '' }}
              </template>
            </vxe-column>
            <vxe-column title="单价" field="creditAmount" width="130" align="right" formatter="toMoney">
              <template #default="{row}">
                {{ row.creditAmount && row.balanceDirection == '贷' ? row.price : '' }}
              </template>
            </vxe-column>
            <vxe-column title="金额" field="creditAmount" align="center" width="130"/>
          </vxe-colgroup>
          <vxe-colgroup title="余额" header-align="center">
            <vxe-column title="方向" field="balanceDirection" align="center" width="70"/>
            <vxe-column title="数量" field="numBalance" width="130" align="right" formatter="toMoney"/>
            <vxe-column title="单价" field="price" width="130" align="right" formatter="toMoney"/>
            <vxe-column title="金额" field="balance" align="right" width="130" formatter="toMoney"/>
          </vxe-colgroup>
        </vxe-table>
      </div>
      <div style="overflow-y: auto;max-height: calc(100vh - 290px)">
        <Tree v-model="subjectId" @select="subjectSelect" :option="param" ref="subject" filterable selectOnClick className="h-tree-theme-row-selected"></Tree>
      </div>
    </div>
  </app-content>
</template>

<script>
import accountbook from "@/api/accountbook";
import {downloadBlob} from "download.js";
import manba from "manba";
import {mapGetters, mapState} from "vuex";
import BooUtil from "@/views/accountbook/bookUtil";
import AccountDateRangeChoose from "@/components/AccountDateRangeChoose";
import {clone, findTree, toArrayTree, toTreeArray} from "xe-utils";
import Qs from "qs";
import setting from "@/api/setting";

export default {
  name: 'DetailedAccounts',
  components: {AccountDateRangeChoose},
  data() {
    return {
      loading: false,
      cloneParams: {},
      subjectList: [],
      params: {
        start: null,
        end: null,
        currencyId: null,
        levelStart: 1,
        levelEnd: 4,
        subjectStart: null,
        subjectEnd: null,
      },
      param: {
        keyName: 'id',
        parentName: 'parentId',
        titleName: 'name',
        dataMode: 'list',
        datas: []
      },
      datalist: [],
      subjectId: null,
      showNumPrice: true,
      numPriceDisabled: true,
      accountDate: null,
      subject: null
    };
  },
  computed: {
    ...mapState(['currentAccountSets']),
    ...mapGetters(['checkoutSelect', 'localCurrency']),
    title() {
      if (!this.params.start) {
        return '';
      }
      if (this.params.start === this.params.end) {
        return manba(this.params.start).format("YYYY年MM期")
      }
      return manba(this.params.start).format("YYYY年MM期") + " - " + manba(this.params.end).format("YYYY年MM期");
    }
  },
  watch: {
    subject() {
      let org = this.showNumPrice;
      if (this.subject.unit) {
        this.numPriceDisabled = false;
        this.showNumPrice = true;
      } else {
        this.showNumPrice = false;
      }
      this.loadList();
    },
    showNumPrice() {
      this.loadList();
    }
  },
  methods: {
    loadSubject() {
      this.datalist = [];
      accountbook.loadSubject(this.params).then(({data}) => {
        this.param.datas = data;
        if (data.length) {
          this.subjectId = data[0].id;
          this.subject = data[0];
        } else {
          this.datalist = [];
        }
      });
    },
    sfilter(item, val) {
      return item.code.indexOf(val) !== -1 || item.name.indexOf(val) !== -1 || item.mnemonicCode.indexOf(val) !== -1;
    },
    loadList() {
      if (this.subjectId) {
        this.loading = true;
        accountbook.loadVoucherDetails(Object.assign({
          subjectId: this.subjectId,
          showNumPrice: this.showNumPrice,
          subjectCode: this.subject.code
        }, this.params)).then(({data}) => {
          this.datalist = data;
          this.$refs.paramsDropdown.hide()
        }).finally(() => this.loading = false);
      }
    },
    subjectSelect(node) {
      this.subject = node;
    },
    exportData() {
      if (this.param.datas.length) {
        const utils = new BooUtil(this.$refs.xTable, '\n')
        accountbook.download({header: utils.header, dataList: utils.dataList}).then((data) => {
          downloadBlob(`${this.subject.name}-${this.title}-明细账.xls`, data)
        })
      }
    },
    printData() {
      if (this.param.datas.length) {
        const queryStr = Qs.stringify(Object.assign({
          subjectId: this.subjectId,
          showNumPrice: this.showNumPrice,
          subjectCode: this.subject.code
        }, this.params));

        const codes = toTreeArray([findTree(toArrayTree(this.param.datas), item => item.code === this.subject.code).item]).filter(val => val.leaf).map(val => val.code);
        window.open("/api/pdf/voucher/details?code=" + codes.join("&code=") + "&" + queryStr)
      }
    },
    resetForm() {
      this.params = clone(this.cloneParams, true)
    }
  },
  created() {
    this.params.start = manba(this.currentAccountSets.currentAccountDate).format("YYYY-M");
    this.params.end = this.params.start;
    this.params.currencyId = this.localCurrency.id;
    this.cloneParams = clone(this.params, true)
    this.loadSubject();

    setting.subject.voucherSelect().then(({data}) => {
      this.subjectList = data;
    })
  }
};
</script>
<style lang="less" scoped>
/deep/ .h-tree-theme-row-selected .h-tree-show.h-tree-show-selected{
   color: var(--primary) !important;
  //  background:none;
}

</style>
