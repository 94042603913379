<template>
	<app-content class="h-panel">
		<div class="flex px-16px pt-16px items-center justify-between">
			<DropdownCustom ref="paramsDropdown" button class="" trigger="click">
				<div v-if="params.start!==params.end">{{ fqFormat(params.start) }} 至 {{ fqFormat(params.end) }}</div>
				<div v-else>{{ fqFormat(params.start) }}</div>
				<template #content>
					<Form style="padding: 10px;width: 400px">
						<FormItem class="!pb-5px" label="会计期间">
							<div class="flex items-center">
								<Select v-model="params.start" :deletable="false" filterable :datas="checkoutSelect"/>
								<span class="mx-8px">至</span>
								<Select v-model="params.end" :deletable="false" filterable :datas="checkoutSelect"/>
							</div>
						</FormItem>
						<FormItem class="!pb-5px" label="起始科目">
							<Select :datas="subjectList" :filter="sfilter" keyName="code" titleName="fullName" v-model="params.subjectStart">
								<template #show="{value}">
									{{ value.code }} {{ value.fullName }}
								</template>
								<template #item="{item}">
									{{ item.code }} {{ item.fullName }}
								</template>
							</Select>
						</FormItem>
						<FormItem class="!pb-5px" label="结束科目">
							<Select :datas="subjectList" :filter="sfilter" keyName="code" titleName="fullName" v-model="params.subjectEnd">
								<template #show="{value}">
									{{ value.code }} {{ value.fullName }}
								</template>
								<template #item="{item}">
									{{ item.code }} {{ item.fullName }}
								</template>
							</Select>
						</FormItem>
						<FormItem class="!pb-5px" label="科目级别">
							<div class="flex items-center">
								<Select v-model="params.levelStart" :deletable="false" filterable :datas="[1,2,3,4]"/>
								<span class="mx-8px">至</span>
								<Select v-model="params.levelEnd" :deletable="false" filterable :datas="[1,2,3,4]"/>
							</div>
						</FormItem>
						<FormItem class="!pb-5px">
							<Button color="primary" @click="loadList();" :loading="loading">确定</Button>
							<Button @click="this.$refs.paramsDropdown.hide()">取消</Button>
							<Button @click="resetForm">重置</Button>
						</FormItem>
					</Form>
				</template>
			</DropdownCustom>
			<div class="float-right flex items-center">
				<vxe-button :disabled="datalist.length===0" @click="exportData" icon="fa fa-download" content="导出"/>
				<vxe-button :disabled="datalist.length===0" @click="printData" icon="fa fa-print" content="打印"/>
			</div>
		</div>
		<div class="p-16px">
			<Loading text="总账数据加载中..." :loading="loading"></Loading>
			<table class="summary" v-if="!showNumPrice">
				<tr >
					<td style="border: none;" class="w-150px">科目：所有</td>
				
					
				</tr>
				<tr class=" bg-info-color white-color">
					<td class="w-150px">科目编码</td>
					<td>科目名称</td>
					<td class="w-150px">期间</td>
					<td class="w-150px">摘要</td>
					<td class="w-150px text-right">借方金额</td>
					<td class="w-150px text-right">贷方金额</td>
					<td class="w-150px text-center">方向</td>
					<td class="w-150px text-right">余额</td>
				</tr>
				<template v-for="item in datalist">
					<tr class="bg-gray3-color">
						<td colspan="8">
							<span @click="toggleSummary(item)" class="text-hover">
								<i class="fa fa-caret-down" :class="{'fa-caret-right':!item._expand}"></i>
								<span class="primary-color font-bold ml-8px"> {{ item.subjectName }}</span>
							</span>
						</td>
					</tr>
					<tr v-if="item._expand" class="summary data-row" v-for="it in item.dataRows">
						<td>{{ it.code }}</td>
						<td>{{ it.subjectName }}</td>
						<td>{{ it.date }}</td>
						<td>{{ it.summary }}</td>
						<td class="text-right">{{ it.debit }}</td>
						<td class="text-right">{{ it.credit }}</td>
						<td class="text-center">{{ it.balanceDirection }}</td>
						<td class="text-right">{{ it.balance }}</td>
					</tr>
				</template>
				<tr v-if="!datalist.length">
					<td colspan="8" class="text-center padding">暂无数据</td>
				</tr>
			</table>
			<vxe-table v-else ref="xTable" :data="datalist"  stripe>
				<vxe-column field="code" title="科目编码" width="110"/>
				<vxe-column field="subjectName" title="科目名称" width="110"/>
				<vxe-column field="subject.unit" title="单位" align="center" width="100"/>
				<vxe-colgroup title="期初余额" align="center">
					<vxe-column title="方向" align="center" width="80">
						<template #default="{row:{summary}}">
							{{ summary[0].balanceDirection }}
						</template>
					</vxe-column>
					<vxe-column title="数量" align="right" width="120">
						<template #default="{row:{summary}}">
							{{ numFormat(summary[0].num) }}
						</template>
					</vxe-column>
					<vxe-column title="单价" align="right" width="120">
						<template #default="{row:{summary}}">
							{{ numFormat(summary[0].price) }}
						</template>
					</vxe-column>
					<vxe-column title="金额" align="right" width="120">
						<template #default="{row:{summary}}">
							{{ numFormat(summary[0].balance) }}
						</template>
					</vxe-column>
				</vxe-colgroup>
				<vxe-colgroup title="本期借方" align="center">
					<vxe-column title="数量" align="right" width="120">
						<template #default="{row:{summary}}">
							{{ numFormat(summary[1].num) }}
						</template>
					</vxe-column>
					<vxe-column title="金额" align="right" width="120">
						<template #default="{row:{summary}}">
							{{ numFormat(summary[1].debitAmount) }}
						</template>
					</vxe-column>
				</vxe-colgroup>
				<vxe-colgroup title="本期贷方" align="center">
					<vxe-column title="数量" align="right" width="120">
						<template #default="{row:{summary}}">
							{{ numFormat(summary[1].num) }}
						</template>
					</vxe-column>
					<vxe-column title="金额" align="right" width="120">
						<template #default="{row:{summary}}">
							{{ numFormat(summary[1].creditAmount) }}
						</template>
					</vxe-column>
				</vxe-colgroup>
				<vxe-colgroup title="本年累计借方" align="center">
					<vxe-column title="数量" align="right" width="120">
						<template #default="{row:{summary}}">
							{{ numFormat(summary[2].num) }}
						</template>
					</vxe-column>
					<vxe-column title="金额" align="right" width="120">
						<template #default="{row:{summary}}">
							{{ numFormat(summary[2].debitAmount) }}
						</template>
					</vxe-column>
				</vxe-colgroup>
				<vxe-colgroup title="本年累计贷方" align="center">
					<vxe-column title="数量" align="right" width="120">
						<template #default="{row:{summary}}">
							{{ numFormat(summary[0].num) }}
						</template>
					</vxe-column>
					<vxe-column title="金额" align="right" width="120">
						<template #default="{row:{summary}}">
							{{ numFormat(summary[0].creditAmount) }}
						</template>
					</vxe-column>
				</vxe-colgroup>
				<vxe-colgroup title="期末余额" align="center">
					<vxe-column title="方向" align="center" width="70">
						<template #default="{row:{summary}}">
							{{ summary[1].balanceDirection }}
						</template>
					</vxe-column>
					<vxe-column title="数量" align="right" width="120">
						<template #default="{row:{summary}}">
							{{ numFormat(summary[1].num) }}
						</template>
					</vxe-column>
					<vxe-column title="单价" align="right" width="120">
						<template #default="{row:{summary}}">
							{{ numFormat(summary[1].price) }}
						</template>
					</vxe-column>
					<vxe-column title="金额" align="right" width="120">
						<template #default="{row:{summary}}">
							{{ numFormat(summary[1].balance) }}
						</template>
					</vxe-column>
				</vxe-colgroup>
			</vxe-table>
		</div>
	</app-content>
</template>

<script>
import BooUtil from "@/views/accountbook/bookUtil";
import accountbook from "@/api/accountbook";
import {downloadBlob} from "download.js";
import manba from "manba";
import {mapGetters, mapState} from "vuex";
import {clone} from "xe-utils";
import setting from "@/api/setting";

export default {
	name: 'GeneralLedger',
	data() {
		return {
			loading: false,
			accountDate: null,
			showNumPrice: false,
			numPriceDisabled: true,
			datalist: [],
			cloneParams: {},
			subjectList: [],
			params: {
				start: null,
				end: null,
				levelStart: 1,
				levelEnd: 4,
				currencyId: null,
				subjectStart: null,
				subjectEnd: null,
			}
		};
	},
	watch: {
		accountDate() {
			this.loadList();
		},
		showNumPrice() {
			this.loadList();
		}
	},
	computed: {
		...mapState(['currentAccountSets']),
		...mapGetters(['checkoutSelect', 'localCurrency']),
	},
	methods: {
		resetForm() {
			this.params = clone(this.cloneParams, true)
		},
		sfilter(item, val) {
			return item.code.indexOf(val) !== -1 || item.name.indexOf(val) !== -1 || item.mnemonicCode.indexOf(val) !== -1;
		},
		loadList() {
			this.loading = true;
			accountbook.loadGeneralLedger(Object.assign({}, this.params, {
				showNumPrice: this.showNumPrice
			})).then(({data}) => {
				this.datalist = data;
			}).finally(() => this.loading = false);
		},
		toggleSummary(item) {
			item._expand = !item._expand
		},
		exportData() {
      accountbook.downloadLedger(this.datalist.map(val => val.dataRows).flat()).then((data) => {
				downloadBlob(`${manba(this.accountDate).format("YYYY年MM期")}-总账.xls`, data)
			})
		},
		printData() {
			if (this.showNumPrice) {
				const utils = new BooUtil(this.$refs.xTable)
				const title = `${manba(this.accountDate).format("YYYY年MM期")}-总账`
				accountbook.print({content: utils.printContent(title, this.currentAccountSets.companyName), rotate: this.showNumPrice}).then((data) => {
					const url = URL.createObjectURL(data);
					window.open(url, '总账')
				})
			} else {
				let data = [BooUtil.PrintStyle];
				this.datalist.forEach(row => {
					data.push(`<div class="title">${row.subjectName} 总账</div>`)
					data.push(`<div class="info">科目：${row.code} ${row.subjectName}</div>`)
					data.push(`<table>`);
					data.push(`<tr><th>科目编码</th><th>科目名称</th><th>期间</th><th>摘要</th><th>借方金额</th><th>贷方金额</th><th>方向</th><th>余额</th></tr>`);
					row.summary.forEach(s => {
						data.push(`<tr>`);
						data.push(`<td>${row.code}</td>`);
						data.push(`<td>${row.subjectName}</td>`);
						data.push(`<td>${this.fqFormat(s.voucherDate)}</td>`);
						data.push(`<td>${s.summary || ''}</td>`);
						data.push(`<td align="right">${s.debitAmount || ''}</td>`);
						data.push(`<td align="right">${s.creditAmount || ''}</td>`);
						data.push(`<td align="center">${s.balanceDirection || ''}</td>`);
						data.push(`<td align="right">${s.balance || ''}</td>`);
						data.push("</tr>")
					})
					data.push("</table>")
					data.push(`<div class="footer" style="margin-bottom: 100px"><span style="float: left">编辑单位：${this.currentAccountSets.companyName}</span> <span style='float: right'>打印日期：${manba().format()}</span></div>`)
					data.push(`<div style="height: 30px"></div> `)
				})
				accountbook.print({content: data.join(""), rotate: true}).then((data) => {
					const url = URL.createObjectURL(data);
					window.open(url, '总账')
				})

			}
		},
		loadSubject() {
			setting.subject.voucherSelect().then(({data}) => {
				this.subjectList = data;
			})
		},
	},
	created() {
		this.params.start = manba(this.currentAccountSets.currentAccountDate).format("YYYY-M");
		this.params.end = this.params.start;
		this.params.currencyId = this.localCurrency.id;
		this.cloneParams = clone(this.params, true);
		this.loadSubject();
		this.loadList();
	}
};
</script>

<style scoped lang="less">
.summary {
  width: 100%;
  border-collapse: collapse;

  td {
    padding: 0 8px;
    border: 1px solid #eee;
    font-size: 12px;
    height: 32px;
  }

  .summary:nth-child(even) {
    background-color: #f8fbf8;
  }

  .summary:hover {
    background-color: #dff7df;
  }


  .header {
    td {
      text-align: center;
      font-weight: bold;
      height: 35px;
    }
  }
}
</style>
