/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
import Ajax from '../js/common/ajax';
import Qs from "qs";

export default {
	list(params) {
		return Ajax.get("/assets", params)
	},
	detail(id) {
		return Ajax.get("/assets/detail/" + id)
	},
	dispose(assetsId, disposeType) {
		return Ajax.get("/assets/dispose/" + assetsId, {disposeType})
	},
	delete(id) {
		return Ajax.delete(`/assets/${id}`)
	},
	save(params = {}) {
		return Ajax.post(`/assets`, params)
	},
	copy(params = {}) {
		return Ajax.post(`/assets/copy`, params)
	},
	batchDelete(ids) {
		return Ajax.delete(`/assets/batch?` + Qs.stringify({id: ids}, {arrayFormat: 'repeat'}))
	},
	checkDate() {
		return Ajax.get(`/assets/checkDate`)
	},
	summary(params) {
		return Ajax.post(`/assets/summary`, params)
	},
	check(params) {
		return Ajax.post(`/assets/check`, params)
	},
	import(params) {
		return Ajax.post(`/assets/import`, params, {'Content-Type': 'multipart/form-data'})
	},
	Depreciation: {
		list(params) {
			return Ajax.get("/assets/depreciation", params)
		},
		detail(params) {
			return Ajax.post("/assets/depreciation/detail", params)
		},
		save(params = {}) {
			return Ajax.post(`/assets/depreciation`, params)
		},
		generateVoucher(id) {
			return Ajax.get(`/assets/depreciation/generate/voucher/${id}`)
		},
		update(params = {}) {
			return Ajax.put(`/assets/depreciation`, params)
		},
		depreciation(id) {
			return Ajax.get(`/assets/depreciation/${id}`)
		},
		cancel(id) {
			return Ajax.get(`/assets/depreciation/cancel/${id}`)
		},
		relateVoucher(id, voucherId) {
			return Ajax.get(`/assets/depreciation/relate/${id}`, {voucherId})
		},
	},
	Change: {
		list(params) {
			return Ajax.get("/assets/change", params)
		},
		save(params = {}) {
			return Ajax.post(`/assets/change`, params)
		},
		delete(id) {
			return Ajax.delete(`/assets/change/${id}`)
		},
		update(params = {}) {
			return Ajax.put(`/assets/change`, params)
		},
		relateVoucher(changeIds, voucherId) {
			return Ajax.get(`/assets/change/relate/${voucherId}?` + Qs.stringify({id: changeIds}, {arrayFormat: 'repeat'}))
		},
		generateVoucher(changeIds) {
			return Ajax.get(`/assets/change/generate/voucher?` + Qs.stringify({id: changeIds}, {arrayFormat: 'repeat'}))
		}
	},
	Template: {
		list(params) {
			return Ajax.get("/assets/template", params)
		},
		save(params = {}) {
			return Ajax.post(`/assets/template`, params)
		},
		delete(id) {
			return Ajax.delete(`/assets/template/${id}`)
		},
		update(params = {}) {
			return Ajax.put(`/assets/template`, params)
		},
	}
}
