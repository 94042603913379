<template>
    <div class="w-700px">
        <Tabs ref="tabs" :datas="tabs" class="px-16px" v-model.number="tab"/>
        <div class="flex justify-between p-8px">
            <div>
                <Input v-model="filter" placeholder="编码或名称检索"/>
                <Button color="primary" class="ml-8px">查询</Button>
            </div>
            <Button color="primary" @click="newItem">新增</Button>
        </div>
        <div class="px-8px pb-8px">
            <vxe-table height="200" :data="dataList"  @current-change="currentChange">
                <vxe-column :title="(tabMap[tab]?tabMap[tab].name:'')+'编码'" field="code" width="100"/>
                <vxe-column :title="(tabMap[tab]?tabMap[tab].name:'')+'名称'" field="name" width="150"/>
                <vxe-column title="备注" field="remark"/>
            </vxe-table>
        </div>
        <div class="px-8px pb-8px text-center">
            <Button @click="$emit('clear')">清空</Button>
            <Button @click="$emit('close')">取消</Button>
        </div>
    </div>
</template>

<script>/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
import Setting from "@/api/setting";
import {layer} from "@layui/layer-vue";
import {h} from "vue";
import AssistingAccountingFastForm from "@/views/cashier/AssistingAccountingFastForm";

export default {
    name: "CashierAssistingAccounting",
    data() {
        return {
            tabs: [],
            tabMap: {},
            categorys: [],
            categoryDetails: {},
            tab: null,
            loading: false,
            filter: null,
        }
    },
    computed: {
        dataList() {
            let list = this.categoryDetails[this.tab] || [];
            return this.filter ? list.filter(val => val.code.indexOf(this.filter) !== -1 || val.name.indexOf(this.filter) !== -1) : list;
        }
    },
    methods: {
        loadList() {
            this.loading = true;
            Setting.accountingCategoryDetails.loadAuxiliaryAccountingData(this.categorys).then(({data}) => {
                this.categoryDetails = data;
            }).finally(() => {
                this.loading = false;
            })
        },
        currentChange({row}) {
            this.$emit('selected', row);
        },
        newItem() {
            let layerId = layer.open({
                title: "新增" + this.tabMap[this.tab].name,
                shadeClose: false,
                closeBtn: false,
                area: ['400px', 'auto'],
                content: h(AssistingAccountingFastForm, {
                    category: this.tabMap[this.tab].name,
                    categoryId: this.tab,
                    categoryType: this.tabMap[this.tab].categoryType,
                    onClose: () => {
                        layer.close(layerId);
                    },
                    onSuccess: () => {
                        this.loadList();
                        layer.close(layerId);
                    }
                })
            });
        }
    },
    created() {
        Setting.accountingCategory.list().then(({data}) => {
            this.categorys = (data || []).filter(val => ['CUSTOMER', 'VENDOR', 'EMPLOYEE'].includes(val.categoryType));
            this.tabs = this.categorys.map(val => {
                this.tabMap[val.id] = val;
                return {
                    title: val.name,
                    key: val.id,
                    categoryType: val.categoryType
                };
            });
            if (this.tabs.length) {
                this.tab = this.tabs[0].key;
                this.loadList();
            }
        })
    }
}
</script>
