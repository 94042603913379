<template>
  <div class="h-full flex flex-column">
    <div class="m-16px flex-1 flex flex-column">
      <Tabs :datas="{'SalaryManagement':'工资列表','SalaryChange':'计提工资，发放工资'}" class="px-20px bg-white-color" v-model="tab"/>
      <Component :is="tab" class="flex-1 flex flex-column"></Component>
    </div>
  </div>
</template>

<script>/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
import SalaryManagement from "@/views/salary/SalaryManagement";
import SalaryChange from "@/views/salary/SalaryChange";

export default {
  name: "Salary",
  components: {SalaryManagement, SalaryChange},
  data() {
    return {
      tab: "SalaryManagement",
      loading: false,
    }
  },
}
</script>
