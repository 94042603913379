<template>
	<div class="modal-column">
		<div class="modal-column-full-body">
				<Form  :labelWidth="120" labelPosition="left">
				<div class="inline-form-item">
					<FormItem label="模板名称" required prop="name">
						<Input v-model="formData.title" style="width: 100px"/>
					</FormItem>
					<FormItem label="凭证字">
						<Select v-model="formData.voucherWord" :datas="voucherWordList" :deletable="false" style="width: 100px"/>
					</FormItem>
				</div>
				<FormItem label="">
					<vxe-table height="200" ref="itemsTable" :data="items"  :edit-config="{trigger:'click',mode:'row'}" :edit-rules="validConfig">
						<vxe-column width="80" align="center">
							<template #default="{row,rowIndex}">
								<span @click="pushItem(row)" class="fa fa-plus mr-16px text-hover"/>
								<span @click="popItem(row)" class="fa fa-minus red-color text-hover"/>
							</template>
						</vxe-column>
						<vxe-column field="subjectTitle" title="会计科目" :edit-render="{name:'$select',options:subjectList,props:{filterable:true}}"/>
						<vxe-column width="100" field="direction" title="方向" :edit-render="{name:'$select',options:[{label:'借',value:'借'},{label:'贷',value:'贷'}]}"/>
						<vxe-column width="140" field="valueField" title="取值" :edit-render="{name:'$select',options:[{label:'不含税金额',value:'taxAmountExcluded'},{label:'税额',value:'taxAmount'},{label:'价税合计',value:'amount'}]}"/>
					</vxe-table>
				</FormItem>
			</Form>
		</div>
		<div style="display: flex;justify-content: end;margin-bottom: 10px;margin-right: 10px">
			<div>
				<Button :loading="loading" @click="$emit('close')" color="" icon="fa" >取消</Button>
				<Button :loading="loading"  @click="doSubmit()"  color="primary" icon="fa" style="margin-left: 10px" >保存</Button>
			</div>
		</div>
	</div>
</template>

<script>
/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
export default {
	name: "SalaryVoucherTemplateForm"
}
</script>

<script setup>
import {reactive, ref, toRefs} from "vue";
import setting from "@/api/setting";
import {SalaryBusiness} from "@/api/SalaryChange";

const emits = defineEmits(['close', 'success']);
const props = defineProps(['type', 'entity']);
const {type, entity} = toRefs(props);
const loading = ref(false)
const itemsTable = ref(null)
const formData = reactive(entity.value ? entity.value : {
	id: null,
	title: null,
	invoiceType: type.value,
	voucherWord: null,
	items: []
})

const validConfig = reactive({
	subjectCode: [{required: true, message: "请选择科目"}]
});

const items = reactive(entity.value && entity.value.items && entity.value.items.length > 0 ? entity.value.items : [
	{subjectCode: null, subjectTitle: null, direction: '借', valueField: "taxAmountExcluded"},
	{subjectCode: null, subjectTitle: null, direction: '借', valueField: "taxAmountExcluded"},
	{subjectCode: null, subjectTitle: null, direction: '借', valueField: "taxAmountExcluded"},
])

const doSubmit = () => {
	itemsTable.value.fullValidate(true, () => {
		let {fullData} = itemsTable.value.getTableData();
		fullData.forEach(row => {
			row.subjectTitle = subjectMap[row.subjectCode];
		})
		loading.value = true;
		SalaryBusiness.save(Object.assign({}, formData, {items: fullData})).then(() => {
			emits('success')
		}).finally(() => loading.value = false)
	})
}

const pushItem = (row) => {
	itemsTable.value.insertAt({subjectCode: null, subjectTitle: null, direction: '借', valueField: "taxAmountExcluded"}, row);
}

const popItem = (row) => {
	let {fullData} = itemsTable.value.getTableData();
	if (fullData.length > 2) {
		itemsTable.value.remove(row);
	}
}

const voucherWordList = ref([]);
setting.voucherWord.list().then(({data}) => {
	formData.voucherWord = data[0].id;
	voucherWordList.value = data.map(val => {
		return {
			key: val.id,
			title: val.word,
		}
	})
})

const subjectList = ref([]);
const subjectMap = reactive({});
setting.subject.voucherSelect({all: false}).then(({data}) => {
	subjectList.value = data.map(val => {
		subjectMap[val.id] = val.fullName;
		return {
			value: val.id,
			label: val.code + "-" + val.fullName,
		}
	})
})
</script>
