<template>
  <vxe-colgroup title="期初余额" align="center">
    <begin-col :type="type" :val-change="valChange" :beginCheck="beginCheck" :is-start-year="isStartYear" :is-unit="isUnit" :is-for="isFor" :is-local="isLocal"/>
  </vxe-colgroup>
  <vxe-colgroup title="借方累计" align="center">
    <total-debit-col :is-start-year="isStartYear" :beginCheck="beginCheck" :val-change="valChange" :is-unit="isUnit" :is-for="isFor" :is-local="isLocal"/>
  </vxe-colgroup>
  <vxe-colgroup title="贷方累计" align="center">
    <total-credit-col :is-start-year="isStartYear" :beginCheck="beginCheck" :val-change="valChange" :is-unit="isUnit" :is-for="isFor" :is-local="isLocal"/>
  </vxe-colgroup>
  <vxe-colgroup title="年初余额" align="center">
    <year-total-col :is-unit="isUnit" :is-for="isFor" :beginCheck="beginCheck" :is-local="isLocal"/>
  </vxe-colgroup>
</template>
<script>
import BeginCol from "@/views/setting/initial/BeginCol.vue";
import TotalDebitCol from "@/views/setting/initial/TotalDebitCol.vue";
import TotalCreditCol from "@/views/setting/initial/TotalCreditCol.vue";
import YearTotalCol from "@/views/setting/initial/YearTotalCol.vue";

export default {
  name: 'AmountGroupCol',
  components: {YearTotalCol, TotalCreditCol, TotalDebitCol, BeginCol},
  props: {
    type: String,
    isUnit: Boolean,
    beginCheck: Boolean,
    isStartYear: Boolean,
    valChange: Function,
    isFor: Boolean,
    isLocal: Boolean,
  }
}
</script>
