<template>
  <v-table-number-input v-bind="props" @onNext="onEnter" @cancelEdit="cancelEdit" v-if="editing && !readonly"/>
  <div v-else class="v-table-number" :class="number<0?'red-color':''" @click="doEdit">
    <span v-for="n in numberStrList">{{ n }}</span>
  </div>
</template>

<script setup>
import {computed, defineEmits, defineProps, ref, watch} from "vue";
import {floor, multiply, padStart, toNumber} from "xe-utils";
import VTableNumberInput from "@components/VoucherTable/VTableNumberInput.vue";
import {toNumberString} from "number-string";

const emits = defineEmits(['cancelEdit', 'onEnter']);

const props = defineProps({
  number: {
    type: [Number, String]
  },
  readonly: {
    type: Boolean,
    default: false
  },
  rowIndex: Number,
  field: String
});

//编辑
const editing = ref(false)
const number = ref(props.number || null);

watch(() => props.number, (val) => {
  number.value = val || null;
})

const numberStrList = computed(() => {
  if (number.value || number.value === 0) {
    let num = number.value;
    if (typeof num === 'string') {
      num = toNumber(number.value);
    }
    num = Math.abs(num);
    if (num === 0 || num <= 999999999.99 && num >= 0.01) {
      let str = toNumberString(floor(multiply(num, 100), 0));
      return str.split("");
    }
    return padStart('金额超过显示范围', 11, ' ').split("")
  }
  return [];
})

const doEdit = () => {
  editing.value = true;
}

const cancelEdit = (numVal) => {
  editing.value = false;
  emits('cancelEdit', numVal)
}

const onEnter = (numVal) => {
  editing.value = false;
  cancelEdit(numVal);
  emits('onEnter', numVal)
}

defineExpose({
  doEdit,
  cancelEdit
})
</script>
<style scoped lang="less">
.v-table-number {
  line-height: 60px;
  background-image: url("../../assets/moneyUint.png");
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  height: 100%;

  span {
    display: inline-block;
    text-align: center;
    width: 20px;
    height: 100%;
    font-size: 15px;
    font-weight: bold;
  }
}
</style>
