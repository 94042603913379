/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
import Ajax from '../js/common/ajax';
import Qs from "qs";

export default {
	login(params = {}) {
		return Ajax.post('/login', Qs.stringify(params));
	},
	register(params = {}) {
		return Ajax.post('/register', Qs.stringify(params));
	},
	mobilelogin(params = {}) {
		return Ajax.post('/mobileLogin', Qs.stringify(params));
	},
	mobileLoginMsg(mobile) {
		return Ajax.get(`/mobileLoginMsg/${mobile}`);
	},
	logout() {
		return Ajax.get('/logout');
	},
	init() {
		return Ajax.get('/init');
	},
	
	pdfFont(extendParam) {
		return Ajax.get('/pdf/font', null, Object.assign(extendParam, {
			responseType: 'blob'
		}));
	},
	updateUser(params = {}) {
		return Ajax.post('/updateUser', Qs.stringify(params));
	},
	updatePwd(params = {}) {
		return Ajax.post('/updatePwd', Qs.stringify(params));
	},
	changePhoneNumber(params = {}) {
		return Ajax.post('/changePhoneNumber', Qs.stringify(params));
	},
	sendMsg(mobile) {
		return Ajax.get(`/sendMsg/${mobile}`);
	},
	regMsg(mobile) {
		return Ajax.get(`/regMsg/${mobile}`);
	},
	renewalValid(code) {
		return Ajax.get(`/renewal/valid`, {code});
	},
	changeAccountSets(accountSetsId) {
		return Ajax.get(`/changeAccountSets?accountSetsId=${accountSetsId}`);
	},
	resetPassword(params = {}) {
		return Ajax.post('/resetPassword', Qs.stringify(params));
	},
	upload(type, formData) {
		return Ajax.post(`/upload/${type}`, formData, {'Content-Type': 'multipart/form-data', repeatable: true});
	}
}
