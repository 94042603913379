<template>
  <div class="h-full p-20px">
    <div class="flex flex-column h-full">
      <vxe-toolbar class-name="px-16px" :loading="loading">
        <template #buttons>
          <vxe-input placeholder="请输入名称" v-model="params.search" clearable/>
          <vxe-button icon="fa fa-search" color="primary" @click="loadData" style="margin-left: 10px">查询</vxe-button>
          <vxe-button icon="fa fa-plus" @click="showForm()" content="新增" status="primary"/>
        </template>
      </vxe-toolbar>
      <div class="flex-1 h-0px" style="width: 100%">
        <vxe-table ref="journalTable"
                   :loading="loading"
                   :data="dataResult.results"
                   height="auto"
                   >
          <vxe-column title="方案名称" field="name" fixed="left"/>
          <vxe-column title="操作" width="200px" align="center">
            <template #default="{row}">
              <div class="actions" v-if="!row.init">
                <span v-if="!row.voucherId" @click="showForm(row)" class="fa fa-edit">编辑</span>
                <span v-if="!row.voucherId" @click="doTrash(row['id'])" class="fa fa-trash">删除</span>
              </div>
            </template>
          </vxe-column>
        </vxe-table>
      </div>
      <vxe-pager @page-change="onPageChange"
                 v-model:current-page="page.page"
                 v-model:page-size="page.pageSize"
                 :total="dataResult.total"
                 :layouts="['PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'Sizes', 'FullJump', 'Total']">
        <template #left>
          <vxe-button @click="loadList()" type="text" size="mini" icon="fa fa-refresh" :loading="loading"></vxe-button>
        </template>
      </vxe-pager>
    </div>
  </div>
</template>

<script>
import {layer} from "@layui/layer-vue";
import {h, ref} from "vue";
import InsureTemplateForm from "@/views/salary/InsureTemplateForm";
import InsuredConfig from "@/api/InsuredConfig";
import {confirm, message} from "heyui.ext";


/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
export default {
  name: "InsureIndex",
  data() {
    return {
      loading: false,
      dataResult: {
        results: [],
        total: 20
      },
      page: {
        page: 1,
        pageSize: 20
      },
      params: {
        search: ''
      }
    }
  },
  methods: {
    showForm(original) {
      let layerId = layer.drawer({
        title: '参保方案',
        shadeClose: true,
        closeBtn: false,
        area: ['50vw', '100vh'],
        content: h(InsureTemplateForm, {
          original,
          onClose: () => {
            layer.close(layerId);
          },
          onSuccess: () => {
            layer.close(layerId);
            this.loadData();
          }
        })
      });
    },
    doTrash(id) {
      confirm({
        title: "系统提示",
        content: `确认删除此记录?`,
        onConfirm: () => {
          this.loading = true;
          // InsuredConfig.delete(id).then(() => {
          // message("删除成功~");
          //   this.loadData();
          // }).finally(() => this.loading = false)
        }
      })
    },
    loadData() {
      this.loading = true;
      InsuredConfig.list(Object.assign({}, this.params, this.page)).then(({data}) => {
        this.dataResult = {
          page: 1,
          pageSize: 20,
          total: 4,
          totalPage: 1,
          results: [
            {id: '1', name: '老板方案'},
            {id: '2', name: '人事方案'},
            {id: '3', name: '产品方案'},
            {id: '4', name: '经理方案'},
          ]
        }
      }).finally(() => this.loading = false);
    },
    onPageChange({currentPage, pageSize}) {
      page.value.page = currentPage;
      page.value.pageSize = pageSize;
      this.loadData();
    }
  },
  created() {
    this.loadData();
  }
}
</script>

<style scoped>

</style>
