<template>
	<div class="modal-column">
		<div class="modal-column-body">
			<Form mode="block" ref="form" :rules="{}" :model="formData">
				<div class="inline-form-item">
					<FormItem label="编码" prop="code" required>
						<NumberInput v-model="formData.code"/>
					</FormItem>
					<FormItem label="名称" prop="name" required>
						<Input type="text" v-model="formData.name"/>
					</FormItem>
				</div>
				<div class="inline-form-item">
					<FormItem label="手机" prop="telPhone">
						<Input type="text" v-model="formData.telPhone"/>
					</FormItem>
					<FormItem label="性别" prop="gender">
						<Input type="text" v-model="formData.gender"/>
					</FormItem>
					<FormItem label="出生日期" prop="birthDate">
						<DatePicker v-model="formData.birthDate"/>
					</FormItem>
				</div>
				<div class="inline-form-item">
					<FormItem label="部门" prop="departmentId">
						<Select v-model="formData.departmentId" :datas="departmentList" keyName="id" titleName="name"/>
					</FormItem>
					<FormItem label="职务" prop="post">
						<Input type="text" v-model="formData.post"/>
					</FormItem>
					<FormItem label="岗位" prop="job">
						<Input type="text" v-model="formData.job"/>
					</FormItem>
				</div>
				<div class="inline-form-item">
					<FormItem label="入职日期" prop="onboardingDate">
						<DatePicker v-model="formData.onboardingDate"/>
					</FormItem>
					<FormItem label="离职日期" prop="dimissionDate">
						<DatePicker v-model="formData.dimissionDate"/>
					</FormItem>
				</div>
				<FormItem label="备注" prop="remark">
					<Input type="text" v-model="formData.remark"/>
				</FormItem>
			</Form>
		</div>
		<div class="modal-column-btn modal-column-between">
			<vxe-button :loading="loading" icon="fa fa-close" @click="$emit('close')">取消</vxe-button>
			<vxe-button :loading="loading" status="primary" icon="fa fa-save" @click="onSave">保存</vxe-button>
		</div>
	</div>
</template>

<script>
/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
export default {
	name: "EmployeeForm"
}
</script>

<script setup>
import {reactive, ref, defineEmits, defineProps, toRefs} from "vue";
import {Department, Employee} from "@/api/Auxiliary";
import {message} from "heyui.ext";
import {CopyObj} from "@/js/common/utils";

const emit = defineEmits(['close', 'success'])
const props = defineProps(['entity'])

const {entity} = toRefs(props)
const loading = ref(false);
const form = ref(null);

const formData = reactive({
	id: null,
	code: null,
	enable: true,
	name: null,
	remark: null,
	birthDate: null,
	departmentId: null,
	dimissionDate: null,
	gender: null,
	job: null,
	onboardingDate: null,
	post: null,
	telPhone: null,
})
const departmentList = ref([])
Department.all().then(({data}) => {
	departmentList.value = data;
})

const onSave = () => {
	const validResult = form.value.valid();
	if (validResult.result) {
		loading.value = true;
		Employee.save(formData).then(() => {
			message("保存成功~")
			emit('success')
		}).finally(() => loading.value = false)
	}
}

CopyObj(formData, entity.value);
</script>
