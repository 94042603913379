import {pick} from "xe-utils";
import manba from "manba";

/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */

const PrintStyle = `
<style>
table{
	border-collapse: collapse;
	width: 100%;
}
td,th{
	border: 1px solid #333333;
	padding: 8px;
	font-size: 12px;
}
th{
	text-align: center;
	font-weight: bold;
	white-space: nowrap;
}
.nowrap{
	white-space: nowrap;
}
.title{
	text-align: center;
	font-size: 26px;
	margin: 20px 0;
}
.info{
	font-size: 12px;
	margin-bottom: 5px;
}
.footer{
	text-align: right;
	font-size: 12px;
	margin-top: 5px;
}
</style>
`

class BooUtil {

	static PrintStyle = PrintStyle;

	constructor(xTable, br = '<br>') {
		this.xTable = xTable;
		this.header = xTable.getColumns().map(val => {
			const col = pick(val, ['id', 'title', 'align', 'parentId']);
			if (col.parentId) {
				const parent = xTable.getColumnById(col.parentId);
				col.title = parent.title + br + col.title;
			}
			return col;
		});
		const rows = xTable.$el.querySelectorAll('.vxe-table--body .vxe-body--row');
		let dataList = [];
		rows.forEach(row => {
			let rowData = {};
			row.querySelectorAll('.vxe-body--column').forEach(cell => {
				rowData[cell.getAttribute('colid')] = cell.innerText
			})
			dataList.push(rowData);
		})
		this.dataList = dataList;
		const headerMap = {};
		this.header.forEach(h => {
			headerMap[h.id] = h;
		})
		this.headerMap = headerMap;
	}

	printContent(title, companyName) {
		let table = [PrintStyle]
		table.push(`<div class="title">${title}</div>`)
		table.push(`<div class="info">编制单位：${companyName}</div>`)
		table.push("<table><tr>")
		table.push(this.header.map(val => `<th>${val.title}</th>`).join(""))
		table.push("</tr>")
		this.dataList.forEach(row => {
			table.push("<tr>")
			Object.keys(row).forEach((val, idx) => {
				if (idx === 0) {
					table.push(`<td class="nowrap" align="${this.headerMap[val].align || 'left'}">${row[val]}</td>`)
				} else {
					table.push(`<td align="${this.headerMap[val].align || 'left'}">${row[val]}</td>`)
				}
			})
			table.push("</tr>")
		})
		table.push("</table>")
		table.push(`<div class="footer">打印日期：${manba().format()}</div>`)
		return table.join("");
	}
}

export default BooUtil
