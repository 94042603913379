/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
import Ajax from '../js/common/ajax';

export const Invoice = {
	list(type, page, params) {
		return Ajax.post("/invoice/list/" + type, params, {params: page})
	},
	download(type, params) {
		return Ajax.post("/invoice/list/" + type, params, {responseType: 'blob'})
	},
	save(params) {
		return Ajax.post("/invoice", params)
	},
	remove(ids) {
		return Ajax.delete("/invoice?id=" + ids.join("&id="))
	},
	batchUpdate(invoiceBusinessTypeId, ids) {
		return Ajax.get("/invoice/batch/" + invoiceBusinessTypeId + "?id=" + ids.join("&id="))
	},
	batchCheck(checkDate, ids) {
		return Ajax.get(`/invoice/batch/check?checkDate=${checkDate}&id=` + ids.join("&id="))
	},
	generateVoucher(rows) {
		return Ajax.post(`/invoice/generate/voucher`, rows)
	},
	import(type, form) {
		return Ajax.post(`/invoice/import/${type}`, form, {'Content-Type': 'multipart/form-data'})
	}
}

export const InvoiceBusiness = {
	list(type) {
		return Ajax.get("/invoice/business", {type})
	},
	save(params) {
		return Ajax.post("/invoice/business", params)
	},
	remove(id) {
		return Ajax.delete("/invoice/business/" + id)
	}
}
