<template>
	<div v-if="!LOGINSTATUS" class="app-header">
		<div class="flex items-center " style="float:left;">
			<div style="width:50px;" v-if="currentAccountSets">
				<Button style="background: none;color: #fff;"  :icon="sliderCollapsed ? 'icon-align-right':'icon-align-left'" size="l" noBorder class="font20" @click="sliderCollapsed=!sliderCollapsed"></Button>
			</div>
			<app-header-account-sets class="ml-10px"/>
			<div style="width:1px;height:50px;margin-right:16px"></div> 
			<div class="h-input-group" style="line-height: normal">

				<Tooltip content="创建账套"></Tooltip>
				<img src="../../assets/index/加.png" style="margin-right: 20px" @click="showForm()">
			</div>
			<div class="h-input-group" style="line-height: normal">
				<Tooltip content="账套设置"></Tooltip>
				<img src="../../assets/index/设置.png" style="margin-right: 20px" @click="goForm()">
			</div>
			<div class="h-input-group" style="line-height: normal">
				<span class='text-14px' style="color: #fff">{{currentAccountDate}}</span>
			</div>
		</div>
		<span class="app-header-icon-item ml-20px text-14px" v-if="User.expirationDay<=31" style="font-weight: bold; color: black">
      		亲，您当前账号
			<template v-if="User.expirationDay">使用期只剩<b class="font-bold red-color">{{ User.expirationDay }}天</b></template>
      		<template v-else><b class="font-bold red-color">已于{{ User.expirationDate }}过期</b></template>喽！体验还不错的话，现在就续期吧。
      		<Button @click="goWorkbench" transparent style="background-color: #006dfd; color: white; border-radius: 6px" class="h-btn h-btn-s">立即购买</Button>
    	</span>

		<div class="float-right app-header-info mr-16px jiantou">
			<!-- <a href="http://www.jhqkg.com" target="_blank" class="topic-text">官网</a> -->
			<!-- <DropdownMenu class="topic-text"  trigger="hover"  :datas="helpMenu" @clickItem="helpTrigger">
				<span style="color: #fff;">帮助中心</span>
			</DropdownMenu> -->
			<!-- <span style="color: #fff;" @click='huanfu(1)'>换肤</span> -->

			<!-- <DropdownMenu  :datas="colArr" trigger="hover"  @clickItem="selCol">
				<span  style="color: #fff;">换肤</span>
			</DropdownMenu> -->
			<!-- <Tooltip
              theme="white"
              trigger="hover"
              editable >
              <span class="" style="color: #fff;">换肤</span>
              <template #content>
                <div style="padding: 10px;">
                    1
                </div>
              </template>
            </Tooltip> -->
        
			<DropdownCustom  trigger="click">
				<span style="color: #fff;">换肤</span>

				<template #content>
					<div style="padding: 10px;display:flex;align-items:center;cursor:pointer;"
					 v-for='item,index in colArr' :key='index' @click.native= 'huanfu(item.col,item.key)'>
					   <div style="width:24px;height:24px;" :style='{background:item.col}'></div>
					   <div  style="margin-left:5px">{{item.lable}}</div>
					</div>
				
					
              </template>
				
			</DropdownCustom>
			
			<span style="color: #fff;margin:0 20px">帮助中心</span>
			
			<!-- <DropdownMenu class="topic-text"  trigger="hover" :datas="helpMenu" @clickItem="helpTrigger">
				<span style="color: #fff;">学习中心</span>
			</DropdownMenu> -->
			<span style="color: #fff;margin:0 20px">学习中心</span>
			<!-- <span class="topic-text" @click="showImage(imageStatus)">客服</span> -->
			<!-- <a class="topic-text" @click="showPhone(phoneStatus)" >售后</a> -->
			<!-- <span class="topic-text" @click="goWorkbench">工作台</span> -->
			
			<DropdownMenu  :datas="moreMenu" trigger="hover"  @clickItem="moreTrigger">
				<span  style="color: #fff;">更多</span>
				<!-- <span class="topic-text" style="color: #fff;">更多</span> -->
			</DropdownMenu>

			

			<img @click="showImage(imageStatus)" v-if="imageStatus" class="topic-contact" src="../../assets/code.jpg" alt="客服">
			<span @click="showPhone(phoneStatus)" v-if="phoneStatus" class="topic-phone-contact">紧急联系点击：18329078628</span>

			<DropdownMenu className="" trigger="hover" offset="0 5" :width="150" placement="bottom-end" :datas="infoMenu" @clickItem="trigger">
				<Avatar :src="avatarUrl" :width="30"><span>{{ User.realName }}</span></Avatar>
			</DropdownMenu>
		</div>
	</div>
	<div v-else>
		<span class="app-header-icon-item ml-20px text-19px" style="color: black">
      		企业控制台
    	</span>
		<div class="float-right app-header-info mr-16px">
			<DropdownMenu className="app-header-dropdown" trigger="hover" offset="0 5" :width="150" placement="bottom-end" :datas="infoMenu" @clickItem="trigger">
				<Avatar :src="avatarUrl" :width="30"><span>{{ User.realName }}</span></Avatar>
			</DropdownMenu>
		</div>
	</div>
</template>
<script>
	import {h} from 'vue';
	import {mapGetters, mapState} from 'vuex';
	import manba from 'manba';
	import appHeaderMessage from './modules/app-header-message';
	import AppHeaderAccountSets from "./modules/app-header-account-sets";
	import {layer} from "@layui/layer-vue";
	import Index from "@/views/personal/Index";
	import Renewal from "@/views/app/Renewal";
	import setting from "@api/setting";
	import AccountForm from "@/views/setting/account/AccountForm";

	export default {
		components: {
			AppHeaderAccountSets,
			appHeaderMessage
		},
		data() {
			return {
				searchText: '',
				currentAccountDate: null,
				imageStatus: false,
				phoneStatus: false,
				helpMenu: [
					{key: 'key1', title: '官网首页'},
					{key: 'key2', title: '计算器'},
					{key: 'key3', title: '个税计算器'},
					{key: 'key4', title: '查询导航'},
					{key: 'key5', title: '分类大全'}
				],
				moreMenu: [
					{key: 'key1', title: '官网首页'},
					{key: 'key2', title: '计算器'},
					{key: 'key3', title: '个税计算器'},
					{key: 'key4', title: '查询导航'},
					{key: 'key5', title: '分类大全'}
				],
				colArr:[
					{lable:'天空蓝(默认)',col:"#409EFF",key:'1'},
					{lable:'夜空蓝',col:"#4F81BD",key:'2'},
					{lable:'喜庆红',col:"#ff2c06",key:'3'},
					{lable:'珊瑚橙',col:"#ff7164",key:'4'},
					{lable:'高贵紫',col:"#c469db",key:'5'},
					{lable:'樱花粉',col:"#f18ae3",key:'6'},
					{lable:'温暖黄',col:"#FFA500",key:'7'},
					{lable:'护眼色',col:"#7bbf82",key:'8'},
				],
				coltype:1,
				infoMenu: [
					{key: 'info', title: '个人信息', icon: 'h-icon-user'},
					{key: 'logout', title: '退出登录', icon: 'h-icon-outbox'}
				],
				LOGINSTATUS: null,
				them:1,
				nowColor: "1",
			};
		},
		computed: {
			...mapState(['User', 'currentAccountSets']),
			...mapGetters(['checkoutList']),
			sliderCollapsed: {
				get() {
					return this.$store.state.sliderCollapsed;
				},
				set(value) {
					this.$store.commit('updateSliderCollapse', value);
				}
			},
			accountDate() {
				return this.currentAccountSets ? manba(this.currentAccountSets.currentAccountDate).format("YYYY年MM月") : null;
			},
			avatarUrl() {
				return this.User.avatarUrl || '/images/yun.png'
			},
			dOp() {
				let filter = this.checkoutList.filter(val => val.status !== 2);
				return {
					start: filter[filter.length - 1].checkDate,
					end: filter[0].checkDate,
				}
			}
		},
		watch: {
			accountDate() {
				this.currentAccountDate = this.accountDate;
			}
		},
		mounted() {
			this.currentAccountDate = this.accountDate;
			const resizeEvent = window.addEventListener('resize', () => {
				if (this.sliderCollapsed && window.innerWidth > 900) {
					this.sliderCollapsed = false;
				} else if (!this.sliderCollapsed && window.innerWidth < 900) {
					this.sliderCollapsed = true;
				}
				if (!this.currentAccountSets) {
					this.sliderCollapsed = true;
				}
			});
			window.dispatchEvent(new Event('resize'));
		},
		methods: {
			
			headleChangeColor (item) {
				console.log(this.nowColor,'item')
			// this.$emit('change', item)
			},
			helpTrigger(data) {
				if (data === 'key1') {

				} else if (data === 'key2') {

				} else if (data === 'key3') {

				} else if (data === 'key4') {

				} else if (data === 'key5') {

				}
			},
			moreTrigger(data) {
				if (data === 'key1') {

				} else if (data === 'key2') {

				} else if (data === 'key3') {

				} else if (data === 'key4') {

				} else if (data === 'key5') {

				}
			},
			selCol(data){
				if (data === 'key1') {

				} else if (data === 'key2') {

				} else if (data === 'key3') {

				} else if (data === 'key4') {

				} else if (data === 'key5') {

				}
			},
			trigger(data) {
				if (data === 'logout') {
					this.$api.common.logout().then(() => {
						window.location.replace("/");
					});
				} else {
					layer.open({
						title: "个人信息设置",
						shadeClose: false,
						zIndex: 999,
						area: ['700px', '500px'],
						content: h(Index)
					});
				}
			},
			doRenewal() {
				const layerId = layer.open({
					title: "账号续期",
					shadeClose: false,
					closeBtn: false,
					zIndex: 1000,
					area: ['500px', 'auto'],
					content: h(Renewal, {
						onClose() {
							layer.close(layerId);
						},
						onSuccess() {
							layer.close(layerId);
						}
					})
				});
			},
			accountDateChange(val) {
				let date = manba(val.replace("年", "-").replace("月", "-") + "01").format();
				setting.accountSets.updateCurrentAccountDate(date).then(() => {
					this.$store.dispatch('init');
				})
			},
			showForm(entity = null) {
				let layerId = layer.open({
					title: "账套信息",
					shadeClose: false,
					closeBtn: false,
					zIndex: 999,
					area: ['900px', '420px'],
					content: h(AccountForm, {
						entity,
						onClose: () => {
							layer.close(layerId);
						},
						onSuccess: () => {
							this.init();
							this.loadList();
							layer.close(layerId);
						}
					})
				});
			},
			goForm() {
				this.$store.commit('newTab', {key: "Account", title: "账套"})
			},
			showImage (imageStatus) {
				this.imageStatus = imageStatus !== true;
			},
			showPhone (phoneStatus) {
				this.phoneStatus = phoneStatus !== true;
			},
			goWorkbench() {
				localStorage.setItem('LOGINSTATUS', 'LOGINSTATUS')
				this.$store.commit('newTab', {key: "ApplicationList", title: ""})
				window.location.replace('/')
			},
			huanfu(val,type){
				this.coltype = type
				this.getcolType(type)
				this.changeTheme(val)
			},
			changeTheme(col) {
				const link = document.createElement('link');
				document.documentElement.style.setProperty("--primary", col)
				link.rel = 'stylesheet';
				link.href = `/styles/doc/themes/pink/index.css`;
				// link.href = `https://www.heyui.top/themes/${theme}/index.css`;
				document.getElementsByTagName('head')[0].appendChild(link);
				// HeyUI.changeTheme('#FDA729')
                // return

			},
			getcolType(type){
				this.$emit('getcolType',type)
			},
			
		},
		created() {
			this.LOGINSTATUS = localStorage.getItem("LOGINSTATUS")
		}
	};
</script>
<style lang="less">
 
	.app-header {
		color: rgba(49, 58, 70, 0.8);

		.h-autocomplete {
			line-height: 1.5;
			float: left;
			margin-top: 15px;
			margin-right: 20px;
			width: 120px;

			&-show, &-show:hover, &-show.focusing {
				outline: none;
				box-shadow: none;
				border-color: transparent;
				border-radius: 0;
			}

			&-show.focusing {
				border-bottom: 1px solid #eee;
			}
		}

		&-info &-icon-item {
			cursor: pointer;
			display: inline-block;
			float: left;
			padding: 0 15px;
			height: @layout-header-height;
			line-height: @layout-header-height;
			margin-right: 10px;

			&:hover {
				background: @hover-background-color;
			}

			i {
				font-size: 18px;
			}

			a {
				color: inherit;
			}

			.h-badge {
				margin: 20px 0;
				display: block;
			}
		}

		.app-header-icon-item {
			margin-left: 20px;

			button {
				background-color: #ffffff;
			}
		}

		.topic-text {
			margin-right: 20px;
			cursor: pointer;
		}

		.topic-contact {
			position: fixed;
			z-index: 2000;
			height: 360px;
		}

		.topic-phone-contact {
			color: red;
			font-weight: bold;
			font-size: 16px;
			text-align: center;
			border: 3px solid black;
			border-radius: 8px;
			position: fixed;
			z-index: 1000;
			line-height: 40px;
			width: 240px;
			left: 78%;
			top: 60px;
		}

		&-dropdown {
			float: right;
			margin-left: 10px;
			padding: 0 5px 0 15px;

			.h-icon-down {
				right: 20px;
			}

			cursor: pointer;

			&:hover, &.h-pop-trigger {
				background: @hover-background-color;
			}

			&-dropdown {
				padding: 5px 0;

				.h-dropdownmenu-item {
					padding: 8px 20px;
				}
			}
		}

		&-menus {
			display: inline-block;
			vertical-align: top;

			> div {
				display: inline-block;
				font-size: 15px;
				padding: 0 25px;
				color: @dark-color;

				&:hover {
					color: var(--primary);
				}

				+ div {
					margin-left: 5px;
				}

				&.h-tab-selected {
					color: @white-color;
					background-color: var(--primary);
				}
			}
		}
	}

	.jiantou{
		.h-icon-down:before{
		color: #fff;
	   }
	   .h-avatar-info{
		 color: #fff;
	   }
	}
</style>
