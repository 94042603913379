<template>
  <div class="modal-column">
    <div class="mb-16px flex flex-column items-center">
      <div class="w-960px main-content-title">
        <span>{{ title }}</span>
      </div>
      <div class="w-960px main-content-info">
        <span>账户：<span>{{ account.code }} {{ account.name }}</span></span>
        <span><span>{{ journal.billDate }}</span></span>
        <span>NO.<span>{{ journal.serialNumber }}</span></span>
      </div>
      <div class="main-content-table">
        <table>
          <tbody>
          <tr>
            <td>摘要</td>
            <td colspan="4"><span>{{ journal.digest }}</span></td>
          </tr>
          <tr>
            <td>大写金额</td>
            <td colspan="2"><span>{{ dxMoney(journal.debit || journal.credit) }}</span></td>
            <td>小写金额</td>
            <td><span>{{ journal.debit || journal.credit }}</span></td>
          </tr>
          <tr>
            <td>往来单位</td>
            <td colspan="2"></td>
            <td>结算方式</td>
            <td></td>
          </tr>
          <tr>
            <td>收支类别</td>
            <td colspan="2"><span>{{ journal.ioTypeValue }}</span></td>
            <td>票据号</td>
            <td></td>
          </tr>
          <tr>
            <td>项目</td>
            <td colspan="2"></td>
            <td>部门</td>
            <td></td>
          </tr>
          <tr>
            <td>备注</td>
            <td colspan="4"></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="modal-column-btn">
      <Button @click="printer" color="primary" icon="fa fa-print">打印</Button>
    </div>
  </div>
</template>

<script>
/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
export default {
  name: "CashierJournalEvidence",
  props: {
    journal: Object,
    account: Object,
  },
  computed: {
    title() {
      if (this.journal.ioTypeValue) {
        const split = this.journal.ioTypeValue.split("-");
        if (split[0] === '收') {
          return '收款凭证';
        }
        return '付款凭证';
      }
      return "转账凭证";
    }
  },
  methods: {
    printer() {
      window.open("/api/pdf/journal?journal=" + this.journal.id)
    }
  },
}
</script>
<style lang="less" scoped>
.main-content-title {
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  margin-top: 30px;
}

.main-content-info {
  margin: 22px 0 17px 0;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
}

.main-content-table table {
  width: 960px;
  margin: 0 auto;
  font-size: 14px;
  table-layout: fixed;
  border-spacing: 0px;
  border: 1px solid #dadada;
}

.main-content-table tr {
  height: 57px;
  line-height: 57px;
}

.main-content-table tr td {
  border-right: 1px solid #dadada;
  border-bottom: 1px solid #dadada;
}

.main-content-table tr td:nth-child(1) {
  text-align: center;
}

.main-content-table tr td:nth-child(2) {
  text-align: left;
  padding-left: 20px;
}

.main-content-table tr td:nth-child(3) {
  text-align: center;
}

.main-content-table tr td:nth-child(4) {
  text-align: left;
  padding-left: 20px;
}
</style>
