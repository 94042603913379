/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
import Ajax from '../js/common/ajax';

export default {
	loadSubject(params = {}) {
		return Ajax.get("/accountBook/subject", params)
	},
	loadAuxiliary(params = {}) {
		return Ajax.get("/accountBook/auxiliary", params)
	},
	loadVoucherDetails(params = {}) {
		return Ajax.get("/accountBook/details", params)
	},
	loadGeneralLedger(params = {}) {
		return Ajax.get("/accountBook/generalLedger", params)
	},
	loadSubjectBalance(params = {}) {
		return Ajax.get("/accountBook/subjectBalance", params)
	},
	loadSubjectSummary(params = {}) {
		return Ajax.get("/accountBook/subjectSummary", params)
	},
	loadAuxiliaryDetails(params = {}) {
		return Ajax.get("/accountBook/auxiliaryDetails", params)
	},
	loadAuxiliaryList(params = {}) {
		return Ajax.get("/accountBook/auxiliaryList", params)
	},
	loadAuxiliaryBalance(params = {}) {
		return Ajax.get("/accountBook/auxiliaryBalance", params)
	},
	timeSeries(params = {}) {
		return Ajax.get("/accountBook/timeSeries", params)
	},
	print(content) {
		return Ajax.post(`/report/export/book/pdf`, content, {
			responseType: 'blob'
		})
	},
	download(params) {
		return Ajax.post(`/report/export/book/download`, params, {
			responseType: 'blob'
		})
	},
	downloadLedger(params) {
		return Ajax.post(`/report/export/book/ledger/download`, params, {
			responseType: 'blob'
		})
	}
}
