<template>
  <div class="h-full p-16px">
    <div class="h-full  flex flex-column">
      <vxe-toolbar class-name="px-8px" :loading="loading">
        <template #buttons>
          <div class="h-input-group h-input-group-auto">
            <span class="">期间：</span>
            <Select v-model="params.start" :datas="checkDataList" class="!w-120px" :deletable="false"/>
            <span class="h-input-addon">至</span>
            <Select v-model="params.end" :datas="checkDataList" class="!w-120px" :deletable="false"/>
            <Button :loading="loading" @click="loadList" color="primary" icon="">查询</Button>
          </div>
        </template>
        <template #tools>
           <Button :loading="loading"  color="" icon="">打印</Button>
           <Button :loading="loading"  color="" icon="">导出</Button>
        </template>
      </vxe-toolbar>
      <div class="flex-1">
        <vxe-table :row-class-name="rowClassName"  height="auto" ref="xTable" :loading="loading" :data="dataList" :tree-config="{transform:true}">
          <vxe-column title="项目" field="type" width="200" tree-node/>
          <vxe-column title="名称" field="name" width="120"/>
          <vxe-column title="期初余额" field="startBalance" align="right" formatter="toMoney"/>
          <vxe-column title="借方" field="debit" align="right" formatter="toMoney"/>
          <vxe-column title="贷方" field="credit" align="right" formatter="toMoney"/>
          <vxe-column title="余额" field="balance" align="right" formatter="toMoney"/>
        </vxe-table>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
export default {
  name: "AssetsCheck"
}
</script>
<script setup>

import {nextTick, reactive, ref} from "vue";
import {useStore} from "vuex";
import manba from "manba";
import Assets from "@/api/Assets";

const store = useStore();
const tab = ref('deptSummary');
const xTable = ref(null);
const loading = ref(false);
const dataList = ref([])
const checkDataList = ref([])
const currentAccountDate = manba(store.state.currentAccountSets.currentAccountDate).startOf(manba.MONTH).format();

const params = reactive({
  start: currentAccountDate,
  end: currentAccountDate,
  type: null,
})
Assets.checkDate().then(({data}) => {
  checkDataList.value = data;
})

const getEnd = () => {
  return {
    end: manba(params.end).endOf(manba.MONTH).format()
  }
}

const loadList = () => {
  loading.value = true;
  Assets.check(Object.assign({}, params, getEnd())).then(({data}) => {
    dataList.value = data;
    nextTick(() => {
      xTable.value.setAllTreeExpand(true)
    })
  }).finally(() => loading.value = false)
}
loadList();

const rowClassName = ({level, row}) => {
  if (row.id === 1 || row.id === 4) {
    return 't-group';
  }
}
</script>
<style scoped lang="less">
  /deep/ .t-group{
  background-color:rgba(47, 168, 253, 0.05) !important;
}
</style>
