<template>
  <div class="h-full p-20px">
    <div class="flex flex-column h-full">
      <vxe-toolbar class-name="px-16px" :loading="loading">
        <template #buttons>
          <account-date-choose v-model="entryDate"/>
        </template>
        <template #tools>
          <i class="vxe-icon-setting" style="cursor: pointer; color: #3385ff" @click="salaryVoucherSettings"><a style="margin-left: 5px">工资凭证设置</a></i>
        </template>
      </vxe-toolbar>
      <div class="checkout-content">
        <div id="itemBoxsContext" class="item-boxs-context">
          <div class="item-box item-box-create" id="salesCost0">
            <div class="item-box-title">计提工资</div>
            <div class="money">
              <span class="currecy has-currency hasCurrency tooltip-f" title="">{{ AccruedSalary }}</span>
            </div>
<!--            <div class="check-btn create-voucher-btn" @click="generateVouchers('AccruedSalary')">生成凭证</div>-->
             <router-link width="1024px" tag="button" :to="{name:'VoucherForm', params: ''}" class="check-btn create-voucher-btn">生成凭证</router-link>
          </div>
          <div class="item-box item-box-create" id="salesCost1">
            <div class="item-box-title">发放工资</div>
            <div class="money">
              <span class="currecy has-currency hasCurrency tooltip-f" title="">{{ PayrollSalary }}</span>
            </div>
<!--            <div class="check-btn create-voucher-btn" @click="generateVouchers('PayrollSalary')">生成凭证</div>-->
            <router-link width="1024px" tag="button" :to="{name:'VoucherForm', params: ''}" class="check-btn create-voucher-btn">生成凭证</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


  import {mapGetters, mapState} from "vuex";

 /**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */

  export default {
    name: "SalaryManagement",
    computed: {
      ...mapState(['currentAccountSets']),
      ...mapGetters(['checkoutSelect', 'localCurrency']),
      deptListMap() {
        let map = {};
        this.deptList.map(val => {
          map[val.key] = val.title;
        })
        return map;
      },
    },
     methods:{
         salaryVoucherSettings(){
             this.$store.commit('newTab', {key: 'SalaryVoucherTemplate', title: "凭证列表"})

         }
     }
  }
</script>
<script setup>
  import {ref, h, watch, reactive} from "vue";
  import manba from "manba";
  import {useStore} from 'vuex'
  import SalaryChange from "@/api/SalaryChange";
  import SalaryVoucherTemplate from "@/views/salary/SalaryVoucherTemplate";
  import {layer} from "@layui/layer-vue";

  const store = useStore();
  const {currentAccountSets} = store.state

  const paramsDropdown = ref(null);
  const loading = ref(false);

  const entryDate = ref(currentAccountSets.currentAccountDate)

  const updating = ref(false);

  const AccruedSalary = ref(0.00);
  const PayrollSalary = ref(0.00);

  const params = reactive({
    entryDate: entryDate,
    deptId: null,
    name: null
  });

  const loadList = () => {
    // SalaryChange.length(entryDate.value).then(() => {
    // })
    AccruedSalary.value = '5211.12'
    PayrollSalary.value = '3689.88'
  }
  loadList();

  // 工资凭证设置接口
  // const salaryVoucherSettings = () => {
  //   layer.drawer({
  //     title: "工资凭证设置",
  //     zIndex: 1000,
  //     area: ['700px', '100vh'],
  //     content: h(SalaryVoucherTemplate)
  //   });
  // }

  // 个税申报接口
  const generateVouchers = (type) => {
    if (type === 'AccruedSalary') {

    } else if (type === 'PayrollSalary') {

    }

  }

  watch(entryDate, () => {
    loadList();
  })

</script>

<style lang="less" scoped>
  .checkout-content {
    position: relative;
    background-color: #ffffff;
    min-height: 496px;
  }
  .item-boxs-context {
    margin-left: -26px;
    min-height: 348px;
  }
  .item-boxs-context .item-box {
    width: 218px;
    height: 153px;
    border: 1px solid #2FA8FD;
    display: inline-block;
    margin-left: 26px;
    margin-bottom: 20px;
  }
  .item-box .item-box-title {
    color: #333333;
    font-size: 16px;
    line-height: 36px;
    border-bottom: 1px solid #2FA8FD;
    text-align: center;
  }
  .money {
    height: 25px;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
  }
  .money .currecy.has-currency {
    color: #333333;
    text-decoration: underline;
    cursor: pointer;
  }
  .money .currecy {
    font-size: 19px;
    line-height: 25px;
    display: inline-block;
  }
  .check-btn.create-voucher-btn {
    background-color: #2FA8FD;
  }
  .check-btn {
    height: 30px;
    width: 96px;
    text-align: center;
    color: #ffffff;
    font-size: 14px;
    line-height: 30px;
    border-radius: 2px;
    margin: 0 auto 20px;
    cursor: pointer;
  }
</style>
