<template>
  <div class="modal-column">
    <div class="flex-1 p-20px">
      <Form ref="form" :model="formData" :rules="{}" mode="block">
        <div class="flex">
          <FormItem class="flex-1" label="记账日期" prop="billDate" required>
            <DatePicker v-model="formData.billDate" :clearable="false" :option="{start:startDate,end:endDate}"/>
          </FormItem>
          <FormItem class="flex-1 ml-16px" label="收支类别" required prop="ioTypeId">
            <Select :datas="ioTypeOptions" filterable v-model="formData.ioTypeId" :deletable="false"/>
          </FormItem>
        </div>
        <FormItem label="摘要" prop="digest" required>
          <Input type="text" v-model="formData.digest"/>
        </FormItem>
        <div class="flex">
          <FormItem class="flex-1 " label="收入(借方)" :required="formData.ioTypeId && (ioTypeMap[formData.ioTypeId] && ioTypeMap[formData.ioTypeId].type==='收')" prop="debit">
            <Input type="text" v-model="formData.debit" :disabled="!formData.ioTypeId || (ioTypeMap[formData.ioTypeId] && ioTypeMap[formData.ioTypeId].type!=='收')"/>
          </FormItem>
          <FormItem class="flex-1 ml-16px" label="支出(贷方)" :required="formData.ioTypeId && (ioTypeMap[formData.ioTypeId] && ioTypeMap[formData.ioTypeId].type==='支')" prop="credit">
            <Input type="text" v-model="formData.credit" :disabled="!formData.ioTypeId || (ioTypeMap[formData.ioTypeId] && ioTypeMap[formData.ioTypeId].type!=='支')"/>
          </FormItem>
          <FormItem class="flex-1 ml-16px" label="结算方式" prop="settlementTypeId">
            <Select :datas="settlementTypeOptions" filterable v-model="formData.settlementTypeId"/>
          </FormItem>
        </div>
        <div class="flex">
          <FormItem class="flex-1 " label="往来单位" prop="accountingCategoryDetailsId">
            <DropdownCustom ref="categoryDropdown" class="block-dropdown" trigger="manual">
              <Input @click="$refs.categoryDropdown.show()" type="text" v-model="formData.accountingCategoryDetailsValue" readonly/>
              <template #content>
                <CashierAssistingAccounting @selected="onCategorySelected" @clear="onCategoryClear" @close="onCategoryClose"/>
              </template>
            </DropdownCustom>
          </FormItem>
          <FormItem class="flex-1 ml-16px" label="项目" prop="projectCategoryDetailsId">
            <Select :datas="projectOptions" filterable v-model="formData.projectCategoryDetailsId"/>
          </FormItem>
          <FormItem class="flex-1 ml-16px" label="部门" prop="deptCategoryDetailsId">
            <Select :datas="deptOptions" filterable v-model="formData.deptCategoryDetailsId"/>
          </FormItem>
        </div>
        <div class="flex">
          <FormItem class="flex-1" label="单据号" prop="billNo">
            <Input type="text" v-model="formData.billNo"/>
          </FormItem>
          <FormItem class="flex-1 ml-16px" label="备注" prop="remark">
            <Input type="text" v-model="formData.remark"/>
          </FormItem>
        </div>
      </Form>
    </div>
    <div class="modal-column-btn">
      <Button @click="$emit('close')">取消</Button>
      <Button color="green" @click="submit" :loading="loading">{{ formData.id ? '更新' : '保存' }}</Button>
    </div>
  </div>
</template>

<script>
/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
export default {
  name: "CashierJournalForm"
}
</script>

<script setup>
import {defineProps, reactive, ref, toRefs, defineEmits, watch} from "vue";
import CashierJournal from "@/api/CashierJournal";
import {CopyObj} from "@/js/common/utils";
import CashierAssistingAccounting from "@/views/cashier/AssistingAccounting";

let props = defineProps(['entity', 'account', 'startDate', 'endDate', 'ioTypeOptions', 'ioTypeMap', 'settlementTypeOptions', 'deptOptions', 'projectOptions']);
const {entity, account, startDate, endDate, ioTypeOptions, ioTypeMap, settlementTypeOptions, deptOptions, projectOptions} = toRefs(props);

let emits = defineEmits(['success', 'close']);

const loading = ref(false);
const form = ref(null);
const categoryDropdown = ref(null);

const formData = reactive({
  "id": null,
  "type": account.value.type,
  "digest": "",
  "serialNumber": "",
  "billDate": endDate.value,
  "accountId": account.value.id,
  "ioTypeId": null,
  "ioTypeValue": "",
  "accountingCategoryId": null,
  "accountingCategoryDetailsId": null,
  "accountingCategoryDetailsValue": "",
  "projectCategoryId": null,
  "projectCategoryDetailsId": null,
  "deptCategoryId": null,
  "deptCategoryDetailsId": null,
  "debit": null,
  "credit": null,
  "remark": null,
  "billNo": null,
  "settlementTypeId": null,
})

const submit = () => {
  let validResult = form.value.valid();
  if (validResult.result) {
    loading.value = true;
    if (formData.projectCategoryDetailsId) {
      formData.projectCategoryId = projectOptions.value.find(val => val.key === formData.projectCategoryDetailsId).accountingCategoryId;
    } else {
      formData.projectCategoryId = null;
    }
    if (formData.deptCategoryDetailsId) {
      formData.deptCategoryId = deptOptions.value.find(val => val.key === formData.deptCategoryDetailsId).accountingCategoryId;
    } else {
      formData.deptCategoryId = null;
    }
    console.log(formData)
    CashierJournal.save(formData).then(() => {
      emits('success');
    }).finally(() => loading.value = false)
  }
}

const onCategorySelected = ({categoryId, id, name, code}) => {
  formData.accountingCategoryDetailsId = id;
  formData.accountingCategoryDetailsValue = code + "-" + name;
  formData.accountingCategoryId = categoryId;
  categoryDropdown.value.hide();
}

const onCategoryClear = () => {
  formData.accountingCategoryDetailsId = null;
  formData.accountingCategoryDetailsValue = null;
  formData.accountingCategoryId = null;
  categoryDropdown.value.hide();
}

const onCategoryClose = () => {
  categoryDropdown.value.hide();
}

watch(() => formData.ioTypeId, (value, oldValue) => {
  if (oldValue) {
    const newType = ioTypeMap.value[value];
    const oldType = ioTypeMap.value[oldValue];

    if (newType.type !== oldType.type) {
      const {debit, credit} = formData;
      formData.debit = credit;
      formData.credit = debit;
    }
  }
})

CopyObj(formData, entity.value);
</script>
