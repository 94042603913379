<template>
  <vxe-toolbar class="bg-white-color p-16px">
    <template #buttons>
      <DatePicker v-model="params.start" :clearable="false" :option="{end:params.end}"/>
      <span class="mx-8px">至</span>
      <DatePicker v-model="params.end" :clearable="false" :option="{start:params.start}"/>
      <Button @click="loadList()" class="mx-8px" color="primary" icon="fa fa-search">查询</Button>
    </template>
    <template #tools>
      <vxe-button content="打印" icon="fa fa-print"/>
      <vxe-button content="导出" icon="fa fa-download"/>
    </template>
  </vxe-toolbar>
  <div class="flex-1 h-0px overflow-y bg-white-color">
    <vxe-table :data="cashList" class="mx-16px mb-8px"  :highlight-current-row="false">
      <vxe-column title="编码" field="code"/>
      <vxe-column title="收支类别" field="name"/>
      <vxe-column title="收入总额" field="sr" align="right" formatter="toMoney"/>
      <vxe-column title="支出总额" field="zc" align="right" formatter="toMoney"/>
      <vxe-column title="收入笔数" field="srbs" align="right"/>
      <vxe-column title="支出笔数" field="zcbs" align="right"/>
    </vxe-table>
    <vxe-table :data="bankList" class="mx-16px mb-8px"  :highlight-current-row="false">
      <vxe-column title="编码" field="code"/>
      <vxe-column title="收支类别" field="name"/>
      <vxe-column title="收入总额" field="sr" align="right" formatter="toMoney"/>
      <vxe-column title="支出总额" field="zc" align="right" formatter="toMoney"/>
      <vxe-column title="收入笔数" field="srbs" align="right"/>
      <vxe-column title="支出笔数" field="zcbs" align="right"/>
    </vxe-table>
    <vxe-table row-class-name="highlight-aggregate" :data="aggregateData" class="mx-16px mb-8px"  :show-header="false" :highlight-current-row="false">
      <vxe-column title="编码" field="code"/>
      <vxe-column title="收支类别" field="name"/>
      <vxe-column title="收入总额" field="sr" align="right" formatter="toMoney"/>
      <vxe-column title="支出总额" field="zc" align="right" formatter="toMoney"/>
      <vxe-column title="收入笔数" field="srbs" align="right"/>
      <vxe-column title="支出笔数" field="zcbs" align="right"/>
    </vxe-table>
    <div class="pl-20px">
      <div class="red-color font-bold">提示：</div>
      内部转账，不计入收支
    </div>
  </div>
</template>

<script>
/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
export default {
  name: "IoSummary"
}
</script>

<script setup>
import {ref} from "vue";
import manba from "manba";
import {useStore} from "vuex";
import CashierReport from "@/api/CashierReport";
import {layer} from "@layui/layer-vue";

const store = useStore();
const currentAccountDate = manba(store.state.currentAccountSets.currentAccountDate);
const startDate = ref(currentAccountDate.startOf(manba.MONTH).format());
const endDate = ref(currentAccountDate.endOf(manba.MONTH).format());

const cashList = ref([]);
const bankList = ref([]);

const params = ref({
  start: startDate,
  end: endDate,
})

const summaryCol = ['sr', 'zc', 'srbs', 'zcbs'];

const aggregateData = ref([])

const loadList = () => {
  const aggregateDataRow = {name: '总计'};
  const cashFooterData = {name: '收入合计'};
  const bankFooterData = {name: '支出合计'};

  const loadId = layer.load(1);
  CashierReport.ioTypeSummary(params.value).then(({data}) => {
    (data || []).forEach(val => {
      for (let i = 0; i < summaryCol.length; i++) {
        const fieldName = summaryCol[i];
        if (val.type === '收') {
          cashFooterData[fieldName] = (cashFooterData[fieldName] || 0) + val[fieldName] || 0;
        } else {
          bankFooterData[fieldName] = (bankFooterData[fieldName] || 0) + val[fieldName] || 0;
        }
        aggregateDataRow[fieldName] = (aggregateDataRow[fieldName] || 0) + (val[fieldName] || 0)
      }
    })
    aggregateData.value = [aggregateDataRow];
    cashList.value = (data || []).filter(val => val.type === '收');
    cashList.value.push(cashFooterData);
    bankList.value = (data || []).filter(val => val.type === '支');
    bankList.value.push(bankFooterData);
  }).finally(() => layer.close(loadId))
}
loadList()
</script>
