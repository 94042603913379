<template>
  <div class="v-table-tr-total">
    <div class="v-table-tr-total-uppercase">
      合计：{{ dxMoney(totalDebit || 0) }}
    </div>
    <div class="v-table-tr-total-debit">
      <v-table-number :number="totalDebit" readonly/>
    </div>
    <div class="v-table-tr-total-credit">
      <v-table-number :number="totalCredit" readonly/>
    </div>
  </div>
</template>

<script setup>
import {onMounted, ref, watch} from "vue";
import VTableNumber from "@components/VoucherTable/VTableNumber.vue";
import {add, floor} from "xe-utils";

const props = defineProps(['detailList']);

const totalDebit = ref(null);
const totalCredit = ref(null);

watch(() => props.detailList, (detail) => {
  calculateTotal(detail)
}, {deep: true})

const calculateTotal = (detail) => {
  if (detail) {
    const debitAmount = detail.reduce((total, row) => add(total, (row.debitAmount || 0)), 0);
    const creditAmount = detail.reduce((total, row) => add(total, (row.creditAmount || 0)), 0);
    if (debitAmount) {
      totalDebit.value = floor(debitAmount, 2)
    } else {
      totalDebit.value = 0;
    }
    if (creditAmount) {
      totalCredit.value = floor(creditAmount, 2)
    } else {
      totalCredit.value = 0;
    }
  }
}

onMounted(() => {
  calculateTotal(props.detailList)
})
</script>

<style scoped lang="less">
.v-table-tr-total {
  height: 60px;
  display: flex;

  &-uppercase {
    border-right: @border;
    font-weight: bold;
    font-size: 14px;
    line-height: 60px;
    padding: 0 10px;
    flex: 1;
  }

  &-debit, &-credit {
    width: 221px;
    border-right: @border;
    background-image: url("../../assets/moneyUint.png");
  }
}
</style>
