<template>
  <div class="modal-column">
    <div class="flex-1 p-20px">
      <Form ref="form" :model="form" :rules="{}" mode="block">
        <FormItem label="编码" prop="code" required>
          <Input type="text" v-model="form.code"/>
        </FormItem>
        <FormItem label="名称" prop="name" required>
          <Input type="text" v-model="form.name"/>
        </FormItem>
        <FormItem label="银行账号" prop="cardNumber" v-if="type==='银行存款'">
          <Input type="text" v-model="form.cardNumber"/>
        </FormItem>
        <FormItem label="币别" required>
          <Select :datas="currencyList" keyName="id" titleName="name" filterable v-model="form.currencyId">
            <template v-slot:item="{ item }">
              {{ item.code }} {{ item.name }}
            </template>
          </Select>
        </FormItem>
        <FormItem label="会计科目">
          <Select :datas="subjectList" keyName="id" titleName="name" filterable v-model="form.subjectId" :filter="filter">
            <template v-slot:item="{ item }">
              {{ item.code }} {{ item.name }}
            </template>
          </Select>
        </FormItem>
      </Form>
    </div>
    <div class="modal-column-btn">
      <Button @click="$emit('close')">取消</Button>
      <Button color="green" @click="submit" :loading="loading">{{ form.id ? '更新' : '保存' }}</Button>
    </div>
  </div>
</template>

<script>/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
import CashierAccount from "@/api/CashierAccount";
import Setting from "@/api/setting";
import {CopyObj} from "@/js/common/utils";

export default {
  name: "CashierAccountForm",
  props: {
    type: String,
    nextCode: String,
    entity: Object,
  },
  data() {
    return {
      loading: false,
      form: {
        id: null,
        code: null,
        name: null,
        cardNumber: null,
        balance: null,
        currencyId: null,
        subjectId: null,
        accountSetsId: null,
        type: this.type,
      },
      currencyList: [],
      subjectList: []
    }
  },
  methods: {
    filter(item, val) {
      return item.code.indexOf(val) !== -1 || item.name.indexOf(val) !== -1 || item.mnemonicCode.indexOf(val) !== -1;
    },
    submit() {
      let validResult = this.$refs.form.valid();
      if (validResult.result) {
        this.loading = true;
        CashierAccount.save(this.form).then(() => {
          this.$emit('success');
        }).finally(() => {
          this.loading = false;
        })
      }
    }
  },
  created() {
    Setting.subject.voucherSelect({showAll: true}).then(({data}) => {
      this.subjectList = data || [];
    })
    Setting.currency.list().then(({data}) => {
      this.currencyList = data || [];
    })

    CopyObj(this.form, this.entity);
    if (!this.entity) {
      this.form.code = this.nextCode;
    }
  }
}
</script>
