<template>
  <div class="modal-column">
    <!--    <div class="px-16px py-8px ">
          <Checkbox v-model="merge">合并凭证</Checkbox>
          <Checkbox v-if="merge" class="inline ml-16px" :datas="{mergeSel:'合并相同的收支科目',mergeOther:'合并相同的对方科目'}" v-model="mergeType"/>
          <Checkbox v-model="imc" class="ml-16px">智能匹配往来单位</Checkbox>
        </div>-->
    <div class="flex bg-green-200 px-16px">
      <div class="w-300px p-8px">日记账</div>
      <div class="flex-1 p-8px">凭证预览</div>
    </div>
    <div class="flex-1 h-0px p-16px overflow-y">
      <template v-for="item in records">
        <div class="flex">
          <div class="w-300px">
            <div class="border mb-8px" :key="item.id">
              <div class="bg-gray2-color p-8px">{{ item.serialNumber }}</div>
              <div class="p-8px text-12px">
                <div>日期：{{ item.billDate }}</div>
                <div class="my-8px">收支类型：{{ item.ioTypeValue }}</div>
                <div class="my-8px">摘要：{{ item.digest }}</div>
                <div>{{ item.ioType === '收' ? '收入' : '支出' }}：{{ numFormat(item.debit || item.credit) }}</div>
              </div>
            </div>
          </div>
          <div class="flex-1 ml-8px">
            <template v-if="voucherList[item.id]">
              <div class="p-8px bg-gray3-color"><span class="mr-26px">日期：{{ voucherList[item.id].voucherDate }}</span> <span>凭证字号：{{ voucherList[item.id].word }}-{{ voucherList[item.id].code }}</span></div>
              <vxe-table :data="voucherList[item.id].details" :show-header="false" show-footer :footer-method="footerMt">
                <vxe-column field="summary"/>
                <vxe-column field="subjectName"/>
                <vxe-column field="debitAmount" align="right" formatter="toMoney"/>
                <vxe-column field="creditAmount" align="right" formatter="toMoney"/>
              </vxe-table>
            </template>
            <template v-else>
              请先设置凭证模板
            </template>
          </div>
        </div>
      </template>
    </div>
    <div class="modal-column-between">
      <Button :loading="loading" @click="$emit('close')" icon="fa fa-close">取消</Button>
      <Button :loading="loading" @click="doSubmit" color="primary" icon="fa fa-save">保存</Button>
    </div>
  </div>
</template>

<script>
/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
export default {
	name: "CashierJournalVoucherPreview"
}
</script>

<script setup>
import {ref, toRefs, getCurrentInstance} from "vue";
import {useStore} from "vuex";
import CashierIoType from "@/api/CashierIoType";
import {groupBy} from "xe-utils";
import manba from "manba";
import voucher from "@/api/voucher";
import cashierJournal from "@/api/CashierJournal";

const store = useStore();
const {appContext} = getCurrentInstance();
const props = defineProps(['records', 'account']);
const emits = defineEmits(['success', 'close']);
const {records, account} = toRefs(props);
const loading = ref(false);
const merge = ref(false);
const imc = ref(false);
const voucherList = ref({});
const mergeType = ref(['mergeSel', 'mergeOther']);
const gp = appContext.config.globalProperties;
CashierIoType.templateList(account.value.type).then(async ({data}) => {
	const templateMap = groupBy(data, val => val.type + "-" + val.ioTypeId);
	const voucherDate = manba(store.state.currentAccountSets.currentAccountDate).endOf(manba.MONTH).format();
	const codeMap = {};
	for (const row of records.value) {
		const template = templateMap[row.ioType + "-" + row.ioTypeId];
		if (template && template.length) {
			let lastCode = codeMap[template[0].word];
			if (!lastCode) {
				const results = await voucher.loadCode({word: template[0].word, currentAccountDate: voucherDate})
				codeMap[template[0].word] = results.data;
				lastCode = results.data;
			}
			const rows = [];
			let debitAmount = 0, creditAmount = 0;
			const voucherData = {word: template[0].word, code: lastCode, voucherDate};
			template.forEach(it => {
				const item = {
          summary: row.digest,
          subjectName: it.subjectTitle,
          subjectId: it.subjectId,
          currencyId: store.getters.localCurrency.id,
        };
				let amount = row.debit || row.credit;
				if (it.direction === '借') {
					item.debitAmount = amount;
					debitAmount += amount;
				} else {
					item.creditAmount = amount;
					creditAmount += amount;
				}
				rows.push(item)
			})
			voucherData.journalId = row.id;
			voucherData.debitAmount = debitAmount;
			voucherData.creditAmount = creditAmount;
			voucherData.details = rows;
			voucherList.value[row.id] = voucherData;
			codeMap[template[0].word] = lastCode + 1;
		}
	}
})

const footerMt = ({data}) => {
	const debitAmount = data.reduce((t, r) => t + (r.debitAmount || 0), 0)
	const creditAmount = data.reduce((t, r) => t + (r.creditAmount || 0), 0)

	return [["合计", gp.dxMoney(debitAmount), gp.numFormat(debitAmount), gp.numFormat(creditAmount)]]
}

const doSubmit = () => {
	if (Object.values(voucherList.value).length) {
		loading.value = true;
		cashierJournal.generateVoucher(Object.values(voucherList.value)).then(() => {
			emits('success');
		}).finally(() => loading.value = false)
	}
}
</script>
