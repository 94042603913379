<template>
  <div ref="modal" class="modal-column" @click.stop="void(0)">
    <div class="flex-1 p-16px">
      <Form ref="form" mode="block" :labelWidth="150" :model="form" :rules="{}">
        <FormItem :label="category.name+'编码'" prop="code" required>
          <Input type="text" v-model="form.code"/>
        </FormItem>
        <FormItem :label="category.name+'名称'" prop="name" required>
          <Input type="text" v-model="form.name"/>
        </FormItem>
        <FormItem label="备注" prop="remark" single>
          <NumberInput v-model="form.remark"></NumberInput>
        </FormItem>
      </Form>
    </div>
    <div class="modal-column-btn">
      <Button @click="$emit('close')">取消</Button>
      <Button color="primary" @click="submit" :loading="loading">保存</Button>
    </div>
  </div>
</template>

<script>/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
import Setting from "@/api/setting";

export default {
  name: "VTableAuxiliaryFastForm",
  props: {
    category: Object,
  },
  data() {
    return {
      loading: false,
      form: {
        code: null,
        name: null,
        remark: null,
        enable: true
      }
    }
  },
  methods: {
    submit() {
      let validResult = this.$refs.form.valid();
      if (validResult.result) {
        this.loading = true;
        Setting.accountingCategoryDetails.saveAd(Object.assign({
          categoryId: this.category.id,
          categoryType: this.category.categoryType,
        }, this.form)).then(() => {
          this.$emit('success');
        }).finally(() => {
          this.loading = false;
        })
      }
    }
  }
}
</script>
