<template>
  <div class="frame-page h-full !m-0px p-20px flex flex-column">
    <vxe-toolbar class-name="px-8px">
      <template #buttons>
        <DropdownCustom ref="seriesParamsDropdown" button trigger="manual" class="" @click.stop="seriesParamsDropdown.show()">
          <span>{{ showDate }}</span>
          <template #content>
            <div class="p-16px border w-400px">
              <Form class="compact">
                <FormItem label="会计期间">
                  <div class="h-input-group">
                    <Select :datas="monthList" v-model="params.start" :deletable="false"/>
                    <span class="h-input-addon">至</span>
                    <Select :datas="monthList" v-model="params.end" :deletable="false"/>
                  </div>
                </FormItem>
                <FormItem label="凭证字">
                  <Select :datas="voucherWordList" keyName="word" titleName="word" v-model="params.word"/>
                </FormItem>
                <FormItem label="制单人">
                  <Select :datas="userList" v-model="params.createMember" keyName="id" titleName="realName"/>
                </FormItem>
                <FormItem label="摘要">
                  <Input v-model="params.summary"/>
                </FormItem>
                <FormItem label="科目">
                  <Select :datas="subjectList" keyName="code" titleName="name" v-model="params.subjectCode" :filter="subjectFilter">
                    <template v-slot:item="{ item }">
                      {{ item.code }} {{ item.name }}
                    </template>
                  </Select>
                </FormItem>
                <FormItem label="金额">
                  <div class="h-input-group">
                    <NumberInput v-model.number="params.amountStart"/>
                    <span class="h-input-addon">至</span>
                    <NumberInput v-model.number="params.amountEnd"/>
                  </div>
                </FormItem>
                <FormItem label="凭证号">
                  <div class="h-input-group">
                    <NumberInput v-model.number="params.codeStart"/>
                    <span class="h-input-addon">至</span>
                    <NumberInput v-model.number="params.codeEnd"/>
                  </div>
                </FormItem>
                <FormItem label="排序方式">
                  <Radio v-model="params.sort" :datas="{code:'凭证序号',voucher_date:'凭证日期'}"></Radio>
                </FormItem>
                <FormItem>
                  <vxe-button :loading="loading" @click="page.page=1; loadList()" status="primary" icon="fa fa-search">查询</vxe-button>
                  <vxe-button :loading="loading" @click="seriesParamsDropdown.hide()">取消</vxe-button>
                  <vxe-button :loading="loading" @click="reset()">重置</vxe-button>
                </FormItem>
              </Form>
            </div>
          </template>
        </DropdownCustom>
      </template>
      <template #tools>
        <vxe-button @click="exportData" icon="fa fa-download" content="导出"/>
        <vxe-button @click="printData" icon="fa fa-print" content="打印"/>
      </template>
    </vxe-toolbar>
    <div class="flex-1">
      <vxe-table ref="xTable" :loading="loading" :data="dataResult.results" height="auto" >
        <vxe-column title="日期" width="110" field="voucherDate"></vxe-column>
        <vxe-column title="凭证字号" field="word" width="80" align="center">
          <template #default="{row}">
            {{ row.word }}
          </template>
        </vxe-column>
        <vxe-column title="摘要" width="180" field="summary"/>
        <vxe-column title="科目编码" width="110" field="subjectCode"/>
        <vxe-column title="科目名称" width="150" field="subjectName"/>
        <vxe-column title="借方金额" width="110" align="right" field="debitAmount" formatter="toMoney"/>
        <vxe-column title="贷方金额" width="110" align="right" field="creditAmount" formatter="toMoney"/>
        <vxe-column title="制单人" width="110" field="userName"/>
        <vxe-column title="审核人" width="110" field="auditName"/>
        <vxe-column title="附件数" width="80" align="right" field="receiptNum"/>
        <vxe-column title="备注" width="110" field="remark"/>
      </vxe-table>
    </div>
    <vxe-pager @page-change="onPageChange"
               v-model:current-page="page.page"
               v-model:page-size="page.pageSize"
               :total="dataResult.total"
               :layouts="['PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'Sizes', 'Total', 'PageCount']">
      <template #left>
        <vxe-button @click="loadList()" type="text" size="mini" icon="fa fa-refresh" :loading="loading"></vxe-button>
      </template>
    </vxe-pager>
  </div>
</template>

<script>
/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
export default {
  name: "TimeSeries"
}
</script>

<script setup>

import {computed, ref} from "vue";
import Accountbook from "@/api/accountbook";
import setting from "@/api/setting";
import Checkout from "@/api/checkout";
import {useStore} from "vuex";
import manba from "manba";
import BooUtil from "@/views/accountbook/bookUtil";
import accountbook from "@/api/accountbook";
import {downloadBlob} from "download.js";

const store = useStore();

const currentAccountDate = manba(store.state.currentAccountSets.currentAccountDate);
const startDate = ref(currentAccountDate.startOf(manba.MONTH).format("YYYY-MM"));
const endDate = ref(currentAccountDate.endOf(manba.MONTH).format("YYYY-MM"));

const loading = ref(false);
const seriesParamsDropdown = ref(null);
const xTable = ref(null);

const page = ref({
  page: 1,
  pageSize: 20
})

const params = ref({
  sort: 'voucher_date',
  start: startDate,
  end: endDate,
})

const showDate = computed(() => {
  if (params.value.start === params.value.end) {
    return manba(params.value.start).format("YYYY年MM月");
  }
  return manba(params.value.start).format("YYYY年MM月") + "-" + manba(params.value.end).format("YYYY年MM月");
})


const reset = () => {
  params.value = {
    sort: 'voucher_date',
    start: startDate,
    end: endDate,
  };
  loadList();
}

const dataResult = ref({
  results: [],
  total: 20
})

const userList = ref([])
setting.user.list().then(({data}) => {
  userList.value = data;
})


const onPageChange = ({currentPage, pageSize}) => {
  page.value.page = currentPage;
  page.value.pageSize = pageSize;
  loadList();
}

const loadList = () => {
  loading.value = true;
  Accountbook.timeSeries(Object.assign({}, page.value, params.value)).then(({data}) => {
    dataResult.value = data;
    seriesParamsDropdown.value.hide();
  }).finally(() => loading.value = false)
}
loadList();

const subjectList = ref([])
setting.subject.voucherSelect().then(({data}) => {
  subjectList.value = data;
})

const subjectFilter = (item, val) => {
  return item.code.indexOf(val) !== -1 || item.name.indexOf(val) !== -1 || item.mnemonicCode.indexOf(val) !== -1;
}

const monthList = ref([]);
Checkout.list().then(({data}) => {
  monthList.value = data.map(val => {
    return {key: val.checkYear + "-" + val.checkMonth, title: val.checkYear + "年" + val.checkMonth + "月"}
  })
})

const voucherWordList = ref([]);
setting.voucherWord.list().then(({data}) => {
  voucherWordList.value = data;
})


const exportData = () => {
  const utils = new BooUtil(xTable.value, '\n')
  accountbook.download({header: utils.header, dataList: utils.dataList}).then((data) => {
    downloadBlob(`${showDate.value}-时序账.xls`, data)
  })
};

const printData = () => {
  const utils = new BooUtil(xTable.value)
  const title = `${showDate.value}-时序账`
  accountbook.print({content: utils.printContent(title, store.state.currentAccountSets.companyName), rotate: true}).then((data) => {
    const url = URL.createObjectURL(data);
    window.open(url, '时序账')
  })
}
</script>
