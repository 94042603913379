<template>
  <div class="modal-column">
    <div class="flex-1 p-10px">
      <Form readonly>
        <div class="font-bold my-16px mx-16px">基本信息</div>
        <div class="inline-form-item">
          <FormItem label="编码" prop="code">
            {{ entity.code }}
          </FormItem>
          <FormItem label="资产名称" prop="name">
            {{ entity.name }}
          </FormItem>
          <FormItem label="规格型号" prop="specification">
            {{ entity.specification }}
          </FormItem>
        </div>
        <div class="inline-form-item">
          <FormItem label="数量" prop="numb">
            {{ entity.numb }}
          </FormItem>
          <FormItem label="使用部门" prop="departmentId">
            {{ entity.deptName }}
          </FormItem>
          <FormItem label="存放位置" prop="location">
            {{ entity.location }}
          </FormItem>
        </div>
      </Form>
      <div class="font-bold my-16px mx-16px">变更内容</div>
      <Form class="mx-20px" ref="form" mode="block" :model="form" :rules="{}">
        <FormItem label="变动类型：" single prop="changeType" required>
          <Select :datas="['原值调整', '累计折旧调整', '使用年限调整', '部门转移', '折旧方法调整', '状态修改']" :deletable="false" v-model="form.changeType">
          </Select>
        </FormItem>
        <template v-if="form.changeType==='原值调整'">
          <FormItem label="原值调整：">
            {{ entity.originalValue }}
          </FormItem>
          <FormItem label="变动后：" prop="afterChange" required>
            <Input type="text" v-model="form.afterChange"/>
          </FormItem>
        </template>
        <template v-if="form.changeType==='累计折旧调整'">
          <FormItem label="累计折旧：">
            {{ entity.totalDepreciation }}
          </FormItem>
          <FormItem label="累计折旧调整值：" prop="afterChange" required>
            <Input type="text" v-model="form.afterChange"/>
          </FormItem>
        </template>
        <template v-if="form.changeType==='使用年限调整'">
          <FormItem label="预计使用月份：">
            <Input type="text" v-model="entity.expectUseMonth" disabled/>
          </FormItem>
          <FormItem label="预计使用月份调整为：" prop="afterChange" required>
            <Input type="text" v-model="form.afterChange"/>
          </FormItem>
        </template>
        <template v-if="form.changeType==='折旧方法调整'">
          <FormItem label="折旧方法：">
            <Select disabled v-model="entity.depreciationMethod" :datas="depreciationMethods" keyName="code" titleName="name"></Select>
          </FormItem>
          <FormItem label="折旧方法调整为：" prop="afterChange" required>
            <Select :deletable="false" v-model="form.afterChange" :datas="depreciationMethods" keyName="code" titleName="name"></Select>
          </FormItem>
        </template>
        <template v-if="form.changeType==='状态修改'">
          <FormItem label="原状态：">
            <Select :datas="statusMap" v-model="entity.status" disabled/>
          </FormItem>
          <FormItem label="状态调整为：" prop="afterChange" required>
            <Select :datas="statusMap" v-model="form.afterChange"/>
          </FormItem>
        </template>
        <template v-if="form.changeType==='部门转移'">
          <FormItem label="部门：">
            {{ entity.deptName }}
          </FormItem>
          <FormItem label="部门调整为：" prop="afterChange" required>
            <Select :datas="deptList" filterable v-model.number="form.afterChange"/>
          </FormItem>
        </template>
      </Form>
    </div>
    <div class="modal-column-between">
      <Button @click="$emit('close')">取消</Button>
      <Button color="primary" @click="submit" :loading="loading">确认调整</Button>
    </div>
  </div>
</template>

<script>

/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
import Assets from "@/api/Assets";

export default {
  name: "AssetsChangeForm",
  props: {
    entity: Object,
    subjectList: Object,
    deptList: Array,
  },
  data() {
    return {
      loading: false,
      depreciationMethods: [
        {name: '平均年限法', code: 0},
        {name: '不折旧', code: 1}
      ],
      statusMap: [
        {title: '正常', key: 1},
        {title: '停用', key: 2},
        {title: '出租', key: 3},
      ],
      form: {
        changeType: '原值调整',
        assetsId: null,
        period: null,
        beforeChange: null,
        afterChange: null,
      },
    }
  },
  watch: {
    'form.changeType'(val) {
      switch (val) {
        case "原值变动":
          this.form.beforeChange = this.entity.originalValue;
          this.form.afterChange = null;
          break;
        case "累计折旧调整":
          this.form.beforeChange = this.entity.totalDepreciation;
          this.form.afterChange = null;
          break;
        case "使用年限调整":
          this.form.beforeChange = this.entity.expectUseMonth;
          this.form.afterChange = null;
          break;
        case "部门转移":
          this.form.beforeChange = this.entity.deptId;
          this.form.afterChange = null;
          break;
        case "折旧方法调整":
          this.form.beforeChange = this.entity.depreciationMethod;
          this.form.afterChange = null;
          break;
        case "状态修改":
          this.form.beforeChange = this.entity.status;
          this.form.afterChange = null;
          break;
      }
    }
  },
  methods: {
    submit() {
      let validResult = this.$refs.form.valid();
      if (validResult.result) {
        this.loading = true;
        Assets.Change.save(this.form).then(() => {
          this.$emit('success');
        }).finally(() => {
          this.loading = false;
        })
      }
    }
  },
  created() {
    this.form.beforeChange = this.entity.originalValue;
    this.form.assetsId = this.entity.id;
  }
}
</script>
