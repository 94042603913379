/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
import {computed, reactive, ref, watch} from "vue";
import {pick} from "xe-utils";

export const usePageInject = (loadHandler) => {

	if (!loadHandler) {
		throw "列表刷新函数不能为空~";
	}

	const loading = ref(false)

	const pageInfo = reactive({
		page: 1,
		pageSize: 20,
		total: 0
	})


	const pageParams = computed(() => {
		return pick(pageInfo, ['page', 'pageSize'])
	})

	const doSearch = () => {
		pageInfo.page = 1;
		loading.value = true;
		loadHandler().finally(() => loading.value = false);
	}

	watch(() => [pageInfo.page, pageInfo.pageSize], () => {
		loading.value = true;
		loadHandler().finally(() => loading.value = false);
	})

	return {pageInfo, doSearch, pageParams, loading};
}
