<template>
    <div class="modal-column">
        <div class="modal-column-full-body">
            <Form mode="block" ref="form" :rules="{}" :model="formData">
                <div class="cate-info">
                    <div class="zmsunform-comm-field">
                        <label class="zmsunform-comm-label">模板编码</label>
                        <div class="zmsunform-comm-fieldcon">
                            <input v-model="formData.code" type="text" autocomplete="off" placeholder="请输入模板编码">
                        </div>
                    </div>
                    <div class="zmsunform-comm-field">
                        <label class="zmsunform-comm-label">模板名称</label>
                        <div class="zmsunform-comm-fieldcon">
                            <input v-model="formData.name" type="text" autocomplete="off" placeholder="请输入模板名称">
                        </div>
                    </div>
                    <div class="zmsunform-comm-field">
                        <label class="zmsunform-comm-label">结转类型</label>
                        <div class="zmsunform-comm-fieldcon">
                            <Select class="zmsunform-selectmenu-main" v-model="formData.type" :datas="typeDataList"
                                    :deletable="false"/>
                        </div>
                    </div>
                    <span class="type-tips">类型: 期末结转(自定义)</span></div>
                <FormItem>
                    <vxe-table v-if="formData.type === 1" height="360" ref="itemsTable" :data="items" 
                               :edit-config="{trigger:'click',mode:'row', showIcon: false}" :edit-rules="validConfig"
                               :header-row-class-name="headerRowClassName"
                               align="center">
                        <vxe-column width="80" align="center">
                            <template #default="{row,rowIndex}">
                                <span @click="pushItem(row)" class="fa fa-plus mr-16px text-hover"/>
                                <span @click="popItem(row)" class="fa fa-minus red-color text-hover"/>
                            </template>
                        </vxe-column>
                        <vxe-column title="摘要" field="summary" width="220" :edit-render="{}">
                            <template #edit="{ row }">
                                <vxe-input v-model="row.summary" type="text"></vxe-input>
                            </template>
                        </vxe-column>
                        <vxe-column title="会计科目" field="subjectId"
                                    :edit-render="{name:'$select',options:subjectList,props:{filterable:true}}">
                            <template #default="{row,rowIndex}">
                                {{ subjectMap[row.subjectId] }}
                            </template>
                        </vxe-column>
                        <vxe-column field="direction" title="方向" width="100"
                                    :edit-render="{name:'$select',options:[{label:'借',value:1},{label:'贷',value:2}]}"/>
                        <vxe-column field="formula" title="金额" width="140">
                            <template #default="{ row }">
                                <vxe-button type="text" @click="editFormula(row)">编辑公式</vxe-button>
                            </template>
                        </vxe-column>
                    </vxe-table>
                    <vxe-table v-if="formData.type === 2" height="200" ref="itemsTable" :data="items" 
                               :edit-config="{trigger:'click',mode:'row', showIcon: false}" :edit-rules="validConfig"
                               :header-row-class-name="headerRowClassName"
                               align="center">
                        <vxe-column width="80" align="center">
                            <template #default="{row,rowIndex}">
                                <span @click="pushItem(row)" class="fa fa-plus mr-16px text-hover"/>
                                <span @click="popItem(row)" class="fa fa-minus red-color text-hover"/>
                            </template>
                        </vxe-column>
                        <vxe-column title="摘要" field="summary" width="220" :edit-render="{}">
                            <template #edit="{ row }">
                                <vxe-input v-model="row.summary" type="text"></vxe-input>
                            </template>
                        </vxe-column>
                        <vxe-column title="会计科目" field="subjectId" width="220"
                                    :edit-render="{name:'$select',options:subjectList,props:{filterable:true}}">
                            <template #default="{row,rowIndex}">
                                {{ subjectMap[row.subjectId] }}
                            </template>
                        </vxe-column>
                        <vxe-column title="辅助核算" field="auxiliary" width="180" :edit-render="{}"></vxe-column>
                        <vxe-column title="转账方式" field="transfer" width="80"
                                    :edit-render="{name:'$select',options:[{label:'转出',value:1},{label:'转入',value:2}]}"/>
                        <vxe-column title="取数方向" field="retrieval" width="80"
                                    :edit-render="{name:'$select',options:[{label:'余额',value:1},{label:'借方',value:2},{label:'贷方',value:3}]}"/>
                        <vxe-column title="百分比(%)" field="percentage" width="90" :edit-render="{}">
                            <template #edit="{ row }">
                                <vxe-input v-model="row.percentage" type="text"></vxe-input>
                            </template>
                        </vxe-column>
                    </vxe-table>
                </FormItem>
            </Form>
        </div>
<!--        <div class="modal-column-between">-->
<!--            <vxe-button :loading="loading" @click="$emit('close')" icon="fa fa-close">取消</vxe-button>-->
<!--            <vxe-button :loading="loading" :disabled="!formData.title" @click="doSubmit()" status="primary" icon="fa fa-check">保存</vxe-button>-->
<!--        </div>-->
        <div class="modal-column-btn">
            <vxe-button :loading="loading" content="取消" @click="$emit('close')"/>
            <vxe-button :loading="loading" content="确认" status="primary" @click="doSubmit"/>
        </div>
    </div>
</template>

<script>
    /**
     * <p>****************************************************************************</p>
     * <p><b>Copyright © 2010-2022杭州财汇餐谋科技有限公司All Rights Reserved<b></p>
     * <ul style="margin:15px;">
     * <li>Description : </li>
     * <li>Version     : 1.0</li>
     * <li>Creation    : 2022年12月22日</li>
     * <li>@author     : ____′↘夏悸</li>
     * </ul>
     * <p>****************************************************************************</p>
     */
    export default {
        name: "CheckTemplateFormOne"
    }
</script>

<script setup>
    import {h, reactive, ref, toRefs} from "vue";
    import setting from "@/api/setting";
    import voucher from "@/api/voucher";
    import CheckTemp from "@/api/CheckTemp";
    import {message} from "heyui.ext";
    import {CopyObj} from "@/js/common/utils";
    import {layer} from "@layui/layer-vue";
    import CheckTemplateFormTwo from "./CheckTemplateFormTwo";

    const emits = defineEmits(['close', 'success']);
    const props = defineProps(['entity']);
    const {entity} = toRefs(props);
    const loading = ref(false)
    const itemsTable = ref(null)
    const formData = reactive({
        id: null,
        code: null,
        name: null,
        type: 1,
        items: []
    })
    const typeDataList = ref([{key: 1, title: '公式结转'}, {key: 2, title: '对应结转'}])

    const validConfig = reactive({
        subjectId: [{required: true, message: "请选择科目"}],
        summary: [{required: true, message: "摘要不能为空"}]
    });

    const loadList = () => {
        if (entity.value) {
            // CheckTemp.getCheck(entity.value).then(({data}) => {
            //     CopyObj(formData, data);
            // }).finally()
            CopyObj(formData, {
                "id": 484662818994389423,
                "code": "000001",
                "name": "缴纳增值税",
                "type": 1,
                "items": [
                    {
                        "summary": "办公费_增值税专用发票1",
                        "subjectId": "433708449910640641",
                        "subjectTitle": "1001-库存现金",
                        "direction": 1,
                        "auxiliary": null,
                        "transfer": null,
                        "retrieval": null,
                        "percentage": null,
                        "formula": [
                            {
                                "calculation": "+",
                                "subjectId": "433708449910640641",
                                "subjectTitle": "1001-库存现金",
                                "auxiliary": null,
                                "accessRules": "余额",
                                "operation": "*",
                                "percentage": "100",
                            },
                            {
                                "calculation": "+",
                                "subjectId": "433708450380402697",
                                "subjectTitle": "1122-应收账款",
                                "auxiliary": null,
                                "accessRules": "找平",
                                "operation": "/",
                                "percentage": "20",
                            }
                        ]
                    },
                    {
                        "summary": "办公费_增值税专用发票2",
                        "subjectId": "433708450254573570",
                        "subjectTitle": "1002-银行存款",
                        "direction": 2,
                        "auxiliary": null,
                        "transfer": null,
                        "retrieval": null,
                        "percentage": null,
                        "formula": []
                    }
                ]
            });
        }
    }
    loadList();

    const items = reactive(formData && formData.items && formData.items.length > 0 ? formData.items : [
        {summary: null, subjectId: null, subjectTitle: null, direction: 1, auxiliary: null, transfer: null, retrieval: null, percentage: null, formula: []},
        {summary: null, subjectId: null, subjectTitle: null, direction: 2, auxiliary: null, transfer: null, retrieval: null, percentage: null, formula: []},
        {summary: null, subjectId: null, subjectTitle: null, direction: 1, auxiliary: null, transfer: null, retrieval: null, percentage: null, formula: []},
    ])

    const doSubmit = () => {
        if (!formData.code) {
            message.warn("亲，模板编码不能为空！");
            return false;
        }
        if (!formData.name) {
            message.warn("亲，模板名称不能为空！");
            return false;
        }
        itemsTable.value.fullValidate(true, () => {
            let flag = true;
            let {fullData} = itemsTable.value.getTableData();
            fullData.forEach(row => {
                if (!row.subjectId || !row.summary) {
                    flag = false;
                    return false;
                }
                row.subjectTitle = subjectMap[row.subjectId];
            })
            if(flag){
                loading.value = true;
                voucher.saveTpl(Object.assign({}, formData, {items: fullData})).then(() => {
                    emits('success')
                }).finally(() => loading.value = false)
            }
        })
    }

    const pushItem = (row) => {
        itemsTable.value.insertAt({summary: null, subjectId: null, subjectTitle: null, direction: 1, auxiliary: null, transfer: null, retrieval: null, percentage: null}, row);
    }

    const popItem = (row) => {
        let {fullData} = itemsTable.value.getTableData();
        if (fullData.length > 2) {
            itemsTable.value.remove(row);
        }
    }

    const subjectList = ref([]);
    const subjectMap = reactive({});
    setting.subject.voucherSelect({all: false}).then(({data}) => {
        subjectList.value = data.map(val => {
            subjectMap[val.id] = val.code + "-" + val.fullName;
            return {
                value: val.id,
                label: val.code + "-" + val.fullName,
            }
        })
    })

    const editFormula = (row) => {
        let layerId = layer.open({
            title: '编辑公式',
            shadeClose: false,
            closeBtn: 1,
            zIndex: 1000,
            area: ['1000px', '65vh'],
            content: h(CheckTemplateFormTwo, {
                entity: row.formula,
                onClose: () => {
                    layer.close(layerId);
                },
                onSuccess: (data) => {
                    row.formula = data;
                    layer.close(layerId);
                },
                onBack: () => {
                    layer.close(layerId);
                }
            })
        });
    }

    const headerRowClassName = ({ column }) => {
        return 'row-class'
    }

</script>

<style lang="less" scoped>
    ::v-deep(.vxe-header--row.row-class) {
        // background-color: #2FA8FD;
        // color: #fff;
        height: 60px;
    }
    ::v-deep(.vxe-body--row) {
        height: 60px;
    }
    
    .zmsunform-comm-field {
        display: inline-block;
        margin: 6px 10px;
        position: relative;
        line-height: 30px;
    }

    .zdy-code {
        width: 268px;
    }

    .zmsunform-comm-field input {
        outline: 0
    }

    .zmsunform-comm-field input[type="text"], .zmsunform-comm-field input[type="password"] {
        padding: 2px 5px;
        line-height: 24px;
        height: 24px;
        color: #464646;
        float: left;
        border: none;
        border-bottom: 1px solid #D7D7D7;
        width: 190px
    }

    .zmsunform-fie-text-has-btn-clear input[type="text"], .zmsunform-fie-text-has-btn-tiptog input[type="text"] {
        padding-right: 22px;
        width: 173px
    }

    .zmsunform-comm-field input[type="text"][disabled] {
        background-color: #f5f5f5;
        color: #4f5254;
        border: none;
        border-bottom: 1px solid #D7D7D7
    }

    .zmsunform-comm-field input[type="text"]:focus {
        border-color: #4265D6
    }

    .zmsunform-comm-field input[type="text"]::-moz-placeholder {
        color: #b5b5b5
    }

    .zmsunform-comm-field input[type="text"]::-webkit-input-placeholder {
        color: #b5b5b5
    }

    .zmsunform-comm-label {
        line-height: 32px;
        float: left;
        padding-right: 4px;
    }

    .zmsunform-comm-fieldcon {
        position: relative;
        float: left;
    }

    .zdy-type {
        width: 188px;
    }

    .zmsunform-selectmenu-main {
        width: 125px;
        position: relative;
    }

    .type-tips {
        vertical-align: 16px;
        margin-left: 20px;
    }
</style>
