<template>
  <app-content style="position: relative;">
    <Loading text="操作中~" :loading="loading"></Loading>
    <div class="mask"
         v-if="User.role ==='View' || (form.id && (form.auditMemberId && currentAccountSets.voucherReviewed))"></div>
    <div class="h-panel" style="width: 1000px;margin: 0 auto;padding-top: 20px;">
      <img v-if="form.id &&  (form.auditMemberId && currentAccountSets.voucherReviewed)" class="auditpic"
           src="@/assets/audit.png" alt="已审核">
      <div class="padding-right-left">
        <Row type="flex" :space-x="10">
          <template v-if="assetsData">
            <Cell>
              <Button :disabled="!canSave" color="primary" @click="save(false,'assets')" :loading="loading">保存
              </Button>
              <Button @click="$emit('close')">返回</Button>
            </Cell>
          </template>
          <template v-else>
            <Cell v-if="User.role !='View'">
              <template v-if="isCheck">
                <Button v-if="isCheck" @click="$emit('close')">返回</Button>
                <Button :disabled="!canSave" color="primary" @click="save(true)" :loading="loading">
                  保存
                </Button>
              </template>
              <template v-else>
                <Button :disabled="!canSave" color="primary" @click="save(true)" :loading="loading" v-if="!form.id">
                  保存并新增
                </Button>
                
                
                <Button :disabled="!canSave" color="primary" >
                  打印
                </Button>
                <Button v-if="form.id" :disabled="!!(form.auditMemberId && currentAccountSets.voucherReviewed)"
                        color="primary" @click="toNew()">
                  新增
                </Button>
                <Button :disabled="!canSave || !!(form.auditMemberId && currentAccountSets.voucherReviewed)"
                        color="primary" @click="save(false)" :loading="loading">
                  保存
                </Button>
                <template v-if="form.id && currentAccountSets.voucherReviewed">
                  <Button v-if="(form.auditMemberId && currentAccountSets.voucherReviewed)" color="primary"
                          @click="cancelAudit" :loading="loading">
                    取消审核
                  </Button>
                  <Button v-else color="primary" @click="audit" :loading="loading">审核</Button>
                </template>
                <DropdownMenu
                        button
                        class="h-btn-green"
                        :datas="{loadTpl:'使用模板',saveTpl:'存为模板'}"
                        @clickItem="trigger">
                  更多
                </DropdownMenu>
              </template>
              <Button  color="primary" @click="resetList()" >
                  重置
                </Button>
            </Cell>
            <Cell v-if="!isCheck && form.id">
              <a class="h-btn" :href="`/api/pdf/voucher?id=${form.id}`" target="_blank">打印</a>
            </Cell>
            <Cell :flex="1" class="text-right" v-if="!isCheck">
              <ButtonGroup>
                <Button :loading="loading" @click="before" size="s" content="上一条" icon="h-icon-left"></Button>
                <Button :loading="loading" @click="next" size="s" content="下一条" icon="h-icon-right"></Button>
              </ButtonGroup>
            </Cell>
          </template>
        </Row>
        <Row class="margin-top" type="flex" :space-x="10">
          <Cell>
            <Select keyName="word" titleName="word" style="min-width: 70px" :deletable="false" :datas="voucherWords"
                    v-model="form.word" placeholder="记"/>
          </Cell>
          <Cell class="label">
            <NumberInput :min="1" v-model="form.code" style="display: inline-block"/>
            号
          </Cell>
          <Cell class="label">
            日期：
          </Cell>
          <Cell class="label">
            <DatePicker :disabled="!!carryForward" :option="dpOps" :clearable="false" v-model="form.voucherDate"
                        format="YYYY-MM-DD"/>
          </Cell>
          <Cell class="label flex items-center">
            <DropdownCustom :toggle-icon="false" class-name="h-text-dropdown" placement="bottom-end">
              <span class="text-hover blue-color font-bold">
                <i class="fa fa-file-text"></i>
                备注
              </span>
              <template #content>
                <Textarea placeholder="请输入备注内容" v-model="form.remark" v-autosize rows="5"
                          style="width: 300px"></Textarea>
              </template>
            </DropdownCustom>
            <div class="ml-16px h-input-group w-150px">
              <span class="h-input-addon">附单据</span>
              <Input v-model.number="form.receiptNum"/>
              <span class="h-input-addon">张</span>
            </div>
            <DropdownCustom :toggle-icon="false" class-name="h-text-dropdown" placement="bottom-end">
              <span class="text-hover blue-color font-bold ml-16px">
                <i class="fa fa-files-o"></i>
                附件
              </span>
              <template #content>
                <div class="w-400px">
                  <div class="p-8px flex justify-between items-center bg-gray3-color">
                    <span>附件列表</span>
                    <Uploader :showFileList="false" :accept="accept" displayType="file" multiple v-model="form.billList"
                              :option="billUploaderOptions">
                      <template #button>
                       <span class="text-hover">
                         <i class="fa fa-upload"></i> 点击上传
                       </span>
                      </template>
                    </Uploader>
                  </div>
                  <div class="h-150px">
                    <vxe-table ref="billListTable" :data="form.billList" :show-header="false" height="auto">
                      <vxe-column field="name">
                        <template #default="{row}">
                          <a :href="'/api'+row.url+'?name='+row.name" target="_blank">{{
                              row.name
                            }}</a>
                        </template>
                      </vxe-column>
                      <vxe-column width="40" align="center">
                        <template #default="{row,rowIndex}">
                          <i class="fa fa-trash text-hover" @click.stop="rmBill(row,rowIndex)"></i>
                        </template>
                      </vxe-column>
                    </vxe-table>
                  </div>
                </div>
              </template>
            </DropdownCustom>
          </Cell>
        </Row>
      </div>
      <VoucherTable2 ref="voucherTable2" :is-edit="!!form.id"  @update="onItemsUpdate"  :voucherDetails="voucherDetails"/>
      <div class="padding-right-left padding-bottom">
        制单人：{{ User.realName }}
      </div>
    </div>
  </app-content>
</template>

<script>
import VoucherTable2 from "../../components/VoucherTable/index";
import {mapGetters, mapState} from 'vuex';
import {h} from 'vue';
import TemplateForm from "@/views/report/template/TemplateForm";
import {message} from "heyui.ext";
import manba from "manba";
import Common from "@/api/common";
import {layer} from "@layui/layer-vue";
import VoucherTplForm from "@/views/voucher/VoucherTplForm.vue";
import VoucherTplList from "@/views/voucher/VoucherTplList.vue";
import setting from "../../api/setting";
import voucher from "@api/voucher";
import checkout from "@api/checkout";
import {add, floor} from "xe-utils";
import { ref } from 'vue'

const accept = "image/*,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/msword,application/pdf,text/plain,application/vnd.openxmlformats-officedocument.wordprocessingml.document"

export default {
  name: "VoucherForm",
  components: {TemplateForm, VoucherTable2: VoucherTable2},
  props: {
    voucherId: String,
    voucherDate: String,
    checkData: Object,
    assetsData: Array,
    profitCheckData: Array,
    type: Object,
    copy: Boolean,
  },
  computed: {
    ...mapState(['User', 'currentAccountSets', 'tabs']),
    ...mapGetters(['firstCheckoutDate', 'localCurrency']),
    canSave() {
      return this.voucherItems.length > 0
    },
    isCheck() {
      return !!this.checkData
    },
    acceptList() {
      return this.accept.split(",");
    },
    cacheKey() {
      return `voucher_cache_${this.currentAccountSets.id}`;
    }
  },
  data() {
    return {
      accept,
      entityId: null,
      loading: false,
      isCopy: false,
      carryForward: false,
      voucherWords: [],
      cachedDetails: [],
      voucherItems: [],
      voucherDetails: [],
      voucherTable: {voucherItems: []},
      dpOps: {
        start: manba().format(),
        end: null
      },
      voucher: {},
      form: {
        id: null,
        remark: '',
        billList: [],
        word: '',
        code: '',
        voucherDate: '',
        receiptNum: 0,
        carryForward: false,
        checkTplId: null,
      },
      billUploaderOptions: {
        onBeforeUpload: (file) => {
          if (!(this.acceptList.includes(file.type) || file.type.startsWith("image/"))) {
            message.error(`${file.name} 文件是不支持的上传类型~`);
            return false;
          }
          if (file.size > 100 * 1024 * 1024 * 1024) {
            message.error(`${file.name} 文件大小超出100M限制`);
            return false;
          }
          return true;
        },
        onChange: async (file) => {
          try {
            const fd = new FormData();
            fd.append("file", file);
            const {data, success, msg} = await Common.upload("voucher", fd);
            if (success) {
              this.form.receiptNum = this.form.billList.length + 1;
              return data.path;
            } else {
              message.error(msg);
              throw new Error(msg);
            }
          } catch (error) {
            console.error(error)
            message.error(`${file.name}上传失败`);
            throw new Error();
          }
        }
      }
    }
  },
  watch: {
    'form.word'(val, old) {
      if (!old && this.entityId) {
        return;
      }
      val && this.loadCode();
    },
    voucherItems: {
      deep: true,
      handler(val) {
        if (!this.form.id) {
          localStorage.setItem(this.cacheKey, JSON.stringify(val));
        }
      }
    },
    'form.voucherDate'(val) {
      this.loadCode(val);
    }
  },
  methods: {
    resetList(){
      this.$refs.voucherTable2.reset();
    },
    onItemsUpdate(val) {
      this.voucherItems = val;
      this.voucherTable.jfTotal = floor(val.reduce((total, row) => add(total, (row.debitAmount || 0)), 0), 2)
      this.voucherTable.dfTotal = floor(val.reduce((total, row) => add(total, (row.creditAmount || 0)), 0), 2)
    },
    loadVoucherWords() {
      setting.voucherWord.list().then(({data}) => {
        this.voucherWords = data || [];
        if (!this.form.word) {
          this.form.word = data.find(value => value.isDefault).word
        }
      })
    },
    loadCode(voucherDate = null) {
      if (this.voucher && this.voucher.id && voucherDate && manba(this.voucher.voucherDate).format("YYYYMM") === manba(voucherDate).format("YYYYMM")) {
        this.form.code = this.voucher.code;
      } else if (this.form.word) {
        this.form.voucherDate && voucher.loadCode({
          word: this.form.word,
          currentAccountDate: voucherDate || this.form.voucherDate
        }).then(({data}) => {
          this.form.code = data
        })
      }
    },
    formValid() {
      if (!this.form.voucherDate) {
        this.$Message("亲，请选择日期！");
        return false;
      }

      if (!this.form.code) {
        this.$Message("亲，请输入编号！");
        return false;
      }

      if (!this.voucherItems.length) {
        this.$Message("亲，第1行不能为空！");
        return false;
      }

      if (this.checkItem("摘要", 'summary') || this.checkItem("科目", 'subjectId') || this.checkItem("金额")) {
        return false;
      }

      if (this.voucherTable.jfTotal !== this.voucherTable.dfTotal) {
        this.$Message("亲，借贷不平衡！");
        return false;
      }
      return true;
    },
    save(next, type = "") {
      if (!this.formValid()) {
        return
      }
      let formData = Object.assign({}, this.form, {
        details: this.voucherItems,
        carryForward: this.carryForward,
        createMember: this.User.id
      });
      if (this.type) {
        formData.checkTplId = this.type.id;
      }
      this.loading = true;
      voucher[this.entityId ? 'update' : 'save'](formData).then(({success, data}) => {
        if (success) {
          localStorage.removeItem(this.cacheKey);
          message("保存成功~")
          if (type === 'assets') {
            this.$emit('success', data.id)
            return
          }
          if (success && !this.form.id) {
            this.isCopy = false;
            if (next) {
              if (!this.isCheck) {
                this.toNew();
              } else {
                this.$emit('success')
              }
            } else {
              this.entityId = data.id;
              this.form.id = data.id;
            }
          } else if (success) {
            this.loading = false;
            this.$emit('success')
          }
        }
      }).catch(() => {
        this.loading = false;
      }).finally(() => this.loading = false);
    },
    checkItem(name, field) {
      let i = 0, len = this.voucherItems.length, row = -1;
      for (; i < len; i++) {
        if ((field && !this.voucherItems[i][field]) || (!field && !this.voucherItems[i].debitAmount && !this.voucherItems[i].creditAmount)) {
          row = i + 1;
          break;
        }
      }

      if (row > -1) {
        this.$Message(`亲，第${row}行，请输入${name}！`);
        return true;
      }
    },
    before() {
      this.loading = true;
      voucher.beforeId({code: this.form.code, voucherDate: this.form.voucherDate, word: this.form.word}).then(({data}) => {
        if (data) {
          this.entityId = data;
          this.init();
        } else {
          this.$Message("亲，已经没有上一条啦！")
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    next() {
      this.loading = true;
      voucher.nextId({code: this.form.code, voucherDate: this.form.voucherDate, word: this.form.word}).then(({data}) => {
        if (data) {
          this.entityId = data;
          this.init();
        } else {
          this.$Message("亲，已经没有下一条啦！")
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    init() {
      if (this.voucherId || this.entityId) {
        this.loading = true;
        voucher.load(this.entityId || this.voucherId).then(({data}) => {
          //复制的时候，去除ID
          if (this.isCopy) {
            data.id = null;
            data.auditMemberId = null;
            data.carryForward = null;
            data.code = null;
            data.voucherDate = manba(this.currentAccountSets.currentAccountDate).endOf(manba.MONTH).format();
          }
          data.details.forEach(val => {
            val.originalCreditAmount = val.creditAmount;
            val.originalDebitAmount = val.debitAmount;
          })
          this.voucherDetails = data.details;
          this.voucher = data;
          this.form = {
            id: data.id,
            auditMemberId: data.auditMemberId,
            word: data.word,
            remark: data.remark,
            voucherDate: data.voucherDate,
            carryForward: data.carryForward,
            billList: data.billList || [],
            code: data.code
          };

          if (this.isCopy) {
            this.loadCode();
          }
        }).finally(() => this.loading = false)
      } else {
        this.form.voucherDate = manba(this.currentAccountSets.currentAccountDate).endOf(manba.MONTH).format();
        this.$nextTick(() => {
          this.voucherDetails = this.cachedDetails || [];
        })
      }

      //结转凭证初始化
      this.checkInit();
      this.profitCheck();
      this.assetsInit();

      this.loadVoucherWords();
    },
    assetsInit() {
      if (this.assetsData) {
        const codeList = Array.from(new Set(this.assetsData.map(val => val.assetsSubjectCode)));
        this.form.voucherDate = manba(this.voucherDate).endOf(manba.MONTH).format();
        setting.subject.listByCode(codeList).then(({data}) => {
          let details = [];
          this.assetsData.forEach(assets => {
            details.push({
              summary: assets.summary,
              subject: data[assets.assetsSubjectCode],
              subjectName: `${data[assets.assetsSubjectCode].code}-${data[assets.assetsSubjectCode].name}`,
              subjectId: data[assets.assetsSubjectCode].id,
              debitAmount: assets.debitAmount || null,
              creditAmount: assets.creditAmount || null,
            });
          })
          this.voucherDetails = details || [];
        });
      }
    },
    checkInit() {
      if (this.checkData && !this.profitCheckData) {
        this.carryForward = !!this.type;
        this.form.voucherDate = manba(this.checkData.checkYear + "-" + this.checkData.checkMonth).endOf(manba.MONTH).format();
        let {title: name} = this.type;
        let details = [];
        checkout.loadTplData({
          checkYear: this.checkData.checkYear,
          checkMonth: this.checkData.checkMonth,
          tplId: this.type.id,
        }).then(({data}) => {
          this.checkTplId = this.type.id;
          data.itemList.forEach(amount => {
            let sub = data.subject[amount.subjectId];
            let detail = {
              summary: `第${this.checkData.checkMonth}期 ${name}`,
              subject: sub,
              subjectName: `${sub.code}-${sub.fullName}`,
              subjectId: sub.id,
              [amount.balanceDirection === '贷' ? 'creditAmount' : 'debitAmount']: amount.amount
            };
            details.push(detail)
          });

          this.$nextTick(() => {
            this.voucherDetails = details || [];
          })
        });
      }
    },
    profitCheck() {
      if (this.profitCheckData) {
        this.carryForward = true;
        this.form.voucherDate = manba(this.checkData.checkYear + "-" + this.checkData.checkMonth).endOf(manba.MONTH).format();
        this.form.voucherDate = manba(this.checkData.checkYear + "-" + this.checkData.checkMonth).endOf(manba.MONTH).format();
        this.form.auditMemberId = this.User.id;
        this.form.auditDate = manba().format();
        let name = "结转损益";
        let details = [];

        this.profitCheckData.forEach(sub => {
          let detail = {
            summary: `第${this.checkData.checkMonth}期 ${name}`,
            subject: sub.subject,
            currencyId: this.localCurrency.id,
            subjectName: `${sub.code}-${sub.fullName}`,
            subjectId: sub.subjectId
          };

          details.push(Object.assign({}, detail, {
            [sub.balanceDirection === '借' ? 'creditAmount' : 'debitAmount']: sub.balance
          }));
        });
        this.$nextTick(() => {
          this.voucherDetails = details || [];
        })
      }
    },
    audit() {
      this.$Confirm("亲，确认要审核吗?").then(() => {
        this.loading = true;
        voucher.audit({
          checked: [this.voucher.id],
          year: this.voucher.year,
          month: this.voucher.month
        }).then(() => {
          this.init();
          this.$Message("审核成功！");
        }).finally(() => {
          this.loading = false;
        });
      });
    },
    cancelAudit() {
      this.$Confirm("亲，确认要取消审核吗?").then(() => {
        this.loading = true;
        voucher.cancelAudit({
          checked: [this.voucher.id],
          year: this.voucher.year,
          month: this.voucher.month
        }).then(() => {
          this.init();
          this.$Message("取消审核成功！");
        }).finally(() => {
          this.loading = false;
        });
      });
    },
    toNew() {
      if (this.voucherId) {
        this.$store.commit('newTab', {key: "VoucherForm", title: "新增凭证"})
        this.$emit('close');
      } else {
        this.$store.commit('refreshTab', {key: "VoucherForm", title: "新增凭证"})
      }
    },
    saveTpl() {
      if (this.formValid()) {
        let layerId = layer.open({
          title: "新建凭证模板",
          shadeClose: false,
          closeBtn: false,
          zIndex: 1000,
          area: ['400px', 'auto'],
          content: h(VoucherTplForm, {
            entity: Object.assign({}, this.form, {details: this.voucherItems, createMember: this.User.id}),
            onClose: () => {
              layer.close(layerId);
            },
            onSuccess: () => {
              layer.close(layerId);
            }
          })
        });
      }
    },
    loadTpl() {
      let layerId = layer.open({
        title: "凭证模板",
        shadeClose: false,
        closeBtn: 1,
        zIndex: 1000,
        area: ['1100px', 'auto'],
        content: h(VoucherTplList, {
          onClose: () => {
            layer.close(layerId);
          },
          onSuccess: (tpl) => {
            this.voucherDetails = tpl.details
            layer.close(layerId);
          },
          onBack: () => {
            layer.close(layerId);
          }
        })
      });
    },
    trigger(key) {
      this[key].call(this);
    },
    rmBill(row, rowIndex) {
      this.form.billList.splice(rowIndex, 1);
      this.$refs.billListTable.remove(row);
      message.success("已移除，保存后生效~")
    }
  },
  created() {
    const cachedDetails = localStorage.getItem(this.cacheKey);
    if (cachedDetails) {
      this.cachedDetails = JSON.parse(cachedDetails);
    }

    this.isCopy = this.copy;
    if (!this.isCopy) {
      this.entityId = this.voucherId;
    }
    this.dpOps.start = this.firstCheckoutDate;
    this.init();
  }
}
</script>

<style scoped>
.mask {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 100;
  bottom: 0;
  top: 110px;
}

.auditpic {
  position: absolute;
  z-index: 100;
  right: 100px;
}

.h-btn.h-btn-green {
  background-color: #2080f0;
  border-color: #167bef;
  color: #ffffff;
}
.h-btn.h-btn-green:hover {
  border-color: #167bef;
  background-color: #2080f0;
}
</style>
