<template>
  <div style="padding: 20px;">
      <div class="toptitle">
       财务报表申报
      </div>
      <Row :space="20">
        <Cell width="8"></Cell>
        <Cell width="8">
          <div style="padding: 10px;">请确认您的报税选项，导出文件</div>
          <Form  :label-width="120" :model="dataForm" ref="dataForm" :rules="{}" :validOnChange="true" showErrorTip>
              <FormItem label="报税类型：" prop="select2" required>
                <!-- <Input type="text" v-model="data.number" /> -->
                <Select v-model="dataForm.select2" :datas="taxlist" keyName="name" titleName="name"></Select>
              </FormItem>
              <FormItem label="报税所属期：" prop="select1" required>
                <Select v-model="dataForm.select1" :datas="taxlist" keyName="name" titleName="name"></Select>
              </FormItem>
             
              <FormItem>
                <Button color="primary" @click="submit">提交</Button>
                <Button @click="toTax">取消</Button>
              </FormItem>
              <FormItem>
                <!-- <Radio v-model="value" :datas="param">
                  <template slot="item" slot-scope="{item}">
                    <i :class="item.icon"></i>
                    1
                    <span>{{item.key}}:{{item.title}}</span>
                  </template>
                </Radio> -->
                     
                      
              </FormItem>
             
            </Form>
        </Cell>
        <Cell width="8" >
          
          <div class="clickspan" style="padding: 10px;">点击查看操作手册</div>
        </Cell>
        
        
         
      </Row>
    <Row  :space="20">
      <Cell width="4"></Cell>
      <Cell width="16">
            登录国税局网站的报税界面，将文件导入即可报税如有疑问，请<span  class="clickspan" >咨询客服</span> 
            ，咨询时间：周一至周五 9:30 - 18:30点击前往<span  class="clickspan" >安徽省国税局</span> 
        </Cell>
    </Row>
     
    
  </div>
</template>
<script>
  import {mapMutations, mapState} from 'vuex';
  import EnterpriseWorkbench from '@/api/EnterpriseWorkbench'

  export default {
    name: 'FinancialReport',
    data() {
      return {
        
        open:require('@/assets/open.png'),
        unopen:require('@/assets/unopen.png'),
        taxlist:[
           {name:'个税申报',isopen:false,},
           {name:'财务报表申请',isopen:false},
           {name:'增值税申报',isopen:true},
           {name:'消费税申报',isopen:true},
           {name:'附加税申报',isopen:true},
           {name:'企业所得税预缴申报',isopen:true},
           {name:'印花税申报',isopen:true},
           {name:'房产税申报',isopen:true},
        ],
        dataForm:{
          select2:'',
          select1:'',
        },
        value: 1,
        value3:null,
        select3:null,
        param3: [
        { title: '选择1', key: 'a1', other: '其他值' },
        { title: '选择2', key: 'a2' },
        { title: '选择3', key: 'a3' }
      ]
      };
    },
    computed: {
      ...mapState(['currentAccountSets', 'currentTab']),
    },
    methods: {
      ...mapMutations(['newTab']),
      toTax(){
        console.log('11')
        this.newTab({
          title: '税务申报',
          key: 'TaxDeclaration'
        })
      },
      submit() {
				  console.log('提交')
					let validResult = this.$refs.dataForm.valid();
          console.log('提交',validResult)
					if (validResult.result) {
					
            console.log('提交11')
						this.loading = true
						// this.$api.common.login(this.login).then(() => {
						// 	localStorage.setItem("login_mobile", this.login.mobile);
						// 	window.location.replace('/');
						// }).catch(() => {
						// 	this.loading = false
						// });
					}
				
			
			},
     
    

    },
    created() {
      
     
    },
  };
</script>

<style lang="less" scoped>
  .toptitle{
    font-size: 32px;
    font-weight: bold;
    color: #333333;
    width: 100%;
    text-align: center;
    margin: 20px 0;
  }
  .box1{
    // width: 390px;
    height: 175px;
    background: #FFFFFF;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    border: 1px solid #D9D9D9;
    position: relative;
    font-size: 30px;
    font-weight: 400;
    color: #999;
    display: flex;
    justify-content: center;
    align-items: center;
    .col1{
      color: #333;
    }
    .fiximg{
      position: absolute;
      bottom: 0;
      right: 0;
      width: 90px;
      height: 90px;
    }
  }
  .tips{
    margin-top: 16px;
    line-height: 30px;
    display: flex;
   flex-wrap: wrap;  
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    span{
      display: inline-block;
    }
  }
  .clickspan{
    color: var(--primary);
    cursor: pointer;
    // padding: 10px;
  }

</style>

