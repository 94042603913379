<template>
  <div class="modal-column">
    <div class="modal-column-full-body">
      <div class="flex mb-16px" v-for="r in records">
        <div class="border w-300px">
          <div class="p-8px bg-gray3-color">{{ r.category }}：{{ r.invoiceNumber }}</div>
          <div class="p-8px">
            <div>开票日期：{{ r.tradeDate }}</div>
            <div class="my-8px">业务类型：{{ r.businessType }}</div>
            <div class="my-8px"> 金额：{{ r.taxAmountExcluded }}</div>
            <div> 税额：{{ r.taxAmount }}</div>
          </div>
        </div>
        <div class="flex-1 ml-16px border">
          <template v-if="r.voucher">
            <div class="p-8px bg-gray3-color"><span class="mr-26px">日期：{{ r.voucher.voucherDate }}</span> <span>凭证字号：{{
                r.voucher.word
              }}-{{ r.voucher.code }}</span></div>
            <div class="p-8px">
              <vxe-table :data="r.voucher.details" :show-overflow="false" :show-header="false" show-footer
                         :footer-method="footerMt">
                <vxe-column field="summary"/>
                <vxe-column field="subjectName"/>
                <vxe-column field="debitAmount" align="right" width="120" formatter="toMoney"/>
                <vxe-column field="creditAmount" align="right" width="120" formatter="toMoney"/>
              </vxe-table>
            </div>
          </template>
          <template v-else>
            请先设置凭证模板
          </template>
        </div>
      </div>
    </div>
    <div class="modal-column-between">
      <vxe-button :loading="loading" @click="$emit('close')" icon="fa fa-close">取消</vxe-button>
      <vxe-button :loading="loading" @click="doSubmit()" status="primary" icon="fa fa-check">生 成</vxe-button>
    </div>
  </div>
</template>

<script>
import {Invoice, InvoiceBusiness} from "@/api/Invoice";
import voucher from "@/api/voucher";
import {mapGetters, mapState} from "vuex";
import manba from "manba";

/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
export default {
  name: "InvoiceGenerateVoucher",
  props: {
    records: Array,
    type: String,
  },
  data() {
    return {
      loading: false,
      templateMap: {}
    }
  },
  computed: {
    ...mapState(['currentAccountSets']),
    ...mapGetters(['localCurrency']),
    accountDate() {
      return manba(this.currentAccountSets.currentAccountDate).endOf(manba.MONTH).format();
    }
  },
  methods: {
    doSubmit() {
      if (this.records.every(value => !!value.voucher)) {
        this.loading = true;
        Invoice.generateVoucher(this.records.map(value => value.voucher)).then(() => {
          this.$emit('success')
        }).finally(() => this.loading = false);
      }
    },
    footerMt({data}) {
      const debitAmount = data.reduce((t, r) => t + (r.debitAmount || 0), 0)
      const creditAmount = data.reduce((t, r) => t + (r.creditAmount || 0), 0)

      return [["合计", this.dxMoney(debitAmount), this.numFormat(debitAmount), this.numFormat(creditAmount)]]
    }
  },
  created() {
    let codeMap = {};
    let _this = this;
    InvoiceBusiness.list(this.type).then(async ({data}) => {
      if (data) {
        data.forEach(it => {
          this.templateMap[it.id] = it;
        })
        for (const row of this.records) {
          const template = _this.templateMap[row.invoiceBusinessTypeId];

          if (template && template.items && template.items.length) {
            let lastCode = codeMap[template.word];
            if (!lastCode) {
              const results = await voucher.loadCode({word: template.word, currentAccountDate: _this.accountDate})
              codeMap[template.word] = results.data;
              lastCode = results.data;
            }
            const rows = [];
            let debitAmount = 0, creditAmount = 0;
            const voucherData = {word: template.word, code: lastCode, voucherDate: _this.accountDate};
            template.items.forEach(it => {
              const item = {
                summary: `${row.businessType}_${row.category}_${row.invoiceNumber || ''}_${row.customerName || ''}`,
                subjectName: it.subjectTitle,
                subjectId: it.subjectId,
                currencyId: this.localCurrency.id,
              };
              if (it.direction === '借') {
                item.debitAmount = row[it.valueField];
                debitAmount += row[it.valueField];
              } else {
                item.creditAmount = row[it.valueField];
                creditAmount += row[it.valueField];
              }
              rows.push(item)
            })
            voucherData.invoiceId = row.id;
            voucherData.debitAmount = debitAmount;
            voucherData.creditAmount = creditAmount;
            voucherData.details = rows;
            row.voucher = voucherData;
            codeMap[template.word] = lastCode + 1;
          }
        }
      }
    })
  }
}
</script>
