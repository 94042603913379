<template>
  <div>
    <div class-name="!px-8px" perfect style="display: flex;margin-top: 10px; background: #FFFFFF">

                <div class="flex items-center">
                  <DatePicker v-model="params.start" :clearable="false" :option="{end:params.end}"/>
                  <span class="mx-8px">至</span>
                  <DatePicker v-model="params.end" :clearable="false" :option="{start:params.start}"/>
                </div>
          <Button style="margin-left: 10px" color="primary" @click="loadList();" :loading="loading">查询</Button>
    </div>
  </div>
</template>
<script>
/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
export default {
  name: "IncomeInvoice"
}
</script>

<script setup>
import {h, reactive, ref, toRefs} from "vue";
import {layer} from "@layui/layer-vue";
import InvoiceForm from "@/views/invoice/InvoiceForm";
import {Invoice} from "@/api/Invoice";
import {confirm, message} from "heyui.ext";
import InvoiceChangeBusinessType from "@/views/invoice/InvoiceChangeBusinessType";
import InvoiceCheck from "@/views/invoice/InvoiceCheck";
import InvoiceGenerateVoucher from "@/views/invoice/InvoiceGenerateVoucher";
import manba from "manba";
import {useStore} from "vuex";

const store = useStore();

const currentAccountDate = manba(store.state.currentAccountSets.currentAccountDate).add(-1, manba.MONTH);
const startDate = ref(currentAccountDate.startOf(manba.MONTH).format());
const endDate = ref(currentAccountDate.endOf(manba.MONTH).format());

const params = reactive({
  start: startDate.value,
  end: endDate.value,
  voucherId: null,
  customerName: null,
  invoiceNumber: null,
  state: null,
  category: null,
});
const resetForm = () => {
  Object.assign(params, {
    start: startDate.value,
    end: endDate.value,
    voucherId: null,
    customerName: null,
    invoiceNumber: null,
    state: null,
    category: null,
  })
  loadList();
  paramsDropdown.value.hide();
}
const props = defineProps(['categoryList'])
const {categoryList} = toRefs(props)
const pageList = ref({
  results: [],
  total: 0,
});
const xTable = ref(null);
const paramsDropdown = ref(null);
const loading = ref(false);
const total = ref(0);
const page = reactive({
  page: 1,
  pageSize: 20
});

const showNewForm = (key, entity) => {
  let layerId = layer.drawer({
    title: key,
    shadeClose: false,
    closeBtn: false,
    zIndex: 1000,
    area: ['1200px', '100vh'],
    content: h(InvoiceForm, {
      entity,
      category: key,
      type: "IN",
      onClose: () => {
        layer.close(layerId);
      },
      onSuccess: () => {
        layer.close(layerId);
        loadList();
      }
    })
  });
}

const loadList = () => {
  loading.value = true;
  Invoice.list("IN", page, params).then(({data}) => {
    pageList.value = data;
    startDate.value = params.start;
    endDate.value = params.end;
  }).finally(() => loading.value = false)
}
loadList();

const onPageChange = ({currentPage, pageSize}) => {
  page.page = currentPage;
  page.pageSize = pageSize;
  loadList();
}

const remove = (rows) => {
  confirm({
    content: "确认删除?",
    async: true,
    onConfirm(resolve) {
      Invoice.remove(rows.map(val => val.id)).then(() => {
        resolve(true);
        loadList();
      }).catch(() => resolve(false))
    }
  })
}


const doAction = (action) => {
  const records = xTable.value.getCheckboxRecords();
  if (records.length) {
    switch (action) {
      case "批量删除":
        remove(records);
        break;
      case "指定业务类型":
      case "批量认证":
        let layerId = layer.open({
          title: action,
          shadeClose: false,
          closeBtn: false,
          zIndex: 1000,
          area: ['400px', 'auto'],
          content: h(action === '指定业务类型' ? InvoiceChangeBusinessType : InvoiceCheck, {
            rows: records,
            type: "IN",
            onClose: () => {
              layer.close(layerId);
            },
            onSuccess: () => {
              layer.close(layerId);
              loadList();
            }
          })
        });
        break;
    }
  }
}

const generateVoucher = () => {
  const records = xTable.value.getCheckboxRecords();
  if (records.length && records.every(val => !val.voucher)) {
    let layerId = layer.drawer({
      title: "生成凭证预览",
      shadeClose: false,
      zIndex: 1000,
      area: ['1100px', '100vh'],
      content: h(InvoiceGenerateVoucher, {
        records,
        type: "IN",
        onClose: () => {
          layer.close(layerId);
        },
        onSuccess: () => {
          layer.close(layerId);
          loadList();
        }
      })
    });
  } else {
    message.warn("没有选择需要生成凭证的记录~");
  }
}
</script>
