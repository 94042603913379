<template>
  <div class="modal-column" style="top: 63px;left: 296.5px;z-index: 799;">
    <div class="zmsundialog-content">
      <div class="mpBox" style="width:auto;">
        <ul style="width: 100%;" >
          <li>
            <div style="padding:10px 20px ;display: flex;justify-content: flex-end;">
              <Button color="primary" @click="showForm()">新增</Button>
              <Button >导入</Button>
              <Button >导出</Button>
            </div>
          </li>
                    <li>
                        <vxe-table ref="journalTable"
                        :loading="loading"
                        :data="dataList"
                        align="center"
                        >
                                <vxe-column title="摘要" field="summary" width="300" >
                                    <template #default="{row}">
                                      <span v-for="(item, index) in row.details"> {{item.summary}}/</span>
                                    </template>
                                </vxe-column>
                                <vxe-column title="科目" field="name" width="300" />
                                <vxe-column title="借方金额" field="code" width="300" />
                                <vxe-column title="贷方金额" field="code"  />
                              
                               
                                <vxe-column title="操作" width="120" fixed="right">
                                    <template #default="{row}">
                                        <div class="actions" >
                                            <span  @click="showForm(row)" class="fa">修改</span>
                                            <span  @click="remove(row['id'])" class="fa">删除</span>
                                        </div>
                                    </template>
                                </vxe-column>
                            </vxe-table>
                        
                       </li>
                 </ul>
                <!-- <ul class="mpItList" style="height: auto;">
                  <li>
                    <div class="pzContentXj"><span class="addNew" @click="showForm()" title="新增"></span></div>
                    <div class="addNewTplBtn th-btn-deep-fz" @click="showForm()">新增模板</div>
                  </li>
                  <li v-for="(item, index) in dataList">
                    <div class="pzContent">
                      <div class="normalPanel">
                        <div class="pzName caigou">
                          <span class="showText els_span">{{ item.name }}</span>
                          <span class="editIcon" @click="showForm(item)" title="编辑"></span>
                          <span class="deleteIcon" @click="remove(item)" title="删除"></span>
                        </div>
                        <div class="pzText">
                          <table>
                            <tbody>
                            <tr>
                              <td class="tpTitle">借：</td>
                              <td class="tpContent">
                                <span v-for="items in item.details" class="els_span">{{ items.subject.balanceDirection === '贷' ? '' : items.subjectName }}</span>
                              </td>
                            </tr>
                            <tr>
                              <td class="tpTitle" >贷：</td>
                              <td class="tpContent">
                                <span v-for="items in item.details" class="els_span">{{ items.subject.balanceDirection === '借' ? '' : items.subjectName }}</span>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <vxe-button class="useTemplateBtn th-btn-deep-fz" @click="doSelect(item)">使用模板</vxe-button>
                  </li>
                </ul> -->
      </div>
    </div>
    <!-- <div class="modal-column-between">
      <vxe-button icon="fa fa-close" @click="$emit('close')">取消</vxe-button>
    </div> -->
  </div>
</template>

<script>
  /**
   * <p>****************************************************************************</p>
   * <p><b>Copyright © 2010-2023杭州财汇餐谋科技有限公司All Rights Reserved<b></p>
   * <ul style="margin:15px;">
   * <li>Description : </li>
   * <li>Version     : 1.0</li>
   * <li>Creation    : 2023年01月11日</li>
   * <li>@author     : ____′↘夏悸</li>
   * </ul>
   * <p>****************************************************************************</p>
   */
  export default {
    name: "TemplateManager"
  }
</script>

<script setup>

  import voucher from "@/api/voucher";
  import {h, ref} from "vue";
  import {confirm, message} from "heyui.ext";
  import {layer} from "@layui/layer-vue";
  import VoucherTplDialog from "@/views/voucher/VoucherTplDialog.vue";

  const loading = ref(false)
  const dataList = ref([])
  const tpl = ref(null)
  const emits = defineEmits(['close', 'success']);

  const loadList = () => {
    loading.value = true;
    voucher.tpl().then(({data}) => {
      dataList.value = data;
      dataList.value = [
        {
          "id": "484662818994389423",
          "name": "采购",
          "details": [
            {
              "id": "483595025876255906",
              "accountSetsId": "469102069945528322",
              "voucherId": "484662818994389423",
              "year": 2023,
              "month": 9,
              "voucherDate": "2023-09-30",
              "currencyId": "433722807319433217",
              "currencyName": "人民币",
              "currencyCode": "RMB",
              "summary": "办公费_增值税专用发票_0001_1100",
              "subjectId": "433708451772911751",
              "subjectName": "5602004 管理费用_办公费",
              "subjectCode": "5602004",
              "subject": {
                "id": "433708451772911751",
                "accountSetsId": "469102069945528322",
                "type": "损益",
                "code": "5602004",
                "queryCode": "56020040000",
                "name": "办公费",
                "mnemonicCode": "glfy_bgf",
                "balanceDirection": "借",
                "status": true,
                "parentId": "433708451743551619",
                "level": 2,
                "systemDefault": true,
                "unit": "",
                "fullName": "管理费用_办公费",
                "leaf": true,
                "adjustments": false,
                "auxiliaryAccounting": [],
                "currencyAccounting": [],
                "children": []
              },
              "debitAmount": 101,
              "creditAmount": 0,
              "num": 0,
              "price": 0,
              "debitAmountFor": 0,
              "creditAmountFor": 0,
              "exchangeRate": 0,
              "debitNum": 0,
              "creditNum": 0,
              "carryForward": false,
              "auxiliaryTitle": ""
            },
            {
              "id": "483595025876255906",
              "accountSetsId": "469102069945528322",
              "voucherId": "484662818994389423",
              "year": 2023,
              "month": 9,
              "voucherDate": "2023-09-30",
              "currencyId": "433722807319433217",
              "currencyName": "人民币",
              "currencyCode": "RMB",
              "summary": "办公费_增值税专用发票_0001_1100",
              "subjectId": "433708451772911751",
              "subjectName": "5602004 管理费用_办公费",
              "subjectCode": "5602004",
              "subject": {
                "id": "433708451772911751",
                "accountSetsId": "469102069945528322",
                "type": "损益",
                "code": "5602004",
                "queryCode": "56020040000",
                "name": "办公费",
                "mnemonicCode": "glfy_bgf",
                "balanceDirection": "借",
                "status": true,
                "parentId": "433708451743551619",
                "level": 2,
                "systemDefault": true,
                "unit": "",
                "fullName": "管理费用_办公费",
                "leaf": true,
                "adjustments": false,
                "auxiliaryAccounting": [],
                "currencyAccounting": [],
                "children": []
              },
              "debitAmount": 101,
              "creditAmount": 0,
              "num": 0,
              "price": 0,
              "debitAmountFor": 0,
              "creditAmountFor": 0,
              "exchangeRate": 0,
              "debitNum": 0,
              "creditNum": 0,
              "carryForward": false,
              "auxiliaryTitle": ""
            },
            {
              "id": "483595025876255907",
              "accountSetsId": "469102069945528322",
              "voucherId": "484662818994389423",
              "year": 2023,
              "month": 9,
              "voucherDate": "2023-09-30",
              "currencyId": "433722807319433217",
              "currencyName": "人民币",
              "currencyCode": "RMB",
              "summary": "办公费_增值税专用发票_0001_1100",
              "subjectId": "433708450762084390",
              "subjectName": "2202 应付账款",
              "subjectCode": "2202",
              "subject": {
                "id": "433708450762084390",
                "accountSetsId": "469102069945528322",
                "type": "负债",
                "code": "2202",
                "queryCode": "22020000000",
                "name": "应付账款",
                "mnemonicCode": "yfzk",
                "balanceDirection": "贷",
                "status": true,
                "parentId": "0",
                "level": 1,
                "systemDefault": true,
                "unit": "",
                "fullName": "应付账款",
                "leaf": true,
                "adjustments": false,
                "auxiliaryAccounting": [],
                "currencyAccounting": [],
                "children": []
              },
              "debitAmount": 0,
              "creditAmount": 101,
              "num": 0,
              "price": 0,
              "debitAmountFor": 0,
              "creditAmountFor": 0,
              "exchangeRate": 0,
              "debitNum": 0,
              "creditNum": 0,
              "carryForward": false,
              "auxiliaryTitle": ""
            }
          ]
        },
        {
          "id": "484662818994389423",
          "name": "存款",
          "details": [
            {
              "id": "483595025876255906",
              "accountSetsId": "469102069945528322",
              "voucherId": "484662818994389423",
              "year": 2023,
              "month": 9,
              "voucherDate": "2023-09-30",
              "currencyId": "433722807319433217",
              "currencyName": "人民币",
              "currencyCode": "RMB",
              "summary": "办公费_增值税专用发票_0001_1100",
              "subjectId": "433708451772911751",
              "subjectName": "5602004 管理费用_办公费",
              "subjectCode": "5602004",
              "subject": {
                "id": "433708451772911751",
                "accountSetsId": "469102069945528322",
                "type": "损益",
                "code": "5602004",
                "queryCode": "56020040000",
                "name": "办公费",
                "mnemonicCode": "glfy_bgf",
                "balanceDirection": "借",
                "status": true,
                "parentId": "433708451743551619",
                "level": 2,
                "systemDefault": true,
                "unit": "",
                "fullName": "管理费用_办公费",
                "leaf": true,
                "adjustments": false,
                "auxiliaryAccounting": [],
                "currencyAccounting": [],
                "children": []
              },
              "debitAmount": 101,
              "creditAmount": 0,
              "num": 0,
              "price": 0,
              "debitAmountFor": 0,
              "creditAmountFor": 0,
              "exchangeRate": 0,
              "debitNum": 0,
              "creditNum": 0,
              "carryForward": false,
              "auxiliaryTitle": ""
            },
            {
              "id": "483595025876255907",
              "accountSetsId": "469102069945528322",
              "voucherId": "484662818994389423",
              "year": 2023,
              "month": 9,
              "voucherDate": "2023-09-30",
              "currencyId": "433722807319433217",
              "currencyName": "人民币",
              "currencyCode": "RMB",
              "summary": "办公费_增值税专用发票_0001_1100",
              "subjectId": "433708450762084390",
              "subjectName": "2202 应付账款",
              "subjectCode": "2202",
              "subject": {
                "id": "433708450762084390",
                "accountSetsId": "469102069945528322",
                "type": "负债",
                "code": "2202",
                "queryCode": "22020000000",
                "name": "应付账款",
                "mnemonicCode": "yfzk",
                "balanceDirection": "贷",
                "status": true,
                "parentId": "0",
                "level": 1,
                "systemDefault": true,
                "unit": "",
                "fullName": "应付账款",
                "leaf": true,
                "adjustments": false,
                "auxiliaryAccounting": [],
                "currencyAccounting": [],
                "children": []
              },
              "debitAmount": 0,
              "creditAmount": 101,
              "num": 0,
              "price": 0,
              "debitAmountFor": 0,
              "creditAmountFor": 0,
              "exchangeRate": 0,
              "debitNum": 0,
              "creditNum": 0,
              "carryForward": false,
              "auxiliaryTitle": ""
            },
            {
              "id": "483595025876255907",
              "accountSetsId": "469102069945528322",
              "voucherId": "484662818994389423",
              "year": 2023,
              "month": 9,
              "voucherDate": "2023-09-30",
              "currencyId": "433722807319433217",
              "currencyName": "人民币",
              "currencyCode": "RMB",
              "summary": "办公费_增值税专用发票_0001_1100",
              "subjectId": "433708450762084390",
              "subjectName": "2202 应付账款",
              "subjectCode": "2202",
              "subject": {
                "id": "433708450762084390",
                "accountSetsId": "469102069945528322",
                "type": "负债",
                "code": "2202",
                "queryCode": "22020000000",
                "name": "应付账款",
                "mnemonicCode": "yfzk",
                "balanceDirection": "贷",
                "status": true,
                "parentId": "0",
                "level": 1,
                "systemDefault": true,
                "unit": "",
                "fullName": "应付账款",
                "leaf": true,
                "adjustments": false,
                "auxiliaryAccounting": [],
                "currencyAccounting": [],
                "children": []
              },
              "debitAmount": 0,
              "creditAmount": 101,
              "num": 0,
              "price": 0,
              "debitAmountFor": 0,
              "creditAmountFor": 0,
              "exchangeRate": 0,
              "debitNum": 0,
              "creditNum": 0,
              "carryForward": false,
              "auxiliaryTitle": ""
            },
          ]
        },
        {
          "id": "483595025343579292",
          "name": "付款",
          "details": [
          ]
        },
        {
          "id": "484662818994389423",
          "name": "发放工资",
          "details": [
            {
              "id": "483595025876255906",
              "accountSetsId": "469102069945528322",
              "voucherId": "484662818994389423",
              "year": 2023,
              "month": 9,
              "voucherDate": "2023-09-30",
              "currencyId": "433722807319433217",
              "currencyName": "人民币",
              "currencyCode": "RMB",
              "summary": "办公费_增值税专用发票_0001_1100",
              "subjectId": "433708451772911751",
              "subjectName": "5602004 管理费用_办公费",
              "subjectCode": "5602004",
              "subject": {
                "id": "433708451772911751",
                "accountSetsId": "469102069945528322",
                "type": "损益",
                "code": "5602004",
                "queryCode": "56020040000",
                "name": "办公费",
                "mnemonicCode": "glfy_bgf",
                "balanceDirection": "借",
                "status": true,
                "parentId": "433708451743551619",
                "level": 2,
                "systemDefault": true,
                "unit": "",
                "fullName": "管理费用_办公费",
                "leaf": true,
                "adjustments": false,
                "auxiliaryAccounting": [],
                "currencyAccounting": [],
                "children": []
              },
              "debitAmount": 101,
              "creditAmount": 0,
              "num": 0,
              "price": 0,
              "debitAmountFor": 0,
              "creditAmountFor": 0,
              "exchangeRate": 0,
              "debitNum": 0,
              "creditNum": 0,
              "carryForward": false,
              "auxiliaryTitle": ""
            },
          ]
        },
        {
          "id": "484662818994389423",
          "name": "收款",
          "details": [
            {
              "id": "483595025876255907",
              "accountSetsId": "469102069945528322",
              "voucherId": "484662818994389423",
              "year": 2023,
              "month": 9,
              "voucherDate": "2023-09-30",
              "currencyId": "433722807319433217",
              "currencyName": "人民币",
              "currencyCode": "RMB",
              "summary": "办公费_增值税专用发票_0001_1100",
              "subjectId": "433708450762084390",
              "subjectName": "2202 应付账款",
              "subjectCode": "2202",
              "subject": {
                "id": "433708450762084390",
                "accountSetsId": "469102069945528322",
                "type": "负债",
                "code": "2202",
                "queryCode": "22020000000",
                "name": "应付账款",
                "mnemonicCode": "yfzk",
                "balanceDirection": "贷",
                "status": true,
                "parentId": "0",
                "level": 1,
                "systemDefault": true,
                "unit": "",
                "fullName": "应付账款",
                "leaf": true,
                "adjustments": false,
                "auxiliaryAccounting": [],
                "currencyAccounting": [],
                "children": []
              },
              "debitAmount": 0,
              "creditAmount": 101,
              "num": 0,
              "price": 0,
              "debitAmountFor": 0,
              "creditAmountFor": 0,
              "exchangeRate": 0,
              "debitNum": 0,
              "creditNum": 0,
              "carryForward": false,
              "auxiliaryTitle": ""
            }
          ]
        },
        {
          "id": "484662818994389423",
          "name": "收入",
          "details": [
            {
              "id": "483595025876255906",
              "accountSetsId": "469102069945528322",
              "voucherId": "484662818994389423",
              "year": 2023,
              "month": 9,
              "voucherDate": "2023-09-30",
              "currencyId": "433722807319433217",
              "currencyName": "人民币",
              "currencyCode": "RMB",
              "summary": "办公费_增值税专用发票_0001_1100",
              "subjectId": "433708451772911751",
              "subjectName": "5602004 管理费用_办公费",
              "subjectCode": "5602004",
              "subject": {
                "id": "433708451772911751",
                "accountSetsId": "469102069945528322",
                "type": "损益",
                "code": "5602004",
                "queryCode": "56020040000",
                "name": "办公费",
                "mnemonicCode": "glfy_bgf",
                "balanceDirection": "借",
                "status": true,
                "parentId": "433708451743551619",
                "level": 2,
                "systemDefault": true,
                "unit": "",
                "fullName": "管理费用_办公费",
                "leaf": true,
                "adjustments": false,
                "auxiliaryAccounting": [],
                "currencyAccounting": [],
                "children": []
              },
              "debitAmount": 101,
              "creditAmount": 0,
              "num": 0,
              "price": 0,
              "debitAmountFor": 0,
              "creditAmountFor": 0,
              "exchangeRate": 0,
              "debitNum": 0,
              "creditNum": 0,
              "carryForward": false,
              "auxiliaryTitle": ""
            },
            {
              "id": "483595025876255907",
              "accountSetsId": "469102069945528322",
              "voucherId": "484662818994389423",
              "year": 2023,
              "month": 9,
              "voucherDate": "2023-09-30",
              "currencyId": "433722807319433217",
              "currencyName": "人民币",
              "currencyCode": "RMB",
              "summary": "办公费_增值税专用发票_0001_1100",
              "subjectId": "433708450762084390",
              "subjectName": "2202 应付账款",
              "subjectCode": "2202",
              "subject": {
                "id": "433708450762084390",
                "accountSetsId": "469102069945528322",
                "type": "负债",
                "code": "2202",
                "queryCode": "22020000000",
                "name": "应付账款",
                "mnemonicCode": "yfzk",
                "balanceDirection": "贷",
                "status": true,
                "parentId": "0",
                "level": 1,
                "systemDefault": true,
                "unit": "",
                "fullName": "应付账款",
                "leaf": true,
                "adjustments": false,
                "auxiliaryAccounting": [],
                "currencyAccounting": [],
                "children": []
              },
              "debitAmount": 0,
              "creditAmount": 101,
              "num": 0,
              "price": 0,
              "debitAmountFor": 0,
              "creditAmountFor": 0,
              "exchangeRate": 0,
              "debitNum": 0,
              "creditNum": 0,
              "carryForward": false,
              "auxiliaryTitle": ""
            }
          ]
        },
        {
          "id": "484662818994389423",
          "name": "提现",
          "details": [
            {
              "id": "483595025876255906",
              "accountSetsId": "469102069945528322",
              "voucherId": "484662818994389423",
              "year": 2023,
              "month": 9,
              "voucherDate": "2023-09-30",
              "currencyId": "433722807319433217",
              "currencyName": "人民币",
              "currencyCode": "RMB",
              "summary": "办公费_增值税专用发票_0001_1100",
              "subjectId": "433708451772911751",
              "subjectName": "5602004 管理费用_办公费",
              "subjectCode": "5602004",
              "subject": {
                "id": "433708451772911751",
                "accountSetsId": "469102069945528322",
                "type": "损益",
                "code": "5602004",
                "queryCode": "56020040000",
                "name": "办公费",
                "mnemonicCode": "glfy_bgf",
                "balanceDirection": "借",
                "status": true,
                "parentId": "433708451743551619",
                "level": 2,
                "systemDefault": true,
                "unit": "",
                "fullName": "管理费用_办公费",
                "leaf": true,
                "adjustments": false,
                "auxiliaryAccounting": [],
                "currencyAccounting": [],
                "children": []
              },
              "debitAmount": 101,
              "creditAmount": 0,
              "num": 0,
              "price": 0,
              "debitAmountFor": 0,
              "creditAmountFor": 0,
              "exchangeRate": 0,
              "debitNum": 0,
              "creditNum": 0,
              "carryForward": false,
              "auxiliaryTitle": ""
            },
            {
              "id": "483595025876255907",
              "accountSetsId": "469102069945528322",
              "voucherId": "484662818994389423",
              "year": 2023,
              "month": 9,
              "voucherDate": "2023-09-30",
              "currencyId": "433722807319433217",
              "currencyName": "人民币",
              "currencyCode": "RMB",
              "summary": "办公费_增值税专用发票_0001_1100",
              "subjectId": "433708450762084390",
              "subjectName": "2202 应付账款",
              "subjectCode": "2202",
              "subject": {
                "id": "433708450762084390",
                "accountSetsId": "469102069945528322",
                "type": "负债",
                "code": "2202",
                "queryCode": "22020000000",
                "name": "应付账款",
                "mnemonicCode": "yfzk",
                "balanceDirection": "贷",
                "status": true,
                "parentId": "0",
                "level": 1,
                "systemDefault": true,
                "unit": "",
                "fullName": "应付账款",
                "leaf": true,
                "adjustments": false,
                "auxiliaryAccounting": [],
                "currencyAccounting": [],
                "children": []
              },
              "debitAmount": 0,
              "creditAmount": 101,
              "num": 0,
              "price": 0,
              "debitAmountFor": 0,
              "creditAmountFor": 0,
              "exchangeRate": 0,
              "debitNum": 0,
              "creditNum": 0,
              "carryForward": false,
              "auxiliaryTitle": ""
            }
          ]
        },
        {
          "id": "484662818994389423",
          "name": "缴纳增值税",
          "details": [
            {
              "id": "483595025876255906",
              "accountSetsId": "469102069945528322",
              "voucherId": "484662818994389423",
              "year": 2023,
              "month": 9,
              "voucherDate": "2023-09-30",
              "currencyId": "433722807319433217",
              "currencyName": "人民币",
              "currencyCode": "RMB",
              "summary": "办公费_增值税专用发票_0001_1100",
              "subjectId": "433708451772911751",
              "subjectName": "5602004 管理费用_办公费",
              "subjectCode": "5602004",
              "subject": {
                "id": "433708451772911751",
                "accountSetsId": "469102069945528322",
                "type": "损益",
                "code": "5602004",
                "queryCode": "56020040000",
                "name": "办公费",
                "mnemonicCode": "glfy_bgf",
                "balanceDirection": "借",
                "status": true,
                "parentId": "433708451743551619",
                "level": 2,
                "systemDefault": true,
                "unit": "",
                "fullName": "管理费用_办公费",
                "leaf": true,
                "adjustments": false,
                "auxiliaryAccounting": [],
                "currencyAccounting": [],
                "children": []
              },
              "debitAmount": 101,
              "creditAmount": 0,
              "num": 0,
              "price": 0,
              "debitAmountFor": 0,
              "creditAmountFor": 0,
              "exchangeRate": 0,
              "debitNum": 0,
              "creditNum": 0,
              "carryForward": false,
              "auxiliaryTitle": ""
            },
            {
              "id": "483595025876255907",
              "accountSetsId": "469102069945528322",
              "voucherId": "484662818994389423",
              "year": 2023,
              "month": 9,
              "voucherDate": "2023-09-30",
              "currencyId": "433722807319433217",
              "currencyName": "人民币",
              "currencyCode": "RMB",
              "summary": "办公费_增值税专用发票_0001_1100",
              "subjectId": "433708450762084390",
              "subjectName": "2202 应付账款",
              "subjectCode": "2202",
              "subject": {
                "id": "433708450762084390",
                "accountSetsId": "469102069945528322",
                "type": "负债",
                "code": "2202",
                "queryCode": "22020000000",
                "name": "应付账款",
                "mnemonicCode": "yfzk",
                "balanceDirection": "贷",
                "status": true,
                "parentId": "0",
                "level": 1,
                "systemDefault": true,
                "unit": "",
                "fullName": "应付账款",
                "leaf": true,
                "adjustments": false,
                "auxiliaryAccounting": [],
                "currencyAccounting": [],
                "children": []
              },
              "debitAmount": 0,
              "creditAmount": 101,
              "num": 0,
              "price": 0,
              "debitAmountFor": 0,
              "creditAmountFor": 0,
              "exchangeRate": 0,
              "debitNum": 0,
              "creditNum": 0,
              "carryForward": false,
              "auxiliaryTitle": ""
            }
          ]
        }
      ];
    }).finally((() => loading.value = false))
  }
  loadList();

  const showForm = (entity) => {
    let layerId = layer.open({
      title: entity ? '编辑模板' : '新建模板',
      shadeClose: false,
      closeBtn: 1,
      zIndex: 1000,
      area: ['1160px', 'auto'],
      content: h(VoucherTplDialog, {
        entity,
        onClose: () => {
          loadList();
          layer.close(layerId);
        },
        onSuccess: (tpl) => {
          layer.close(layerId);
        },
        onBack: () => {
          layer.close(layerId);
        }
      })
    });
  }

  const remove = (entity) => {
    confirm({
      content: "确定删除此模板?",
      async: true,
      onConfirm(resolve) {
        voucher.deleteTpl(entity.id).then(() => {
          resolve(true)
          loadList();
        }).catch(() => {
          resolve(false)
        })
      }
    })
  }

  const doSelect = (entity) => {
    emits('success', entity)
  }

</script>

<style lang="less" scoped>
  .zmsundialog-content {
    position: relative;
    background-color: #FFF;
  }

  .mpBox {
    width: 960px;
    height: 450px;
    margin: 20px auto 0;
  }

  .mpTit {
    font-size: 20px;
    height: 36px;
    line-height: 36px;
    font-weight: bold;
  }

  .mpItList {
    height: 438px;
    border: 1px solid #f2f2f2;
    margin-top: 10px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .mpItList li {
    display: block;
    float: left;
    width: 230px;
    height: 214px;
    padding: 10px;
    text-align: center;
  }

  .pzContentXj {
    height: 156px;
    border: 1px solid #2080f0;
    position: relative;
    border-radius: 3px;
    background: #fff url('../../assets/line-big.png') no-repeat;
  }

  .addNew {
    width: 88px;
    height: 88px;
    display: block;
    position: absolute;
    left: 60px;
    top: 30px;
    background: url('../../assets/add-new.default.png') center no-repeat;
    margin: 6px auto;
    font-size: 14px;
  }

  .th-btn-deep-fz {
    width: 112px;
    height: 32px;
    line-height: 31px;
    margin: 6px auto;
    border-radius: 3px;
    cursor: pointer;
  }
  .th-btn-deep-fz {
    color: white !important;
    border: 1px solid #2080f0 !important;
    background: #2080f0 !important;
  }
  .th-btn-deep-fz:hover {
    color: white !important;
    border: 1px solid #1766c2 !important;
    background: #1766c2 !important
  }

  // 2...
  .pzContent {
    border: 1px solid #2080f0;
    border-radius: 3px;
  }

  .pzName {
    font-size: 16px;
    padding-left: 40px;
    color: #FFFFFF;
    height: 34px;
    line-height: 34px;
    position: relative;
    background: #2080f0 6px center no-repeat;
    background-image: url('../../assets/itemCommIc.svg');
  }

  .pzContent .showText {
    width: 120px;
    display: block;
    text-align: left;
  }

  .pzContent .editIcon {
    right: 46px;
  }

  .pzContent .editIcon {
    background: url('../../assets/itemEditIc.svg') no-repeat center;
    background-size: 20px;
  }

  .pzContent .deleteIcon {
    right: 10px;
  }

  .pzContent .deleteIcon {
    background: url('../../assets/itemDelIc.svg') no-repeat center;
    background-size: 20px;
  }

  .pzContent .editIcon, .pzContent .deleteIcon {
    display: block;
    font-size: 18px;
    position: absolute;
    top: 4px;
    line-height: 24px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    font-family: "ykj-iconfont";
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
  }

  .pzContent .pzText {
    height: 122px;
    position: relative;
    background: #fff url('../../assets/line.png') no-repeat;
    text-align: left;
  }

  .pzContent .pzText table {
    position: absolute;
    width: 178px;
    top: 20px;
    left: 30px;
    font-size: 14px;
    line-height: 22px;
  }

  .pzContent .pzText table .tpTitle {
    text-align: right;
    vertical-align: top;
  }

  .pzContent .pzText table .tpContent {
    width: 150px;
    vertical-align: top;

    span {
      display: block;
      width: 150px;
    }
  }

  .els_span {
    display: inline-block;
    word-break: keep-all;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
    max-width: 100%;
  }
</style>
