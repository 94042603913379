<template>
  <div class="modal-column">
    <div class="flex-1 p-20px">
      <Form ref="form" :model="form" :rules="{}" mode="block">
        <FormItem label="编码" prop="code" required>
          <Input type="text" v-model="form.code"/>
        </FormItem>
        <FormItem label="名称" prop="name" required>
          <Input type="text" v-model="form.name"/>
        </FormItem>
      </Form>
    </div>
    <div class="modal-column-btn">
      <Button @click="$emit('close')">取消</Button>
      <Button color="green" @click="submit" :loading="loading">{{ form.id ? '更新' : '保存' }}</Button>
    </div>
  </div>
</template>

<script>/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
import CashierIoType from "@/api/CashierIoType";
import {CopyObj} from "@/js/common/utils";

export default {
  name: "CashierIoTypeForm",
  props: {
    type: String,
    nextCode: String,
    entity: Object,
  },
  data() {
    return {
      loading: false,
      form: {
        id: null,
        code: null,
        name: null,
        accountSetsId: null,
        type: this.type,
      },
    }
  },
  methods: {
    submit() {
      let validResult = this.$refs.form.valid();
      if (validResult.result) {
        this.loading = true;
        CashierIoType.save(this.form).then(() => {
          this.$emit('success');
        }).finally(() => {
          this.loading = false;
        })
      }
    }
  },
  created() {
    CopyObj(this.form, this.entity);
    if (!this.entity) {
      this.form.code = this.nextCode;
    }
  }
}
</script>
