<template>
  <vxe-toolbar class-name="px-8px">
    <template #buttons>
      <Button>2019年9月</Button>
    </template>
  </vxe-toolbar>
  <div class="flex" style="overflow-y: auto">
    <table class="cus-table flex-1">
      <thead class="header">
      <tr>
        <td>项目</td>
        <td class="text-right">本年累计金额</td>
        <td class="text-right">本期金额</td>
      </tr>
      </thead>
      <tbody>
      <template v-for="row in items">
        <report-row :report="report" :reportData="reportData" :row="row" :lv="0"/>
      </template>
      </tbody>
    </table>
  </div>
</template>

<script>
/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
export default {
  name: "CwgyxxReportView"
}
</script>

<script setup>

import ReportApi from "@/api/report";
import {ref, toRefs} from "vue";
import {toArrayTree} from "xe-utils";
import ReportRow from "@/views/report/view/ReportRow";

const props = defineProps(['report']);

const {report} = toRefs(props);

const reportData = ref({
  beginYear: {},
  endTerm: {},
})

const items = toArrayTree(report.value.items);

</script>
