<template>
    <div class="modal-column">
        <div class="modal-column-full-body">
            <Form ref="form" :model="form" :rules="{}" labelWidth="150">
                <div lass="ml-20px  mb-16px" style="display: flex">
                    <div class="title"/>
                    <div style="margin-left: 10px;  font-size: 18px;flood-color: #333333;">基本信息</div>
                </div>
                <div class="inline-form-item">
                    <FormItem label="编号" prop="code" required>
                        <Input type="text" v-model="form.code" :disabled="disabled"/>
                    </FormItem>
                    <FormItem label="姓名" prop="name" required>
                        <Input type="text" v-model="form.name"/>
                    </FormItem>
                    <FormItem label="性别" prop="gender" required>
                        <Select :deletable="false" v-model="form.gender" :datas="{1:'男', 2:'女'}"/>
                    </FormItem>
                </div>
                <div class="inline-form-item">
                    <FormItem label="证件类型" prop="cardType" required>
                        <Select :deletable="false" v-model="form.cardType"
                                :datas="{1:'居民身份证', 2:'中国护照', 3:'港澳居民来往内地通行证', 4:'台湾居民来往内地通行证', 5:'港澳居民居住证', 6:'台湾居民居住证', 7:'外国护照', 8:'外国人永久居留身份证'}"/>
                    </FormItem>
                    <FormItem label="证件号码" prop="idCard" required>
                        <Input type="text" v-model="form.idCard"/>
                    </FormItem>
                    <FormItem label="出生日期" prop="birthday" required>
                        <DatePicker v-model="form.birthday" :disabled="disabled"></DatePicker>
                    </FormItem>
                </div>
                <div class="inline-form-item">
                    <FormItem label="手机" prop="mobile" required>
                        <Input type="text" v-model="form.mobile"/>
                    </FormItem>
                    <FormItem label="邮箱" prop="email" required>
                        <Input type="text" v-model="form.email"/>
                    </FormItem>
                    <FormItem label="学历" prop="education" required>
                        <Select :deletable="false" v-model="form.education"
                                :datas="{1:'初中', 2:'高中', 3:'中专', 4:'大专', 5:'本科', 6:'硕士', 7:'博士', 8:'其他'}"/>
                    </FormItem>
                </div>
                <div class="inline-form-item">
                    <FormItem label="银行名称" prop="bankName">
                        <Input type="text" v-model="form.bankName"/>
                    </FormItem>
                    <FormItem label="银行卡号" prop="bankCode">
                        <Input type="text" v-model="form.bankCode"/>
                    </FormItem>
                    <FormItem label="入职日期" prop="employDate" required>
                        <DatePicker v-model="form.employDate" :disabled="disabled"></DatePicker>
                    </FormItem>
                </div>
                <div class="inline-form-item">
                    <FormItem label="部门" prop="deptId" required>
                        <Select :datas="deptList" filterable v-model="form.deptId" :disabled="disabled"/>
                    </FormItem>
                    <FormItem label="岗位" prop="post">
                        <Input type="text" v-model="form.post"/>
                    </FormItem>
                    <FormItem label="工资始发日期" prop="salaryStartDate" required>
                        <DatePicker v-model="form.salaryStartDate" :disabled="disabled"></DatePicker>
                    </FormItem>
                </div>
                <div class="inline-form-item">
                    <FormItem label="职称" prop="professional">
                        <Input type="text" v-model="form.professional"/>
                    </FormItem>
                    <FormItem label="计提工资科目" prop="salaryAccount" required>
                        <Select :deletable="false" :disabled="disabled" :datas="subjectList" keyName="code"
                                titleName="fullName" filterable v-model="form.salaryAccount" :filter="filter">
                            <template v-slot:item="{ item }">
                                {{ item.code }} {{ item.fullName }}
                            </template>
                        </Select>
                    </FormItem>
                    <FormItem label="员工状态" prop="status" required>
                        <Select :deletable="false" v-model="form.status" :datas="{1:'在职', 2:'离职'}"/>
                    </FormItem>
                </div>
                <div class="inline-form-item">
                    <FormItem label="备注" prop="remark">
                        <Textarea v-model="form.remark" rows="2"></Textarea>
                    </FormItem>
                </div>

                <div lass="ml-20px  mb-16px" style="display: flex">
                    <div class="title"/>
                    <div style="margin-left: 10px;  font-size: 18px;flood-color: #333333;">扣除款项</div>
                </div>
                <div class="inline-form-item" style="margin-top: 40px">
                    <FormItem label="是否缴纳五险一金" prop="payMethod" required>
                        <Radio v-model="form.payMethod" dict="defaultRadios"/>
                    </FormItem>
<!--                    <FormItem label="参保方案设置" prop="insureId" required>-->
<!--                        <Select @change="changeInsure" :datas="insureList" filterable v-model="form.insureId"-->
<!--                                :disabled="disabled"/>-->
<!--                    </FormItem>-->
                </div>
                <div v-if="form.payMethod">
                    <vxe-table
                            ref="itemsTable"
                            :data="form.payDataInfo"

                            :edit-config="{trigger:'click', mode:'cell', showIcon: false}"
                            @edit-closed="saveChange"
                            style="height: 265px"
                            height="auto"
                            align="center">
                        <vxe-column field="projectName" title="项目名称"/>
                        <vxe-column field="paymentBase" title="缴纳基数"
                                    :edit-render="{name:'$input',props:{type:'number'}}"/>
                        <vxe-colgroup title="公司承担部分">
                            <vxe-column field="companyPayment" title="公司缴纳比例(%)" :edit-render="{name:'$input'}"/>
                            <vxe-column field="companyMoney" title="公司缴纳金额"
                                        :edit-render="{name:'$input',props:{type:'number'}}"/>
                        </vxe-colgroup>
                        <vxe-colgroup title="个人承担部分">
                            <vxe-column field="individualPayment" title="个人缴纳比例(%)"
                                        :edit-render="{name:'$input'}"/>
                            <vxe-column field="individualMoney" title="个人缴纳金额"
                                        :edit-render="{name:'$input',props:{type:'number'}}"/>
                        </vxe-colgroup>
                    </vxe-table>
                </div>
            </Form>
        </div>
        <div style="display: flex;justify-content: center;align-items: center">
            <Button color="primary" @click="submit" :loading="loading">{{ this.id ? '更新' : '保存' }}</Button>
            <Button @click="$emit('getdata',{},false)" style="margin-left: 10px">取消</Button>

        </div>
    </div>
</template>

<script>/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
import Employee from "@/api/Employee";
import InsuredConfig from "@/api/InsuredConfig";
import {CopyObj} from "@/js/common/utils";
import {mapState} from "vuex";
import setting from "@/api/setting";
import Setting from "@/api/setting";

export default {
    name: "EmployeeForm",
    props: {
        entity: Object,
        id: Object
    },
    data() {
        return {
            loading: false,
            deptList: [],
            insureList: [],
            subjectList: [],
            form: {
                id: null,
                code: null,
                name: null,
                gender: 1,
                cardType: 1,
                idCard: null,
                birthday: null,
                mobile: null,
                email: null,
                education: 1,
                bankName: null,
                bankCode: null,
                employDate: null,
                deptId: null,
                post: null,
                salaryStartDate: null,
                professional: null,
                salaryAccount: null,
                status: 1,
                remark: null,
                payMethod: true,
                payDataInfo: []
            },
        }
    },
    computed: {
        ...mapState(['currentAccountSets']),
    },
    methods: {
        loadList() {
            if (this.id === undefined) {
                // InsuredConfig.queryInsureInfo(this.form.insureId).then((data) => {
                //   this.form.payDataInfo = data
                // })
                this.form.payDataInfo = [
                    {
                        id: '1',
                        pid: '1',
                        projectName: '养老保险',
                        paymentBase: '1.21',
                        companyPayment: '20',
                        companyMoney: '1',
                        individualPayment: '8',
                        individualMoney: '6'
                    },
                    {
                        id: '2',
                        pid: '2',
                        projectName: '医疗保险',
                        paymentBase: '2.21',
                        companyPayment: '10',
                        companyMoney: '2',
                        individualPayment: '2',
                        individualMoney: '5'
                    },
                    {
                        id: '3',
                        pid: '3',
                        projectName: '失业保险',
                        paymentBase: '3.21',
                        companyPayment: '1',
                        companyMoney: '3',
                        individualPayment: '0.2',
                        individualMoney: '4'
                    },
                    {
                        id: '4',
                        pid: '4',
                        projectName: '工伤保险',
                        paymentBase: '4.21',
                        companyPayment: '0.5',
                        companyMoney: '4',
                        individualPayment: '0',
                        individualMoney: '0'
                    },
                    {
                        id: '5',
                        pid: '5',
                        projectName: '生育保险',
                        paymentBase: '5.21',
                        companyPayment: '0.8',
                        companyMoney: '5',
                        individualPayment: '0',
                        individualMoney: '0'
                    },
                    {
                        id: '6',
                        pid: '6',
                        projectName: '住房公积金',
                        paymentBase: '6.21',
                        companyPayment: '12',
                        companyMoney: '6',
                        individualPayment: '12',
                        individualMoney: '1'
                    },
                ]
            } else {
                // Employee.queryEmployeeInfo(this.id).then((data) => {
                //   this.form = data
                // })
                // Employee.queryEmployeePayInfo(this.id).then((data) => {
                //   this.form.payDataInfo = data
                // })
            }
        },
        submit() {
            // let validResult = this.$refs.form.valid();
            // if (validResult.result) {
            //   this.loading = true;
            //   if (!this.form.payMethod) {
            //     this.form.payDataInfo = []
            //   }
            //   Employee.save(this.form).then(() => {
            //     this.$emit('success');
            // }).finally(() => {
            //   this.loading = false;
            // });
            // }
            this.$emit('getdata', {}, false)
        },
        initSelect() {
            setting.accountingCategoryDetails.loadAuxiliaryAccountingDataByName(['DEPARTMENT', 'VENDOR']).then(({data}) => {
                this.deptList = data['DEPARTMENT'].map(val => {
                    return {
                        key: val.id,
                        accountingCategoryId: val.categoryId,
                        title: val.name
                    }
                });
            })
            Setting.subject.voucherSelect().then(({data}) => {
                this.subjectList = data || [];
            })
            InsuredConfig.list().then(({data}) => {
                // this.insureList = data.map(val => {
                //   return {
                //     key: val.id,
                //     title: val.name
                //   }
                // })
                this.insureList = [
                    {id: '1', name: '老板方案'},
                    {id: '2', name: '人事方案'},
                    {id: '3', name: '产品方案'},
                    {id: '4', name: '经理方案'},
                ].map(val => {
                    return {
                        key: val.id,
                        title: val.name
                    }
                })
                this.form.insureId = this.insureList[0].key
            });
        },

        // 编辑数据接口
        saveChange({row, column}) {
            // const field = column.field
            // const cellValue = row[field]
            // if ((row['projectName'] === '工伤保险' || row['projectName'] === '生育保险') && (field === 'individualPayment' || field === 'individualMoney')) {
            //   row[field] = ''
            //   return false
            // }
        },
        changeInsure(data) {
            // InsuredConfig.queryInsureProject(data.key).then((result) => {
            //   this.insureList = result
            // })
        }
    },
    created() {
        // CopyObj(this.form, this.entity);
        this.loadList()
        this.initSelect()
    }
}
</script>

<style lang="less" scoped>
.line {
  height: 1px;
  background-color: #dadada;
  margin: 20px 0;
}

.title {
  width: 4px;
  height: 24px;
  background: #2FA8FD;
  border-radius: 6px 6px 6px 6px;
  opacity: 1;
}
</style>
