<template>
    <div class="modal-column">
        <div class="modal-column-full-body">
            <div style="font-size: 16px;color: #FF7979;display: flex;justify-content: center">您正在执行敏感操作，为确保您的账号安全，请使用缔造梦云财务APP扫码验证身份</div>
            <div style="font-size: 16px;color: #333333;margin-top: 10px;margin-right: 10px">第一步：下载缔造梦云财务APP<span style="color: #2FA8FD">没有缔造梦云财务APP？点此下载</span></div>
            <div style="font-size: 16px;color: #333333;margin-top: 10px;">第二步：登录缔造梦云财务APP扫描二维码验证身份</div>
            <div style="display: flex;justify-content: center;align-items: center;margin-top: 10px">
                <img src="../../assets/boss_code.png" style="height: 180px;width: 180px">
            </div>
            <div style="font-size: 16px;color: #333333;margin-top: 10px;display: flex;justify-content: center">登录缔造梦云财务APP</div>
            <div style="font-size: 16px;color: #333333;margin-top: 10px;display: flex;justify-content: center">打卡手机扫一扫</div>
        </div>
        <div class="line"></div>
        <div style="display: flex;justify-content: end;align-items: center; padding: 20px">
            <Button @click="$emit('close')" >取消</Button>
            <Button color="primary" @click="goNext()" style="margin-left: 20px">确定</Button>
        </div>
    </div>
</template>

<script>

import Employee from "@/api/Employee";
import InsuredConfig from "@/api/InsuredConfig";
import {CopyObj} from "@/js/common/utils";
import {mapState} from "vuex";
import setting from "@/api/setting";
import Setting from "@/api/setting";

export default {
    name: "EmployeeForm",
    props: {
        entity: Object,
        id: Object
    },
    data() {
        return {
            loading: false,
            deptList: [],
            insureList: [],
            subjectList: [],
            form: {
                id: null,
                code: null,
                name: null,
                gender: 1,
                cardType: 1,
                idCard: null,
                birthday: null,
                mobile: null,
                email: null,
                education: 1,
                bankName: null,
                bankCode: null,
                employDate: null,
                deptId: null,
                post: null,
                salaryStartDate: null,
                professional: null,
                salaryAccount: null,
                status: 1,
                remark: null,
                payMethod: true,
                payDataInfo: []
            },
        }
    },
    computed: {
        ...mapState(['currentAccountSets']),
    },

    methods: {
        goNext(){
            this.$emit('success')
            console.log(22222)
            this.$store.commit('newTab', {key: "ConnectVerify", title: "关联进销存"})
        },
        loadList() {
            if (this.id === undefined) {
                // InsuredConfig.queryInsureInfo(this.form.insureId).then((data) => {
                //   this.form.payDataInfo = data
                // })
                this.form.payDataInfo = [
                    {
                        id: '1',
                        pid: '1',
                        projectName: '养老保险',
                        paymentBase: '1.21',
                        companyPayment: '20',
                        companyMoney: '1',
                        individualPayment: '8',
                        individualMoney: '6'
                    },
                    {
                        id: '2',
                        pid: '2',
                        projectName: '医疗保险',
                        paymentBase: '2.21',
                        companyPayment: '10',
                        companyMoney: '2',
                        individualPayment: '2',
                        individualMoney: '5'
                    },
                    {
                        id: '3',
                        pid: '3',
                        projectName: '失业保险',
                        paymentBase: '3.21',
                        companyPayment: '1',
                        companyMoney: '3',
                        individualPayment: '0.2',
                        individualMoney: '4'
                    },
                    {
                        id: '4',
                        pid: '4',
                        projectName: '工伤保险',
                        paymentBase: '4.21',
                        companyPayment: '0.5',
                        companyMoney: '4',
                        individualPayment: '0',
                        individualMoney: '0'
                    },
                    {
                        id: '5',
                        pid: '5',
                        projectName: '生育保险',
                        paymentBase: '5.21',
                        companyPayment: '0.8',
                        companyMoney: '5',
                        individualPayment: '0',
                        individualMoney: '0'
                    },
                    {
                        id: '6',
                        pid: '6',
                        projectName: '住房公积金',
                        paymentBase: '6.21',
                        companyPayment: '12',
                        companyMoney: '6',
                        individualPayment: '12',
                        individualMoney: '1'
                    },
                ]
            } else {
                // Employee.queryEmployeeInfo(this.id).then((data) => {
                //   this.form = data
                // })
                // Employee.queryEmployeePayInfo(this.id).then((data) => {
                //   this.form.payDataInfo = data
                // })
            }
        },
        submit() {
            // let validResult = this.$refs.form.valid();
            // if (validResult.result) {
            //   this.loading = true;
            //   if (!this.form.payMethod) {
            //     this.form.payDataInfo = []
            //   }
            //   Employee.save(this.form).then(() => {
            //     this.$emit('success');
            // }).finally(() => {
            //   this.loading = false;
            // });
            // }
            this.$emit('getdata', {}, false)
        },
        initSelect() {
            setting.accountingCategoryDetails.loadAuxiliaryAccountingDataByName(['DEPARTMENT', 'VENDOR']).then(({data}) => {
                this.deptList = data['DEPARTMENT'].map(val => {
                    return {
                        key: val.id,
                        accountingCategoryId: val.categoryId,
                        title: val.name
                    }
                });
            })
            Setting.subject.voucherSelect().then(({data}) => {
                this.subjectList = data || [];
            })
            InsuredConfig.list().then(({data}) => {
                // this.insureList = data.map(val => {
                //   return {
                //     key: val.id,
                //     title: val.name
                //   }
                // })
                this.insureList = [
                    {id: '1', name: '老板方案'},
                    {id: '2', name: '人事方案'},
                    {id: '3', name: '产品方案'},
                    {id: '4', name: '经理方案'},
                ].map(val => {
                    return {
                        key: val.id,
                        title: val.name
                    }
                })
                this.form.insureId = this.insureList[0].key
            });
        },

        // 编辑数据接口
        saveChange({row, column}) {
            // const field = column.field
            // const cellValue = row[field]
            // if ((row['projectName'] === '工伤保险' || row['projectName'] === '生育保险') && (field === 'individualPayment' || field === 'individualMoney')) {
            //   row[field] = ''
            //   return false
            // }
        },
        changeInsure(data) {
            // InsuredConfig.queryInsureProject(data.key).then((result) => {
            //   this.insureList = result
            // })
        }
    },
    created() {
        // CopyObj(this.form, this.entity);
        this.loadList()
        this.initSelect()
    }
}
</script>

<style lang="less" scoped>
.line {
  height: 1px;
  background-color: #dadada;
  margin: 20px 0;
}

.title {
  width: 4px;
  height: 24px;
  background: #2FA8FD;
  border-radius: 6px 6px 6px 6px;
  opacity: 1;
}
</style>
