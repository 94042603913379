<template>
    <app-content class="h-panel" style="overflow: hidden">
        <div style="margin: 20px 20px 0px 20px">
            <div class="flex items-center">
                <DatePicker v-model="startDate" :format="format"></DatePicker>
                <span class="mx-8px">至</span>
                <DatePicker v-model="endDate" :format="format"></DatePicker>

                <Button class="mx-10px" color="primary">收票宝上传</Button>
                <Button class="mx-10px" color="primary">上传</Button>
                <DropdownMenu class="mx-10px" button @clickItem="downloadTrigger"
                              :datas="{all:'全部',checked:'已审核',checked1:'未审核'}">
                    审核
                </DropdownMenu>
                <DropdownMenu class="mx-10px" button @clickItem="downloadTrigger"
                              :datas="{all:'单据凭证',checked:'其他凭证'}">
                    关联凭证
                </DropdownMenu>
                <Button class="mx-10px">移动</Button>
                <Button class="mx-10px">新建文件</Button>
                <Button class="mx-10px">下载</Button>
                <Button class="mx-10px">删除</Button>
                <div class="h-input h-input-suffix-icon" style="border: 1px solid #c1c1c1;padding: 5px">
                    <input type="text" placeholder="请输入关键字"/>
                    <i class="h-icon-search"/>
                </div>
            </div>
        </div>
        <div class="h-panel-body voucher-list" style="display: flex;justify-content: space-between">
            <Table style="width: 80%" :datas="fileDatas" :border="false" :checkbox="true" :stripe="true"
                   :loading="loading">
                <TableItem title="文件名" prop="fileName"></TableItem>
                <TableItem title="文件类型" prop="fileType"></TableItem>
                <TableItem title="单据类型" prop="formType"></TableItem>
                <TableItem title="上传人" prop="creator"></TableItem>
                <TableItem title="上传方式" prop="uploadType"></TableItem>
                <TableItem title="日期" prop="uploadDate"></TableItem>
                <TableItem title="大小" prop="fileSize"></TableItem>
                <TableItem title="凭证" prop="voucher"></TableItem>
                <TableItem title="事项" prop="things"></TableItem>
                <TableItem title="校验状态" prop="checkStatus"></TableItem>
            </Table>
            <div style="width: 18%;border: 1px solid #D9D9D9">
                <div style="width: 100%;height: 50px;background: #FAFAFA;display: flex;font-size: 16px;padding-left: 10px; align-items: center">
                    <span>已用空间(</span>
                    <span style="color: #2FA8FD">6.75MB</span>
                    <span>/10.00MB)</span>
                </div>
                <Tree :option="paramTree" ref="demo" v-model="value" :multiple="true" choose-mode="all"
                      @choose="choose"></Tree>
            </div>
        </div>
    </app-content>
</template>

<script>
import Qs from "qs";
import {mapActions, mapGetters, mapState} from 'vuex'
import {h} from 'vue'
import {clone, pick} from "xe-utils";
import {layer} from "@layui/layer-vue";
import VoucherImport from "@/views/voucher/VoucherImport";
import VoucherPrinterSetting from "@/views/voucher/VoucherPrinterSetting";
import manba from "manba";
import voucher from "@/api/voucher";
import {downloadBlob} from "download.js";
import FinishingOffNo from "@/views/voucher/FinishingOffNo.vue";
import {message} from "heyui.ext";

export default {
    name: "ElectronicArchives",
    data() {
        let list = [
            {
                id: 1,
                title: '所有文件',
                children: [
                    {
                        id: 10,
                        title: '1001库存现金',
                        children: [
                            {id: 15, title: '现金'},
                            {id: 16, title: '支票'},
                            ]

                    },
                    {id: 11, title: '1002银行转帐'},
                ]
            },
            {
                id: 2,
                title: '备份数据',
            },

        ];
        return {
            datas: [],
            fileDatas: [
                {
                    id: 1,
                    fileName: '单据凭证.jpeg',
                    fileType: '图片',
                    formType: '单据类型',
                    creator: '张三',
                    uploadType: '财务系统',
                    uploadDate: '2023-09-20',
                    fileSize: '1M',
                    voucher: '',
                    things: '',
                    checkStatus: '无需审查'
                },
                {
                    id: 2,
                    fileName: '销售报表.xls',
                    fileType: '表格',
                    formType: '报表类型',
                    creator: '张三',
                    uploadType: '财务系统',
                    uploadDate: '2023-09-20',
                    fileSize: '1M',
                    voucher: '',
                    things: '',
                    checkStatus: '无需审查'
                },
            ],
            accountDate: null,
            format: 'YYYY-MM-DD',
            startDate: '',
            endDate: '',
            pagination: {
                page: 1,
                pageSize: 20,
                total: 0
            },
            voucherWords: ['全部', '选择-'],
            params: {
                voucherDate: {start: null, end: null},
                audit: 0,
                voucherWord: 0,
                summary: null,
            },
            loading: false,
            checkAll: false,
            param: [{title: '打印全部', key: 'printAll'}, {title: '打印选中', key: 'printCheck'}],
            value: [],
            paramTree: {
                keyName: 'id',
                titleName: 'title',
                dataMode: 'tree',
                datas: list
            }
        };
    },
    watch: {
        checkAll(nval) {
            let data = Array.from(this.datas);
            data.forEach(val => val._checked = nval);
            this.datas = data;
        }
    },
    computed: {
        ...mapState(['User', 'currentAccountSets']),
        ...mapGetters(['checkoutSelect', 'localCurrency']),
        date() {
            return manba(this.accountDate);
        }
    },
    methods: {
        ...mapActions(['init']),
        currentChange() {
            this.loadList();
        },
        trigger(key) {
            this[key].call(this);
        },
        downloadTrigger(key) {
            if (key === 'all') {
                voucher.download(this.params).then((data) => {
                    downloadBlob(this.currentAccountSets.companyName + (manba().format('YYYYMMDDHHmmss')) + "凭证.xlsx", data);
                })
            } else {
                let checked = this.datas.filter(value => value._checked);
                if (checked.length) {
                    voucher.download({id: checked.map(value => value.id)}).then((data) => {
                        downloadBlob(this.currentAccountSets.companyName + (manba().format('YYYYMMDDHHmmss')) + "凭证.xlsx", data);
                    })
                } else {
                    this.$Message("未选择记录");
                }
            }
        },
        printAll() {
            let param = Qs.stringify({
                audit: this.params.audit,
                voucherDateStart: this.params.voucherDate.start,
                voucherDateEnd: this.params.voucherDate.end
            }, {indices: false});
            window.open(`/api/pdf/voucher?${param}`);
        },
        printCheck() {
            let checked = this.datas.filter(value => value._checked);
            if (checked.length) {
                let param = Qs.stringify({id: checked.map(value => value.id)}, {indices: false});
                window.open(`/api/pdf/voucher?${param}`);
            } else {
                this.$Message("未选择记录");
            }
        },
        loadList() {
            const loadId = layer.load(2);
            voucher.list(this.pagination, this.params).then(({data}) => {
                this.datas = data.results || [];
                this.pagination = {
                    page: data.page,
                    size: data.size,
                    total: data.total
                }
            }).finally(() => layer.close(loadId))
        },
        remove(row) {
            this.$Confirm("确认删除?").then(() => {
                const loadId = layer.load(2);
                voucher.delete([pick(row, ['id', 'year', 'month'])]).then(({data}) => {
                    if (data && data.length) {
                        this.$Message("部分删除不成功，请检查所在账期是否已结账~")
                    } else {
                        this.$Message("删除成功~")
                        this.init();
                    }

                    this.loadList();
                }).finally(() => layer.close(loadId))
            });
        },
        finishingOffNo() {
            let layerId = layer.open({
                title: "断号整理",
                shadeClose: false,
                closeBtn: false,
                zIndex: 1000,
                area: ['400px', 'auto'],
                content: h(FinishingOffNo, {
                    onClose: () => {
                        layer.close(layerId);
                    },
                    onSuccess: () => {
                        layer.close(layerId);
                        this.loadList();
                    }
                })
            });
        },
        batchDelete() {
            let checked = this.datas.filter(value => value._checked && !value.auditMemberId);
            if (checked.length) {
                this.$Confirm("确认删除?").then(() => {
                    const loadId = layer.load(2);
                    this.$api.voucher.delete(checked.map(val => pick(val, ['id', 'year', 'month']))).then(({data}) => {
                        if (data && data.length) {
                            this.$Message("部分删除不成功，请检查所在账期是否已结账~")
                        } else {
                            this.$Message("删除成功~")
                        }
                        this.init();
                        this.loadList();
                    }).finally(() => {
                        layer.close(loadId);
                    });
                });
            } else {
                message.warn("没有可删除凭证~");
            }
        },
        audit() {
            let checked = this.datas.filter(value => value._checked);
            if (checked.length) {
                const vdates = new Set(checked.map(val => val.year + "" + val.month))
                if (vdates.size > 1) {
                    this.$Message("不能跨期操作~");
                    return
                }
                this.$Confirm("亲，确认要审核吗?").then(() => {
                    this.loading = true;
                    this.$api.voucher.audit({
                        checked: checked.map(value => value.id),
                        year: checked[0].year,
                        month: checked[0].month
                    }).then(() => {
                        this.loadList();
                        this.$Message("审核成功！");
                    }).finally(() => {
                        this.loading = false;
                    });
                });
            } else {
                this.$Message("未选择记录");
            }
        },
        cancelAudit() {
            let checked = this.datas.filter(value => value._checked);
            if (checked.length) {
                const vdates = new Set(checked.map(val => val.year + "" + val.month))
                if (vdates.size > 1) {
                    this.$Message("不能跨期操作~");
                    return
                }
                this.$Confirm("亲，确认要取消审核吗?").then(() => {
                    this.loading = true;
                    this.$api.voucher.cancelAudit({
                        checked: checked.map(value => value.id),
                        year: checked[0].year,
                        month: checked[0].month
                    }).then(() => {
                        this.loadList();
                        this.$Message("取消审核成功！");
                    }).finally(() => {
                        this.loading = false;
                    });
                });
            } else {
                this.$Message("未选择记录");
            }
        },
        printerSetting(data) {
            let layerId = layer.open({
                title: "打印设置",
                shadeClose: false,
                closeBtn: false,
                zIndex: 1000,
                area: ['700px', '550px'],
                content: h(VoucherPrinterSetting, {
                    onClose: () => {
                        layer.close(layerId);
                    },
                    onSuccess: () => {
                        layer.close(layerId);
                        this.loadList()
                    }
                })
            });
        },
        doUpload() {
            let layerId = layer.open({
                title: "批量导入凭证",
                shadeClose: false,
                closeBtn: false,
                zIndex: 1000,
                area: ['500px', 'auto'],
                content: h(VoucherImport, {
                    onClose: () => {
                        layer.close(layerId);
                    },
                    onSuccess: () => {
                        layer.close(layerId);
                        this.loadList()
                    }
                })
            });
        },
        resetForm() {
            this.params = clone(this.cloneParams, true)
        }
    },
    created() {
        this.params.voucherDate.start = manba(this.currentAccountSets.currentAccountDate).format("YYYY-M");
        this.params.voucherDate.end = this.params.voucherDate.start;
        this.cloneParams = clone(this.params, true);
        this.loadList();
    }
};
</script>
