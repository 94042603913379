<template>
    <div class="modal-column" style="top: 63px;left: 296.5px;z-index: 799;">
        <div class="zmsundialog-content">
            <div class="mpBox">
                    <div class="dmBox">
                        <div class="zmsunform-row zmsunform-row-r1">
                            <div class="zmsunform-comm-field zmsunform-comm-required zmsunform-fie-text">
                                <label class="zmsunform-comm-label">模板名称</label>
                                <div class="zmsunform-comm-fieldcon">
                                    <input type="text" autocomplete="off" placeholder="输入模板名称" v-model="formData.name">
                                </div>
                                <span class="zmsunform-comm-prompt" style="display: none;">
                                    <span class="zmsunform-comm-prompt-ico"></span>
                                    <span class="zmsunform-comm-prompt-txt"></span></span>
                                <div class="zmsunform-fie-text-intellisence-container" style="display:none;">
                                    <div class="zmsunform-fie-text-intellisence-items"></div>
                                </div>
                            </div>
                        </div>
                        <voucher-table class="zmsunform-voucher" @update="onItemsUpdate" :voucherDetails="voucherDetails"/>
                    </div>
                </div>
        </div>
        <div class="modal-column-between">
            <vxe-button icon="fa fa-close" @click="$emit('close')">取消</vxe-button>
            <vxe-button status="primary" icon="fa fa-check" @click="doSaveTpl()">保存模板</vxe-button>
        </div>
    </div>
</template>

<script>
    import VoucherTable from "../../components/VoucherTable";
    import voucher from "@/api/voucher";
    import {add, floor, pick} from "xe-utils";
    import {message} from "heyui.ext";

    /**
     * <p>****************************************************************************</p>
     * <p><b>Copyright © 2010-2023杭州财汇餐谋科技有限公司All Rights Reserved<b></p>
     * <ul style="margin:15px;">
     * <li>Description : </li>
     * <li>Version     : 1.0</li>
     * <li>Creation    : 2023年01月11日</li>
     * <li>@author     : ____′↘夏悸</li>
     * </ul>
     * <p>****************************************************************************</p>
     */
    export default {
        name: "VoucherTplDialog",
        components: {VoucherTable},
        props: {
            entity: ''
        },
        computed: {
            voucherItems() {
                return this.voucherTable.voucherItems;
            },
        },
        data() {
            return {
                loading: false,
                formData: {
                    id: '',
                    name: '',
                    details: []
                },
                voucherItems: [],
                voucherTable: {},
                voucherDetails: []
            }
        },
        methods: {
            loadList() {
                this.loading = true;
                voucher.load(this.formData.id).then(({data}) => {
                    data.details.forEach(val => {
                        val.originalCreditAmount = val.creditAmount;
                        val.originalDebitAmount = val.debitAmount;
                    })
                    this.voucherDetails = data.details;
                }).finally(() => this.loading = false)
            },
            onItemsUpdate(val) {
                this.voucherItems = val;
                this.voucherTable.jfTotal = floor(val.reduce((total, row) => add(total, (row.debitAmount || 0)), 0), 2)
                this.voucherTable.dfTotal = floor(val.reduce((total, row) => add(total, (row.creditAmount || 0)), 0), 2)
            },
            doSaveTpl() {
                if (!this.formData.name) {
                    this.$Message("亲，模板名称不能为空！");
                    return false;
                }
                if (!this.voucherItems.length) {
                    this.$Message("亲，借贷金额不能为空！");
                    return false;
                }
                if (this.voucherTable.jfTotal !== this.voucherTable.dfTotal) {
                    this.$Message("亲，借贷不平衡！");
                    return false;
                }
                this.loading = true;
                this.formData.details = this.voucherItems;
                voucher.saveTpl(Object.assign({}, this.formData)).then(() => {
                    message.success("模板保存成功~");
                }).finally(() => {
                    this.loading = false;
                    this.$emit('close');
                })
            }
        },
        created() {
        },
        mounted() {
            if (this.entity) {
                this.formData.id = this.entity.id
                this.formData.name = this.entity.name
                this.loadList();
            }
        }
    }
</script>

<style lang="less" scoped>
    .zmsundialog-content {
        position: relative;
        background-color: #FFF;
    }

    .mpBox {
        width: 1005px;
        height: 560px;
        margin: 20px auto 0;
    }

    .mpTit {
        font-size: 20px;
        height: 36px;
        line-height: 36px;
        font-weight: bold;
    }

    .dmBox {
        border: 1px solid #eff0f0;
        padding-top: 20px;
    }
    .zmsunform-row-r1 {
        position: relative;
        height: 30px;
        margin-left: 20px;
    }
    .zmsunform-comm-field input {
        outline: 0
    }
    .zmsunform-comm-field input[type="text"],.zmsunform-comm-field input[type="password"] {
        padding: 2px 5px;
        line-height: 24px;
        height: 24px;
        color: #464646;
        float: left;
        border: none;
        border-bottom: 1px solid #D7D7D7;
        width: 190px
    }
    .zmsunform-fie-text-has-btn-clear input[type="text"],.zmsunform-fie-text-has-btn-tiptog input[type="text"] {
        padding-right: 22px;
        width: 173px
    }
    .zmsunform-comm-field input[type="text"][disabled] {
        background-color: #f5f5f5;
        color: #4f5254;
        border: none;
        border-bottom: 1px solid #D7D7D7
    }
    .zmsunform-comm-field input[type="text"]:focus {
        border-color: #4265D6
    }
    .zmsunform-comm-field input[type="text"]::-moz-placeholder {
        color: #b5b5b5
    }
    .zmsunform-comm-field input[type="text"]::-webkit-input-placeholder {
        color: #b5b5b5
    }
    .zmsunform-comm-label {
        float: left;
        padding-right: 4px;
    }
    .zmsunform-comm-fieldcon {
        position: relative;
        float: left;
    }
    .zmsunform-voucher {
        height: 500px;
        overflow-y: auto;
        overflow-x: hidden;
    }


</style>
