<template>
  <div class="v-table-td-credit">
    <v-table-number ref="numberRef" field="creditAmount" :rowIndex="rowIndex" @onEnter="onEnter" @cancel-edit="cancelEdit" :number="number"/>
  </div>
</template>
<script setup>
import VTableNumber from "@components/VoucherTable/VTableNumber.vue";
import {defineExpose, getCurrentInstance, onMounted, ref, watch} from "vue";
import {getVTable} from "@components/VoucherTable/VtUtil";
import {divide, round} from "xe-utils";

const props = defineProps(['credit', 'rowIndex', 'detail']);

const number = ref(null)
const numberRef = ref(null)
let VTable = null;

const cancelEdit = (numVal) => {
	number.value = numVal;
	props.detail.price = round(divide(numVal, props.detail.num), 2);
	VTable.exposed.updateFiled(props.rowIndex, 'creditAmount', numVal);
}

const onEnter = () => {
	cancelEdit();
	VTable.exposed.next(props.rowIndex + 1, 'summary');
}

watch(() => props.credit, (val) => {
	number.value = val;
})

onMounted(() => {
	VTable = getVTable(getCurrentInstance())
	number.value = props.credit;
})

defineExpose({
	doEdit: () => numberRef.value.doEdit(),
	endEdit: () => numberRef.value.cancelEdit()
})
</script>
<style scoped lang="less">
.v-table {

  &-td {
    &-credit {
      width: 221px;
      background-image: url("../../assets/moneyUint.png");
    }
  }
}
</style>
