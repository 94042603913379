<template>
    <app-content class="h-panel">
        <div style="display: flex;flex-direction: column;justify-content: center;align-items: center">
            <div style="width: 80%;margin-top: 5%">
                <Steps :datas="data" :step="step" :contentLayout="contentLayout"></Steps>
            </div>
            <div v-if="step==0" style="height: 500px;width: 50%;display:flex;flex-direction: column;justify-content: center;align-items: center">
                <Form
                    ref="form"
                    :labelPosition="right"
                    :labelWidth="120">
                    <FormItem label="当前套账:">
                        <div>{{ currentAccountSets.companyName }}</div>
                    </FormItem>
                    <FormItem label="授权老板:">
                        <Input v-model="model.phone"/>
                    </FormItem>
                </Form>
                <div style="display:flex;justify-content: center;align-items: center;margin-top: 50px">
                    <Button color="primary" @click="step++">保存</Button>
                    <Button  style="margin-left: 40px" @click="goback">取消</Button>
                </div>
            </div>
            <div v-if="step==1" style="height: 500px;width: 50%;display:flex;flex-direction: column;justify-content: center;align-items: center">
                    <div style="display: flex">
                        <div style="display: flex;flex-direction: column">
                            <div style="font-size: 24px;margin-top: 15px">关注公众号【缔造梦】</div>
                            <div style="font-size: 16px;margin-top: 10px">
                                微信扫码关注
                            </div>
                        </div>
                        <div style="display: flex;justify-content: center;align-items: center;margin-left: 20px">
                            <img src="../../assets/boss_code.png" style="height: 180px;width: 180px">
                        </div>
                    </div>

                <div style="display:flex;justify-content: center;align-items: center;margin-top: 50px">
                    <Button   @click="step--">上一步</Button>
                    <Button color="primary" style="margin-left: 40px" @click="step++">已关注，下一步</Button>
                </div>
            </div>

            <div v-if="step==2" style="height: 500px;width: 50%;display:flex;flex-direction: column;justify-content: center;align-items: center">
                    <div style="display: flex;flex-direction: column">
                        <div style="font-size: 24px;margin-top: 15px">将公众号推送给老板</div>
                        <div style="font-size: 16px;margin-top: 10px">
                            推荐朋友>关注成功
                        </div>
                    </div>
                <div style="display:flex;justify-content: center;align-items: center;margin-top: 50px">
                    <Button   @click="step--">上一步</Button>
                    <Button color="primary" style="margin-left: 40px" @click="step++">已推荐关注，下一步</Button>
                </div>
            </div>
            <div v-if="step==3" style="height: 500px;width: 50%;display:flex;flex-direction: column;justify-content: center;align-items: center">
                <div style="display: flex;flex-direction: column">
                    <div style="font-size: 24px;margin-top: 15px">绑定【缔造梦】帐号</div>
                    <div style="font-size: 16px;margin-top: 10px">
                        登陆已授权账号>绑定成功
                    </div>
                </div>
                <div style="display:flex;justify-content: center;align-items: center;margin-top: 50px">
                    <Button   @click="step--">上一步</Button>
                    <Button color="primary" style="margin-left: 40px" @click="goback">完成绑定，下一步</Button>
                </div>
            </div>
        </div>

    </app-content>
</template>

<script>


import {mapState} from "vuex";
import {right} from "core-js/internals/array-reduce";

export default {
    name: 'Approve',
    data() {
        return {
            model:{
              phone:undefined,
            },
            contentLayout: 'transverse',
            data: [
                {key: 'a', title: '授权看帐', desc: ''},
                {key: 'b', title: '关注公众号', desc: ''},
                {key: 'c', title: '推送给老板', desc: ''},
                {key: 'd', title: '成功绑定账号', desc: ''}
            ],
            step: 0
        };
    },
    computed: {
        ...mapState(['currentAccountSets', 'User']),
    },
    watch: {},
    methods: {
        right,
        goback() {
            this.$store.commit('newTab', {key: "BossSee", title: "账套"})

        },

    },
    mounted() {
    }
};
</script>
<style>
.bossItemBg {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    width: 550px;
    height: 288px;
    background: rgba(47, 168, 253, 0.06);
    box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.16);
    border-radius: 0px 0px 4px 4px;
}
</style>
