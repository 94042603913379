<template>
	<div @click="openLayer">
		<slot/>
	</div>
</template>

<script>/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
import {layer} from "@layui/layer-vue";
import {routeList} from "@/router";
import {h} from "vue";

export default {
	name: "RouterLink",
	emits: ['success'],
	props: {
		to: Object,
		width: {
			type: String,
			default: "50vw"
		},
	},
	data() {
		return {
			title: ""
		}
	},
	methods: {
		openLayer() {
			// return
			let find = routeList.find(value => value.name === this.to.name);
			this.title = find.title;
			let layerId = layer.drawer({
				title: this.title,
				shadeClose: true,
				zIndex: 999,
				area: [this.width, '100vh'],
				content: h(find.component, Object.assign(this.to.params, {
					onSuccess: (args) => {
						console.log('sucesee')
						this.$emit('success', args)
					},
					onClose: (args) => {
						//  console.log('关闭1',args)
						layer.close(layerId);
					}
				})),
				onClose: (args) => {
					// console.log('关闭2',args)
					this.$emit('success', args)
				}
			});
		}
	},
}
</script>
