<template>
    <div class="modal-column">
        <div class="modal-column-full-body">
            <vxe-table height="200" ref="itemsTable" :data="items" 
                       :edit-config="{trigger:'click',mode:'row', showIcon: false}" :edit-rules="validConfig"
                       :header-row-class-name="headerRowClassName"
                       align="center">
                <vxe-column width="80" align="center">
                    <template #default="{row,rowIndex}">
                        <span @click="pushItem(row)" class="fa fa-plus mr-16px text-hover"/>
                        <span @click="popItem(row)" class="fa fa-minus red-color text-hover"/>
                    </template>
                </vxe-column>
                <vxe-column title="运算符号" field="calculation" width="90"
                            :edit-render="{name:'$select',options:[{label:'+',value:'+'},{label:'-',value:'-'}]}"/>
                <vxe-column title="会计科目" field="subjectId"
                            :edit-render="{name:'$select',options:subjectList,props:{filterable:true}}">
                    <template #default="{row,rowIndex}">
                        {{ subjectMap[row.subjectId] }}
                    </template>
                </vxe-column>
                <vxe-column title="辅助核算" field="auxiliary" width="160" :edit-render="{}"></vxe-column>
                <vxe-column title="取数规则" :width="180" field="accessRules">
                    <template #default="{row}">
                        <Select class="access-rule" v-model="row.accessRules" :datas="accessRuleList" :deletable="false"/>
                    </template>
                </vxe-column>
                <vxe-column title="运算" field="operation" width="90"
                            :edit-render="{name:'$select',options:[{label:'*',value:'*'},{label:'/',value:'/'}]}"/>
                <vxe-column title="数值" field="percentage" width="110" :edit-render="{}">
                    <template #edit="{ row }">
                        <vxe-input v-model="row.percentage" type="text"></vxe-input>
                    </template>
                </vxe-column>
            </vxe-table>
        </div>
        <div class="modal-column-btn">
            <vxe-button :loading="loading" content="取消" @click="$emit('close')"/>
            <vxe-button :loading="loading" content="确认" status="primary" @click="doSubmit"/>
        </div>
    </div>
</template>

<script>
    /**
     * <p>****************************************************************************</p>
     * <p><b>Copyright © 2010-2022杭州财汇餐谋科技有限公司All Rights Reserved<b></p>
     * <ul style="margin:15px;">
     * <li>Description : </li>
     * <li>Version     : 1.0</li>
     * <li>Creation    : 2022年12月22日</li>
     * <li>@author     : ____′↘夏悸</li>
     * </ul>
     * <p>****************************************************************************</p>
     */
    export default {
        name: "CheckTemplateFormOne"
    }
</script>

<script setup>
    import {reactive, ref, toRefs} from "vue";
    import setting from "@/api/setting";
    import {heyuiConfig, message} from "heyui.ext";

    const emits = defineEmits(['close', 'success']);
    const props = defineProps(['entity']);
    const {entity} = toRefs(props);
    const loading = ref(false)
    const itemsTable = ref(null)

    const accessRuleList = ref([])

    const validConfig = reactive({
        subjectId: [{required: true, message: "请选择科目"}],
    });

    const accessRules = () => {
        accessRuleList.value =  heyuiConfig.getDict("accessRules1")
    }
    accessRules();

    const items = reactive(entity.value.length > 0 ? entity.value : [
        {calculation: '+', subjectId: null, subjectTitle: null, auxiliary: null, accessRules: '余额', operation: null, percentage: null},
        {calculation: '+', subjectId: null, subjectTitle: null, auxiliary: null, accessRules: '余额', operation: null, percentage: null},
    ])

    const dataList = ref([])
    const doSubmit = () => {
        let {fullData} = itemsTable.value.getTableData();
        fullData.forEach(row => {
            row.subjectTitle = subjectMap[row.subjectId];
            dataList.value.push(row)
        })
        emits('success', dataList.value)
    }

    const pushItem = (row) => {
        itemsTable.value.insertAt({calculation: '+', subjectId: null, subjectTitle: null, auxiliary: null, accessRules: '余额', operation: null, percentage: null}, row);
    }

    const popItem = (row) => {
        let {fullData} = itemsTable.value.getTableData();
        if (fullData.length > 2) {
            itemsTable.value.remove(row);
        }
    }

    const subjectList = ref([]);
    const subjectMap = reactive({});
    setting.subject.voucherSelect({all: false}).then(({data}) => {
        subjectList.value = data.map(val => {
            subjectMap[val.id] = val.code + "-" + val.fullName;
            return {
                value: val.id,
                label: val.code + "-" + val.fullName,
            }
        })
    })

    const headerRowClassName = ({ column }) => {
        return 'row-class'
    }

</script>

<style lang="less" scoped>
    ::v-deep(.vxe-header--row.row-class) {
        background-color: #2080f0;
        color: #fff;
    }
    .zmsunform-comm-field {
        display: inline-block;
        margin: 6px 10px;
        position: relative;
        line-height: 30px;
    }

    .zdy-code {
        width: 268px;
    }

    .zmsunform-comm-field input {
        outline: 0
    }

    .zmsunform-comm-field input[type="text"], .zmsunform-comm-field input[type="password"] {
        padding: 2px 5px;
        line-height: 24px;
        height: 24px;
        color: #464646;
        float: left;
        border: none;
        border-bottom: 1px solid #D7D7D7;
        width: 190px
    }

    .zmsunform-fie-text-has-btn-clear input[type="text"], .zmsunform-fie-text-has-btn-tiptog input[type="text"] {
        padding-right: 22px;
        width: 173px
    }

    .zmsunform-comm-field input[type="text"][disabled] {
        background-color: #f5f5f5;
        color: #4f5254;
        border: none;
        border-bottom: 1px solid #D7D7D7
    }

    .zmsunform-comm-field input[type="text"]:focus {
        border-color: #4265D6
    }

    .zmsunform-comm-field input[type="text"]::-moz-placeholder {
        color: #b5b5b5
    }

    .zmsunform-comm-field input[type="text"]::-webkit-input-placeholder {
        color: #b5b5b5
    }

    .zmsunform-comm-label {
        line-height: 32px;
        float: left;
        padding-right: 4px;
    }

    .zmsunform-comm-fieldcon {
        position: relative;
        float: left;
    }

    .zdy-type {
        width: 188px;
    }

    .zmsunform-selectmenu-main {
        width: 125px;
        position: relative;
    }

    .type-tips {
        vertical-align: 16px;
        margin-left: 20px;
    }

    .access-rule {
        width: 160px;
    }
</style>
