<template>
  <div class="h-full p-20px">
    <div class="flex flex-column h-full">
      <div class="lay-title">
        <div class="lay-title-txt">产品激活</div>
      </div>
      <div class="flex-1 h-0px">
        <p class="tips">如您在服务商处购买了激活码，请在此激活：</p>
        <div class="layui-input-group">
          <div class="layui-form-item">
            <span class="layui-form-label">激活码</span>
            <Input class="layui-input-inline" :clearable="false" v-model="code" placeholder="请输入激活码"/>
          </div>
        </div>
        <div class="layui-input-group">
          <div class="layui-form-item">
            <label class="layui-form-label"></label>
            <Button :loading="loading" :disabled="!code" class="layui-input-inline layui-btn layui-btn-primary th-btn-hole hvr-radial-out" @click="doConfirm">立即激活</Button>
          </div>
        </div>
        <div class="notice">
          <blockquote class="layui-elem-quote">注意事项</blockquote>
          <ol>
            <li>如果您的激活码连续输入错误，将锁定您的账号，请及时联系客服解决</li>
            <li>云应用产品激活后，可立即使用</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {mapMutations, mapState} from 'vuex';
  import EnterpriseWorkbench from '@/api/EnterpriseWorkbench'
  import {message} from "heyui.ext";

  export default {
    name: 'ProductActivation',
    data() {
      return {
        loading: false,
        code: null,
      };
    },
    computed: {
      ...mapState(['currentAccountSets', 'currentTab']),
    },
    methods: {
      ...mapMutations(['newTab']),
      doConfirm() {
        if (this.code) {
          this.loading = true;
          EnterpriseWorkbench.proActive(this.code).then(({data}) => {
            this.updateAccount(data);
            message("激活成功");
            this.$emit('success');
          }).finally(() => this.loading = false)
        }
      }
    },
    created() {
      // this.loadList();
    },
  };
</script>

<style lang="less" scoped>
  .lay-title {
    margin: 0 0 15px 0;
    border-radius: 5px;
    border-color: #ffffff ;
    background: #ffffff ;
    /*border-bottom: 1px solid;*/
  }
  .lay-title-txt {
    font-size: 16px;
    margin-left: 20px;
    height: 50px;
    line-height: 50px;
    display: inline-block;
    position: relative;
    border-bottom: 2px solid #006eff;
  }
  .tips {
    padding: 30px 50px;
  }
  .layui-input-group {
    padding: 10px 50px;
    margin-bottom: 15px;
  }
  .layui-form-item {
    margin-bottom: 15px;
  }
  .layui-form-label {
    float: left;
    display: block;
    padding: 9px 15px;
    width: 80px;
    font-weight: 400;
    line-height: 20px;
    text-align: right;
  }
  .layui-input-inline {
    float: left;
    width: 210px;
    margin-right: 10px;
    height: 38px;
    border-width: 1px;
    border-style: solid;
    background-color: #fff;
    border-radius: 4px;
  }

  .h-btn[disabled] {
    cursor: not-allowed;
    background-color: #f5f5f5;
    border-color: #d3d3d3;
    color: #999999;
  }

  .layui-btn {
    display: inline-block;
    height: 38px;
    line-height: 38px;
    padding: 0 18px;
    background-color: #009688;
    color: #fff;
    white-space: nowrap;
    text-align: center;
    font-size: 14px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  .layui-btn-primary {
    border: 1px solid #C9C9C9;
    background-color: #fff;
    color: #555;
  }

  .th-btn-hole {
    color: #537791;
    border: 1px solid;
    border-color: #537791;
  }

  .hvr-radial-out {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0,0,0,0);
    position: relative;
    overflow: hidden;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: .3s;
    transition-duration: .3s
  }

  .hvr-radial-out:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #4391ec;
    border-radius: 100%;
    -webkit-transform: scale(0);
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out
  }

  .hvr-radial-out:active,.hvr-radial-out:focus,.hvr-radial-out:hover {
    color: #fff !important
  }

  .hvr-radial-out:active:before,.hvr-radial-out:focus:before,.hvr-radial-out:hover:before {
    -webkit-transform: scale(2);
    transform: scale(2)
  }

  .notice {
    padding: 30px 50px;
  }
  .layui-elem-quote {
    margin-bottom: 10px;
    padding: 15px;
    line-height: 22px;
    border-left: 5px solid #009688;
    border-radius: 0 2px 2px 0;
    background-color: #ffffff;
  }
  .notice ol {
    padding: 10px 20px;
  }
  .notice ol li {
    list-style: decimal;
    line-height: 30px;
  }
</style>

