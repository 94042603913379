<template>
  <app-content class="h-panel">
    <Tabs class="px-20px" :datas="subjectType" v-model="selected" @change="change"></Tabs>
    <div class="padding-right-left padding-top">
      <span class="dark4-color">*提示：按 Ctrl + F 键并输入科目编码或者科目名称可以查找科目。</span>
      <div class="pull-right">
        <Checkbox v-model="status" class="mr-16px">显示停用科目</Checkbox>
        <Button @click="encodingSetting">编码设置</Button>
        <Button @click="doUpload" icon="fa fa-upload">
          导入
        </Button>
        <a class="h-btn" href="/api/subject/download">
          <i class="fa fa-download"></i>
          导出
        </a>
          <Button @click="">明细转辅助</Button>
          <Button @click="">生成凭证</Button>
      </div>
    </div>
    <div class="h-panel-body padding">
      <vxe-table ref="subjectTable"
                 highlight-current-row
                 :data="datas" 
                 :loading="loading"
                 :row-class-name="rowClass"
                 :row-config="{keyField:'code'}"
                 :tree-config="{transform: true, rowField: 'id', parentField: 'parentId'}">
        <vxe-column title="科目编码" tree-node field="code"/>
        <vxe-column title="科目名称" field="name"/>
        <vxe-column title="助记码" field="mnemonicCode"/>
        <vxe-column title="余额方向" field="balanceDirection" align="center" :width="100"/>
        <vxe-column title="数量核算" field="unit" align="center" :width="100"/>
        <vxe-column title="外币核算" field="currencyAccounting">
          <template #default="{row:{currencyAccountingNames}}">
            {{ currencyAccountingNames.join(',') }}
          </template>
        </vxe-column>
        <vxe-column title="辅助核算" field="auxiliaryAccounting">
          <template #default="{row:{auxiliaryAccountingNames}}">
            {{ auxiliaryAccountingNames.join(',') }}
          </template>
        </vxe-column>
        <vxe-column title="状态" align="center" :width="100" field="status">
          <template #default="{row}">
            {{ row.status ? "正常" : "不可用" }}
          </template>
        </vxe-column>
        <vxe-column title="操作" :width="150" align="center">
          <template #default="{row}">
            <div class="actions">
              <span @click="create(null,row)" v-if="row.status && row.level < currentAccountSets.encoding.length">新增</span>
              <span @click="edit(row,null)">编辑</span>
              <span v-if="row.status && !row.systemDefault" @click="remove(row)">删除</span>
            </div>
          </template>
        </vxe-column>
      </vxe-table>
    </div>
  </app-content>
</template>

<script>

import {mapState} from 'vuex';
import {layer} from "@layui/layer-vue";
import {h} from "vue";
import SubjectForm from "@/views/setting/subject/SubjectForm";
import EncodingSettingForm from "@/views/setting/subject/EncodingSettingForm";
import SubjectImport from "@/views/setting/subject/SubjectImport";
import setting from "@/api/setting";

export default {
  name: "Subject",
  data() {
    return {
      datas: [],
      type: "资产",
      selected: '资产',
      loading: false,
      status: false,
      lastCode: null
    };
  },
  computed: {
    ...mapState(['currentAccountSets', 'subjectType'])
  },
  watch: {
    type() {
      this.loadList()
    },
    numberAccounting(val) {
      if (!val) {
        this.form.unit = '';
      }
    },
    status() {
      this.loadList();
    }
  },
  methods: {
    encodingSetting() {
      let layerId = layer.open({
        title: "编码设置",
        shadeClose: false,
        closeBtn: false,
        zIndex: 99,
        area: ['700px', 'auto'],
        content: h(EncodingSettingForm, {
          onClose: () => {
            layer.close(layerId);
          },
          onSuccess: () => {
            layer.close(layerId);
            this.loadList();
          }
        })
      });
    },
    loadList() {
      this.loading = true;
      setting.subject.list(this.type).then(({data}) => {
        this.datas = (data || []).filter(val => !this.status ? val.status : true);
        this.$nextTick(() => {
          this.$refs.subjectTable.setAllTreeExpand(true);
          this.$refs.subjectTable.refreshColumn();

          if (this.lastCode) {
            this.$nextTick(() => {
              let rowById = this.$refs.subjectTable.getRowById(this.lastCode);
              this.$refs.subjectTable.scrollToRow(rowById);
              this.$refs.subjectTable.setCurrentRow(rowById);
            })
          }
        })
      }).finally(() => this.loading = false);
    },
    create(subject = null, parent = null, used = false) {
      if (subject) {
        parent = this.$refs.subjectTable.getParentRow(subject);
      }
      let maxCode;
      if (!parent) {
        let map = this.datas.filter(value => !value.parentId).map(value => value.code);
        maxCode = map[map.length - 1];
      }
      this.lastCode = null;
      let layerId = layer.drawer({
        title: "科目信息",
        shadeClose: false,
        closeBtn: false,
        zIndex: 1000,
        area: ['calc(100vw - 180px)', '100vh'],
        content: h(SubjectForm, {
          maxCode, subject, parent, used,
          type: this.type,
          onClose: () => {
            layer.close(layerId);
          },
          onSuccess: (code) => {
            this.lastCode = code;
            this.loadList();
            layer.close(layerId);
          }
        })
      });
    },
    change(data) {
      this.type = data.title;
    },
    remove(data) {
      this.$Confirm("确认删除?").then(() => {
        setting.subject.delete(data.id).then(() => {
          this.loadList();
        })
      })
    },
    edit(row) {
      setting.subject.checkUse(row.id).then(({data}) => {
        this.create(row, null, data);
      });
    },
    doUpload() {
      let layerId = layer.open({
        title: "科目导入",
        shadeClose: false,
        closeBtn: false,
        zIndex: 1000,
        area: ['calc(100vw - 180px)', 'auto'],
        content: h(SubjectImport, {
          onClose: () => {
            layer.close(layerId);
          },
          onSuccess: () => {
            layer.close(layerId);
          }
        })
      });
    },
    rowClass({row}) {
      if (!row.status) {
        return 'gray-color'
      }
    }
  },
  created() {
    this.selected = this.subjectType[0];
  },
  mounted() {
    this.loadList();
  }
};
</script>

<style scoped>
div.h-tabs.h-tabs-card {
  border-bottom: 1px solid #52abc50d;
}

div.h-panel-body {
  padding-top: 0px;
}

div.h-panel-bar {
  border-bottom: 0px solid #eeeeee;
  border-top: 1px solid #eeeeee;
}
</style>
