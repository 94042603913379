<template>
  <div class="p-16px">
    <Form mode="block" :model="model" :rules="validationRules" ref="form">
      <FormItem label="原密码" prop="original">
        <Input type="password" v-model="model.original"/>
      </FormItem>
      <FormItem label="新密码" prop="newPassword">
        <Input type="password" v-model="model.newPassword"/>
      </FormItem>
      <FormItem label="重复密码" prop="repeatPassword">
        <Input type="password" v-model="model.repeatPassword"/>
      </FormItem>
      <FormItem>
        <Button @click="save" color="primary" :loading="isLoading">保存</Button>
      </FormItem>
    </Form>
  </div>
</template>
<script>
export default {
  name: "ChangePassword",
  data() {
    return {
      isLoading: false,
      model: {
        original: "",
        newPassword: "",
        repeatPassword: "",
      },
      validationRules: {
        required: ["original", "newPassword", "repeatPassword"],
        combineRules: [{
          refs: ['newPassword', 'repeatPassword'],
          valid: {
            valid: 'equal',
            message: '两次输入的密码不一致'
          }
        }]
      }
    };
  },
  methods: {
    save() {
      let validResult = this.$refs.form.valid();
      if (validResult.result) {
        this.isLoading = true;
        this.$api.common.updatePwd(this.model).then(() => {
          this.$Message('更新密码成功');
          this.isLoading = false;
          window.location.replace("/");
        }).catch(() => {
          this.isLoading = false;
        });
      }
    }
  },
  computed: {}
};
</script>
