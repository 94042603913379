<template>
  <div class="modal-column">
    <div class="modal-column-body">
      <div>
        <p>1、您将把账套数据恢复到备份文件所在的状态，<b class="red-color">此操作不可回退，请谨慎操作</b>。</p>
        <p>2、为保证恢复数据的完整性，<b class="red-color">请确保账套里的其他用户已经退出系统</b>。</p>
      </div>
      <div class="pl-100px">
        <Radio v-model="checked" class="inline" :datas="{'1':'不覆盖本账套数据，创建新账套数据','2':'覆盖本账套数据'}"></Radio>
      </div>
    </div>
    <div class="modal-column-btn">
      <Button :loading="loading" @click="$emit('close')">取消</Button>
      <Button :loading="loading" @click="onConfirm" color="red">确认恢复</Button>
    </div>
  </div>
</template>

<script>/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
import setting from "@/api/setting";
import {confirm} from "heyui.ext";

export default {
  name: "RecoverForm",
  props: {
    entity: Object
  },
  data() {
    return {
      checked: '1',
      loading: false
    }
  },
  methods: {
    onConfirm() {
      confirm({
        title: "重要提示",
        content: "再次确认是否要恢复当前备份!!!",
        async: true,
        onConfirm: (resolve) => {
          setting.backup.recover(this.entity.id, this.checked).then(() => {
            window.location.replace("/")
            this.$emit('close');
          }).finally(() => resolve(true))
        }
      })

    }
  },
}
</script>

<style scoped lang="less">
.inline {
  :deep(.h-radio-label) {
    display: block;
  }
}
</style>
