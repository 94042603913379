<template>
    <div class="h-full p-20px">
        <div class="h-full  flex flex-column">
                <Tabs :datas="{'客户':'客户','供应商':'供应商','账户':'账户','其他':'其他收支'}"
                      v-model="ioType"
                      class="bg-white-color px-16px"/>

            <div v-if="ioType=='账户'" class="h-full  flex flex-column">
                <vxe-toolbar class="px-16px">
                    <template #buttons>
                        <div style="font-size: 16px;color: #999999">
                            提示：实时同步显示关联的进销存账户信息，匹配的会计科目会自动生成凭证
                        </div>
                    </template>
                    <template #tools>
                        <Button :loading="dataLoading" @click="backUp" color="">批量指定科目</Button>
                    </template>
                </vxe-toolbar>
                <div class="flex-1">
                    <vxe-table border="none" height="auto" ref="xTable" :loading="dataLoading" show-overflow stripe
                               :data="dataList">
                        <vxe-column  type="checkbox" />
                        <vxe-column title="编码" field="code" width="400"/>
                        <vxe-column title="进销存账户名称" field="name"/>
                        <vxe-column title="会计科目" field="subject"/>
                    </vxe-table>
                </div>

            </div>

        </div>
    </div>
</template>

<script>
/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
export default {
    name: "BakUp"
}
</script>

<script setup>
import {confirm, loading} from "heyui.ext";
import {ref, h} from "vue";
import setting from "@/api/setting";
import RecoverForm from "@/views/setting/RecoverForm";
import {layer} from "@layui/layer-vue";

const dataLoading = ref(false)
const afterAuth = ref(false)
const dataList = ref([{
    code:'1001',
    name:'现金',
    subject:'会计001',
}])
const fileInput = ref(null)
const ioType = ref('单据')

const loadList = () => {
    // dataLoading.value = true;
    // setting.backup.list().then(({data}) => {
    //     dataList.value = data;
    // }).finally(() => dataLoading.value = false)
}
loadList();

const backUp = () => {
    loading("备份中...")
    setting.backup.backup().then(() => {
        loadList();
    }).finally(() => loading.close())
}

const remove = (row) => {
    confirm({
        content: "确认删除此备份?",
        async: true,
        onConfirm(resolve) {
            loading("删除中...")
            setting.backup.delete(row.id).then(() => {
                loadList();
            }).finally(() => {
                loading.close();
                resolve(true);
            })
        }
    })
}

const recover = (entity) => {
    let layerId = layer.open({
        title: "恢复数据",
        shadeClose: false,
        closeBtn: false,
        zIndex: 1000,
        area: ['550px', 'auto'],
        content: h(RecoverForm, {
            entity,
            onClose: () => {
                layer.close(layerId);
            }
        })
    });
}

const uploadBackUp = () => {
    if (fileInput.value.files.length) {
        loading("上传中...")
        const formData = new FormData();
        formData.append("file", fileInput.value.files[0]);
        setting.backup.upload(formData).then(() => {
            loadList();
        }).finally(() => loading.close())
    }

}
</script>
