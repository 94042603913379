<template>
  <app-content class="h-panel">
    <div class="h-panel-bar">
      <span class="h-panel-title"><template v-if="auxiliary">{{ auxiliary.name }} -</template> 核算项目余额</span>
    </div>
    <Row type="flex" :space-x="10" class="margin-right-left margin-top">
      <Cell>
        <account-date-choose v-model="accountDate"/>
      </Cell>
      <Cell class="label">辅助类别：</Cell>
      <Cell>
        <Select type="object" v-model="auxiliary" :deletable="false" style="min-width: 100px" :datas="auxiliaryType" keyName="id" titleName="name"/>
      </Cell>
      <Cell>
        <Button :disabled="!auxiliary" :loading="loading" color="primary" @click="doSearch">查询</Button>
      </Cell>
      <Cell :flex="1" class="text-right" style="display: flex;justify-content: flex-end;align-items: center;">
        <!-- <div class="float-right">
          <Checkbox v-model="showNumPrice">显示数量金额</Checkbox>
        </div> -->
        <Checkbox class="mr-16px" >显示科目</Checkbox>
        <Checkbox class="mr-16px" >科目横向展示</Checkbox>

        <Checkbox class="mr-16px" v-model="showNumPrice">显示数量金额</Checkbox>
        <Checkbox class="mr-16px" >显示本年累计</Checkbox>
        <vxe-button @click="exportData" icon="fa fa-download" content="导出"/>
        <vxe-button @click="exportData" icon="fa fa-download" content="导出"/>
        <vxe-button @click="printData" icon="fa fa-print" content="打印"/>
      </Cell>
    </Row>
    <div class="h-panel-body" v-if="auxiliary">
      <div class="tablezero">
         <span>科目：所有</span>
      </div>
      <vxe-table ref="xTable" :data="dataList" stripe >
        <vxe-column :title="`${auxiliary.name}编码`" field="code">
          <template #default="{row}">
            <div :style="{'padding-left':(row.level)*10+'px'}">{{ row.code }}</div>
          </template>
        </vxe-column>
        <vxe-column :title="`${auxiliary.name}名称`" field="name">
          <template #default="{row}">
            <div :style="{'padding-left':(row.level)*10+'px'}">{{ row.name }}</div>
          </template>
        </vxe-column>
        <vxe-colgroup title="期初余额" align="center">
          <vxe-column title="借方" field="beginningDebitBalance" align="right" formatter="toMoney"/>
          <vxe-column title="贷方" field="beginningCreditBalance" align="right" formatter="toMoney"/>
        </vxe-colgroup>
        <vxe-colgroup title="本期发生额" align="center">
          <vxe-column title="借方" field="currentDebitAmount" align="right" formatter="toMoney"/>
          <vxe-column title="贷方" field="currentCreditAmount" align="right" formatter="toMoney"/>
        </vxe-colgroup>
        <vxe-colgroup title="期末余额" align="center">
          <vxe-column title="借方" field="endingDebitBalance" align="right" formatter="toMoney"/>
          <vxe-column title="贷方" field="currentCreditAmount" align="right" formatter="toMoney"/>
        </vxe-colgroup>
      </vxe-table>
    </div>
  </app-content>
</template>

<script>
import BooUtil from "@/views/accountbook/bookUtil";
import accountbook from "@/api/accountbook";
import {downloadBlob} from "download.js";
import manba from "manba";
import {mapState} from "vuex";

export default {
  name: "AuxiliaryAccountingBalance",
  data() {
    return {
      dataList: [],
      showNumPrice: false,
      loading: false,
      accountDate: null,
      auxiliary: null,
      auxiliaryItem: {},
      auxiliaryType: [],
      auxiliaryList: []
    }
  },
  computed: {
    ...mapState(['currentAccountSets'])
  },
  methods: {
    loadAuxiliaryType() {
      this.$api.setting.accountingCategory.list().then(({data}) => {
        this.auxiliaryType = data;
        if (data.length) {
          this.auxiliary = data[0];
        }
      });
    },
    loadList() {
      if (this.auxiliary) {
        this.loading = true;
        this.$api.accountbook.loadAuxiliaryBalance({
          accountDate: this.accountDate,
          auxiliaryId: this.auxiliary.id
        }).then(({data}) => {
          this.dataList = data;
        }).finally(() => {
          this.loading = false;
        });
      }
    },
    doSearch() {
      this.loadList();
    },
    exportData() {
      const utils = new BooUtil(this.$refs.xTable, '\n')
      accountbook.download({header: utils.header, dataList: utils.dataList}).then((data) => {
        downloadBlob(`${this.auxiliary ? this.auxiliary.name + '-' : ''}${manba(this.accountDate).format("YYYY年MM期")}-核算项目余额.xls`, data)
      })
    },
    printData() {
      const utils = new BooUtil(this.$refs.xTable)
      const title = `${this.auxiliary ? this.auxiliary.name + '-' : ''}${manba(this.accountDate).format("YYYY年MM期")}-核算项目余额`
      accountbook.print({content: utils.printContent(title, this.currentAccountSets.companyName), rotate: this.showNumPrice}).then((data) => {
        const url = URL.createObjectURL(data);
        window.open(url, '账簿')
      })
    }
  },
  mounted() {
    this.loadAuxiliaryType();
  }
}
</script>

<style scoped lang="less">
.balance {
  width: 100%;
  border-collapse: collapse;

  td {
    padding: 0 8px;
    border: 1px solid #e2e2e2;
    font-size: 12px;
    height: 35px;

    &.tip {
      background-color: #FFEEEF;
    }
  }

  tbody tr:nth-child(even) {
    background-color: #f8fbf8;
  }

  tbody tr:hover {
    background-color: #F0F6FF;
  }

  .header {
    td {
      background-color: #F5F5F5;
      text-align: center;
      font-weight: bold;
    }
  }
}
</style>
