<template>
  <div class="frame-page h-full !m-0px p-20px flex flex-column">
    <vxe-toolbar class-name="px-8px">
      <template #buttons>
        <DropdownCustom ref="checkUpDropdown" button class="h-btn-primary" trigger="manual" @click.stop="checkUpDropdown.show()">
          <div>{{ showDate }}</div>
          <template #content>
            <Form style="padding: 10px">
              <FormItem class="!pb-5px" label="会计期间">
                <div class="flex items-center">
                  <Select :datas="monthList" class="!w-130px" v-model="params.start" :deletable="false"/>
                  <span class="mx-8px">至</span>
                  <Select :datas="monthList" class="!w-150px" v-model="params.end" :deletable="false"/>
                </div>
              </FormItem>
              <FormItem class="!pb-5px" label="账户">
                <Select placeholder="全部账户" :datas="accountList" keyName="id" titleName="name" filterable v-model.number="params.cashierAccountId"/>
              </FormItem>
              <FormItem label="币别">
                <Select placeholder="所有币别" :datas="currencyList" keyName="id" titleName="name" filterable v-model.number="params.currencyId"/>
              </FormItem>
              <FormItem class="!pb-5px">
                <Button color="primary" @click="doSearch();" :loading="loading">确定</Button>
                <Button @click="checkUpDropdown.hide()">取消</Button>
                <Button>重置</Button>
              </FormItem>
            </Form>
          </template>
        </DropdownCustom>
      </template>
      <template #tools>
        <vxe-button content="打印" icon="fa fa-print"/>
        <vxe-button content="导出" icon="fa fa-download"/>
      </template>
    </vxe-toolbar>
    <div class="bg-white-color p-8px mt-8px">账户：所有</div>
    <div class="flex-1">
      <vxe-table ref="xTable"  :data="dataList" height="auto" :tree-config="{expandAll:true}" :row-class-name="rowClassName">
        <vxe-column width="200" field="project" tree-node title="项目"/>
        <vxe-column field="name" title="名称"/>
        <vxe-column field="currencyName" title="币别"/>
        <vxe-column field="initBalance" title="期初余额"/>
        <vxe-column field="sr" title="借方(收入)"/>
        <vxe-column field="zc" title="贷方(支出)"/>
        <vxe-column field="balance" title="余额"/>
      </vxe-table>
    </div>
  </div>
</template>

<script>
/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
export default {
  name: "CheckUp"
}
</script>

<script setup>
import {reactive, ref, computed, nextTick} from "vue";
import Checkout from "@/api/checkout";
import {useStore} from "vuex";
import manba from "manba";
import CashierAccount from "@/api/CashierAccount";
import setting from "@/api/setting";
import {getDateDiff} from "xe-utils";
import {message} from "heyui.ext";
import CashierReport from "@/api/CashierReport";
import {layer} from "@layui/layer-vue";

const store = useStore();

const currentAccountDate = manba(store.state.currentAccountSets.currentAccountDate);
const startDate = ref(currentAccountDate.startOf(manba.MONTH).format("YYYY-MM"));
const endDate = ref(currentAccountDate.endOf(manba.MONTH).format("YYYY-MM"));

const xTable = ref(null);
const checkUpDropdown = ref(null);
const loading = ref(false);

const params = reactive({
  start: startDate,
  end: endDate,
  cashierAccountId: null,
  currencyId: null,
});

const showDate = computed(() => {
  if (params.start === params.end) {
    return manba(params.start).format("YYYY年MM月");
  }
  return manba(params.start).format("YYYY年MM月") + "-" + manba(params.end).format("YYYY年MM月");
})

const monthList = ref([]);
const accountList = ref([]);
const currencyList = ref([]);
const dataList = ref([]);

Checkout.list().then(({data}) => {
  monthList.value = data.map(val => {
    return {key: val.checkYear + "-" + val.checkMonth, title: val.checkYear + "年" + val.checkMonth + "月"}
  })
})

CashierAccount.list().then(({data}) => {
  accountList.value = data;
})

setting.currency.list().then(({data}) => {
  currencyList.value = data;
})

const loadList = () => {
  const loadId = layer.load(1);
  CashierReport.accountCheck(params).then(({data}) => {
    dataList.value = data;
    nextTick(() => {
      xTable.value.setAllTreeExpand(true);
      checkUpDropdown.value.hide();
    })
  }).finally(() => layer.close(loadId));
}
loadList();

const doSearch = () => {
  if (params.start !== params.end && !getDateDiff(params.start, params.end).done) {
    message.error("开始期间不能大于结束期间")
    return
  }

  loadList();
}

const rowClassName = ({level, row}) => {
  if (level === 0) {
    return 't-group';
  } else if (row.project === '差异' && row.initBalance !== row.balance) {
    return 't-diff'
  }
}
</script>
