<template>
  <div class="h-full p-20px">
    <div class="flex flex-column h-full">
      <div class="lay-title">
          <div class="lay-title-txt">应用列表</div>
      </div>
      <div class="flex-1 h-0px">
        <vxe-table ref="journalTable"
                   :checkbox-config="{visibleMethod:({ row }) => !row.init,checkMethod:({ row }) => !row.init}"
                   :loading="loading"
                   :data="dataResult.results"
                   height="auto"
                   align="center"
                   >
          <vxe-column type="checkbox" width="50" align="center" fixed="left"/>
          <vxe-column title="编号" field="code" width="180px"/>
          <vxe-column title="应用名称" field="applyName"/>
          <vxe-column title="应用版本" field="applyVersion" />
          <vxe-column title="开通日期" field="openDate"/>
          <vxe-column title="到期日期" field="expirationDate"/>
          <vxe-column title="操作">
            <template #default="{row}">
              <div class="actions">
                <span @click="enteringApplication(row['id'])">进入应用</span>
              </div>
            </template>
          </vxe-column>
        </vxe-table>
      </div>
      <vxe-pager @page-change="onPageChange"
               v-model:current-page="page.page"
               v-model:page-size="page.pageSize"
               :total="dataResult.total"
               :layouts="['PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'Sizes', 'FullJump', 'Total']">
      <template #left>
        <vxe-button @click="loadList()" type="text" size="mini" icon="fa fa-refresh" :loading="loading"></vxe-button>
      </template>
    </vxe-pager>
    </div>
  </div>
</template>
<script>
  import {mapMutations, mapState} from 'vuex';
  import EnterpriseWorkbench from '@/api/EnterpriseWorkbench'

  export default {
    name: 'ApplicationList',
    data() {
      return {
        loading: false,
        datas: [],
        dataResult: {
          results: [],
          total: 20
        },
        page: {
          page: 1,
          pageSize: 20
        },
      };
    },
    computed: {
      ...mapState(['currentAccountSets', 'currentTab']),
    },
    methods: {
      ...mapMutations(['newTab']),
      init() {
        if (this.currentAccountSets) {

        }
      },
      loadList () {
        this.loading = true
        EnterpriseWorkbench.list(Object.assign({}, this.page)).then(({data}) => {
          // this.dataResult = data;
          this.dataResult = {
            page: 1,
            pageSize: 20,
            total: 1,
            totalPage: 1,
            results: [
              { code: '001', applyName: '云财务', applyVersion: '标准版' , openDate: '2020-08-04 19:56:13', expirationDate: '终身使用'},
            ],
          }
        }).finally(() => this.loading = false)
      },
      onPageChange({currentPage, pageSize}) {
        this.page.page = currentPage;
        this.page.pageSize = pageSize;
        this.loadList();
      },
      enteringApplication(id) {
        localStorage.removeItem('LOGINSTATUS')
        this.$store.commit('newTab', {key: "Home", title: "首页"})
        window.location.replace('/')
      }

    },
    created() {
      this.init();
      this.loadList();
    },
  };
</script>

<style lang="less" scoped>
  .lay-title {
    margin: 0 0 15px 0;
    border-radius: 5px;
    border-color: #ffffff ;
    background: #ffffff ;
    /*border-bottom: 1px solid;*/
  }
  .lay-title-txt {
    font-size: 16px;
    margin-left: 20px;
    height: 50px;
    line-height: 50px;
    display: inline-block;
    position: relative;
    border-bottom: 2px solid #006eff;
  }
</style>

