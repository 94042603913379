<template>
  <div class="modal-column">
    <div class="modal-column-full-body">
      <Form ref="form" :model="form" :rules="{}" labelWidth="150">
        <!-- <div class="ml-20px font-bold mb-16px">基本信息</div> -->
        <div class="titlecss">基本信息</div>
        <div class="inline-form-item">
          <FormItem label="资产编码" prop="code" required>
            <Input type="text" v-model="form.code" :disabled="disabled"/>
          </FormItem>
          <FormItem label="资产名称" prop="name" required>
            <Input type="text" v-model="form.name"/>
          </FormItem>
          <FormItem label="资产类别" prop="assetsTypeId" required>
            <Select :deletable="false" :datas="assetsTypeList" keyName="id" titleName="name" filterable
                    v-model="form.assetsTypeId">
              <template v-slot:item="{ item }">
                {{ item.code }} {{ item.name }}
              </template>
            </Select>
          </FormItem>
        </div>
        <div class="inline-form-item">
          <FormItem label="增加方式" prop="addType" required>
            <Select :deletable="false" v-model="form.addType" :datas="{1:'购入',2:'在建工程转入',3:'其他'}"/>
          </FormItem>
          <FormItem label="开始使用日期" prop="startDate" required>
            <DatePicker v-model="form.startDate" :disabled="disabled"></DatePicker>
          </FormItem>
          <FormItem label="使用部门" prop="deptId" required>
            <Select :datas="deptList" filterable v-model="form.deptId" :disabled="disabled"/>
          </FormItem>
        </div>
        <div class="inline-form-item">
          <FormItem label="规格型号" prop="specification">
            <Input type="text" v-model="form.specification"/>
          </FormItem>
          <FormItem label="供应商" prop="supplierId">
            <Input type="text" v-model="form.supplier"/>
          </FormItem>
          <FormItem label="录入期间" prop="entryDate">
            <DatePicker disabled v-model="form.entryDate"></DatePicker>
          </FormItem>
        </div>
        <div class="titlecss">折旧方式</div>

        <!-- <div class="ml-20px font-bold mb-16px">折旧方式</div> -->
        <div class="inline-form-item">
          <FormItem label="折旧方法" prop="depreciationMethod" required>
            <Select :deletable="false" v-model="form.depreciationMethod" :disabled="disabled"
                    :datas="depreciationMethods" keyName="code" titleName="name"></Select>
          </FormItem>
          <FormItem label="录入当期是否折旧" prop="isPeriod" required>
            <Radio :disabled="form.depreciationMethod" v-model="form.isPeriod" dict="defaultRadios"/>
          </FormItem>
          <FormItem label="资产科目" prop="assetsSubjectCode" required>
            <Select :deletable="false" :disabled="disabled" :datas="assetsSubjectList" keyName="code"
                    titleName="name" v-model="form.assetsSubjectCode">
              <template v-slot:item="{ item }">
                {{ item.code }} {{ item.name }}
              </template>
            </Select>
          </FormItem>
        </div>
        <div class="inline-form-item">
          <FormItem label="累计折旧科目" prop="totalDepreciationSubjectCode" required>
            <Select :deletable="false" :disabled="disabled" :datas="totalDepreciationSubjectList" keyName="code"
                    titleName="name" v-model="form.totalDepreciationSubjectCode">
              <template v-slot:item="{ item }">
                {{ item.code }} {{ item.name }}
              </template>
            </Select>
          </FormItem>
          <FormItem label="资产清理科目" prop="assetsCleanSubjectCode" required>
            <Select :deletable="false" :disabled="disabled" :datas="assetsCleanSubjectList" keyName="code"
                    titleName="name" v-model="form.assetsCleanSubjectCode">
              <template v-slot:item="{ item }">
                {{ item.code }} {{ item.name }}
              </template>
            </Select>
          </FormItem>
          <FormItem label="折旧费用科目" prop="depreciationExpenseSubjectCode" required>
            <Select :deletable="false" :disabled="disabled" :datas="subjectList" keyName="code"
                    titleName="fullName" filterable v-model="form.depreciationExpenseSubjectCode" :filter="filter">
              <template v-slot:item="{ item }">
                {{ item.code }} {{ item.fullName }}
              </template>
            </Select>
          </FormItem>
        </div>
        <div class="titlecss">资产数据</div>

        <!-- <div class="ml-20px font-bold mb-16px">资产数据</div> -->
        <div class="inline-form-item">
          <FormItem label="资产原值" prop="originalValue" required>
            <Input type="text" v-model="form.originalValue" :disabled="!form.assetsTypeId || disabled"/>
          </FormItem>
          <FormItem label="残值率(%)" prop="residualRate" required>
            <Input type="text" v-model="form.residualRate" :disabled="!form.assetsTypeId || disabled"/>
          </FormItem>
          <FormItem label="预计残值" prop="expectResidualRateComp">
            <Input type="text" disabled v-model="expectResidualRateComp"/>
          </FormItem>
        </div>
        <div class="inline-form-item">
          <FormItem label="预计使用月份" prop="expectUseMonth">
            <Input type="text" v-model="form.expectUseMonth" :disabled="!form.assetsTypeId || disabled"/>
          </FormItem>
          <FormItem label="已折旧月份" prop="useMonth" required>
            <Input type="text" v-model="form.useMonth" :disabled="!form.assetsTypeId || disabled"/>
          </FormItem>
          <FormItem label="剩余使用月份" prop="remainingUseMonth">
            <Input type="text" disabled v-model="remainingUseMonthComp"/>
          </FormItem>
        </div>
        <div class="inline-form-item">
          <FormItem label="累计折旧" prop="totalDepreciation">
            <Input type="text" v-model="form.totalDepreciation" :disabled="!form.assetsTypeId || disabled"/>
          </FormItem>
          <FormItem label="本年累计折旧" prop="yearTotalDepreciation">
            <Input type="text" v-model="form.yearTotalDepreciation" :disabled="!form.assetsTypeId || disabled"/>
          </FormItem>
          <FormItem label="以前年度累计折旧" prop="beforeYearTotalDepreciation">
            <Input disabled type="text" v-model="beforeYearTotalDepreciationComp"/>
          </FormItem>
        </div>
        <div class="inline-form-item">
          <FormItem label="每月折旧额" prop="currentMonthDepreciation">
            <Input type="text" v-model="currentMonthDepreciation" disabled/>
          </FormItem>
          <FormItem label="备注" prop="remark">
            <Input type="text" v-model="form.remark"/>
          </FormItem>
          <FormItem/>
        </div>
      </Form>
    </div>
    <div class="modal-column-between" style="background: none;">
      <!-- <Button @click="console">打印</Button> -->
      <Button @click="$emit('getdata',{},false)">取消</Button>
      <Button color="primary" @click="submit" :loading="loading">{{ form.id ? '更新' : '保存' }}</Button>
    </div>
  </div>
</template>

<script>/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
import Assets from "@/api/Assets";
import {CopyObj} from "@/js/common/utils";
import {mapState} from "vuex";

export default {
  name: "AssetsForm2",
  props: {
    // dataform: {
		// 	type: Object,
		// 	default: {}
		// },
    nextCode: String,
    entity: Object,
    checkDate: Object,
    assetsTypeList: Array,
    deptList: Array,
    supplierList: Array,
    subjectList: Array,
  },
  data() {
    return {
      loading: false,
      depreciationMethods: [
        {name: '平均年限法', code: 0},
        {name: '不折旧', code: 1}
      ],
      assetsSubjectList: [],
      totalDepreciationSubjectList: [],
      assetsCleanSubjectList: [],
      form: {
        id: null,
        code: null,
        name: null,
        addType: 1,
        assetsTypeId: null,
        deptId: null,
        supplier: null,
        isPeriod: 0,
        depreciationMethod: 0,
        canEdit: true,
        assetsSubjectCode: null,
        totalDepreciationSubjectCode: null,
        assetsCleanSubjectCode: null,
        depreciationExpenseSubjectCode: null,
        specification: null,
        startDate: null,
        originalValue: null,
        expectUseMonth: null,
        useMonth: 0,
        remainingUseMonth: null,
        residualRate: null,
        expectResidualRate: null,
        totalDepreciation: 0,
        yearTotalDepreciation: 0,
        beforeYearTotalDepreciation: 0,
        currentMonthDepreciation: 0,
        detailCount: 0,
        depreciationCreditSum: 0,
        remark: null,
        isVoucher: false,
        // entryDate: this.checkDate.key,
      },
    }
  },
  watch: {
    'form.depreciationMethod'(val) {
      if (this.form.isVoucher) {
        return
      }
      if (val) {
        this.form.isPeriod = 0;
      }
    },
    'form.assetsTypeId'(val) {
      if (this.form.isVoucher) {
        return
      }
      if (val) {
        const assetsType = this.assetsTypeList.find(value => value.id === val);
        this.form.expectUseMonth = assetsType.useMonth;
        this.form.residualRate = assetsType.residualRate;
      }
    }
  },
  computed: {
    ...mapState(['currentAccountSets']),
    disabled() {
      return !this.form.canEdit || this.form.isVoucher;
    },
    expectResidualRateComp() {
      if (this.form.originalValue && this.form.residualRate) {
        return Number(this.form.originalValue * (this.form.residualRate / 100)).toFixed(2)
      }
      return 0;
    },
    remainingUseMonthComp() {
      if (this.form.expectUseMonth && this.form.useMonth) {
        return Number(this.form.expectUseMonth - this.form.useMonth).toFixed(2)
      }
      return this.form.expectUseMonth;
    },
    beforeYearTotalDepreciationComp() {
      if (this.form.totalDepreciation && this.form.yearTotalDepreciation) {
        return Number(this.form.totalDepreciation - this.form.yearTotalDepreciation).toFixed(2)
      }
      return 0;
    },
    currentMonthDepreciation() {
      return this.disabled ? this.form.currentMonthDepreciation : (((this.form.originalValue - this.expectResidualRateComp - this.form.totalDepreciation) / (this.remainingUseMonthComp + this.form.detailCount)) || 0).toFixed(2);
    }
  },
  methods: {
    console(){
      console.log(this.nextCode,'dataform')
      // console.log(this.dataform2,'dataform2')
    },
    filter(item, val) {
      return item.code.indexOf(val) !== -1 || item.name.indexOf(val) !== -1 || item.mnemonicCode.indexOf(val) !== -1;
    },
    submit() {
      let validResult = this.$refs.form.valid();
      if (validResult.result) {
        this.loading = true;
        this.form.beforeYearTotalDepreciation = this.beforeYearTotalDepreciationComp;
        this.form.expectResidualRate = this.expectResidualRateComp;
        this.form.remainingUseMonth = this.remainingUseMonthComp;
        this.form.currentMonthDepreciation = this.currentMonthDepreciation;
        Assets.save(this.form).then(() => {
          this.$emit('success');
        }).finally(() => {
          this.loading = false;
        })
      }
    }
  },
  created() {
    CopyObj(this.form, this.entity);

    this.subjectList.forEach(val => {
      if (val.name === '固定资产') {
        this.assetsSubjectList = [val];
        if (!this.entity) {
          this.form.assetsSubjectCode = val.code;
        }
      } else if (val.name === '累计折旧') {
        this.totalDepreciationSubjectList = [val];
        if (!this.entity) {
          this.form.totalDepreciationSubjectCode = val.code;
        }
      } else if (val.name === '固定资产清理') {
        this.assetsCleanSubjectList = [val];
        if (!this.entity) {
          this.form.assetsCleanSubjectCode = val.code;
        }
      } else if (val.name === '管理费用-折旧费' || val.name === '管理费用-固定资产折旧') {
        if (!this.entity) {
          this.form.depreciationExpenseSubjectCode = val.code;
        }
      }
    })
  }
}
</script>

<style lang="less" scoped>
	
</style>