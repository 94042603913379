<template>
    <div class="modal-column">
        <div class="modal-column-full-body">
            <Form ref="form" :model="form" :rules="{}" labelWidth="150">
                <div class="inline-form-item">
                    <FormItem label="上传图片" prop="code" style="width: 33%;">
                        <img src="../../assets/upload.png" style="width: 90px;height: 90px">
                    </FormItem>
                    <div style="display: flex;flex-direction: column;width: 66%">
                        <div class="inline-form-item">
                            <FormItem label="出票日期" prop="name" required  style="width: 50%">
                                <DatePicker v-model="form.employDate"/>
                            </FormItem>
                            <FormItem label="票据种类" prop="gender" required  style="width: 50%">
                                <Select :deletable="false" v-model="form.gender" :datas="{1:'汇款发票', 2:'银行柜台票据'}"/>
                            </FormItem>
                        </div>
                        <div class="inline-form-item">
                            <FormItem label="汇款到期日" prop="name" required  style="width: 50%">
                                <DatePicker v-model="form.salaryStartDate"/>
                            </FormItem>
                            <FormItem label="票据状态" prop="gender" required  style="width: 50%">
                                <Select :deletable="false" v-model="form.status" :datas="{1:'已签收', 2:'未签收'}"/>
                            </FormItem>
                        </div>
                    </div>
                </div>
                <div class="inline-form-item">
                    <FormItem label="票据号码" prop="cardType" required style="width: 33%;">
                        <Input type="text" v-model="form.idCard"/>
                    </FormItem>
                    <FormItem label="出票金额" prop="idCard" required style="width: 33%;">
                        <Input type="text" v-model="form.code"/>
                    </FormItem>
                    <FormItem label="能否转让" prop="birthday" required style="width: 33%;">
                        <Radio v-model="form.bankCode" :datas="[{ name: '能', code: 0 }, { name: '否', code: '1' }]"  keyName="code" titleName="name"></Radio>
                    </FormItem>
                </div>
                <div class="inline-form-item">
                    <FormItem label="出票人全称"  style="width: 33%;">
                        <Input type="text" />
                    </FormItem>
                    <FormItem label="出票人账号"  style="width: 33%;">
                        <Input type="text" />
                    </FormItem>
                    <FormItem label="出票人开户银行"   style="width: 33%;">
                        <Input type="text" />
                    </FormItem>
                </div>
                <div class="inline-form-item">
                    <FormItem label="收票人全称"   style="width: 33%;">
                        <Input type="text" />
                    </FormItem>
                    <FormItem label="收票人账号"  style="width: 33%;">
                        <Input type="text"/>
                    </FormItem>
                    <FormItem label="收票人开户银行"  style="width: 33%;">
                        <Input type="text" />
                    </FormItem>
                </div>
                <div class="inline-form-item">
                    <FormItem label="承诺人全称" requried style="width: 33%;">
                        <Input type="text" />
                    </FormItem>
                    <FormItem label="承诺人账号" requried style="width: 33%;">
                        <Input type="text"/>
                    </FormItem>
                    <FormItem label="承诺人开户银行"  style="width: 33%;">
                        <Input type="text" />
                    </FormItem>
                </div>
                <div class="inline-form-item">
                    <FormItem label="备注" prop="cardType"  style="width: 100%;">
                        <Textarea row="3"/>
                    </FormItem>
                </div>
            </Form>
            <div class="modal-column-center" style="display: flex; justify-content: center">
                <Button @click="$emit('close')">取消</Button>
                <Button color="primary" @click="submit" :loading="loading">{{ this.id ? '更新' : '保存' }}</Button>
            </div>
        </div>

    </div>
</template>

<script>


import Employee from "@/api/Employee";
import InsuredConfig from "@/api/InsuredConfig";
import {CopyObj} from "@/js/common/utils";
import {mapState} from "vuex";
import setting from "@/api/setting";
import Setting from "@/api/setting";

export default {
    name: "BillLedgerAdd",
    props: {
        entity: Object,
        id: Object
    },
    data() {
        return {
            options: {
                max_file_size: '1mb',
                filters: {
                    mime_types: [
                        {title: 'Image files', extensions: 'jpg,gif,png'}
                    ]
                }
            },
            file: null,
            loading: false,
            deptList: [],
            insureList: [],
            subjectList: [],
            form: {
                id: null,
                code: null,
                name: null,
                gender: 1,
                cardType: 1,
                idCard: null,
                birthday: null,
                mobile: null,
                email: null,
                education: 1,
                bankName: null,
                bankCode: null,
                employDate: null,
                deptId: null,
                post: null,
                salaryStartDate: null,
                professional: null,
                salaryAccount: null,
                status: 1,
                remark: null,
                payMethod: true,
                payDataInfo: []
            },
        }
    },
    computed: {

        ...mapState(['currentAccountSets']),
    },
    methods: {
        loadList() {
            if (this.id === undefined) {
                // InsuredConfig.queryInsureInfo(this.form.insureId).then((data) => {
                //   this.form.payDataInfo = data
                // })
                this.form.payDataInfo = [
                    {
                        id: '1',
                        pid: '1',
                        projectName: '养老保险',
                        paymentBase: '1.21',
                        companyPayment: '20',
                        companyMoney: '1',
                        individualPayment: '8',
                        individualMoney: '6'
                    },
                    {
                        id: '2',
                        pid: '2',
                        projectName: '医疗保险',
                        paymentBase: '2.21',
                        companyPayment: '10',
                        companyMoney: '2',
                        individualPayment: '2',
                        individualMoney: '5'
                    },
                    {
                        id: '3',
                        pid: '3',
                        projectName: '失业保险',
                        paymentBase: '3.21',
                        companyPayment: '1',
                        companyMoney: '3',
                        individualPayment: '0.2',
                        individualMoney: '4'
                    },
                    {
                        id: '4',
                        pid: '4',
                        projectName: '工伤保险',
                        paymentBase: '4.21',
                        companyPayment: '0.5',
                        companyMoney: '4',
                        individualPayment: '0',
                        individualMoney: '0'
                    },
                    {
                        id: '5',
                        pid: '5',
                        projectName: '生育保险',
                        paymentBase: '5.21',
                        companyPayment: '0.8',
                        companyMoney: '5',
                        individualPayment: '0',
                        individualMoney: '0'
                    },
                    {
                        id: '6',
                        pid: '6',
                        projectName: '住房公积金',
                        paymentBase: '6.21',
                        companyPayment: '12',
                        companyMoney: '6',
                        individualPayment: '12',
                        individualMoney: '1'
                    },
                ]
            } else {
                // Employee.queryEmployeeInfo(this.id).then((data) => {
                //   this.form = data
                // })
                // Employee.queryEmployeePayInfo(this.id).then((data) => {
                //   this.form.payDataInfo = data
                // })
            }
        },
        submit() {
            let validResult = this.$refs.form.valid();
            if (validResult.result) {
                this.loading = true;
                if (!this.form.payMethod) {
                    this.form.payDataInfo = []
                }
                Employee.save(this.form).then(() => {
                    this.$emit('success');
                }).finally(() => {
                    this.loading = false;
                });
            }
        },
        initSelect() {
            setting.accountingCategoryDetails.loadAuxiliaryAccountingDataByName(['DEPARTMENT', 'VENDOR']).then(({data}) => {
                this.deptList = data['DEPARTMENT'].map(val => {
                    return {
                        key: val.id,
                        accountingCategoryId: val.categoryId,
                        title: val.name
                    }
                });
            })
            Setting.subject.voucherSelect().then(({data}) => {
                this.subjectList = data || [];
            })
            InsuredConfig.list().then(({data}) => {
                // this.insureList = data.map(val => {
                //   return {
                //     key: val.id,
                //     title: val.name
                //   }
                // })
                this.insureList = [
                    {id: '1', name: '老板方案'},
                    {id: '2', name: '人事方案'},
                    {id: '3', name: '产品方案'},
                    {id: '4', name: '经理方案'},
                ].map(val => {
                    return {
                        key: val.id,
                        title: val.name
                    }
                })
                this.form.insureId = this.insureList[0].key
            });
        },

        // 编辑数据接口
        saveChange({row, column}) {
            // const field = column.field
            // const cellValue = row[field]
            // if ((row['projectName'] === '工伤保险' || row['projectName'] === '生育保险') && (field === 'individualPayment' || field === 'individualMoney')) {
            //   row[field] = ''
            //   return false
            // }
        },
        changeInsure(data) {
            // InsuredConfig.queryInsureProject(data.key).then((result) => {
            //   this.insureList = result
            // })
        }
    },
    created() {
        // CopyObj(this.form, this.entity);
        this.loadList()
        this.initSelect()
    },
}
</script>

<style lang="less" scoped>
.line {
  height: 1px;
  background-color: #dadada;
  margin: 20px 0;
}
</style>
