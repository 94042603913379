<template>
	<div class="modal-column">
		<div class="flex-1 flex p-8px items-center justify-center">
			<span>科目编码规则：4</span>
			<template v-for="(item,i) in codeLen">
				<template v-if="i>0">
					<div class="flex items-center codeItem">
						<span class="mx-8px">-</span>
						<Select v-model="codeLen[i]" :datas="lenData2" :deletable="false"/>
						<i v-if="i>3" class="fa fa-minus-circle red-color text-hover " @click.stop="delItem(i)"></i>
					</div>
				</template>
			</template>
			<i v-if="codeLen.length<8" class="fa fa-plus-circle text-hover ml-8px" @click="newItem"></i>
		</div>
		<div class="modal-column-btn">
			<Button @click="$emit('close')" :loading="loading">取消</Button>
			<Button color="green" @click="saveEncoding" :loading="loading">保存</Button>
		</div>
	</div>
</template>

<script>
import {mapState} from "vuex";
import setting from "@/api/setting";

/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
export default {
	name: "EncodingSettingForm",
	data() {
		return {
			codeLen: [4, 2, 2, 2],
			lenData: [2, 3, 4],
			lenData2: [1, 2, 3, 4],
			loading: false
		}
	},
	computed: {
		...mapState(['currentAccountSets']),
	},
	methods: {
		saveEncoding() {
			let code = this.codeLen.join('-');
			if (code !== this.currentAccountSets.encoding.join('-')) {
				this.loading = true;
				setting.accountSets.updateEncode(this.codeLen).finally(() => {
					this.loading = false;
				}).then(() => {
					this.$store.dispatch('init');
					this.$emit('success')
				});
			} else {
				this.$emit('close')
			}
		},
		newItem() {
			this.codeLen.push(2);
		},
		delItem(i) {
			this.codeLen.splice(i, 1);
		}
	},
	created() {
		this.codeLen = [...this.currentAccountSets.encoding];
	}
}
</script>
<style lang="less" scoped>
.codeItem {
	position: relative;

	.fa-minus-circle {
		position: absolute;
		top: -5px;
		z-index: 1;
		right: -5px;
	}
}
</style>
