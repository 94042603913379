<template>
  <div class="h-full p-20px">
    <div class="flex flex-column h-full">
      <vxe-toolbar class-name="px-16px" :loading="loading">
        <template #buttons>
          <DropdownCustom ref="paramsDropdown" button class="" trigger="click">
            <div>{{ manba(params.entryDate).format('YYYY年MM月') }}</div>
            <template #content>
              <Form style="padding: 10px">
                <FormItem class="!pb-5px" label="工资月份">
                  <Select :datas="checkoutSelect" filterable v-model="params.entryDate" :deletable="false"/>
                </FormItem>
                <FormItem class="!pb-5px" label="部门">
                  <Select :datas="deptOptions" filterable v-model="params.deptId"/>
                </FormItem>
                <FormItem class="!pb-5px" label="姓名">
                  <Input v-model="params.name"/>
                </FormItem>
                <FormItem class="!pb-5px">
                  <vxe-button status="primary" @click="loadList();" :loading="loading">确定</vxe-button>
                  <vxe-button @click="this.$refs.paramsDropdown.hide()">取消</vxe-button>
                  <vxe-button @click="reset">重置</vxe-button>
                </FormItem>
              </Form>
            </template>
          </DropdownCustom>
          <a style="cursor: pointer; margin-left: 20px; color: #1063e0" @click="setSalaryItem()">自定义工资项目</a>
        </template>
        <template #tools>
          <Checkbox v-model="showAll" class="mr-16px">编辑、显示工资明细</Checkbox>
          <Button @click="generatePayroll">生成工资表</Button>
          <DropdownMenu
                  button
                  trigger="hover"
                  @clickItem="importJournal"
                  style="width: 102px; text-align: center"
                  :datas="{declarationImport:'个税申报表导入',templateImport:'模板导入'}">
            导入
          </DropdownMenu>
          <Button @click="sendPayslip">发送工资条</Button>
          <Button @click="individualDeclaration">个税申报</Button>
          <DropdownMenu
                  button
                  trigger="hover"
                  @clickItem="batchClickItem"
                  :datas="{batchDelete:'删除',exportJournal:'导出',printList:'打印',payrollRecord:'工资条记录',declarationRecord:'申报记录'}">
            更多操作
          </DropdownMenu>
        </template>
      </vxe-toolbar>
      <div class="flex-1 h-0px">
        <div style="width: 94%; height: 100%; float: left">
          <div style="width: 100%;height: 100%;">
          <vxe-table ref="journalTable"
                     :checkbox-config="{visibleMethod:({ row }) => !row.init,checkMethod:({ row }) => !row.init}"
                     :loading="loading"
                     :data="dataResult.results"
                     height="auto"
                     align="center"
                     v-if="!showAll"
                     >
            <vxe-column type="checkbox" width="50" align="center" fixed="left"/>
            <vxe-column title="编号" field="code" width="120"/>
            <vxe-column title="姓名" field="name" width="160"/>
            <vxe-column title="部门" field="deptName" width="160"/>
            <vxe-column title="工资总额" field="code1" width="160"/>
            <vxe-column title="应扣工资" field="code2" width="160"/>
            <vxe-column title="实发工资调整" field="code3" width="160"/>
            <vxe-column title="其他" field="other" width="160"/>
          </vxe-table>
          <vxe-table
                  :checkbox-config="{visibleMethod:({ row }) => !row.init,checkMethod:({ row }) => !row.init}"
                  :loading="loading"
                  :data="dataResult.results"
                  height="auto"
                  align="center"
                  :edit-config="{trigger: 'dblclick', mode: 'row', showIcon: false}"
                  @edit-closed="saveSalaryChange"
                  v-if="showAll"
                  >
            <vxe-column type="checkbox" width="50" align="center" fixed="left"/>
            <vxe-column title="编号" field="code" width="100" :edit-render="{}">
              <template #edit="{ row }">
                <vxe-input v-model="row.code" type="text"></vxe-input>
              </template>
            </vxe-column>
            <vxe-column title="姓名" field="name" width="120" :edit-render="{}">
              <template #edit="{ row }">
                <vxe-input v-model="row.name" type="text"></vxe-input>
              </template>
            </vxe-column>
            <vxe-column title="部门" field="deptName" width="120" :edit-render="{}">
              <template #edit="{ row }">
                <vxe-input v-model="row.deptName" type="text"></vxe-input>
              </template>
            </vxe-column>
            <vxe-colgroup title="工资总额">
              <vxe-column
                      v-for="(config, index) in dataResult.totalSalaryColumn"
                      :key="index"
                      :field="config.field"
                      :title="config.fieldName"
                      :width="130"
                      :edit-render="{}">
                <template #edit="{ row, column  }">
                  <vxe-input v-model="row[column.property]" type="text"></vxe-input>
                </template>
              </vxe-column>
            </vxe-colgroup>
            <vxe-colgroup title="应扣工资">
              <vxe-column
                      v-for="(config, index) in dataResult.deductibleSalaryColumn"
                      :key="index"
                      :field="config.field"
                      :title="config.fieldName"
                      :width="130"
                      :edit-render="{}">
                <template #edit="{ row, column  }">
                  <vxe-input v-model="row[column.property]" type="text"></vxe-input>
                </template>
              </vxe-column>
            </vxe-colgroup>
            <vxe-colgroup title="实发工资调整">
              <vxe-column
                      v-for="(config, index) in dataResult.actualSalaryColumn"
                      :key="index"
                      :field="config.field"
                      :title="config.fieldName"
                      :width="130"
                      :edit-render="{}">
                <template #edit="{ row, column  }">
                  <vxe-input v-model="row[column.property]" type="text"></vxe-input>
                </template>
              </vxe-column>
            </vxe-colgroup>
            <vxe-colgroup title="公司承担款项">
              <vxe-column title="养老保险" field="code4" width="110" :edit-render="{}">
                <template #edit="{ row }">
                  <vxe-input v-model="row.code4" type="text"></vxe-input>
                </template>
              </vxe-column>
              <vxe-column title="医疗保险" field="code5" width="110" :edit-render="{}">
                <template #edit="{ row }">
                  <vxe-input v-model="row.code5" type="text"></vxe-input>
                </template>
              </vxe-column>
              <vxe-column title="失业保险" field="code6" width="110" :edit-render="{}">
                <template #edit="{ row }">
                  <vxe-input v-model="row.code6" type="text"></vxe-input>
                </template>
              </vxe-column>
              <vxe-column title="工伤保险" field="code7" width="110" :edit-render="{}">
                <template #edit="{ row }">
                  <vxe-input v-model="row.code7" type="text"></vxe-input>
                </template>
              </vxe-column>
              <vxe-column title="生育保险" field="code8" width="110" :edit-render="{}">
                <template #edit="{ row }">
                  <vxe-input v-model="row.code8" type="text"></vxe-input>
                </template>
              </vxe-column>
              <vxe-column title="住房公积金" field="code9" width="110" :edit-render="{}">
                <template #edit="{ row }">
                  <vxe-input v-model="row.code9" type="text"></vxe-input>
                </template>
              </vxe-column>
            </vxe-colgroup>
            <vxe-colgroup title="个人承担款项">
              <vxe-column title="养老保险" field="code10" width="110" :edit-render="{}">
                <template #edit="{ row }">
                  <vxe-input v-model="row.code10" type="text"></vxe-input>
                </template>
              </vxe-column>
              <vxe-column title="医疗保险" field="code11" width="110" :edit-render="{}">
                <template #edit="{ row }">
                  <vxe-input v-model="row.code11" type="text"></vxe-input>
                </template>
              </vxe-column>
              <vxe-column title="失业保险" field="code12" width="110" :edit-render="{}">
                <template #edit="{ row }">
                  <vxe-input v-model="row.code12" type="text"></vxe-input>
                </template>
              </vxe-column>
              <vxe-column title="住房公积金" field="code13" width="110" :edit-render="{}">
                <template #edit="{ row }">
                  <vxe-input v-model="row.code13" type="text"></vxe-input>
                </template>
              </vxe-column>
            </vxe-colgroup>
            <vxe-column title="其他" field="other" width="120" :edit-render="{}">
              <template #edit="{ row }">
                <vxe-input v-model="row.other" type="text"></vxe-input>
              </template>
            </vxe-column>
          </vxe-table>
        </div>
        </div>
       
        <div style="width: 6%; float: right; margin-top: 20px">
          <i v-if="ShowMonthList" class="vxe-icon-arrow-double-left" style="float: left" @click="chooseMonthType(false)"></i>
          <i v-else class="vxe-icon-arrow-double-right" style="float: left" @click="chooseMonthType(true)"></i>
          <div class="monthDateCont" v-if="ShowMonthList">
            <div class="monthDateBox singleDate">
              <div class="monthPick monthPickStart">{{ monthDataList.nowDate }}</div>
            </div>
            <ul class="monthList">
              <li v-if="monthStart !== 0"><a class="monthItem prev_btn" @click="updateMonth(true)"><i class="vxe-icon-arrow-up"></i></a></li>
              <li v-else><a class="monthItem prev_btn prev_disabled_btn"><i class="vxe-icon-arrow-up"></i></a></li>
              <li v-for="(item, index) in monthDataList.results.slice(monthStart, monthEnd)">
                <a v-if="item.substring(5) !== '1' && item === monthDataList.nowDate" class="monthItem active">{{ item.substring(5) + '月' }}</a>
                <a v-else-if="item.substring(5) !== '1' && item !== monthDataList.nowDate" class="monthItem" @click="changeMonth(index)">{{ item.substring(5) + '月' }}</a>
                <a v-else class="monthItem" @click="changeMonth(index)">{{ item.substring(5) + '月' }}<span class="year">{{ item.substring(0, 4) }}</span></a>
              </li>
              <li v-if="monthEnd !== monthDataList.results.length"><a class="monthItem prev_btn" @click="updateMonth(false)"><i class="vxe-icon-arrow-down"></i></a></li>
              <li v-else><a class="monthItem prev_btn prev_disabled_btn"><i class="vxe-icon-arrow-down"></i></a></li>
            </ul>
          </div>
        </div>
      </div>
      <vxe-pager @page-change="onPageChange"
                 v-model:current-page="page.page"
                 v-model:page-size="page.pageSize"
                 :total="dataResult.total"
                 :layouts="['PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'Sizes', 'FullJump', 'Total']">
        <template #left>
          <vxe-button @click="loadList()" type="text" size="mini" icon="fa fa-refresh" :loading="loading"></vxe-button>
        </template>
      </vxe-pager>
    </div>
  </div>
</template>

<script>


  import {mapGetters, mapState} from "vuex";

 /**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */

  export default {
    name: "SalaryManagement",
     methods:{
          setSalaryItem  (){
             this.$store.commit('newTab', {key: 'SalaryCustomize', title: "凭证列表"})
         },

     },
    computed: {
      ...mapState(['currentAccountSets']),
      ...mapGetters(['checkoutSelect', 'localCurrency']),
      deptListMap() {
        let map = {};
        this.deptList.map(val => {
          map[val.key] = val.title;
        })
        return map;
      },
    },
  }
</script>
<script setup>
  import {ref, h, watch, reactive} from "vue";
  import manba from "manba";
  import {confirm, message} from "heyui.ext";
  import {useStore} from 'vuex'
  import SalaryManage from "@/api/SalaryManage";
  import CheckWorkSettingForm from "@/views/salary/CheckWorkSettingForm";
  import setting from "@/api/setting";
  import Qs from "qs";
  import {layer} from "@layui/layer-vue";

  const store = useStore();

  const deptOptions = ref([]);
  const account = ref(null);
  const journalTable = ref(null);
  const paramsDropdown = ref(null);
  const loading = ref(false);

  const currentAccountDate = manba(store.state.currentAccountSets.currentAccountDate);
  const entryDate = ref(currentAccountDate.startOf(manba.MONTH).format("YYYY-MM"));

  const updating = ref(false);
  const showAll = ref(false);

  const ShowMonthList = ref(true);
  const monthDataList = ref({
    results: [],
    nowDate: null
  });
  const monthStart = ref(0);
  const monthEnd = ref(12);

  const page = ref({
    page: 1,
    pageSize: 20
  })

  const dataResult = ref({
    results: [],
    totalSalaryColumn: [],
    deductibleSalaryColumn: [],
    actualSalaryColumn: [],
    total: 20
  })

  const params = reactive({
    entryDate: entryDate,
    deptId: null,
    name: null
  });

  const reset = () => {
    params.value = {
      entryDate: entryDate,
      deptId: null,
      name: null
    };
    loadList();
  }

  const onPageChange = ({currentPage, pageSize}) => {
    page.value.page = currentPage;
    page.value.pageSize = pageSize;
    loadList();
  }

  // const setSalaryItem = () => {
  //   let layerId = layer.drawer({
  //     title: "自定义工资项目",
  //     shadeClose: false,
  //     closeBtn: false,
  //     zIndex: 1000,
  //     area: ['800px', '100vh'],
  //     content: h(CheckWorkSettingForm, {
  //       onClose: () => {
  //         layer.close(layerId);
  //         loadList();
  //       },
  //       onSuccess: () => {
  //         loadList();
  //         layer.close(layerId);
  //       }
  //     })
  //   });
  // }

  setting.accountingCategoryDetails.loadAuxiliaryAccountingDataByName(['DEPARTMENT', 'VENDOR']).then(({data}) => {
    deptOptions.value = data['DEPARTMENT'].map(val => {
      return {
        key: val.id,
        accountingCategoryId: val.categoryId,
        title: val.name
      }
    });
  })

  const loadList = () => {
    loading.value = true
    SalaryManage.list(Object.assign({}, params, page.value)).then(({data}) => {
      // dataResult.value = data;
      dataResult.value = {
        page: 1,
        pageSize: 20,
        total: 1,
        totalPage: 1,
        results: [
          { code: '001', name: '测试1', deptName: '行政部' , mobile: '15763589658', idCard: '412545785451245632', code1: '5000.12', code2: '109', code3: '12', code4: '99.12', code5: '12', code6: '11.99', code7: '99.12', code8: '12', code9: '11.99', other: '13.11', code10: '11.99', code11: '11.99', code12: '11.99', code13: '11.99', code14: '11.99', code15: '11.99', code16: '11.99', code17: '11.99', code18: '11.99', code19: '11.99', code20: '11.99', code21: '11.99'},
          { code: '', name: '', deptName: '合计' , mobile: '15763589658', idCard: '412545785451245632', code1: '5000.12', code2: '109', code3: '12', code4: '99.12', code5: '12', code6: '11.99', code7: '99.12', code8: '12', code9: '11.99', other: '13.11', code10: '11.99', code11: '11.99', code12: '11.99', code13: '11.99', code14: '11.99', code15: '11.99', code16: '11.99', code17: '11.99', code18: '11.99', code19: '11.99', code20: '11.99', code21: '11.99'},
        ],
        // 工资总额
        totalSalaryColumn: [
          {id: 1, field: 'code14', fieldName: '工资', fieldType: '系统字段', proConfig: '工资总额', dataType: '数值型', formula: '工资', weight: 1},
          {id: 2, field: 'code15', fieldName: '津贴补助', fieldType: '系统字段', proConfig: '工资总额', dataType: '数值型', formula: '工资', weight: 2},
          {id: 3, field: 'code16', fieldName: '奖金', fieldType: '系统字段', proConfig: '工资总额', dataType: '数值型', formula: '工资', weight: 3},
        ],
        // 应扣工资
        deductibleSalaryColumn: [
          {id: 4, field: 'code17', fieldName: '考勤扣款', fieldType: '输入字段', proConfig: '应扣工资', dataType: '数值型', formula: '工资', weight: 4},
          {id: 5, field: 'code18', fieldName: '其他扣款', fieldType: '输入字段', proConfig: '应扣工资', dataType: '数值型', formula: '工资', weight: 5},
        ],
        // 实发工资调整
        actualSalaryColumn: [
          {id: 6, field: 'code19', fieldName: '调整1', fieldType: '计算字段', proConfig: '实发工资调整', dataType: '数值型', formula: 'if 工资 > 0', weight: 6},
          {id: 7, field: 'code20', fieldName: '调整2', fieldType: '计算字段', proConfig: '实发工资调整', dataType: '数值型', formula: 'if 工资 > 0', weight: 7},
          {id: 8, field: 'code21', fieldName: '调整3', fieldType: '计算字段', proConfig: '实发工资调整', dataType: '数值型', formula: 'if 工资 > 0', weight: 8},
        ]
      }
      paramsDropdown.value.hide();
    }).finally(() => loading.value = false)
  }
  loadList();

  const calcTotal = ({columns, data}) => {
    const row = [];
    columns.forEach(col => {
      if ('code2' === col.property) {
        // row.push(this.numFormat((total, r) => total + (r.code2 || 0), 0));
      } else if ('code3' === col.property) {
        // row.push(this.numFormat(this.topData.filter(val => val.balanceDirection === '贷').reduce((total, r) => total + (r.beginBalance || 0), 0)));
      } else {
        row.push(col.property === "deptName" ? '合计' : "")
      }
    })
    return [row];
  }

  // 是否显示月份列表
  const chooseMonthType = (result) => {
    ShowMonthList.value = result
  }

  // 查询月份列表接口
  const queryMonthList = () => {
    // SalaryManage.queryMonthList().then(() => {
    // }).finally()
    monthDataList.value = {
      results: ['2022-5', '2022-6', '2022-7', '2022-8', '2022-9', '2022-10', '2022-11', '2022-12', '2023-1', '2023-2', '2023-3', '2023-4', '2023-5', '2023-6', '2023-7', '2023-8'],
      nowDate: '2023-7'
    }
    monthStart.value = monthDataList.value.results.length - 13
    monthEnd.value = monthDataList.value.results.length - 1
  }
  queryMonthList()

  // 改变月份
  const updateMonth = (result) => {
    if (result) {
      monthStart.value = monthStart.value - 1
      monthEnd.value = monthEnd.value - 1
    } else {
      monthStart.value = monthStart.value + 1
      monthEnd.value = monthEnd.value + 1
    }
  }
 // 改变月份
  const changeMonth = (result) => {
    monthDataList.value.nowDate = monthDataList.value.results.slice(monthStart.value, monthEnd.value)[result]
    entryDate.value = monthDataList.value.nowDate
    loadList()
  }

  // 编辑数据接口
  const saveSalaryChange = ({ row, column }) => {
    const field = column.field
    const cellValue = row[field]
    console.log(row)
    // SalaryManage.update(row).then(() => {
    //   this.loadList();
    // }).finally(() => this.loading = false)
  }

  const printClickItem = (key) => {
    window.open("/api/pdf/journal/list/" + account.value.id + "?" + Qs.stringify(Object.assign(params, page.value)))
  }

  const exportJournal = () => {
    window.open("/api/cashier/journal/download/" + account.value.id + "?" + Qs.stringify(Object.assign(params, page.value)))
  }

  // 生成工资表
  const generatePayroll = () => {

  }

  // 导入接口
  const importJournal = (type) => {
    switch (type) {
      case "declarationImport":

        break
      case "templateImport":

        break
    }
  }

  // 发送工资条接口
  const sendPayslip = () => {

  }

  // 个税申报接口
  const individualDeclaration = () => {

  }

  // 更多操作
  const batchClickItem = (type) => {
    let records = journalTable.value.getCheckboxRecords().map(row => row.id);
    if (records.length) {
      switch (type) {
        case 'batchDelete':
          confirm({
            content: `确认删除这些记录?`,
            onConfirm: () => {
              loading.value = true;
              SalaryManage.batchDelete(records).then(() => {
                loadList();
              }).finally(() => loading.value = false)
            }
          })
          break
        case 'exportJournal':
          exportJournal()
          break
        case 'printList':
          printClickItem()
          break
        case 'payrollRecord':
        case 'declarationRecord':
      }
    } else {
      message("请选择需要操作的记录~")
    }
  }
</script>

<style lang="less" scoped>
  .monthDateCont {
    position: relative;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    transition: all 0 ease 2s;
    float: right;
    width: 82%;
  }
  .monthDateBox {
    position: relative;
    padding: 5px 0;
    text-align: center;
    background-color: #f7f8ff;
    cursor: pointer;
    user-select: none;
  }
  .monthDateBox .monthPick {
    margin: 5px 3px;
    height: 24px;
    line-height: 24px;
    font-size: 12px;
    color: #000;
    border-radius: 5px;
    border: 1px solid #F2F2F2;
    background-color: #fff;
  }
  .monthList {
    text-align: center;
    background-color: #fff;
    /*padding: 10px 20px 10px 30px;*/
  }
  .monthList li a.prev_btn, .zmsunform-fie-ex_datev .monthList li a.next_btn {
    background-color: #2080f0;
    color: #fff;
    width: 20px;
    height: 20px;
    line-height: 20px;
    margin: 10px 0;
  }
  .monthList li a.prev_disabled_btn, .monthList li a.next_disabled_btn, .monthList li a.prev_disabled_btn:hover, .monthList li a.next_disabled_btn:hover {
    cursor: default;
    color: #bbb;
    background-color: transparent;
  }
  .monthList li a.active {
    color: #fff;
    background-color: #2080f0;
  }
  .monthList li a:hover {
    color: #fff;
    background-color: #2080f0;
  }
  .monthList li a {
    position: relative;
    display: inline-block;
    width: 28px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    color: #333;
    cursor: pointer;
    font-size: 12px;
    border-radius: 30px;
    user-select: none;
  }
  .monthList li a .year {
    position: absolute;
    left: -24px;
    top: 0px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    display: inline-block;
    color: #fff;
    background-color: #2080f0;
    line-height: 8px;
    padding: 2px 3px;
  }
</style>
