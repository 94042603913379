import BillLedger from "../../views/cashier/BillLedger.vue";
import BankCompany from "../../views/cashier/BankCompany.vue";

const Manager = [
	{
		title: '首页',
		key: 'Home',
		icon: '首页icon',
		icons: '首页（选中）',
	},
	{
		title: '凭证',
		key: 'Voucher',
		icon: '凭证',
		icons: '凭证（选中）',
		children: [
			{
				title: '新增凭证',
				key: 'VoucherForm'
			},
			{
				title: '凭证查询',
				key: 'Voucher'
			},
			{
				title: '电子档案',
				key: 'ElectronicArchives'
			},{
				title: '发票宝',
				key: 'BillInfo'
			},
			
		]
	},
	{
		title: '资金',
		key: 'funds',
		icon: '资金',
		icons: '资金（选中）',
		children: [
			{
				title: '日记账',
				key: 'CashierJournal'
			},
			{
				title: '内部转账',
				key: 'InternalTransfer'
			},
			{
				title: '票据台账',
				key: 'BillLedger'
			},
			{
				title: '资金报表',
				key: 'FundsReport'
			},
			{
				title: '核对总账',
				key: 'CheckUp'
			},
			{
				title: '收支类别',
				key: 'CashierIoType'
			},
			{
				title: '账户设置',
				key: 'CashierAccount'
			},
			{
				title: '银企互联',
				key: 'BankCompany'
			}
		]
	},
	// 发票
	{
		title: '发票',
		key: 'Invoice',
		icon: '发票',
		icons: '发票（选中）',
		
	},
	// 工资
	{
		title: '工资',
		key: 'Salary',
		icon: '工资',
		icons: '工资（选中）',
		children: [
			{
				title: '工资管理',
				key: 'Salary'
			},
			{
				title: '员工信息',
				key: 'EmployeeInformation'
			},
			{
				title: '参保方案设置',
				key: 'InsureIndex'
			},{
				title: '五险一金',
				key: 'FiveOne'
			},
		]
	},
	// 固定资产
	{
		title: '固定资产',
		key: 'assets',
		icon: '固定资产',
		icons: '固定资产（选中）',
		children: [
			{
				title: '固定资产管理',
				key: 'Assets'
			},
			{
				title: '固定资产类别',
				key: 'AssetsType'
			},
			{
				title: '折旧明细表',
				key: 'AssetsDepreciationList'
			},
			{
				title: '固定资产汇总',
				key: 'AssetsSummary'
			},
			{
				title: '固定资产对账',
				key: 'AssetsCheck'
			}
		]
	},
	// 期末
	{
		title: '期末结转',
		key: 'Checkout',
		icon: '期末结转',
		icons: '期末结转（选中）',
	},
	// 账簿
	{
		title: '账簿',
		key: 'AccountBook',
		icon: '账簿',
		icons: '账簿（选中）',
		children: [
			{
				title: '明细账',
				key: 'DetailedAccounts'
			},
			{
				title: '总账',
				key: 'GeneralLedger'
			},
			{
				title: '科目余额',
				key: 'SubjectBalance'
			},
			{
				title: '时序账',
				key: 'TimeSeries'
			},
			{
				title: '科目汇总',
				key: 'SubjectSummary'
			},
			{
				title: '核算项目明细账',
				key: 'AuxiliaryAccountingDetail'
			},
			{
				title: '核算项目余额',
				key: 'AuxiliaryAccountingBalance'
			}
		]
	},
	// 报表
	{
		title: '报表',
		key: 'ReportList',
		icon: '报表',
		icons: '报表（选中）',
		children: []
	},
	// 一键报税
	{
		title: '一键报税',
		key: 'TaxDeclaration',
		icon: '一键报税',
		icons: '一键报税（选中）',
		children: [
			{
				title: '税务申报',
				key: 'TaxDeclaration'
			}
		]
	},
	
	
	
	
	{
		title: '设置',
		key: 'Setting',
		icon: '设置',
		icons: '设置（选中）',
		children: [
			{
				title: '账套',
				key: 'Account'
			},
			{
				title: '科目',
				key: 'Subject'
			},
			{
				title: '期初',
				key: 'Initial'
			},
			{
				title: '币别',
				key: 'Currency'
			},{
				title: '凭证模版',
				key: 'VoucherTemplate'
			},{
				title: '老板看帐',
				key: 'BossSee'
			},{
				title: '操作日志',
				key: 'OptionLog'
			},{
				title: '关联进销存',
				key: 'ConnectJXC'
			},
			{
				title: '凭证字',
				key: 'VoucherWord'
			},
			{
				title: '辅助核算',
				key: 'AssistingAccounting'
			}, {
				title: '操作日志',
				key: 'TemplateManager'
			}
			, {
				title: '权限设置',
				key: 'PermissionSetting'
			}, {
				title: '备份恢复',
				key: 'BakUp'
			}, {
				title: '归档管理',
				key: 'ArchiveManagement'
			},
			{
				title: '重置初始化',
				key: 'SetReset'
			},
			{
				title: '旧账导入',
				key: 'OldAccount'
			},



		]
	}
];

const Making = [
	{
		title: '首页',
		key: 'Home',
		icon: '首页icon',
		icons: '首页（选中）',
	},
	{
		title: '凭证',
		key: 'vouchers',
		icon: '凭证',
		icons: '凭证（选中）',
		children: [
			{
				title: '新增凭证',
				key: 'VoucherForm'
			},
			{
				title: '凭证查询',
				key: 'Voucher'
			}
		]
	},
	{
		title: '期末结转',
		key: 'CheckList',
		icon: '期末结转',
		icons: '期末结转（选中）',
	},
	{
		title: '账簿',
		key: 'AccountBooks',
		icon: '账簿',
		icons: '账簿（选中）',
		children: [
			{
				title: '明细账',
				key: 'DetailedAccounts'
			},
			{
				title: '总账',
				key: 'GeneralLedger'
			},
			{
				title: '科目余额',
				key: 'SubjectBalance'
			},
			{
				title: '科目汇总',
				key: 'SubjectSummary'
			},
			{
				title: '核算项目明细账',
				key: 'AuxiliaryAccountingDetail'
			},
			{
				title: '核算项目余额',
				key: 'AuxiliaryAccountingBalance'
			}
		]
	},
	{
		title: '报表',
		key: 'ReportList',
		icon: '报表',
		icons: '报表（选中）',
	},
	{
		title: '设置',
		key: 'Setting',
		icon: '设置',
		icons: '设置（选中）',
		children: [
			{
				title: '账套',
				key: 'Account'
			}
		]
	}
];

const View = [
	{
		title: '首页',
		key: 'Home',
		icon: '首页icon',
		icons: '首页（选中）',
	},
	{
		title: '凭证',
		key: 'vouchers',
		icon: '凭证',
		icons: '凭证（选中）',
		children: [
			{
				title: '凭证列表',
				key: 'Voucher'
			}
		]
	},
	{
		title: '账簿',
		key: 'AccountBooks',
		icon: '账簿',
		icons: '账簿（选中）',
		children: [
			{
				title: '明细账',
				key: 'DetailedAccounts'
			},
			{
				title: '总账',
				key: 'GeneralLedger'
			},
			{
				title: '科目余额',
				key: 'SubjectBalance'
			},
			{
				title: '科目汇总',
				key: 'SubjectSummary'
			},
			{
				title: '核算项目明细账',
				key: 'AuxiliaryAccountingDetail'
			},
			{
				title: '核算项目余额',
				key: 'AuxiliaryAccountingBalance'
			}
		]
	},
	{
		title: '报表',
		key: 'ReportList',
		icon: '报表',
		icons: '报表（选中）',
	},
	{
		title: '设置',
		key: 'Setting',
		icon: '设置',
		icons: '设置（选中）',
		children: [
			{
				title: '账套',
				key: 'Account'
			}
		]
	}
];

const EnterpriseWorkbench = [
	{
		title: '应用列表',
		key: 'ApplicationList',
		icon: '首页icon',
		icons: '首页（选中）',
	},
	{
		title: '产品激活',
		key: 'ProductActivation',
		icon: '凭证',
		icons: '凭证（选中）',
	},
	{
		title: '个人中心',
		key: 'PersonalCenter',
		icon: '设置',
		icons: '设置（选中）',
	}
];

export default {
	Manager,
	Making,
	View,
	EnterpriseWorkbench
};
