<template>
	<div class='Sliderbox'>
		<span class='SliderTxt' v-if='slidertype' style='color:#fff'>验证完成</span>
		<span class='SliderTxt' v-if='!slidertype'>向右滑动完成验证</span>

		<Slider v-model="msg" id='Slidercss' class='Slidercss' 
		:showtip="false"
		 @change='changesi' :range='{start: 0, end: 100}'></Slider>
   </div>
</template>
<script>

	import code from "@/api/code";
	import SmsVerificationCode from "../../components/SmsVerificationCode"
	import UserAgreement from './UserAgreement'
	import axios from 'axios';
	export default {
		name: 'LoginForm',
		components: {SmsVerificationCode, UserAgreement},
		data() {
			return {
				
				msg: "",
				slidertype:false,
			}
		},
		mounted() {
		this.setCurrentFocusPos()
		},
		created() {
			//this.getCode()
		},
		methods: {
			changesi(val) {
			
			if(val > 91){
			 this.msg = 91;
			 this.slidertype = true;
			}else{
		 
			}
		 },
		 
		 


		  setCurrentFocusPos(){
				let that = this
				var slider = document.getElementById("Slidercss");
				slider.addEventListener('mouseup', function() {
				
					if(that.msg <91){
						that.msg = 0
						that.slidertype = false
					}
				});

			},
		}
	}
</script>

<style lang="less" scoped>
	.Sliderbox{
	border:1px solid #ccc;
	padding:5px 0;
	width:100%;
	display:flex;
	height:40px;
	align-items:center;
	position:relative;
	
	
}
.SliderTxt{
    position:absolute;
	top:3px;
	left:40%;
	color:#333;
	font-size:16px;
	font-weight:400;
	z-index:998 !important;
}
.Slidercss{
	width:100%;
	position:relative;
	
}

/deep/ .h-slider{
   padding:0!important;
}
// 滑块
/deep/ .h-slider-node{
	// background:#000 !important;
	width:40px;
	height:40px;
	padding:5px;
	overflow:hidden;
	border-radius:0px!important;
	margin-top:-12px;
	margin-left:0;
	transform:scale(1) !important;
	background: url("../../assets/to.png");
    background-size: 100% 100%;
	border:1px solid #ccc;
	z-index:999;
	
	
}
/deep/ .h-slider-node:hover{
	// transform:scale(0.8) !important;
	// transform:translate(-20px,0)
	
}
// 
/deep/ .h-slider-track {
	// background:#ccc !important;
	height:40px!important;
	border-radius:0px!important;
}
/deep/ .h-slider-line{
	background:none !important;
}
</style>
