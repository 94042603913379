<template>
	<div class="h-panel-search mb-16px">
		<div class="flex justify-between">
			<div class="flex">
				<div class="h-input-group mr-8px">
					<span class="">输入编码或名称：</span>
					<Input type="text" v-model="keyword" @keydown.enter="$emit('search')"/>
				</div>
				<Button color="primary" @click="$emit('search')"> 查询</Button>
			</div>
			<div>
				<Button v-if="category.categoryType !=='CASH_FLOW'" color="primary" @click="$emit('showForm')">新增</Button>
				<Button v-if="category.categoryType !=='CASH_FLOW'" @click="doImport">导入</Button>
				<Button @click="doExport">导出</Button>
				<Button v-if="category.categoryType !=='CASH_FLOW'" @click="clearData">清空</Button>
			</div>
		</div>
	</div>
</template>

<script>
/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
export default {
	name: "AAccountingTopBar"
}
</script>

<script setup>
import {ref, watch, h} from "vue";
import {confirm} from "heyui.ext";
import setting from "@/api/setting";
import {downloadBlob} from "download.js";
import AuxiliaryImport from "./form/AuxiliaryImport.vue";
import {layer} from "@layui/layer-vue";

let emits = defineEmits(['search', 'update:filter', 'showForm']);
let props = defineProps(['filter', 'category']);

const keyword = ref(props.filter)
watch(keyword, (val) => {
	emits('update:filter', val)
})

const clearData = () => {
	confirm({
		content: '亲，您确认要清空所有的数据吗?',
		async: true,
		onConfirm: (resolve) => {
			setting.accountingCategoryDetails.clearData(props.category.id, props.category.categoryType).then(() => {
				resolve(true);
				emits('search')
			})
		}
	})
}

const doImport = () => {
	let layerId = layer.open({
		title: props.category.name + "导入",
		shadeClose: false,
		closeBtn: false,
		zIndex: 1000,
		area: ['700px', 'auto'],
		content: h(AuxiliaryImport, {
			category: props.category,
			onClose: () => {
				layer.close(layerId);
			},
			onSuccess: () => {
				layer.close(layerId);
				emits('search')
			}
		})
	});
}

const doExport = () => {
	setting.accountingCategory.download(props.category.id, props.category.categoryType).then((blob) => {
		downloadBlob(props.category.name + "数据.xls", blob)
	})
}
</script>
