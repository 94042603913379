<template>
  <div class=" font-bold">
    <div class="h-panel-body padding" style="display: flex;align-items: center;border-bottom: 1px solid #e4e4e4;">
       <span class="dian1"></span>
       <span>未结转损益未结账</span>
       <span class="dian2"></span>
       <span>已结转损益未结账</span>
       <span class="dian3"></span>
       <span>已结转损益已结账</span>
    </div>
    <!-- <h3 class="margin text-center" v-if="!Object.keys(dataList).length">亲，目前没有已结账的期间！</h3> -->
    <!-- <template v-for="(year,yi) in years">
      <h2 class="margin-bottom">{{ year }}年</h2>
      <Row :space="20">
        <template v-for="(month,mi) in dataList[year]">
          <Cell width="3" v-if="month.status===2">
            <div class="period" :class="{disable:month.disable,completed:month.status===2}" @click="unCheck(yi,mi,month)">
              <div class="bg-primary-color month-header"></div>
              {{ month.checkMonth }}月
            </div>
          </Cell>
        </template>
      </Row>
    </template> -->
    <template v-for="(year,yi) in 1">
      <h2 class="margin-bottom titleBig" >2023</h2>
      <Row :space="20">
        <Cell width="3">
        <div class="period2"  >
            <span>0</span>
          </div>
        </Cell>
      </Row>
    </template>
  </div>
</template>

<script>

export default {
  name: "UnCheckOut",
  data() {
    return {
      dataList: {}
    }
  },
  computed: {
    years() {
      return Object.keys(this.dataList).sort((a, b) => b - a);
    }
  },
  methods: {
    loadList() {
      this.$api.checkout.list().then(({data}) => {
        let checkData = {};
        data.forEach(val => {
          if (val.status === 2) {
            if (!checkData[val.checkYear]) {
              checkData[val.checkYear] = [];
            }
            checkData[val.checkYear].push(val);
          }
        });
        this.dataList = checkData;
      })
    },
    unCheck(yi, mi, data) {
      if (yi === 0 && mi === 0) {
        this.$Confirm("确定进行反结账 ?", "反结账操作").then(() => {
          this.$Loading("正在反结账中...");
          this.$api.checkout.unCheck({year: data.checkYear, month: data.checkMonth}).then(() => {
            this.$store.dispatch('init');
            this.loadList();
          }).finally(() => {
            this.$Loading.close();
          });
        });
      } else {

      }
    }
  },
  created() {
    this.loadList();
  }
}
</script>


<style lang="less" scoped>
.h-row > div > div {
  padding: 8px;
  border-radius: 5px;
  text-align: center;
  font-size: 20px;
  color: #000;
}

.period {
  border-radius: 3px;
  text-align: center;
  line-height: 54px;
  border: 1px solid #929292;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
  height: 80px;

  &:hover {
    cursor: pointer;
    box-shadow: 0 0 20px 2px rgba(102, 100, 100, 0.24), 0px 2px 4px 0px rgba(199, 198, 198, 0.5);
  }

  .month-header {
    height: 25px;
    margin: -8px -8px 0 -8px;
  }

  &.completed {
    .month-header {
      background-color: #929292 !important;
      line-height: 20px;
    }

    color: #929292;
  }
}
.titleBig{
  font-size: 50px;
  font-weight: bold;
  color: #2FA8FD;
  text-align: center;
}
.dian1{
  display: inline-block;
  height: 16px;
  width: 16px;
  margin: 0 10px 0 20px;
  border-radius: 50%;
  background: #FD7B1D;
}
.dian2{
  display: inline-block;
  height: 16px;
  width: 16px;
  margin: 0 10px 0 20px;
  border-radius: 50%;
  background: #216EFF;
}
.dian3{
  display: inline-block;
  height: 16px;
  width: 16px;
  margin: 0 10px 0 20px;
  border-radius: 50%;
  background: #C4C4C4;
}
.period2 {
	border-radius: 3px;
	text-align: center;
	// line-height: 54px;
	// border: 1px solid @primary-color;
	white-space: nowrap;
	overflow: hidden;
	box-sizing: border-box;
	height: 80px;
    background-image: url('../../assets/month.png');
	background-size: 100% 100%;
	padding-top: 20px !important;
	span{
		font-size: 43px;
		font-weight: 400;
		color: #2FA8FD;
	}
	&:hover {
		cursor: pointer;
		// box-shadow: 0 0 20px 2px rgba(102, 100, 100, 0.24), 0px 2px 4px 0px rgba(199, 198, 198, 0.5);
	}

	.month-header {
		height: 25px;
		margin: -8px -8px 0 -8px;
	}

	&.completed {
		.month-header {
			background-color: @gray1-color !important;
			line-height: 20px;
		}

		border-color: @gray1-color;

		color: #a2a2a2;

		&:hover {
			cursor: default;
			box-shadow: none;
		}
	}

	&.disable {
		opacity: 0.4;

		&:hover {
			cursor: default;
			box-shadow: none;
		}
	}
}
</style>
