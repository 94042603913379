<template>
  <tr>
    <td nowrap class="report-row" :data-lv="lv">
      <span :class="{'font-bold':row.isBolder}" :style="`margin-left:${lv*20}px`">
      {{ row.title }} <span @click="editFormula(row)" v-if="!row.isClassified" class="report-edit-formulas text-12px primary-color text-hover">编辑公式</span>
      </span>
    </td>
    <template v-if="moretd">
      <template v-for="td in moretd">
        <slot :row="row" :td="td"/>
      </template>
    </template>
    <template v-else>
      <slot :row="row"/>
    </template>
  </tr>
  <template v-for="child in row.children ">
    <report-row :report="report" :parent="row" :row="child" :lv="lv+1" :moretd="moretd">
      <template #default="{row,td}">
        <slot :row="row" :td="td"/>
      </template>
    </report-row>
  </template>
</template>

<script>
/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
export default {
  name: "ReportRow"
}
</script>

<script setup>
import {layer} from "@layui/layer-vue";
import {h} from "vue";
import TemplateItemFormulaModal from "@/views/report/template/TemplateItemFormulaModal";
import EventBus from "@/js/common/EventBus";

const props = defineProps(['row', 'lv', 'parent', 'moretd', 'report']);

const {row, lv, parent, moretd, report} = props;

const editFormula = (row) => {
  let layerId = layer.open({
    title: "公式编辑",
    shadeClose: false,
    closeBtn: false,
    zIndex: 1000,
    area: ['1000px', 'auto'],
    content: h(TemplateItemFormulaModal, {
      templateItem: row, templateItems: report.items,
      onClose: () => {
        layer.close(layerId);
      },
      onSuccess: () => {
        EventBus.emit("report.reload")
        layer.close(layerId);
      }
    })
  });
}
</script>
