<template>
  <vxe-column v-if="isUnit" title="数量" field="yearTotalQuantity" align="right" width="90"/>
  <vxe-column v-if="isFor&&!isLocal" title="原币" field="yearTotalFor" align="right" width="100"/>
  <vxe-column :title="colTitle" field="yearTotal" align="right" width="100"/>
</template>

<script>
/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
export default {
  name: "YearTotalCol",
  props: {
    isUnit: Boolean,
    isFor: Boolean,
    isLocal: Boolean,
    beginCheck: Boolean,
  },
  computed: {
    colTitle() {
      if (this.isFor) {
        return '本位币';
      }
      return this.isUnit ? '金额' : '年初余额'
    }
  },
}
</script>
