<template>
	<div class="app-menu">
	    <div class='customer' @click='showcustom = !showcustom'>
			在<br>
			线<br>
			客<br>
			服<br>
			<img v-if='showcustom' class="cusimg"  src="../../assets/boss_code.png" />
		</div>
		<appLogo></appLogo>
		<div class="h-menu h-menu-dark ovscoll" style="background: none;box-shadow: 2px 0 4px 0 rgba(0, 21, 41, 0.2);height:calc(100vh - 60px)" :class="sliderCollapsed?'h-menu-mode-collapse':'h-menu-mode-vertical'">
			<li class="h-menu-li h-menu-first-level" v-for="m in menus" @click="trigger(m)">
				<Tooltip  v-if="sliderCollapsed&& m.children &&!m.children.length" :content="m.title" placement="right">
					<div class="h-menu-show " :class="{' h-menu-li-selected':selected(m)}" >
						<span class="h-menu-show-icon" v-if="m.icon">
							<i :class="m.icon"></i>
						</span>
						<span class="h-menu-show-desc text-16px" style='font-weight:400' >{{ m.title }}</span>
						<span class="h-menu-show-expand" v-if="m.children"><i class="h-icon-angle-down"></i></span>
					</div>
				</Tooltip>
				<template v-else>
					<div class="h-menu-show maintitle" :class="{' h-menu-li-selected':selected(m)}" style="line-height: 18px">
						<span class="h-menu-show-icon" v-if="m.icon">
							<img class="h-menu-show-image" v-if="selected(m)" :src="bindIcon(m.icons)" />
							<img class="h-menu-show-image" v-else :src="bindIcon(m.icon)" />
						</span>
						<span class="h-menu-show-desc text-16px center centerhover" style='font-weight:400'  v-if="!sliderCollapsed">{{ m.title }}</span>
						<span class="centerhover text-16px"  v-if="sliderCollapsed" style="margin-top: 10px;font-weight:400">{{ m.title }}</span>
						<!-- <span class="h-menu-show-expand" v-if="m.children">
							<i class="h-icon-angle-down"></i>
							22
						</span> -->
						<img v-if="m.children && !sliderCollapsed"
						 class="h-menu-show-image h-menu-show-desc" 
						 style="width: 7px;height: 10px;margin-right: 16px;" 
						 src="../../assets/arrow4-right.png"  />
						
					</div>
				</template>
				<div class=" h-menu-other-levels !flex xuanfu"  :class="{'flex-column':m.children.filter(val=>val.children).length===0}" v-if="m.children && m.children.length">
					<template v-for="c1 in m.children">
						<template v-if="c1.children && c1.children.length">
							<div class="flex-1 m-8px">
								<div class="py-8px px-4px primary-color  text-13px"> {{ c1.title }}</div>
								<div @click.stop="trigger(c2)" class="p-6px text-hover text-13px" :class="currentTab === c2.key?'font-bold primary-color':''" v-for="c2 in c1.children" :key="c2.key">
									{{ c2.title }}
								</div>
							</div>
						</template>
						<div v-else :class="[currentTab === c1.key?' primary-color':'']" class="py-10px px-16px text-hover text-16px" @click.stop="trigger(c1)">
							{{ c1.title }}
						</div>
					</template>
				</div>
			</li>
		</div>
		<div class="version">
			<div>v{{ VERSION }}</div>
			<!-- <div>{{ dFormat(BUID_DATE, 'YYYYMMDDHHmmss') }}</div> -->
		</div>
	</div>
</template>
<script>
	import {mapMutations, mapState} from 'vuex';
	import appLogo from './app-logo';
	import $ from 'jquery';
	import report from "../../api/report";

	export default {
		name: "AppMenu",
		props: {
			theme: String
		},
		data() {
			return {
				showcustom: false
			}
		},
		computed: {
			...mapState(['User', 'sliderCollapsed', 'currentAccountSets', 'currentTab', 'menus'])
		},
		methods: {
			...mapMutations(['newTab']),
			selected(item) {
				if (this.menus) {
					let find = this.menus.find(val => {
						if (val.key === this.currentTab) {
							return true;
						} else if (val.children) {
							for (const c1 of val.children) {
								if (c1.key === this.currentTab) {
									return true;
								} else if (c1.children) {
									for (const c2 of c1.children) {
										if (c2.key === this.currentTab) {
											return true;
										}
									}
								}
							}
						}
					})
					return find && (item.key === find.key);
				}
				return false;
			},
			trigger(data) {
				if (!data.children) {
					if (data.key === 'Home' && this.currentTab !== data.key) {
						this.$store.commit('refreshHomeTab', {key: "Home", title: "首页"})
					} else this.newTab(data);
				}
			},
			hideMenu() {
				this.$store.commit('updateSliderCollapse', true);
			},
			bindIcon(icon) {
				return require("@/assets/menu/" + icon + ".png");
			}
		},
		components: {
			appLogo
		},
		created() {
			// 后台获取报表菜单
			if (this.menus) {
				report.template.select().then(({data}) => {
					const reportList = this.menus.find(val => val.key === "ReportList")
					if (reportList) {
						data.forEach(item => {
							item.dimensions.forEach(d => {
								if (reportList.children) {
									reportList.children.push({
										title: item.name + (d === 'QUARTER' ? "季报" : ''),
										key: "ReportView:" + item.templateKey + (d === 'QUARTER' ? ":" + d : ''),
										params: {
											reportId: item.templateKey,
											dimension: d
										}
									})
								}
							})
						})
					}
				});
			}
		},
		mounted() {
			$('.h-menu .h-menu-first-level').hover((e) => {
				const maxHeight = window.document.body.scrollHeight;
				// console.log(maxHeight,'maxHeight')
				const other = $('.h-menu-other-levels', e.currentTarget);
				if (other.length) {
					const offset = $(e.currentTarget).offset()
					// console.log(offset,'offset')
					const height = other.height()
					// console.log(maxHeight - height - 30,'maxHeight - height - 30')
					if (offset.top + height > maxHeight) {
						other.offset({
							top: maxHeight - height - 30
						})
					} else {
						// 注释下代码后则使用样式自定义的顶部距离
						// other.offset({
						// 	top: offset.top 
						// })
					}
				}
			})
		}
	};
</script>
<style lang="less" scoped>
.h-menu-show-desc{
	color: #000;
}
.h-menu-show:hover span{
	color: #000;
}

// .ovscoll{
// 	height: calc(100vh - 60px);
//     overflow-y: scroll;
// }
// .ovscoll::-webkit-scrollbar {
//     display: none;
//   }
.maintitle{
	width: 100%;
	// height: 40px;
	// background: #ccc;
	padding: 10px 0;
	display: flex;
	flex-wrap: wrap;
	// justify-content: space-between;
	align-items: center;
	justify-content: center;
	font-weight: bold;
	.center{
		flex: 1;
		margin-left: 15px;
		
	}
}
.maintitle:hover .centerhover{
     color: #fff !important;
}
// 悬浮框样式
.xuanfu{
	background-color: #fff !important;
	// color: #333 !important;
	box-shadow: 0px 0px 10px 1px rgba(0,21,41,0.2);
    border-radius: 4px;
	font-size: 16px !important;
    text-align: left;
    font-weight: 400 !important;
    color: #666666 !important;
	// font-weight: bold;

	// transform: translate(-5px,10px);
}
	.version {
		position: absolute;
		font-weight: bold;
		bottom: 0;
		left: 0;
		width: 100%;
		color: white;
		text-align: center;
		z-index: -1;
		font-size: 12px;
		white-space: nowrap;
	}

	.h-menu-mode-vertical {
		> .h-menu-li {
			> .h-menu-show {
				padding-left: 10px;
			}
		}

	    // 侧边栏选中样式
		.h-menu-li-selected{
			// color: @white-color !important;
			color: #000 !important; 
			background: none;
			.center{
				color: var(--primary);
			}
		}

		.h-menu-show {
			overflow: hidden;
			text-overflow: ellipsis;
			color: #b3b3b3;
			padding: 15px 10px;
			transition: color @transition-time;

			&-icon {
				margin-right: 5px;
			}
			.h-menu-show-image {
				float: left;
				height: 18px;
			}

			&-expand {
				> i {
					transition: transform @transition-time;
					transform: rotate(-90deg);
				}
				right: 10px;
			}
		}
        //  悬浮窗口样式
		.h-menu-other-levels {
			background-color: #FFFFFF !important;
			box-shadow: @box-shadow;
			box-shadow:0px 0px 10px 1px rgba(0, 21, 41, 0.2);
			
			color: #001529 ;
			padding: 10px 0;
			margin-left: 4px;
			left: 100%;
			top: -5px ;
			border-radius: 4px;
			overflow: hidden;
			position: absolute;
			width: 0;
			transition: 0.2s cubic-bezier(0.78, 0.17, 0.43, 1);
			white-space: nowrap;
			display: inline-block;
			opacity: 0.8;
			transform: scale(0.8);
			transform-origin: left;
			max-height: initial !important;
        
			.h-menu-show {
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}

		.h-menu-li:hover {
			position: relative;
			> .h-menu-other-levels {
				
				width: auto;
				opacity: 1;
				transform: scale(1);
				overflow: visible;
				
				// 悬浮样式
				// border: 3px solid #3b76f5;
				// transform: translate(-5px,10px);
				// box-shadow: 0px 0px 10px 1px rgba(0,21,41,0.2);
                // border-radius: 8px 8px 8px 8px;
				&:before {
					content: '';
					position: absolute;
					height: 100%;
					top: 0;
					left: -10px;
					width: 10px;
				}
			}
		}
	}

	.h-menu.h-menu-mode-collapse .h-menu-other-levels {
		width: 0 !important;
		max-width: none !important;
	}

	.h-menu.h-menu-mode-collapse {
		.h-menu-li:hover {
			.h-menu-other-levels {
				width: auto !important;
				color: @white-color;
			}
		}
	}

	.h-menu-dark .h-menu-show:hover {
		background-color: var(--primary);
	}
	.h-menu-dark .h-menu-li-selected {
		background: none;
        color: var(--primary);
	}
	.setcss{
		border-radius:40px !important;
	}
	.customer{
		cursor:pointer;
		position:fixed;
		right:16px;
		top:50%;
		width: 36px;
		height: 131px;
		background: var(--primary);
		border-radius: 4px 4px 4px 4px;
		font-size: 16px;
		font-weight: 400;
		color: #FFFFFF;
		opacity: 1;
		display:flex;
		justify-content:center;
		align-items:center;
	}
	.cusimg{
		// width:200px  !important;
		// height:200px  !important;
		position:fixed;
		right:52px;
		top:calc(50% - 18px);
		// bottom:0;
		// right:0px;
	}
</style>
