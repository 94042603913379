<template>
  <Form class="m-16px" mode="twocolumn" :labelWidth="90" ref="templateItemForm" :rules="validationTifRules" :model="templateItemForm">
    <FormItem label="项目名称" prop="title">
      <Row type="flex" :space-x="10">
        <Cell :flex="1"><Input v-model="templateItemForm.title" type="text"/></Cell>
        <Cell>
          <Checkbox v-model="templateItemForm.isClassified" content="归类项不需要设置行号和公式">归类项</Checkbox>
        </Cell>
      </Row>
    </FormItem>
    <FormItem label="上级项目" prop="parentItem">
      <Select type="object" v-model="parentItem" :datas="templateItems" keyName="id">
        <template #default="{item}" slot="item">
          <span :style="{paddingLeft:(item.level*10)+'px'}">{{ item.title }}</span>
        </template>
      </Select>
    </FormItem>
    <FormItem label="行次" prop="lineNum" v-if="!templateItemForm.isClassified">
      <NumberInput :min="1" v-model="templateItemForm.lineNum" useOperate/>
    </FormItem>
    <FormItem label="取数来原" prop="sources" v-if="!templateItemForm.isClassified">
      <Radio v-model="templateItemForm.sources" dict="reportTemplateItemSources"></Radio>
    </FormItem>
    <FormItem single label="类别" prop="type" v-if="template.type == 1 && !templateItemForm.isClassified">
      <Radio v-model="templateItemForm.type" dict="reportTemplateItemType"></Radio>
    </FormItem>
    <FormItem label="名称加粗" prop="sources">
      <Radio v-model="templateItemForm.isBolder" dict="defaultRadios"></Radio>
    </FormItem>
    <FormItem label="是否折叠" prop="sources">
      <Radio v-model="templateItemForm.isFolding" dict="defaultRadios"></Radio>
    </FormItem>
    <FormItem label="显示位置" prop="pos">
      <NumberInput :min="1" v-model="templateItemForm.pos" useOperate/>
    </FormItem>
  </Form>
  <div class="p-16px text-center">
    <Button :loading="loading" @click="$emit('close')">取消</Button>
    <Button color="primary" :loading="loading" @click="addItem">保存</Button>
  </div>
</template>
<script>
import report from "@/api/report";

export default {
  name: 'TemplateItemModal',
  props: {
    template: Object,
    parent: Object,
    org: Object,
    templateItems: Array
  },
  data() {
    return {
      loading: false,
      parentItem: false,
      templateItemModel: false,
      templateItemForm: {
        title: '',
        lineNum: 1,
        isClassified: false,
        sources: 0,
        type: 0,
        isBolder: 0,
        isFolding: 0,
        pos: 1,
        level: 1
      }
    }
  },
  computed: {
    validationTifRules() {
      let required = ['title'];
      if (!this.templateItemForm.isClassified) {
        required = required.concat(['lineNum', 'sources']);
      }
      return {required};
    },
    maxLineNum() {
      let lineNum = 1;
      if (this.templateItems.length > 0) {
        this.templateItems.forEach(val => {
          lineNum = Math.max(lineNum, val.lineNum);
        });
        lineNum++;
      }
      return lineNum;
    }
  },
  methods: {
    addItem() {
      let validResult = this.$refs.templateItemForm.valid();
      if (validResult.result) {
        if (this.parentItem) {
          if (this.parentItem.level) {
            this.templateItemForm.level = this.parentItem.level + 1;
          }
          this.templateItemForm.parentId = this.parentItem.id;
        }
        this.loading = true;
        report.template.items[this.templateItemForm.id ? 'update' : 'save'](Object.assign(this.templateItemForm, {templateId: this.template.id})).then(() => {
          this.$emit("success");
        }).finally(() => {
          this.loading = false;
        });
      }
    }
  },
  created() {
    this.parentItem = this.parent;
    this.templateItemForm.lineNum = this.maxLineNum;
    if (this.org != null) {
      this.templateItemForm = Object.assign({}, this.org);
    }
  }
}
</script>
