<template>
	<div ref="modal" class="modal-column">

		
		<div class="flex-1 p-16px pr-50px" style="overflow-y: auto;position: relative">
			<Form :readonly="used" ref="form" :labelWidth="150" :model="form" :rules="validationRules" mode="block">
				<img v-if="used" class="used" src="@/assets/used.png" alt="已使用">
				<template v-if="faster">
          <FormItem label="上级科目" prop="parentId" required>
            <Select :deletable="false" :datas="subjectList" keyName="id" titleName="name" filterable type="object"
                    v-model="parentSubject" :filter="filter">
              <template v-slot:item="{ item }">
                {{ item.code }} {{ item.name }}
              </template>
            </Select>

          </FormItem>
          <FormItem label="科目类型" required prop="type">
            <Select :disabled="!!parentSubject" :datas="subjectType" keyName="title" v-model="form.type"
                    :deletable="false"/>
          </FormItem>
        </template>
				<FormItem label="科目编码" prop="code">
					<div class=" sub-code" v-if="!form.id && (parent || parentSubject)">
						<span class="sub-code-pre">{{ (parent || parentSubject).code }}</span>
						<Input type="text" :style="{paddingLeft:(parent || parentSubject).code.length*8+'px'} " v-model="form.code" :maxlength="len"/>
					</div>
					<Input v-else-if="!form.id && !parent" type="text" v-model="form.code" :maxlength="len"/>
					<Input v-else type="text" v-model="form.code" disabled/>
					<div class="pt-5px text-12px">*注意：科目编码规则： {{ encoding.join("-") }}。</div>
				</FormItem>
				<FormItem label="科目名称" prop="name">
					<Input type="text" v-model="form.name" :disabled="form.systemDefault"/>
				</FormItem>
				<FormItem label="上级科目" v-if="!faster">
					<Input type="text" :value="parent?parent.name:''" disabled/>
				</FormItem>
				<FormItem label="余额方向">
					<Radio v-model="form.balanceDirection" :datas="['借','贷']" :disabled="used"/>
				</FormItem>
				<FormItem label="状态">
					<Radio v-model="form.status" dict="statusRadios"></Radio>
				</FormItem>
				<FormItem single :showLabel="false">
					<div class="flex">
						<div class="w-80px mr-16px">
							<Checkbox v-model="numberAccounting" :disabled="disabledUni">数量核算</Checkbox>
						</div>
						<div v-if="numberAccounting" class="flex flex-1 items-center">
							<span class="mr-8px">计量单位</span> <Input type="text" v-model="unit" :disabled="disabledUni" class="!w-80px"/>
						</div>
					</div>
				</FormItem>
				<FormItem single :showLabel="false">
					<div class="flex">
						<div class="w-80px mr-16px">
							<Checkbox v-model="assistAccounting" :disabled="disabledAccounting">辅助核算</Checkbox>
						</div>
						<div v-if="assistAccounting" class="flex-1">
							<Checkbox :limit="5" v-model="auxiliaryAccounting" :datas="accountingCategory" keyName="id" titleName="name" :disabled="disabledAccounting"></Checkbox>
						</div>
					</div>
				</FormItem>
				<FormItem single :showLabel="false" v-if="currencyList.length>1">
					<div class="flex">
						<div class="w-80px mr-16px">
							<Checkbox v-model="assistCurrencyAccounting" :disabled="disabledCurrencyAccounting">外币核算</Checkbox>
						</div>
						<div v-if="assistCurrencyAccounting" class="flex-1">
							<Checkbox v-model="currencyAccounting" :datas="currencyList.filter(c=>!c.localCurrency)" keyName="id" titleName="name" :disabled="disabledCurrencyAccounting"></Checkbox>
						</div>
					</div>
				</FormItem>
			</Form>
			<Form :readonly="used" ref="form" :labelWidth="150" :model="form" :rules="validationRules" mode="block">
				<img v-if="used" class="used" src="@/assets/used.png" alt="已使用">
				<template v-if="faster">
          <FormItem label="上级科目" prop="parentId" required>
            <Select :deletable="false" :datas="subjectList" keyName="id" titleName="name" filterable type="object"
                    v-model="parentSubject" :filter="filter">
              <template v-slot:item="{ item }">
                {{ item.code }} {{ item.name }}
              </template>
            </Select>
          </FormItem>
          <FormItem label="科目类型" required prop="type">
            <Select :disabled="!!parentSubject" :datas="subjectType" keyName="title" v-model="form.type"
                    :deletable="false"/>
          </FormItem>
        </template>
				<FormItem label="科目编码" prop="code">
					<div class=" sub-code" v-if="!form.id && (parent || parentSubject)">
						<span class="sub-code-pre">{{ (parent || parentSubject).code }}</span>
						<Input type="text" :style="{paddingLeft:(parent || parentSubject).code.length*8+'px'} " v-model="form.code" :maxlength="len"/>
					</div>
					<Input v-else-if="!form.id && !parent" type="text" v-model="form.code" :maxlength="len"/>
					<Input v-else type="text" v-model="form.code" disabled/>
					<div class="pt-5px text-12px">*注意：科目编码规则： {{ encoding.join("-") }}。</div>
				</FormItem>
				<FormItem label="科目名称" prop="name">
					<Input type="text" v-model="form.name" :disabled="form.systemDefault"/>
				</FormItem>
				<FormItem label="上级科目" v-if="!faster">
					<Input type="text" :value="parent?parent.name:''" disabled/>
				</FormItem>
				<FormItem label="余额方向">
					<Radio v-model="form.balanceDirection" :datas="['借','贷']" :disabled="used"/>
				</FormItem>
				<FormItem label="状态">
					<Radio v-model="form.status" dict="statusRadios"></Radio>
				</FormItem>
				<FormItem single :showLabel="false">
					<div class="flex">
						<div class="w-80px mr-16px">
							<Checkbox v-model="numberAccounting" :disabled="disabledUni">数量核算</Checkbox>
						</div>
						<div v-if="numberAccounting" class="flex flex-1 items-center">
							<span class="mr-8px">计量单位</span> <Input type="text" v-model="unit" :disabled="disabledUni" class="!w-80px"/>
						</div>
					</div>
				</FormItem>
				<FormItem single :showLabel="false">
					<div class="flex">
						<div class="w-80px mr-16px">
							<Checkbox v-model="assistAccounting" :disabled="disabledAccounting">辅助核算</Checkbox>
						</div>
						<div v-if="assistAccounting" class="flex-1">
							<Checkbox :limit="5" v-model="auxiliaryAccounting" :datas="accountingCategory" keyName="id" titleName="name" :disabled="disabledAccounting"></Checkbox>
						</div>
					</div>
				</FormItem>
				<FormItem single :showLabel="false" v-if="currencyList.length>1">
					<div class="flex">
						<div class="w-80px mr-16px">
							<Checkbox v-model="assistCurrencyAccounting" :disabled="disabledCurrencyAccounting">外币核算</Checkbox>
						</div>
						<div v-if="assistCurrencyAccounting" class="flex-1">
							<Checkbox v-model="currencyAccounting" :datas="currencyList.filter(c=>!c.localCurrency)" keyName="id" titleName="name" :disabled="disabledCurrencyAccounting"></Checkbox>
						</div>
					</div>
				</FormItem>
			</Form>

			<Row :space="9" type="flex" justify="center">
				<Cell width="16">
					<Form :readonly="used"  ref="form" :labelWidth="150" :model="form" :rules="validationRules" mode="twocolumn">
						<img v-if="used" class="used" src="@/assets/used.png" alt="已使用">
						<template v-if="faster">
						<FormItem label="上级科目" prop="parentId" required>
							<Select :deletable="false" :datas="subjectList" keyName="id" titleName="name" filterable type="object"
									v-model="parentSubject" :filter="filter">
							<template v-slot:item="{ item }">
								{{ item.code }} {{ item.name }}
							</template>
							</Select>
						</FormItem>
						<FormItem label="科目类型" required prop="type">
							<Select :disabled="!!parentSubject" :datas="subjectType" keyName="title" v-model="form.type"
									:deletable="false"/>
						</FormItem>
						</template>
						<FormItem label="科目编码" prop="code" >
							<div class=" sub-code" v-if="!form.id && (parent || parentSubject)">
								<span class="sub-code-pre">{{ (parent || parentSubject).code }}</span>
								<Input type="text" :style="{paddingLeft:(parent || parentSubject).code.length*8+'px'} " v-model="form.code" :maxlength="len"/>
							</div>
							<Input v-else-if="!form.id && !parent" type="text" v-model="form.code" :maxlength="len"/>
							<Input v-else type="text" v-model="form.code" disabled/>
							<!-- <div class="pt-5px text-12px">*注意：科目编码规则： {{ encoding.join("-") }}。</div> -->
						</FormItem>
						<FormItem label="科目名称" prop="name">
							<Input type="text" v-model="form.name" :disabled="form.systemDefault"/>
						</FormItem>
						<FormItem label="上级科目" v-if="!faster" >
							<Input type="text" :value="parent?parent.name:''" disabled/>
						</FormItem>
						<FormItem label="科目类别" >
							<Input type="text"  />
						</FormItem>
						
						<FormItem label="余额方向">
							<Radio v-model="form.balanceDirection" :datas="['借','贷']" :disabled="used"/>
						</FormItem>
						<FormItem label="状态">
							<Radio v-model="form.status" dict="statusRadios"></Radio>
						</FormItem>
						<FormItem  single :showLabel="false">
							<div class="flex">
								<div class="w-80px ml-80px">
									<Checkbox v-model="numberAccounting" :disabled="disabledUni">数量核算</Checkbox>
								</div>
								<div v-if="numberAccounting" class="flex flex-1 items-center">
									<span class="mr-8px">计量单位</span> <Input type="text" v-model="unit" :disabled="disabledUni" class="!w-80px"/>
								</div>
							</div>
						</FormItem>
						<FormItem single :showLabel="false">
							<div class="flex">
								<div class="w-80px ml-80px">
									<Checkbox v-model="assistAccounting" :disabled="disabledAccounting">辅助核算</Checkbox>
								</div>
								<div v-if="assistAccounting" class="flex-1">
									<Checkbox :limit="5" v-model="auxiliaryAccounting" :datas="accountingCategory" keyName="id" titleName="name" :disabled="disabledAccounting"></Checkbox>
								</div>
							</div>
						</FormItem>
						<FormItem single :showLabel="false" v-if="currencyList.length>1">
							<div class="flex">
								<div class="w-80px ml-80px">
									<Checkbox v-model="assistCurrencyAccounting" :disabled="disabledCurrencyAccounting">外币核算</Checkbox>
								</div>
								<div v-if="assistCurrencyAccounting" class="flex-1">
									<Checkbox v-model="currencyAccounting" :datas="currencyList.filter(c=>!c.localCurrency)" keyName="id" titleName="name" :disabled="disabledCurrencyAccounting"></Checkbox>
								</div>
							</div>
						</FormItem>
					</Form>
				</Cell>
			</Row>
		</div>
		<div class="modal-column-between">
			<Button @click.stop="$emit('close')">取消</Button>
			<div>
				<Button v-if="!form.id " @click="submit(true)" :loading="loading">保存并新增</Button>
				<Button color="primary" @click.stop="submit()" :loading="loading">{{ form.id ? '更新' : '保存' }}</Button>
			</div>
		</div>
	</div>
</template>

<script>
import {mapState} from "vuex";
import {clone, padStart} from "xe-utils";
import Setting from "@/api/setting";
import setting from "@/api/setting";
import {confirm} from "heyui.ext";
import EventBus from "@/js/common/EventBus";
import {ArrToObj} from "@js/common/utils";

/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
export default {
	name: "SubjectForm",
	props: {
		type: {
			type: String,
			default: '资产'
		},
		maxCode: String,
		subject: Object,
		parent: Object,
		used: Boolean,
		faster: Boolean,
	},
	data() {
		return {
			parentUsed: false,
			subjectList: [],
			currencyList: [],
			encoding: [],
			accountingCategory: [],
			auxiliaryAccounting: [],
			currencyAccounting: [],
			unit: '',
			validationRules: {
				required: ["code", "name", 'type']
			},
			encodingSettingForm: false,
			parentSubject: null,
			numberAccounting: false,
			assistCurrencyAccounting: false,
			assistAccounting: false,
			form: {
        "id": "",
        "code": "",
        "name": "",
        "mnemonicCode": "",
        "unit": "",
        "balanceDirection": "借",
        "auxiliaryAccounting": null,
        "currencyAccounting": null,
        "level": 1,
        "parentId": null,
        "systemDefault": false,
        "type": this.type,
        "status": true,
		
      },
			loading: false
		}
	},
	watch: {
		parentSubject(val) {
			this.setParent(val)
		},
		auxiliaryAccounting(val) {
			let map = val.map(val => this.accountingCategoryMap[val]);
			if (map.find(val => val.categoryType === "VENDOR")) {
				this.accountingCategory.find(val => val.categoryType === "CUSTOMER").disabled = true;
			} else if (map.find(val => val.categoryType === "CUSTOMER")) {
				this.accountingCategory.find(val => val.categoryType === "VENDOR").disabled = true;
			} else {
				const customer = this.accountingCategory.find(val => val.categoryType === "CUSTOMER");
				const vendor = this.accountingCategory.find(val => val.categoryType === "VENDOR");
				if (customer) {
					customer.disabled = false;
				}
				if (vendor) {
					vendor.disabled = false;
				}
			}
		},
		assistAccounting(val) {
			if (!val) {
				this.auxiliaryAccounting = []
			}
		},
		numberAccounting(val) {
			if (!val) {
				this.unit = "";
			}
		}
	},
	computed: {
		...mapState(['currentAccountSets', 'subjectType']),
		accountingCategoryMap() {
			return ArrToObj(this.accountingCategory, val => val.id);
		},
		disabledUni() {
			return this.used
				|| (this.parent && this.parent.auxiliaryAccounting && this.parent.auxiliaryAccounting.length)
				|| (this.parentSubject && this.parentUsed)
		},
		disabledAccounting() {
			return this.used
				|| (this.parent && this.parent.auxiliaryAccounting && this.parent.auxiliaryAccounting.length)
				|| (this.parentSubject && this.parentUsed);
		},
		disabledCurrencyAccounting() {
			return ((this.used
					|| (this.parentSubject && this.parentUsed))
				&& (!this.subject || !this.subject.currencyAccounting)
			) || (this.parent && this.parent.currencyAccounting && this.parent.currencyAccounting.length);
		}
	},
	methods: {
		setParent(val) {
			if (val) {
				this.form.parentId = val.id;
				this.form.unit = val.unit;
				this.form.type = val.type;
				this.form.auxiliaryAccounting = val.auxiliaryAccounting;
				this.numberAccounting = !!this.form.unit;
				this.assistAccounting = this.form.auxiliaryAccounting && this.form.auxiliaryAccounting.length > 0;
				this.assistCurrencyAccounting = this.form.currencyAccounting && assistCurrencyAccounting.length > 0;

				this.unit = this.form.unit;

				this.form.balanceDirection = val.balanceDirection;
				const children = this.subjectList.filter(row => row.parentId === val.id);
				children.sort((a, b) => a.code - b.code);
				this.form.code = this.getLastCode(val, children);
				this.form.level = val.level + 1;

				setting.subject.checkUse(val.id).then(({data}) => {
					this.parentUsed = data;
				});
			} else {
				this.parentUsed = false;
				this.form.parentId = null;
				this.form.level = 1;
				this.form.unit = null;
				this.form.auxiliaryAccounting = null;
				this.form.currencyAccounting = null;
				this.form.code = '';
				this.form.type = '资产';
				this.form.balanceDirection = '借';
				this.numberAccounting = false;
				this.assistAccounting = false;
			}
      this.$refs.form.valid();
		},
		loadAccountingCategory() {
			return new Promise((resolve) => {
				setting.accountingCategory.list().then(({data}) => {
					data.forEach(val => val.disabled = false)
					this.accountingCategory = data;
					resolve()
				});
				Setting.currency.list().then(({data}) => {
					this.currencyList = data;
				})
			})
		},
		async submit(next = false) {
			let validResult = this.$refs.form.valid();
			if (validResult.result) {
				let formData = clone(this.form, true);
				if (!(this.assistAccounting && this.auxiliaryAccounting.length)) {
					formData.auxiliaryAccounting = null;
				} else {
					formData.auxiliaryAccounting = this.auxiliaryAccounting;
				}

				if (!(this.assistCurrencyAccounting && this.currencyAccounting.length)) {
					formData.currencyAccounting = null;
				} else {
					formData.currencyAccounting = this.currencyAccounting;
				}

				if (this.numberAccounting && this.unit) {
					formData.unit = this.unit;
				} else {
					formData.unit = null;
				}

				if (this.parent && !formData.id) {
					formData.code = this.parent.code + formData.code;
				}

				if (this.parentSubject) {
					formData.code = this.parentSubject.code + formData.code;
				}

				if (this.parent) {
					const {data} = await setting.subject.checkUse(this.parent.code)
					if (data) {
						const result = await new Promise((resolve) => {
							confirm({
								content: `上级科目已有数据，保存后，<br/>将同时新增同名下级科目${formData.code}${this.parent.name}替代，<br/>您确定要继续吗？`,
								onConfirm() {
									resolve(true)
								},
								onCancel() {
									resolve(false)
								}
							})
						})
						if (!result) {
							return
						}
					}
				}

				this.loading = true;
				setting.subject[formData.id ? 'update' : 'save'](formData).then(() => {
					EventBus.emit('reloadSubject')
					if (next) {
						this.form.name = null;
						this.form.unit = null;
						this.form.auxiliaryAccounting = null;
						this.form.currencyAccounting = null;
						this.form.balanceDirection = '借';
						this.numberAccounting = false;
						this.assistAccounting = false;
						this.loadSubject(() => {
							this.setParent(this.parentSubject)
						})
					} else {
						this.$emit('success', formData.code);
					}
				}).finally(() => {
					this.loading = false;
				})
			}
		},
		getLastCode(parent, children = []) {
			const reg = new RegExp(this.encoding.map(val => '(\\d{' + val + '})?').join(""))
			this.len = this.encoding[parent.level];
			if (children.length) {
				return reg.exec(String(Number(children[children.length - 1].code) + 1))[parent.level + 1];
			} else {
				return padStart("1", this.len, "0");
			}
		},
		filter(item, val) {
			return item.code.indexOf(val) !== -1 || item.name.indexOf(val) !== -1 || item.mnemonicCode.indexOf(val) !== -1;
		},
		loadSubject(cal) {
			setting.subject.voucherSelect({showAll: 1}).then(({data}) => {
				this.subjectList = data;
				if (cal) {
					cal.call(this)
				}
			});
		}
	},
	created() {
		this.encoding = this.currentAccountSets.encoding;
		this.len = this.encoding[0];
		this.lastCode = this.maxCode ? String(Number(this.maxCode) + 1) : null;
		if (this.parent) {
			this.lastCode = this.getLastCode(this.parent, this.parent.children);

			this.form.parentId = this.parent.id;
			this.form.level = this.parent.level + 1;
			this.form.balanceDirection = this.parent.balanceDirection;
		}

		this.loadAccountingCategory().finally(() => {
			if (this.parent) {
				this.form.auxiliaryAccounting = this.auxiliaryAccounting = this.parent.auxiliaryAccounting;
				this.form.currencyAccounting = this.currencyAccounting = this.parent.currencyAccounting;
				this.form.unit = this.unit = this.parent.unit;

				if (this.form.unit) {
					this.numberAccounting = true;
				}

				if (this.form.unit) {
					this.numberAccounting = true;
				}

				if (this.form.auxiliaryAccounting && this.form.auxiliaryAccounting.length > 0) {
					this.assistAccounting = true;
				}

				if (this.form.currencyAccounting && this.form.currencyAccounting.length > 0) {
					this.currencyAccounting = true;
				}
			}
			if (this.subject) {
				Object.keys(this.form).forEach(key => {
					this.form[key] = this.subject[key];
				})

				this.numberAccounting = !!this.subject.unit;
				this.assistAccounting = this.subject.auxiliaryAccounting.length > 0;
				this.assistCurrencyAccounting = this.subject.currencyAccounting.length > 0;
				this.unit = this.subject.unit;
				if (this.subject.auxiliaryAccounting) {
					this.auxiliaryAccounting = this.subject.auxiliaryAccounting;
				}
				if (this.subject.currencyAccounting) {
					this.currencyAccounting = this.subject.currencyAccounting;
				}
			} else {
				this.form.code = this.lastCode;
			}
		})

		if (this.faster) {
			this.loadSubject();
		}
	}
}
</script>
<style lang="less">
.used {
  position: absolute;
  right: 50px;
  z-index: 10;
  top: 0;
}
</style>
