<template>
  <app-content class="app-home-vue frame-page suixiao" v-if="currentTab==='Home'">
    <template v-if="currentAccountSets">
      <!-- <Row :space="24">
        <Cell width="14">
          <div class="h-panel" style="height: 230px">
            <div class="h-panel-bar">
              <div class="h-panel-title" style="font-size: 17px; color: black; font-weight: bold;">常用功能</div>
            </div>
            <Row :space="24" style="margin: 20px 10px">
              <Cell width="4">
                <div class="first-one" @click="jumpVouch('SubjectBalance')">
                  <img src="../assets/index/余额表.png" class="first-one-img"/>
                  <p>余额表</p>
                </div>
              </Cell>
              <Cell width="4">
                <div class="first-one" @click="jumpVouch('DetailedAccounts')">
                  <img src="../assets/index/明细账.png" class="first-one-img"/>
                  <p>明细账</p>
                </div>
              </Cell>
              <Cell width="4">
                <div class="first-one" @click="jumpVouch('zcfz')">
                  <img src="../assets/index/资产负债表.png" class="first-one-img"/>
                  <p>资产负债表</p>
                </div>
              </Cell>
              <Cell width="4">
                <div class="first-one" @click="jumpVouch('xjll')">
                  <img src="../assets/index/现金流量表.png" class="first-one-img"/>
                  <p>现金流量表</p>
                </div>
              </Cell>
              <Cell width="4">
                <div class="first-one" @click="jumpVouch('lr')">
                  <img src="../assets/index/利润表.png" class="first-one-img"/>
                  <p>利润表</p>
                </div>
              </Cell>
              <Cell width="4">
                <div class="first-one" @click="jumpVouch('AuxiliaryAccountingBalance')">
                  <img src="../assets/index/辅助核算余额表.png" class="first-one-img"/>
                  <p>辅助核算余额表</p>
                </div>
              </Cell>
            </Row>
          </div>
        </Cell>
        <Cell width="5">
          <div class="h-panel" style="height: 230px">
            <div class="h-panel-bar">
              <div class="h-panel-title" style="font-size: 17px; color: black; font-weight: bold;">凭证</div>
              <div class="h-panel-title" style="font-size: 10px;">（{{ dFormat(currentAccountSets.currentAccountDate, "YYYY/MM") }}）</div>
            </div>
            <div align="center">
              <Row :space="24" style="margin: 0 30px">
                <Cell width="12">
                    <span style="font-size: 14px; color: #666666">总数</span>
                    <p style="font-size: 20px; color: #666666">{{ voucherCount }}<b style="font-size: 12px; color: #666666">张</b></p>
                </Cell>
                <Cell width="12">
                    <span style="font-size: 14px; color: #666666">未审核</span>
                    <p style="font-size: 20px; color: #f86549">{{ unVoucherCount }}<b style="font-size: 12px; color: #666666">张</b></p>
                </Cell>
                <div>
                  <Button @click="addVouch" style="background-color: #4265d6; color: white; border-radius: 18px; width: 90%; height: 35px">新增凭证</Button>
                </div>
                <div>
                  <Button @click="checkVouch('Voucher')" style="color: #4265d6; border-color: #4265d6; border-radius: 18px; width: 90%; height: 35px; margin-top: 5px">查看凭证</Button>
                </div>
              </Row>
            </div>
          </div>
        </Cell>
        <Cell width="5">
          <div class="h-panel" style="height: 230px">
            <div class="h-panel-bar">
              <div class="h-panel-title" style="font-size: 17px; color: black; font-weight: bold;">发票管理</div>
            </div>
            <div>
              <Row :space="24" style="margin: 0 10px">
                <Cell width="12">
                  <div style="margin-top: 20px">
                    <div class="home-show-invoice">
                      <img src="../assets/index/进项发票.png" align="center">
                      <span style="font-size: 14px; color: #666666">进项发票</span>
                    </div>
                    <p style="font-size: 20px; color: #666666; text-align: center">{{ this.invoiceDataInfo.inputInvoice }}<b style="font-size: 14px; color: #666666">张</b></p>
                    <div style="background-color: #f5f5f5; border-radius: 4px; height: 35px; margin-top: 20px; padding: 6px 10px">
                      <span style="float: left; color: #666666">合计</span>
                      <span style="float: right; font-weight: bold">{{ this.invoiceDataInfo.inputInvoiceT }}</span>
                    </div>
                  </div>
                </Cell>
                <Cell width="12">
                  <div style="margin-top: 20px">
                    <div class="home-show-invoice">
                      <img src="../assets/index/销项发票.png">
                      <span style="font-size: 14px; color: #666666">销项发票</span>
                    </div>
                    <p style="font-size: 20px; color: #666666; text-align: center">{{ this.invoiceDataInfo.outputInvoice }}<b style="font-size: 14px; color: #666666">张</b></p>
                    <div style="background-color: #f5f5f5; border-radius: 4px; height: 35px; margin-top: 20px; padding: 6px 10px">
                      <span style="float: left; color: #666666">合计</span>
                      <span style="float: right; font-weight: bold">{{ this.invoiceDataInfo.outputInvoiceT }}</span>
                    </div>
                  </div>
                </Cell>
              </Row>
            </div>
          </div>
        </Cell>
      </Row> -->
      
      <Row :space="24" style="background: #fff;">
        <Cell width="24">
          <div class="h-panel">
            <div style="width: 100%; margin-top: 10px">
              <Row :space="24">
                <Cell width="6" @click="checkVouch('Voucher')">
                  <div class="home-show-image bg-orange">
                    <span class="panel-title" >查看凭证</span>
                    <p class="panel-amount">{{ this.cashDataInfo.backCash }}</p>
                  </div>
                </Cell>
                <Cell width="6">
                  <div class="home-show-image bg-light-orange" @click="jumpVouch('SubjectBalance')">
                    <span class="panel-title"> 科目余额表</span>
                    <p class="panel-amount">{{ this.cashDataInfo.stockCash }}</p>
                  </div>
                </Cell>
                <Cell width="6">
                  <div class="home-show-image bg-blue" @click="jumpVouch('zcfz')">
                    <span class="panel-title">资产负债表</span>
                    <p class="panel-amount">{{ this.cashDataInfo.receiveCash }}</p>
                  </div>
                </Cell>
                <Cell width="6">
                  <div class="home-show-image bg-purple" @click="jumpVouch('lr')">
                    <span class="panel-title" >利润表</span>
                    <p class="panel-amount">{{ this.cashDataInfo.payCash }}</p>
                  </div>
                </Cell>
              </Row>
            </div>
          </div>
        </Cell>
      </Row>

      <Row :space="0" style="background: #fff;width:100%" type="flex" justify="space-between"  @click="addVouch">
        <Cell width="6" class='f-w-c addbg' >
            <img src="@/assets/upload.png" alt="" style="width: 80px;height:80px">
             <div style='width:100%;text-align:center'>新增凭证</div>
        </Cell>
        <Cell width="18" class='f-w-c'>
            <img src="@/assets/headimg.png" alt="" style="width: 100%;height:140px">
        </Cell>
        <!-- <Cell width="24" style="display: flex;justify-content: center;color: #333333;">
            新增凭证
        </Cell> -->
       
      </Row>
      <div class="tips"> 2023年6月财务指标</div>

      <Row :space="24" style="background: #fff;margin-top:-50px;" justify="space-between" >
        <Cell width="8" ></Cell>
        
        <Cell width="8" style="display: flex;justify-content: center;align-items: center;margin: 20px 0;">
          <img src="@/assets/arrow4-left.png" alt="" style="width: 12px;">
           <span style="margin: 0 20px;font-size: 20px;font-weight: 400;">2023年6月</span>
           <img src="@/assets/arrow4-right.png" alt="" style="width: 12px;">
        </Cell>
        <Cell width="8" style="margin: 20px 0;">
          <SwitchList v-model="value1" :datas="param1"></SwitchList>
          <span style="margin: 0 20px;font-size: 16px;font-weight: 400;color: #999999;">单位：万元</span>
        </Cell>
        <Cell width="4" v-for="item,index in info" :key="index" style='width:20%'>
           <div style="display: flex;align-items: center;">
            <img style="width: 24px;height: 24px;" :src="item.imgurl" alt="">
            <span style="margin: 10px;">{{item.name}}</span>
           </div>
           <div style="background: #ccc;width: 20%;height: 24px;border-radius: 5px;"
            :style="{width:item.width,background:item.col}"></div>
           <div style="font-size: 26px;font-weight: 400;color: #333333;">1,004,500.00</div>
        </Cell>
       
      </Row>
      <!-- <div class="tips"> </div> -->
      <!-- <Row :space="24" style="background: #fff;">
        <Cell width="16">
          <div class="h-panel" style="height: 335px" id="hPanel">
            <div class="h-panel-bar">
              <div class="h-panel-title" style="font-size: 17px; color: black; font-weight: bold;">营收统计</div>
            </div>
            <div id="BusinessChart"></div>
          </div>
        </Cell>
        <Cell width="8">
          <div class="h-panel" style="height: 335px">
            <div class="h-panel-bar">
              <div class="h-panel-title" style="font-size: 17px; color: black; font-weight: bold;">费用分析</div>
            </div>
            <div id="CostChart"></div>
          </div>
        </Cell>
      </Row> -->
      
    </template>
      
    <div class="h-panel" v-else >
      <div class="h-panel-bar"><span class="h-panel-title" style="font-size: 17px; color: black; font-weight: bold;">初始化账套</span></div>
      <div class="h-panel-body">
        <AccountForm @success="onAccountSuccess" />
      </div>
    </div>
     
  </app-content>
</template>
<script>
  import revenueProfit from '../js/data/revenueProfit';
  import {mapMutations, mapState} from 'vuex';
  import AccountForm from "@/views/setting/account/AccountForm";
  import echarts from 'echarts'

  export default {
    name: 'Home',
    components: {AccountForm},
    data() {
      return {
        revenueProfitOptions: revenueProfit,
        cashData: [{name: '库存现金', value: 0}, {name: '银行存款', value: 0}, {name: '其他货币资金', value: 0}],
        costData: [{name: '销售费用', value: 0}, {name: '管理费用', value: 0}, {name: '财务费用', value: 0}],
        voucherCount: 0,
        unVoucherCount: 0,
        loading: false,
        datas: [],
        type: 'type1',
        cashDataInfo: {
          backCash: '0.00',
          stockCash: '0.00',
          receiveCash: '0.00',
          payCash: '0.00',
        },
        invoiceDataInfo: {
          inputInvoice: '0',
          inputInvoiceT: '0.00',
          outputInvoice: '0',
          outputInvoiceT: '0.00',
        },
        // 经营状况分析数据
        BusinessChartData: {
          dayData: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
          data1Count: [0, 1, 1, 5.70, 1, 1, 1, 1, 1, 1, 1, 1],
          data2Count: [1, 2, 2, 2, 2, 2, 2, 2, 3.7, 2, 2, 2],
          data3Count: [3, 3, 3, 1, 3, 3, 3, 3, 3.6, 3, 1, 3],
          data4Count: [1, 4, 4, 4, 4, 4, 1, 4, 4, 4, 2, 4],
          data5Count: [2, 5, 2, 3, 1, 4, 5, 5, 2.2, 5, 2, 1],
        },
        info: [
        {name:'货币资金',col:'#216EFF',num:'1,004,500.00',width:'10%',imgurl:require('@/assets/index/ic1.png')},
        {name:'应收账款',col:'#FD8125',num:'1,004,500.00',width:'100%',imgurl:require('@/assets/index/ic2.png')},
        {name:'存货    ',col:'#65D42D',num:'1,004,500.00',width:'70%',imgurl:require('@/assets/index/ic3.png')},
        {name:'固定资产',col:'#766EF7',num:'1,004,500.00',width:'70%',imgurl:require('@/assets/index/ic4.png')},
        {name:'应付账款',col:'#04C6AA',num:'1,004,500.00',width:'30%',imgurl:require('@/assets/index/ic5.png')},
        {name:'综合税负率',col:'#65D42D',num:'1,004,500.00',width:'70%',imgurl:require('@/assets/index/ic6.png')},
        {name:'应交税费',col:'#766EF7',num:'1,004,500.00',width:'20%',imgurl:require('@/assets/index/ic7.png')},
        {name:'营业收入',col:'#04C6AA',num:'1,004,500.00',width:'70%',imgurl:require('@/assets/index/ic8.png')},
        {name:'利润额',col:'#FD8125',num:'1,004,500.00',width:'70%',imgurl:require('@/assets/index/ic9.png')},
        {name:'净利率',col:'#216EFF',num:'1,004,500.00',width:'70%',imgurl:require('@/assets/index/ic10.png')},
       
        ],
        value1: '选择1',
        param1: ['月度指标', '年度指标'],
        
      };
    },
    computed: {
      ...mapState(['currentAccountSets', 'currentTab']),
      costTotal() {
        let total = 0;
        this.costData.forEach(item => {
          total += item.value;
        });
        return total.toFixed(2);
      },
      cashTotal() {
        let total = 0;
        this.cashData.forEach(item => {
          total += item.value;
        });
        return total.toFixed(2);
      }
    },
    methods: {
      ...mapMutations(['newTab']),
      addVouch() {
        this.newTab({
          title: '新增凭证',
          key: 'VoucherForm'
        })
      },
      init() {
        if (this.currentAccountSets) {
          Promise.all([
            Api.home.voucherCount(),
            Api.home.chart.revenueProfit(),
            Api.home.chart.cost(),
            Api.home.chart.cash(),
            // 未审核凭证数量接口
            // Api.home.unVoucherCountInfo(),
            // 发票管理接口
            // Api.home.queryInvoiceDataInfo(),
          ]).then((reslut) => {
            this.voucherCount = reslut[0].data;
            this.updateRevenueProfitOptions(reslut[1].data);
            this.cashData = reslut[3].data;

            this.costData = reslut[2].data;

            // this.cashDataInfo = reslut[2].data;
            // this.unVoucherCount = reslut[5].data;
            // this.invoiceDataInfo = reslut[6].data;
          });
        }
      },
      updateRevenueProfitOptions(chartData) {
        let lr = chartData["本年利润"];
        let zysr = chartData["主营业务收入"];
        let opt = Object.assign({}, this.revenueProfitOptions);
        if (lr) {
          Object.keys(lr).forEach(value => {
            opt.series[1].data[value - 1] = (lr[value] / 1000).toFixed(2);
          });
        }
        if (zysr) {
          Object.keys(zysr).forEach(value => {
            opt.series[0].data[value - 1] = (zysr[value] / 1000).toFixed(2);
          });
        }
        this.revenueProfitOptions = opt;
      },
      onAccountSuccess() {
        window.location.replace("/");
      },
      jumpVouch(key) {
        if (key === 'zcfz') {
          this.$store.commit('newTab', {key: 'ReportView:' + key, title: "资产负债表", params: {reportId: 'zcfz', dimension: ''}})
        } else if (key === 'lr') {
          this.$store.commit('newTab', {key: 'ReportView:' + key, title: "利润表", params: {reportId: 'lr', dimension: ''}})
        } else if (key === 'xjll') {
          this.$store.commit('newTab', {key: 'ReportView:' + key, title: "现金流量表", params: {reportId: 'xjll', dimension: ''}})
        } else if (key === 'SubjectBalance') {
          this.$store.commit('newTab', {key: key, title: "科目余额"})
        } else if (key === 'DetailedAccounts') {
          this.$store.commit('newTab', {key: key, title: "明细账"})
        } else if (key === 'AuxiliaryAccountingBalance') {
          this.$store.commit('newTab', {key: key, title: "核算项目余额"})
        }
      },
      checkVouch(key) {
        if (key === 'Voucher') {
          this.$store.commit('newTab', {key: key, title: "凭证列表"})
        }else if (key === 'VoucherForm') {
          this.$store.commit('newTab', {key: key, title: "新增凭证"})
        }
      },
      checkBusinessChart() {
        // document.getElementById('BusinessChart').removeAttribute("_echarts_instance_");
        document.getElementById('BusinessChart').style.width = document.getElementById('hPanel').offsetWidth + "px"
        let businessChart = echarts.init(document.getElementById('BusinessChart'), 'light')
        businessChart.clear()
        businessChart.setOption({
          legend: {	//图表上方的图例
            data: ['收入', '成本', '费用', '税金', '利润']
          },
          xAxis: {
            type: 'category',
            data: this.BusinessChartData.dayData,   // x轴数据
            name: '',   // x轴名称
            nameTextStyle: {	// x轴名称样式
              fontWeight: 600,
              fontSize: 18
            }
          },
          yAxis: {
            type: 'value',
            name: '',   // y轴名称
            nameTextStyle: {	// y轴名称样式
              fontWeight: 600,
              fontSize: 18
            }
          },
          grid: {
            left: 20,
            right: 20,
            bottom: 20,
            top: 30,
            containLabel: true
          },
          tooltip: {   //鼠标放到图上的数据展示样式
            trigger: 'axis',
            axisPointer: {
              type: 'cross'
            },
            padding: [5, 10]
          },
          series: [	//每条折线的数据系列
            {
              name: '收入',
              data: this.BusinessChartData.data1Count,
              type: 'line',
              smooth: true,
              animationDuration: 1800,
              animationEasing: 'cubicInOut',
              itemStyle: {
                normal: {
                  color: '#4565c4',
                  lineStyle: {
                    color: '#4565c4',
                    width: 2
                  }
                }
              },
            },
            {
              name: '成本',
              data: this.BusinessChartData.data2Count,
              type: 'line',
              smooth: true,
              animationDuration: 1800,
              animationEasing: 'cubicInOut',
              itemStyle: {
                normal: {
                  color: '#64c269',
                  lineStyle: {
                    color: '#64c269',
                    width: 2
                  }
                }
              },
            },
            {
              name: '费用',
              data: this.BusinessChartData.data3Count,
              type: 'line',
              smooth: true,
              animationDuration: 1800,
              animationEasing: 'cubicInOut',
              itemStyle: {
                normal: {
                  color: '#e2d82c',
                  lineStyle: {
                    color: '#e2d82c',
                    width: 2
                  }
                }
              },
            },
            {
              name: '税金',
              data: this.BusinessChartData.data4Count,
              type: 'line',
              smooth: true,
              animationDuration: 1800,
              animationEasing: 'cubicInOut',
              itemStyle: {
                normal: {
                  color: '#c054de',
                  lineStyle: {
                    color: '#c054de',
                    width: 2
                  }
                }
              },
            },
            {
              name: '利润',
              data: this.BusinessChartData.data5Count,
              type: 'line',
              smooth: true,
              animationDuration: 1800,
              animationEasing: 'cubicInOut',
              itemStyle: {
                normal: {
                  color: '#e00425',
                  lineStyle: {
                    color: '#e00425',
                    width: 2
                  }
                }
              },
            }
          ],
        })
      },
      checkCostChart() {
        // document.getElementById('CostChart').removeAttribute("_echarts_instance_");
        let costChart = echarts.init(document.getElementById('CostChart'))
        costChart.clear()
        costChart.setOption({
          tooltip: {
            trigger: 'item'
          },
          legend: {
            orient: 'horizontal',
            left: 'center',
            top: '3%',
            textStyle: {
              fontSize: 16,
              color: '#747474',
              padding:[3, 3]
            }
          },
          graphic: {
            type: "text",
            left: "47%",
            top: "45%",
            data: [12],
            style: {
              text: String(
                      this.costData.reduce((sum, item, index, array)=>{
                        return sum + item.value
                      }, 0.00)
              ).replace(/(\d)(?=(?:\d{6})+$)/g, '$1.') + "\n\n" +  "合计",
              textAlign: "center",
              fill: "#333",
              fontSize: 15,
            }
          },
          color: ['#4499ec', '#2ee261', '#efc541'],
          series: [
            {
              name: '费用分析',
              type: 'pie',
              center: ['50%', '50%'],
              radius: ['40%', '70%'],
              avoidLabelOverlap: false,
              label: {
                show: true,
                formatter(param) {
                  return param.value;
                }
              },
              labelLine: {
                show: true
              },
              data: this.costData
            }
          ]
        })
      }
    },
    created() {
      const currentTab = localStorage.getItem("currentTab")
      if (!currentTab) {
        this.$nextTick(() => {
          // this.checkBusinessChart()
          // this.checkCostChart()
        })
      }
      this.init();
    },
    mounted() {
      const currentTab = localStorage.getItem("currentTab")
      if (currentTab && JSON.parse(currentTab).key === 'Home') {
        // this.checkBusinessChart()
        // this.checkCostChart()
      }
    }
  };
</script>

<style lang="less" scoped>
  .first-one {
    cursor: pointer;
    text-align: center;

    &:hover {
      color: #f60 !important;
    }
  }

  .first-one-img {
    margin: auto;
  }

  .h-panel-body {
    ::v-deep .el-progress-bar__innerText {
      color: #000000;
    }
  }

  .el-divider--vertical {
    display: inline-block;
    width: 1px;
    height: 80px;
    margin-top: 35px;
    vertical-align: middle;
    position: relative;
  }

  .el-divider-horizontal {
    display: block;
    height: 1px;
    width: 100%;
    border-top: 1px var(--el-border-color) var(--el-border-style);
  }

  .home-show-image.bg-orange {
    // background-color: #ccc;
    background-image: url("../assets/index/i1.png");
    background-size: 100% 100%;
  }

  .home-show-image.bg-light-orange {
    background-image: url("../assets/index/i2.png");
    background-size: 100% 100%;
  }

  .home-show-image.bg-blue {
    background-image: url("../assets/index/i3.png");
    background-size: 100% 100%;
  }

  .home-show-image.bg-purple {
    background-image: url("../assets/index/i4.png");
    background-size: 100% 100%;
  }

  .home-show-image {
    width: 90%;
    height: 140px;
    display: inline-block;
    border-radius: 4px;
    box-shadow: 0px 1px 8px 0px rgba(196, 196, 196, 0.5);
    box-sizing: border-box;
    padding: 24px;
    background-size: 40%;
    background-position: 90% 50%;
    background-repeat: no-repeat;
    cursor: pointer;
    transition: all 0.3s;
    margin: 0 20px;
  }

  .home-show-image:hover {
    transform: scale(1.08);
  }

  .panel-title {
    font-size: 16px;
    color: #999;
    font-size: 32px;
    font-weight: bold;
    color: #36407B;
  }

  .panel-amount {
    font-size: 22px;
    color: #999;
    margin-top: 20px;
  }

  .home-show-invoice {
   text-align: center;
    img {
      height: 18px;
      display: inline;
      vertical-align: sub;
    }
  }

  #BusinessChart{
    width: 100%;
    height: 290px;
  }

  #CostChart{
    width: 100%;
    height: 290px;
  }

  .progress-bar{
    display:inline-block;
    width: 100%;
    box-sizing: border-box; /* 盒模型的方式 */
    margin-top: 12px;
    margin-bottom: 18px;
  }

  .progress-bar-outer{
    width: 100%;
    border-radius: 15px;
    background-color: #e1f0ff;
    height:30px;
    align-items: center;
    display: flex;
  }

  .progress-text{
    font-size: 12px;
    display: inline-block;
    vertical-align: middle;
    position: absolute;
    margin-left: 50%;
  }

  .progress-bar-inner{
    border-radius: 15px 15px 15px 15px;
    height: 100%;
    transition: width 0.6s ease;
    text-align: right;
    line-height: 80%;
  }
  .tips{
    margin: 20px 0;
    // border-left: 4px solid #2FA8FD;
    font-size: 20px;
    font-weight: 400;
    color: #333333;
    display: flex;
    align-items: center;
    // padding-left: 20px;
  }
  .tips::before{
     content:'';
     display:inline-block;
     width:4px;
     height:20px;
     margin-right:15px;
     background:#2FA8FD;
     border-radius:4px;
  }
  .suixiao{
    // width:500px;
    // height:500px;
    // background:#ccc;
    // display:flex;
    // justify-content:center;
  }
  .addbg{
    height:140px;
    width:24%;
    background-image: url("../assets/addbg.png");
    background-size: 100% 100%;
    margin-right:5px;
    box-sizing:border-box;
  }

</style>

