/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
const apis = require.context('.', false, /.*\.js$/);

let apiObj = {};

apis.keys().forEach(fileName => {
	if (fileName !== './index.js') {
		const name = fileName.substring(2, fileName.lastIndexOf('.'))
		apiObj[name] = apis(fileName).default;
	}
});

export default apiObj;
