<template>
  <div class="modal-column">
    <div class="modal-column-body">
      <Form mode="block">
        <FormItem label="业务类型" prop="invoiceBusinessTypeId" required>
          <Select :datas="businessList" keyName="id" :deletable="false" v-model="invoiceBusinessTypeId"/>
        </FormItem>
      </Form>
    </div>
    <div class="modal-column-between">
      <vxe-button :loading="loading" @click="$emit('close')" icon="fa fa-close">取消</vxe-button>
      <vxe-button :loading="loading" @click="doSubmit()" status="primary" icon="fa fa-check">保存</vxe-button>
    </div>
  </div>
</template>

<script>
import {Invoice, InvoiceBusiness} from "@/api/Invoice";

/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
export default {
  name: "InvoiceChangeBusinessType",
  props: {
    rows: Array,
    type: String
  },
  data() {
    return {
      loading: false,
      businessList: [],
      invoiceBusinessTypeId: null
    }
  },
  methods: {
    doSubmit() {
      this.loading = true;
      Invoice.batchUpdate(this.invoiceBusinessTypeId, this.rows.map(val => val.id)).then(() => {
        this.$emit('success')
      }).finally(() => this.loading = false)
    }
  },
  created() {
    InvoiceBusiness.list(this.type).then(({data}) => {
      this.businessList = data;
      this.invoiceBusinessTypeId = data[0].id;
    })
  }
}
</script>
