<template>
  <div class="p-16px">
    <div>
      <p>您正在删除账套：</p>
      <p class="red-color"><b>{{ rmData.companyName }}</b></p>
      <p>为保障数据安全，需要短信验证身份。</p>
    </div>
    <Row type="flex" :space="10">
      <Cell><Input type="text" v-model="User.mobile" disabled/></Cell>
      <Cell>
        <SmsVerificationCode :mobile="User.mobile"/>
      </Cell>
    </Row>
    <Row type="flex" :space="10">
      <Cell><Input v-model="msgCode" type="number" placeholder="请输入验证码"/></Cell>
    </Row>
  </div>
  <div class="text-center">
    <Button @click="$emit('close')" :loading="loading">取消</Button>
    <Button color="red" :disabled="!this.msgCode" @click="doRemove" :loading="loading">确认删除</Button>
  </div>
</template>

<script>
/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
import {mapState} from 'vuex'
import SmsVerificationCode from "@/components/SmsVerificationCode";

export default {
  name: "RemoveConfirm",
  components: {SmsVerificationCode},
  props: {
    rmData: Object,
  },
  data() {
    return {
      loading: false,
      msgCode: ''
    }
  },
  computed: {
    ...mapState(['User'])
  },
  methods: {
    doRemove() {
      if (this.msgCode) {
        this.$Confirm("确认删除?").then(() => {
          this.loading = true;
          this.$api.setting.accountSets.delete(this.rmData.id, this.msgCode).then(() => {
            this.$emit('success')
          }).finally(() => this.loading = false)
        })
      }
    }
  },
}
</script>
