<template>
	<div class="modal-column">
		<div class="padding flex-1">
			<p>第一步： 请点击下面的链接下载Excel模板，并按照模板填写信息</p>
			<a class="blue-color text-hover" :download="category.name+'导入模板.xls'" :href="`/api/accounting-category/template?categoryId=${category.id}&categoryType=${category.categoryType}`">下载模板</a>
			<p>第二步： 导入Excel模板文件</p>
			<span class="blue-color text-hover" @click="fileRef.click()">选取文件</span>
			<div class="h-30px mt-8px"><span v-if="fileName">已选：{{ fileName }}</span></div>
			<input type="file" style="visibility: hidden;position: absolute" @change="fileChange()" ref="fileRef">
		</div>
		<div class="modal-column-btn">
			<Button @click="$emit('close')" :loading="loading">取消</Button>
			<Button color="green" @click="importData" :disabled="!fileName" :loading="loading">导入</Button>
		</div>
	</div>
</template>

<script>
/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
export default {
	name: "AuxiliaryImport",
}
</script>

<script setup>
import {ref} from "vue";
import setting from "@api/setting";
import {message} from "heyui.ext";

let props = defineProps(['category']);
let emits = defineEmits(['success', 'close']);

const loading = ref(false)
const fileRef = ref(null)
const fileName = ref(null)
const file = ref(null)
const fileChange = () => {
	file.value = fileRef.value.files[0];
	fileName.value = file.value.name;
}
const importData = () => {
	if (fileRef.value.files.length) {
		let formData = new FormData();
		formData.append('file', file.value);
		formData.append('categoryId', props.category.id);
		formData.append('categoryType', props.category.categoryType);
		loading.value = true;
		setting.accountingCategory.import(formData).then(({data}) => {
			message(`总导入：${data.total}，新增：${data.inserted}，更新：${data.updated}`);
			fileRef.value.value = "";
			emits('success', data);
		}).finally(() => {
			loading.value = false;
		}).catch(() => {
			fileRef.value.value = "";
			file.value = null;
		});
	}
}
</script>
