/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
import Ajax from '../js/common/ajax';
import Qs from "qs";

export default {
	//账套管理
	accountSets: {
		list() {
			return Ajax.get("/account-sets")
		},
		load(id) {
			return Ajax.get(`/account-sets/${id}`)
		},
		delete(id, smsCode) {
			return Ajax.delete(`/account-sets/${id}/${smsCode}`)
		},
		reset(id, smsCode, enableDate, resetType) {
			return Ajax.get(`/account-sets/reset/${id}`, {smsCode, enableDate, resetType})
		},
		save(params = {}) {
			return Ajax.post(`/account-sets`, params)
		},
		addUser(params = {}) {
			return Ajax.get(`/account-sets/addUser`, params)
		},
		updateUserRole(params = {}) {
			return Ajax.get(`/account-sets/updateUserRole`, params)
		},
		addNewUser(params = {}) {
			return Ajax.get(`/account-sets/addNewUser`, params)
		},
		removeUser(uid) {
			return Ajax.get(`/account-sets/removeUser/${uid}`)
		},
		checkUse(accountSetsId) {
			return Ajax.get(`/account-sets/checkUse`, {accountSetsId})
		},
		update(params = {}) {
			return Ajax.put(`/account-sets`, params)
		},
		identification(params = {}) {
			return Ajax.post(`/account-sets/identification`, Qs.stringify(params))
		},
		handOver(params = {}) {
			return Ajax.post(`/account-sets/handOver`, Qs.stringify(params))
		},
		updateEncode(params = {}) {
			return Ajax.post(`/account-sets/updateEncode`, params)
		},
		getSettlementTypeList() {
			return Ajax.get(`/account-sets/settlementType`)
		},
		updateCurrentAccountDate(currentAccountDate) {
			return Ajax.get(`/account-sets/update/currentAccountDate`, {currentAccountDate})
		}
	},
	//凭证字
	voucherWord: {
		list() {
			return Ajax.get("/voucher-word")
		},
		load(id) {
			return Ajax.get(`/voucher-word/${id}`)
		},
		delete(id) {
			return Ajax.delete(`/voucher-word/${id}`)
		},
		save(params = {}) {
			return Ajax.post(`/voucher-word`, params)
		},
		update(params = {}) {
			return Ajax.put(`/voucher-word`, params)
		}
	},
	//币别
	currency: {
		list() {
			return Ajax.get("/currency")
		},
		load(id) {
			return Ajax.get(`/currency/${id}`)
		},
		delete(id) {
			return Ajax.delete(`/currency/${id}`)
		},
		save(params = {}) {
			return Ajax.post(`/currency`, params)
		},
		update(params = {}) {
			return Ajax.put(`/currency`, params)
		}
	},
	//科目
	subject: {
		list(type) {
			return Ajax.get("/subject?type=" + type)
		},
		load(id) {
			return Ajax.get(`/subject/${id}`)
		},
		delete(id) {
			return Ajax.delete(`/subject/${id}`)
		},
		save(params = {}) {
			return Ajax.post(`/subject`, params)
		},
		update(params = {}) {
			return Ajax.put(`/subject`, params)
		},
		voucherSelect(params = {}) {
			return Ajax.get(`/subject/voucher/select`, params)
		},
		loadByCode(params) {
			return Ajax.post(`/subject/loadByCode`, params)
		},
		listByCode(code = []) {
			return Ajax.get(`/subject/list/ByCode?` + Qs.stringify({code}, {indices: false}))
		},
		checkUse(subjectCode) {
			return Ajax.get(`/subject/checkUse/${subjectCode}`)
		},
		checkHasData(subjectCode) {
			return Ajax.get(`/subject/checkHasData/${subjectCode}`)
		},
		balance(params = {}) {
			return Ajax.post(`/subject/balance`, params, {repeatable: true})
		},
		import(params) {
			return Ajax.post(`/subject/import`, params, {'Content-Type': 'multipart/form-data'})
		}
	},
	//科目
	initialBalance: {
		list(params) {
			return Ajax.get("/initial-balance", params)
		},
		currencyList(type) {
			return Ajax.get("/initial-balance/currency", {type})
		},
		load(id) {
			return Ajax.get(`/initial-balance/${id}`)
		},
		delete(id) {
			return Ajax.delete(`/initial-balance/${id}`)
		},
		save(params = {}) {
			return Ajax.post(`/initial-balance`, params)
		},
		update(params = {}) {
			return Ajax.put(`/initial-balance`, params)
		},
		trialBalance() {
			return Ajax.get(`/initial-balance/trialBalance`)
		},
		saveAuxiliary(params) {
			return Ajax.post(`/initial-balance/auxiliary`, params)
		},
		import(params) {
			return Ajax.post(`/initial-balance/import`, params, {'Content-Type': 'multipart/form-data'})
		}
	},
	//辅助核算类别
	accountingCategory: {
		list() {
			return Ajax.get("/accounting-category")
		},
		listByIds(idsList) {
			return Ajax.post("/accounting-category/byid", idsList)
		},
		load(id) {
			return Ajax.get(`/accounting-category/${id}`)
		},
		delete(id) {
			return Ajax.delete(`/accounting-category/${id}`)
		},
		save(params = {}) {
			return Ajax.post(`/accounting-category`, params)
		},
		update(params = {}) {
			return Ajax.put(`/accounting-category`, params)
		},
		import(params) {
			return Ajax.post(`/accounting-category/import`, params, {'Content-Type': 'multipart/form-data'})
		},
		download(categoryId, categoryType) {
			return Ajax.get(`/accounting-category/download`, {categoryId, categoryType}, {
				responseType: 'blob'
			})
		}
	},
	accountingCategoryDetails: {
		list(categoryId, params = {}) {
			return Ajax.get(`/accounting-category-details?categoryId=${categoryId}`, params)
		},
		load(id) {
			return Ajax.get(`/accounting-category-details/${id}`)
		},
		delete(id) {
			return Ajax.delete(`/accounting-category-details/${id}`)
		},
		clearData(id, categoryType) {
			return Ajax.delete(`/accounting-category-details/clear/${id}`, {params: {categoryType}})
		},
		save(params = {}) {
			return Ajax.post(`/accounting-category-details`, params)
		},
		saveAd(params = {}) {
			return Ajax.post(`/accounting-category-details/ad`, params)
		},
		update(params = {}) {
			return Ajax.put(`/accounting-category-details`, params)
		},
		loadAuxiliaryAccountingData(params) {
			return Ajax.post(`/accounting-category-details/loadAuxiliaryAccountingData`, params)
		},
		loadAuxiliaryAccountingDataByName(params) {
			return Ajax.post(`/accounting-category-details/loadAuxiliaryAccountingDataByName`, params)
		},
		loadByName(name) {
			return Ajax.get(`/accounting-category-details/loadAuxiliaryAccountingDataByName`, {name})
		}
	},
	//用户
	user: {
		list() {
			return Ajax.get("/user")
		},
		load(id) {
			return Ajax.get(`/user/${id}`)
		},
		delete(id) {
			return Ajax.delete(`/user/${id}`)
		},
		save(params = {}) {
			return Ajax.post(`/user`, params)
		},
		update(params = {}) {
			return Ajax.put(`/user`, params)
		}
	},
	//模板
	voucherTemplate: {
		list() {
			return Ajax.get("/voucher-template")
		},
		load(id) {
			return Ajax.get(`/voucher-template/${id}`)
		},
		delete(id) {
			return Ajax.delete(`/voucher-template/${id}`)
		},
		save(params = {}) {
			return Ajax.post(`/voucher-template`, params)
		},
		update(params = {}) {
			return Ajax.put(`/voucher-template`, params)
		}
	},
	backup: {
		backup() {
			return Ajax.post(`/backup`)
		},
		list() {
			return Ajax.get(`/backup`)
		},
		delete(backId) {
			return Ajax.delete(`/backup/` + backId)
		},
		upload(formData) {
			return Ajax.post(`/backup/upload`, formData)
		},
		recover(backId, type) {
			return Ajax.get(`/backup/recover/` + backId + "/" + type)
		}
	}
}
