<template>
	<div class="modal-column">
		<div class="padding flex-1">
			<p>第一步： 请点击下面的链接下载Excel模板，并按照模板填写信息</p>
			<a class="blue-color text-hover" href="/api/voucher/template">下载模板</a>
			<p>第二步： 导入Excel模板文件</p>
			<span class="blue-color text-hover" @click="$refs.file.click()">选取文件</span>
			<div class="h-30px mt-8px"><span v-if="fileName">已选：{{ fileName }}</span></div>
			<input type="file" style="visibility: hidden;position: absolute" @change="fileChange()" ref="file">
		</div>
		<div class="modal-column-btn">
			<Button color="green" @click="importData" :disabled="!fileName" :loading="loading">导入</Button>
			<Button @click="$emit('close')" :loading="loading">取消</Button>
		</div>
	</div>
</template>

<script>
/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
import {mapActions} from 'vuex'

export default {
	name: "VoucherImport",
	data() {
		return {
			file: null,
			loading: false
		}
	},
	computed: {
		fileName() {
			if (this.file) {
				return this.file.name;
			}
			return '';
		}
	},
	methods: {
		...mapActions(['init']),
		importData() {
			if (this.$refs.file.files.length) {
				let formData = new FormData();
				formData.append('file', this.$refs.file.files[0]);
				this.loading = true;
				this.$api.voucher.import(formData).then(({data}) => {
					this.$Message("亲,导入成功~");
					this.$refs.file.value = "";
					this.init();
					this.$emit('success', data);
				}).finally(() => {
					this.loading = false;
				}).catch(() => {
					this.$refs.file.value = "";
					this.file = null;
				});
			}
		},
		fileChange() {
			this.file = this.$refs.file.files[0];
		}
	},
}
</script>
