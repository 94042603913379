<template>
  <Loading :loading="loading" text="报表数据计算中..."/>
 
  <vxe-toolbar class-name="px-8px">
    <template #buttons>
      
      <DatePicker v-if="dimension==='QUARTER'" v-model="voucherDate" type="quarter" :clearable="false"/>
      <account-date-choose v-else v-model="voucherDate"/>
    </template>
    <template #tools>
      
      <vxe-button :loading="dloading" @click="printData" icon="fa fa-print" content="打印"/>
      <vxe-button :loading="dloading" @click="exportData" icon="fa fa-download" content="导出"/>
    </template>
  </vxe-toolbar>
  <div ref="reportContent" class="flex" style="overflow-y: auto">
    <table class="cus-table flex-1">
      <thead class="header">
      <tr>
        <td>项目</td>
        <td class="text-right">本年累计金额</td>
        <td class="text-right">本期金额</td>
      </tr>
      </thead>
      <tbody>
      <template v-for="row in items">
        <report-row :report="report" :row="row" :lv="0">
          <template #default="{row}">
            <td class="text-right" :data-val="reportData?reportData.endTerm[row.id]:''"><span v-if="reportData">{{ numFormat(reportData.endTerm[row.id]) }}</span></td>
            <td class="text-right" :data-val="reportData?reportData.beginYear[row.id]:''"><span v-if="reportData">{{ numFormat(reportData.beginYear[row.id]) }}</span></td>
          </template>
        </report-row>
      </template>
      </tbody>
    </table>
  </div>
</template>

<script>
/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
export default {
  name: "LrbReportView"
}
</script>

<script setup>

import ReportApi from "@/api/report";
import {ref, toRefs, watch} from "vue";
import {toArrayTree} from "xe-utils";
import ReportRow from "@/views/report/view/ReportRow";
import {useStore} from "vuex";
import manba from "manba";
import jQuery from "jquery";
import Report from "@/api/report";
import {downloadBlob} from "download.js";

const store = useStore();
const {currentAccountSets} = store.state

const props = defineProps(['report', 'dimension']);

const {report, dimension} = toRefs(props);
const reportContent = ref(null);
const loading = ref(false);
const dloading = ref(false);
const voucherDate = ref(currentAccountSets.currentAccountDate)
const accountSetsName = ref(currentAccountSets.companyName)

const reportData = ref({
  beginYear: {},
  endTerm: {},
})

const items = toArrayTree(report.value.items);

const loadData = () => {
  loading.value = true;
  ReportApi.view(report.value.templateKey, report.value.id, {
    voucherDate: manba(voucherDate.value).startOf(manba.MONTH).format(),
    dimension: dimension.value
  }).then(({data}) => {
    reportData.value = Object.assign(reportData.value, data);
  }).finally(() => loading.value = false);
}
loadData()
watch(voucherDate, () => {
  loadData();
})

const printData = () => {
  const clone = jQuery(reportContent.value).clone();
  clone.find('.report-edit-formulas').remove()

  dloading.value = true;
  Report.print(jQuery('.p-header').html() + "<div class=\"flex\">" + clone.html() + "</div>").then((data) => {
    let myWindow = window.open(URL.createObjectURL(data), '利润表')
  }).finally(() => dloading.value = false)
}

const exportData = () => {
  const clone = jQuery(reportContent.value).clone();
  clone.find('.report-edit-formulas').remove()
  let dataList = [];
  clone.find("tr").each((idx, tr) => {
    let row = [];
    jQuery(tr).find('td').each((idx, td) => {
      let val = jQuery(td).data('val');
      let lv = jQuery(td).data('lv') || 0;
      if (!val || val === '-0') {
        val = jQuery(td).text();
      }
      for (let i = 0; i < lv; i++) {
        val = "    " + val
      }
      row.push(val)
    })
    dataList.push(row);
  })
  dloading.value = true;
  Report.download({
    title: '利润表' + (dimension.value === 'QUARTER' ? '季报' : ''),
    voucherDate: voucherDate.value,
    accountSetsName: `编制单位：${accountSetsName.value}`,
    dataList
  }).then((data) => {
    downloadBlob(manba().format("YYYYMMDDhhmmss") + "利润表" + (dimension.value === 'QUARTER' ? '季报' : '') + ".xls", data)
  }).finally(() => dloading.value = false)
}
</script>
