<template>
  <div class="h-full p-20px">
    <div class="h-full  flex flex-column"  v-if="!isupdate">
      <div class="flex p-16px justify-between bg-white-color">
        <div class="h-input-group">
          <!--          <span class="h-input-addon">编码或名称</span>-->
          <!-- <Input placeholder="请输入编码或名称" v-model="filterVal"/> -->
          <div class="h-input h-input-suffix-icon">
            <Input placeholder="请输入编码或名称" v-model="filterVal"/>
            <i class="h-icon-search" @click="loadList"></i>
          </div>
          <Button icon="fa " color="primary" @click="loadList">查询</Button>
        </div>
        <Button @click="showForm()" color="primary" icon="fa fa-plus">新增</Button>
      </div>
      <div class="flex-1">
        <vxe-table height="auto" ref="xTable" :loading="loading" show-overflow stripe :data="typeData">
          <vxe-column title="资产类别编码" field="code" width="100"/>
          <vxe-column title="资产类别名称" field="name"/>
          <vxe-column title="折旧方法" field="depreciationMethod"/>
          <vxe-column title="使用期限" field="useMonth"/>
          <vxe-column title="残值率" field="residualRate">
            <template #default="{row}">
              {{ row.residualRate }} %
            </template>
          </vxe-column>
          <vxe-column title="固定资产科目" field="costName"/>
          <vxe-column title="累计折旧科目" field="depreciationName"/>
          <vxe-column title="备注" field="remark"/>
          <vxe-column title="操作" :width="150" align="center" width="200">
            <template #default="{row}">
              <div class="actions">
                <span @click="showForm(row)">编辑</span>
                <span @click="remove(row)">删除</span>
              </div>
            </template>
          </vxe-column>
        </vxe-table>
      </div>
    </div>

    <div v-if="isupdate">
      <component is="AssetsTypeForm"
          @close="btnclick" 
          @success="loadList" 
          :type = tab
          :nextCode=nextCode
          :entity= entity />
    </div>


  </div>
   
</template>

<script>/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
import {layer} from "@layui/layer-vue";
import {h} from "vue";
import {padStart} from "xe-utils";
import AssetsType from "@/api/AssetsType";
import AssetsTypeForm from "@/views/assets/AssetsTypeForm";

export default {
  name: "AssetsType",
  data() {
    return {
      filterVal: null,
      loading: false,
      dataList: [],
      isupdate:false,
    }
  },
  components: {AssetsTypeForm},
  computed: {
    typeData() {
      return this.dataList.filter(val => val.type === this.tab)
    },
    nextCode() {
      return padStart((this.typeData.length + 1) + "", 3, '0')
    }
  },
  methods: {
    btnclick(params){
      this.isupdate = false
      // console.log(`子组件${params}触发`)
    },
    loadList() {
      this.loading = true
      this.isupdate = false
      console.log('保存')
      AssetsType.list(this.filterVal).then(({data}) => {
        this.dataList = data || [];
      }).finally(() => this.loading = false)
    },
    showForm(entity = null) {
      // store.commit('dataAll/data', '777') 
      this.entity = entity
      this.isupdate = true
      // this.$store.commit('dataAll/setdata', '888')
        //  = data
      return
      let layerId = layer.open({
        title: "固定资产类别信息",
        shadeClose: false,
        closeBtn: false,
        zIndex: 1000,
        area: ['800px', 'auto'],
        content: h(AssetsTypeForm, {
          entity,
          type: this.tab,
          nextCode: this.nextCode,
          onClose: () => {
            layer.close(layerId);
          },
          onSuccess: () => {
            this.loadList();
            layer.close(layerId);
          }
        })
      });
    },
    remove(row) {
      this.$Confirm("确认删除?").then(() => {
        this.loading = true;
        AssetsType.delete(row.id).then(() => {
          this.loadList();
        }).finally(() => this.loading = true)
      })
    }
  },
  created() {
    this.loadList();
  }
}
</script>
