<template>
	<div class="h-full p-20px">
		<div class="h-panel h-full flex flex-column">
			<Tabs class="px-20px" :datas="tabDatas" v-model="selected"/>
			<div class="h-panel-body padding flex-1 flex flex-column">
				<AccountingCategory @reloadTabs="reloadTabs" v-if="!showCategory"/>
				<template v-if="category">
					<component :is="categoryTypeMap[category.categoryType]" :category="category"/>
				</template>
			</div>
		</div>
	</div>
</template>
<script>
import AccountingCategory from "@/views/setting/AssistingAccounting/AccountingCategory";
import CategoryCustom from "@/views/setting/AssistingAccounting/CategoryCustom";
import setting from "@/api/setting";
import CashFlow from "@/views/setting/AssistingAccounting/CashFlow.vue";
import Customer from "@/views/setting/AssistingAccounting/Customer.vue";
import Department from "@/views/setting/AssistingAccounting/Department.vue";
import Employee from "@/views/setting/AssistingAccounting/Employee.vue";
import Organization from "@/views/setting/AssistingAccounting/Organization.vue";
import Project from "@/views/setting/AssistingAccounting/Project.vue";
import Stocks from "@/views/setting/AssistingAccounting/Stocks.vue";
import Vendor from "@/views/setting/AssistingAccounting/Vendor.vue";

export default {
	name: "AssistingAccounting",
	components: {Vendor, Stocks, Project, Organization, Employee, Department, Customer, CashFlow, CategoryCustom, AccountingCategory},
	data() {
		return {
			category: null,
			showCategory: false,
			selected: 'CUSTOMER',
			categoryTypeMap: {
				"CUSTOMER": 'Customer',
				"VENDOR": 'Vendor',
				"STOCKS": 'Stocks',
				"PROJECT": 'Project',
				"ORGANIZATION": 'Organization',
				"EMPLOYEE": 'Employee',
				"DEPARTMENT": 'Department',
				"CASH_FLOW": 'CashFlow',
				"CUSTOMIZATION": 'CategoryCustom',
			},
			categoryMap: {},
			tabDatas: [{
				title: '辅助核算类别',
				key: 'AccountingCategory'
			}]
		};
	},
	watch: {
		selected(val) {
			if (val === 'AccountingCategory') {
				this.showCategory = false;
				this.category = null;
			} else {
				this.category = this.categoryMap[val];
				this.categoryId = val;
				this.showCategory = true;
			}
		}
	},
	mounted() {
		this.init();
	},
	methods: {
		init() {
			setting.accountingCategory.list().then(({data}) => {
				let tabDatas = [{
					title: '辅助核算类别',
					key: 'AccountingCategory'
				}];
				data.forEach(val => {
					this.categoryMap[val.id] = val
					tabDatas.push({
						title: val.name,
						key: val.id
					})
				});
				this.selected = data[0].id;
				this.tabDatas = tabDatas;
			});
		},
		reloadTabs() {
			setting.accountingCategory.list().then(({data}) => {
				let tabDatas = [{
					title: '辅助核算类别',
					key: 'AccountingCategory'
				}];
				data.forEach(val => {
					this.categoryMap[val.id] = val
					tabDatas.push({
						title: val.name,
						key: val.id
					});
				});
				this.tabDatas = tabDatas;
			});
		}
	}
};
</script>
<style lang='less'>
div.h-panel-bar {
  border-top: 0 solid #eeeeee
}
</style>


