<template>
    <div class="flex flex-column h-full p-20px">
        <div class="h-panel h-full flex flex-column">
            <Tabs class="px-20px" :datas="subjectType" v-model="selected"></Tabs>
            <div class="pt-16px pr-16px flex justify-end" style="align-items: center">
                <div class="h-input-group mr-16px" v-if="isFor">
                    <span class="h-input-addon">币别</span>
                    <Select class="min-w-120px" keyName="id" titleName="name" type="object" v-model="currency"
                            :datas="currencyList" :deletable="false"/>
                    <span class="h-input-addon" v-if="currency">汇率：{{ currency.exchangeRate }}</span>
                </div>
                <div class="actions" style="margin-right: 10px">
                <a >帮助</a>
                </div>
                <Checkbox v-if="isUnit" v-model="hideUni" class="mr-16px">隐藏数量</Checkbox>
                <Button @click="doUpload" icon="fa fa-upload" v-if="!beginCheck">
                    导入
                </Button>
                <a class="h-btn" href="/api/initial-balance/download">
                    <i class="fa fa-download"></i>
                    导出
                </a>
                <Button @click="trialBalance" color="primary">试算平衡</Button>
            </div>
            <div class="flex-1 p-16px h-0px">
                <vxe-table :loading="loading" ref="xTable" :data="datas"

                           height="auto"
                           :row-config="{keyField:'treeId',height:30}"
                           :tree-config="{transform: true, expandAll:true,showIcon:false,rowField: 'treeId', parentField: 'parentTreeId'}">
                    <vxe-column title="科目编码" field="subjectCode" tree-node :min-width="200" :max-width="250"/>
                    <vxe-column title="科目名称" field="subjectName" :min-width="200">
                        <template #default="{row}">
                            <span class="mr-8px">{{ row.subjectName }}</span>
                            <Tooltip
                                    v-if="row.auxiliaryAccounting.length && currency && currency.id!==0 && row.auxiliaryDetailId ==='0'"
                                    content="点击添加辅助项期初">
                                <i @click="addAuxiliary(row)" class="fa fa-plus-circle text-hover"></i>
                            </Tooltip>
                        </template>
                    </vxe-column>
                    <vxe-column title="余额方向" field="balanceDirection" width="120" align="center"/>
                    <amount-group-col :beginCheck="beginCheck" :type="selected"
                                      v-if="(isUnit || !this.isLocal) && !this.hideUni" :is-unit="isUnit"
                                      :is-for="isFor" :is-local="isLocal" :is-start-year="isStartYear"
                                      :val-change="valChange"/>
                    <amount-col :beginCheck="beginCheck" :type="selected" v-else :is-start-year="isStartYear"
                                :val-change="valChange"/>
                </vxe-table>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex';
import manba from 'manba';
import {layer} from "@layui/layer-vue";
import {h} from "vue";
import InitialImport from "@/views/setting/initial/InitialImport";
import Np from "number-precision";
import InitialTrial from "@/views/setting/initial/InitialTrial";
import setting from "@/api/setting";
import AmountCol from "@/views/setting/initial/AmountCol.vue";
import AmountGroupCol from "@/views/setting/initial/AmountGroupCol.vue";
import YearTotalCol from "@/views/setting/initial/YearTotalCol.vue";
import InitialAuxiliary from "@/views/setting/initial/InitialAuxiliary.vue";
import {pick} from "xe-utils";
import checkout from "@api/checkout";


export default {
    name: "Initial",
    components: {YearTotalCol, AmountGroupCol, AmountCol},
    data() {
        return {
            datas: [],
            codeUsed: [],
            currencyList: [],
            selected: '资产',
            loading: false,
            hideUni: false,
            beginCheck: false,
            currency: null,
        };
    },
    computed: {
        ...mapState(['currentAccountSets', 'subjectType', 'currencyMap']),
        ...mapGetters(['localCurrency']),
        isUnit() {
            return this.datas.filter(value => value.unit).length > 0;
        },
        isStartYear() {
            return manba(this.currentAccountSets.enableDate).month() === 1;
        },
        currencyId() {
            return this.currency ? this.currency.id : this.localCurrency.id;
        },
        isFor() {
            return this.currencyList.length > 0;
        },
        isLocal() {
            return this.localCurrency.id === this.currencyId || this.currencyId === 0;
        },
        exchangeRate() {
            return Number(this.currency.exchangeRate);
        }
    },
    watch: {
        selected() {
            this.loadCurrencyList();
            this.loadList();
        },
        currency() {
            this.loadList();
        }
    },
    methods: {
        valChange(row, field) {
            row[field] = row[field] || 0;
            let widthFiled, calVal;
            switch (field) {
                case 'beginForBalance':
                    calVal = Np.times(row.beginForBalance, this.exchangeRate);
                    widthFiled = 'beginBalance';
                    break
                case 'beginBalance':
                    calVal = Np.divide(row.beginBalance, this.exchangeRate);
                    widthFiled = 'beginForBalance';
                    break
                case 'yearTotalDebitFor':
                    calVal = Np.times(row.yearTotalDebitFor, this.exchangeRate);
                    widthFiled = 'yearTotalDebit';
                    break
                case 'yearTotalDebit':
                    calVal = Np.divide(row.yearTotalDebit, this.exchangeRate);
                    widthFiled = 'yearTotalDebitFor';
                    break
                case 'yearTotalCreditFor':
                    calVal = Np.times(row.yearTotalCreditFor, this.exchangeRate);
                    widthFiled = 'yearTotalCredit';
                    break
                case 'yearTotalCredit':
                    calVal = Np.divide(row.yearTotalCredit, this.exchangeRate);
                    widthFiled = 'yearTotalCreditFor';
                    break
            }

            let changeRows = {
                [row.id]: row
            };
            if (row.parentTreeId !== '0') {
                this.calParent(row, field, changeRows)

                if (widthFiled) {
                    row[widthFiled] = Np.round(calVal, 2)
                    this.calParent(row, widthFiled, changeRows)
                }
            } else {
                this.calTotal(row)
            }

            let updateRows = Object.values(changeRows).map(val => {
                let row = pick(val, ["id", 'currencyId', 'subjectId', 'auxiliaryDetailId', 'accountSetsId', "beginBalance", "beginForBalance", "endBalance", "endForBalance", "debitFor", "debit", "creditFor", "credit", "yearTotalDebitFor", "yearTotalDebit", "yearTotalCreditFor", "yearTotalCredit", "yearTotalFor", "yearTotal", "beginQuantity", "endQuantity", "creditQuantity", "debitQuantity", "yearTotalCreditQuantity", "yearTotalDebitQuantity", "yearTotalQuantity"])
                if (val.balanceDirection === '借') {
                    row.endBalance = row.beginBalance + row.debit - row.credit;
                    row.endBalanceFor = row.beginBalanceFor + row.debitFor - row.creditFor;
                } else {
                    row.endBalance = row.beginBalance - row.debit + row.credit;
                    row.endBalanceFor = row.beginBalanceFor - row.debitFor + row.creditFor;
                }

                if (val.debit) {
                    row.yearTotalDebit += val.debit
                }
                if (val.debitFor) {
                    row.yearTotalDebitFor += val.debitFor
                }
                if (val.credit) {
                    row.yearTotalCredit += val.credit
                }
                if (val.creditFor) {
                    row.yearTotalCreditFor = val.creditFor
                }
                if (val.debitQuantity) {
                    row.yearTotalDebitQuantity += val.debitQuantity
                }
                if (val.creditQuantity) {
                    row.yearTotalCreditQuantity += val.creditQuantity
                }
                return row;
            });

            setting.initialBalance.save(updateRows);
        },
        loadCurrencyList() {
            setting.initialBalance.currencyList(this.selected).then(({data}) => {
                if (data.length) {
                    this.currencyList = [{id: 0, name: '综合本位币', exchangeRate: '1.00000000'}, ...data]
                } else {
                    this.currencyList = [];
                }
            })
        },
        loadList() {
            this.loading = true;
            setting.initialBalance.list({type: this.selected, currencyId: this.currencyId}).then(({
                                                                                                      data: {
                                                                                                          balances,
                                                                                                          codeUsed
                                                                                                      }
                                                                                                  }) => {
                this.datas = balances;
                this.codeUsed = codeUsed || [];
                this.$nextTick(() => {
                    this.$refs.xTable.setAllTreeExpand(true);
                });
            }).finally(() => {
                this.loading = false;
            })
        },
        calTotal(row) {
            /**
             * 年初原币金额
             * 借方科目：年初数 = 期初数 + 本年累计贷方 - 本年累计借方
             * 贷方科目：年初数 = 期初数 + 本年累计借方 - 本年累计贷方
             */
            if (row.balanceDirection === '借') {
                row.yearTotal = Np.round(Np.plus(row.beginBalance, Np.minus(row.yearTotalCredit, row.yearTotalDebit)), 2);
                row.yearTotalFor = Np.round(Np.plus(row.beginBalanceFor, Np.minus(row.yearTotalCreditFor, row.yearTotalDebitFor)), 2);
                row.yearTotalQuantity = Np.round(Np.plus(row.beginQuantity, Np.minus(row.creditQuantity, row.debitQuantity)), 2);

            } else {
                row.yearTotal = Np.round(Np.plus(row.beginBalance, Np.minus(row.yearTotalDebit, row.yearTotalCredit)), 2);
                row.yearTotalFor = Np.round(Np.plus(row.beginBalanceFor, Np.minus(row.yearTotalDebitFor, row.yearTotalCreditFor)), 2);
                row.yearTotalQuantity = Np.round(Np.plus(row.beginQuantity, Np.minus(row.debitQuantity, row.creditQuantity)), 2);
            }
        },
        calParent(row, field, changeRows) {
            const parent = this.$refs.xTable.getRowById(row.parentTreeId);
            parent[field] = parent.children.reduce((sum, it) => sum + it[field], 0)
            this.calTotal(row);
            this.calTotal(parent);
            changeRows[parent.id] = parent;
            if (parent.parentTreeId !== '0') {
                this.calParent(parent, field, changeRows);
            }
        },
        trialBalance() {
            layer.open({
                title: "期初试算结果",
                zIndex: 1000,
                area: ['900px', '300px'],
                content: h(InitialTrial)
            });
        },
        doUpload() {
            let layerId = layer.open({
                title: "财务期初余额导入",
                shadeClose: false,
                closeBtn: false,
                zIndex: 1000,
                area: ['500px', 'auto'],
                content: h(InitialImport, {
                    onClose: () => {
                        layer.close(layerId);
                    },
                    onSuccess: () => {
                        this.loadList();
                        layer.close(layerId);
                    }
                })
            });
        },
        addAuxiliary(row) {
            let layerId = layer.open({
                title: row.subjectName + "辅助明细",
                shadeClose: false,
                closeBtn: false,
                zIndex: 1000,
                area: ['1000px', '600px'],
                content: h(InitialAuxiliary, {
                    subject: row,
                    currency: this.currency,
                    onClose: () => {
                        layer.close(layerId);
                    },
                    onSuccess: () => {
                        layer.close(layerId);
                        this.loadList();
                    }
                })
            });
        }
    },
    created() {
        checkout.beginCheck().then(({data}) => {
            this.beginCheck = data;
        })
    },
    mounted() {
        this.currency = this.localCurrency;
        this.loadCurrencyList();
        this.loadList();
    }
};
</script>
<style scoped lang="less">
:deep(.vxe-input.size--mini) {
  height: 20px;
  line-height: 20px;
}
</style>
