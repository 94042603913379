<template>
  <div class="modal-column">
    <div class="modal-column-full-body">
      <Form mode="block" prop="fieldName" :model="form" :rules="{}" ref="form">
        <FormItem label="字段类型" prop="fieldType">
          <Radio v-model="form.fieldType" :datas="['系统字段', '输入字段', '计算字段']"/>
        </FormItem>
        <FormItem label="字段名称" required prop="fieldName">
          <Input v-model.trim="form.fieldName"/>
          <div class="pt-6px text-12px green-color">字段名称不能重复</div>
          <div v-if="form.fieldType==='输入字段'" class="pt-6px text-12px blue-color">
            输入字段在计算之前需要进行数据录入
          </div>
        </FormItem>
        <FormItem label="数据类型" prop="dataType">
          <Radio v-model="form.dataType" :datas="['数值型', '字符型']"/>
        </FormItem>
        <FormItem label="项目配置" prop="proConfig">
          <Select :deletable="false" v-model="form.proConfig" :datas="{'工资总额': '工资总额 ', '应扣工资': '应扣工资', '实发工资调整': '实发工资调整'}"/>
        </FormItem>
        <FormItem label="系统字段" v-if="form.fieldType==='系统字段'" prop="formula">
          <Select @change="changeField" :datas="sfList" :filter="sfilter" keyName="name" titleName="name" v-model="form.formula"></Select>
        </FormItem>
        <FormItem label="显示位置" prop="weight">
          <NumberInput v-model="form.weight"/>
        </FormItem>
        <FormItem label="计算公式" v-if="form.fieldType==='计算字段'">
          <Codemirror :extensions="extensions" :style="{ height: '200px' }" language="java" v-model="form.formula" placeholder="请输入计算公式"></Codemirror>
          <div class="mt-8px">
            <div class="mb-8px">可选系统字段：<span class="text-12px">(点击名称快速复制)</span></div>
            <div class="mb-16px">
              <span @click="copy(sf.name)" class="h-tag text-hover mb-8px" v-for="sf in sfList">{{ sf.name }}</span>
            </div>
            <template v-if="afList.length">
              <div class="my-8px">可选自定义字段：<span class="text-12px">(点击名称快速复制)</span></div>
              <div>
                <span @click="copy(af.fieldName)" class="h-tag text-hover mb-8px" v-for="af in afList">{{ af.fieldName }}</span>
              </div>
            </template>
          </div>
        </FormItem>
      </Form>
      <div class="flex-1" style="height: 300px">
        <vxe-table ref="itemsTable"
                   :loading="loading"
                   :data="forms.configList"
                   height="auto"
                   align="center"
                   >
          <vxe-column field="fieldType" title="字段类型"/>
          <vxe-column field="fieldName" title="字段名称"/>
          <vxe-column field="dataType" title="数据类型"/>
          <vxe-column field="proConfig" title="项目配置"/>
          <vxe-column field="formula" title="计算公式"/>
          <vxe-column field="weight" title="显示位置"/>
          <vxe-column title="操作">
            <template #default="{row}">
              <div class="actions" v-if="!row.init">
                <span v-if="!row.voucherId" @click="editForm(row)" class="fa fa-edit">编辑</span>
                <span v-if="!row.voucherId" @click="remove(row)" class="fa fa-trash">删除</span>
              </div>
            </template>
          </vxe-column>
        </vxe-table>
      </div>
    </div>
    <div class="modal-column-between">
      <Button @click="$emit('close')">取消</Button>
      <Button color="primary" @click="submit" :loading="loading">保存</Button>
    </div>

  </div>
</template>

<script>
/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
import {clipboard, message} from 'heyui.ext';
import {CopyObj} from "@/js/common/utils";
import {Codemirror} from 'vue-codemirror';
import {java} from '@codemirror/lang-java'
import SalaryManage from "@/api/SalaryManage";
import {layer} from "@layui/layer-vue";
import {h} from "vue";
import WorkSettingForm from "@/views/salary/WorkSettingForm";

export default {
  name: "CheckWorkSettingForm",
  emits: {close: null, success: null},
  components: {Codemirror},
  props: {
    entity: Object,
    attendanceList: Array
  },
  data() {
    return {
      loading: false,
      editor: null,
      extensions: [java()],
      form: {
        id: null,
        fieldName: null,
        formula: "",
        weight: 1,
        dataType: "数值型",
        proConfig: '工资总额',
        fieldType: '系统字段',
      },
      forms: {
        configList: [],
      },
      salaryFields: [],
      sfList: [],
      afList: []
    }
  },
  methods: {
    loadSf() {
      // 获取系统字段接口
      SalaryManage.listCheckWork().then(({data}) => {
        // this.sfList = data || [];
        this.sfList = [
          {id: 1, name: '基本工资'},
          {id: 2, name: '出勤天数'},
          {id: 3, name: '交通补贴'}
        ]
      });
    },
    loadAf() {
      SalaryManage.listCheckWork().then(({data}) => {
        // this.afList = data || [];
        this.afList = [
          {id: 1, fieldName: '迟到次数'},
          {id: 2, fieldName: '早退次数'},
        ]
      });
    },
    copy(field) {
      clipboard({
        text: field
      });
    },
    submit() {
      let validResult = this.$refs.form.valid();
      if (validResult.result) {
        let usedList = [];
        if (this.entity && this.entity.fieldName !== this.form.fieldName) {
          this.attendanceList.forEach(val => {
            if (val.fieldType === '计算字段' && val.formula && val.formula.indexOf(this.entity.fieldName) !== -1) {
              usedList.push(val);
            }
          });
          this.salaryFields.forEach(val => {
            if (val.formula && val.formula.indexOf(this.entity.fieldName) !== -1) {
              val._tag = "salary";
              usedList.push(val);
            }
          });
        }

        if (usedList.length) {
          // let layerId = layer.drawer({
          //   title: "引用变更",
          //   shadeClose: false,
          //
          //   area: ['800px', '100vh'],
          //   content: h(FixedConflict, {
          //     usedList,
          //     attendanceField: this.form,
          //     oldName: this.entity.fieldName,
          //     onClose: () => {
          //       layer.close(layerId);
          //     },
          //     onSuccess: () => {
          //       layer.close(layerId);
          //       message("保存成功~");
          //       this.$emit('success');
          //     }
          //   })
          // });
        } else {
          this.loading = true;
          SalaryManage.saveCheckWork(this.form).then(() => {
            message("保存成功~");
            this.$emit('success');
          }).finally(() => this.loading = false);
        }
      }
    },
    changeField(data) {
      this.form.fieldName = data.name
    },
    loadList() {
      SalaryManage.listSalaryWork().then(({data}) => {
        // this.forms.configList = data;
        this.forms.configList = [
          {id: 1, field: 'code14', fieldName: '工资', fieldType: '系统字段', proConfig: '工资总额', dataType: '数值型', formula: '工资', weight: 1},
          {id: 2, field: 'code15', fieldName: '津贴补助', fieldType: '系统字段', proConfig: '工资总额', dataType: '数值型', formula: '工资', weight: 2},
          {id: 3, field: 'code16', fieldName: '奖金', fieldType: '系统字段', proConfig: '工资总额', dataType: '数值型', formula: '工资', weight: 3},
          {id: 4, field: 'code17', fieldName: '考勤扣款', fieldType: '输入字段', proConfig: '应扣工资', dataType: '数值型', formula: '工资', weight: 4},
          {id: 5, field: 'code18', fieldName: '其他扣款', fieldType: '输入字段', proConfig: '应扣工资', dataType: '数值型', formula: '工资', weight: 5},
          {id: 6, field: 'code19', fieldName: '调整1', fieldType: '计算字段', proConfig: '实发工资调整', dataType: '数值型', formula: 'if 工资 > 0', weight: 6},
          {id: 7, field: 'code20', fieldName: '调整2', fieldType: '计算字段', proConfig: '实发工资调整', dataType: '数值型', formula: 'if 工资 > 0', weight: 7},
          {id: 8, field: 'code21', fieldName: '调整3', fieldType: '计算字段', proConfig: '实发工资调整', dataType: '数值型', formula: 'if 工资 > 0', weight: 8}
        ]
      })
    },
    // 编辑自定义工资项目列表
    editForm(original) {
      let layerId = layer.open({
        title: '参保方案',
        shadeClose: false,
        closeBtn: false,
        zIndex: 1000,
        area: ['900px', '600px'],
        content: h(WorkSettingForm, {
          original,
          onClose: () => {
            layer.close(layerId);
          },
          onSuccess: () => {
            layer.close(layerId);
            this.loadData();
          }
        })
      });
    },
    remove(row) {
      this.$Confirm("确认删除此记录?").then(() => {
        this.loading = true;
        SalaryManage.deleteCheckWork(row).then(() => {
          this.loadList();
        }).finally(() => this.loading = false)
      })
    }
  },
  created() {
    this.loadSf();
    this.loadAf();
    this.loadList();
    SalaryManage.listSalaryWork().then(({data}) => {
      this.salaryFields = data;
    })
    CopyObj(this.form, this.entity);
  },
}
</script>
