<template>
  <app-content class="h-panel">
    <div class="h-panel-bar">
        <div class="margin-top margin-right" style="display: flex;justify-content: end">
            <Button color="primary" @click="showForm=true">新增</Button>
        </div>
    </div>

    <div class="h-panel-body">
      <vxe-table :data="datas" >
        <vxe-column title="币别" field="code"></vxe-column>
        <vxe-column title="名称" field="name"></vxe-column>
        <vxe-column title="汇率" field="exchangeRate"></vxe-column>
        <vxe-column title="是否本位币">
          <template #default="{row}">
            {{ row.localCurrency ? '是' : '否' }}
          </template>
        </vxe-column>
        <vxe-column title="操作" :width="100" align="center">
          <template #default="{row}">
            <div class="actions" v-if="!row.localCurrency">
              <span @click="edit(row)">编辑</span>
              <span @click="remove(row)">删除</span>
            </div>
          </template>
        </vxe-column>
      </vxe-table>
    </div>
    <Modal v-model="showForm" hasCloseIcon>
      <template #header>币别设置</template>
      <Form ref="form" :labelWidth="100" :model="form" :rules="validationRules">
        <FormItem label="币别" prop="code">
          <Input type="text" v-model="form.code" @input="checkCode" maxlength="3"/>
        </FormItem>
        <FormItem label="名称" prop="name">
          <Input type="text" v-model="form.name"/>
        </FormItem>
        <FormItem label="汇率" prop="exchangeRate">
          <NumberInput v-model="form.exchangeRate"></NumberInput>
        </FormItem>
      </Form>
      <div class="text-center w-500px">
        <Button color="primary" @click="submit" :loading="loading">{{ form.id ? '更新' : '保存' }}</Button>
        <Button @click="showForm=false" :loading="loading">取消</Button>
      </div>
    </Modal>
  </app-content>
</template>

<script>
const emptyForm = {
  "code": "",
  "name": "",
  "exchangeRate": "",
};

export default {
  name: 'Currency',
  data() {
    return {
      datas: [],
      form: Object.assign({}, emptyForm),
      validationRules: {
        required: ["code", "name", "exchangeRate"],
        rules: {
          code: {
            maxLen: 3,
            minLen: 3,
            valid: {
              pattern: /^[A-Z]{3}$/,
              message: '需为三位的大写英文字母'
            }
          }
        }
      },
      showForm: false,
      loading: false
    };
  },
  watch: {
    showForm(val) {
      if (!val) {
        this.reset();
      }
    }
  },
  methods: {
    loadList() {
      this.$api.setting.currency.list().then(({data}) => {
        this.datas = data || [];
      })
    },
    submit() {
      let validResult = this.$refs.form.valid();
      if (validResult.result) {
        this.loading = true;
        this.$api.setting.currency[this.form.id ? 'update' : 'save'](this.form).then(() => {
          this.loadList();
          this.showForm = false;
          this.loading = false;
          this.$store.dispatch('updateCurrency');
        }).catch(() => {
          this.loading = false;
        })
      }
    },
    reset() {
      this.form = Object.assign({}, emptyForm)
    },
    remove(data) {
      this.$Confirm("确认删除?").then(() => {
        this.$api.setting.currency.delete(data.id).then(() => {
          this.loadList();
        })
      })
    },
    edit(data) {
      this.form = Object.assign({}, data)
      this.showForm = true;
    },
    checkCode() {
      this.form.code = this.form.code.toUpperCase();
    }
  },
  mounted() {
    this.loadList();
  }
};
</script>
