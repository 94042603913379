<template>
	<Loading :loading="loading" text="报表数据计算中..."/>
	<vxe-toolbar class-name="px-8px">
		<template #buttons>
			<DatePicker ref="dateComp" v-if="dimension==='QUARTER'" v-model="voucherDate" type="quarter" :clearable="false"/>
			<account-date-choose v-else v-model="voucherDate"/>
			<vxe-button class="ml-16px" v-if="reportId==='xjll'"  content="公式法"/>
		</template>
		<template #tools>
			<!-- {{reportId}} -->
			<Checkbox class="mr-16px" v-if="dimension==='QUARTER'" >显示本年所有季度</Checkbox>
			<Checkbox class="mr-16px"  >显示上年金额</Checkbox>
			<vxe-button @click="printData" icon="fa fa-print" content="打印"/>
			<vxe-button @click="exportData" icon="fa fa-download" content="导出"/>
		</template>
	</vxe-toolbar>
	<div ref="reportContent" class="flex-1 p-8px" style="overflow-y: auto">
		<vxe-table ref="xTable" :data="dataList" >
			<vxe-column :title="h.title" v-for="h in headers" :field="h.key" :align="h.isTitle?(h.width?'center':'left'):'right'" :width="h.isTitle?h.width||'auto':150">
				<template #default="{row}">
					<div v-if="row.id" class="col-title">
						<template v-if="h.isTitle && !h.isRowNum">
							<span :style="{'padding-left': (15*row.level)+'px'}">{{ row.title }}</span>
							<span @click.stop="editFormula(row.id)" v-if="row.editFormulas" class="col-title-edit text-hover text-12px ml-8px primary-color">
								<i class="fa fa-edit"></i> 编辑公式
							</span>
						</template>
						<template v-else-if="h.isRowNum">
							{{ row.rowNum }}
						</template>
					</div>
					<div v-else :class="row[h.key+ 'isBolder']?'font-bold text-14px':''" class="col-title">
						<template v-if="h.isTitle">
							<span :style="{'padding-left': (15*row[h.key+ 'lv'])+'px'}">{{ row[h.key] }}</span>
							<span @click.stop="editFormula(row[h.key+ 'id'])" v-if="row[h.key+ 'editFormulas']" class="col-title-edit text-hover text-12px ml-8px primary-color">
								<i class="fa fa-edit"></i> 编辑公式
							</span>
							
						</template>
						<span v-else>{{ row[h.key] }}</span>
					</div>
				</template>
			</vxe-column>
		</vxe-table>
	</div>
</template>

<script>
/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
export default {
	name: "ZcfzbReportView"
}
</script>

<script setup>
import ReportApi from "@/api/report";
import {ref, toRefs, watch, h, computed} from "vue";
import {useStore} from "vuex";
import manba from "manba";
import {repeat, toArrayTree} from "xe-utils";
import AccountDateChoose from "@/components/AccountDateChoose";
import {VXETable} from "vxe-table";
import {layer} from "@layui/layer-vue";
import TemplateItemFormulaModal from "@/views/report/template/TemplateItemFormulaModal.vue";
import EventBus from "@js/common/EventBus";
import print from "@js/common/print";
import {dictMapping} from "heyui.ext";
import ExcelJS from "exceljs";
import {downloadBlob} from "download.js";

const store = useStore();
const {currentAccountSets} = store.state

const props = defineProps(['report', 'dimension','reportId']);

const {report, dimension} = toRefs(props);
const reportContent = ref(null);
const xTable = ref(null);
//const headers = ref({});
const dataList = ref( []);
const reportItems = ref([]);
const loading = ref(false);
const dateComp = ref(null);
const voucherDate = ref(currentAccountSets.currentAccountDate)

if (dimension.value === 'QUARTER') {
	voucherDate.value = manba(currentAccountSets.currentAccountDate).startOf(manba.QUARTER).format()
}

const showDate = computed(() => {
	if (dimension.value === 'QUARTER') {
		return dateComp.value.showDate;
	}
	return manba(voucherDate.value).format('YYYY年MM月');
})

const headers = computed(() => {
	let tree = toArrayTree(props.report.items);
	let _headers = [];
	if (tree.filter(f => f.isClassified).length > 0) {
		tree.forEach(root => {
			_headers.push({title: root.title, key: root.title, isTitle: true})
			_headers.push({title: '行次', key: root.title + 'rowNum', isTitle: true, isRowNum: true, width: 50})
			report.value.reportCols.forEach(col => {
				_headers.push({title: col.title, key: root.title + col.title})
			})
		})
	} else {
		_headers.push({title: '项目', key: '项目', isTitle: true})
		_headers.push({title: '行次', key: '项目rowNum', isTitle: true, isRowNum: true, width: 50})
		report.value.reportCols.forEach(col => {
			_headers.push({title: col.title, key: "项目" + col.title})
		})
	}
	// console.log(_headers,'_headers')
	return _headers;
})

const items = ref(toArrayTree(report.value.items));
let startIndex = 0;
const loadData = () => {
	loading.value = true;
	ReportApi.view(report.value.templateKey, {
		voucherDate: voucherDate.value,
		dimension: dimension.value
	}).then(({data}) => {
		reportItems.value = data;
		let tree = toArrayTree(data);
		if (tree.filter(f => f.isClassified).length > 0) {
			let rows = [];
			tree.forEach(root => {
				buildRow(root.children, rows, root.title, 0)
				startIndex = 0;
			})
			dataList.value = rows;
			// console.log(dataList.value,'dataList.value')
		} else {
			let rows = [];
			buildRow(tree, rows, '项目', 0)
			dataList.value = rows;
			// console.log(dataList.value,'dataList.value')
		}
	}).finally(() => loading.value = false);
	
}
loadData()


const buildRow = (items, rows, colTitle, lv) => {
	for (let i = 0; i < items.length; i++) {
		let item = items[i];
		if (!rows[startIndex]) {
			rows[startIndex] = {}
		}
		rows[startIndex][colTitle] = item.title;
		rows[startIndex][colTitle + "isBolder"] = item.isBolder;
		rows[startIndex][colTitle + "rowNum"] = item.rowNum;
		rows[startIndex][colTitle + "lv"] = lv;
		rows[startIndex][colTitle + "editFormulas"] = item.editFormulas;
		rows[startIndex][colTitle + "id"] = item.id;

		Object.keys(item.values).forEach(key => {
			rows[startIndex][colTitle + key] = VXETable.formats.store.toMoney({cellValue: item.values[key]});
		})
		startIndex++;
		if (item.children && item.children.length > 0) {
			buildRow(item.children, rows, colTitle, lv + 1);
		}
	}
}

watch(voucherDate, () => {
	loadData();
})

const editFormula = (itemId) => {
	let layerId = layer.open({
		title: "公式编辑",
		shadeClose: false,
		closeBtn: false,
		zIndex: 1000,
		area: ['1000px', 'auto'],
		content: h(TemplateItemFormulaModal, {
			templateItem: report.value.items.find(val => val.id === itemId),
			templateItems: report.value.items,
			template: report.value,
			onClose: () => {
				layer.close(layerId);
			},
			onSuccess: () => {
				EventBus.emit("report.reload")
				layer.close(layerId);
			}
		})
	});
}

const huiTitle = () => {
	let title = "会"
	title += dictMapping(currentAccountSets.accountStandard, 'accountingStandardsPrint')
	title += dictMapping(report.value.templateKey, 'reportPrint')
	return title;
}

const pdfWidth = {
	项目: 117,
	资产: 43,
	负债和所有者权益: 43,
	行次: 15,
	期末余额: 35,
	年初余额: 35,
	本年累计金额: 60,
	本期金额: 60,
}

const printData = () => {
	print.print({putOnlyUsedFonts: true}).then((doc) => {
		doc.setFontSize(16);
		doc.text(report.value.name, 105, 10, {align: 'center'})
		doc.setFontSize(9);
		doc.text(huiTitle(), 200, 15, {align: 'right', lineHeightFactor: 0.5})
		doc.text("编制单位：" + currentAccountSets.companyName, 10, 20)
		doc.text(showDate.value, 105, 20, null, null, 'center')
		doc.text("单位：元", 200, 20, null, null, 'right')

		let headers = xTable.value.getColumns();
		let {fullData} = xTable.value.getTableData();
		doc.table(10, 22, fullData.map(item => {
			let row = {};
			headers.forEach(h => {
				let val = item[h.property] || " ";
				if (['资产', '负债和所有者权益', '项目'].includes(h.property)) {
					val = repeat(" ", (item[h.property + "lv"] || 0) * 2) + val;
				}
				row[h.property] = val
			})
			return row;
		}), headers.map(val => {
			return {
				id: val.property,
				name: val.property,
				prompt: val.title,
				width: pdfWidth[val.title],
				align: val.align
			}
		}), {
			padding: 2, css: {
				"font-size": 0.5
			}
		})
		doc.print(report.value.name);
	})
}

const xlsWidth = {
	项目: 46,
	资产: 25,
	负债和所有者权益: 25,
	行次: 7,
	期末余额: 22,
	年初余额: 22,
	本年累计金额: 22,
	本期金额: 22,
}

const reportCols = report.value.reportCols.map(val => val.title)

const exportData = () => {
	let headers = xTable.value.getColumns();
	let {fullData} = xTable.value.getTableData();

	const wb = new ExcelJS.Workbook();
	const ws = wb.addWorksheet(props.report.title);
	//标题
	let headerCell = ws.getCell("A1");
	ws.mergeCells(1, 1, 1, headers.length);
	headerCell.font = {
		bold: true,
		size: 16,
	};
	headerCell.alignment = {vertical: 'middle', horizontal: 'center'};
	headerCell.value = report.value.name;
	//二级标题
	let subHeaderCell = ws.getCell("A2");
	ws.mergeCells(2, 1, 2, headers.length);
	subHeaderCell.alignment = {vertical: 'center', horizontal: 'right'};
	subHeaderCell.value = huiTitle();
	//企业
	//二级标题
	let companyNameCell = ws.getCell("A3");
	companyNameCell.value = "编制单位：" + currentAccountSets.companyName;
	//日期
	let dateCell = ws.getCell("B3");
	ws.mergeCells(3, 2, 3, headers.length - 1);
	dateCell.value = showDate.value;
	//币别
	let currencyCell = ws.getCell(3, headers.length);
	currencyCell.value = "单位：元";
	currencyCell.alignment = {vertical: 'center', horizontal: 'right'};

	headers.forEach((item, index) => {
		let cell = ws.getCell(4, index + 1);
		cell.font = {
			bold: true,
			size: 13,
		};
		cell.value = item.title;
		cell.alignment = {vertical: 'center', horizontal: item.align};

		ws.getColumn(index + 1).width = xlsWidth[item.title];
		thinBorder(cell);
	})

	const rows = fullData.map(item => {
		let row = {};
		headers.forEach(h => {
			let val = item[h.property] || " ";
			if (['资产', '负债和所有者权益', '项目'].includes(h.property)) {
				val = repeat(" ", (item[h.property + "lv"] || 0) * 2) + val;
			}
			row[h.property] = val
		})
		return row;
	});

	rows.forEach((row, rIndex) => {
		headers.forEach((h, colIndex) => {
			let cell = ws.getCell(5 + rIndex, colIndex + 1);
			cell.font = {
				size: 9,
			};
			cell.alignment = {vertical: 'center', horizontal: h.align};
			if (reportCols.includes(h.title) && row[h.property] && row[h.property].trim()) {
				cell.value = row[h.property] ? Number(row[h.property].replaceAll(",", "")) : '';
				cell.numFmt = "#,##0.00_);[Red](#,##0.00)"
			} else {
				cell.value = row[h.property];
			}
			thinBorder(cell);
		})
	})

	wb.xlsx.writeBuffer().then(buffer => {
		downloadBlob(`${manba().format('YYYYMMDDHHmm')}${report.value.name}.xlsx`, new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}))
	})
}

const thinBorder = (cell) => {
	cell.border = {
		top: {style: 'thin'},
		left: {style: 'thin'},
		bottom: {style: 'thin'},
		right: {style: 'thin'}
	};
}
</script>
<style lang="less" scoped>
.col-title-edit {
	display: none;
}

.col-title:hover {
	.col-title-edit {
		display: inline-block;
	}
}
</style>
