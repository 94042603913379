<template>
  <div class="modal-column">
    <div>
      <Button @click="showForm()" color="primary" class="float-right mt-4px mr-16px">新建</Button>
      <Tabs :datas="tabs" v-model="type" class="px-16px"/>
    </div>
    <div class="flex-1 h-0px p-16px">
      <div class="border p-8px text-12px mb-16px" v-for="(items,key) in templateList" :key="key">
        <div class="flex justify-between">
          <span class="font-bold">{{ key }}</span>
          <div class="actions">
            <span @click="showForm(key)">编辑</span>
            <span @click="doTrash(key)">删除</span>
          </div>
        </div>
        <div class="mt-16px">
          <div class="my-8px ml-16px" v-for="item in items" :key="item.id">
            {{ item.direction }}：{{ item.subjectTitle }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
export default {
  name: "CashierIoTypeTemplate"
}
</script>

<script setup>
import {ref, toRefs, h, watch, reactive} from "vue";
import CashierIoType from "@/api/CashierIoType";
import {layer} from "@layui/layer-vue";
import {confirm} from "heyui.ext";
import CashierIoTypeTemplateForm from "@/views/cashier/CashierIoTypeTemplateForm";
import {groupBy} from "xe-utils";

const props = defineProps(['ioType']);
const {ioType} = toRefs(props);
const type = ref('收');
const templateList = ref({});
const tabs = reactive({收: '收入模板', 支: '支出模板'});

const loadList = () => {
  CashierIoType.templateList(ioType.value, type.value).then(({data}) => {
    templateList.value = groupBy(data, val => val.ioTypeName)
  })
}

loadList();

const showForm = (ioTypeName = null) => {
  let layerId = layer.open({
    title: ioType.value + " " + tabs[type.value],
    shadeClose: false,
    closeBtn: false,
    zIndex: 1000,
    area: ['500px', 'auto'],
    content: h(CashierIoTypeTemplateForm, {
      items: ioTypeName ? templateList.value[ioTypeName] : null, ioType, type,
      onClose: () => {
        layer.close(layerId);
      },
      onSuccess: () => {
        loadList();
        layer.close(layerId);
      }
    })
  });
}

const doTrash = (ioTypeName) => {
  confirm({
    content: `确认删除${ioTypeName}凭证模板?`,
    onConfirm() {
      const loadId = layer.load(3);
      CashierIoType.templateDelete(templateList.value[ioTypeName].map(val => val.id)).then(() => {
        loadList();
      }).finally(() => layer.close(loadId))
    }
  })
}

watch(type, () => loadList())
</script>
