<template>
    <div class="h-full p-20px">
        <div class="h-full  flex flex-column">
            <Tabs :datas="{'单据':'进销存单据列表','一览':'进销存凭证一览表','差异':'查看进销存差异'}"
                  v-model="ioType"
                  class="bg-white-color px-16px"/>

            <div v-if="ioType=='单据'" class="h-full  flex flex-column">
                <vxe-toolbar class="px-16px">
                    <template #buttons>
                        <DropdownCustom ref="journalParamsDropdown" button class="h-btn" trigger="click">
                            <div>{{ startDate }} 至 {{ endDate }}</div>
                        </DropdownCustom>
                    </template>
                    <template #tools>
                        <Button :loading="dataLoading" @click="backUp" color="">批量删除</Button>
                        <Button :loading="dataLoading" @click="backUp" color="primary">生成凭证</Button>
                    </template>
                </vxe-toolbar>
                <div class="flex-1">
                    <vxe-table border="none" height="auto" ref="xTable" :loading="dataLoading" show-overflow stripe
                               :data="dataList">
                        <vxe-column type="checkbox"/>
                        <vxe-column title="日期" field="code"/>
                        <vxe-column title="单据编号" field="name"/>
                        <vxe-column title="单据类型" field="subject"/>
                        <vxe-column title="供应商" field="subject"/>
                        <vxe-column title="单据金额" field="subject"/>
                        <vxe-column title="制单人" field="subject"/>
                        <vxe-column title="凭证字号" field="subject"/>
                    </vxe-table>
                </div>

            </div>
            <div v-else-i="ioType=='差异'" class="h-full  flex flex-column">
                <vxe-toolbar class="px-16px">
                    <template #buttons>
                        <DropdownCustom ref="journalParamsDropdown" button class="h-btn" trigger="click">
                            <div>{{ startDate }}</div>
                        </DropdownCustom>
                        <div style="font-size: 14px;margin-left: 10px; color: #2FA8FD">
                            查看差异说明
                        </div>
                    </template>
                    <template #tools>
                        <Button :loading="dataLoading" @click="backUp" color="primary">导出</Button>
                    </template>
                </vxe-toolbar>
                <div class="flex-1">
                    <vxe-table border="none" height="auto" ref="xTable" :loading="dataLoading" show-overflow stripe
                               :data="dataList">
                        <vxe-colgroup title="分组信息">
                            <vxe-column title="日期" field="code"/>
                            <vxe-column title="凭证号" field="name"/>
                            <vxe-column title="摘要" field="subject"/>
                            <vxe-column title="借方" field="subject"/>
                            <vxe-column title="贷方" field="subject"/>
                            <vxe-column title="余额" field="subject"/>
                        </vxe-colgroup>
                        <vxe-colgroup title="分组信息">
                            <vxe-column title="进销存单据号" field="code"/>
                            <vxe-column title="凭证号" field="name"/>
                            <vxe-column title="入库" field="subject"/>
                            <vxe-column title="出库" field="subject"/>
                            <vxe-column title="余额" field="subject"/>
                        </vxe-colgroup>
                            <vxe-column title="差额" field="code"/>
                    </vxe-table>
                </div>

            </div>

        </div>
    </div>
</template>

<script>
/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
export default {
    name: "CreateVoucher"
}
</script>

<script setup>
import {confirm, loading} from "heyui.ext";
import {ref, h, reactive} from "vue";
import setting from "@/api/setting";
import RecoverForm from "@/views/setting/RecoverForm";
import {layer} from "@layui/layer-vue";
import manba from "manba";
import {useStore} from "vuex";

const dataLoading = ref(false)
const afterAuth = ref(false)
const dataList = ref([{
    code: '1001',
    name: '现金',
    subject: '会计001',
}])
const store = useStore();

const fileInput = ref(null)
const ioType = ref('单据')
const currentAccountDate = manba(store.state.currentAccountSets.currentAccountDate);
const startDate = ref(currentAccountDate.startOf(manba.MONTH).format());
const endDate = ref(currentAccountDate.endOf(manba.MONTH).format());

const params = reactive({
    start: startDate.value,
    end: endDate.value,
    ioTypeId: null,
    settlementTypeId: null,
    projectCategoryDetailsId: null,
    deptCategoryDetailsId: null,
    code: null,
    minMoney: null,
    maxMoney: null,
    accountingCategoryDetailsValue: null,
    digest: null,
    remark: null,
});
const loadList = () => {
    dataLoading.value = true;
    setting.backup.list().then(({data}) => {
        dataList.value = data;
    }).finally(() => dataLoading.value = false)
}
loadList();

const backUp = () => {
    loading("备份中...")
    setting.backup.backup().then(() => {
        loadList();
    }).finally(() => loading.close())
}

const remove = (row) => {
    confirm({
        content: "确认删除此备份?",
        async: true,
        onConfirm(resolve) {
            loading("删除中...")
            setting.backup.delete(row.id).then(() => {
                loadList();
            }).finally(() => {
                loading.close();
                resolve(true);
            })
        }
    })
}

const recover = (entity) => {
    let layerId = layer.open({
        title: "恢复数据",
        shadeClose: false,
        closeBtn: false,
        zIndex: 1000,
        area: ['550px', 'auto'],
        content: h(RecoverForm, {
            entity,
            onClose: () => {
                layer.close(layerId);
            }
        })
    });
}

const uploadBackUp = () => {
    if (fileInput.value.files.length) {
        loading("上传中...")
        const formData = new FormData();
        formData.append("file", fileInput.value.files[0]);
        setting.backup.upload(formData).then(() => {
            loadList();
        }).finally(() => loading.close())
    }

}
</script>
