<template>
	<div class="flex result justify-center items-center">
		<div class="flex-1 text-center" style="border-right: 1px solid #eeeeee">
			<div class="font-bold margin">期初余额</div>
			<div class="pic-body beginningBalance" :class="{left:trialBalance.diffInital<0,right:trialBalance.diffInital>0}">
				<span class="span1">{{ numFormat(trialBalance.initalDebit) }}</span>
				<span class="span2">{{ numFormat(Math.abs(trialBalance.diffInital)) }}</span>
				<span class="span3">{{ numFormat(trialBalance.initalCredit) }}</span>
				<div class="pic"></div>
			</div>
		</div>
		<div class="flex-1 text-center" style="border-right: 1px solid #eeeeee">
			<div class="font-bold margin">累计发生</div>
			<div class="pic-body beginningBalance" :class="{left:trialBalance.diffTotal<0,right:trialBalance.diffTotal>10}">
				<span class="span1">{{ numFormat(trialBalance.debitTotal) }}</span>
				<span class="span2">{{ numFormat(Math.abs(trialBalance.diffTotal)) }}</span>
				<span class="span3">{{ numFormat(trialBalance.creditTotal) }}</span>
				<div class="pic"></div>
			</div>
		</div>
		<div class="flex-1 text-center ">
			<div class="font-bold margin">资产负债表期初</div>
			<div class="pic-body liabilities" :class="{left:trialBalance.totalDiff<0,right:trialBalance.totalDiff>0}">
				<span class="span1">{{ numFormat(trialBalance.totalDebit) }}</span>
				<span class="span2">{{ numFormat(Math.abs(trialBalance.totalDiff)) }}</span>
				<span class="span3">{{ numFormat(trialBalance.totalCredit) }}</span>
				<div class="pic "></div>
			</div>
		</div>
	</div>
</template>

<script>
/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
export default {
	name: "InitialTrial"
}
</script>

<script setup>
import setting from "@/api/setting";
import {ref} from "vue";

const trialBalance = ref({
	"creditTotal": 0,
	"debitTotal": 0,
	"initalCredit": 0,
	"initalDebit": 0,
	"totalCredit": 0,
	"totalDebit": 0,
	"diffInital": 0,
	"totalDiff": 0,
	"diffTotal": 0
})

setting.initialBalance.trialBalance().then(({data}) => {
	Object.assign(trialBalance.value, data)
});
</script>
<style lang="less" scoped>
.result {

  .pic {
    width: 238px;
    height: 120px;
    background-image: url("../../../assets/settings.png");
    background-repeat: no-repeat;
    margin: 20px auto;
  }


  .pic-body {
    position: relative;
    margin: 50px auto 0;
    width: 260px;

    &.beginningBalance {
      .pic {
        background-position: -160px 0;
      }

      span {
        position: absolute;
      }

      .span1 {
        top: 0;
        left: 22px;
      }

      .span2 {
        top: 50px;
        left: 0;
        width: 100%;
      }

      .span3 {
        top: 0;
        right: 26px;
      }

      &.right {
        .pic {
          height: 130px;
          background-position: -160px -132px;
        }

        .span1 {
          top: 25px;
        }

        .span3 {
          top: -25px;
        }
      }

      &.left {
        .pic {
          height: 130px;
          background-position: -160px -272px;
        }

        .span1 {
          top: -25px;
        }

        .span3 {
          top: 25px;
        }
      }
    }

    &.liabilities {
      .pic {
        background-position: -420px 5px;
      }

      span {
        position: absolute;
      }

      .span1 {
        top: 0;
        left: 22px;
      }

      .span2 {
        top: 50px;
        left: 0;
        width: 100%;
      }

      .span3 {
        top: 0;
        right: 10px;
      }

      &.right {
        .pic {
          height: 130px;
          background-position: -420px -132px;
        }

        .span1 {
          top: 25px;
        }

        .span3 {
          top: -25px;
        }
      }

      &.left {
        .pic {
          height: 130px;
          background-position: -420px -272px;
        }

        .span1 {
          top: -25px;
        }

        .span3 {
          top: 25px;
        }
      }
    }
  }
}
</style>
