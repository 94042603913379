<template>
  <div class="h-full p-20px">
    <div class="h-full  flex flex-column">
      <Tabs :datas="{'deptSummary':'按使用部门汇总','typeSummary':'按资产类别汇总'}" class="px-20px bg-white-color" v-model="tab"/>
      <vxe-toolbar class-name="px-8px" :loading="loading">
        <template #buttons>
          <div class="h-input-group h-input-group-auto">
            <span class="">会计期间：</span>
            <Select v-model="params.start" :datas="checkDataList" class="!w-120px" :deletable="false"/>
            <span class="h-input-addon">至</span>
            <Select v-model="params.end" :datas="checkDataList" class="!w-120px" :deletable="false"/>
            <Button :loading="loading" @click="loadList" color="primary" icon="">查询</Button>
          </div>
        </template>
        <template #tools>
            <Button :loading="loading"  color="" icon="">导出</Button>
        </template>
      </vxe-toolbar>
      <div class="flex-1">
        <vxe-table height="auto" ref="xTable" :loading="loading" show-overflow  :data="dataList">
          <vxe-column title="名称" field="name" width="130"/>
          <vxe-colgroup title="资产原值" align="center">
            <vxe-column title="期初数" field="originalStart" align="right" width="100" formatter="toMoney"/>
            <vxe-column title="本期增加" field="originalCredit" align="right" width="100" formatter="toMoney"/>
            <vxe-column title="本期减少" field="originalDebit" align="right" width="100" formatter="toMoney"/>
            <vxe-column title="期末数" field="originalLast" align="right" width="100" formatter="toMoney"/>
          </vxe-colgroup>
          <vxe-colgroup title="累计折旧" align="center">
            <vxe-column title="期初数" field="depreciationStart" align="right" width="100" formatter="toMoney"/>
            <vxe-column title="本期增加" field="depreciationDebit" align="right" width="100" formatter="toMoney"/>
            <vxe-column title="本期减少" field="depreciationCredit" align="right" width="100" formatter="toMoney"/>
            <vxe-column title="期末数" field="depreciationLast" align="right" width="100" formatter="toMoney"/>
          </vxe-colgroup>
          <vxe-colgroup title="减值准备" align="center">
            <vxe-column title="期初数" field="impairmentStart" align="right" width="100" formatter="toMoney"/>
            <vxe-column title="本期增加" field="impairmentDebit" align="right" width="100" formatter="toMoney"/>
            <vxe-column title="本期减少" field="impairmentCredit" align="right" width="100" formatter="toMoney"/>
            <vxe-column title="期末数" field="impairmentLast" align="right" width="100" formatter="toMoney"/>
          </vxe-colgroup>
        </vxe-table>
      </div>
    </div>
  </div>
</template>

<script>/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
export default {
  name: "AssetsSummary",
}
</script>

<script setup>

import {reactive, ref, watch} from "vue";
import {useStore} from "vuex";
import manba from "manba";
import Assets from "@/api/Assets";

const store = useStore();
const tab = ref('deptSummary');
const loading = ref(false);
const dataList = ref([])
const checkDataList = ref([])
const currentAccountDate = manba(store.state.currentAccountSets.currentAccountDate).startOf(manba.MONTH).format();

const params = reactive({
  start: currentAccountDate,
  end: currentAccountDate,
  type: null,
})
Assets.checkDate().then(({data}) => {
  checkDataList.value = data;
})

const loadList = () => {
  loading.value = true;
  Assets.summary(Object.assign({}, params, {type: tab.value})).then(({data}) => {
    dataList.value = data;
  }).finally(() => loading.value = false)
}
loadList();

watch(tab, loadList);
</script>
