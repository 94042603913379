<template>
  <div class="v-table">
    <div class="v-table-body">
      <v-table-header/>
      <v-table-tr v-for="(d,i) in voucherDetailList"
                  :voucherDetailList='voucherDetailList'
                  :subjectList="subjectList"
                  :subjectMap="subjectMap"
                  :rowIndex="i"
                  :voucherDetail="d"/>
      <v-table-tr-total :detailList="voucherDetailList"/>
    </div>
  </div>
</template>

<script setup>
import VTableHeader from "@components/VoucherTable/VTableHeader.vue";
import VTableTr from "@components/VoucherTable/VTableTr.vue";
import {defineProps, nextTick, onMounted, ref, watch} from "vue";
import {useStore} from "vuex";
import setting from "@api/setting";
import {ArrToObj} from "@js/common/utils";
import VTableTrTotal from "@components/VoucherTable/VTableTrTotal.vue";
import {add, floor, pick, subtract} from "xe-utils";
import EventBus from "@/js/common/EventBus";

const props = defineProps(['voucherDetails', 'isEdit', 'readonly',]);
const emits = defineEmits(['update']);
const store = useStore();

const newEmptyRow = () => {
  return {
    summary: '',
    debitAmount: null,
    debitAmountFor: 0,
    originalCreditAmount: 0,
    originalDebitAmount: 0,
    creditAmount: null,
    creditAmountFor: 0,
    subject: null,
    subjectId: null,
    auxiliary: [],
    currencyId: store.getters.localCurrency.id,
    num: 0,
    debitNum: 0,
    creditNum: 0,
    price: 0,
    exchangeRate: 0,
  };
}

const rowColRefs = {};
const subjectList = ref([]);
const subjectMap = ref({});
const voucherDetailList = ref([newEmptyRow(), newEmptyRow(), newEmptyRow(), newEmptyRow(), newEmptyRow()]);

const reset = () => {
  voucherDetailList.value = defaultList();
  // console.log('zizizizi')
}
const defaultList = () => {
  return [newEmptyRow(), newEmptyRow(), newEmptyRow(), newEmptyRow(), newEmptyRow()];
}

const newRow = (rowIndex) => {
  voucherDetailList.value.splice(rowIndex + 1, 0, newEmptyRow())
}

const loadSubject = () => {
  setting.subject.voucherSelect().then(({data}) => {
    subjectList.value = data;
    subjectMap.value = ArrToObj(data, val => val.id)

    voucherDetailList.value.forEach(vd => {
      if (vd.subjectId && !vd.subject) {
        vd.subject = subjectMap.value[vd.subjectId];
      }
    })
  })
}

loadSubject();

const rmRow = (rowIndex) => {
  if (voucherDetailList.value.length < 6) {
    voucherDetailList.value.splice(rowIndex, 1, newEmptyRow())
  } else {
    voucherDetailList.value.splice(rowIndex, 1);
  }
}

watch(voucherDetailList, (val) => {
  emits('update', val.filter(val => {
    return (val.debitAmount != null || val.creditAmount != null);
  }).map(value => {
    if (value.subject) {
      value.subjectId = value.subject.id;
    }
    return pick(value, Object.keys(value).filter(val => val !== 'subject'));
  }));
}, {deep: true})

watch(() => props.voucherDetails, (val) => {
  let list = defaultList();
  (val || []).forEach((row, index) => {
    list[index] = row;
  })
  voucherDetailList.value = list;
})

onMounted(() => {
  let list = voucherDetailList.value;
  (props.voucherDetails || []).forEach((row, index) => {
    voucherDetailList.value[index] = row;
  })
  voucherDetailList.value = list;
})

EventBus.on("reloadSubject", loadSubject)

// 暴露给父元素可已通过ref调用
defineExpose({
  reset,
  newRow,
  rmRow,
  loadSubject,
  getDetailList: () => {
    return voucherDetailList.value;
  },
  updateFiled: (rowIndex, col, val) => {
    if (val) {
      if (col === 'creditAmount') {
        voucherDetailList.value[rowIndex]['debitAmount'] = null;
      } else if (col === 'debitAmount') {
        voucherDetailList.value[rowIndex]['creditAmount'] = null;
      }
    }
    voucherDetailList.value[rowIndex][col] = val;
  },
  next: (rowIndex, nextCol) => {
    const detail = voucherDetailList.value;
    if (rowColRefs[rowIndex]) {
      rowColRefs[rowIndex][nextCol].doEdit();
    } else {
      detail.push(newEmptyRow())
      nextTick(() => {
        rowColRefs[rowIndex][nextCol].doEdit();
      })
    }

    if (nextCol === 'summary') {
      //下一行自动平衡
      setTimeout(() => {
        if (detail[rowIndex - 1] && !detail[rowIndex].summary) {
          detail[rowIndex].summary = detail[rowIndex - 1].summary;
        }

        if (detail[rowIndex] && !(detail[rowIndex].debitAmount || detail[rowIndex].creditAmount)) {
          const totalDebit = detail.reduce((total, row) => add(total, (row.debitAmount || 0)), 0)
          const totalCredit = detail.reduce((total, row) => add(total, (row.creditAmount || 0)), 0)
          if (totalDebit !== totalCredit) {
            if (totalDebit > totalCredit) {
              detail[rowIndex].creditAmount = floor(subtract(totalDebit, totalCredit), 2);
            } else {
              detail[rowIndex].debitAmount = floor(subtract(totalCredit, totalDebit), 2);
            }
          }
        }
      }, 200)
    }
  },
  pushColInstance: (rowIndex, colRef) => {
    rowColRefs[rowIndex] = colRef;
  },
  autoEqAmount(rowIndex, field) { //等于号平衡
    const detail = voucherDetailList.value.filter((value, index) => index !== rowIndex);
    const totalDebit = detail.reduce((total, row) => add(total, (row.debitAmount || 0)), 0)
    const totalCredit = detail.reduce((total, row) => add(total, (row.creditAmount || 0)), 0)
    //置空自己
    if (field === 'debitAmount') {
      voucherDetailList.value[rowIndex]['creditAmount'] = null;
      voucherDetailList.value[rowIndex][field] = floor(subtract(totalCredit, totalDebit), 2);
    } else {
      voucherDetailList.value[rowIndex]['debitAmount'] = null;
      voucherDetailList.value[rowIndex][field] = floor(subtract(totalDebit, totalCredit), 2);
    }
  }
})
</script>

<style scoped lang="less">
.v-table {
  background: @white-color;
  font-size: 12px;
  width: 1000px;
  padding: 10px 20px;

  &-body {
    border: @border;
  }
}
</style>
