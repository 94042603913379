<template>
  <div class="modal-column">
    <div class="modal-column-body">
      <Form ref="form" :model="formData" :rules="{}" mode="block">
        <FormItem label="收支类别" prop="ioTypeId" v-if="type==='setIoType'">
          <Select :datas="ioTypeOptions" filterable v-model.number="formData.ioTypeId" :deletable="false"/>
        </FormItem>
        <FormItem label="往来单位" prop="accountingCategoryDetailsId" v-if="type==='setAccountingCategory'">
          <DropdownCustom ref="categoryDropdown" class="block-dropdown" trigger="manual">
            <Input @click="$refs.categoryDropdown.show()" type="text" v-model="formData.accountingCategoryDetailsValue" readonly/>
            <template #content>
              <CashierAssistingAccounting @selected="onCategorySelected" @clear="onCategoryClear" @close="onCategoryClose"/>
            </template>
          </DropdownCustom>
        </FormItem>
        <FormItem label="项目" prop="projectCategoryDetailsId" v-if="type==='setProject'">
          <Select :datas="projectOptions" filterable v-model.number="formData.projectCategoryDetailsId"/>
        </FormItem>
        <FormItem label="部门" prop="deptCategoryDetailsId" v-if="type==='setDept'">
          <Select :datas="deptOptions" filterable v-model.number="formData.deptCategoryDetailsId"/>
        </FormItem>
      </Form>
    </div>
    <div class="modal-column-btn">
      <Button @click="$emit('close')">取消</Button>
      <Button color="green" @click="submit" :loading="loading">保存</Button>
    </div>
  </div>
</template>

<script>
/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
export default {
  name: "CashierJournalBatchUpdate"
}
</script>

<script setup>
import {toRefs, ref, reactive} from "vue";
import CashierAssistingAccounting from "@/views/cashier/AssistingAccounting";
import CashierJournal from "@/api/CashierJournal";

let props = defineProps(['records', 'type', 'ioTypeOptions', 'ioTypeMap', 'settlementTypeOptions', 'deptOptions', 'projectOptions']);
const emits = defineEmits(['success', 'close']);

const {records, type, ioTypeOptions, ioTypeMap, settlementTypeOptions, deptOptions, projectOptions} = toRefs(props);

const loading = ref(false)
const categoryDropdown = ref(null)

const formData = reactive({
  "ioTypeId": null,
  "accountingCategoryId": null,
  "accountingCategoryDetailsId": null,
  "accountingCategoryDetailsValue": "",
  "projectCategoryId": null,
  "projectCategoryDetailsId": null,
  "deptCategoryId": null,
  "deptCategoryDetailsId": null,
})

const onCategorySelected = ({accountingCategoryId, id, name, code}) => {
  formData.accountingCategoryDetailsId = id;
  formData.accountingCategoryDetailsValue = code + "-" + name;
  formData.accountingCategoryId = accountingCategoryId;
  categoryDropdown.value.hide();
}

const onCategoryClear = () => {
  formData.accountingCategoryDetailsId = null;
  formData.accountingCategoryDetailsValue = null;
  formData.accountingCategoryId = null;
  categoryDropdown.value.hide();
}

const onCategoryClose = () => {
  categoryDropdown.value.hide();
}

const submit = () => {
  loading.value = true;
  if (formData.projectCategoryDetailsId) {
    formData.projectCategoryId = projectOptions.value.find(val => val.key === formData.projectCategoryDetailsId).accountingCategoryId;
  } else {
    formData.projectCategoryId = null;
  }
  if (formData.deptCategoryDetailsId) {
    formData.deptCategoryId = deptOptions.value.find(val => val.key === formData.deptCategoryDetailsId).accountingCategoryId;
  } else {
    formData.deptCategoryId = null;
  }
  CashierJournal.batchUpdate(records.value, type.value, formData).then(() => {
    emits('success');
  }).finally(() => loading.value = false)
}
</script>
