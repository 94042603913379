<template>
  <div class="h-full flex flex-column">
   
    <div class="m-16px flex-1 flex flex-column" v-if="!isupdate">
      <Tabs :datas="datalsit" class="px-20px bg-white-color" v-model="tab"/>
      <Component :is="tab" :dataform="dataform" class="flex-1 flex flex-column"
       @getdata="getdata"></Component>
    </div>
    <!-- 修改与编辑 -->
    <div class="m-16px flex-1 flex flex-column" v-if="isupdate">
      <Component is="AssetsForm2"
       :nextCode="dataform.nextCode"
       :entity="dataform.entity"
       :checkDate="dataform.checkDate"
       :assetsTypeList="dataform.assetsTypeList"
       :deptList="dataform.deptList"
       :supplierList="dataform.supplierList"
       :subjectList="dataform.subjectList"
        class="flex-1 flex flex-column" @getdata="getdata"></Component>
    </div>
   
  </div>
</template>
<script setup>
  
	import {ref} from "vue";

	const dataform = ref({});
  const isupdate = ref(false);

	const getdata = (data,type=false) => {
    isupdate.value = type
		dataform.value = data;
		console.log(dataform.value,'dataform')
	}

</script>
<script>/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
import AssetsList from "@/views/assets/AssetsList";
import AssetsChange from "@/views/assets/AssetsChange";
import AssetsDepreciationDetails from "@/views/assets/AssetsDepreciationDetails";
// 新增
import AssetsFixedInitial from "@/views/assets/AssetsFixedInitial";
import AssetsForm2 from "@/views/assets/AssetsForm2";

export default {
  name: "Assets",
  components: {AssetsList, AssetsChange, AssetsDepreciationDetails,AssetsFixedInitial,AssetsForm2},
  data() {
    return {
      tab: "AssetsList",
      loading: false,
      datalsit:{
        
        'AssetsList':'固定资产列表',
        'AssetsChange':'变更记录及生成凭证',
        'AssetsDepreciationDetails':'计提折旧及生成凭证',
        'AssetsFixedInitial':'固定资产初始化',
        
      }
    }
  },
}
</script>


