<template>
	<div class="modal-column">
		<div class="modal-column-body">
			<Form ref="formRef" :labelWidth="120" :model="form" :rules="validationRules">
				<FormItem label="辅助核算类别" prop="name">
					<Input v-model="form.name" :readonly="!form.canEdit"/>
				</FormItem>
				<FormItem label="默认列">
					<Row :space="10" type="flex">
						<Cell><Input type="text" value="编码" readonly/></Cell>
						<Cell><Input type="text" value="名称" readonly/></Cell>
						<Cell><Input type="text" value="备注" readonly/></Cell>
					</Row>
				</FormItem>
				<FormItem label="自定义列" prop="columns">
					<Row :space="10" type="flex">
						<Cell v-for="(c,i) in form.columns" :key="i">
							<div class="h-input h-input-suffix-icon">
								<Input :placeholder="'自定义列'+(i+1)" type="text" v-model="form.columns[i].title" :readonly="!form.canEdit"/>
								<i class="h-icon-trash" v-if="form.canEdit" @click="deleteItem(i)"></i>
							</div>
						</Cell>
						<Cell>
							<Button v-if="form.columns.length < 15 && form.canEdit" color="green" @click="addColumn">添加列</Button>
						</Cell>
					</Row>
				</FormItem>
			</Form>
		</div>
		<div class="modal-column-between" v-if="form.canEdit">
			<Button @click="$emit('close')" :loading="loading" icon="fa fa-close">取消</Button>
			<Button @click="submit" icon="fa fa-save" :loading="loading" color="primary">保存</Button>
		</div>
	</div>
</template>

<script>
/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
export default {
	name: "CategoryForm"
}
</script>

<script setup>
import {reactive, ref, defineEmits} from "vue";
import setting from "@/api/setting";
import {CopyObj} from "@/js/common/utils";

const emits = defineEmits(['success', 'close']);
const props = defineProps(['entity']);
const form = reactive({
	"id": null,
	"name": "",
	"categoryType": "CUSTOMIZATION",
	"canEdit": true,
	"columns": [],
})

const loading = ref(false)
const formRef = ref(null)

const validationRules = {
	required: ["name"],
	rules: {
		code: {
			maxLen: 4
		}
	}
}

const addColumn = () => {
	form.columns.push({code: 'cusColumn' + form.columns.length, title: ''})
}

const deleteItem = (i) => {
	form.columns.splice(i, 1);
	form.columns.forEach((item, i) => {
		item.code = 'cusColumn' + i;
	})
}

const submit = () => {
	let validResult = formRef.value.valid();
	if (validResult.result) {
		loading.value = true;
		setting.accountingCategory[form.id ? 'update' : 'save'](form).then(() => {
			emits('success')
		}).finally(() => {
			loading.value = false
		})
	}
}

CopyObj(form, props.entity);
</script>
