<template>
  <div class="modal-column">
    <div class="flex justify-between bg-white-color items-center">
      <Tabs :datas="tabs" class="px-20px bg-white-color flex-1" v-model="tab"/>
      <vxe-button class="mr-16px" @click="showForm()" status="primary" icon="fa fa-plus">新建</vxe-button>
    </div>
    <div class="modal-column-full-body">
      <div v-for="item in templateList[tab]" class="border p-8px mb-16px">
        <div class="font-bold mb-8px flex justify-between items-center">
          <span>{{ item.title }}</span>
          <div>
            <vxe-button @click="showForm(item)" type="text" icon="fa fa-edit">编辑</vxe-button>
            <vxe-button @click="remove(item)" type="text" icon="fa fa-trash">删除</vxe-button>
          </div>
        </div>
        <div class="pl-16px">
          <div v-for="vi in item.items">
            {{ vi.direction }}： {{ vi.subjectTitle }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2019-2020金华企客观网络科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2020年08月08日</li>
 * <li>@author     : ____′↘缔造梦</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
export default {
  name: "InvoiceVoucherTemplate"
}
</script>

<script setup>
import {reactive, ref, h, watch} from "vue";
import {InvoiceBusiness} from "@/api/Invoice";
import {layer} from "@layui/layer-vue";
import InvoiceVoucherTemplateForm from "@/views/invoice/InvoiceVoucherTemplateForm";
import {confirm} from "heyui.ext";

const tabs = reactive({
  "OUT": "销售模板",
  "IN": "采购模板",
})

const tab = ref("OUT");

const templateList = reactive({
  "IN": [],
  "OUT": [],
})

watch(tab, () => {
  loadList();
})

const loadList = () => {
  InvoiceBusiness.list(tab.value).then(({data}) => {
    templateList[tab.value] = data;
  })
}
loadList();

const showForm = (entity = null) => {
  let layerId = layer.open({
    title: "生成凭证规则",
    shadeClose: false,
    closeBtn: false,
    zIndex: 1000,
    area: ['700px', '60vh'],
    content: h(InvoiceVoucherTemplateForm, {
      entity,
      type: tab.value,
      onClose: () => {
        layer.close(layerId);
      },
      onSuccess: () => {
        layer.close(layerId);
        loadList();
      }
    })
  });
}

const remove = (row) => {
  confirm({
    content: "确认删除?",
    async: true,
    onConfirm(resolve) {
      InvoiceBusiness.remove(row.id).then(() => {
        resolve(true);
        loadList();
      }).catch(() => resolve(false))
    }
  })
}
</script>
