<template>
    <div class="h-full p-20px">
        <div class="flex flex-column h-full">
            <vxe-toolbar class-name="px-16px" :loading="loading">
                <template #buttons>
                    <vxe-button :disabled="creatorNum>=User.accountSetsNum" icon="fa fa-plus" @click="showForm()"
                                content="新建账套" status="primary"/>
                    <span style="margin-left: 10px">当前账套数量为{{ datas.length }}个，您可创建 <b class="red-color">{{ User.accountSetsNum }}</b> 个，更多账套数请联系服务商购买~</span>
                </template>
                <template #tools>
                    <vxe-input icon="fa fa-search"/>
                    <img  src="../../assets/account_1.png"  style="width: 25px;height: 25px;margin-left: 10px"/>
                    <img  src="../../assets/account_2.png"  style="width: 25px;height: 25px;margin-left: 10px"/>
                    <img  src="../../assets/account_3.png"  style="width: 25px;height: 25px;margin-left: 10px"/>
                </template>
            </vxe-toolbar>
            <div class="flex-1 h-0px" style="width: 100%">
                <vxe-table ref="journalTable"
                           :loading="loading"
                           :data="datas"
                           height="auto"
                >
                    <vxe-column title="" fixed="left" align="right">
                        <template #default="{row}">
                            <span v-if="row.id === currentAccountSets.id" style="color: #2FA8FD">当前</span>
                        </template>
                    </vxe-column>
                    <vxe-column title="单位名称" field="companyName" fixed="left"/>
                    <vxe-column title="当前记账年月" field="enableDate" fixed="left"/>
                    <vxe-column title="套餐启用年月" field="enableDate" fixed="left"/>
                    <vxe-column title="会计准则" fixed="left">
                        <template #default="{row}">
                            {{ dictMapping(row.accountStandard, 'accountingStandards') }}
                        </template>
                    </vxe-column>
                    <vxe-column title="资金模块" fixed="left">
                        <template #default="{row}">
                            {{ row.assets ? '启用' : '禁用' }}
                        </template>
                    </vxe-column>
                    <vxe-column title="固定资产模块" fixed="left">
                        <template #default="{row}">
                            {{ row.fixedAssetModule ? '启用' : '禁用' }}
                        </template>
                    </vxe-column>
                    <vxe-column title="凭证审核" fixed="left">
                        <template #default="{row}">
                            {{ row.voucherReviewed ? '启用' : '禁用' }}
                        </template>
                    </vxe-column>
                    <vxe-column title="账套权限" fixed="left">
                        <template #default="{row}">
                            账套管理员
                        </template>
                    </vxe-column>
                    <vxe-column title="操作" width="200px" align="center">
                        <template #default="{row}">
                            <div class="actions" v-if="!row.init">
                                <span v-if="!row.voucherId" @click="showForm(row)" class="fa">编辑</span>
                                <span v-if="!row.voucherId" class="fa">复制</span>
                                <span v-if="!row.voucherId" @click="doTrash(row['id'])" class="fa">删除</span>
                                <span v-if="!row.voucherId" class="fa">密码锁</span>
                            </div>
                        </template>
                    </vxe-column>
                </vxe-table>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import {mapActions, mapState} from 'vuex';
import {h} from 'vue';
import SmsVerificationCode from "../../components/SmsVerificationCode";
import {layer} from "@layui/layer-vue";
import AccountForm from "@/views/setting/account/AccountForm";
import RemoveConfirm from "@/views/setting/account/RemoveConfirm";
import ResetConfirm from "@/views/setting/account/ResetConfirm";
import {dictMapping} from "heyui.ext";


export default {
    name: 'Account',
    components: {SmsVerificationCode},
    data() {
        return {
            datas: [],
            rmData: {},
            msgCode: '',
            // used: false,
            loading: false,
            imgurl: require('@/assets/isacount.png'),
            param1: ['编辑'],
            param: ['编辑', '删除', '重置']
        };
    },
    computed: {
        ...mapState(["User", "currentAccountSets"]),
        creatorNum() {
            return this.datas.filter(val => val.creatorId === this.User.id).length;
        }
    },
    methods: {
        dictMapping,
        ...mapActions(['init']),
        trigger(code, row) {
            if (code == '编辑') {
                this.edit(row)
            } else if (code == '删除') {
                this.remove(row)
            } else if (code == '重置') {
                this.reset(row)
            }

        },
        loadList() {
            this.$api.setting.accountSets.list().then(({data}) => {
                console.log(data)
                this.datas = data || [];
            })
        },
        dateFormat({cellValue}) {
            return cellValue ? moment(cellValue).format("YYYY年MM月") : '';
        },
        remove(rmData) {
            let layerId = layer.open({
                title: "删除确认",
                shadeClose: false,
                closeBtn: false,
                zIndex: 999,
                area: ['400px', '300px'],
                content: h(RemoveConfirm, {
                    rmData,
                    onClose: () => {
                        layer.close(layerId);
                    },
                    onSuccess: () => {
                        this.init();
                        this.loadList();
                        layer.close(layerId);
                    }
                })
            });
        },
        reset(rmData) {
            let layerId = layer.open({
                title: "重置确认",
                shadeClose: false,
                closeBtn: false,
                zIndex: 999,
                area: ['500px', 'auto'],
                content: h(ResetConfirm, {
                    rmData,
                    onClose: () => {
                        layer.close(layerId);
                    },
                    onSuccess: () => {
                        this.init();
                        this.loadList();
                        layer.close(layerId);
                    }
                })
            });
        },
        edit(row) {
            Api.setting.accountSets.checkUse(row.id).then(({data}) => {
                this.showForm(row);
            })
        },
        showForm(entity = null) {
            let layerId = layer.open({
                title: "账套信息",
                shadeClose: false,
                closeBtn: false,
                zIndex: 999,
                area: ['900px', '500px'],
                content: h(AccountForm, {
                    entity,
                    onClose: () => {
                        layer.close(layerId);
                    },
                    onSuccess: () => {
                        this.init();
                        this.loadList();
                        layer.close(layerId);
                    }
                })
            });
        }
    },
    mounted() {
        this.loadList();
    }
};
</script>
<script setup>

import {ref} from "vue";

const used = ref();


</script>
<style lang='less' scoped>
.box2 {
  // width: 390px;
  height: 203px;
  background: #FFFFFF;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #2FA8FD;
  display: flex;
  flex-direction: column;

  .nameTitle {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    padding: 16px 12px;
    border-bottom: 1px solid #2FA8FD;

    img {
      margin-top: 2px;
      // vertical-align: bottom;
      width: 24px;
      height: 24px;
      margin-left: 20px;
    }

    span {
      font-size: 18px;
      font-weight: 400;
      color: #333333;
    }
  }

  .name2 {
    display: flex;
    // align-items: center;
    padding: 12px;

    img {
      width: 24px;
      height: 24px;
    }

    .spancss {
      display: flex;
      flex-direction: column;
      margin-left: 5px;

      .span1 {
        color: #666666;
      }

      .span2 {
        width: 85px;
        height: 28px;
        background: rgba(47, 168, 253, 0.3);
        border-radius: 4px 4px 4px 4px;
        text-align: center;
        line-height: 28px;
        color: #2FA8FD;
        font-size: 14px;
        margin: 10px 0;
        // opacity: 0.3;
      }

      .span3 {
        // margin-top: 10px;
        color: #666666;
        font-size: 14px;
      }
    }

  }

  position: relative;

  .fiximg {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 90px;
    height: 90px;
  }

}
</style>
